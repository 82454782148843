import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { Root, TextInput, TinyMCEWrapper } from './style';
import InvoerVlak from '../../../Gedeeld/InvoerVlak';
import { IconKruis, IconSave, IconVerwijderen } from '../../../Icons';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import GeadresseerdeInput from './GeadresseerdeInput';
import IEmailGeadresseerde from '../../../../../../shared/src/models/email/IEmailGeadresseerde';
import api from '../../../../api';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { IOphalenEmailDetailResultElement } from '../../../../../../shared/src/api/v2/dienst/email/email';
import BijlagenContainer, {
  Bestand,
  BestandType,
  IExternBestand,
  ILokaalBestand,
} from '../../../BijlagenContainer';
import { IUploadVoortgangVanLokaleBestanden } from '../../../BestandDragAndDropZone';
import { IOphalenAfgeleideDataResult } from '../../../../../../shared/src/api/v2/relatie';
import BijlageKnop from '../../../BijlageKnop';
import {
  IAccountContextDataParams,
  IPersoonContextDataParams,
} from '../../../../../../shared/src/api/v2/Sjabloon/contextdata';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import SjabloonKnop, { EWeergave } from '../../../SjabloonKnop';
import nameof from '../../../../core/nameOf';
import { IDataContext } from '../../../../helpers/sjabloon';
import { EResultType } from '../../../../stores/CheckStore';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import { IEmailOpzet } from '../../../../../../shared/src/api/v2/communicatie/email';
import { Omit } from '../../../../../../shared/src/core';
import HorizontaleScheidingslijn from '../../../layout/HorizontaleScheidingslijn';
import VerticaleScheidingslijn from '../../../layout/VerticaleScheidingslijn';
import RelatiesVisualisatie from '../../../personalia/RelatiesVisualisaties';
import { toast } from 'react-hot-toast';
import { addMinutes } from 'date-fns';
import VersturenKnop, { EUitstelling } from './VersturenKnop';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';

export interface IFormikValues {
  emailBerID: number | null;
  fromEmailGebrID: number | null;
  sjabBasisID: number | null;
  taalID: number | null;
  subject: string;
  body: string;
  recipients: IEmailGeadresseerde[];
  CC: IEmailGeadresseerde[];
  BCC: IEmailGeadresseerde[];
  bijlagen: Bestand[];
  contextRelIDs: number[];
  uitstelling: EUitstelling;
}

interface IProps {
  emailBerID: number | null;
  relID?: number;
  onAutosaveFormulierChange: (formulier: IFormikValues | null) => void;
  onFormulierChange?: (formulier: IFormikValues) => void;
  onSluiten?: () => void;
  annulerenWeergeven?: boolean;
  formulier: IFormikValues | null;
  defaultSjabBasisID: number | null;
  defaultTaalID: number | null;
  defaultFromEmailGebrID: number | null;
  defaultSubject: string;
  defaultBody: string;
  defaultRecipients: IEmailGeadresseerde[];
  defaultCC: IEmailGeadresseerde[];
  defaultBCC: IEmailGeadresseerde[];
  defaultBijlagen: Bestand[];
  defaultContextRelIDs: number[];
  defaultUitstelling: EUitstelling;
}

const buttonWidth = 80;
const buttonPadding = '2px 5px';
const initialBodyValue =
  '<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body style="padding: 10px;"></body>\n</html>';
export const defaultOpstellenFormulierFormikValues: IFormikValues = {
  subject: '',
  body: '',
  bijlagen: [],
  BCC: [],
  CC: [],
  recipients: [],
  fromEmailGebrID: null,
  emailBerID: null,
  sjabBasisID: null,
  taalID: null,
  contextRelIDs: [],
  uitstelling: EUitstelling.Direct,
};

const emailOpzetMaken = async (values: IFormikValues): Promise<Omit<IEmailOpzet, 'van'>> => {
  let inhoud = values.body;
  if (values.sjabBasisID !== null) {
    const result = await api.v2.sjabloon.resolveSjabloonBasis({
      inhoud,
      sjabBasisID: values.sjabBasisID,
    });
    const renderResult = await api.v2.sjabloon.renderSjabloon({
      data: result.output,
      taalID: values.taalID === null ? undefined : values.taalID,
      contexten: {},
    });
    inhoud = renderResult.output;
  }

  return {
    aan: values.recipients,
    cc: values.CC,
    bcc: values.BCC,
    // interneReferentie: uuid(),
    onderwerp: values.subject || '',
    inhoud,
    isHTML: true,
    bijlagen: values.bijlagen.map((x) => ({
      bestandID: (x as IExternBestand).bestand.ID,
    })),
    contextRelIDs: values.contextRelIDs,
  };
};

export interface IOpstellenFormulierRef {
  setFormulier: (formulier: IFormikValues) => void;
  resetForm: () => void;
}

const OpstellenFormulier = observer(
  forwardRef<IOpstellenFormulierRef, IProps>((props, forwardedRef) => {
    const formikRef = useRef<Formik<IFormikValues>>(null);
    const { emailStore } = useContext(RootStoreContext);
    const [
      opgehaaldConcept,
      setOpgehaaldConcept,
    ] = useState<IOphalenEmailDetailResultElement | null>(null);

    useEffect(() => {
      if (emailStore.emailAccounts !== null) {
        return;
      }
      (async () => {
        await emailStore.ophalenEmailAccounts();
      })();
    }, [emailStore.emailAccounts]);

    useEffect(() => {
      setOpgehaaldConcept(null);
      // if (props.emailBerID === null) {
      //   setOpgehaaldConcept(null);
      // } else {
      // (async () => {
      //   const result = await api.v2.dienst.email.ophalenEmailDetail({
      //     emailBerID: props.emailBerID!,
      //   });
      //   setOpgehaaldConcept(result.emails[0]);
      // })();
      // }
    }, [props.emailBerID, setOpgehaaldConcept]);

    const initialValues = useMemo<IFormikValues | null>(() => {
      if (emailStore.emailAccounts === null) {
        return null;
      }

      let fromEmailGebrID = null;
      if (emailStore.emailAccounts.length > 0) {
        fromEmailGebrID = emailStore.emailAccounts[0].EmailGebrID;
      }

      if (opgehaaldConcept !== null) {
        return {
          emailBerID: opgehaaldConcept.EmailBerID || fromEmailGebrID,
          fromEmailGebrID: opgehaaldConcept.EmailGebrID,
          recipients: opgehaaldConcept.Aan,
          body: opgehaaldConcept.Inhoud || initialBodyValue,
          BCC: opgehaaldConcept.BCC,
          CC: opgehaaldConcept.CC,
          subject: opgehaaldConcept.Onderwerp || '',
          sjabBasisID: null,
          taalID: null,
          bijlagen: [],
          contextRelIDs: [],
          uitstelling: EUitstelling.Direct,
        };
      }

      if (props.formulier !== null) {
        return {
          emailBerID: props.formulier.emailBerID || null,
          fromEmailGebrID: props.formulier.fromEmailGebrID || fromEmailGebrID,
          subject: props.formulier.subject,
          body: props.formulier.body || initialBodyValue,
          recipients: props.formulier.recipients,
          CC: props.formulier.CC,
          BCC: props.formulier.BCC,
          bijlagen: props.formulier.bijlagen,
          sjabBasisID: props.formulier.sjabBasisID,
          taalID: props.formulier.taalID,
          contextRelIDs: props.formulier.contextRelIDs,
          uitstelling: props.formulier.uitstelling,
        };
      }

      return {
        emailBerID: null,
        fromEmailGebrID: props.defaultFromEmailGebrID || fromEmailGebrID,
        subject: props.defaultSubject,
        body: props.defaultBody || initialBodyValue,
        recipients: props.defaultRecipients,
        CC: props.defaultCC,
        BCC: props.defaultBCC,
        bijlagen: props.defaultBijlagen,
        sjabBasisID: props.defaultSjabBasisID,
        taalID: props.defaultTaalID,
        contextRelIDs: props.defaultContextRelIDs,
        uitstelling: props.defaultUitstelling,
      };
    }, [
      opgehaaldConcept,
      emailStore.emailAccounts,
      props.defaultSubject,
      props.defaultBody,
      props.defaultRecipients,
      props.defaultFromEmailGebrID,
      props.defaultCC,
      props.defaultBCC,
      props.defaultBijlagen,
      props.defaultUitstelling,
      props.formulier,
    ]);

    // useMemo(() => {
    //   if (initialValues === null) {
    //     return;
    //   }
    //   setBCCTonen(initialValues.BCC.length > 0);
    // }, [setBCCTonen, initialValues]);

    const handleSubmit = useCallback(
      async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
        actions.setSubmitting(true);

        const opzet = await emailOpzetMaken(values);

        let uitgesteldVersturenOp: Date | null = null;

        switch (values.uitstelling) {
          case EUitstelling.Direct:
            uitgesteldVersturenOp = null;
            break;
          case EUitstelling.EenMinuut:
            uitgesteldVersturenOp = addMinutes(new Date(), 1);
            break;
          case EUitstelling.DrieMinuten:
            uitgesteldVersturenOp = addMinutes(new Date(), 3);
            break;
          case EUitstelling.VijfMinuten:
            uitgesteldVersturenOp = addMinutes(new Date(), 5);
            break;
        }

        await api.v2.email.versturen({
          van_EmailGebrID: values.fromEmailGebrID,
          email: opzet,
          uitgesteldVersturenOp,
        });

        const formikActions = formikRef.current!.getFormikActions();
        formikActions.resetForm(defaultOpstellenFormulierFormikValues);

        actions.setSubmitting(false);

        toast.success('Email verstuurd');

        props.onSluiten?.();
      },
      [props.onSluiten],
    );

    const handleValidate = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
      const errors: FormikErrors<IFormikValues> = {};
      if (values.fromEmailGebrID === null) {
        errors.fromEmailGebrID = 'Van is verplicht';
      }

      if (values.recipients.length === 0 && values.CC.length > 1) {
        // @ts-ignore
        errors.recipients = 'Het is niet mogelijk om enkel CC/BCC als geadresseerde in te voeren';
      }
      if (values.recipients.length === 0 && values.BCC.length > 1) {
        // @ts-ignore
        errors.recipients = 'Het is niet mogelijk om enkel CC/BCC als geadresseerde in te voeren';
      }

      if (values.recipients.length === 0) {
        // @ts-ignore
        errors.recipients = 'Je dient minstens een geadresseerde in te voeren';
      }

      return errors;
    }, []);

    const isLoading = useMemo(() => {
      return initialValues === null || (props.emailBerID !== null && opgehaaldConcept === null);
    }, [initialValues, props.emailBerID, opgehaaldConcept]);

    useImperativeHandle(forwardedRef, () => ({
      setFormulier: (values: IFormikValues) => {
        formikRef.current!.getFormikActions().setValues(values);
      },
      resetForm: () => {
        formikRef.current!.getFormikActions().resetForm(defaultOpstellenFormulierFormikValues);
      },
    }));

    if (isLoading) {
      return (
        <Root>
          <LoadingSpinner />
        </Root>
      );
    }

    return (
      <Formik<IFormikValues>
        ref={formikRef}
        // enableReinitialize
        initialValues={initialValues!}
        onSubmit={handleSubmit}
        validate={handleValidate}
        isInitialValid={() => Object.keys(handleValidate(initialValues!)).length === 0}
        render={(formikProps: FormikProps<IFormikValues>) => (
          <OpstellenFormulierInner
            {...props}
            formikProps={formikProps}
            opgehaaldConcept={opgehaaldConcept}
            isLoading={isLoading}
          />
        )}
      />
    );
  }),
);

const OpstellenFormulierInner = observer(
  (
    props: IProps & {
      formikProps: FormikProps<IFormikValues>;
      opgehaaldConcept: IOphalenEmailDetailResultElement | null;
      isLoading: boolean;
    },
  ) => {
    const { formikProps, opgehaaldConcept, isLoading } = props;
    const { isSubmitting } = formikProps;

    const { emailStore, checkStore } = useContext(RootStoreContext);

    const bijlagenRef = useRef<Bestand[]>(formikProps.values.bijlagen);
    const setBijlagen = useCallback((bijlagen: Bestand[]) => {
      bijlagenRef.current = bijlagen;
      formikProps.setFieldValue('bijlagen', bijlagen);
    }, []);
    const formikValuesBijlagenStringify = JSON.stringify(formikProps.values.bijlagen);
    useEffect(() => {
      if (formikValuesBijlagenStringify === JSON.stringify(bijlagenRef.current)) {
        return;
      }
      bijlagenRef.current = formikProps.values.bijlagen;
    }, [formikValuesBijlagenStringify]);

    const [recipientsText, setRecipientsText] = useState<string>('');
    const [ccText, setCcText] = useState<string>('');
    const [bccText, setBccText] = useState<string>('');
    // const fileInputRef = useRef<HTMLInputElement>(null);
    const [CCTonen, setCCTonen] = useState((props.formulier?.CC.length ?? 0) > 0);
    const [BCCTonen, setBCCTonen] = useState((props.formulier?.BCC.length ?? 0) > 0);
    const [relContextTonen, setRelContextTonen] = useState(
      (props.formulier?.contextRelIDs.length ?? 0) > 0,
    );
    const [uploadProgresses, setUploadProgresses] = useState<IUploadVoortgangVanLokaleBestanden>(
      {},
    );

    const dataContextenRef = useRef<IDataContext[] | null>(null);
    const [afgeleideData, setAfgeleideData] = useState<IOphalenAfgeleideDataResult | null>(null);
    useEffect(() => {
      if (props.relID === undefined) {
        setAfgeleideData(null);
        return;
      }
      (async () => {
        const result = await api.v2.relatie.ophalenAfgeleideData({
          relID: props.relID!,
        });
        setAfgeleideData(result);
      })();
    }, [props.relID]);

    const [eenduidigPersoon, setEenduigPersoon] = useState<
      IRemoteData<IOphalenPersonenResultElementV2 | null>
    >(createReadyRemoteData(null));

    const handleAnnuleren = useCallback(() => {
      if (props.onSluiten) {
        props.onSluiten();
      }
    }, [props.onSluiten]);

    const handleSaveEmail = useCallback(
      async (values: IFormikValues) => {
        formikProps.setSubmitting(true);

        const opzet = await emailOpzetMaken(values);

        await api.v2.email.bewaren({
          van_EmailGebrID: values.fromEmailGebrID,
          email: opzet,
        });

        props.onSluiten?.();
        formikProps.resetForm(defaultOpstellenFormulierFormikValues);

        formikProps.setSubmitting(false);

        toast.success('Email opgeslagen');
        props.onSluiten?.();
      },
      [props.onSluiten, props.emailBerID, props.onSluiten, formikProps],
    );

    const handleConceptVerwijderen = useCallback(async () => {
      if (props.onSluiten) {
        props.onSluiten();
      }
      await api.v2.dienst.email.verwijderenConcept(props.emailBerID!);
    }, [props.onSluiten, props.emailBerID]);

    const handleBestandenBijgevoegd = useCallback(
      (bestanden: Bestand[]) => {
        const lokaleBestanden = bestanden.filter(
          (best) => best.type === BestandType.Lokaal,
        ) as ILokaalBestand[];

        lokaleBestanden.forEach(async (bestand) => {
          const externBestand = await api.v2.bestand.upload(
            {
              naam: bestand.naam,
              mediaType: bestand.mediaType,
              url: bestand.url,
              file: bestand.file,
              grootte: bestand.grootte,
            },
            (percentage) => {
              setUploadProgresses((prev) => ({
                ...prev,
                [bestand.url]: percentage,
              }));
            },
          );
          setBijlagen([
            ...bijlagenRef.current!.filter((x) => {
              return (x as any).url !== bestand.url;
            }),
            {
              type: BestandType.ASPDrive,
              bestand: externBestand,
            },
          ]);
        });

        const nieuweBijlagen = [...bijlagenRef.current!, ...bestanden];
        setBijlagen(nieuweBijlagen);
      },
      [setUploadProgresses],
    );

    const hasUploadingFiles = useMemo(
      () => formikProps.values.bijlagen.some((x) => x.type === BestandType.Lokaal),
      [formikProps.values.bijlagen],
    );
    const canSendMail = useMemo(() => {
      const trimmedRecipientsText = recipientsText.trim();
      const trimmedCcText = ccText.trim();
      const trimmedBccText = bccText.trim();
      if (
        trimmedRecipientsText.length !== 0 ||
        trimmedCcText.length !== 0 ||
        trimmedBccText.length !== 0
      ) {
        return false;
      }
      return formikProps.isValid && !formikProps.isSubmitting && !hasUploadingFiles;
    }, [
      formikProps.isValid,
      formikProps.isSubmitting,
      hasUploadingFiles,
      recipientsText,
      ccText,
      bccText,
    ]);
    // const canSaveEmail = formikProps.dirty;
    //
    const values = formikProps.values;
    if (
      props.onFormulierChange !== undefined &&
      JSON.stringify(values) !== JSON.stringify(props.formulier)
    ) {
      props.onFormulierChange(values);
    }
    if (
      values.subject !== '' ||
      values.CC.length > 0 ||
      values.recipients.length > 0 ||
      values.BCC.length > 0 ||
      values.body !== initialBodyValue
    ) {
      props.onAutosaveFormulierChange(values);
    } else {
      props.onAutosaveFormulierChange(null);
    }

    let dataContexten: Array<IDataContext> = [];
    const gekoppeldeGeadresseerde = useMemo(
      () => values.recipients.find((x) => x.persID !== null),
      [values.recipients],
    );

    useEffect(() => {
      if (gekoppeldeGeadresseerde === undefined) {
        setEenduigPersoon(createReadyRemoteData(null));
        return;
      }
      setEenduigPersoon(createPendingRemoteData());
      api.v2.persoon
        .ophalenPersonen({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [gekoppeldeGeadresseerde.persID!],
              },
            ],
          },
        })
        .then((result) => {
          setEenduigPersoon(createReadyRemoteData(result.personen[0]));
        });
    }, [gekoppeldeGeadresseerde?.persID]);

    if (gekoppeldeGeadresseerde !== undefined) {
      dataContexten = [
        ...dataContexten,
        {
          naamEnum: 'PERSOON',
          data: {
            persID: gekoppeldeGeadresseerde.persID,
          } as IPersoonContextDataParams,
        },
      ];
    }
    // TODO: Relatie context op een andere manier bepalen?
    // if (
    //   props.relID !== undefined ||
    //   (gekoppeldeGeadresseerde !== undefined && gekoppeldeGeadresseerde.relID !== null)
    // ) {
    //   dataContexten = [
    //     ...dataContexten,
    //     {
    //       naamEnum: 'RELATIE',
    //       data: {
    //         relID: props.relID !== undefined ? props.relID : gekoppeldeGeadresseerde!.relID,
    //       } as IRelatieContextDataParams,
    //     },
    //   ];
    // }

    if (afgeleideData !== null) {
      if (afgeleideData.eenduidig_AccID !== null) {
        dataContexten = [
          ...dataContexten,
          {
            naamEnum: 'ACCOUNT',
            data: {
              accID: afgeleideData.eenduidig_AccID,
            } as IAccountContextDataParams,
          },
        ];
      }
    }
    dataContextenRef.current = dataContexten;

    return (
      <>
        <Root>
          <div className="formulier list-group list-group-flush">
            <div className="list-group-item" style={{ margin: 0, padding: 0 }}>
              <InvoerVlak
                attributenLinks={
                  <OverlayTrigger
                    overlay={
                      <Popover id="van-knop-popover">
                        {emailStore.emailAccounts === null ? (
                          <LoadingSpinner />
                        ) : (
                          <div
                            className="list-group list-group-flush"
                            style={{ margin: '-.5rem -.75rem' }}
                          >
                            {emailStore.emailAccounts.map((emailAccount) => {
                              return (
                                <div
                                  key={emailAccount.EmailGebrID}
                                  className="list-group-item list-group-item-action"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    formikProps.setFieldValue(
                                      'fromEmailGebrID',
                                      emailAccount.EmailGebrID,
                                    );
                                    document.body.click();
                                  }}
                                >
                                  {emailAccount.GebruikersNaam}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </Popover>
                    }
                    placement="bottom-start"
                    trigger="click"
                    rootClose
                  >
                    <button
                      className="btn btn-primary btn-primary-soft mr-4"
                      style={{ width: buttonWidth, padding: buttonPadding }}
                    >
                      Van
                    </button>
                  </OverlayTrigger>
                }
                attributenRechts={null}
                invoerVeld={
                  <Field
                    name="fromEmailGebrID"
                    render={({ field }: FieldProps<IFormikValues>) => {
                      if (field.value === null) {
                        return <span style={{ fontStyle: 'italic' }}>Nog niet geselecteerd</span>;
                      }

                      return (
                        <span>
                          {
                            emailStore.emailAccounts!.find(
                              (emailAccount) => emailAccount.EmailGebrID === field.value,
                            )!.GebruikersNaam
                          }
                        </span>
                      );
                    }}
                  />
                }
              />
            </div>

            <div className="list-group-item" style={{ margin: 0, padding: 0 }}>
              <InvoerVlak
                attributenLinks={
                  <div
                    className="ml-2 mr-4 d-flex align-items-center justify-content-center"
                    style={{ width: 80 }}
                    // style={{ width: buttonWidth, padding: buttonPadding }}
                  >
                    <span className="mr-2">Aan</span>
                  </div>
                }
                attributenRechts={
                  <>
                    {!CCTonen && (
                      <button
                        className="btn btn-primary btn-primary-soft ml-4"
                        style={{ width: buttonWidth, padding: buttonPadding }}
                        onClick={() => setCCTonen(true)}
                      >
                        CC
                        {props.formulier?.CC !== undefined && props.formulier.CC.length > 0 && (
                          <span className="text-muted">
                            &nbsp;-&nbsp;{props.formulier.CC.length}
                          </span>
                        )}
                      </button>
                    )}
                    {!BCCTonen && (
                      <button
                        className="btn btn-primary btn-primary-soft ml-4"
                        style={{ width: buttonWidth, padding: buttonPadding }}
                        onClick={() => setBCCTonen(true)}
                      >
                        BCC
                        {props.formulier?.BCC !== undefined && props.formulier.BCC.length > 0 && (
                          <span className="text-muted">
                            &nbsp;-&nbsp;{props.formulier.BCC.length}
                          </span>
                        )}
                      </button>
                    )}

                    {!relContextTonen && (
                      <button
                        className="btn btn-primary btn-primary-soft ml-4"
                        style={{ width: buttonWidth, padding: buttonPadding }}
                        onClick={() => setRelContextTonen(true)}
                      >
                        Context
                        {props.formulier?.contextRelIDs !== undefined &&
                          props.formulier.contextRelIDs.length > 0 && (
                            <span className="text-muted">
                              &nbsp;-&nbsp;{props.formulier.contextRelIDs.length}
                            </span>
                          )}
                      </button>
                    )}
                  </>
                }
                invoerVeld={
                  <Field
                    name="recipients"
                    render={({ field, form }: FieldProps<IFormikValues>) => {
                      return (
                        <GeadresseerdeInput
                          value={field.value}
                          onChange={(value) => form.setFieldValue(field.name, value)}
                          text={bccText}
                          onTextChange={setBccText}
                        />
                      );
                    }}
                  />
                }
              />
            </div>

            {CCTonen ? (
              <div className="list-group-item" style={{ margin: 0, padding: 0 }}>
                <InvoerVlak
                  attributenLinks={
                    <div
                      className="ml-2 mr-4 d-flex align-items-center justify-content-center"
                      style={{ width: 80 }}
                      // style={{ width: buttonWidth, padding: buttonPadding }}
                    >
                      <span className="mr-2">CC</span>
                    </div>
                  }
                  attributenRechts={
                    <button
                      className="btn btn-primary btn-danger-soft ml-4"
                      style={{ width: buttonWidth, padding: buttonPadding }}
                      onClick={() => setCCTonen(false)}
                    >
                      <IconKruis style={{ fill: '#AAA' }} />
                    </button>
                  }
                  invoerVeld={
                    <Field
                      name="CC"
                      render={({ field, form }: FieldProps<IFormikValues>) => {
                        return (
                          <GeadresseerdeInput
                            value={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            text={ccText}
                            onTextChange={setCcText}
                          />
                        );
                      }}
                    />
                  }
                />
              </div>
            ) : null}

            {BCCTonen ? (
              <div className="list-group-item" style={{ margin: 0, padding: 0 }}>
                <InvoerVlak
                  attributenLinks={
                    <div
                      className="ml-2 mr-4 d-flex align-items-center justify-content-center"
                      style={{ width: 80 }}
                      // style={{ width: buttonWidth, padding: buttonPadding }}
                    >
                      <span className="mr-2">BCC</span>
                    </div>
                  }
                  attributenRechts={
                    <button
                      className="btn btn-primary btn-danger-soft ml-4"
                      style={{ width: buttonWidth, padding: buttonPadding }}
                      onClick={() => setBCCTonen(false)}
                    >
                      <IconKruis style={{ fill: '#AAA' }} />
                    </button>
                  }
                  invoerVeld={
                    <Field
                      name="BCC"
                      render={({ field, form }: FieldProps<IFormikValues>) => {
                        return (
                          <GeadresseerdeInput
                            value={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            text={bccText}
                            onTextChange={setBccText}
                          />
                        );
                      }}
                    />
                  }
                />
              </div>
            ) : null}

            {relContextTonen ? (
              <div className="list-group-item" style={{ margin: 0, padding: 0 }}>
                <InvoerVlak
                  attributenLinks={
                    <div
                      className="ml-2 mr-4 d-flex align-items-center justify-content-center"
                      style={{ width: 80 }}
                      // style={{ width: buttonWidth, padding: buttonPadding }}
                    >
                      <span className="mr-2">Context</span>
                    </div>
                  }
                  attributenRechts={
                    <button
                      className="btn btn-primary btn-danger-soft ml-4"
                      style={{ width: buttonWidth, padding: buttonPadding }}
                      onClick={() => setRelContextTonen(false)}
                    >
                      <IconKruis style={{ fill: '#AAA' }} />
                    </button>
                  }
                  invoerVeld={
                    <Field
                      name={nameof<IFormikValues>('contextRelIDs')}
                      render={({ field, form }: FieldProps<IFormikValues>) => {
                        return <RelatiesVisualisatie relIDs={field.value} />;
                      }}
                    />
                  }
                />
              </div>
            ) : null}

            <div
              className="list-group-item"
              style={{ margin: 0, padding: 0, display: 'flex', width: '100%' }}
            >
              <InvoerVlak
                attributenLinks={null}
                attributenRechts={null}
                invoerVeld={
                  <Field
                    name="subject"
                    render={({ field }: FieldProps<IFormikValues>) => {
                      return (
                        <TextInput {...field} placeholder="Onderwerp" style={{ width: '100%' }} />
                      );
                    }}
                  />
                }
              />
            </div>

            <Field
              name="body"
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <TinyMCEWrapper>
                  <TinyMCEEditor
                    apiKey="vwadi0h4ncvngqx33m154synirmk800idujzjsly8onq1pig"
                    value={fieldProps.field.value}
                    onEditorChange={(content: any, editor: any) =>
                      fieldProps.form.setFieldValue(fieldProps.field.name, content)
                    }
                    init={{
                      toolbar: false,
                      // plugins: ['link', 'table', 'font', 'fullpage'],
                      plugins: [
                        'advlist autolink link image lists charmap print preview hr anchor pagebreak',
                        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                        'table emoticons template paste help font fullpage',
                      ],

                      // toolbar:
                      //   'undo redo | bold italic | alignleft aligncenter alignright | code',
                      content_css: ['https://fonts.googleapis.com/css?family=Muli'],
                      content_style:
                        '.mce-content-body {font-size:14px;font-family:Muli; padding: 10px 20px; } p { margin: 0; margin-bottom: 12px; margin-top: 12px; }',
                      font_formats: 'Muli=Muli, cursive;',
                      // valid_children: '+body[style]',
                      visual: false,
                      menubar: false,
                      // menu: {
                      //   file: {
                      //     title: 'File',
                      //     items: 'newdocument restoredraft | preview | print ',
                      //   },
                      //   edit: {
                      //     title: 'Edit',
                      //     items: 'undo redo | cut copy paste | selectall | searchreplace',
                      //   },
                      //   view: {
                      //     title: 'View',
                      //     items:
                      //       'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
                      //   },
                      //   insert: {
                      //     title: 'Insert',
                      //     items:
                      //       'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
                      //   },
                      //   format: {
                      //     title: 'Format',
                      //     items:
                      //       'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
                      //   },
                      //   tools: {
                      //     title: 'Tools',
                      //     items: 'spellchecker spellcheckerlanguage | code wordcount',
                      //   },
                      //   table: {
                      //     title: 'Table',
                      //     items: 'inserttable | cell row column | tableprops deletetable',
                      //   },
                      //   help: { title: 'Help', items: 'help' },
                      // },
                    }}
                  />
                </TinyMCEWrapper>
              )}
            />

            <Field
              name="bijlagen"
              render={({ field, form }: FieldProps<IFormikValues>) => {
                const bestanden = field.value as Bestand[];
                return (
                  bestanden.length !== 0 && (
                    <>
                      <HorizontaleScheidingslijn />
                      <div className="p-2" style={{ background: Kleur.HeelLichtGrijs }}>
                        <BijlagenContainer
                          bestanden={field.value}
                          uploadProgresses={uploadProgresses}
                          onBestandenChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          bestandenMuterenToegestaan={!isLoading && !isSubmitting}
                        />
                      </div>
                    </>
                  )
                );
              }}
            />
          </div>
          <div className="acties">
            {props.onSluiten !== undefined && props.annulerenWeergeven && (
              <button className="btn btn-secondary btn-sm mr-2" onClick={handleAnnuleren}>
                Annuleren
              </button>
            )}
            {opgehaaldConcept !== null && (
              <button
                className="btn btn-danger btn-sm ml-2 mr-2"
                onClick={handleConceptVerwijderen}
              >
                Concept verwijderen
              </button>
            )}
            <button
              className="btn btn-light btn-sm d-flex align-items-center"
              onClick={async () => {
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: 'Bevestig wissen email',
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }
                formikProps.resetForm(defaultOpstellenFormulierFormikValues);
              }}
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            >
              <IconVerwijderen
                style={{
                  fill: Kleur.Grijs,
                  width: 15,
                  height: 15,
                }}
              />
              <span className="ml-2">Wissen</span>
            </button>

            <div className="flex-fill" />
            <div className="d-flex align-items-center">
              <Field
                name={nameof<IFormikValues>('sjabBasisID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <SjabloonKnop
                        beschikbareContextenSelectie={dataContexten}
                        communicatieKanaalSelectie={{
                          naamEnum: 'EMAIL',
                        }}
                        basisNietToepassen
                        weergave={EWeergave.Groot}
                        // taalID={eenduidigPersoon.data?.taal.TaalID ?? undefined}
                        onSjabloonGeresolved={async (result) => {
                          const resolveResult = result.resolveResult;
                          let onderwerpVervangen = resolveResult.onderwerp !== undefined;
                          let bijlagenSituatie: 'VERVANGEN' | 'SAMENVOEGEN' = 'VERVANGEN';
                          if (
                            form.values.subject.trim().length > 0 &&
                            resolveResult.onderwerp !== undefined &&
                            resolveResult.onderwerp !== form.values.subject.trim()
                          ) {
                            const bevestigenResult = await checkStore.bevestigen({
                              inhoud: (
                                <div className="d-flex flex-column">
                                  <span>
                                    Wil je ook het onderwerp vervangen met die van het sjabloon (zie
                                    hieronder)?
                                  </span>
                                  <span className="mt-2 font-weight-bold">
                                    {resolveResult.onderwerp}
                                  </span>
                                  <span className="mt-2 small">
                                    Druk op Annuleren om het huidige onderwerp te behouden.
                                  </span>
                                </div>
                              ),
                            });
                            onderwerpVervangen = bevestigenResult.type === EResultType.Ok;
                          }
                          if (
                            form.values.bijlagen.length > 0 &&
                            resolveResult.bestanden.length > 0
                          ) {
                            const bevestigenResult = await checkStore.bevestigen({
                              inhoud: (
                                <div className="d-flex flex-column">
                                  <span>
                                    Het gekozen sjabloon heeft bijlagen toegekend gekregen maar er
                                    zijn ook al bijlagen aan deze email bijgevoegd.
                                  </span>
                                  <span>
                                    Wil je de bijlagen vervangen door de bijlagen van het sjabloon
                                    of wil je alle bijlagen samenvoegen?
                                  </span>
                                  <span className="mt-2 small">
                                    Druk op Ok om te vervangen en op Annuleren om samen te voegen.
                                  </span>
                                </div>
                              ),
                            });
                            bijlagenSituatie =
                              bevestigenResult.type === EResultType.Ok
                                ? 'VERVANGEN'
                                : 'SAMENVOEGEN';
                          }

                          if (onderwerpVervangen) {
                            form.setFieldValue(
                              nameof<IFormikValues>('subject'),
                              resolveResult.onderwerp || '',
                            );
                          }
                          form.setFieldValue(
                            nameof<IFormikValues>('body'),
                            resolveResult.inhoud || '',
                          );
                          if (bijlagenSituatie === 'VERVANGEN') {
                            form.setFieldValue(
                              nameof<IFormikValues>('bijlagen'),
                              resolveResult.bestanden.map(
                                (bestand) =>
                                  ({
                                    type: BestandType.ASPDrive,
                                    bestand,
                                  } as IExternBestand),
                              ),
                            );
                          } else if (bijlagenSituatie === 'SAMENVOEGEN') {
                            form.setFieldValue(nameof<IFormikValues>('bijlagen'), [
                              ...form.values.bijlagen,
                              ...resolveResult.bestanden.map(
                                (bestand) =>
                                  ({
                                    type: BestandType.ASPDrive,
                                    bestand,
                                  } as IExternBestand),
                              ),
                            ]);
                          }
                          form.setFieldValue(field.name, resolveResult.sjabBasisID);
                        }}
                        disabled={eenduidigPersoon.state === ERemoteDataState.Pending}
                      />
                      {field.value !== null && (
                        <div
                          className="ml-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => form.setFieldValue(field.name, null)}
                        >
                          <UitlegTooltip
                            inhoud={
                              <div className="d-flex flex-column">
                                <span>Deactiveer het toegepaste sjabloon</span>
                                <span className="mt-2 small">
                                  Gebruik dit om te voorkomen dat de basis van een sjabloon alsnog
                                  wordt toegepast.
                                </span>
                              </div>
                            }
                          >
                            <IconKruis style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
                          </UitlegTooltip>
                        </div>
                      )}
                    </>
                  );
                }}
              />
            </div>

            <div className="ml-2">
              <BijlageKnop onBijgevoegd={handleBestandenBijgevoegd} />
            </div>

            <VerticaleScheidingslijn className="ml-2" />

            <button
              className="btn btn-light btn-sm ml-2 d-flex align-items-center"
              disabled={!canSendMail}
              onClick={() => handleSaveEmail(formikProps.values)}
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            >
              <IconSave
                style={{
                  fill: Kleur.Grijs,
                  width: 16,
                  height: 16,
                  position: 'relative',
                  top: 1,
                }}
              />
              <span className="ml-2">Bewaren</span>
            </button>

            <VersturenKnop
              onVersturen={formikProps.submitForm}
              disabled={!canSendMail}
              uitstelling={formikProps.values.uitstelling}
              onUitstellingChange={(uitstelling) =>
                formikProps.setFieldValue(nameof<IFormikValues>('uitstelling'), uitstelling)
              }
            />
          </div>
        </Root>
      </>
    );
  },
);

export default OpstellenFormulier;
