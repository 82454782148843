import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IOphalenBestandenResultElement } from '../../../../../../../shared/src/api/v2/bestand/bestand';
import { ISponsorContractBijlage } from '../../../../../../../shared/src/api/v2/sponsoringv2/contract';
import api from '../../../../../api';
import BijlageKnop, { EOrientatie } from '../../../../../components/BijlageKnop';
import BijlagenContainer, {
  BestandType,
  EWeergaveFocus,
  IExternBestand,
} from '../../../../../components/BijlagenContainer';
import MenuLayout from '../../../../../components/MenuLayout';
import useUpload from '../../../../../core/useUpload';

interface IProps {
  koppelBasisID: number;
}

const GekoppeldeBijlagen: React.FC<IProps> = (props) => {
  const [rijen, setRijen] = useState<ISponsorContractBijlage[] | null>(null);
  const [bijlageBestanden, setBijlageBestanden] = useState<IOphalenBestandenResultElement[] | null>(
    null,
  );

  const {
    moetNogUploaden,
    isBezigMetUploaden,
    uploadProgresses,
    bestanden,
    muteerBestanden,
  } = useUpload(
    useMemo(() => {
      console.log('update upload data');
      console.log(bijlageBestanden);
      console.log(rijen);
      return {
        initieleBestanden:
          bijlageBestanden?.map(
            (bestand): IExternBestand => {
              return {
                type: BestandType.ASPDrive,
                bestand: bestand,
              };
            },
          ) ?? null,
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (bestandIDs: number[]) => {
          if (rijen === null) {
            return;
          }

          for (let bestandID of bestandIDs) {
            const bestaandeRij = rijen.find((r) => r.BestandID == bestandID);
            if (!bestaandeRij) {
              console.log('koppelen:' + bestandID);
              // nieuwe bijlagen koppelen
              await api.v2.sponsoringv2.contract.koppelenContractBijlagen({
                ContractID: props.koppelBasisID,
                BestandID: bestandID,
              });
            }
          }

          for (let rij of rijen) {
            if (!bestandIDs.includes(rij.BestandID)) {
              console.log('ontkoppelen:' + rij.ID);
              // verwijderde bijlagen ontkoppelen
              await api.v2.sponsoringv2.contract.ontkoppelenContractBijlagen({
                ids: [rij.ID!],
              });
            }
          }
        },
      };
    }, [bijlageBestanden, rijen]),
  );

  const ophalenRijen = async () => {
    const results = await api.v2.sponsoringv2.contract.ophalenGekoppeldeBijlagen({
      filterSchema: {
        filters: [{ naam: 'CONTRACT_IDS', data: [props.koppelBasisID] }],
      },
    });
    console.log('bijlage rijen');
    console.log(results);
    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, [bestanden]);

  const ophalenBijlagen = async () => {
    if (rijen === null) {
      return;
    }

    const bestandIDs = rijen.map((r) => r.BestandID);
    const results = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [{ naam: 'IDS', data: bestandIDs }],
      },
    });

    console.log('bijlage bestanden');
    console.log(results.bestanden);
    setBijlageBestanden(results.bestanden);
  };

  useEffect(() => {
    ophalenBijlagen();
  }, [rijen]);

  return (
    <div className="d-flex">
      <MenuLayout
        menu={<></>}
        body={
          <div>
            <>
              <div className="row">
                <div className="col-6 d-flex">
                  <div style={{ height: '100%' }} className="mr-2">
                    <BijlageKnop
                      disabled={isBezigMetUploaden}
                      onBijgevoegd={(bijlagen) =>
                        muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
                      }
                      orientatie={EOrientatie.Verticaal}
                    />
                  </div>
                  <div>
                    <BijlagenContainer
                      bestanden={bestanden}
                      uploadProgresses={uploadProgresses}
                      onBestandenChange={(bestanden) => {
                        muteerBestanden((x) => bestanden);
                      }}
                      weergaveFocus={EWeergaveFocus.Bestand}
                      bestandenMuterenToegestaan={!isBezigMetUploaden}
                      geenBestandenWeergaveComponent={() => <span>Geen bijlagen gekoppeld</span>}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        }
      />
    </div>
  );
};

export default GekoppeldeBijlagen;
