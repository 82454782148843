import { addDays } from 'date-fns';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/service';
import {
  IOphalenOpdrachtenResult,
  IOphalenOpdrachtenResultElement,
} from '../../../../../shared/src/api/v2/service/opdracht';
import {
  IOphalenWerkbonnenResultElement,
  IToevoegenWerkbonParams,
  IWijzigenWerkbonParams,
} from '../../../../../shared/src/api/v2/service/werkbon';
import api from '../../../api';
import Dialoog from '../../dialogen/Dialoog';
import BedragInput from '../../formulier/BedragInput';
import DatumKiezer from '../../formulier/DatumKiezer';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import MultiCombobox, { IKolom } from '../../formulier/MultiCombobox';
import VinkVeld from '../../formulier/VinkVeld';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import IDialoogProps from '../../../core/IDialoogProps';
import nameOf from '../../../core/nameOf';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import { OpdrachtenContext } from '../../../paginas/Service/Opdrachten';
import { EBtwsoort, ERegelstatusVordering } from '../../../bedrijfslogica/enums';
import ServiceopdrachtSelectieDialoog, {
  defaultSelecterenValues,
  IServiceopdrachtSelectieDialoogResult,
} from '../ServiceopdrachtSelectieDialoog';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import { observer } from 'mobx-react-lite';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import { IOphalenBtwTarievenResultElement } from '../../../../../shared/src/api/v2/btw';

interface IDialoogResult {
  factRegID?: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  serviceopdracht: IOphalenOpdrachtenResultElement | null;
  werkbonID: number | null;
  bezoekdatum?: Date;
  werkzaamheden?: string;
  kostenTotaal?: number;
  kostenDoorberekenen?: boolean;
  opdrachtAfgehandeld?: boolean;
  kostenDoorberekend?: boolean;
  servDienstID?: number;
  opdrachtnummer?: string;
  referentiecode?: string;
  merknaam?: string;
  typenaam?: string;
  postcode?: string;
  bronBestandID?: number | null;
  infoBestandID?: number | null;
}

interface IFormikValues {
  bezoekdatum: Date | null;
  werkzaamheden: string;
  kostenTotaal: number;
  servDienstID: number | null;
  servOpdID: number | null;
  kostenDoorberekenen: boolean;
  opdrachtAfgehandeld: boolean | null;
  kostenDoorberekend: boolean;
  standaardkostenBerekenen: boolean;
  isBeoordeeld: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  servOpdID: 'Serviceopdracht',
  bezoekdatum: 'Bezoekdatum',
  werkzaamheden: 'Werkzaamheden',
  kostenTotaal: 'Tot. kosten (exc. btw)',
  servDienstID: 'Servicedienst',
  kostenDoorberekenen: 'Kosten doorberekenen',
  opdrachtAfgehandeld: 'Rep.opdracht als afgehandeld markeren',
  kostenDoorberekend: 'Doorberekend',
  standaardkostenBerekenen: 'Standaardkosten ipv reeele kosten',
  isBeoordeeld: 'Beoordeeld op doorberekenen',
};

const WerkbonMutatieDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  // const diensten = opdrachtenContext.diensten;
  const [werkbon, setWerkbon] = useState<IOphalenWerkbonnenResultElement | null>(null);
  const [btwTarief, setBtwTarief] = useState<IOphalenBtwTarievenResultElement | null>(null);

  const ophalenWerkbon = useCallback(async () => {
    if (props.werkbonID === null) {
      return;
    }

    const werkbon = (
      await api.v2.service.ophalenWerkbonnen({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.werkbonID] }],
        },
      })
    ).werkbonnen[0];

    setWerkbon(werkbon);
  }, [props.werkbonID]);

  useEffect(() => {
    ophalenWerkbon();
  }, [ophalenWerkbon]);

  const ophalenBtwTarief = useCallback(async () => {
    const btwTarievenResult = await api.v2.btw.ophalenBtwTarieven({
      filterSchema: {
        filters: [
          { naam: 'NAAM_ENUM', data: [EBtwsoort.AfdragenHoog] },
          { naam: 'PEILDATUM', data: null },
        ],
      },
    });
    setBtwTarief(btwTarievenResult.btwTarieven[0]);
  }, []);

  useEffect(() => {
    ophalenBtwTarief();
  }, [ophalenBtwTarief]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (props.werkbonID !== null && werkbon === null) {
      return null;
    }
    // Is het een nieuwe werkbon of een bestaande?
    if (props.werkbonID === null) {
      // Nieuwe werkbon
      // De service dienst is de dienst van de serviceopdracht als deze bekend is, tenzij het een hoofdaannemer is.
      const servDienstID =
        props.servDienstID ??
        (props.serviceopdracht === null
          ? null
          : !props.serviceopdracht.dienst.IsHoofdaannemer
          ? props.serviceopdracht!.dienst.ID
          : null);

      return {
        servOpdID: props.serviceopdracht?.ServOpdID ?? null,
        bezoekdatum: props.bezoekdatum ?? null,
        werkzaamheden: props.werkzaamheden ?? '',
        kostenTotaal: props.kostenTotaal ?? 0,
        servDienstID,
        kostenDoorberekenen: props.kostenDoorberekenen ?? false,
        opdrachtAfgehandeld:
          props.opdrachtAfgehandeld ?? props.serviceopdracht === null
            ? null
            : !props.serviceopdracht.Afgehandeld
            ? true
            : null,
        kostenDoorberekend: props.kostenDoorberekend ?? false,
        standaardkostenBerekenen: false,
        isBeoordeeld: false,
      };
    } else {
      // Wijzigen werkbon

      return {
        servOpdID: werkbon!.ServOpdID,
        bezoekdatum: werkbon!.Bezoekdatum !== null ? new Date(werkbon!.Bezoekdatum) : null,
        werkzaamheden: werkbon!.Werkzaamheden ?? '',
        kostenTotaal: werkbon!.KostenTotaal,
        servDienstID: werkbon!.ServDienstID,
        kostenDoorberekenen: werkbon!.KostenDoorberekenen,
        opdrachtAfgehandeld: null,
        kostenDoorberekend:
          werkbon!.KostenDoorberekend !== null ? werkbon!.KostenDoorberekend : false,
        standaardkostenBerekenen: false,
        isBeoordeeld: werkbon!.IsBeoordeeld,
      };
    }
  }, [
    props.servDienstID,
    props.serviceopdracht,
    props.werkbonID,
    werkbon,
    props.bezoekdatum,
    props.werkzaamheden,
    props.kostenTotaal,
    props.kostenDoorberekenen,
    props.opdrachtAfgehandeld,
    props.kostenDoorberekend,
  ]);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.servOpdID === null) {
      errors.servOpdID = 'Verplicht';
    }
    if (values.werkzaamheden.trim().length === 0) {
      errors.werkzaamheden = `Verplicht`;
    }
    if (values.bezoekdatum === null) {
      errors.bezoekdatum = `Verplicht`;
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (btwTarief === null) {
        return;
      }

      actions.setSubmitting(true);

      if (props.werkbonID === null) {
        // Nieuwe werkbon

        const params: IToevoegenWerkbonParams = {
          servOpdID: values.servOpdID!,
          bezoekdatum: values.bezoekdatum,
          werkzaamheden: values.werkzaamheden,
          kostenTotaal: values.kostenTotaal,
          servDienstID: values.servDienstID,
          kostenDoorberekenen: values.kostenDoorberekenen,
          kostenDoorberekend: values.kostenDoorberekenen ? values.kostenDoorberekend : null,
          bronBestandID: props.bronBestandID ?? null,
          infoBestandID: props.infoBestandID ?? null,
          isBeoordeeld: values.isBeoordeeld,
        };

        const checkData = await api.v2.service.checkToevoegenWerkbon(params);
        const checkResult = await checkStore.controleren({
          checkData,
        });
        if (checkResult.type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        if (
          (
            await checkStore.bevestigen({
              inhoud: (
                <span>
                  Werkbon vastleggen?
                  {values.kostenDoorberekenen && (
                    <span>
                      <br />
                      <br />
                      Er is aangegeven dat de{' '}
                      {values.standaardkostenBerekenen ? 'standaard' : 'reeele '}
                      kosten doorberekend moeten worden. Er wordt hiervoor direct een vordering
                      gemaakt met de status WOP.
                    </span>
                  )}
                </span>
              ),
            })
          ).type === EResultType.Annuleren
        ) {
          actions.setSubmitting(false);
          return;
        }

        const toevoegenResult = await api.v2.service.toevoegenWerkbon(params);

        if (values.opdrachtAfgehandeld) {
          // Eventueel de serviceopdracht als Afgehandeld markeren.
          await api.v2.service.wijzigenServiceopdrachtAfgehandeld({
            ids: [props.serviceopdracht!.ServOpdID],
            afgehandeld: true,
          });
        }

        if (values.kostenDoorberekenen) {
          // Eventueel direct een vordering maken tbv doorberekening kosten

          const result = await api.v2.service.doorberekenenKosten({
            werkbonID: toevoegenResult.ID,
            standaardkostenBerekenen: values.standaardkostenBerekenen,
          });

          // De kosten doorberekend worden inclusief BTW. De kosten die in de werkbon zijn exclusief
          // const kostenTotaalIncBtw = values.kostenTotaal * (1 + btwTarief.Percentage / 100);

          // const vorderingParams = {
          //   relID,
          //   bedrag: kostenTotaalIncBtw,
          //   omschrijving: 'Doorberekende kosten reparatie',
          //   cntID:
          //     props.serviceopdracht!.melding.contract !== null
          //       ? props.serviceopdracht!.melding.contract.CntID
          //       : null,
          //   regelstatus: ERegelstatusVordering.Ophouden,
          //   credit: false,
          //   periodeVan: null,
          //   periodeTot: null,
          //   grbRekID: null,
          // };
          // const vorderingResult = await api.v2.vordering.toevoegenVordering(vorderingParams);

          props.onSuccess({
            factRegID: result.factRegID,
          });
          actions.setSubmitting(false);
          return;
        }
      } else {
        if (werkbon === null) {
          return;
        }
        // Wijzigen werkbon
        const params: IWijzigenWerkbonParams = {
          werkbonID: props.werkbonID,
          bezoekdatum: values.bezoekdatum,
          werkzaamheden: values.werkzaamheden,
          kostenTotaal: values.kostenTotaal,
          servDienstID: values.servDienstID,
          kostenDoorberekenen: values.kostenDoorberekenen,
          kostenDoorberekend: values.kostenDoorberekenen ? values.kostenDoorberekend : null,
          bronBestandID: props.bronBestandID ?? werkbon.bron?.ID ?? null,
          infoBestandID: props.infoBestandID ?? werkbon.info?.ID ?? null,
          isBeoordeeld: values.isBeoordeeld,
        };

        await api.v2.service.wijzigenWerkbon(params);
      }
      props.onSuccess({});

      actions.setSubmitting(false);
    },
    [werkbon, btwTarief, props.bronBestandID],
  );

  return initialValues === null ? (
    <LoadingSpinner />
  ) : (
    <Formik<IFormikValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
      render={(formikProps) => {
        return <WerkbonMutatieDialoogFormulier {...props} formikProps={formikProps} />;
      }}
    />
  );
});

const WerkbonMutatieDialoogFormulier = (
  props: IProps & { formikProps: FormikProps<IFormikValues> },
) => {
  const opdrachtenContext = useContext(OpdrachtenContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const formikProps = props.formikProps;
  const { submitForm, isSubmitting, values, isValid, errors } = formikProps;

  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);
  const ophalenDiensten = useCallback(async () => {
    const diensten = (
      await api.v2.dienst.service.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      })
    ).diensten;

    const dienstenGesorteerd = _.orderBy(
      diensten,
      [
        (x: IOphalenDienstenResultElement) => {
          return x.relatie!.weergavenaam;
        },
      ],
      ['asc'],
    );

    setDiensten(dienstenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenDiensten();
  }, [ophalenDiensten]);

  const opdrachtKolommen = useMemo<IKolom<IOphalenDienstenResultElement>[]>(() => {
    return [
      {
        key: '__relatie' as any,
        label: 'Naam',
        formatFabriek: (rij) => {
          return rij.relatie!.weergavenaam;
        },
        breedte: 200,
      },
      {
        key: 'IsHoofdaannemer' as any,
        label: 'Hoofdaanm.',
        formatFabriek: (rij) => {
          return rij.IsHoofdaannemer ? 'Ja' : 'Nee';
        },
        breedte: 100,
      },
      {
        key: 'Actief' as any,
        label: 'Actief',
        formatFabriek: (rij) => {
          return rij.Actief ? 'Ja' : 'Nee';
        },
        breedte: 80,
      },
    ];
  }, []);

  const [serviceopdracht, setServiceopdracht] = useState<
    IRemoteData<IOphalenOpdrachtenResultElement | null>
  >(createPendingRemoteData());
  useEffect(() => {
    if (values.servOpdID === null) {
      setServiceopdracht(createReadyRemoteData(null));
      return;
    }
    setServiceopdracht(createPendingRemoteData());
    api.v2.service
      .ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [values.servOpdID] }],
        },
      })
      .then((result: IOphalenOpdrachtenResult) => {
        const opdracht = result.opdrachten[0];
        setServiceopdracht(createReadyRemoteData(opdracht));
      });
  }, [values.servOpdID]);

  return (
    <>
      <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
        <ModalHeader>
          <ModalTitle>Werkbon {props.werkbonID === null ? 'toevoegen' : 'wijzigen'}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <label>{veldnamen.bezoekdatum}</label>
              <Field
                name="bezoekdatum"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <div className="d-sflex ">
                      <DatumKiezer
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        waarde={field.value}
                        isClearable
                        determineValidDate={(date) => {
                          return date <= addDays(new Date(), 0);
                        }}
                        determinePreviousValidDate="ONBEGRENST"
                        determineNextValidDate={(date) => {
                          const maxDate = addDays(new Date(), 0);
                          const newDate = addDays(date, +1);
                          if (newDate <= maxDate) {
                            return newDate;
                          }
                          return null;
                        }}
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </div>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <label>{veldnamen.werkzaamheden}</label>
              <Field
                name={nameOf<IFormikValues>('werkzaamheden')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <textarea {...field} className="form-control" rows={3} />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-6 align-items-left mt-3">
              <label>{veldnamen.kostenTotaal}</label>
              <Field
                name={nameOf<IFormikValues>('kostenTotaal')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <BedragInput
                        value={Math.abs(field.value) || 0}
                        onChange={(x) => {
                          form.setFieldValue(field.name, x);
                          if (x <= 0) {
                            form.setFieldValue(nameOf<IFormikValues>('kostenTotaal'), false);
                          }
                        }}
                        min={0}
                        max={+1000}
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            {props.werkbonID === null && (
              <>
                <div className="col-12 d-flex mt-3">
                  <div className="d-flex">
                    <Field
                      name={nameOf<IFormikValues>('kostenDoorberekenen')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <VinkVeld
                            aangevinkt={field.value}
                            onGewijzigd={(x) => {
                              form.setFieldValue(field.name, x);
                            }}
                          />
                        );
                      }}
                    />
                    <label className="ml-3">{veldnamen.kostenDoorberekenen}</label>
                  </div>
                  {formikProps.values.kostenDoorberekenen && (
                    <div className="d-flex" style={{ marginLeft: 20 }}>
                      <Field
                        name={nameOf<IFormikValues>('standaardkostenBerekenen')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => {
                                form.setFieldValue(field.name, x);
                              }}
                            />
                          );
                        }}
                      />
                      <label className="ml-3">{veldnamen.standaardkostenBerekenen}</label>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="col-12 mt-2">
              <label>{veldnamen.servDienstID}</label>
              <Field
                name={nameOf<IFormikValues>('servDienstID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (diensten === null) {
                    return <LoadingSpinner />;
                  }

                  return (
                    <>
                      <MultiCombobox<number, IOphalenDienstenResultElement>
                        sleutelExtractor={(x) => x.ID}
                        representatieFabriek={(x) => {
                          return x.relatie!.weergavenaam;
                        }}
                        waarde={field.value}
                        onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                        opties={diensten}
                        kolommen={opdrachtKolommen!}
                        isWisbaar
                        options={{
                          geenWaardeBericht: 'Kies een servicedienst',
                        }}
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <Field
                name={nameOf<IFormikValues>('isBeoordeeld')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        <VinkVeld
                          aangevinkt={field.value}
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        />
                        <span className="ml-2">{veldnamen.isBeoordeeld}</span>
                      </div>
                    </>
                  );
                }}
              />
            </div>

            {values.opdrachtAfgehandeld !== null && (
              <div className="col-12 d-flex mt-3">
                <Field
                  name={nameOf<IFormikValues>('opdrachtAfgehandeld')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => {
                          form.setFieldValue(field.name, x);
                        }}
                      />
                    );
                  }}
                />
                <label className="ml-3">{veldnamen.opdrachtAfgehandeld}</label>
              </div>
            )}

            <div className="col-12 mt-3 d-flex flex-column">
              <label>{veldnamen.servOpdID}</label>
              <Field
                name={nameOf<IFormikValues>('servOpdID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (serviceopdracht.state === ERemoteDataState.Pending) {
                    return <Skeleton width={150} />;
                  }

                  return (
                    <a
                      href="#"
                      onClick={async () => {
                        const result = await globaleRenderer.render<
                          IServiceopdrachtSelectieDialoogResult
                        >((renderProps) => (
                          <ServiceopdrachtSelectieDialoog
                            dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                            open
                            onSuccess={(result) => renderProps.destroy(result)}
                            onAnnuleren={() => renderProps.destroy()}
                            koppelOpties={{
                              defaultSelecterenValues: {
                                ...defaultSelecterenValues,
                                opdrachtnummer:
                                  props.opdrachtnummer ?? defaultSelecterenValues.opdrachtnummer,
                                referentiecode:
                                  props.referentiecode ?? defaultSelecterenValues.referentiecode,
                                postcode: props.postcode ?? defaultSelecterenValues.postcode,
                                typenaam: props.typenaam ?? defaultSelecterenValues.typenaam,
                              },
                            }}
                          />
                        ));
                        if (result !== undefined) {
                          form.setFieldValue(field.name, result.servOpdID);
                        }
                      }}
                    >
                      {serviceopdracht.data === null
                        ? 'Koppel serviceopdracht...'
                        : serviceopdracht.data!.melding.Meldnummer +
                          '-' +
                          serviceopdracht.data!.Volgnummer}
                    </a>
                  );
                }}
              />
            </div>
          </div>

          {/* {values.kostenDoorberekenen && (
                <div className="col-6 d-flex">
                  <Field
                    name={nameof<IFormikValues>('kostenDoorberekend')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <VinkVeld
                          aangevinkt={field.value}
                          onGewijzigd={(x) => {
                            form.setFieldValue(field.name, x);
                          }}
                        />
                      );
                    }}
                  />
                  <label className="ml-3">{veldnamen.kostenDoorberekend}</label>
                </div>
              )} */}
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-start">
          <button
            className="btn btn-primary"
            onClick={submitForm}
            style={{ width: 100 }}
            disabled={isSubmitting || !isValid}
          >
            Ok
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => props.onAnnuleren()}
            style={{ width: 100 }}
          >
            Annuleren
          </button>
        </ModalFooter>
      </Dialoog>
    </>
  );
};

export default WerkbonMutatieDialoog;
