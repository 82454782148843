import React, { useEffect, useMemo, useState } from 'react';
import { IOphalenGeslachtenResult } from '../../../../../shared/src/api/v2/geslacht';
import api from '../../../api';
import Combobox, { IOptie } from '../Combobox';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { IOphalenTalenResult } from '../../../../../shared/src/api/v2/taal';

export interface IOptions {
  standaardVoorselecteren: boolean;
}

interface IProps {
  taalID: number | null;
  onChange: (taalID: number | null) => void;
  options?: IOptions;
  disabled?: boolean;
}

const defaultOptions: IOptions = {
  standaardVoorselecteren: false,
};

const TaalSelectie: React.FC<IProps> = (props) => {
  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);
  const { children, taalID, onChange } = props;
  const [talen, setTalen] = useState<IOphalenTalenResult | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.taal.ophalen({ filterSchema: { filters: [] } });
      setTalen(result);
    })();
  }, []);

  useEffect(() => {
    if (taalID !== null || !options.standaardVoorselecteren) {
      return;
    }

    (async () => {
      const result = (
        await api.v2.taal.ophalen({
          filterSchema: { filters: [{ naam: 'NAAM_ENUMS', data: ['TAAL_N'] }] },
        })
      )[0];
      onChange(result.TaalID);
    })();
  }, []);

  const opties = useMemo<Array<IOptie<number>> | null>(() => {
    if (talen === null) {
      return null;
    }

    return talen.map((taal) => ({
      id: taal.TaalID,
      label: taal.Naam,
    }));
  }, [talen]);

  if (opties === null) {
    return <LoadingSpinner />;
  }

  return (
    <Combobox<number>
      geselecteerd={taalID}
      onSelectieChange={onChange}
      opties={opties}
      disabled={props.disabled}
    />
  );
};

export default TaalSelectie;
