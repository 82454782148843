import {
  IExporterenEntiteitenParams,
  IExporterenEntiteitenResult,
  IImporterenEntiteitenParams,
  IImporterenEntiteitenResult,
  IOphalenImportExportEntiteitenParams,
  IOphalenImportExportEntiteitenResult,
  IVoorbereidenImporterenEntiteitenParams,
  IVoorbereidenImporterenEntiteitenResult,
} from '../../../../../shared/src/api/v2/ImportExport';
import api from '../..';
const importExport = {
  ophalenImportExportEntiteiten: async (
    params: IOphalenImportExportEntiteitenParams,
  ): Promise<IOphalenImportExportEntiteitenResult> => {
    return await api.post('/v2/import-export/ophalen-import-export-entiteiten', params);
  },
  exporterenEntiteiten: async (
    params: IExporterenEntiteitenParams,
  ): Promise<IExporterenEntiteitenResult> => {
    return await api.post('/v2/import-export/exporteren-entiteiten', params);
  },
  voorbereidenImporterenEntiteiten: async (
    params: IVoorbereidenImporterenEntiteitenParams,
  ): Promise<IVoorbereidenImporterenEntiteitenResult> => {
    return await api.post('/v2/import-export/voorbereiden-importeren-entiteiten', params);
  },
  importerenEntiteiten: async (
    params: IImporterenEntiteitenParams,
  ): Promise<IImporterenEntiteitenResult> => {
    return await api.post('/v2/import-export/importeren-entiteiten', params);
  },
};

export default importExport;
