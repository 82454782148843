import React, { useContext, useEffect, useState } from 'react';
import api from '../../../../../../../../../api';
import { ISectieProps } from '../index';
import GegevensLayout from '../../../../../../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../../../../../../components/layout/VerticaleScheidingslijn';
import HorizontaleScheidingslijn from '../../../../../../../../../components/layout//HorizontaleScheidingslijn';
import { format } from 'date-fns';
import { formatteerBedrag } from '../../../../../../../../../helpers';
import ProductmodelInfoDialoog from '../../../../../../../../../components/productmodel/ProductmodelInfoDialoog';
import { EContractStatus } from '../ContractStatus';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import TariefactieInfoDialoog from '../../../../../../../../../components/Aanbod/Tarieven/Actie/InfoDialoog';
import { IconInformatie } from '../../../../../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../../../../../bedrijfslogica/constanten';

interface IProps extends ISectieProps {}

const Details: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const contract = props.contract!;

  const [restbedragProlongatie, setRestbedragProlongatie] = useState<number | null>(null);
  const [prodModID, setProdModID] = useState<number | null>(null);
  const [tariefactieInfoDialoogState, setTariefactieInfoDialoogState] = useState<{
    id: number;
  } | null>(null);

  useEffect(() => {
    (async () => {
      if (contract.prolongatie.Tot === null || contract.prolongatie.Eind === null) {
        return;
      }
      const params = {
        datumVan: contract.prolongatie.Tot,
        datumTot: contract.prolongatie.Eind,
        maandhuur: contract.basis.Maandhuur,
        maandhuurActie: contract.basis.actie !== null ? contract.basis.actie.Maandhuur : null,
        datumActieEind: contract.basis.actie !== null ? contract.basis.actie.DatumTot : null,
        gebruikerstoeslag: contract.basis.gebruikerstoeslag,
      };
      const result = await api.v2.prolongatie.berekenHuurprolongatie(params);

      setRestbedragProlongatie(result.huurProlongatie);
    })();
  }, [contract]);

  const eenmaligBedrag =
    formatteerBedrag(
      contract.basis.actie !== null && contract.basis.actie.EenmaligBedrag !== null
        ? contract.basis.actie.EenmaligBedrag
        : contract.basis.EenmaligBedrag,
    ) +
    (contract.basis.actie !== null && contract.basis.actie.EenmaligBedrag !== null
      ? ' (' + formatteerBedrag(contract.basis.EenmaligBedrag) + ')'
      : '');

  // const producten = contract.producten.map((x: any) => {
  //   return (
  //     <>
  //       <div style={{ width: 300 }}>
  //         <GegevensLayout
  //           gegevens={[
  //             {
  //               label: 'Type/merk',
  //               waarde: `${x.Typenaam} ${x.Merknaam}`,
  //             },
  //             {
  //               label: 'Soort',
  //               waarde: x.Productsoortnaam,
  //             },
  //             {
  //               label: 'Ref.code',
  //               waarde: x.Referentiecode,
  //             },
  //             {
  //               label: 'leeftijd',
  //               waarde: `${x.leeftijd !== null ? maandenNaarJaren(x.leeftijd) : ''}`,
  //             },
  //             {
  //               label: 'Locatie',
  //               waarde: `${
  //                 x.locatie !== null
  //                   ? formatteerAdresV2({
  //                       bisnummer: x.locatie.Bisnummer,
  //                       straatnaam: x.locatie.Straatnaam,
  //                       huisnummer: x.locatie.Huisnummer,
  //                       postcode: x.locatie.Postcode,
  //                       plaatsnaam: x.locatie.Plaatsnaam,
  //                     })
  //                   : ''
  //               }`,
  //             },
  //           ]}
  //         />
  //       </div>
  //       <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
  //         <VerticaleScheidingslijn height={80} />
  //       </div>
  //     </>
  //   );
  // });

  const { klantkaartStore } = useContext(RootStoreContext);
  klantkaartStore.ophalenRelatie(contract.RelID);
  const factuurniveau = klantkaartStore.relatie!.debiteur!.FactuurNiveau;

  // Rekening bepalen (Afwijkend of Standaard?)
  let rekening: { iban: string; rekeningnaam: string; afwijkend: boolean } | null = null;
  if (contract.rekening !== null) {
    rekening = {
      iban: contract.rekening.IBAN,
      rekeningnaam: contract.rekening.Rekeningnaam,
      afwijkend: true,
    };
  } else {
    const rekeningen = klantkaartStore.relatie!.financieel.rekeningen;
    const standaardRelRekID = klantkaartStore.relatie!.financieel.StandaardRekening_RelRekID;
    const standaardRekening = rekeningen.find((x) => x.RelRekID === standaardRelRekID) ?? null;
    rekening =
      standaardRekening !== null
        ? {
            iban: standaardRekening.IBAN,
            rekeningnaam: standaardRekening.Rekeningnaam,
            afwijkend: false,
          }
        : null;
  }

  // const restbedrag: IGegeven | null =
  //   restbedragProlongatie !== null
  //     ? {
  //         label: 'Restbedrag huur',
  //         waarde: formatteerBedrag(restbedragProlongatie),
  //       }
  //     : null;

  return (
    <>
      <div className="d-flex pt-2 pb-3 pl-4 pr-4">
        <div>
          {contract !== null ? (
            <div>
              <div className="d-flex">
                <div style={{ minWidth: 135 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Maandhuur',
                        waarde: `${formatteerBedrag(contract.basis.Maandhuur)}`,
                      },
                      {
                        label: 'Gebr.toeslag',
                        waarde: `${formatteerBedrag(contract.basis.gebruikerstoeslag)}`,
                      },
                      {
                        label: 'Eenmalig',
                        waarde: `${eenmaligBedrag}`,
                      },
                      null,
                      {
                        label: 'Actienr',
                        waarde:
                          contract.basis.actie !== null ? (
                            contract.basis.actie.TarActieID !== null ? (
                              <>
                                {' '}
                                <span className="mr-1">
                                  {contract.basis.actie.tariefactie!.Nummer}
                                </span>
                                <span>
                                  {' '}
                                  <a
                                    href="#"
                                    onClick={() =>
                                      setTariefactieInfoDialoogState({
                                        id: contract.basis.actie!.TarActieID!,
                                      })
                                    }
                                  >
                                    <IconInformatie
                                      style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                                    />
                                  </a>
                                </span>
                              </>
                            ) : (
                              <>Onbekend</>
                            )
                          ) : (
                            <span>Geen</span>
                          ),
                      },
                      {
                        label: 'Actiehuur',
                        waarde: `${
                          contract.basis.actie !== null && contract.basis.actie.Maandhuur !== null
                            ? `${formatteerBedrag(contract.basis.actie.Maandhuur)} ${
                                contract.basis.actie.Actieduur !== 0
                                  ? ' (' + contract.basis.actie.Actieduur + ' mnd)'
                                  : ''
                              }`
                            : ''
                        }`,
                      },
                      {
                        label: 'Actie tot',
                        waarde: `${
                          contract.basis.actie !== null && contract.basis.actie.Maandhuur !== null
                            ? contract.basis.actie.DatumTot !== null
                              ? format(new Date(contract.basis.actie.DatumTot), 'dd-MM-yyyy')
                              : 'Looptijd'
                            : ''
                        }`,
                      },
                      // {
                      //   label: 'Actienr',
                      //   waarde: (
                      //     <>
                      //       {contract.basis.actie !== null &&
                      //       contract.basis.actie.TarActieID !== null
                      //         ? contract.basis.actie.tariefactie!.Nummer
                      //         : ''}
                      //       <span>
                      //         {' '}
                      //         <a
                      //           href="#"
                      //           onClick={() => setTariefactieInfoDialoogState({ id: 10 })}
                      //         >
                      //           <IconInformatie
                      //             style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                      //           />
                      //         </a>
                      //       </span>
                      //     </>
                      //   ),
                      // },
                    ]}
                  />
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={150} />
                </div>

                <div style={{ minWidth: 145 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Prol. vanaf',
                        waarde:
                          contract.prolongatie.Start === null
                            ? ''
                            : format(new Date(contract.prolongatie.Start), 'dd-MM-yyyy'),
                      },
                      {
                        label: 'Geprol. tot',
                        waarde:
                          contract.prolongatie.Tot === null
                            ? ''
                            : format(new Date(contract.prolongatie.Tot), 'dd-MM-yyyy'),
                      },
                      {
                        label: 'Prol. eind',
                        waarde:
                          contract.prolongatie.Eind === null
                            ? ''
                            : format(new Date(contract.prolongatie.Eind), 'dd-MM-yyyy'),
                      },
                      null,
                      {
                        label: 'Restbedrag',
                        waarde: `${
                          restbedragProlongatie !== null
                            ? formatteerBedrag(restbedragProlongatie)
                            : '-'
                        }`,
                      },
                      {
                        label: 'Kosten VTB',
                        waarde: `${
                          contract.KostenVtb !== null ? formatteerBedrag(contract.KostenVtb) : '-'
                        }`,
                      },
                    ]}
                  />
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={125} />
                </div>

                <div style={{ minWidth: 120 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Aantal gebr.',
                        waarde: `${contract.basis.AantalGebruikers}`,
                      },
                      {
                        label: 'Max. tarief',
                        waarde: `${contract.basis.AantalGebruikersTarief}`,
                      },
                      {
                        label: 'Max. toegest.',
                        waarde: `${contract.basis.AantalGebruikersMax}`,
                      },
                      null,
                      {
                        label: 'Primo lev.',
                        waarde: `${
                          contract.basis.DatumGeleverd !== null
                            ? format(new Date(contract.basis.DatumGeleverd), 'dd-MM-yyyy')
                            : '-'
                        }`,
                      },
                      {
                        label: 'Ultimo ret.',
                        waarde: `${
                          contract.basis.DatumRetour !== null
                            ? format(new Date(contract.basis.DatumRetour), 'dd-MM-yyyy')
                            : '-'
                        }`,
                      },
                    ]}
                  />
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={125} />
                </div>
                <div style={{ minWidth: 150 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Min. einddatum',
                        waarde: (
                          <span>{format(new Date(contract.minimaleEinddatum), 'dd-MM-yyyy')}</span>
                        ),
                      },
                      contract.AangebodenOvernameOp !== null
                        ? {
                            label: 'Aangeboden op',
                            waarde: (
                              <span>
                                {format(
                                  new Date(contract.AangebodenOvernameOp),
                                  'dd-MM-yyyy HH:mm',
                                )}
                              </span>
                            ),
                          }
                        : null,

                      contract.status.NaamEnum === EContractStatus.Beeindigd
                        ? {
                            label: 'Reden beeindiging',
                            waarde:
                              contract.redenBeeindiging !== null
                                ? contract.redenBeeindiging.Naam
                                : 'Niet opgegeven',
                          }
                        : contract.status.NaamEnum === EContractStatus.Geannuleerd
                        ? {
                            label: 'Reden annulering',
                            waarde:
                              contract.redenAnnulering !== null
                                ? contract.redenAnnulering.Naam
                                : 'Niet opgegeven',
                          }
                        : contract.status.NaamEnum === EContractStatus.Afgekeurd
                        ? {
                            label: 'Reden afgekeurd',
                            waarde:
                              contract.redenAfkeuring !== null
                                ? contract.redenAfkeuring.Naam
                                : 'Niet opgegeven',
                          }
                        : contract.status.NaamEnum === EContractStatus.Overgenomen
                        ? {
                            label: 'Reden Overname',
                            waarde:
                              contract.redenOverdracht !== null
                                ? contract.redenOverdracht.Naam
                                : 'Niet opgegeven',
                          }
                        : contract.status.NaamEnum === EContractStatus.Lopend
                        ? null
                        : null,
                    ]}
                  />
                </div>
              </div>

              <div className="mb-3 mt-3">
                <HorizontaleScheidingslijn />
              </div>

              <div className="d-flex">
                <div style={{ width: 280 }}>
                  {rekening !== null ? (
                    <>
                      {/* {rekening.afwijkend ? (
                        <div>Afwijkende rekening:</div>
                      ) : (
                        <div>Standaard rekening:</div>
                      )} */}

                      <GegevensLayout
                        gegevens={[
                          {
                            label: `IBAN`,
                            waarde: `${rekening !== null ? `${rekening.iban}` : ``}`,
                          },
                          {
                            label: `Naam`,
                            waarde: `${rekening !== null ? `${rekening.rekeningnaam}` : ''}`,
                          },
                          {
                            label: 'Factuurkenmerk',
                            waarde: `${
                              contract.factuurkenmerk !== null
                                ? `${contract.factuurkenmerk.Naam}`
                                : 'Niet opgegeven'
                            }`,
                          },
                          // {
                          //   label: 'Fact.kenmerk',
                          //   waarde: `${
                          //     contract.factuurkenmerk !== null
                          //       ? `${contract.factuurkenmerk.Kenmerk}`
                          //       : 'Niet opgegeven'
                          //   }`,
                          // },
                        ]}
                      />
                    </>
                  ) : (
                    <span>Er is geen rekening bekend</span>
                  )}
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={65} />
                </div>

                <div style={{ minWidth: 230 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Interne locatie',
                        waarde:
                          contract.basis.interneLocatie !== null
                            ? contract.basis.interneLocatie.Naam
                            : null,
                      },
                      {
                        label: 'Verdieping',
                        waarde:
                          contract.basis.locatieniveau !== null
                            ? contract.basis.locatieniveau.Naam
                            : null,
                      },
                      {
                        label: 'Trekschakelaar',
                        waarde:
                          contract.basis.AansluitenTrekschakelaar !== null &&
                          contract.basis.AansluitenTrekschakelaar
                            ? 'Ja'
                            : 'Nee',
                      },
                    ]}
                  />
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={65} />
                </div>

                <div style={{ minWidth: 225 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Geregistreerd',
                        waarde:
                          contract.RecordToegevoegd !== null
                            ? format(new Date(contract.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                            : 'Niet vastgelegd',
                      },
                      {
                        label: 'Bevestigd',
                        waarde:
                          contract.DatumBevestigd !== null
                            ? format(new Date(contract.DatumBevestigd), 'dd-MM-yyyy HH:mm')
                            : 'Nee',
                      },
                      {
                        label: 'Gefiatteerd',
                        waarde:
                          contract.DatumGefiatteerd !== null
                            ? format(new Date(contract.DatumGefiatteerd), 'dd-MM-yyyy HH:mm')
                            : 'Nee',
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>Geen contract</div>
          )}
        </div>
      </div>
      {prodModID !== null && (
        <ProductmodelInfoDialoog
          open
          onSuccess={() => setProdModID(null)}
          onAnnuleren={() => setProdModID(null)}
          prodModID={prodModID}
        />
      )}
      {tariefactieInfoDialoogState !== null && (
        <TariefactieInfoDialoog
          open
          onSuccess={() => setTariefactieInfoDialoogState(null)}
          onAnnuleren={() => setTariefactieInfoDialoogState(null)}
          id={tariefactieInfoDialoogState.id}
        />
      )}
    </>
  );
};

export default Details;
