import { SortableHandle } from 'react-sortable-hoc';
import { IconDragIndicator } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import React from 'react';

const DragHandle = SortableHandle(({ style }: any) => (
  <span style={{ ...style, ...{ cursor: 'grab' } }}>
    <IconDragIndicator style={{ width: 17, height: 17, fill: Kleur.Grijs }} />
  </span>
));

export default DragHandle;
