import React, { useContext, useMemo, useRef, useState } from 'react';
import {
  CenterContainer,
  NewContactContainer,
  NoConversationMessageContainer,
  Root,
} from './style';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { IContact, IConversation, IMessage, INewContact } from '../index';
import api from '../../../../api';
import BerichtSamensteller, { BeschikbareContextenSelectie } from '../../BerichtSamensteller';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconSend } from '../../../Icons';
import ConversatieSectie, {
  Bericht,
  ERichting,
  IComponentInterface,
} from '../../ConversatieSectie';
import BerichtComponent from './BerichtComponent';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';

export class SmsBericht implements Bericht {
  public base: IMessage;

  constructor(base: IMessage) {
    this.base = base;
  }

  public getId(): number | string {
    return this.base.id;
  }

  public getDatum(): Date {
    return new Date(this.base.time);
  }

  public getRichting(): ERichting {
    return this.base.isIncoming ? ERichting.Inkomend : ERichting.Uitgaand;
  }
}

interface IProps {
  conversation: IConversation | null;
  selectedContactNumber: string | null;
  message: string;
  onMessageChange: (value: string) => void;
}

const ConversationSection: React.FC<IProps> = observer((props) => {
  const { persoonStore } = useContext(RootStoreContext);
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const conversatieSectieComponentInterface = useRef<IComponentInterface>(null);

  const isNewContact = useMemo(() => {
    if (props.conversation === null) {
      return null;
    }
    const contact = props.conversation.contact;

    return (contact as IContact).id === undefined;
  }, [props.conversation]);

  const berichten = useMemo(() => {
    if (props.conversation === null) {
      return null;
    }
    return props.conversation.messages.map((x) => new SmsBericht(x));
  }, [props.conversation?.messages]);

  const persoon = useMemo(() => {
    if (props.conversation === null || props.conversation.contact.persID === null) {
      return null;
    }
    return persoonStore.personen[props.conversation.contact.persID];
  }, [props.conversation?.contact.persID, persoonStore.personen]);

  return (
    <Root>
      {props.selectedContactNumber === null ? (
        <CenterContainer>
          <NoConversationMessageContainer>
            Selecteer een contact om te beginnnen.
          </NoConversationMessageContainer>
        </CenterContainer>
      ) : props.conversation === null ? (
        <CenterContainer>
          <LoadingSpinner />
        </CenterContainer>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {isNewContact ? (
            <NewContactContainer>
              Start de conversatie door een bericht te sturen.
            </NewContactContainer>
          ) : (
            <div
              className="d-flex flex-fill flex-column"
              style={{
                overflowY: 'hidden',
                overflowX: 'hidden',
                width: '100%',
              }}
            >
              <ConversatieSectie
                berichten={berichten!}
                berichtComponent={BerichtComponent}
                onComponentInterfaceChange={(x) => {
                  // @ts-ignore
                  conversatieSectieComponentInterface.current = x;
                }}
                containerStyle={{
                  backgroundColor: '#ececec',
                }}
              />
            </div>
          )}
          {/*<MessageComposer*/}
          {/*  persID={props.conversation.contact.persID}*/}
          {/*  value={message}*/}
          {/*  onChange={handleMessageComposerValueChange}*/}
          {/*  onHeightChange={handleMessageComposerHeightChange}*/}
          {/*  onVersturen={handleMessageComposerVersturen}*/}
          {/*/>*/}

          <div>
            <BerichtSamensteller
              tekst={props.message}
              maxKarakters={960}
              onTekstChange={(x) => props.onMessageChange(x)}
              onIndienen={async () => {
                if (props.conversation === null) {
                  return;
                }

                props.onMessageChange('');

                if (isNewContact) {
                  const contact = props.conversation.contact as INewContact;
                  // Nieuw contact
                  await api.v2.dienst.sms.versturenBijNieuwContact({
                    inhoud: props.message,
                    persID: contact.persID,
                    telefoonNummer: contact.phoneNumber,
                    tempId: contact.tempId,
                  });
                } else {
                  await api.v2.dienst.sms.versturenBericht({
                    // @ts-ignore
                    smsContactID: props.conversation.contact.id,
                    inhoud: props.message,
                  });
                }
              }}
              sjabloonData={{
                communicatieKanaalSelectie: {
                  naamEnum: 'SMS',
                },
                beschikbareContextenSelectie: [
                  props.conversation.contact.persID === null
                    ? null
                    : {
                        naamEnum: 'PERSOON',
                        data: {
                          persID: props.conversation.contact.persID,
                        },
                      },
                ].filter((x) => x !== null) as BeschikbareContextenSelectie,
              }}
              // disabled={!magBerichtVersturen}
              onHeightChange={() => conversatieSectieComponentInterface.current?.controleerScroll()}
              indienenKnopComponent={(indienenKnopProps) => {
                return (
                  <button
                    className="btn btn-sm btn-light"
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    onClick={indienenKnopProps.handleIndienenClick}
                    disabled={!indienenKnopProps.magIndienen}
                  >
                    <IconSend style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
                  </button>
                );
              }}
              taalID={persoon?.taal.TaalID ?? undefined}
            />
          </div>
        </div>
      )}
    </Root>
  );
});

export default ConversationSection;
