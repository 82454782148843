import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input, Representatie } from './style';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { IconKruis, IconUitklappen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Popover from 'react-bootstrap/Popover';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedDataTypeProvider,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Overlay from 'react-bootstrap/Overlay';
import ResizeObserver from 'react-resize-observer';
import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import InactiefOverlay from '../../InactiefOverlay';

export interface IKolom<TEntity> {
  key: keyof TEntity;
  label: string | JSX.Element;
  breedte: number;
  formatFabriek?: (entiteit: TEntity) => string | number | null | undefined | JSX.Element;
}

export interface IOptions<TIdentifier, TEntity> {
  popoverComp?: React.ComponentType<PropsWithChildren<{}>>;
  toevoegingLinks?: JSX.Element;
  toevoegingRechts?: JSX.Element;
  geenWaardeBericht?: string | JSX.Element;
  // Wordt toegepast als er wel een id is opgegeven, maar deze niet in de lijst voorkomt
  // (e.g. een gefilterde lijst)
  // Gebruikt dan deze functie om alsnog het element te bepalen door e.g. een api call te maken
  entiteitBepaler?: (id: TIdentifier) => Promise<TEntity | null>;
}

interface IProps<TIdentifier, TEntity> {
  /**
   * Extraheert de sleutel waarde uit de entiteit
   * @param entiteit
   */
  sleutelExtractor: (entiteit: TEntity) => TIdentifier;
  /**
   * Extraheert een respresentatie van het geselecteerde entiteit voor weergave in de input
   * @param entiteit
   */
  representatieFabriek: (entiteit: TEntity) => string | JSX.Element | null | undefined | number;
  /**
   * De huidige waarde die geselecteerd is
   */
  waarde: TIdentifier | null;
  /**
   * Notificatie functie wanneer de gebruiker van selectie heeft gewijzigd
   * @param waarde
   */
  onWaardeChange: (waarde: TIdentifier | null) => void;
  /**
   * De mogelijke opties
   */
  opties: Array<TEntity> | null;
  /**
   * De kolommen die getoond moeten worden
   */
  kolommen: Array<IKolom<TEntity>>;
  /**
   * Voegt een wis knop toe om het veld te kunnen legen
   */
  isWisbaar?: boolean;
  disabled?: boolean;
  /**
   * Extra customisatie mogelijkheden
   */
  options?: IOptions<TIdentifier, TEntity>;
  onTonenChanged?: (tonen: boolean) => void;
  sorterenToestaan?: boolean;
}

const defaultOptions: IOptions<any, any> = {
  popoverComp: (props: PropsWithChildren<any>) => <>{props.children}</>,
  geenWaardeBericht: (
    <span className="font-italic" style={{ fontSize: 13, color: Kleur.Grijs }}>
      Maak een keuze
    </span>
  ),
};

interface IBepaaldEntiteit<TIdentifier, TEntity> {
  id: TIdentifier;
  entiteit: TEntity | null;
}

const MultiCombobox = <TIdentifier extends number | string, TEntity extends any>(
  props: IProps<TIdentifier, TEntity>,
) => {
  const {
    kolommen,
    opties,
    representatieFabriek,
    sleutelExtractor,
    waarde,
    onWaardeChange,
    isWisbaar,
    disabled,
  } = props;

  const [tonen, setTonen] = useState(false);
  useEffect(() => {
    if (props.onTonenChanged === undefined) {
      return;
    }
    props.onTonenChanged(tonen);
  }, [tonen, props.onTonenChanged]);
  const [bepaaldEntiteit, setBepaaldEntiteit] = useState<IBepaaldEntiteit<
    TIdentifier,
    TEntity
  > | null>(null);

  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);
  const entiteit: TEntity | null = useMemo(() => {
    if (waarde === null) {
      return null;
    }
    if (bepaaldEntiteit !== null && bepaaldEntiteit.id === waarde) {
      return bepaaldEntiteit.entiteit;
    }
    if (opties === null) {
      return null;
    }

    return opties.find((optie) => sleutelExtractor(optie) === waarde) || null;
  }, [sleutelExtractor, waarde, opties, bepaaldEntiteit]);

  const gridKolommen = useMemo<TypedColumn<TEntity>[]>(() => {
    return kolommen.map((kolom) => ({
      name: kolom.key,
      title: kolom.label === '' ? ' ' : (kolom.label as any),
    }));
  }, [kolommen]);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<TEntity>[]>(() => {
    return kolommen.map((kolom) => ({
      columnName: kolom.key,
      width: kolom.breedte,
    }));
  }, [kolommen]);

  const dataTypeProviders = useMemo(() => {
    return kolommen
      .filter((kolom) => kolom.formatFabriek !== undefined)
      .map((kolom, i) => (
        <TypedDataTypeProvider<TEntity>
          key={i}
          for={[kolom.key]}
          formatterComponent={(props) => kolom.formatFabriek!(props.row) as any}
        />
      ));
  }, [kolommen]);

  const handleSelection = useCallback(
    (id: TIdentifier | null) => {
      onWaardeChange(id);
      setBepaaldEntiteit(
        id === null
          ? null
          : {
              id,
              entiteit: props.opties!.find((x) => props.sleutelExtractor(x) === id)!,
            },
      );
      setTonen(false);
      // document.body.click();
    },
    [onWaardeChange, props.opties, props.sleutelExtractor],
  );

  useEffect(() => {
    if (
      props.waarde === null ||
      options.entiteitBepaler === undefined ||
      (props.opties !== null && props.opties.some((x) => props.sleutelExtractor(x) === waarde))
    ) {
      return;
    }

    (async () => {
      const result = await options.entiteitBepaler!(props.waarde!);
      setBepaaldEntiteit({
        id: props.waarde!,
        entiteit: result,
      });
    })();
  }, [options.entiteitBepaler, props.waarde, props.opties, props.sleutelExtractor]);

  const PopoverComp = options.popoverComp!;
  const inputRef = useRef<HTMLDivElement>(null);

  return (
    <InactiefOverlay
      isInactief={disabled === true}
      element={
        <>
          <Overlay
            show={tonen}
            target={inputRef.current!}
            placement="auto"
            onHide={() => {
              setTonen(false);
            }}
            rootClose
          >
            <Popover
              id="multi-combobox-popover"
              style={{
                // maxWidth: kolommen.map((x) => x.breedte).reduce((acc, curr) => acc + curr, 15),
                minWidth: 'min-content',
              }}
              onClick={(ev) => ev.stopPropagation()}
            >
              <PopoverComp>
                {opties === null ? (
                  <LoadingSpinner />
                ) : (
                  <GridStyleWrapper maxHeight={500} rowAmount={opties.length} hiddenOverflowX>
                    <Grid
                      getRowId={sleutelExtractor as any}
                      columns={gridKolommen as any}
                      rows={opties}
                    >
                      {dataTypeProviders}
                      {props.sorterenToestaan && <SortingState defaultSorting={[]} />}
                      {props.sorterenToestaan && <IntegratedSorting />}
                      <VirtualTable
                        // estimatedRowHeight={43}
                        rowComponent={(props) => (
                          <tr
                            style={{ cursor: 'pointer' }}
                            onClick={(ev) => {
                              ev.stopPropagation();
                              ev.preventDefault();
                              handleSelection(props.tableRow.rowId as TIdentifier);
                              return false;
                            }}
                          >
                            {props.children}
                          </tr>
                        )}
                        messages={{
                          noData: 'Geen opties',
                        }}
                      />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
                      <TableHeaderRow showSortingControls={props.sorterenToestaan} />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </PopoverComp>
            </Popover>
          </Overlay>
          <Input
            ref={inputRef}
            onClick={(ev) => {
              setTonen((tonen) => !tonen);
            }}
          >
            {options.toevoegingLinks !== undefined && options.toevoegingLinks}
            <Representatie>
              {entiteit === null ? options.geenWaardeBericht : representatieFabriek(entiteit)}
            </Representatie>
            {options.toevoegingRechts !== undefined && options.toevoegingRechts}
            {isWisbaar && entiteit !== null && (
              <div
                style={{
                  width: 25,
                  position: 'relative',
                  top: 1,
                }}
                className="d-flex justify-content-center align-items-center mr-1"
                onClick={(ev) => {
                  ev.stopPropagation();
                  handleSelection(null);
                }}
              >
                <IconKruis style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
              </div>
            )}
            <div
              style={{
                borderLeft: `1px solid ${Kleur.LichtGrijs}`,
                width: 25,
                backgroundColor: Kleur.HeelLichtGrijs,
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <IconUitklappen style={{ width: 20, height: 20, fill: Kleur.Grijs }} />
            </div>
          </Input>
        </>
      }
    />
  );
};

export default MultiCombobox;
