import styled from 'styled-components';
import { EGrootte } from './index';

export const Root = styled.div`
  display: flex;
  background-color: #ffffff;
  font-size: 12px;
  width: 96px;
`;

export const BaseButton = styled.button`
  background: transparent;
  width: 100px;
  border-radius: 0;
  text-align: center;
  border: 1px solid #efefef;
  color: #505050;
  //border-right: 0;
  outline: 0;

  &:hover {
    background-color: #c4dcfe;
  }
`;

export const Button = BaseButton;

export const OpgepaktContainer = styled(BaseButton)<{ isEigenaar: boolean }>`
  background-color: ${(props) => (props.isEigenaar ? '#bbffca' : '#fed9c6')};

  &:hover {
    background-color: #d9ebfe;
  }
`;

export interface IToekennenKnopProps {
  grootte: EGrootte;
}

export const ToekennenKnop = styled.button<IToekennenKnopProps>`
  outline: 0;
  padding: ${(props) => (props.grootte === EGrootte.Normaal ? '3px' : '0')};
  border: 1px solid #efefef;
  background-color: #f6f5f5;
  //border-left: 0;

  &:hover {
    background-color: #d2d2d2;
  }
`;

export const GebruikersSelectieKnop = styled.button`
  &:hover {
    background-color: #eae9e9;
  }
`;
