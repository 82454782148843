import React from 'react';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';

interface IPropsIndicatie {
  status: number;
}

const kleurMap: { [status: string]: string } = {
  [0]: EKleur.Grijs,
  [1]: EKleur.Groen,
  [2]: EKleur.Geel,
  [3]: EKleur.Oranje,
  [4]: EKleur.Rood,
};

const letterMap: Record<number, string> = {
  0: '?',
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
};

const letterOffset: Record<number, [number, number]> = {
  3: [0, 0.5],
};

const uitlegMap: Record<number, string> = {
  [0]: 'Nog geen data beschikbaar',
  [1]: 'Zeer goed: Alles altijd op tijd betaald',
  [2]: 'Goed: laatste jaar 1 of 2 aanmaningen, geen dossiers',
  [3]: 'Matig: laatste jaar 3 of meer aanmaningen en/of ooit een dossier',
  [4]: 'Slecht: laatste jaar 5 of meer aanmaningen en/of tenminste een dossier en/of ooit 2 of meer dossiers',
};

export const BetaalgedragIndicatie: React.FC<IPropsIndicatie> = (props) => {
  const width = 18;
  const height = 18;

  // const width = 28;
  // const height = 22;

  // const topOffset = letterOffset[props.status] ? letterOffset[props.status][1] : 0;
  // const leftOffset = letterOffset[props.status] ? letterOffset[props.status][0] : 0;

  return (
    <div
      style={{
        minWidth: width,
        minHeight: height,
        maxWidth: width,
        maxHeight: height,
        backgroundColor: kleurMap[props.status],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
      }}
      title={uitlegMap[props.status]}
    >
      <span
        style={{
          color: EKleur.Wit,
          fontSize: 12,
          fontWeight: 'bold',
        }}
      >
        {letterMap[props.status]}
      </span>
    </div>
  );
};
