import api from '../../../index';
import {
  IOphalenTypenParametersParams,
  IOphalenTypenParametersResult,
  IToevoegenTypeParametersParams,
  IToevoegenTypeParametersResult,
  IVerwijderenTypenParametersParams,
  IVerwijderenTypenParametersResult,
  IWijzigenTypeParametersParams,
  IWijzigenTypeParametersResult,
} from '../../../../../../shared/src/api/v2/inkoop/parameters';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IOphalenAanmeldingStatussenParams,
  IOphalenAanmeldingStatussenResult,
  IOphalenAanmeldingenParams,
  IOphalenAanmeldingenResult,
  IToevoegenAanmeldingenParams,
  IToevoegenAanmeldingenResult,
  IVersturenAanmeldingenParams,
  IVersturenAanmeldingenResult,
  IVerwijderenAanmeldingenParams,
  IVerwijderenAanmeldingenResult,
  IWijzigenAanmeldingParams,
  IWijzigenAanmeldingResult,
} from '../../../../../../shared/src/api/v2/inkoop/retour';

const parameters = {
  ophalenAanmeldingen: async (
    params: IOphalenAanmeldingenParams,
  ): Promise<IOphalenAanmeldingenResult> => {
    return await api.post('v2/inkoop/retour/ophalen-aanmeldingen', params);
  },
  checkWijzigenAanmelding: async (params: IWijzigenAanmeldingParams): Promise<ICheckData> => {
    return await api.post('v2/inkoop/retour/check-wijzigen-aanmelding', params);
  },
  wijzigenAanmelding: async (
    params: IWijzigenAanmeldingParams,
  ): Promise<IWijzigenAanmeldingResult> => {
    return await api.post('v2/inkoop/retour/wijzigen-aanmelding', params);
  },
  checkVerwijderenAanmeldingen: async (
    params: IVerwijderenAanmeldingenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/inkoop/retour/check-verwijderen-aanmeldingen', params);
  },
  verwijderenAanmeldingen: async (
    params: IVerwijderenAanmeldingenParams,
  ): Promise<IVerwijderenAanmeldingenResult> => {
    return await api.post('v2/inkoop/retour/verwijderen-aanmeldingen', params);
  },
  checkVersturenAanmeldingen: async (params: IVersturenAanmeldingenParams): Promise<ICheckData> => {
    return await api.post('v2/inkoop/retour/check-versturen-aanmeldingen', params);
  },
  versturenAanmeldingen: async (
    params: IVersturenAanmeldingenParams,
  ): Promise<IVersturenAanmeldingenResult> => {
    return await api.post('v2/inkoop/retour/versturen-aanmeldingen', params);
  },
  ophalenAanmeldingStatussen: async (
    params: IOphalenAanmeldingStatussenParams,
  ): Promise<IOphalenAanmeldingStatussenResult> => {
    return await api.post('v2/inkoop/retour/ophalen-aanmelding-statussen', params);
  },
  toevoegenAanmeldingen: async (
    params: IToevoegenAanmeldingenParams,
  ): Promise<IToevoegenAanmeldingenResult> => {
    return await api.post('v2/inkoop/retour/toevoegen-aanmeldingen', params);
  },
};

export default parameters;
