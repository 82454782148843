import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';
import { IBulkbericht } from '../../../../../../shared/src/api/v2/bulkbericht';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import api, { IPaginatiePositie } from '../../../../api';
import { format } from 'date-fns';
import { IconToevoegen } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import ToevoegenDialoog from './ToevoegenDialoog';
import { IBulkberichtvoorkeur } from '../../../../../../shared/src/api/v2/bulkbericht/voorkeur';
import { IMaatwerkselectie } from '../../../../../../shared/src/api/v2/bulkbericht/maatwerkselectie';
import { IOntvangersselectie } from '../../../../../../shared/src/api/v2/bulkbericht/ontvangersselectie';
import { ISjabloon } from '../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { IOntvangerssoort } from '../../../../../../shared/src/api/v2/bulkbericht/ontvangerssoort';
import BulkberichtUitgeklapteRij from './BulkberichtUitgeklapteRij';

export type Kolom =
  | 'nummer'
  | 'datum'
  | 'voorkeur'
  | 'ontvangerssoort'
  | 'ontvangers'
  | 'aangeboden_voor_verzenden_op'
  | 'sjabloon';

interface IVoorkeuren {
  [voorkeurID: number]: IBulkberichtvoorkeur;
}

interface IMaatwerkselecties {
  [maatwerkselectieID: number]: IMaatwerkselectie;
}

interface IOntvangersselecties {
  [ontvangersselectieID: number]: IOntvangersselectie;
}

interface IOntvangerssoorten {
  [ontvangerssoortID: number]: IOntvangerssoort;
}

interface ISjablonen {
  [sjabloonID: number]: ISjabloon;
}

interface IData {
  berichten: { [index: number]: IBulkbericht };
  voorkeuren: IVoorkeuren;
  maatwerkselecties: IMaatwerkselecties;
  ontvangersselecties: IOntvangersselecties;
  ontvangerssoorten: IOntvangerssoorten;
  sjablonen: ISjablonen;
  totaalAantal: number;
}

export interface IBulkberichtenContext {
  data: IRemoteData<IData>;
  dataVerversen: () => Promise<void>;
}

export const BulkberichtenContext = React.createContext<IBulkberichtenContext>(null!);

const initielePaginatie: IPaginatiePositie = {
  index: 0,
  aantal: 50,
};

interface IProps extends RouteComponentProps {}

const Berichten = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  // tslint:disable-next-line:variable-name
  const [data, _setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef(data);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    _setData(data);
  }, []);

  const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);

  const keyExtractor = useCallback((item: IBulkbericht) => item.ID, []);
  const kolommen = useMemo<ASPKolom<Kolom, IBulkbericht>[]>(
    () => [
      {
        key: 'nummer',
        breedteType: EAspKolomBreedteType.Vast,
        label: 'Nummer',
        vasteBreedte: 85,
        renderer: (item) => item.Nummer,
      },
      {
        key: 'voorkeur',
        breedteType: EAspKolomBreedteType.Vast,
        label: 'Voorkeur',
        vasteBreedte: 250,
        renderer: (item) => {
          if (item.VoorkeurID === null) {
            return <span className="text-muted font-italic">Geen voorkeur toegepast</span>;
          }

          const voorkeur = dataRef.current.data!.voorkeuren[item.VoorkeurID];
          return <span>{voorkeur.Naam}</span>;
        },
      },
      {
        key: 'ontvangerssoort',
        breedteType: EAspKolomBreedteType.Vast,
        label: 'Ontvangerssoort',
        vasteBreedte: 200,
        renderer: (item) => {
          if (item.MaatwerkselectieID !== null) {
            const maatwerkselectie = dataRef.current.data!.maatwerkselecties[
              item.MaatwerkselectieID
            ];
            const ontvangerssoort = dataRef.current.data!.ontvangerssoorten[
              maatwerkselectie.OntvangerssoortID
            ];
            return <span>{ontvangerssoort.Naam}</span>;
          }

          if (item.OntvangersselectieID !== null) {
            const ontvangersselectie = dataRef.current.data!.ontvangersselecties[
              item.OntvangersselectieID
            ];
            const ontvangerssoort = dataRef.current.data!.ontvangerssoorten[
              ontvangersselectie.OntvangerssoortID
            ];
            return <span>{ontvangerssoort.Naam}</span>;
          }

          throw new Error(`Onbekende ontvangers voor bulkbericht met ID ${item.ID}`);
        },
      },
      {
        key: 'ontvangers',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        label: 'Ontvangers',
        renderer: (item) => {
          if (item.MaatwerkselectieID !== null) {
            return <span className="text-muted font-italic">Maatwerk selectie toegepast</span>;
          }

          if (item.OntvangersselectieID !== null) {
            const ontvangersselectie = dataRef.current.data!.ontvangersselecties[
              item.OntvangersselectieID
            ];
            return (
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                  display: 'block',
                }}
              >
                {ontvangersselectie.Naam}
              </span>
            );
          }

          throw new Error(`Onbekende ontvangers voor bulkbericht met ID ${item.ID}`);
        },
      },
      {
        key: 'sjabloon',
        breedteType: EAspKolomBreedteType.Vast,
        label: 'Sjabloon',
        vasteBreedte: 300,
        renderer: (item) => {
          const sjabloon = dataRef.current.data!.sjablonen[item.SjabID];

          return <span>{sjabloon.Naam}</span>;
        },
      },
      {
        key: 'aangeboden_voor_verzenden_op',
        breedteType: EAspKolomBreedteType.Vast,
        label: 'Aangeboden voor verzenden',
        vasteBreedte: 250,
        renderer: (item) =>
          item.IsAangebodenVoorVerzendenOp === null
            ? '- -'
            : format(new Date(item.IsAangebodenVoorVerzendenOp!), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: 'datum',
        breedteType: EAspKolomBreedteType.Vast,
        label: 'Datum',
        vasteBreedte: 150,
        renderer: (item) => format(new Date(item.RecordToegevoegd!), 'dd-MM-yyyy HH:mm'),
      },
    ],
    [],
  );
  const ophalenData = useCallback(async (positie: IPaginatiePositie, uitbreiden = false) => {
    const result = await api.v2.bulkbericht.ophalenBulkberichten({
      orderSchema: { orders: [{ naam: 'RECORD_TOEGEVOEGD', richting: 'DESC' }] },
      paginatie: positie,
    });

    const basis = uitbreiden ? dataRef.current!.data?.berichten ?? {} : {};
    const berichten = result.items.reduce((acc, item, i) => {
      const idx = positie.index + i;
      acc[idx] = item;
      return acc;
    }, basis);

    const voorkeurIDs = result.items.map((x) => x.VoorkeurID).filter((x) => x !== null) as number[];
    const maatwerkselectieIDs = result.items
      .map((x) => x.MaatwerkselectieID)
      .filter((x) => x !== null) as number[];
    const ontvangersselectieIDs = result.items
      .map((x) => x.OntvangersselectieID)
      .filter((x) => x !== null) as number[];
    const sjabloonIDs = result.items.map((x) => x.SjabID);

    const voorkeurenBasis = uitbreiden ? dataRef.current!.data?.voorkeuren ?? {} : {};
    const maatwerkselectiesBasis = uitbreiden ? dataRef.current!.data?.maatwerkselecties ?? {} : {};
    const ontvangersselectiesBasis = uitbreiden
      ? dataRef.current!.data?.ontvangersselecties ?? {}
      : {};
    const sjablonenBasis = uitbreiden ? dataRef.current!.data?.sjablonen ?? {} : {};

    const [voorkeuren, maatwerkselecties, ontvangersselectiesX, sjablonenX] = await Promise.all([
      (async () => {
        if (voorkeurIDs.length === 0) {
          return voorkeurenBasis;
        }

        const result = await api.v2.bulkbericht.voorkeur.ophalenBulkberichtVoorkeuren({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: voorkeurIDs,
              },
            ],
          },
        });

        return result.items.reduce<IVoorkeuren>((acc, voorkeur) => {
          acc[voorkeur.ID] = voorkeur;
          return acc;
        }, voorkeurenBasis);
      })(),
      (async () => {
        if (maatwerkselectieIDs.length === 0) {
          return maatwerkselectiesBasis;
        }

        const result = await api.v2.bulkbericht.maatwerkselectie.ophalenMaatwerkselecties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: maatwerkselectieIDs,
              },
            ],
          },
        });

        return result.items.reduce<IMaatwerkselecties>((acc, maatwerkselectie) => {
          acc[maatwerkselectie.ID] = maatwerkselectie;
          return acc;
        }, maatwerkselectiesBasis);
      })(),
      (async () => {
        if (ontvangersselectieIDs.length === 0) {
          return ontvangersselectiesBasis;
        }

        const result = await api.v2.bulkbericht.ontvangersselectie.ophalenOntvangersselecties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: ontvangersselectieIDs,
              },
            ],
          },
        });

        return result.items.reduce<IOntvangersselecties>((acc, ontvangersselectie) => {
          acc[ontvangersselectie.ID] = ontvangersselectie;
          return acc;
        }, ontvangersselectiesBasis);
      })(),
      (async () => {
        if (sjabloonIDs.length === 0) {
          return sjablonenBasis;
        }

        const result = await api.v2.sjabloon.ophalenSjablonen({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: sjabloonIDs,
              },
            ],
          },
        });

        return result.sjablonen.reduce<ISjablonen>((acc, sjabloon) => {
          acc[sjabloon.ID] = sjabloon;
          return acc;
        }, sjablonenBasis);
      })(),
    ]);

    const ontvangersselecties: IOntvangersselecties = ontvangersselectiesX as any;
    const sjablonen: ISjablonen = sjablonenX as any;

    const ontvangerssoortIDs = [
      ...new Set([
        ...Object.values(ontvangersselecties).map((x: IOntvangersselectie) => x.OntvangerssoortID),
        ...Object.values(maatwerkselecties).map((x: IMaatwerkselectie) => x.OntvangerssoortID),
      ]),
    ];
    const ontvangerssoortenResult = await api.v2.bulkbericht.ontvangerssoort.ophalenOntvangerssoorten(
      {
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: ontvangerssoortIDs,
            },
          ],
        },
      },
    );

    const ontvangerssoortenBasis = uitbreiden ? dataRef.current!.data?.ontvangerssoorten ?? {} : {};

    const ontvangerssoorten = ontvangerssoortenResult.items.reduce<IOntvangerssoorten>(
      (acc, ontvangerssoort) => {
        acc[ontvangerssoort.ID] = ontvangerssoort;
        return acc;
      },
      ontvangerssoortenBasis,
    );

    const nieuweData: IData = {
      totaalAantal: result.totaalAantal,
      berichten,
      voorkeuren,
      maatwerkselecties,
      ontvangersselecties,
      ontvangerssoorten,
      sjablonen,
    };
    setData(createReadyRemoteData(nieuweData));
  }, []);

  const dataVerversen = useCallback(async () => {
    await ophalenData(initielePaginatie);
  }, [ophalenData]);

  useEffect(() => {
    dataVerversen();
  }, [dataVerversen]);

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      await ophalenData(positie, true);
    },
    [ophalenData],
  );

  const handleToevoegenClick = useCallback(async () => {
    const output = await globaleRenderer.render((renderProps) => (
      <ToevoegenDialoog
        open
        onSuccess={(x) => renderProps.destroy(x)}
        onAnnuleren={() => renderProps.destroy()}
      />
    ));

    if (output === undefined) {
      return;
    }

    await dataVerversen();
  }, [dataVerversen]);

  const handleVerwijderenRij = useCallback(
    async (item: IBulkbericht) => {
      await api.v2.bulkbericht.verwijderenBulkberichten({
        ids: [item.ID],
      });

      await ophalenData(initielePaginatie);
    },
    [ophalenData],
  );

  const bulkberichtenContext = useMemo<IBulkberichtenContext>(
    () => ({
      data,
      dataVerversen: async () => {
        await ophalenData(initielePaginatie);
      },
    }),
    [data, ophalenData],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={handleToevoegenClick}
          >
            <IconToevoegen style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
            <span className="ml-2">Toevoegen</span>
          </button>
        </div>
      }
      body={
        <BulkberichtenContext.Provider value={bulkberichtenContext}>
          <ASPTabel
            keyExtractor={keyExtractor}
            kolommen={kolommen}
            rijen={data.data?.berichten ?? {}}
            totaalAantalRijen={data.data?.totaalAantal ?? 10}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
            onVerwijderenRij={handleVerwijderenRij}
            uitgeklapteRijComponent={BulkberichtUitgeklapteRij}
            uitgeklapt={uitgeklapt}
            onUitgeklaptChange={setUitgeklapt}
            uitgeklapteRijHoogte={800}
          />
        </BulkberichtenContext.Provider>
      }
    />
  );
};

export default Berichten;
