import React, { useMemo } from 'react';
import { getCanvasFont, getTextWidth } from '../../../helpers/tekst';

export interface IFontInfo {
  fontFamily: string;
  fontSize: number | string;
  fontWeight: string;
}

export interface IFontBepaler {
  bepaler: () => IFontInfo;
}

interface IProps {
  tekst: string;
  width: number;
  maxLines?: number;
  fontBepaler?: IFontBepaler;
  outerCss?: React.CSSProperties;
  innerCss?: React.CSSProperties;
}

const NA_ELLIPSIS_AANTAL_KARAKTERS = 7;

const TekstOverflowCenterEllipsis = (props: IProps) => {
  const [element, setElement] = React.useState<HTMLElement | null>(null);

  if (props.fontBepaler !== undefined) {
    return <Inner {...props} />;
  }

  return (
    <span ref={(el) => setElement(el)} style={props.outerCss}>
      {element && <Inner {...props} element={element} />}
    </span>
  );
};

const Inner = (props: IProps & { element?: HTMLElement }) => {
  const fontBepaler = useMemo(() => {
    return (
      props.fontBepaler ?? {
        bepaler: () => {
          const x = getCanvasFont(props.element!);
          return {
            fontFamily: x.fontFamily,
            fontWeight: x.fontWeight,
            fontSize: x.fontSize,
          };
        },
      }
    );
  }, [props.fontBepaler]);

  const font = useMemo(() => {
    const fontInfo = fontBepaler.bepaler();

    const fontSize =
      typeof fontInfo.fontSize === 'string' ? fontInfo.fontSize : `${fontInfo.fontSize}px`;

    return `${fontInfo.fontWeight} ${fontSize} ${fontInfo.fontFamily}`;
  }, [fontBepaler]);

  const ellipsisBreedte = useMemo(() => {
    return getTextWidth('...', font);
  }, []);

  const karakters = useMemo(() => {
    return props.tekst.split('');
  }, [props.tekst]);
  const karakterBreedtes = useMemo(() => {
    return karakters.map((character) => getTextWidth(character, font));
  }, []);

  const tekstBreedte = useMemo(() => karakterBreedtes.reduce((acc, curr) => acc + curr, 0), [
    karakterBreedtes,
  ]);

  const maxLines = useMemo(() => props.maxLines ?? 1, [props.maxLines]);

  const tekst = useMemo(() => {
    if (tekstBreedte > props.width) {
      const naEllipsisTekstBreedte = karakterBreedtes
        .slice(karakterBreedtes.length - NA_ELLIPSIS_AANTAL_KARAKTERS, karakterBreedtes.length)
        .reduce((acc, curr) => acc + curr, 0);

      const breedteVoorEllipsisOver = props.width - ellipsisBreedte - naEllipsisTekstBreedte;
      const aantalKaraktersVoorTonen = karakterBreedtes.reduce(
        (acc, curr, i) => {
          if (acc.breedteAcc + curr > breedteVoorEllipsisOver) {
            return {
              ...acc,
              breedteAcc: acc.breedteAcc + curr,
            };
          }
          return {
            breedteAcc: acc.breedteAcc + curr,
            idx: i,
          };
        },
        {
          breedteAcc: 0,
          idx: 0,
        },
      ).idx;

      const voorEllipsisTekst = props.tekst.slice(0, aantalKaraktersVoorTonen);
      const naEllipsisTekst = props.tekst.slice(
        props.tekst.length - NA_ELLIPSIS_AANTAL_KARAKTERS,
        props.tekst.length,
      );

      return `${voorEllipsisTekst}...${naEllipsisTekst}`;
    }

    return props.tekst;
  }, [props.tekst, tekstBreedte, props.width]);

  return (
    <span
      title={props.tekst}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxLines,
        ...props.innerCss,
      }}
    >
      {tekst}
    </span>
  );
};

export default TekstOverflowCenterEllipsis;
