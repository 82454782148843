import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameOf from '../../../core/nameOf';
import * as Yup from 'yup';
import api from '../../../api';
import { IInstellenBezigheidParams } from '../../../../../shared/src/api/v2/gebruiker';
import VinkVeld from '../../../components/formulier/VinkVeld';
import Dialoog from '../../../components/dialogen/Dialoog';
import DatumKiezer from '../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../helpers/datum';
import Combobox from '../../../components/formulier/Combobox';
import {
  IOphalenEscalatiecontextenResultElement,
  IOphalenEscalatiesResultElement,
} from '../../../../../shared/src/api/v2/escalatie';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import { addDays } from 'date-fns';

interface IDialoogResult {}

interface IFormikValues {
  ontstaansdatum: Date | null;
  datumOpgelost: Date | null;
  korteOmschrijving: string;
  context: number | null;
  isAfgehandeld: boolean;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ontstaansdatum: 'Ontstaansdatum',
  datumOpgelost: 'Datum opgelost',
  korteOmschrijving: 'Korte omschrijving',
  context: 'Context',
  isAfgehandeld: 'Afgehandeld',
};

const WijzigenEscalatieDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [bezigheid, setBezigheid] = useState<IInstellenBezigheidParams | null>(null);

  const [escalatie, setEscalatie] = useState<IOphalenEscalatiesResultElement | null>(null);

  const [escalatiecontexten, setEscalatiecontext] = useState<
    IOphalenEscalatiecontextenResultElement[] | null
  >(null);

  const ophalenEscalatie = useCallback(async () => {
    const result = await api.v2.escalatie.ophalenEscalaties({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    setEscalatie(result.escalaties[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenEscalatie();
  }, [ophalenEscalatie]);

  const ophalenEscalatiecontexten = useCallback(async () => {
    const result = await api.v2.escalatie.ophalenEscalatiecontexten({
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });
    setEscalatiecontext(result.contexten);
  }, []);

  useEffect(() => {
    ophalenEscalatiecontexten();
  }, [ophalenEscalatiecontexten]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (escalatie === null) {
      return null;
    }

    return {
      ontstaansdatum:
        escalatie.Ontstaansdatum !== null ? dagDatum(new Date(escalatie.Ontstaansdatum)) : null,
      datumOpgelost:
        escalatie.DatumOpgelost !== null ? dagDatum(new Date(escalatie.DatumOpgelost)) : null,
      korteOmschrijving: escalatie.KorteOmschrijving ?? '',
      context: escalatie.EscalatiecontextID,
      isAfgehandeld: escalatie.IsAfgehandeld,
    };
  }, [escalatie]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        id: props.id,
        ontstaansdatum: values.ontstaansdatum,
        datumOpgelost: values.datumOpgelost,
        korteOmschrijving: values.korteOmschrijving !== '' ? values.korteOmschrijving : null,
        escalatiecontextID: values.context,
        isAfgehandeld: values.isAfgehandeld,
      };

      const checkData = await api.v2.escalatie.checkWijzigenEscalatie(params);
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je de wijzigingen vastleggen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.escalatie.wijzigenEscalatie(params);

      await props.onSuccess({});

      actions.setSubmitting(false);
    },
    [props.onSuccess, props.id, bezigheid],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [nameOf<IFormikValues>('korteOmschrijving')]: Yup.string().required(),
    });
  }, []);

  const notitiesRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    notitiesRef.current?.focus();
  }, []);

  if (initialValues === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen escalatie</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, isSubmitting, submitForm, values } = formikProps;

          return (
            <>
              <ModalBody>
                {escalatiecontexten !== null && (
                  <>
                    {' '}
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.ontstaansdatum}</label>
                        <Field
                          name={nameOf<IFormikValues>('ontstaansdatum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            const minimaleDatum = addDays(new Date(), -60);
                            const maximaleDatum = addDays(new Date(), +1);

                            return (
                              <DatumKiezer
                                isClearable
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineValidDate={(date) => {
                                  return date >= minimaleDatum && date <= maximaleDatum;
                                }}
                                determineNextValidDate={(date) => {
                                  const nieuweDatum = addDays(date, +1);
                                  if (nieuweDatum <= maximaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const nieuweDatum = addDays(date, -1);
                                  if (nieuweDatum >= minimaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>{veldnamen.datumOpgelost}</label>
                        <Field
                          name={nameOf<IFormikValues>('datumOpgelost')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            const minimaleDatum = addDays(new Date(), -60);
                            const maximaleDatum = addDays(new Date(), +1);

                            return (
                              <DatumKiezer
                                isClearable
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineValidDate={(date) => {
                                  return date >= minimaleDatum && date <= maximaleDatum;
                                }}
                                determineNextValidDate={(date) => {
                                  const nieuweDatum = addDays(date, +1);
                                  if (nieuweDatum <= maximaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const nieuweDatum = addDays(date, -1);
                                  if (nieuweDatum >= minimaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>{veldnamen.korteOmschrijving}</label>
                        <Field
                          name={nameOf<IFormikValues>('korteOmschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <textarea {...field} className="form-control" rows={1} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.context}</label>
                        <Field
                          name={nameOf<IFormikValues>('context')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (escalatiecontexten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                isWisbaar
                                geselecteerd={field.value}
                                onSelectieChange={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                                opties={escalatiecontexten.map((x) => {
                                  return {
                                    id: x.ID,
                                    label: x.Naam,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('isAfgehandeld')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Afgehandeld</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>

                      {/*               
                  <div className="col-12 mt-3">
                    <label>Notities</label>
                    <Field
                      name={nameOf<IFormikValues>('notities')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <textarea
                            ref={notitiesRef}
                            {...field}
                            className="form-control"
                            rows={3}
                            placeholder={'Hier een verplicht een notitie invoeren'}
                          />
                        );
                      }}
                    />
                  </div> */}
                    </div>
                  </>
                )}
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenEscalatieDialoog;
