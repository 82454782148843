import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import {
  IOphalenTakenResultElement,
  IWijzigenTaakParams,
} from '../../../../../../shared/src/api/v2/taken';
import api from '../../../../api';
import nameof from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IconOpenInNew } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { EResultType } from '../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {
  taakID: number;
}

interface IFormikValues {
  naam: string;
  naamEnum: string;
  actief: boolean;
  omschrijving: string;
  crontabSyntax: string;
  visualiseren: boolean;
  parallelToestaan: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naamEnum: 'Naam enum',
  naam: 'Naam',
  crontabSyntax: 'Crontab',
  omschrijving: 'Omschrijving',
  actief: 'Actief',
  visualiseren: 'Visualiseren',
  parallelToestaan: 'Parallel uitvoeren toestaan',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, taakID } = props;
  const { instellingStore, checkStore } = useContext(RootStoreContext);

  const [taak, setTaak] = useState<IOphalenTakenResultElement | null>(null);

  const ophalenTaak = useCallback(async (id: number) => {
    const result = await api.v2.taken.ophalenTaak({
      id,
    });
    setTaak(result);
  }, []);

  useEffect(() => {
    ophalenTaak(taakID);
  }, [taakID, ophalenTaak]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IWijzigenTaakParams = {
        naamEnum: values.naamEnum,
        naam: values.naam,
        actief: values.actief,
        crontabSyntax: values.crontabSyntax,
        omschrijving: values.omschrijving,
        parallelToestaan: values.parallelToestaan,
        visualiseren: values.visualiseren,
        id: taakID,
      };
      const checkData = await api.v2.taken.checkWijzigenTaak(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.taken.wijzigenTaak(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [taakID],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (taak === null) {
      return null;
    }
    return {
      actief: taak.Actief,
      crontabSyntax: taak.CrontabSyntax,
      naam: taak.Naam,
      naamEnum: taak.NaamEnum,
      omschrijving: taak.Omschrijving,
      visualiseren: taak.Visualiseren,
      parallelToestaan: taak.ParallelToestaan,
    };
  }, [taak]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      actief: Yup.boolean(),
      omschrijving: Yup.string(),
      crontabSyntax: Yup.string(),
      naam: Yup.string(),
      naamEnum: Yup.string(),
      visualiseren: Yup.boolean(),
      parallelToestaan: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen Taak</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <input className="form-control" type="text" {...field} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.crontabSyntax}</label>
                        <Field
                          name={nameof<IFormikValues>('crontabSyntax')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <input className="form-control" type="text" {...field} />
                                <button
                                  className="ml-2 btn btn-light d-flex align-items-center"
                                  disabled={instellingStore.CronTabGeneratorPaginaURL === null}
                                  onClick={() => {
                                    const url = `${instellingStore.CronTabGeneratorPaginaURL!}#${taak!.CrontabSyntax.replace(
                                      / /g,
                                      '_',
                                    )}`;
                                    const win = window.open(url, '_blank');
                                    win!.focus();
                                  }}
                                >
                                  <IconOpenInNew
                                    style={{
                                      fill: Kleur.Grijs,
                                      width: 16,
                                      height: 16,
                                      marginTop: 2,
                                    }}
                                  />
                                  <span className="ml-2">Hulptool</span>
                                </button>
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameof<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <textarea className="form-control" rows={3} {...field} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('parallelToestaan')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.parallelToestaan}</span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <Field
                          name={nameof<IFormikValues>('visualiseren')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.visualiseren}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
