import React, { useMemo } from 'react';
import styled from 'styled-components';
import EntiteitKolom from './EntiteitKolom';
import {
  IData,
  IEntiteit,
  IMergeDialoogVeld,
  IMergeDialoogVeldFormatterProps,
  IVastGegeven,
} from '../types';

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-x: auto;
`;

interface IProps<TVelden extends string | number | symbol, TVasteGegevens> {
  entiteiten: Array<IEntiteit<TVelden, TVasteGegevens>>;
  onEntiteitenChange: (entiteiten: Array<IEntiteit<TVelden, TVasteGegevens>>) => void;
  velden: Record<TVelden, IMergeDialoogVeld<any>>;
  waarden: Record<TVelden, IData<any>>;
  onWaardenChange: (waarden: Record<TVelden, IData<any>>) => void;
  dialoogIndex: number;
  vasteGegevens?: IVastGegeven<TVelden, TVasteGegevens>[];
}

const EntiteitenVlak = <TVelden extends string | number | symbol, TVasteGegevens>(
  props: IProps<TVelden, TVasteGegevens>,
) => {
  const magToepassenUitvinken = useMemo(() => {
    return props.entiteiten.filter((x) => x.toepassen).length > 2;
  }, [props.entiteiten]);

  return (
    <Root>
      {props.entiteiten.map((entiteit, i) => {
        const andereEntiteiten = props.entiteiten.filter((x, j) => j !== i);

        return (
          <EntiteitKolom
            key={i}
            entiteit={entiteit}
            onEntiteitChange={(entiteit) => {
              const entiteiten = [...props.entiteiten];
              entiteiten[i] = entiteit;
              props.onEntiteitenChange(entiteiten);
            }}
            andereEntiteiten={andereEntiteiten}
            alleEntiteiten={props.entiteiten}
            velden={props.velden}
            waarden={props.waarden}
            onWaardenChange={props.onWaardenChange}
            headerNummer={i + 1}
            magToepassenUitvinken={magToepassenUitvinken}
            dialoogIndex={props.dialoogIndex}
            vasteGegevens={props.vasteGegevens}
          />
        );
      })}
    </Root>
  );
};

export default EntiteitenVlak;
