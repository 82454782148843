import React, { useState } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import EntiteitVeld from './EntiteitVeld';
import {
  EDataType,
  IBepaaldData,
  IEntiteit,
  IMergeDialoogVeld,
  IData,
  IVastGegeven,
} from '../../types';
import VinkVeld from '../../../formulier/VinkVeld';
import InactiefOverlay from '../../../InactiefOverlay';
import { IconCopy } from '../../../Icons';

interface IRootProps {
  width: number;
}

const Root = styled.div<IRootProps>`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  border-right: 1px solid ${Kleur.LichtGrijs};
`;

const HeaderContainer = styled.div`
  padding: 8px 10px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${Kleur.HeelLichtGrijs};

  .header-nummer {
    margin-left: 5px;
    margin-right: 10px;
    font-weight: bold;
  }
`;

interface IVastGegevenVeldProps {
  height: number;
}

const VastGegevenVeld = styled.div<IVastGegevenVeldProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Kleur.LichtGrijs};
  min-height: ${(props) => `${props.height}px`};
  max-height: ${(props) => `${props.height}px`};
  margin-top: 1px;
`;

interface IProps<TVelden extends string | number | symbol, TVasteGegevens = {}> {
  entiteit: IEntiteit<TVelden, TVasteGegevens>;
  onEntiteitChange: (entiteit: IEntiteit<TVelden, TVasteGegevens>) => void;
  andereEntiteiten: IEntiteit<TVelden, TVasteGegevens>[];
  alleEntiteiten: IEntiteit<TVelden, TVasteGegevens>[];
  velden: Record<TVelden, IMergeDialoogVeld<any>>;
  waarden: Record<TVelden, IData<any>>;
  onWaardenChange: (waarden: Record<TVelden, IData<any>>) => void;
  headerNummer: number;
  magToepassenUitvinken: boolean;
  dialoogIndex: number;
  vasteGegevens?: IVastGegeven<TVelden, TVasteGegevens>[];
}

interface IVeldState {
  hovered: boolean;
}

const EntiteitKolom = <TVelden extends string | number | symbol, TVasteGegevens = {}>(
  props: IProps<TVelden, TVasteGegevens>,
) => {
  const Header = props.entiteit.header;

  const [veldState, setVeldState] = useState<Record<TVelden, IVeldState | undefined>>(
    {} as Record<TVelden, IVeldState | undefined>,
  );

  return (
    <Root width={props.entiteit.breedte ?? 300}>
      <HeaderContainer>
        <span className="header-nummer">{props.headerNummer}</span>
        <Header dialoogIndex={props.dialoogIndex} />
        <div className="flex-fill" />
        <VinkVeld
          aangevinkt={props.entiteit.toepassen}
          onGewijzigd={(x) =>
            props.onEntiteitChange({
              ...props.entiteit,
              toepassen: x,
            })
          }
          disabled={props.entiteit.toepassen && !props.magToepassenUitvinken}
        />
      </HeaderContainer>
      <InactiefOverlay
        isInactief={!props.entiteit.toepassen}
        element={
          <div>
            {props.vasteGegevens?.map((vastGegeven, i) => {
              const Formatter = vastGegeven.formatter;
              return (
                <VastGegevenVeld key={i} height={vastGegeven.height}>
                  <Formatter entiteit={props.entiteit} />
                </VastGegevenVeld>
              );
            })}
            {Object.keys(props.entiteit.data).map((key, i, arr) => {
              const veld = key as TVelden;
              const data: any = props.entiteit.data[veld];
              const formatter = props.velden[veld].formatter;
              const height = props.velden[veld].height;
              const andereDatas: any[] = props.andereEntiteiten.map((x) => x.data[veld]);
              const alleDatas: any[] = props.alleEntiteiten.map((x) => x.data[veld]);

              const alleDatasZijnGelijkZonderNull = alleDatas
                .filter((x) => x !== null)
                .every((x, i, arr) => {
                  return x === arr[0];
                });

              const groupedDatas = alleDatasZijnGelijkZonderNull
                ? null
                : alleDatas.reduce((acc, x) => {
                    if (x === null) {
                      return acc;
                    }
                    const key = x;
                    if (!acc[key]) {
                      acc[key] = [];
                    }
                    acc[key].push(x);
                    return acc;
                  }, {});

              const groupedByKeysNumberMap =
                groupedDatas === null
                  ? null
                  : Object.keys(groupedDatas).reduce<Record<any, number>>((acc, x, idx) => {
                      acc[x] = idx;
                      return acc;
                    }, {});

              const isLaatste = i === arr.length - 1;
              const hovered = veldState[veld] && veldState[veld]!.hovered;

              return (
                <div
                  key={key}
                  style={{
                    borderBottom: isLaatste ? undefined : `1px solid ${Kleur.LichtGrijs}`,
                  }}
                  onMouseEnter={() => {
                    setVeldState((x) => ({
                      ...x,
                      [veld]: {
                        ...x[veld],
                        hovered: true,
                      },
                    }));
                  }}
                  onMouseLeave={() => {
                    setVeldState((x) => ({
                      ...x,
                      [veld]: {
                        ...x[veld],
                        hovered: false,
                      },
                    }));
                  }}
                >
                  {hovered && (
                    <div
                      style={{
                        minHeight: 20,
                        maxHeight: 20,
                        marginBottom: -20,
                        position: 'relative',
                        top: 5,
                        right: 4,
                        fontSize: 13,
                        cursor: 'pointer',
                      }}
                      className="d-flex align-items-end justify-content-end"
                      onClick={() => {
                        const nieuweWaarden = {
                          ...props.waarden,
                          [veld]: {
                            type: EDataType.Bepaald,
                            data,
                          } as IBepaaldData<any>,
                        };
                        props.onWaardenChange(nieuweWaarden);
                      }}
                    >
                      <IconCopy style={{ fill: Kleur.Grijs, height: 16, width: 16 }} />
                    </div>
                  )}
                  <EntiteitVeld
                    data={data}
                    andereDatas={andereDatas}
                    formatter={formatter}
                    height={height}
                  />
                  {alleDatasZijnGelijkZonderNull || data === null ? null : (
                    <div
                      style={{
                        minHeight: 20,
                        maxHeight: 20,
                        marginBottom: -20,
                        position: 'relative',
                        top: -22,
                        color: Kleur.Blauw,
                        right: 8,
                        fontSize: 13,
                      }}
                      className="d-flex align-items-end justify-content-end"
                    >
                      {groupedByKeysNumberMap![data] + 1}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        }
      />
    </Root>
  );
};

export default EntiteitKolom;
