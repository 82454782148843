import React, { useMemo } from 'react';
import styled from 'styled-components';
import IEmailGeadresseerde from '../../../../../../../../shared/src/models/email/IEmailGeadresseerde';
import {
  IOphalenRelatiesResultElementV2,
  IOphalenRelPersResultElement,
} from '../../../../../../../../shared/src/api/v2/relatie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../../../../shared/src/api/v2/organisatie/organisatie';
import IRemoteData, { createReadyRemoteData } from '../../../../../../models/IRemoteData';
import Avatar from '../../../../../personalia/Avatar';
import EmailAvatar from '../../../EmailAvatar';
import { genereerIdentiteitenVanEmail } from '../../../helpers';
import { IEmail } from '../../../../../../../../shared/src/api/v2/email-v2';
import { IOphalenEmailAccountsResultElement } from '../../../../../../../../shared/src/api/v2/dienst/email/email';

const Root = styled.div`
  display: flex;
  align-items: center;
`;

interface IProps {
  email: IEmail;
  emailAccount: IOphalenEmailAccountsResultElement;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  relPersCache: Record<number, IRemoteData<IOphalenRelPersResultElement[]>>;
  avatarImageSrc: IRemoteData<string | null>;
}

const GeadresseerdenSectie = (props: IProps) => {
  const identiteiten = useMemo(
    () => genereerIdentiteitenVanEmail(props.email, props.emailAccount.Email, props.relPersCache),
    [props.email, props.relPersCache, props.emailAccount.Email],
  );
  const hoofdIdentiteit = useMemo(() => {
    if (identiteiten.length === 0) {
      return null;
    }
    return identiteiten[0];
  }, [identiteiten]);

  const avatar = useMemo(() => {
    return (
      <EmailAvatar
        avatarImageSrc={props.avatarImageSrc}
        identiteit={createReadyRemoteData(hoofdIdentiteit)}
        relatiesCache={props.relatiesCache}
        personenCache={props.personenCache}
        organisatiesCache={props.organisatiesCache}
        avatarGrootte={40}
        avatarFontSize={16}
      />
    );
  }, [
    props.avatarImageSrc,
    props.relatiesCache,
    props.personenCache,
    props.organisatiesCache,
    hoofdIdentiteit,
  ]);

  const van = useMemo(() => {
    return props.email.van?.Email ?? null;
  }, [props.email.van]);
  const naar = useMemo(() => {
    return props.email.aan.map((x) => x.Email).join(', ');
  }, [props.email.aan]);
  const cc = useMemo(() => {
    return props.email.cc.map((x) => x.Email).join(', ');
  }, [props.email.cc]);
  const bcc = useMemo(() => {
    return props.email.bcc.map((x) => x.Email).join(', ');
  }, [props.email.bcc]);

  return (
    <Root>
      <div className="d-flex align-items-center">
        {avatar}
        <div className="d-flex flex-column ml-3">
          <span style={{ fontSize: 16 }}>{van}</span>
          <div>
            <span>Naar:</span>
            <span className="ml-2">{naar}</span>
          </div>
          {cc.length > 0 && (
            <div>
              <span>CC:</span>
              <span className="ml-2">{cc}</span>
            </div>
          )}
          {bcc.length > 0 && (
            <div>
              <span>BCC:</span>
              <span className="ml-2">{bcc}</span>
            </div>
          )}
        </div>
      </div>
    </Root>
  );
};

export default GeadresseerdenSectie;
