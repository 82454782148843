import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IOphalenReviewsResultElement,
  IOphalenUitnodigingenResultElement,
  IOphalenVoorstellenResultElement,
  IOphalenVoorstelUitnodigingenResultElement,
} from '../../../../../shared/src/api/v2/review/klant';
import MenuLayout from '../../../components/MenuLayout';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../core/nameOf';
import api from '../../../api';
import useUrlState from '../../../core/useUrlState';
import { addMonths, format } from 'date-fns';
import _ from 'lodash';
import { IconBetaling, IconUpload, IconVerwijderen } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import DetailComp from './DetailComp';
import BestandenDragAndDropDialoog from '../../../components/BestandenDragAndDropDialoog';
import RelatieKoppelComponent from '../../../components/RelatieKoppelComponent';
import { defaultSelecterenValues } from '../../../components/personalia/RelatieSelectieDialoog';
import { ETekstPrecisie } from '../../../components/formulier/TekstPrecisieSelectie';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  inlezenBeoordelingenDialoogState: IInlezenBeoordelingenDialoogState | null;
}

export interface IInlezenBeoordelingenDialoogState {
  open: boolean;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  inlezenBeoordelingenDialoogState: null,
};

const geenData = {
  noData: 'Geen uitnodigingen gevonden',
};

export interface IRegel extends IOphalenReviewsResultElement {}

const Beoordelingen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [beoordelingen, setBeoordelingen] = useState<IOphalenReviewsResultElement[] | null>(null);
  const [uitnodigingen, setUitnodigingen] = useState<IOphalenUitnodigingenResultElement[] | null>(
    null,
  );
  const [voorstellen, setVoorstellen] = useState<
    IOphalenVoorstelUitnodigingenResultElement[] | null
  >(null);

  const ophalenBeoordelingen = useCallback(async () => {
    const peildatum = addMonths(new Date(), -24);

    const beoordelingen = (
      await api.v2.review.ophalenReviews({
        filterSchema: {
          filters: [{ naam: 'DATUM_BEOORDELING_VANAF', data: peildatum }],
        },
      })
    ).reviews;

    const beoordelingenGesorteerd = _.orderBy(beoordelingen, ['DatumBeoordeling'], ['desc']);

    setBeoordelingen(beoordelingenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenBeoordelingen();
  }, [ophalenBeoordelingen]);

  const ophalenUitnodigingen = useCallback(async () => {
    if (beoordelingen === null) {
      return;
    }

    const uitnodigingen = await api.v2.review.ophalenUitnodigingen({
      filterSchema: {
        filters: [{ naam: 'IDS', data: beoordelingen.map((x) => x.ReviewUitnID) }],
      },
    });

    setUitnodigingen(uitnodigingen.uitnodigingen);
  }, [beoordelingen]);

  useEffect(() => {
    ophalenUitnodigingen();
  }, [ophalenUitnodigingen]);

  const ophalenVoorstellen = useCallback(async () => {
    if (uitnodigingen === null) {
      return;
    }

    const voorstellenResult = await api.v2.review.ophalenVoorstelUitnodigingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: uitnodigingen
              .filter((x) => x.ReviewUitnVoorstelID !== null)
              .map((x) => x.ReviewUitnVoorstelID),
          },
        ],
      },
    });

    setVoorstellen(voorstellenResult.uitnodigingen);
  }, [uitnodigingen]);

  useEffect(() => {
    ophalenVoorstellen();
  }, [ophalenVoorstellen]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.review.checkVerwijderenBeoordelingen({
      ids: urlState.selectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Beoordelingen verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.review.verwijderenBeoordelingen({
      ids: urlState.selectie,
    });

    ophalenBeoordelingen();
  }, [urlState.selectie]);

  const handleBelonen = useCallback(async () => {
    const checkData = await api.v2.review.checkBelonenBeoordelingen({
      ids: urlState.selectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Beoordelingen belonen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.review.belonenBeoordelingen({
      ids: urlState.selectie,
    });

    ophalenBeoordelingen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'DatumBeoordeling',
        title: 'Datum',
      },
      {
        name: '__persoon' as any,
        title: 'Persoon',
        // getCellValue: (x) => {
        //   return x.
        // },
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: 'BeoordelingKort',
        title: 'Beoordeling',
      },
      {
        name: 'Score',
        title: 'Score',
      },
      {
        name: 'Aanbevelen',
        title: 'Aanbevelen',
      },
      {
        name: 'VorderingGemaakt',
        title: 'Beloond',
      },
      {
        name: 'NietBelonen',
        title: 'Niet belonen',
      },
      {
        name: '__actieNietOpWebsite' as any,
        title: 'Evt. op website',
      },
      // {
      //   name: '__aanleiding' as any,
      //   title: 'Aanleiding',
      // },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'DatumBeoordeling',
        width: 135,
      },
      {
        columnName: '__persoon' as any,
        width: 250,
      },
      {
        columnName: 'BeoordelingKort',
        width: 400,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: 'Score',
        width: 90,
      },
      {
        columnName: 'Aanbevelen',
        width: 120,
      },
      {
        columnName: 'NietBelonen',
        width: 120,
      },
      {
        columnName: 'VorderingGemaakt',
        width: 120,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 200,
      },
      {
        columnName: '__aanleiding' as any,
        width: 150,
      },
      {
        columnName: '__actieNietOpWebsite' as any,
        width: 135,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Review Beoordelingen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () =>
                  setUrlStateSync('inlezenBeoordelingenDialoogState', { open: true })
                }
              >
                <IconUpload style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Inlezen beoordelingen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => handleVerwijderen()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => handleBelonen()}
              >
                <IconBetaling style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Belonen</span>
              </button>
              {/* <div className="d-flex align-items-center">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div> */}
            </div>
          </>
        }
        body={
          beoordelingen === null || uitnodigingen === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper rowAmount={beoordelingen.length} height={'calc(100vh - 150px)'}>
              <Grid rows={beoordelingen} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__persoon']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (rij.PersID === null) {
                      return <span>{rij.Email}</span>;
                    }
                    return <PersoonVisualisatie persID={rij.PersID} />;
                  }}
                />

                <DataTypeProvider
                  for={['__aanleiding']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    const uitnodiging =
                      uitnodigingen.find((x) => x.ID === rij.ReviewUitnID) ?? null;

                    if (uitnodiging === null) {
                      return <span></span>;
                    }

                    const voorstel =
                      voorstellen?.find((x) => x.ID === uitnodiging.ReviewUitnVoorstelID) ?? null;

                    if (voorstel === null) {
                      return <span></span>;
                    }

                    return <span>{voorstel.aanleiding?.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('Score')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('NietBelonen')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value ? 'X' : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('Aanbevelen'), nameOf<IRegel>('VorderingGemaakt')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('DatumBeoordeling')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__actieNietOpWebsite']}
                  formatterComponent={(formatterProps) => {
                    const row: IRegel = formatterProps.row;
                    return (
                      <span className="ml-2">
                        <a
                          href="#"
                          onClick={async () => {
                            await api.v2.review.toggleNietOpWebsite({ ids: [row.ID] });
                            await ophalenBeoordelingen();
                          }}
                        >
                          {row.NietOpWebsite ? 'Nee' : 'Ja'}
                        </a>
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('RecordToegevoegd')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    return (
                      <RelatieKoppelComponent
                        relID={rij.RelID}
                        onRelIDChange={async (relID) => {
                          await api.v2.review.wijzigenBeoordeling({
                            ID: rij.ID,
                            relID,
                            persID: rij.PersID,
                          });
                          await ophalenBeoordelingen();
                        }}
                        koppelOpties={{
                          defaultSelecterenValues: {
                            ...defaultSelecterenValues,
                            email: rij.Email === null ? defaultSelecterenValues.email : rij.Email,
                            emailTekstprecisie:
                              rij.Email === null
                                ? defaultSelecterenValues.emailTekstprecisie
                                : ETekstPrecisie.Exact,
                          },
                        }}
                      />
                    );
                    // return <RelatieVisualisatie relID={rij.RelID} />;
                  }}
                />

                <RowDetailState defaultExpandedRowIds={[]} />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {urlState.inlezenBeoordelingenDialoogState !== null && (
        <BestandenDragAndDropDialoog
          open
          onSuccess={async (result) => {
            await api.v2.review.inlezenBeoordelingen({
              bestandID: result.bestanden.map((x) => x.ID)[0],
            });
            ophalenBeoordelingen();
            setUrlStateSync('inlezenBeoordelingenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('inlezenBeoordelingenDialoogState', null)}
          toegestaneBestandstypen={[
            {
              mediaType: 'application/vnd.ms-excel',
              weergaveNaam: 'Excel',
            },
            {
              mediaType: 'text/csv',
              weergaveNaam: 'CSV',
            },
            {
              mediaType: 'text/plain',
              weergaveNaam: 'Tekst',
            },
          ]}
        />
      )}
    </>
  );
};

export default withRouter(Beoordelingen);
