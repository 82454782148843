import React, { useContext, useEffect, useMemo } from 'react';
import { Root } from './styles';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import classNames from 'classnames';
import { Kleur } from '../../../bedrijfslogica/constanten';

const RealtimeStatus: React.FC<{}> = observer((props) => {
  const { realtimeStore } = useContext(RootStoreContext);

  if (realtimeStore.isConnected) {
    return null;
  }

  return (
    <Root>
      <div
        style={{
          // height: 5,
          width: '100%',
          backgroundColor: realtimeStore.isConnected ? Kleur.LichtGroen : Kleur.Rood,
          color: realtimeStore.isConnected ? Kleur.Zwart : Kleur.Wit,
        }}
        className="d-flex align-items-center justify-content-center pt-1 pb-1"
      >
        Realtime verbinding is verbroken
      </div>
    </Root>
  );
});

export default RealtimeStatus;
