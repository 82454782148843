import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../api';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import IDialoogProps from '../../../../core/IDialoogProps';
import { RootStoreContext } from '../../../../stores/RootStore';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../core/nameOf';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { IOphalenHerincassozakenResultElement } from '../../../../../../shared/src/api/v2/debiteur/herincassozaak';
import { dagDatum } from '../../../../helpers/datum';
import BedragInput from '../../../../components/formulier/BedragInput';
import { IWijzigenHerincassozaakParams } from '../../../../../../shared/src/api/v2/debiteur/herincassozaak';

interface IProps extends IDialoogProps<null> {
  ID: number;
}

interface IFormikValues {
  notities: string;
  dossierDatum: Date | string;
  // status: number;
  // resultaatStatus: number;
  afgehandeld: boolean;
  // externeReferentie: string | null;
  hoofdsom: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  notities: 'Notities',
  dossierDatum: 'Dossierdatum',
  // status: 'Dossierstatus',
  // resultaatStatus: 'Resultaat',
  afgehandeld: 'Afgehandeld',
  // externeReferentie: 'Externe referentie',
  hoofdsom: 'Hoofdsom',
};

const WijzigenDialoog: React.FC<IProps> = (props) => {
  const { open, onSuccess, onAnnuleren, dialoogIndex, ID } = props;
  const { klantkaartStore } = useContext(RootStoreContext);

  const [herincassozaak, setHerincassozaak] = useState<IOphalenHerincassozakenResultElement | null>(
    null,
  );

  // const [dossierStatussen, setDossierStatussen] = useState<
  //   IOphalenDossierStatussenResultElement[] | null
  // >(null);
  // const [dossierResultaatStatussen, setDossierResultaatStatussen] = useState<
  //   IOphalenResultaatStatussenResultElement[] | null
  // >(null);

  // const ophalenDossierStatussen = useCallback(async () => {
  //   const { statussen } = await api.v2.debiteur.incassozaak.ophalenDossierStatussen({});
  //   setDossierStatussen(statussen);
  // }, []);

  // useEffect(() => {
  //   ophalenDossierStatussen();
  // }, [ophalenDossierStatussen]);

  // const ophalenResultaatStatussen = useCallback(async () => {
  //   const { statussen } = await api.v2.debiteur.incassozaak.ophalenResultaatStatussen({});
  //   setDossierResultaatStatussen(statussen);
  // }, []);

  // useEffect(() => {
  //   ophalenResultaatStatussen();
  // }, [ophalenResultaatStatussen]);

  const ophalenHerincassozaak = useCallback(async () => {
    const result = await api.v2.debiteur.herincassozaak.ophalenHerincassozaken({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.ID] }] },
    });

    setHerincassozaak(result.herincassozaken[0]);
  }, [props.ID]);

  useEffect(() => {
    ophalenHerincassozaak();
  }, [props.ID]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (herincassozaak === null) {
      return null;
    }

    return {
      notities: herincassozaak.Notities === null ? '' : herincassozaak.Notities,
      dossierDatum: dagDatum(new Date(herincassozaak.DossierDatum)),
      // status: herincassozaak.Status,
      // resultaatStatus: herincassozaak.ResultaatStatus,
      afgehandeld: herincassozaak.Afgehandeld,
      // externeReferentie: herincassozaak.ExterneReferentie,
      hoofdsom: herincassozaak.Hoofdsom,
    };
  }, [herincassozaak]);

  const handleSubmit = useCallback(async (values: IFormikValues) => {
    const params: IWijzigenHerincassozaakParams = {
      id: props.ID,
      notities: values.notities === '' ? null : values.notities,
      dossierDatum: values.dossierDatum,
      afgehandeld: values.afgehandeld,
      hoofdsom: values.hoofdsom,
    };

    await api.v2.debiteur.herincassozaak.wijzigenHerincassozaak(params);

    onSuccess(null);
  }, []);

  // const validationSchema = Yup.object().shape({
  //   notities: Yup.string(),
  //   dossierDatum: Yup.date().required(),
  //   dossierStatus: Yup.number().required(),
  //   // resultaatStatus: Yup.number().required(),
  // });

  if (herincassozaak === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen Herincassozaak</ModalTitle>
      </ModalHeader>

      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues!}
        isInitialValid
        // validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    {/* <div className="col-12">
                      <label>{veldnamen.status}</label>

                      <Field
                        name={nameOf<IFormikValues>('status')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (dossierStatussen === null) {
                            return <LoadingSpinner />;
                          }

                          return (
                            <Combobox
                              // legeOptieTonen
                              geselecteerd={field.value}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              opties={dossierStatussen.map((status) => {
                                return {
                                  id: status.Status,
                                  label: status.Naam,
                                };
                              })}
                            />
                          );
                        }}
                      />
                    </div> */}

                    {/* <div className="col-12 mt-2">
                      <label>{veldnamen.externeReferentie}</label>
                      <Field
                        name={nameOf<IFormikValues>('externeReferentie')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return <input {...field} className="form-control" type="text" />;
                        }}
                      />
                    </div> */}

                    {/* <div className="col-12 mt-2">
                      <label>{veldnamen.resultaatStatus}</label>

                      <Field
                        name={nameOf<IFormikValues>('resultaatStatus')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (dossierResultaatStatussen === null) {
                            return <LoadingSpinner />;
                          }

                          return (
                            <Combobox
                              geselecteerd={field.value}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              opties={dossierResultaatStatussen!.map((status) => {
                                return {
                                  id: status.Status,
                                  label: status.Naam,
                                };
                              })}
                            />
                          );
                        }}
                      />
                    </div> */}

                    <div className="col-12 mt-3">
                      <label>{veldnamen.dossierDatum}</label>
                      <Field
                        name="dossierDatum"
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <div className="d-flex ">
                              <DatumKiezer
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                waarde={field.value}
                                determineValidDate={() => {
                                  return true;
                                }}
                                determinePreviousValidDate="ONBEGRENST"
                                determineNextValidDate="ONBEGRENST"
                              />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-4 align-items-left mt-3">
                      <label>{veldnamen.hoofdsom}</label>
                      <Field
                        name={nameOf<IFormikValues>('hoofdsom')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <BedragInput
                                value={Math.abs(field.value) || 0}
                                onChange={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                              {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.notities}</label>
                      <Field
                        name={nameOf<IFormikValues>('notities')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return <textarea {...field} className="form-control" rows={3} />;
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <Field
                        name={nameOf<IFormikValues>('afgehandeld')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">Afgehandeld</span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenDialoog;
