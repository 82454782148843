import React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ETransportopdrachtRegelsoort } from '../../../../bedrijfslogica/enums';
import { IOpdracht } from '../../../transport/PlanningTabel';
import _ from 'lodash';

interface IProps extends RouteComponentProps {
  opdracht: IOpdracht;
}

const SoortenIndicatie: React.FC<IProps> = observer((props) => {
  const rij: IOpdracht = props.opdracht;

  const leveropdrachtenAanwezig = rij.regels.some(
    (x) => x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering,
  );
  const retouropdrachtenAanwezig = rij.regels.some(
    (x) => x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour,
  );
  const omruilopdrachtenAanwezig = rij.regels.some((x) => x.OmruilID !== null);

  // Zitten er contractwissels bij?
  const wisselContracten_CntIDs = _.flatten(
    rij.regels
      .filter((x) => x.contract !== null && x.contract.ContractwisselDoor_CntID !== null)
      .map((x) => x.contract!.CntID),
  );
  const contractwisselAanwezig = rij.regels
    .filter((x) => x.contract !== null)
    .some((x) => wisselContracten_CntIDs.indexOf(x.contract!.CntID) !== -1);

  // const premiums = rij.premiums.length !== 0 ? ` + P${rij.premiums.length}` : '';
  // const premiums = rij.premiums.length !== 0 ? ` + P` : '';

  const soortenIndicatie =
    rij.regels.length +
    ' ' +
    (leveropdrachtenAanwezig ? 'L' : '') +
    (retouropdrachtenAanwezig ? 'R' : '') +
    (omruilopdrachtenAanwezig ? 'O' : '');
  // +
  // (contractwisselAanwezig ? 'W' : '');

  return <span>{soortenIndicatie}</span>;
});

export default withRouter(SoortenIndicatie);
