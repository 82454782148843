import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { addMonths, format } from 'date-fns';
import _ from 'lodash';
import MenuLayout from '../../../../components/MenuLayout';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import { formatteerPersoonNaam } from '../../../../helpers';
import { IOphalenContactpersonenResultElement } from '../../../../../../shared/src/api/v2/relatie/contactpersoon';
import RelatiesVisualisaties from '../../../../components/personalia/RelatiesVisualisaties';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import { IconVerwijderen } from '../../../../components/Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import PersoonWijzigenDialoog from '../../../../components/dialogen/PersoonWijzigenDialoog';
import PersoonVisualisatie from '../../../../components/personalia/PersoonVisualisatie';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  NietGekoppeldAanRelatie = 'NIET_GEKOPPELD_AAN_RELATIE',
}

export interface IWijzigenPersoonDialoogState {
  persID: number;
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  wijzigenPersoonDialoogState: IWijzigenPersoonDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.NietGekoppeldAanRelatie,
      data: true,
      isActief: true,
    },
  ],
  wijzigenPersoonDialoogState: null,
};

export interface IRegel extends IOphalenPersonenResultElementV2 {}

const Persoon: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);

  const [personen, setPersonen] = useState<IOphalenPersonenResultElementV2[] | null>(null);
  const [persoonRelaties, setPersoonRelaties] = useState<
    IOphalenContactpersonenResultElement[] | null
  >(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenPersonen = useCallback(async () => {
    const peildatum = addMonths(new Date(), -12);

    const personenResult = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          { naam: 'RECORD_TOEGEVOEGD_VANAF', data: peildatum },
          { naam: 'IS_VERVANGEN', data: false },
        ],
        // filters: [
        //   { naam: 'RECORD_TOEGEVOEGD_VANAF', data: peildatum },
        //   // { naam: 'NIET_GEKOPPELD_AAN_RELATIE', data: true },
        // ],
      },
      orderSchema: { orders: [{ naam: 'RECORD_TOEGEVOEGD', richting: 'DESC' }] },
    });

    setPersonen(personenResult.personen);
  }, [filterSchema.filters!]);

  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const ophalenPersoonRelaties = useCallback(async () => {
    if (personen === null) {
      return;
    }
    const persoonRelaties = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: { filters: [{ naam: 'PERS_IDS', data: personen.map((x) => x.PersID) }] },
    });

    setPersoonRelaties(persoonRelaties.contactpersonen);
  }, [personen]);

  useEffect(() => {
    ophalenPersoonRelaties();
  }, [ophalenPersoonRelaties]);

  const keyExtractor = useCallback((row: IRegel) => row.PersID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__naam' as any,
        title: 'Naam',
        getCellValue: (rij) => {
          const naam = formatteerPersoonNaam({
            aanhefKort: rij.geslacht.AanhefKort,
            achternaam: rij.Achternaam,
            voorletters: rij.Voorletters,
            voornaam: rij.Voornaam,
            voorvoegsel: rij.Voorvoegsel,
          });

          return naam;
        },
        // getCellValue: (rij) => {
        //   return <PersoonVisualisatie persID={rij.PersID} />;
        // },
      },
      {
        name: '__isRelatie' as any,
        title: 'Is relatie',
      },
      {
        name: '__aantalRelaties' as any,
        title: 'Atl. rel.',
        getCellValue: (rij) => {
          if (persoonRelaties === null) {
            return null;
          }
          const relIDs: number[] = persoonRelaties
            .filter((x) => x.PersID === rij.PersID)
            .map((x) => x.RelID);

          return relIDs.length;
        },
      },
      {
        name: '__relaties' as any,
        title: 'Relaties',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Toegevoegd op',
      },
    ],
    [persoonRelaties],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__naam' as any,
        width: 250,
      },
      {
        columnName: '__isRelatie' as any,
        width: 100,
      },
      {
        columnName: '__aantalRelaties' as any,
        width: 90,
      },
      {
        columnName: '__relaties' as any,
        width: 350,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.NietGekoppeldAanRelatie,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Geen gekoppelde relatie</span>;
        },
      },
    ],
    [],
  );

  const handleVerwijderen = useCallback(async (ids: number[]) => {
    const params = { persIDs: ids };

    const checkData = await api.v2.persoon.checkVerwijderenPersonen(params);
    const checkResult = await checkStore.controleren({ checkData });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: `Geselecteerde personen verwijderen?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    // TODO Afvangen fout API
    const result = await api.v2.persoon.verwijderenPersonen(params);

    ophalenPersonen();
  }, []);

  return (
    <>
      {personen === null || persoonRelaties === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex">
          <MenuLayout
            menu={
              <>
                <div className="mt-2 d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                    onClick={() => {
                      handleVerwijderen(urlState.selectie);
                    }}
                    disabled={urlState.selectie.length === 0}
                  >
                    <span>
                      <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                    </span>
                    <span className="ml-2">Verwijderen</span>
                  </button>

                  <div className="d-flex flex-fill ml-3">
                    <FilterBalkV2
                      filters={filters}
                      filterData={urlState.filterData}
                      onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                      onFilterSchemaChange={setFilterSchema}
                    />
                  </div>
                </div>
              </>
            }
            body={
              <GridStyleWrapper height={'calc(100vh - 100px)'}>
                <Grid rows={personen} getRowId={keyExtractor} columns={kolommen}>
                  <DataTypeProvider
                    for={['__naam']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;

                      return <PersoonVisualisatie persID={rij.PersID} />;

                      // const naam = formatteerPersoonNaam({
                      //   aanhefKort: rij.geslacht.AanhefKort,
                      //   achternaam: rij.Achternaam,
                      //   voorletters: rij.Voorletters,
                      //   voornaam: rij.Voornaam,
                      //   voorvoegsel: rij.Voorvoegsel,
                      // });

                      // return <span>{naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__isRelatie']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      const relaties = persoonRelaties.filter((x) => x.PersID === rij.PersID);
                      const isRelatie = relaties.some((x) => x.IsRelatie);
                      return <span>{isRelatie ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__aantalRelaties']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;

                      const relIDs: number[] = persoonRelaties
                        .filter((x) => x.PersID === rij.PersID)
                        .map((x) => x.RelID);

                      return <span>{relIDs.length}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__relaties']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;

                      const relIDs: number[] = persoonRelaties
                        .filter((x) => x.PersID === rij.PersID)
                        .map((x) => x.RelID);

                      if (relIDs.length === 0) {
                        return <span />;
                      }
                      return (
                        <RelatiesVisualisaties
                          relIDs={relIDs}
                          relatieLinkBuilder={(hoedanigheid, relID) =>
                            `/${
                              hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                            }/${relID}/contactpersonen`
                          }
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['RecordToegevoegd']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      const datum = new Date(rij.RecordToegevoegd!);
                      return <span>{format(datum, 'dd-MM-yyyy HH:mm:ss')}</span>;
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <RowDetailState defaultExpandedRowIds={[]} />

                  <VirtualTable />
                  <TableColumnResizing defaultColumnWidths={kolombreedtes} />

                  <TableHeaderRow showSortingControls />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={(x) => {
                      const id = x[x.length - 1] as number;
                      setUrlStateSync('wijzigenPersoonDialoogState', { persID: id });
                    }}
                    onCommitChanges={() => null}
                  />

                  <TableEditColumn
                    width={35}
                    showEditCommand
                    commandComponent={DXCommandComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />

                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            }
          />
        </div>
      )}
      {urlState.wijzigenPersoonDialoogState !== null && (
        <PersoonWijzigenDialoog
          open
          onSuccess={() => {
            setUrlStateSync('wijzigenPersoonDialoogState', null);
            ophalenPersonen();
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenPersoonDialoogState', null);
          }}
          dialoogIndex={1}
          persID={urlState.wijzigenPersoonDialoogState.persID}
        />
      )}
    </>
  );
});

export default withRouter(Persoon);
