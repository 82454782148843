import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../models/IRemoteData';
import {
  IOphalenBijzonderhedenParams,
  IOphalenBijzonderhedenResult,
  IOphalenDashboardFinancieleInfoResult,
  IOphalenDashboardInfoResult,
  IOphalenDashboardServicedienstInfoResult,
  IOphalenDashboardTransportInfoResult,
  IOphalenStandVanZakenResult,
} from '../../../../shared/src/api/v2/dashboard';
import api from '../../api';
import { IOphalenOpdrachtenResultElement } from '../../../../shared/src/api/v2/inkoop/opdracht';
import OnlineGebruikers from './OnlineGebruikers';
import VerwachteInkoopleveringenTegel from './VerwachteInkoopleveringenTegel';
import AlgemeneInformatie from './AlgemeneInformatie';
import Bijzonderheden from './Bijzonderheden';
import { IOphalenInstellingenResult } from '../../../../shared/src/api/v2/instelling';
import { addDays, format } from 'date-fns';
import { IOphalenBatchesResultElement } from '../../../../shared/src/api/v2/bank/opdracht/opdracht';
import BankopdrachtenInformatie from './BankopdrachtenInformatie';
import VerlopenInkoopOpdrachtenTegel from './VerlopenInkoopOpdrachtenTegel';
import { IOphalenContractenResultElementV2 } from '../../../../shared/src/api/v2/contract';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import TransportinformatieTegel from './TransportinformatieTegel';
import FinancieleinformatieTegel from './FinancieleinformatieTegel';
import StandVanZaken from './StandVanZaken';
import LopendeActies from './LopendeActies';
import { IActieregel } from '../Aanbod/Tarieven/Acties';
import { IOphalenAbonnementenResultElement } from '../../../../shared/src/api/v2/aanbod/Abonnement';
import _ from 'lodash';
import { IOphalenPremiumsResultElement } from '../../../../shared/src/api/v2/transport/opdracht/premium';
import { Helmet } from 'react-helmet';
import VoipAccountsVigerend from './VoipAccountsVigerend';
import { IOphalenActueleOpeningsinfoResult } from '../../../../shared/src/api/v2/openingstijd';
import { IOphalenTakenResult } from '../../../../shared/src/api/v2/taken';
import ServicedienstTegel from './ServicedienstTegel';

export interface ILopendeActieregel extends IActieregel {
  abonnementen: IOphalenAbonnementenResultElement[];
  isHoofdactie: boolean;
}

export interface IDashboardContext {
  dashboardInfo: IRemoteData<IOphalenDashboardInfoResult>;
  inkoopopdrachtenVerwacht: IRemoteData<IOphalenOpdrachtenResultElement[]>;
  inkoopopdrachtenVerlopen: IRemoteData<IOphalenOpdrachtenResultElement[]>;
  instellingen: IRemoteData<IOphalenInstellingenResult>;
  lopendeBankbatches: IRemoteData<IOphalenBatchesResultElement[]>;
  contracten: IRemoteData<IOphalenContractenResultElementV2[]>;
  transport: IRemoteData<IOphalenDashboardTransportInfoResult>;
  financieleInfo: IRemoteData<IOphalenDashboardFinancieleInfoResult>;
  standVanZaken: IRemoteData<IOphalenStandVanZakenResult>;
  lopendeActies: IRemoteData<ILopendeActieregel[]>;
  premiums: IRemoteData<IOphalenPremiumsResultElement[]>;
  openingsinfo: IRemoteData<IOphalenActueleOpeningsinfoResult>;
  plannertaken: IRemoteData<IOphalenTakenResult>;
  servicediensten: IRemoteData<IOphalenDashboardServicedienstInfoResult>;
}
export const DashboardContext = React.createContext<IDashboardContext>(null as any);

const Dashboard = () => {
  const [instellingen, setInstellingen] = useState<IRemoteData<IOphalenInstellingenResult>>(
    createPendingRemoteData(),
  );

  const [premiums, setPremiums] = useState<IRemoteData<IOphalenPremiumsResultElement[]>>(
    createPendingRemoteData(),
  );

  const [dashboardInfo, setDashboardInfo] = useState<IRemoteData<IOphalenDashboardInfoResult>>(
    createPendingRemoteData(),
  );

  const [transport, setTransport] = useState<IRemoteData<IOphalenDashboardTransportInfoResult>>(
    createPendingRemoteData(),
  );

  const [financieleInfo, setFinancieleInfo] = useState<
    IRemoteData<IOphalenDashboardFinancieleInfoResult>
  >(createPendingRemoteData());

  const [standVanZaken, setStandVanZaken] = useState<IRemoteData<IOphalenStandVanZakenResult>>(
    createPendingRemoteData(),
  );

  const [inkoopopdrachtenVerwacht, setInkoopopdrachtenVerwacht] = useState<
    IRemoteData<IOphalenOpdrachtenResultElement[]>
  >(createPendingRemoteData());

  const [inkoopopdrachtenVerlopen, setInkoopopdrachtenVerlopen] = useState<
    IRemoteData<IOphalenOpdrachtenResultElement[]>
  >(createPendingRemoteData());

  const [lopendeBankbatches, setLopendeBankbatches] = useState<
    IRemoteData<IOphalenBatchesResultElement[]>
  >(createPendingRemoteData());

  const [contracten, setContracten] = useState<IRemoteData<IOphalenContractenResultElementV2[]>>(
    createPendingRemoteData(),
  );

  const [openingsinfo, setOpeningsinfo] = useState<IRemoteData<IOphalenActueleOpeningsinfoResult>>(
    createPendingRemoteData(),
  );

  const [plannertaken, setPlannertaken] = useState<IRemoteData<IOphalenTakenResult>>(
    createPendingRemoteData(),
  );

  const [servicediensten, setServicediensten] = useState<
    IRemoteData<IOphalenDashboardServicedienstInfoResult>
  >(createPendingRemoteData());

  const ophalenPlannertaken = useCallback(async () => {
    const result = await api.v2.taken.ophalenTaken({});
    setPlannertaken(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenPlannertaken();
  }, [ophalenPlannertaken]);

  const ophalenStandVanZaken = useCallback(async () => {
    const result = await api.v2.dashboard.ophalenStandVanZaken({});
    setStandVanZaken(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenStandVanZaken();
  }, [ophalenStandVanZaken]);

  const [lopendeActies, setLopendeActies] = useState<IRemoteData<ILopendeActieregel[]>>(
    createPendingRemoteData(),
  );

  const ophalenPremiumsInfo = useCallback(async () => {
    const result = (await api.v2.transport.opdracht.premium.ophalenPremiums({})).premiums;
    setPremiums(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenPremiumsInfo();
  }, [ophalenPremiumsInfo]);

  const ophalenLopendeActies = useCallback(async () => {
    const actiesResult = await api.v2.aanbod.tarieven.acties.ophalenActies({
      filterSchema: {
        filters: [
          { naam: 'ACTIEF', data: true },
          { naam: 'REL_IDS', data: null },
          { naam: 'PEILDATUM', data: new Date() },
        ],
      },
    });

    const abonIDs = _.flatten(actiesResult.abonnementActies.map((x) => x.AbonID));
    const abonnementenResult = (
      await api.v2.abonnement.ophalenAbonnementen({
        filterSchema: { filters: [{ naam: 'IDS', data: abonIDs }] },
      })
    ).abonnementen;

    const result = actiesResult.acties.map((actie) => {
      const abonnementActies = actiesResult.abonnementActies.filter(
        (x) => x.TarActieID === actie.TarActieID,
      );
      const actiePerioden = actiesResult.actiePerioden.filter(
        (x) => x.TarActieID === actie.TarActieID,
      );
      const actieCodes = actiesResult.actieCodes.filter((x) => x.TarActieID === actie.TarActieID);
      const entiteitActies = actiesResult.entiteitActies.filter(
        (x) => x.TarActieID === actie.TarActieID,
      );

      const abonnementen = abonnementenResult.filter(
        (x) => abonnementActies.map((x) => x.AbonID).indexOf(x.AbonID) !== -1,
      );

      return {
        ...actie,
        abonnementActies,
        actiePerioden,
        actieCodes,
        entiteitActies,
        onVerversenAangevraagd: () => null,
        abonnementen,
        isHoofdactie: actie.TarActieID === actiesResult.hoofdActie_TarActieID,
      };
    });

    setLopendeActies(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenLopendeActies();
  }, [ophalenLopendeActies]);

  const ophalenInstellingen = useCallback(async () => {
    const result = await api.v2.instelling.ophalenInstellingen({});
    setInstellingen(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenInstellingen();
  }, [ophalenInstellingen]);

  const ophalenDashboardInfo = useCallback(async () => {
    const result = await api.v2.dashboard.ophalenDashboardInfo({});
    setDashboardInfo(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenDashboardInfo();
  }, [ophalenDashboardInfo]);

  const ophalenTransportInfo = useCallback(async () => {
    const result = await api.v2.dashboard.ophalenTransportInfo({});
    setTransport(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenTransportInfo();
  }, [ophalenTransportInfo]);

  const ophalenFinancieleInfo = useCallback(async () => {
    const result = await api.v2.dashboard.ophalenFinancieleInfo({});
    setFinancieleInfo(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenFinancieleInfo();
  }, [ophalenFinancieleInfo]);

  const ophalenInkoopopdrachten = useCallback(async () => {
    const peildatum = format(addDays(new Date(), 0), 'yyyy-MM-dd');

    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          { naam: 'IS_VERSTUURD', data: true },
          { naam: 'NOG_TE_LEVEREN', data: true },
          { naam: 'IS_AFGEHANDELD', data: false },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'DATUM_VERWACHT',
            richting: 'ASC',
          },
          {
            naam: 'DATUM_GEWENST',
            richting: 'ASC',
          },
        ],
      },
    });

    const leveringenVandaag = result.inkoopopdrachten
      .filter((x) => x.DatumVerwacht !== null || x.DatumGewenst !== null)
      .filter((x) => {
        return (
          format(
            new Date((x.DatumVerwacht !== null ? x.DatumVerwacht : x.DatumGewenst)!),
            'yyyy-MM-dd',
          ) === peildatum
        );
      });

    setInkoopopdrachtenVerwacht(createReadyRemoteData(leveringenVandaag));

    const opdrachtenVerlopen = result.inkoopopdrachten
      .filter((x) => x.DatumVerwacht !== null || x.DatumGewenst !== null)
      .filter((x) => !x.GeenTeLaatMelding)
      .filter((x) => {
        const datumlevering = x.DatumVerwacht !== null ? x.DatumVerwacht : x.DatumGewenst;
        return format(new Date(datumlevering!), 'yyyy-MM-dd') < peildatum;
      });
    setInkoopopdrachtenVerlopen(createReadyRemoteData(opdrachtenVerlopen));
  }, []);

  useEffect(() => {
    ophalenInkoopopdrachten();
  }, [ophalenInkoopopdrachten]);

  const ophalenUitstaandeBankbatches = useCallback(async () => {
    const result = await api.v2.bank.opdracht.ophalenBatches({
      filterSchema: { filters: [{ naam: 'IS_UITGEVOERD', data: false }] },
      orderSchema: { orders: [{ naam: 'UITVOERDATUM', richting: 'ASC' }] },
    });
    setLopendeBankbatches(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenUitstaandeBankbatches();
  }, [ophalenUitstaandeBankbatches]);

  const ophalenActueleOpeningsinfo = useCallback(async () => {
    const result = await api.v2.openingstijd.ophalenActueleOpeningsinfo({});
    setOpeningsinfo(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenActueleOpeningsinfo();
  }, [ophalenActueleOpeningsinfo]);

  const ophalenServicedienstenInfo = useCallback(async () => {
    const result = await api.v2.dashboard.ophalenServicedienstInfo({});
    setServicediensten(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenServicedienstenInfo();
  }, [ophalenServicedienstenInfo]);

  useEffect(() => {
    (async () => {
      // const contractenResult = (
      //   await api.v2.contract.ophalenContractenV2({
      //     filterSchema: {
      //       filters: [{ naam: 'STATUS_NAAM_ENUMS', data: [EContractStatus.Lopend] }],
      //     },
      //   })
      // ).contracten;

      setContracten(createReadyRemoteData([]));
    })();
  }, []);

  const contextValue = useMemo<IDashboardContext>(() => {
    return {
      dashboardInfo,
      inkoopopdrachtenVerwacht,
      inkoopopdrachtenVerlopen,
      instellingen,
      lopendeBankbatches,
      contracten,
      transport,
      financieleInfo,
      standVanZaken,
      lopendeActies,
      premiums,
      openingsinfo,
      plannertaken,
      servicediensten,
    };
  }, [
    dashboardInfo,
    inkoopopdrachtenVerwacht,
    inkoopopdrachtenVerlopen,
    instellingen,
    lopendeBankbatches,
    contracten,
    transport,
    financieleInfo,
    standVanZaken,
    lopendeActies,
    premiums,
    openingsinfo,
    plannertaken,
    servicediensten,
  ]);

  if (
    dashboardInfo.state === ERemoteDataState.Pending ||
    inkoopopdrachtenVerwacht.state === ERemoteDataState.Pending ||
    inkoopopdrachtenVerlopen.state === ERemoteDataState.Pending ||
    instellingen.state === ERemoteDataState.Pending ||
    lopendeBankbatches.state === ERemoteDataState.Pending ||
    contracten.state === ERemoteDataState.Pending ||
    transport.state === ERemoteDataState.Pending ||
    financieleInfo.state === ERemoteDataState.Pending ||
    lopendeActies.state === ERemoteDataState.Pending ||
    premiums.state === ERemoteDataState.Pending ||
    openingsinfo.state === ERemoteDataState.Pending ||
    plannertaken.state === ERemoteDataState.Pending ||
    servicediensten.state === ERemoteDataState.Pending
    // standVanZaken.state === ERemoteDataState.Pending
  ) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <DashboardContext.Provider value={contextValue}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div
        className="d-flex flex-fill p-3"
        style={{ columnGap: 30, overflowY: 'auto', overflowX: 'hidden', height: '100vh' }}
      >
        <div style={{ flex: 2 }}>
          <div className="row">
            {dashboardInfo.state === ERemoteDataState.Ready &&
              financieleInfo.state === ERemoteDataState.Ready &&
              openingsinfo.state === ERemoteDataState.Ready &&
              instellingen.state === ERemoteDataState.Ready && (
                <div className="col-7">
                  <AlgemeneInformatie />
                </div>
              )}

            {dashboardInfo.state === ERemoteDataState.Ready && (
              <div className="col-5">
                <StandVanZaken />
              </div>
            )}

            {dashboardInfo.state === ERemoteDataState.Ready && (
              <div className="col-7">
                <Bijzonderheden />
              </div>
            )}

            {inkoopopdrachtenVerwacht.state === ERemoteDataState.Ready &&
              inkoopopdrachtenVerwacht.data!.length > 0 && (
                <div className="col-12">
                  <VerwachteInkoopleveringenTegel />
                </div>
              )}

            {lopendeBankbatches.state === ERemoteDataState.Ready &&
              lopendeBankbatches.data!.length > 0 && (
                <div className="col-7">
                  <BankopdrachtenInformatie />
                </div>
              )}

            {dashboardInfo.state === ERemoteDataState.Ready && (
              <div className="col-5">
                <FinancieleinformatieTegel />
              </div>
            )}

            {transport.state === ERemoteDataState.Ready && (
              <div className="col-7">
                <TransportinformatieTegel />
              </div>
            )}

            {servicediensten.state === ERemoteDataState.Ready && (
              <div className="col-5">
                <ServicedienstTegel />
              </div>
            )}

            {/* {inkoopopdrachtenVerwacht.state === ERemoteDataState.Ready && (
              <div className="col-12">
                <VerwachteInkoopleveringenTegel />
              </div>
            )} */}

            {/* {financieleInfo.state === ERemoteDataState.Ready && (
              <div className="col-6">
                <FinancieleinformatieTegel />
              </div>
            )} */}

            {inkoopopdrachtenVerlopen.state === ERemoteDataState.Ready &&
              inkoopopdrachtenVerlopen.data!.length > 0 && (
                <div className="col-12">
                  <VerlopenInkoopOpdrachtenTegel />
                </div>
              )}

            {lopendeActies.state === ERemoteDataState.Ready && (
              <div className="col-12">
                <LopendeActies />
              </div>
            )}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className="row">
            <div className="col-12">
              <OnlineGebruikers />
            </div>
            <div className="col-12">
              <VoipAccountsVigerend />
            </div>
          </div>
        </div>
      </div>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
