import React, { useCallback, useMemo, useRef, useState } from 'react';
import { format, setHours, setMinutes } from 'date-fns';
import nl from 'date-fns/locale/nl';
import InactiefOverlay from '../../InactiefOverlay';

interface IProps {
  /**
   * Als waarde `null` is dan wordt het component als inactief beschouwd (een tijd wordt altijd
   * gekozen bij een datum)
   */
  waarde: Date | null;
  onChange: (waarde: Date) => void;
  onInputRef?: (ref: HTMLInputElement) => void;
}

const TijdKiezer = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const handleInputRef = useCallback(
    (ref: HTMLInputElement) => {
      inputRef.current = ref;
      if (props.onInputRef !== undefined) {
        props.onInputRef(ref);
      }
    },
    [props.onInputRef],
  );

  const tekstWaarde = useMemo(
    () => (props.waarde === null ? '' : format(props.waarde, 'HH:mm', { locale: nl })),
    [props.waarde],
  );
  const [input, setInput] = useState(tekstWaarde);

  const [focused, setFocused] = useState(false);

  const weergaveTekst = useMemo(
    () => (props.waarde === null ? '' : focused ? input : tekstWaarde),
    [input, focused, props.waarde],
  );

  return (
    <InactiefOverlay
      isInactief={props.waarde === null}
      element={
        <input
          ref={handleInputRef}
          className="form-control"
          placeholder="00:00"
          style={{ width: 60 }}
          value={weergaveTekst}
          onChange={(ev) => setInput(ev.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);

            const setZeroedDate = () => {
              let newDate = setHours(props.waarde!, 0);
              newDate = setMinutes(newDate, 0);
              props.onChange(newDate);
            };

            if (input.indexOf(':') === -1) {
              setZeroedDate();
              return;
            }
            const [urenStr, minutenStr] = input.split(':');
            const urenInput = Number(urenStr);
            if (isNaN(urenInput)) {
              setZeroedDate();
              return;
            }
            const minutenInput = Number(minutenStr);
            if (isNaN(minutenInput)) {
              setZeroedDate();
              return;
            }
            const uren = urenInput > 23 ? 23 : urenInput <= 0 ? 0 : urenInput;
            const minuten = minutenInput > 59 ? 59 : minutenInput <= 0 ? 0 : minutenInput;

            let newDate = setHours(props.waarde!, uren);
            newDate = setMinutes(newDate, minuten);
            props.onChange(newDate);
          }}
        />
      }
    />
  );
};

export default TijdKiezer;
