import React from 'react';
import { TableRowDetail as TableRowDetailBase } from '@devexpress/dx-react-grid';
import { IOphalenControlTakenResultElement } from '../../../../../../shared/src/api/v2/control/control';
import { TableRowDetailWrapper } from '../../../../helpers/dxTableGrid';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { IOphalenTakenResultElement } from '../../../../../../shared/src/api/v2/taken';

const Detailregel = (props: TableRowDetailBase.ContentProps) => {
  const row: IOphalenTakenResultElement = props.row;
  // return (
  //   <TableRowDetailWrapper>
  //     <div className="row d-flex">
  //       <TabelInspringBlok />

  //       <div className="d-flex p-3">
  //         <GegevensLayout
  //           gegevens={[
  //             {
  //               label: 'Omschrijving',
  //               waarde: <span>{row.Omschrijving}</span>,
  //             },
  //           ]}
  //         />
  //       </div>
  //     </div>
  //   </TableRowDetailWrapper>
  // );
  return (
    <TableRowDetailWrapper>
      <div className="row d-flex">
        <TabelInspringBlok />
        <span
          className="p-4"
          style={{
            overflowWrap: 'break-word',
            maxWidth: 1000,
          }}
        >
          {row.Omschrijving}
        </span>
      </div>
    </TableRowDetailWrapper>
  );
};

export default Detailregel;
