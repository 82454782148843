import React, { useCallback, useContext, useEffect, useState } from 'react';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { IconVerwijderen, IconToevoegen } from '../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import ActieMenuKnop from '../../../../../../../components/ActieMenuKnop';
import SelectieVak from '../../../../../../../components/SelectieVak';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  IOphalenMeldingenResultElement,
  IOphalenTerugkoppelingResultElement,
} from '../../../../../../../../../shared/src/api/v2/service/melding';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../shared/src/api/v2/service/opdracht';
import MeldingTegel from './MeldingTegel';
import { RouteComponentProps, withRouter } from 'react-router';
import useUrlState from '../../../../../../../core/useUrlState';
import { EResultType } from '../../../../../../../stores/CheckStore';
import ServiceMeldingDialoog from './NieuweMeldingDialoog';
import NieuwOpdrachtDialoog from './opdracht/NieuwOpdrachtDialoog';
import _ from 'lodash';
import { IEntiteitProps } from '../../../../../../../components/kaart/EntiteitContainer';
import { ServiceContext } from '../index';
import IPaginatiePositie from '../../../../../../../../../shared/src/models/IPaginatiePositie';
import { IOphalenGebruikersResultElement } from '../../../../../../../../../shared/src/api/v2/gebruiker';

export interface IUrlState {
  selectie: number[];
  serviceMeldingDialoogTonen: boolean;
  nieuwOpdrachtDialoogTonen: boolean;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  serviceMeldingDialoogTonen: false,
  nieuwOpdrachtDialoogTonen: false,
};

export interface IRow extends IOphalenMeldingenResultElement {
  reparatieopdrachten: IOphalenOpdrachtenResultElement[];
  aspGebruiker: IOphalenGebruikersResultElement | null;
  terugkoppeling: IOphalenTerugkoppelingResultElement | null;
}

interface IProps extends IEntiteitProps, RouteComponentProps {}

const Meldingen: React.FC<IProps> = observer((props) => {
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const serviceContext = useContext(ServiceContext);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [meldingen, setMeldingen] = useState<IRow[] | null>(null);

  const ophalenMeldingen = useCallback(async () => {
    // const cntBasisIDs = _.uniq(
    //   (
    //     await api.v2.contract.ophalenContractenV2({
    //       filterSchema: { filters: [{ naam: 'REL_IDS', data: [serviceContext.relID] }] },
    //     })
    //   ).contracten.map((x) => x.basis.CntBasisID),
    // );

    const cntIDs = _.uniq(
      (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'REL_IDS', data: [serviceContext.relID] }] },
        })
      ).contracten.map((x) => x.CntID),
    );

    const paginatie: IPaginatiePositie = {
      index: 0,
      aantal: 200,
    };

    const meldingenResult = await api.v2.service.ophalenMeldingen({
      filterSchema: {
        // filters: [{ naam: 'RELID', data: [serviceContext.relID] }],
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'RELID',
                data: [serviceContext.relID],
              },
            },
            // {
            //   filter: {
            //     naam: 'CNTBASIS_IDS',
            //     data: cntBasisIDs,
            //   },
            // },
            {
              filter: {
                naam: 'CNT_IDS',
                data: cntIDs,
              },
            },
          ],
        },
      },
      orderSchema: { orders: [{ naam: 'MELDDATUM', richting: 'DESC' }] },
      paginatie,
    });

    const servMeldIDs = meldingenResult.meldingen.map((x) => x.ID);
    const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'SERVMELD_IDS', data: servMeldIDs }] },
    });
    const opdrachten = opdrachtenResult.opdrachten;
    const opdrachtenGesorteerd = _.orderBy(
      opdrachten,
      [
        (x: any) => {
          return x.DatumVerstuurd !== null
            ? x.DatumVerstuurd
            : x.RecordToegevoegd !== null
            ? x.RecordToegevoegd
            : '2199-01-01';
        },
      ],
      ['desc'],
    );

    const aspGebrIDs = _.uniq(meldingenResult.meldingen.map((x) => x.AspGebrID));
    const gebruikersResult = (
      await api.v2.aspGebruiker.ophalenGebruikers({
        filterSchema: { filters: [{ naam: 'ASP_GEBR_IDS', data: aspGebrIDs }] },
      })
    ).gebruikers;

    const terugkoppelingenResult = await api.v2.service.ophalenTerugkoppelingen({
      filterSchema: {
        filters: [
          {
            naam: 'SERV_MELD_IDS',
            data: servMeldIDs,
          },
        ],
      },
    });

    const meldingen = meldingenResult.meldingen.map((melding) => {
      const opdrachtenVoorMelding = opdrachtenGesorteerd.filter(
        (x: any) => x.melding.ID === melding.ID,
      );

      const aspGebruiker =
        melding.AspGebrID !== null
          ? gebruikersResult.find((x) => x.AspGebrID === melding.AspGebrID) ?? null
          : null;

      const terugkoppeling =
        terugkoppelingenResult.terugkoppelingen.find((x) => x.ServMeldID === melding.ID) ?? null;

      return {
        ...melding,
        reparatieopdrachten: opdrachtenVoorMelding,
        aspGebruiker,
        terugkoppeling,
      };
    });

    setMeldingen(meldingen);
  }, [props.relID]);

  useEffect(() => {
    ophalenMeldingen();
  }, [ophalenMeldingen]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.service.checkVerwijderenMeldingen({
      IDs: urlState.selectie,
    });
    const controleResult = await checkStore.controleren({
      checkData,
    });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Wil je de melding(en) verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.verwijderenMeldingen({
      IDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    ophalenMeldingen();
  }, [ophalenMeldingen, urlState.selectie]);

  return (
    <>
      {/* <Root> */}
      {meldingen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <>
              <div className="d-flex align-items-center ml-4">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  // disabled={urlState.selectie.length !== 1}
                  onClick={() => {
                    return setUrlStateSync('serviceMeldingDialoogTonen', true);
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  &nbsp; Servicemelding
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length !== 1}
                  onClick={async () => {
                    const checkData = await api.v2.service.checkSelectieToevoegenOpdracht({
                      servMeldID: urlState.selectie[0],
                    });
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }
                    return setUrlStateSync('nieuwOpdrachtDialoogTonen', true);
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  &nbsp; Reparatieopdracht
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={handleVerwijderen}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  &nbsp; Verwijderen
                </button>
                <div className="flex-fill" />
                {/*<ActieMenuKnop acties={[]} />*/}
              </div>
              {/*<div className="d-flex align-items-center mt-3 ml-4">*/}
              {/*  <FilterBalk />*/}
              {/*</div>*/}
              <div className="mt-3 ml-4">
                <SelectieVak
                  aantal={urlState.selectie.length}
                  totaalAantal={meldingen!.length}
                  onChange={(alles) => {
                    if (alles) {
                      setUrlStateSync(
                        'selectie',
                        meldingen!.map((x) => x.ID),
                      );
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                  entiteitEnkelvoud="melding"
                  entiteitMeervoud="meldingen"
                />
              </div>
            </>
          }
          body={
            <div className="d-flex flex-column flex-fill" style={{ position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  width: '100%',
                }}
                className="d-flex flex-column flex-fill pl-3 pr-3 pt-3 pb-3"
              >
                {meldingen!.map((melding, i) => {
                  const selected = urlState.selectie.includes(melding.ID);

                  const onSelectedChange = (isSelected: boolean) => {
                    if (isSelected && !urlState.selectie.includes(melding.ID)) {
                      setUrlStateSync('selectie', [...urlState.selectie, melding.ID]);
                    } else if (!isSelected && urlState.selectie.includes(melding.ID)) {
                      setUrlStateSync(
                        'selectie',
                        urlState.selectie.filter((x) => x !== melding.ID),
                      );
                    }
                  };

                  return (
                    <div key={melding.ID} className={i === 0 ? '' : 'mt-2'}>
                      <MeldingTegel
                        melding={melding}
                        selected={selected}
                        onSelectedChange={onSelectedChange}
                        onRequestRefresh={ophalenMeldingen}
                        relID={props.relID}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          }
        />
      )}
      {/* </Root> */}
      {urlState.serviceMeldingDialoogTonen && (
        <ServiceMeldingDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('serviceMeldingDialoogTonen', false);
            setUrlStateSync('selectie', []);
            ophalenMeldingen();
          }}
          onAnnuleren={() => setUrlStateSync('serviceMeldingDialoogTonen', false)}
          relID={serviceContext.relID}
          // locID={null}
          // cntID={null}
          // prodID={null}
        />
      )}
      {urlState.nieuwOpdrachtDialoogTonen && (
        <NieuwOpdrachtDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('nieuwOpdrachtDialoogTonen', false);
            ophalenMeldingen();
          }}
          onAnnuleren={() => setUrlStateSync('nieuwOpdrachtDialoogTonen', false)}
          servMeldID={urlState.selectie[0]}
          relID={serviceContext.relID}
          cntID={null}
        />
      )}
    </>
  );
});

export default withRouter(Meldingen);
