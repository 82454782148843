import React from 'react';
import PaginaHeader from '../PaginaHeader';
import { NavLink, Route, Redirect } from 'react-router-dom';
import { CommunicatieNavigatie } from '../Communicatie/styles';
import { RouteComponentProps } from 'react-router-dom';
import Voorstellen from './Voorstellen';
import Contracten from './Contracten';
import Materiaal from './Materiaal';
import Diensten from '../Diensten/Beheer/Sponsoring';
import { IconDienst } from '../../components/Icons';

interface ISubpagina {
  url: string;
  titel: string;
}

const subpaginas: ISubpagina[] = [
  {
    url: 'voorstellen',
    titel: 'Voorstellen',
  },
  {
    url: 'contracten',
    titel: 'Contracten',
  },
  {
    url: 'materiaal',
    titel: 'Materiaal',
  },
  {
    url: 'diensten',
    titel: 'Sponsordiensten',
  },
];

interface IProps extends RouteComponentProps<{}> {}

const Sponsoring: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <CommunicatieNavigatie>
          {subpaginas.map((pagina) => {
            return (
              <li>
                <NavLink
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  className="d-flex align-items-center justify-content-center"
                  to={`${match.url}/${pagina.url}`}
                >
                  {/* <IconDienst style={{ width: 20, height: 20 }} /> */}
                  &nbsp; {pagina.titel}
                </NavLink>
              </li>
            );
          })}
        </CommunicatieNavigatie>
      </PaginaHeader>

      <Route
        exact
        path={match.path}
        component={() => <Redirect to={`${match.path}/${subpaginas[0].url}`} />}
      />

      <Route path={`${match.path}/voorstellen`} component={Voorstellen} />
      <Route path={`${match.path}/contracten`} component={Contracten} />
      <Route path={`${match.path}/materiaal`} component={Materiaal} />
      <Route path={`${match.path}/diensten`} component={Diensten} />
    </div>
  );
};

export default Sponsoring;
