import React, { useContext, useEffect, useMemo, useRef } from 'react';
import NavigatieOverlay from '../NavigatieOverlay';
import { createReadyRemoteData, ERemoteDataState } from '../../../models/IRemoteData';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import Memo from '../../../components/Memo';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import HorizontaleScheidingslijn from '../../../components/layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {}

const MemosOverlay: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, memoStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (memoStore.memos.state === ERemoteDataState.Ready) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    memoStore.ophalenMemos();
  }, [memoStore.memos]);

  const memos = useMemo(() => {
    if (memoStore.memos.state === ERemoteDataState.Pending) {
      return null;
    }
    return memoStore.memos.data!.filter(
      (memo) => memo.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
    );
  }, [memoStore.memos, gebruikerStore.gebruiker]);

  return (
    <NavigatieOverlay>
      <div style={{ fontSize: 12, width: 425, minHeight: 100 }} className="d-flex flex-column p-2">
        <div
          className="d-flex flex-fill flex-column p-3"
          style={{ background: Kleur.Wit, borderRadius: 5 }}
        >
          {memos === null ? (
            <div className="flex-fill d-flex justify-content-center align-items-center">
              <LoadingSpinner />
            </div>
          ) : (
            <div
              className="flex-fill d-flex flex-column"
              style={{
                maxHeight: 625,
                overflow: 'auto',
              }}
            >
              <a
                href="#"
                onClick={async (ev) => {
                  ev.preventDefault();

                  await api.v2.memo.toevoegenMemo({
                    inhoud: '',
                    relID: null,
                    aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                  });
                }}
              >
                Memo toevoegen
              </a>

              <HorizontaleScheidingslijn className="mt-2" />
              {memos.length === 0 ? (
                <div className="d-flex flex-fill align-items-center justify-content-center mt-2">
                  <span>Je hebt momenteel geen memos.</span>
                </div>
              ) : (
                memos.map((memo) => {
                  return (
                    <div className="d-flex flex-column mt-2">
                      <div className="d-flex align-items-center">
                        {memo.RelID !== null && (
                          <RelatieVisualisatie
                            relID={memo.RelID}
                            // Sluit overlay
                            onGenavigeerd={() => document.body.click()}
                          />
                        )}
                        <div className="flex-fill" />
                        <a
                          href="#"
                          onClick={async (ev) => {
                            ev.preventDefault();
                            await memoStore.verwijderMemo(memo.ID);
                          }}
                        >
                          Memo verwijderen
                        </a>
                      </div>
                      <Memo
                        memo={memo.Inhoud || ''}
                        onMemoChange={(x) => {
                          memoStore.wijzigMemo(memo.ID, {
                            ...memo,
                            Inhoud: x,
                          });
                        }}
                        geenAutoFocus
                        disabled={
                          memoStore.memoIDsInVerwerking.findIndex((x) => x === memo.ID) !== -1
                        }
                      />
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
      </div>
    </NavigatieOverlay>
  );
});

export default MemosOverlay;
