import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenInventarisatieregelsResultElement } from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import api from '../../../../api';
import { Kleur, Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { ITabbladComponentProps } from '../index';
import Combobox from '../../../../components/formulier/Combobox';
import WijzigenRegelDialoog from './WijzigenRegelDialoog';
import ProductinfoDialoog from '../../../../components/product/ProductinfoDialoog';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import { IconKruis, IconSend, IconWijzigen, IconZoekgemeld } from '../../../../components/Icons';
import {
  bepaalTekstOvereenkomingen,
  IBepaalTekstOvereenkomingOutput,
  ITekstMetSleutel,
} from '../../../../helpers/tekst';
import TekstOvereenkomingWeergave from '../../../../components/TekstOvereenkomingWeergave';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import InventarisatieInfoDialoog from '../InventarisatieInfoDialoog';
import { EProductstatus } from '../../../../bedrijfslogica/enums';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import toast from 'react-hot-toast';
import TableData from '../../../../components/tabel/ASPTabel/Body/TableData';
import {
  IOphalenProductsoortenResult,
  IOphalenProductsoortenResultElement,
} from '../../../../../../shared/src/api/v2/product/soort';

interface IProps extends ITabbladComponentProps, RouteComponentProps {}

enum EKolom {
  Merknaam,
  Typenaam,
  Categorie,
  Referentiecode,
  Nieuw,
  Inslagdatum,
  Partij,
  AantalIntern,
  AantalExtern,
  Notities,
  InventarisatieInfo,
  IsDefinitief,
  AantalAanwezig,
  AantalInLevering,
  Productstatus,
}

enum EFilter {
  Verschil = 'VERSCHIL',
  Definitief = 'IS_DEFINITIEF',
  Bron = 'BRON',
  Productsoort = 'PRODSRT_IDS',
}

interface IWijzigenRegelDialoogState {
  id: number;
}
interface IProductinfoDialoogState {
  id: number;
}
interface IInventarisatieInfoDialoogState {
  typeID: number;
  magInvID: number;
}

export interface IUrlState {
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
  filterData: IFilterData<EFilter>[];
  wijzigenRegelDialoogState: IWijzigenRegelDialoogState | null;
  productinfoDialoogState: IProductinfoDialoogState | null;
  inventarisatieDialoogState: IInventarisatieInfoDialoogState | null;
}

interface IKolomState {
  aantalAanwezigMuteren: boolean;
  aantalAanwezigMuterenWaarde: number;
}

const Lijst: React.FC<IProps> = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      wijzigenRegelDialoogState: null,
      productinfoDialoogState: null,
      inventarisatieDialoogState: null,
      filterData: [
        {
          naam: EFilter.Verschil,
          data: 1,
          isActief: false,
        },
        {
          naam: EFilter.Definitief,
          data: 2,
          isActief: false,
        },
        {
          naam: EFilter.Bron,
          data: 1,
          isActief: false,
        },
        {
          naam: EFilter.Productsoort,
          data: 11, // TODO
          isActief: false,
        },
      ],
      sortering: [
        {
          key: EKolom.Merknaam,
          sortering: ESortering.Ascending,
        },
        {
          key: EKolom.Typenaam,
          sortering: ESortering.Ascending,
        },
        {
          key: EKolom.Nieuw,
          sortering: ESortering.Ascending,
        },
        {
          key: EKolom.Referentiecode,
          sortering: ESortering.Ascending,
        },
      ],
    }),
    [],
  );

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'lijstState',
  );

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const [inventarisatieregels, setInventarisatieregels] = useState<
    IOphalenInventarisatieregelsResultElement[] | null
  >(null);

  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);

  const [productsoorten, setProductsoorten] = useState<IOphalenProductsoortenResult | null>(null);

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema: { filters: [{ naam: 'ACTIEF', data: true }] },
    });

    setProductsoorten(result);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const ophalenRegels = useCallback(async () => {
    const filterVerschil = filterSchema.filters!.find((x) => x.naam === EFilter.Verschil)!;
    const filterDefinitief = filterSchema.filters!.find((x) => x.naam === EFilter.Definitief)!;
    const filterBron = filterSchema.filters!.find((x) => x.naam === EFilter.Bron)!;
    const filterProductsoort = filterSchema.filters!.find((x) => x.naam === EFilter.Productsoort)!;

    const filters = [
      ...(filterVerschil !== undefined
        ? filterVerschil.data === 1
          ? [{ naam: 'MET_VERSCHIL', data: true }]
          : filterVerschil.data === 2
          ? [{ naam: 'MET_MANCO', data: true }]
          : filterVerschil.data === 3
          ? [{ naam: 'MET_SURPLUS', data: true }]
          : filterVerschil.data === 4
          ? [{ naam: 'GEEN_VERSCHIL', data: true }]
          : []
        : []),
      ...(filterDefinitief !== undefined
        ? filterDefinitief.data === 1
          ? [{ naam: 'IS_DEFINITIEF', data: true }]
          : filterDefinitief.data === 2
          ? [{ naam: 'IS_DEFINITIEF', data: false }]
          : []
        : []),
      ...(filterBron !== undefined
        ? filterBron.data === 1
          ? [{ naam: 'AANTAL_INTERN_IS_GROTER_DAN_NUL', data: true }]
          : filterBron.data === 2
          ? [{ naam: 'AANTAL_EXTERN_IS_GROTER_DAN_NUL', data: true }]
          : []
        : []),
      ...(filterProductsoort !== undefined
        ? [{ naam: 'PRODSRT_IDS', data: filterProductsoort.data }]
        : []),
    ];

    const inventarisatieregelsResult = await api.v2.magazijn.inventarisatie.ophalenInventarisatieregels(
      {
        filterSchema: {
          filters: [...filters, { naam: 'MAGINV_IDS', data: [props.inventarisatieID] }],
        },
      },
    );

    if (inventarisatieregelsResult.inventarisatieregels.length === 0) {
      setInventarisatieregels([]);
      return;
    }
    //
    // const regels = _.orderBy(
    //   inventarisatieregelsResult.inventarisatieregels,
    //   [
    //     (x: IOphalenInventarisatieregelsResultElement) => x.producttype.Merknaam,
    //     (x: IOphalenInventarisatieregelsResultElement) => x.producttype.Typenaam,
    //   ],
    //   ['asc', 'asc'],
    // );
    //
    setInventarisatieregels(inventarisatieregelsResult.inventarisatieregels);
  }, [props.inventarisatieID, filterSchema]);

  useEffect(() => {
    ophalenRegels();
  }, [ophalenRegels]);

  const ophalenProducten = useCallback(async () => {
    if (inventarisatieregels === null) {
      return;
    }
    const producten = (
      await api.v2.product.ophalenProductenV2({
        filterSchema: {
          filters: [
            {
              naam: 'REFERENTIECODES',
              data: inventarisatieregels
                .filter((x) => x.Referentiecode !== null)
                .map((x) => x.Referentiecode),
            },
          ],
        },
      })
    ).producten;

    setProducten(producten);
  }, [inventarisatieregels]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const typenaamOvereenkomingen = useMemo<IBepaalTekstOvereenkomingOutput | null>(() => {
    if (inventarisatieregels === null) {
      return null;
    }

    const tekstenMetSleutel = inventarisatieregels.map((x) => ({
      tekst: x.producttype.Typenaam,
      sleutel: x.producttype.TypeID,
    }));

    return bepaalTekstOvereenkomingen({
      tekstenMetSleutel: _.uniqBy(tekstenMetSleutel, (x: ITekstMetSleutel) => x.tekst),
      gelijkheidspercentage: 0.65,
    });
  }, [inventarisatieregels]);

  const [kolommenState, setKolommenState] = useState<Record<number, IKolomState>>({});

  const keyExtractor = useCallback(
    (item: IOphalenInventarisatieregelsResultElement) => item.ID,
    [],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenInventarisatieregelsResultElement>[]>(
    () => [
      {
        key: EKolom.Merknaam,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (x) => x.producttype.Merknaam,
        vergelijkingswaarde: (x) => x.producttype.Merknaam,
      },
      {
        key: EKolom.Typenaam,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 155,
        renderer: (x) => {
          if (typenaamOvereenkomingen === null) {
            return null;
          }

          return (
            <TekstOvereenkomingWeergave
              sleutel={x.producttype.TypeID}
              tekst={x.producttype.Typenaam}
              output={typenaamOvereenkomingen}
            />
          );
        },
        vergelijkingswaarde: (x) => x.producttype.Typenaam,
      },
      {
        key: EKolom.Categorie,
        label: 'Cat.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (x) => x.producttype.ProductsoortnaamKort,
      },
      {
        key: EKolom.Nieuw,
        label: 'N/G',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 70,
        renderer: (x) => (x.Nieuw ? 'N' : 'G'),
        vergelijkingswaarde: (x) => (x.Nieuw ? 0 : 1),
      },
      {
        key: EKolom.Referentiecode,
        label: 'Ref.code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => x.Referentiecode,
        vergelijkingswaarde: (x) => (x.Referentiecode !== null ? x.Referentiecode : ''),
      },
      {
        key: EKolom.Productstatus,
        label: 'Status',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x: IOphalenInventarisatieregelsResultElement) => x.productstatus.Naam,
        // vergelijkingswaarde: (x) => (x.Referentiecode !== null ? x.Referentiecode : ''),
      },
      {
        key: EKolom.AantalIntern,
        label: 'Int.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 70,
        renderer: (x) => {
          const filterData = filterSchema.filters!.find((x) => x.naam === EFilter.Bron)!;
          const filterIsActief = urlState.filterData.find((x) => x.naam === EFilter.Bron)?.isActief;
          // Filter op Extern? Dan lichtgrijs weergeven
          return filterIsActief && filterData.data === 2 ? (
            <span style={{ color: EKleur.LichtGrijs }}>{x.AantalIntern}</span>
          ) : (
            <span>{x.AantalIntern}</span>
          );
        },
      },
      {
        key: EKolom.AantalInLevering,
        label: 'Lev.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 70,
        renderer: (x) => {
          if (x.AantalInLevering === 0) {
            return <span></span>;
          }
          return <span style={{ color: EKleur.Grijs }}>{x.AantalInLevering}</span>;
        },
        tdComponent: (tdProps) => (
          <TableData
            {...tdProps}
            style={{ cursor: 'pointer' }}
            title="Van het Aantal intern is dit het aantal daarvan wat in een leveropdracht zit"
          />
        ),
      },
      {
        key: EKolom.AantalExtern,
        label: 'Ext.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 70,
        renderer: (x) => {
          const filterData = filterSchema.filters!.find((x) => x.naam === EFilter.Bron)!;
          const filterIsActief = urlState.filterData.find((x) => x.naam === EFilter.Bron)?.isActief;
          // Filter op Extern? Dan lichtgrijs weergeven
          return filterIsActief && filterData.data === 1 ? (
            <span style={{ color: EKleur.LichtGrijs }}>{x.AantalExtern}</span>
          ) : (
            <span>{x.AantalExtern}</span>
          );
        },
      },
      {
        key: EKolom.AantalAanwezig,
        label: '# Aanwezig',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 105,
        renderer: (x) => {
          const key = keyExtractor(x);
          const kolomState = kolommenState[key];
          if (kolomState?.aantalAanwezigMuteren ?? false) {
            const aantalMuteren = kolomState?.aantalAanwezigMuterenWaarde ?? 0;
            return (
              <div className="d-flex align-items-center flex-fill">
                <span className="mr-2" style={{ fontSize: 16, fontWeight: 'bold' }}>
                  {aantalMuteren === x.AantalIntern
                    ? '='
                    : aantalMuteren < x.AantalIntern
                    ? '-'
                    : '+'}
                </span>
                <NumeriekVeld
                  waarde={kolomState.aantalAanwezigMuterenWaarde}
                  onChange={(x) =>
                    setKolommenState((prev) => ({
                      ...prev,
                      [key]: {
                        ...prev[key],
                        aantalAanwezigMuterenWaarde: x,
                      },
                    }))
                  }
                  min={0}
                  rootProps={{ autoFocus: true }}
                  onBlur={async () => {
                    setKolommenState((prev) => ({
                      ...prev,
                      [key]: {
                        ...prev[key],
                        aantalAanwezigMuteren: false,
                      },
                    }));
                    setInventarisatieregels(
                      (prev) =>
                        prev?.map((inventarisatieregel) => {
                          if (inventarisatieregel.ID === key) {
                            return {
                              ...inventarisatieregel,
                              AantalAanwezig: kolomState.aantalAanwezigMuterenWaarde,
                            };
                          }
                          return inventarisatieregel;
                        }) ?? null,
                    );

                    await api.v2.magazijn.inventarisatie.wijzigenInventarisatieregel({
                      id: key,
                      aantalAanwezig: kolomState.aantalAanwezigMuterenWaarde,
                      notities: x.Notities,
                    });
                    props.onVerversenAangevraagd();
                  }}
                />
              </div>
            );
          }
          return (
            <span className="d-flex flex-fill align-items-center">
              <span className="flex-fill">{x.AantalAanwezig}</span>
              {!x.IsDefinitief && x.AantalAanwezig !== null && (
                <button
                  style={{
                    padding: 0,
                    border: 0,
                    background: 0,
                    outline: 0,
                    position: 'relative',
                    top: -1,
                  }}
                  title="Verwijderen aantal aanwezig"
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: 'Bevestig het verwijderen van het aantal aanwezig',
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    setInventarisatieregels(
                      (prev) =>
                        prev?.map((inventarisatieregel) => {
                          if (inventarisatieregel.ID === key) {
                            return {
                              ...inventarisatieregel,
                              AantalAanwezig: null,
                            };
                          }
                          return inventarisatieregel;
                        }) ?? null,
                    );

                    await api.v2.magazijn.inventarisatie.wijzigenInventarisatieregel({
                      id: key,
                      aantalAanwezig: null,
                      notities: x.Notities,
                    });
                    props.onVerversenAangevraagd();
                  }}
                >
                  <IconKruis style={{ fill: Kleur.Grijs, width: 17, height: 17 }} />
                </button>
              )}
              {!x.IsDefinitief && (
                <button
                  style={{
                    padding: 0,
                    border: 0,
                    background: 0,
                    outline: 0,
                    position: 'relative',
                    top: -1,
                    marginLeft: 5,
                  }}
                  title="Wijzigen aantal aanwezig"
                  onClick={() =>
                    setKolommenState((prev) => ({
                      ...prev,
                      [key]: {
                        ...prev[key],
                        aantalAanwezigMuteren: true,
                        aantalAanwezigMuterenWaarde: x.AantalAanwezig ?? 0,
                      },
                    }))
                  }
                >
                  <IconWijzigen style={{ fill: Kleur.Grijs, width: 15, height: 15 }} />
                </button>
              )}
            </span>
          );
        },
      },
      {
        key: EKolom.IsDefinitief,
        label: 'Def.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
        renderer: (x) => {
          if (x.IsDefinitief) {
            return <span style={{ color: EKleur.Groen }}>Def.</span>;
          }
          // return <span style={{ color: EKleur.Rood }}>Niet def.</span>;
          return <span style={{ color: EKleur.LichtGrijs }}>Niet</span>;
        },
      },
      // {
      //   key: EKolom.Productinfo,
      //   label: 'Info',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 60,
      //   renderer: (x) => {
      //     if (producten === null) {
      //       return;
      //     }
      //     const product =
      //       x.Referentiecode !== null
      //         ? producten.find((p) => p.Referentiecode === x.Referentiecode)!
      //         : null;

      //     if (product === null) {
      //       return <span></span>;
      //     }

      //     return (
      //       <a
      //         href="#"
      //         onClick={(ev) => {
      //           ev.preventDefault();
      //           setUrlStateSync('productinfoDialoogState', { id: product.ProdID });
      //         }}
      //         className="mr-1"
      //       >
      //         Info
      //       </a>
      //     );
      //   },
      // },
      {
        key: EKolom.InventarisatieInfo,
        label: '',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 60,
        renderer: (x) => {
          return (
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                setUrlStateSync('inventarisatieDialoogState', {
                  typeID: x.producttype.TypeID,
                  magInvID: x.MagInvID,
                });
              }}
              className="mr-1"
            >
              Info
            </a>
          );
        },
      },
      {
        key: EKolom.Notities,
        label: 'Notities',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => (
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={x.Notities ?? ''}
          >
            {x.Notities}
          </span>
        ),
      },
    ],
    [
      typenaamOvereenkomingen,
      producten,
      kolommenState,
      filterSchema.filters,
      props.onVerversenAangevraagd,
    ],
  );

  const filterVerschilopties = [
    { id: 1, label: 'Manco of Surplus' },
    { id: 2, label: 'Manco' },
    { id: 3, label: 'Surplus' },
    { id: 4, label: 'Geen' },
  ];

  const filterBronopties = [
    { id: 1, label: 'Intern' },
    { id: 2, label: 'Extern' },
  ];

  const filterDefinitiefopties = [
    { id: 1, label: 'Definitief' },
    { id: 2, label: 'Niet definitief' },
  ];

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Verschil,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">M/S</span>
              {filterVerschilopties === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  legeOptieTonen
                  opties={filterVerschilopties}
                />
              )}
            </div>
          );
        },
      },
      {
        naam: EFilter.Definitief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Stat.</span>
              {filterDefinitiefopties === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  opties={filterDefinitiefopties}
                />
              )}
            </div>
          );
        },
      },
      {
        naam: EFilter.Bron,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Bron</span>
              {filterBronopties === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  opties={filterBronopties}
                />
              )}
            </div>
          );
        },
      },
      {
        naam: EFilter.Productsoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Cat.</span>
              {productsoorten === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  opties={productsoorten.map((x) => {
                    return { id: x.ProdSrtID, label: x.Naam };
                  })}
                />
              )}
            </div>
          );
        },
      },
    ],
    [filterVerschilopties, filterDefinitiefopties, filterBronopties],
  );

  return (
    <>
      {inventarisatieregels === null || productsoorten === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <div>
              <div className="d-flex align-items-center">
                {/* <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                onClick={async () => {
                  const bestandenResult = await globaleRenderer.render<
                    IBestandenDragAndDropDialoogResult
                  >((renderProps) => (
                    <BestandenDragAndDropDialoog
                      open
                      onSuccess={(result) => renderProps.destroy(result)}
                      onAnnuleren={() => renderProps.destroy()}
                      toegestaneBestandstypen={[
                        {
                          weergaveNaam: 'CSV',
                          mediaType: 'text/csv',
                        },
                      ]}
                      selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
                    />
                  ));

                  if (bestandenResult === undefined) {
                    return;
                  }
                  const bestand = bestandenResult.bestanden[0];

                  let ladenResult: ILadenExterneInventarisatieResult;
                  try {
                    ladenResult = await api.v2.magazijn.inventarisatie.lijst.extern.ladenExterneInventarisatie(
                      {
                        bestandID: bestand.ID,
                      },
                    );
                  } catch (err) {
                    console.error(err);
                    toast.error('Fout bij het inlezen van de externe inventarisatie');
                    return;
                  }

                  await api.v2.magazijn.inventarisatie.lijst.extern.converterenExternInventarisatiebestand(
                    {
                      magInvExtBestID: ladenResult.MagInvExtBestID,
                      magInvID: props.inventarisatieID,
                    },
                  );

                  toast.success('Inlezen en converteren van externe inventarisatie gelukt');

                  return null;
                }}
              >
                <span className="ml-2">Inlezen extern bestand</span>
              </button> */}

                {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: `Inventarisaties samenvoegen?`,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.magazijn.inventarisatie.samenvoegenInventarisaties({
                    magInvID: props.inventarisatieID,
                  });

                  ophalenRegels();

                  toast.success('Samenvoegen inventarisaties gelukt');

                  return null;
                }}
              >
                <span className="ml-2">Samenvoegen inventarisaties</span>
              </button> */}

                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    const checkData = await api.v2.magazijn.inventarisatie.checkMarkerenDefinitief({
                      IDs: urlState.selectie,
                    });

                    const controlerenResult = await checkStore.controleren({ checkData });
                    if (controlerenResult.type === EResultType.Annuleren) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: (
                            <span>
                              Wil je de geselecteerde regels als definitief markeren?
                              <br />
                              <br />
                              Daarmee wordt aangegeven dat het aanwezige aantal vastgesteld is.
                            </span>
                          ),
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.magazijn.inventarisatie.markerenDefinitief({
                      IDs: urlState.selectie,
                    });

                    setUrlStateSync('selectie', []);
                    ophalenRegels();
                    props.onVerversenAangevraagd();

                    return null;
                  }}
                >
                  <span className="ml-2">Def. markeren</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    const checkData = await api.v2.magazijn.inventarisatie.checkMarkerenNietDefinitief(
                      {
                        IDs: urlState.selectie,
                      },
                    );

                    const controlerenResult = await checkStore.controleren({ checkData });
                    if (controlerenResult.type === EResultType.Annuleren) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: (
                            <span>
                              Wil je de geselecteerde regels als niet-definitief markeren?
                            </span>
                          ),
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.magazijn.inventarisatie.markerenNietDefinitief({
                      IDs: urlState.selectie,
                    });

                    setUrlStateSync('selectie', []);
                    ophalenRegels();
                    props.onVerversenAangevraagd();

                    return null;
                  }}
                >
                  <span className="ml-2">Niet-def. markeren</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length !== 1}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: <span>Wil je 1 product hiervoor zoekmelden?</span>,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    // Haal de tyepe/nieuw/ref.code op van de geselecteerde regel
                    const regel = inventarisatieregels.find((x) => x.ID === urlState.selectie[0])!;

                    const inventarisatie = (
                      await api.v2.magazijn.inventarisatie.ophalenInventarisaties({
                        filterSchema: { filters: [{ naam: 'IDS', data: [regel.MagInvID] }] },
                      })
                    ).inventarisaties[0];

                    // Zoek daarmee een product in de voorraad
                    const producten = (
                      await api.v2.product.ophalenProductenV2({
                        filterSchema: {
                          filters: [
                            { naam: 'LOC_IDS', data: [inventarisatie.magazijn.LocID] },
                            { naam: 'TYPE_IDS', data: [regel.producttype.TypeID] },
                            { naam: 'PRODUCTSTATUS_NAAM_ENUMS', data: [EProductstatus.Voorraad] },
                            { naam: 'IS_ZOEK', data: false },
                            { naam: 'IS_NIEUW', data: regel.Nieuw },

                            regel.Referentiecode !== null
                              ? { naam: 'REFERENTIECODES', data: [regel.Referentiecode] }
                              : { naam: 'REFERENTIECODE_IS_LEEG', data: true },
                          ],
                        },
                      })
                    ).producten;

                    if (producten.length === 0) {
                      alert('Er kan geen gekoppeld product gevonden worden');
                      return;
                    }
                    const product = producten[0];

                    // Meld het product als zoek
                    await api.v2.retourverwerking.zoekMeldenProducten({
                      magID: inventarisatie.magazijn.MagID,
                      prodIDs: [product.ProdID],
                    });

                    // alert(JSON.stringify(producten));

                    setUrlStateSync('selectie', []);
                    ophalenRegels();

                    toast.success('Zoekmelden gelukt');

                    return;
                  }}
                >
                  <IconZoekgemeld style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Zoekmelden</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: <span>Lijst versturen?</span>,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.magazijn.inventarisatie.versturenVerschillijst({
                      magInvID: props.inventarisatieID,
                    });

                    ophalenRegels();

                    // toast.success('Verwijderen externe lijst gelukt');

                    return;
                  }}
                >
                  <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Verschillijst</span>
                </button>
              </div>
              <div className="d-flex flex-fill mt-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          }
          body={
            <ASPTabel
              rijen={inventarisatieregels}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              leegComponent={(leegProps) => (
                <div
                  style={{ ...leegProps }}
                  className="d-flex align-items-center justify-content-center"
                >
                  Geen regels gevonden
                </div>
              )}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              sortering={urlState.sortering}
              onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
              lokaalSorteren
              onWijzigenRij={async (rij) => {
                setUrlStateSync('wijzigenRegelDialoogState', {
                  id: rij.ID,
                });
              }}
            />
          }
        />
      )}
      {urlState.wijzigenRegelDialoogState !== null && (
        <WijzigenRegelDialoog
          open
          id={urlState.wijzigenRegelDialoogState.id}
          onSuccess={() => {
            ophalenRegels();
            props.onVerversenAangevraagd();
            setUrlStateSync('wijzigenRegelDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenRegelDialoogState', null)}
        />
      )}
      {urlState.productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={urlState.productinfoDialoogState.id}
          onSuccess={() => {
            ophalenRegels();
            props.onVerversenAangevraagd();
            setUrlStateSync('productinfoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('productinfoDialoogState', null)}
        />
      )}
      {urlState.inventarisatieDialoogState !== null && (
        <InventarisatieInfoDialoog
          open
          typeID={urlState.inventarisatieDialoogState.typeID}
          magInvID={urlState.inventarisatieDialoogState.magInvID}
          onSuccess={() => {
            ophalenRegels();
            props.onVerversenAangevraagd();
            setUrlStateSync('inventarisatieDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('inventarisatieDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Lijst);
// function setFilterSchema(x: IFilterSchema): void {
//   throw new Error('Function not implemented.');
// }
