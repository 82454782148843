import React, { useMemo, useRef } from 'react';
import { Field, FieldProps } from 'formik';
import VinkVeld from '../../../VinkVeld';
import IRemoteData, { ERemoteDataState } from '../../../../../models/IRemoteData';
import { IFormikValues, veldnamen } from '../index';
import useGebruikersStempel, {
  IPlaatsStempelOutput,
} from '../../../../../core/useGebruikersStempel';
import nameOf from '../../../../../core/nameOf';
import { IOphalenLocatieResult } from '../../../../../../../shared/src/api/v2/locatie/locatie';
import TijdvakVeld from '../../../TijdvakVeld';

export interface IAlgemeenTabbladProps {
  values: IFormikValues;
  setFieldValue: (field: string, value: any) => void;
  bijzonderhedenPlaatsStempelOutput: IPlaatsStempelOutput | null;
  onBijzonderhedenPlaatsStempelOutputChange: (output: IPlaatsStempelOutput | null) => void;
  bestaandeLocatieBepaald: IRemoteData<IOphalenLocatieResult | null>;
}

const AlgemeenTabblad = (props: IAlgemeenTabbladProps) => {
  const {
    values,
    setFieldValue,
    bijzonderhedenPlaatsStempelOutput,
    onBijzonderhedenPlaatsStempelOutputChange,
    bestaandeLocatieBepaald,
  } = props;
  const notitiesRef = useRef<HTMLTextAreaElement>(null);

  const { plaatsStempel, bezigMetStempelen } = useGebruikersStempel(
    useMemo(
      () => ({
        inputRef: notitiesRef,
        text: values.bijzonderheden,
        onTextChange: (x) => setFieldValue(nameOf<IFormikValues>('bijzonderheden'), x),
      }),
      [values.bijzonderheden, setFieldValue],
    ),
  );

  return (
    <div className="form-group pl-3 pr-3 pt-2 pb-2">
      <div className="row">
        <div className="col-12 d-flex align-items-center mt-3">
          <Field
            name="liftAanwezig"
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <VinkVeld
                  aangevinkt={field.value}
                  onGewijzigd={(x) => {
                    form.setFieldValue(field.name, x);
                  }}
                />
              );
            }}
          />
          <span className="ml-2">{veldnamen.liftAanwezig}</span>
        </div>
        <div className="col-12 mt-3">
          <label>{veldnamen.bezoekinstructies}</label>
          <Field
            name="bezoekinstructies"
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Past niet door de deur"
                  {...field}
                  disabled={bestaandeLocatieBepaald.state === ERemoteDataState.Pending}
                />
              );
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <label>{veldnamen.bereikbaarTijdvak}</label>
          <Field
            name={nameOf<IFormikValues>('bereikbaarTijdvak')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <TijdvakVeld
                  value={field.value}
                  onChange={(x) => {
                    form.setFieldValue(field.name, x);
                  }}
                  enkeleToestaan
                />
              );
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <div className="d-flex align-items-center">
            <label>{veldnamen.bijzonderheden}</label>
            <div className="flex-fill" />
            {bijzonderhedenPlaatsStempelOutput === null ? (
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  const output = plaatsStempel();
                  onBijzonderhedenPlaatsStempelOutputChange(output);
                }}
              >
                Stempel
              </a>
            ) : (
              <>
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();

                    bijzonderhedenPlaatsStempelOutput!.herstellen();
                    onBijzonderhedenPlaatsStempelOutputChange(null);
                  }}
                >
                  Herstellen
                </a>
                <a
                  className="ml-2"
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();

                    bijzonderhedenPlaatsStempelOutput!.doorvoeren();
                    onBijzonderhedenPlaatsStempelOutputChange(null);
                  }}
                >
                  Doorvoeren
                </a>
              </>
            )}
          </div>
          <Field
            name="bijzonderheden"
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <textarea
                  ref={notitiesRef}
                  disabled={
                    bestaandeLocatieBepaald.state === ERemoteDataState.Pending
                    // bezigMetStempelen
                  }
                  rows={6}
                  className="form-control"
                  placeholder="De hond blaft veel"
                  {...field}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AlgemeenTabblad;
