import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import {
  ICheckWijzigenInkoopfactuurToegestaanParams,
  IOphalenFacturenResultElement,
} from '../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import WijzigenFactuurDialoog from '../../../../components/inkoopfactuur/WijzigenFactuurDialoog';

import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Column,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  IconAttachment,
  IconVerwijderen,
  IconToevoegen,
  IconVink,
  IconBetaling,
  IconKruis,
  IconInformatie,
  IconBlock,
} from '../../../../components/Icons';
import { addDays, format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RowDetailComp from './RowDetailComp';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import BetaalopdrachtDialoog from './BetaalopdrachtDialoog';
import MenuLayout from '../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { dagDatum } from '../../../../helpers/datum';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import {
  IFilterSchemaFilter,
  IFilterSchemaUitgebreideFilter,
} from '../../../../../../shared/src/models/filter';
import DownloadKnop from '../../../../components/DownloadKnop';
import downloadUrl from '../../../../core/downloadUrl';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import useSelectieCheck from '../../../../core/useSelectieCheck';
import nameOf from '../../../../core/nameOf';
import SelectieVak from '../../../../components/SelectieVak';
import FactuurinfoDialoog from '../../../../components/inkoopfactuur/FactuurinfoDialoog';
import styled from 'styled-components';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import InkoopfactuurVisualisatie from '../../../../components/entiteitVisualisaties/InkoopfactuurVisualisatie';
import VerrekenenDialoog from '../../../../components/inkoopfactuur/VerrekenenDialoog';
import { Helmet } from 'react-helmet';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { IOphalenBetaalwijzenResultElement } from '../../../../../../shared/src/api/v2/betaalwijze';
import MultiSelect from '../../../../components/formulier/MultiSelect';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';

interface IProps extends RouteComponentProps {}

export interface IBetaalopdrachtDialoogState {
  inkFactIDs: number[];
}
export interface IWijzigenFactuurDialoogState {
  inkFactID: number;
}

export interface IFactuurinfoDialoogState {
  inkFactID: number;
}

export interface IVerrekenenDialoogState {
  inkFactID: number;
}
export interface IUrlState {
  selectie: number[];
  betaalopdrachtDialoogState: IBetaalopdrachtDialoogState | null;
  wijzigenFactuurDialoogState: IWijzigenFactuurDialoogState | null;
  factuurinfoDialoogState: IFactuurinfoDialoogState | null;
  verrekenenDialoogState: IVerrekenenDialoogState | null;
  filterdata: IFilterData<EFilter>[];
}
// const defaultUrlState: IUrlState = {
//   selectie: [],
//   betaalopdrachtDialoogState: null,
//   wijzigenFactuurDialoogState: null,
//   factuurinfoDialoogState: null,
//   verrekenenDialoogState: null,
// };

export interface IRow extends IOphalenFacturenResultElement {
  // boeking: IOphalenJournaalpostenResultElement | null;
  relatie: IOphalenRelatiesResultElementV2;
  bankopdrachten: IOphalenOpdrachtenResultElement[];
}

interface IFormatterProps extends IRow {}

const geenData = {
  noData: 'Geen inkoopfacturen gevonden',
};

// interface IOphoudenTrProps {
//   nietKleuren?: boolean;
// }

// const OphoudenTr = styled.tr<IOphoudenTrProps>`
//   td {
//     ${(props) =>
//       props.nietKleuren
//         ? ''
//         : `
//       color: ${EKleur.Rood} !important;
//     `}
//   }
// `;

enum EFilter {
  Betaalwijze = 'BETAALWIJZE_IDS',
  Vervaldatum = 'VERVALDATUM_KORTING_TEM',
  // AlleenBetaalbaarBijVerzamelbetaling = 'ALLEEN_BETAALBAAR_BIJ_VERZAMELBETALING',
}

const Betalen: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      selectie: [],
      betaalopdrachtDialoogState: null,
      wijzigenFactuurDialoogState: null,
      factuurinfoDialoogState: null,
      verrekenenDialoogState: null,
      filterdata: [
        {
          naam: EFilter.Betaalwijze,
          data: [1, 10], // TODO Dennis tblBetWijze VERZAMELBETALING  VERREKENEN
          isActief: true,
        },
        {
          naam: EFilter.Vervaldatum,
          data: dagDatum(addDays(new Date(), 7)).toISOString(),
          isActief: false,
        },
        // { naam: EFilter.AlleenBetaalbaarBijVerzamelbetaling, data: true, isActief: false },
      ],
    };
  }, []);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [facturen, setFacturen] = useState<IRow[] | null>(null);
  const [bankRekID, setBankRekID] = useState<number | null>(null);
  const [filterBetaalwijze, setFilterBetaalwijze] = useState<boolean>(false);
  const [filterBetaalbaar, setFilterBetaalbaar] = useState<boolean>(false);
  const [filterNietGeboekt, setFilterNietGeboekt] = useState<boolean>(false);

  // const [filterData, setFilterData] = useState<IFilterData<EFilter>[]>([
  //   {
  //     naam: EFilter.Betaalwijze,
  //     isActief: false,
  //     data: [1], // TODO Dennis
  //   },
  // ]);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  // const filterSchema = useMemo(() => maakFilterSchema(filterData), [filterData]);

  const keyExtractor = useCallback((row: IRow) => row.InkFactID, []);

  useSelectieCheck(
    useMemo(
      () => ({
        selectie: urlState.selectie,
        onSelectieChange: (x: number[]) => setUrlStateSync('selectie', x),
        sleutels: facturen === null ? null : facturen.map(keyExtractor),
      }),
      [facturen],
    ),
  );

  const ophalenInstelling = useCallback(async () => {
    const instelling = await api.v2.instelling.ophalenInstellingen({});
    setBankRekID(instelling.CrediteurenBetalingen_BankRekID);
  }, []);

  useEffect(() => {
    ophalenInstelling();
  }, [ophalenInstelling]);

  const ophalenFacturen = useCallback(async () => {
    // Inkoopfacturen ophalen die voldoen aan de criteria om betaald te kunnen worden

    const betaalbaarFilter = filterBetaalbaar
      ? [
          {
            and: [
              { filter: { naam: 'BETAALWIJZE_NAAMENUMS', data: ['VERZAMELBETALING'] } },
              { filter: { naam: 'IN_LOPENDE_BANKOPDRACHT', data: false } },
              { filter: { naam: 'AANHOUDEN', data: false } },
              { filter: { naam: 'IN_UITGEVOERDE_EN_NIET_GEBOEKTE_BANKOPDRACHT', data: false } },
            ],
          },
        ]
      : null;

    const filterschema = {
      uitgebreideFilter: {
        and: [
          ...(filterSchema.filters?.map((filter) => ({
            filter,
          })) ?? []),
          {
            filter: {
              naam: 'IS_GEFIATTEERD',
              data: true,
            },
          },
          {
            filter: {
              naam: 'IS_OPENSTAAND',
              data: true,
            },
          },
          filterNietGeboekt
            ? null
            : {
                filter: {
                  naam: 'IS_GEBOEKT',
                  data: true,
                },
              },
          betaalbaarFilter !== null
            ? {
                or: [
                  ...betaalbaarFilter,
                  {
                    filter: {
                      naam: 'BETAALWIJZE_NAAMENUMS',
                      data: ['VERZAMELBETALING'],
                      inverteren: true,
                    },
                  },
                ],
              }
            : null,
        ].filter((x) => x !== null) as IFilterSchemaUitgebreideFilter[],
      },
    };

    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: filterschema,
      // filterSchema: {
      //   filters: [
      //     filterBetaalbaar,
      //     ...filterSchema.filters!,
      //     { naam: 'IS_GEFIATTEERD', data: true },
      //     { naam: 'IS_OPENSTAAND', data: true },
      //     filterNietGeboekt ? null : { naam: 'IS_GEBOEKT', data: true },
      //   ].filter((x) => x !== null) as IFilterSchemaFilter[],
      // },
      orderSchema: {
        orders: [
          { naam: 'FACTUURDATUM', richting: 'DESC' },
          { naam: 'FACTUURNUMMER', richting: 'DESC' },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 150,
      },
    });
    if (facturenResult.facturen.length === 0) {
      setFacturen([]);
      return;
    }

    // Sorteer facturen
    // const facturenGesorteerd: IOphalenFacturenResultElement[] = _.orderBy(
    //   facturenResult.facturen,
    //   ['Factuurdatum'],
    //   ['desc'],
    // );

    // Ophalen relatie-gegevens
    const relIDs = _.uniq(facturenResult.facturen.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    // Ophalen bankopdrachten
    const bankopdrachtenResult = _.flatten(
      facturenResult.facturen.map((x) => x.bankopdrachtenData),
    );
    const bankOpdIDs = bankopdrachtenResult.map((x: any) => x.BankOpdID);
    const bankopdrachten = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: bankOpdIDs }] },
      })
    ).opdrachten;

    const facturen = facturenResult.facturen.map((f: IOphalenFacturenResultElement) => {
      const relatie = relatiesResult.relaties.find((r) => r.RelID === f.RelID)!;

      const bankOpdIDs = f.bankopdrachtenData.map((x) => x.BankOpdID);

      // Selecteer alleen de relevante bankopdrachten
      const bankopdrachtenVoorFactuur = bankopdrachten
        .filter((x) => bankOpdIDs.indexOf(x.BankOpdID) !== -1)
        .filter(
          (x: IOphalenOpdrachtenResultElement) =>
            x.batch === null ||
            x.batch.bankmutatie === null ||
            (x.batch.bankmutatie !== null && !x.batch.bankmutatie.Geboekt),
          // (x.batch.bankmutatie !== null &&
          //   !(x.batch.bankmutatie.boeking !== null && x.batch.bankmutatie.boeking.Definitief)),
        );
      const rij = { ...f, relatie, bankopdrachten: bankopdrachtenVoorFactuur };
      return rij;
    });

    setFacturen(facturen);
  }, [filterBetaalwijze, filterBetaalbaar, filterNietGeboekt, filterSchema]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const handleVerwijderenBankopdracht = useCallback(
    async (bankOpdID: number, inkFactID: number) => {
      const params = { bankOpdID: bankOpdID, inkFactIDs: [inkFactID] };

      const checkData = await api.v2.bank.opdracht.checkVerwijderenInkoopfacturenUitOpdracht(
        params,
      );
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je deze factuur uit de bankopdracht verwijderen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const result = await api.v2.bank.opdracht.verwijderenInkoopfacturenUitOpdracht(params);

      ophalenFacturen();
    },
    [ophalenFacturen],
  );

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: '__infoFactuur' as any,
        title: ' ',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: 'Geboekt',
        title: 'Geboekt',
      },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      {
        name: '__relatie' as any,
        title: 'Naam',
        getCellValue: (x: IRow) => {
          return x.relatie.weergavenaam;
        },
      },
      {
        name: '__heeftBestanden' as any,
        title: 'Bijl.',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
        getCellValue: (x) => {
          return x.Vervaldatum;
        },
      },
      {
        name: 'VervaldatumKorting',
        title: 'Bij korting',
      },
      {
        name: 'betaalwijze',
        title: 'Bet.wijze',
      },
      {
        name: 'Aanhouden',
        title: ' ',
      },
      // {
      //   name: 'Aanhouden',
      //   title: 'Aanh.',
      // },
      {
        name: '__bankopdracht' as any,
        title: 'Bankopdracht',
      },

      // {
      //   name: '__heeftBestanden' as any,
      //   title: 'Bijl.',
      // },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Factuurdatum',
        width: 110,
      },
      {
        columnName: 'Factuurnummer',
        width: 150,
      },
      {
        columnName: '__infoFactuur' as any,
        width: 60,
      },
      {
        columnName: 'Aanhouden',
        width: 60,
      },
      {
        columnName: '__relatie' as any,
        width: 150,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 80,
      },
      {
        columnName: 'Openstaand',
        width: 120,
      },
      {
        columnName: 'Geboekt',
        width: 100,
      },
      {
        columnName: 'Onderwerp',
        width: 250,
      },
      {
        columnName: 'Vervaldatum',
        width: 120,
      },
      {
        columnName: 'VervaldatumKorting',
        width: 110,
      },
      {
        columnName: 'betaalwijze',
        width: 95,
      },
      // {
      //   columnName: 'Aanhouden',
      //   width: 75,
      // },
      {
        columnName: '__bankopdracht' as any,
        width: 225,
      },
      // {
      //   columnName: '__heeftBestanden' as any,
      //   width: 100,
      // },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  const BankOpdracht: React.FC<IFormatterProps> = (rij) => {
    const bankopdrachten = rij.bankopdrachten;

    // In deze context alleen bankopdrachten die:
    // - niet in een batch zitten
    // - in een batch die nog niet uitgevoerd is
    // - in een batch die uitgevoerd is maar nog niet geboekt

    const batchIDs = _.uniq(
      bankopdrachten.filter((x) => x.batch !== null).map((x) => x.batch!.BatchID),
    );
    const bankmutaties = api.v2.bank.mutatie.ophalenMutaties({
      filterSchema: { filters: [{ naam: 'IDS', data: batchIDs }] },
    });

    if (bankopdrachten.length === 0) {
      return <span></span>;
    }

    // const opdrachtdatums = bankopdrachten
    //   .map((x) => {
    //     const datum =
    //       x.Uitvoerdatum !== null ? format(new Date(x.Uitvoerdatum), 'dd-MM-yyyy') : 'Zsm';
    //     return `${datum}`;
    //   })
    //   .join(', ');

    const opdrachtdatums = bankopdrachten.map((x, i) => {
      const datum =
        x.Uitvoerdatum !== null ? format(new Date(x.Uitvoerdatum), 'dd-MM-yyyy') : 'Zsm';

      return (
        <div className="d-flex align-items-center">
          <span>{datum}</span>
          <>
            {x.batch === null && (
              <>
                <span
                  className="ml-2"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    handleVerwijderenBankopdracht(x.BankOpdID, rij.InkFactID);
                  }}
                >
                  <IconKruis
                    style={{
                      fill: EKleur.Grijs,
                      width: 18,
                      height: 18,
                      cursor: 'pointer',
                      position: 'relative',
                      bottom: 1,
                    }}
                  />
                </span>
              </>
            )}

            {i !== bankopdrachten.length - 1 && <span className="ml-2" />}
          </>
        </div>
      );
    });

    return (
      <div className="d-flex align-items-center" style={{ flex: 1, flexWrap: 'wrap' }}>
        {opdrachtdatums}
      </div>
    );
  };

  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalwijzenResultElement[] | null>(
    null,
  );

  const ophalenBetaalwijzen = useCallback(async () => {
    const result = await api.v2.betaalwijze.ophalenBetaalwijzen({});

    setBetaalwijzen(result.betaalwijzen);
  }, []);

  useEffect(() => {
    ophalenBetaalwijzen();
  }, [ophalenBetaalwijzen]);

  const betaalwijzenKolommen = useMemo<IKolom<IOphalenBetaalwijzenResultElement>[]>(
    () => [
      {
        key: 'Naam' as any,
        label: 'Naam',
        breedte: 125,
        formatFabriek: (rij) => rij.Naam,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      // {
      //   naam: EFilter.AlleenBetaalbaarBijVerzamelbetaling,
      //   altijdWeergevenInBalk: true,
      //   weergave: (weergaveProps) => {
      //     return (
      //       <div className="d-flex align-items-center">
      //         <span>VZ alleen indien betaalbaar</span>
      //       </div>
      //     );
      //   },
      // },
      {
        naam: EFilter.Betaalwijze,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (betaalwijzen === null) {
            return <LoadingSpinner />;
          }
          return (
            <span className="d-flex align-items-center">
              <span>Betaalwijzen</span>
              <span className="ml-2">
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(data) => {
                    weergaveProps.onDataChange(data);
                    weergaveProps.toepassen();
                  }}
                  // opties={grootboekrekeningenFilterOpties}
                  // opties={grootboekrekeningenFilterOpties.map(
                  //   (status): IOptie<number> => {
                  //     return { key: status.ID, weergave: status.Naam };
                  //   },
                  // )}
                  opties={betaalwijzen.map((x) => ({
                    key: x.BetWijzeID,
                    weergave: x.Naam,
                  }))}
                  isVerwijderbaar={false}
                />
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.Vervaldatum,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            Vervaldatum t/m
            <span className="ml-2">
              <DatumKiezer
                waarde={new Date(weergaveProps.data)}
                onGewijzigd={(x) => {
                  weergaveProps.onDataChange(x?.toISOString() ?? null);
                  weergaveProps.toepassen();
                }}
                determineValidDate={() => true}
                determineNextValidDate={(date) => addDays(date, 1)}
                determinePreviousValidDate={(date) => addDays(date, -1)}
              />
            </span>
          </div>
        ),
        onActiefGemuteerd: (weergaveProps) => {
          if (weergaveProps.isActief) {
          }
        },
      },
    ],
    [betaalwijzen, betaalwijzenKolommen],
  );

  return (
    <>
      <Helmet>
        <title>Betalen inkoopfacturen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.inkoopfactuur.checkSelectieBetaalopdrachtInkoopfacturen(
                    {
                      inkFactIDs: urlState.selectie,
                    },
                  );

                  const checkResult = await checkStore.controleren({
                    checkData,
                  });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  return setUrlStateSync('betaalopdrachtDialoogState', {
                    inkFactIDs: urlState.selectie,
                  });
                }}
              >
                <IconBetaling style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Betaalopdrachten maken</span>
              </button>

              <button
                className="btn btn-sm btn-light  ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length < 2}
                onClick={async () => {
                  const checkData = await api.v2.inkoopfactuur.checkSelectieVerrekenenInkoopfacturen(
                    {
                      inkFactIDs: urlState.selectie,
                    },
                  );

                  const checkResult = await checkStore.controleren({
                    checkData,
                  });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  return setUrlStateSync('verrekenenDialoogState', {
                    inkFactIDs: urlState.selectie,
                  });
                }}
              >
                <IconBetaling style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verrekenen</span>
              </button>

              <div className="d-flex align-items-center ml-3">
                <VinkVeld
                  aangevinkt={filterNietGeboekt}
                  onGewijzigd={(x) => setFilterNietGeboekt(x)}
                />
                <UitlegTooltip inhoud={'Ook niet-geboekte facturen weergeven'}>
                  <span className="ml-2">Ook niet-geboekte weergeven</span>
                </UitlegTooltip>
              </div>

              <div className="d-flex align-items-center ml-3">
                <VinkVeld
                  aangevinkt={filterBetaalbaar}
                  onGewijzigd={(x) => setFilterBetaalbaar(x)}
                />
                <UitlegTooltip inhoud={'Zonder actuele bankopdracht en niet op Aanhouden'}>
                  <span className="ml-2">VZ alleen indien betaalbaar</span>
                </UitlegTooltip>
              </div>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={setFilterSchema}
                />
              </div>
            </div>

            <div className="d-flex"></div>
            <div className="mt-2 d-flex align-items-center">
              <>
                {facturen !== null && (
                  <>
                    <SelectieVak
                      totaalAantal={facturen.length}
                      aantal={urlState.selectie.length}
                      // entiteitEnkelvoud="product"
                      // entiteitMeervoud="producten"
                      onChange={(allesGeselecteerd) => {
                        if (allesGeselecteerd) {
                          setUrlStateSync('selectie', facturen.map(keyExtractor));
                        } else {
                          setUrlStateSync('selectie', []);
                        }
                      }}
                    />
                  </>
                )}
              </>
            </div>
          </div>
        }
        body={
          <>
            {facturen === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={kolommen} rows={facturen} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={['Factuurnummer']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;
                      return <InkoopfactuurVisualisatie inkFactID={rij.InkFactID} />;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRow>('Aanhouden')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      return formatterProps.value ? (
                        <UitlegTooltip inhoud={'Deze factuur is aangehouden'}>
                          {' '}
                          <IconBlock style={{ width: 15, height: 15, fill: EKleur.Rood }} />
                        </UitlegTooltip>
                      ) : (
                        <span></span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__heeftBestanden']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      const heeftBestanden = rij.bestanden.length > 0;
                      return (
                        <>
                          {heeftBestanden && (
                            <DownloadKnop
                              onDownloadAangevraagd={async () => {
                                await downloadUrl(rij.bestanden[0].url, rij.bestanden[0].Naam);
                              }}
                            />
                          )}
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Factuurdatum']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;
                      const datum = format(new Date(rij.Factuurdatum), 'dd-MM-yyyy');
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Vervaldatum']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;
                      const datum = format(new Date(rij.Vervaldatum), 'dd-MM-yyyy');

                      const d1 = rij.Vervaldatum.toString();
                      const d2 = dagDatum(new Date()).toISOString();

                      if (d1 < d2) {
                        return <span style={{ color: EKleur.Rood }}>{datum}</span>;
                      }
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['VervaldatumKorting']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;

                      if (rij.VervaldatumKorting === null) {
                        return <span></span>;
                      }
                      const datum = format(new Date(rij.VervaldatumKorting), 'dd-MM-yyyy');

                      const d1 = rij.VervaldatumKorting.toString();
                      const d2 = dagDatum(new Date()).toISOString();

                      if (d1 < d2) {
                        return <span style={{ color: EKleur.Rood }}>{datum}</span>;
                      }
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__infoFactuur']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;

                      return (
                        <>
                          <a
                            href="#"
                            className="ml-1"
                            style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                            onClick={() => {
                              setUrlStateSync('factuurinfoDialoogState', {
                                inkFactID: rij.InkFactID,
                              });
                            }}
                          >
                            <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                          </a>
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRow;
                      return (
                        <>
                          <RelatieVisualisatie
                            relID={rij.RelID}
                            relatieLinkBuilder={(hoedanigheid, relID) =>
                              `/${
                                hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                              }/${relID}/facturen/overzicht`
                            }
                          />
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__bankopdracht']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRow;
                      return <BankOpdracht {...rij} />;
                    }}
                  />

                  {/* <DataTypeProvider
                    for={['Aanhouden']}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value ? 'Ja' : ''}</span>;
                    }}
                  /> */}

                  <DataTypeProvider
                    for={[nameOf<IRow>('Geboekt')]}
                    formatterComponent={(formatterProps) => {
                      return (
                        <span>
                          {formatterProps.value ? (
                            <span>Ja</span>
                          ) : (
                            <span style={{ color: EKleur.Rood }}>Nee</span>
                          )}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['betaalwijze']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;
                      // if (rij.Verrekenen) {
                      //   return <span>Verrekenen</span>;
                      // }
                      const naam = rij.betaalwijze !== null ? rij.betaalwijze.NaamKort : '';

                      return (
                        <span
                          style={{
                            color:
                              rij.betaalwijze !== null && rij.betaalwijze.NaamEnum === 'ONTVANGEN'
                                ? EKleur.Groen
                                : undefined,
                          }}
                        >
                          {naam}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['IBAN']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRow;

                      const relRekID = rij.relatie.financieel.StandaardRekening_RelRekID;
                      const rekening =
                        rij.relatie.financieel.rekeningen.find((x) => x.RelRekID === relRekID) ||
                        null;
                      const iban = rekening !== null ? rekening.IBAN : null;

                      return <span>{iban}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(formatterProps) => (
                      <FormatteerBedrag bedrag={formatterProps.value} />
                    )}
                  />
                  <DataTypeProvider
                    for={['Openstaand']}
                    formatterComponent={(formatterProps) => (
                      <FormatteerBedrag bedrag={formatterProps.value} />
                    )}
                  />

                  <RowDetailState defaultExpandedRowIds={[]} />

                  <SortingState
                    defaultSorting={[
                      { columnName: 'Factuurdatum', direction: 'desc' },
                      { columnName: 'Factuurnummer', direction: 'desc' },
                    ]}
                  />
                  <IntegratedSorting />
                  {/* <IntegratedSorting
                    columnExtensions={[
                      {
                        columnName: 'betaalwijze',
                        compare: (a: IBetaalwijze, b: IBetaalwijze) => {
                          const aLower = a.Naam.toLowerCase();
                          const bLower = b.Naam.toLowerCase();
                          if (aLower === bLower) {
                            return 0;
                          }
                          return aLower < bLower ? -1 : 1;
                        },
                      },
                    ]}
                  /> */}

                  <VirtualTable
                    estimatedRowHeight={43}
                    messages={geenData}
                    columnExtensions={kolomExtensies}
                    // rowComponent={(rowProps) => {
                    //   const rij: IRow = rowProps.row;
                    //   if (rij.Ophouden) {
                    //     return <OphoudenTr nietKleuren={false}>{rowProps.children}</OphoudenTr>;
                    //   }

                    //   return <tr>{rowProps.children}</tr>;
                    // }}
                  />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={async (x) => {
                      const id = x[x.length - 1] as number;

                      const params: ICheckWijzigenInkoopfactuurToegestaanParams = {
                        inkFactID: id,
                      };

                      const checkData = await api.v2.inkoopfactuur.checkWijzigenInkoopfactuurToegestaan(
                        params,
                      );
                      const controleResult = await checkStore.controleren({
                        checkData,
                      });
                      if (controleResult.type === EResultType.Annuleren) {
                        return;
                      }

                      setUrlStateSync('wijzigenFactuurDialoogState', { inkFactID: id });
                    }}
                    onCommitChanges={() => null}
                  />
                  <TableEditColumn
                    width={35}
                    // showAddCommand={true}
                    showEditCommand
                    // showDeleteCommand
                    commandComponent={DXCommandComponent}
                  />

                  <TableRowDetail
                    contentComponent={RowDetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}

            {urlState.betaalopdrachtDialoogState !== null && (
              <BetaalopdrachtDialoog
                open
                bankRekID={bankRekID}
                inkFactIDs={urlState.selectie}
                onSuccess={() => {
                  ophalenFacturen();
                  setUrlStateSync('betaalopdrachtDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('betaalopdrachtDialoogState', null)}
              />
            )}

            {urlState.wijzigenFactuurDialoogState !== null && (
              <WijzigenFactuurDialoog
                open
                inkFactID={urlState.wijzigenFactuurDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('wijzigenFactuurDialoogState', null);
                  ophalenFacturen();
                }}
                onAnnuleren={() => setUrlStateSync('wijzigenFactuurDialoogState', null)}
              />
            )}

            {urlState.factuurinfoDialoogState !== null && (
              <FactuurinfoDialoog
                open
                inkFactID={urlState.factuurinfoDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('factuurinfoDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('factuurinfoDialoogState', null)}
              />
            )}
            {urlState.verrekenenDialoogState && (
              <VerrekenenDialoog
                inkfactIDs={urlState.selectie}
                open
                onSuccess={() => {
                  setUrlStateSync('verrekenenDialoogState', null);
                  ophalenFacturen();
                }}
                onAnnuleren={() => setUrlStateSync('verrekenenDialoogState', null)}
              />
            )}
          </>
        }
      />
    </>
  );
});

export default withRouter(Betalen);
