import { format } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenInformatieVoorOverzichtVerkoopvoorstel } from '../../../../../../../../../shared/src/api/v2/relatie';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import {
  IUrlState as IVerkoopvoorstelUrlState,
  defaultUrlState as defaultVerkoopvoorstelUrlState,
} from '../../Contracten/Verkoop';
import NavigatiePijl from '../NavigatiePijl';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtVerkoopvoorstel;
}

const Verkoopvoorsteltegel = (props: IProps) => {
  return (
    <TegelBasis titel="Verkoopvoorstel">
      <div>
        <table>
          <tbody>
            {props.data.voorstellen.map((voorstel) => {
              return (
                <tr key={voorstel.id}>
                  <td style={{ width: 100, verticalAlign: 'top' }}>
                    {format(new Date(voorstel.geldigVan), 'dd-MM-yyyy')}
                  </td>
                  <td style={{ width: 90 }}>
                    <FormatteerBedrag bedrag={voorstel.bedrag} />
                  </td>
                  <td style={{ width: 250, verticalAlign: 'top' }}>
                    {voorstel.productsoortnaamKort} {voorstel.merknaam} {voorstel.typenaam}
                  </td>

                  <td style={{ verticalAlign: 'top' }}>
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam<IVerkoopvoorstelUrlState>({
                          ...defaultVerkoopvoorstelUrlState,
                          selectie: [voorstel.id],
                        });
                        return `contracten/verkoop?state=${state}`;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {props.data.totaalAantalVoorstellen > props.data.voorstellen.length && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/service/meldingen`);
              }}
            >
              Er zijn nog {props.data.totaalAantalVoorstellen - props.data.voorstellen.length}
              &nbsp;andere meldingen...
            </a>
          </div>
        )}
      </div>
    </TegelBasis>
  );
};

export default withRouter(Verkoopvoorsteltegel);
