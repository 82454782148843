import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { DXTableCheckboxComponent } from '../../../../helpers/dxTableGrid';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import SelectieVak from '../../../../components/SelectieVak';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MenuLayout from '../../../../components/MenuLayout';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import nameOf from '../../../../core/nameOf';
import WijzigBerichtindicatieDialoog from './WijzigBerichtindicatieDialoog';
import { IOphalenStornoredenenResultElement } from '../../../../../../shared/src/api/v2/bank/bank';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { Helmet } from 'react-helmet';

enum EFilter {
  Versturen = 'STORNOBERICHT_VERSTUREN',
  NietVerstuurd = 'STORNOBERICHT_NIET_VERSTUURD',
  Stornoredenen = 'BANKOPDRETCD_IDS',
}

interface IProps extends RouteComponentProps {}

export interface IIndicatieWijzigenDialoogState {
  bankOpdIDs: number[];
}
interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  indicatieWijzigenDialoogState: IIndicatieWijzigenDialoogState | null;
}

const redenBijOntbrekendeStornocodeInTabel = (
  <span style={{ color: EKleur.LichtGrijs }}>Stornocode komt niet voor in tabel</span>
);

const Stornering: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      selectie: [],
      filterData: [
        {
          naam: EFilter.Versturen,
          data: true,
          isActief: true,
        },
        {
          naam: EFilter.NietVerstuurd,
          data: true,
          isActief: true,
        },
        {
          naam: EFilter.Stornoredenen,
          data: null,
          isActief: false,
        },
      ],
      indicatieWijzigenDialoogState: null,
    };
  }, []);

  // const defaultUrlState: IUrlState = {
  //   selectie: [],
  //   filterData: [
  //     {
  //       naam: EFilter.Versturen,
  //       data: true,
  //       isActief: true,
  //     },
  //     {
  //       naam: EFilter.NogNietVerstuurd,
  //       data: false,
  //       isActief: true,
  //     },
  //     // {
  //     //   naam: EFilter.StornoberichtVersturen,
  //     //   data: true,
  //     //   isActief: true,
  //     // },
  //   ],
  //   indicatieWijzigenDialoogState: null,
  // };

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [bankopdrachten, setBankopdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(
    null,
  );
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const [stornoredenen, setStornoredenen] = useState<IOphalenStornoredenenResultElement[] | null>(
    null,
  );

  const stornoredenenKolommen = useMemo<IKolom<IOphalenStornoredenenResultElement>[]>(() => {
    return [
      {
        key: 'Code',
        label: 'Code',
        breedte: 70,
      },
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 400,
      },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Versturen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Met indicatie versturen</span>
            </span>
          );
        },
      },
      {
        naam: EFilter.NietVerstuurd,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Nog niet verstuurd</span>
            </span>
          );
        },
      },
      {
        naam: EFilter.Stornoredenen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Categorieen</span>
              {
                <MultiCombobox<number, IOphalenStornoredenenResultElement>
                  sleutelExtractor={(row) => row.ID}
                  onWaardeChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  representatieFabriek={(row) => `${row.Code} - ${row.Naam}`}
                  waarde={weergaveProps.data}
                  opties={stornoredenen}
                  kolommen={stornoredenenKolommen}
                />
              }
            </span>
          );
        },
      },
    ],
    [stornoredenen],
  );

  const ophalenStornoredenen = useCallback(async () => {
    const stornoredenenResult = await api.v2.bank.ophalenStornoredenen({
      orderSchema: { orders: [{ naam: 'CODE', richting: 'ASC' }] },
    });
    setStornoredenen(stornoredenenResult.redenen);
  }, []);

  useEffect(() => {
    ophalenStornoredenen();
  }, [ophalenStornoredenen]);

  const ophalenBankopdrachten = useCallback(async () => {
    if (filterSchema.filters === undefined) {
      return;
    }

    const opdrachtenResult = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            { naam: 'GESTORNEERD', data: true },
            { naam: 'SOORT', data: 1 }, // Alleen incasso-opdrachten
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        orderSchema: {
          orders: [
            { naam: 'STORNODATUM', richting: 'DESC' },
            { naam: 'ID', richting: 'DESC' },
          ],
        },
        paginatie: { index: 0, aantal: 250 },
      })
    ).opdrachten;

    // Alleen bankopdrachten waarvan de stornomutatie geboekt (en daarmee ook definitief) is
    // const opdrachten = opdrachtenResult.filter(
    //   (x) => x.stornomutatie!.boeking !== null && x.stornomutatie!.boeking.Definitief,
    // );
    const opdrachten = opdrachtenResult;

    setBankopdrachten(opdrachten);
  }, [filterSchema.filters]);

  useEffect(() => {
    ophalenBankopdrachten();
  }, [ophalenBankopdrachten]);

  const handleVersturen = useCallback(async () => {
    const params = { bankOpdIDs: urlState.selectie };
    const checkData = await api.v2.bank.opdracht.checkVersturenStornoberichten(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je voor de geselecteerde ${urlState.selectie.length} storneringen een stornobericht versturen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const result = await api.v2.bank.opdracht.versturenStornoberichten(params);
    setUrlStateSync('selectie', []);
    ophalenBankopdrachten();
  }, [urlState.selectie]);

  const handleNietVersturen = useCallback(async () => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je voor de geselecteerde ${urlState.selectie.length} storneringen GEEN stornobericht versturen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const params = { opdIDs: urlState.selectie };
    const result = await api.v2.debiteur.stornering.markerenNietVersturenStornoberichten(params);

    setUrlStateSync('selectie', []);
    ophalenBankopdrachten();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((rij: IOphalenOpdrachtenResultElement) => rij.BankOpdID, []);

  const kolommen = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__telefoonnummer' as any,
        title: 'Telefoonnummer',
      },
      // {
      //   name: 'Relatienummer',
      //   title: 'Rel.nr',
      // },
      {
        name: '__stornodatum' as any,
        title: 'Gestorneerd op',
        getCellValue: (x) => {
          return x.stornomutatie !== null ? x.stornomutatie.Mutatiedatum : null;
        },
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__openstaandBijFacturen' as any,
        title: 'Openstaand',
      },
      {
        name: 'Betalingskenmerk',
        title: 'Kenmerk',
      },
      {
        name: '__stornocode' as any,
        title: 'Code',
        getCellValue: (x) => (x.stornomutatie !== null ? x.stornomutatie.Returncode : null),
      },
      {
        name: '__stornoreden' as any,
        title: 'Reden',
        getCellValue: (x) =>
          x.stornomutatie !== null
            ? x.stornomutatie.stornoreden !== null
              ? x.stornomutatie.stornoreden.Naam
              : ''
            : null,
      },
      {
        name: 'StornoberichtVersturen',
        title: 'Ind. verst.',
      },
      {
        name: 'StornoberichtVerstuurdOp',
        title: 'Verstuurd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 300,
      },
      {
        columnName: 'Relatienummer',
        width: 100,
      },
      {
        columnName: '__telefoonnummer' as any,
        width: 150,
      },
      {
        columnName: '__stornodatum' as any,
        width: 150,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__openstaandBijFacturen' as any,
        width: 120,
      },
      {
        columnName: 'Betalingskenmerk',
        width: 150,
      },
      {
        columnName: '__stornocode' as any,
        width: 100,
      },
      {
        columnName: '__stornoreden' as any,
        width: 350,
      },
      {
        columnName: 'StornoberichtVersturen',
        width: 110,
      },
      {
        columnName: 'StornoberichtVerstuurdOp',
        width: 135,
      },
    ],
    [],
  );

  return bankopdrachten === null || stornoredenen === null ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Storneringen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  disabled={urlState.selectie.length === 0}
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  onClick={() => {
                    handleVersturen();
                  }}
                >
                  {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Storneringsbericht versturen</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  disabled={urlState.selectie.length === 0}
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  onClick={() => {
                    setUrlStateSync('indicatieWijzigenDialoogState', {
                      bankOpdIDs: urlState.selectie,
                    });
                  }}
                >
                  {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Wijzigen Indicatie versturen</span>
                </button>
              </div>

              <div className="d-flex flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>

            <div className="d-flex align-items-center mt-3">
              <SelectieVak
                totaalAantal={bankopdrachten.length}
                aantal={urlState.selectie.length}
                onChange={(allesGeselecteerd) => {
                  if (allesGeselecteerd) {
                    setUrlStateSync('selectie', bankopdrachten.map(keyExtractor));
                  } else {
                    setUrlStateSync('selectie', []);
                  }
                }}
              />
            </div>
          </>
        }
        body={
          <>
            <GridStyleWrapper height={'calc(100vh - 100px)'}>
              <Grid rows={bankopdrachten} getRowId={keyExtractor} columns={kolommen}>
                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(props) => <RelatieVisualisatie relID={props.row.RelID} />}
                />

                <DataTypeProvider
                  for={['__stornodatum']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenOpdrachtenResultElement = formatterProps.row;

                    const datum =
                      rij.stornomutatie !== null
                        ? format(new Date(rij.stornomutatie.Mutatiedatum), 'dd-MM-yyyy')
                        : '';
                    return <span>{datum}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['Bedrag']}
                  formatterComponent={(props) => (
                    <span>
                      <FormatteerBedrag bedrag={props.value} />
                    </span>
                  )}
                />

                <DataTypeProvider
                  for={['__openstaandBijFacturen']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                    const totaalOpenstaand = _.sum(rij.debiteurFacturen.map((x) => x.Openstaand));
                    return (
                      <span>
                        <FormatteerBedrag bedrag={totaalOpenstaand} />
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__stornocode']}
                  formatterComponent={(props) => {
                    const rij: IOphalenOpdrachtenResultElement = props.row;
                    if (rij.stornomutatie === null) {
                      return <span></span>;
                    }
                    return <span>{rij.stornomutatie.Returncode}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__telefoonnummer']}
                  formatterComponent={(props) => {
                    const rij: IOphalenOpdrachtenResultElement = props.row;
                    if (rij.relatie!.persoon!.TelefoonMobiel === null) {
                      return <span></span>;
                    }
                    return <span>{rij.relatie!.persoon!.TelefoonMobiel}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__stornoreden']}
                  formatterComponent={(props) => {
                    const rij: IOphalenOpdrachtenResultElement = props.row;
                    if (rij.stornomutatie === null) {
                      return <span></span>;
                    }
                    return (
                      <span>
                        {rij.stornomutatie.stornoreden !== null
                          ? rij.stornomutatie.stornoreden.Naam
                          : redenBijOntbrekendeStornocodeInTabel}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenOpdrachtenResultElement>('StornoberichtVersturen')]}
                  formatterComponent={(props) => {
                    return <span>{props.value ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenOpdrachtenResultElement>('StornoberichtVerstuurdOp')]}
                  formatterComponent={(props) => {
                    if (props.value === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(props.value), 'dd-MM-yyyy HH:mm')}</span>;
                  }}
                />

                <EditingState
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                  }}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    // alert(id);
                    setWijzigenID(id);
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

                <VirtualTable
                  messages={{
                    noData: 'Geen storneringen gevonden',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                <TableHeaderRow showSortingControls />
                {/* <TableRowDetail
                  contentComponent={() => <span></span>}
                  toggleCellComponent={DXTableToggleCellComponent}
                /> */}
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          </>
        }
      ></MenuLayout>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: EKleur.HeelLichtGrijs,
          borderBottom: `1px solid ${EKleur.LichtGrijs}`,
        }}
      ></div>

      {urlState.indicatieWijzigenDialoogState !== null && (
        <WijzigBerichtindicatieDialoog
          open={true}
          bankOpdIDs={urlState.indicatieWijzigenDialoogState.bankOpdIDs}
          onSuccess={() => {
            setUrlStateSync('selectie', []);
            ophalenBankopdrachten();
            setUrlStateSync('indicatieWijzigenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('indicatieWijzigenDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Stornering);
