import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';

interface IProps {}

const Documenten: React.FC<IProps> = observer((props) => {
  return <span>Documenten</span>;
});

export default Documenten;
