import { makeObservable, observable } from 'mobx';
import { INotitieParams, IRelatie } from '../models/relatie';
import {
  IOphalenContactpersoonParams,
  IOphalenContactpersonenResultElement,
} from '../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../api';
import {
  IContactpersoonGekoppeldMessageData,
  IContactpersoonOntkoppeldMessageData,
  IPersoonGewijzigdMessageData,
} from '../../../shared/src/api/v2/persoon/persoon';
import { maakFilterSchema } from '../components/FilterBalkV2';
import { IOphalenRelatiesResultElementV2 } from '../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import { IOphalenFinancieleInformatieResult } from '../../../shared/src/api/v2/debiteur';

interface IVerwijderenCheckResultaat {
  errors: any[];
  warnings: any[];
}

// tslint:disable no-console
class KlantkaartStore {
  public relatie: IOphalenRelatiesResultElementV2 | null = null;
  public debiteurInformatie: IOphalenFinancieleInformatieResult | null = null;
  public contactpersonen: IOphalenContactpersonenResultElement[] = [];
  // public signaleringen: ISignaleringenResultElement[] = [];
  public geselecteerdContactpersoon: any = null;
  public geselecteerdContactpersoonPersID: number | null = null;
  public contactpersoonVerwijderenCheckResultaat: IVerwijderenCheckResultaat = {
    errors: [],
    warnings: [],
  };

  constructor() {
    makeObservable(this, {
      relatie: observable,
      debiteurInformatie: observable,
      contactpersonen: observable,
      contactpersoonVerwijderenCheckResultaat: observable,
      geselecteerdContactpersoon: observable,
      geselecteerdContactpersoonPersID: observable,
      // signaleringen: observable,
    });
  }

  public ophalenRelatie = async (id: number) => {
    // this.relatie = null;
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: [id] }] },
    });
    const debiteurInformatieResult = await api.v2.debiteur.ophalenFinancieleInformatie({
      relID: id,
    });
    this.relatie = ({
      ...relatiesResult.relaties[0]!,
      __bijgewerkt: new Date(), // Forceer updates bij e.g. bijzonderheden
    } as any) as IOphalenRelatiesResultElementV2;

    this.debiteurInformatie = debiteurInformatieResult;
    this.updateActiefContactpersoon(
      this.relatie.persoon === null ? null : this.relatie.persoon.PersID,
    );
  };

  public verversRelatie = async () => {
    if (this.relatie === null) {
      return;
    }
    const relID = this.relatie.RelID;
    // this.relatie = null as any;
    await this.ophalenRelatie(relID);
  };

  public wijzigNotities = async (notitieParams: INotitieParams) => {
    const { notities, vasteInfo, relID } = notitieParams;
    try {
      await api.v2.relatie.wijzigenNotities({
        relID,
        notities: notities === '' ? null : notities,
        vasteInfo: vasteInfo === '' ? null : vasteInfo,
      });
      this.ophalenRelatie(relID);
    } catch (err) {
      throw err;
    }
  };

  public ophalenContactpersonenVoorlijst = async (params: { relID: number; sortering: number }) => {
    const { relID, sortering } = params;
    if (relID !== undefined) {
      // const result = await api.v2.relatie.ophalenContactpersonenVoorLijst({
      //   relID,
      //   sortering,
      // });

      const personenResult = await api.v2.relatie.ophalenContactpersonen({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] },
      });
      const result = personenResult.contactpersonen;

      if (this.geselecteerdContactpersoonPersID === null) {
        const relatie = result.find((item: any) => item.IsRelatie);
        if (relatie) {
          this.updateActiefContactpersoon(relatie.PersID);
        }
      }

      let resultGesorteerd;
      switch (sortering) {
        case 1:
          resultGesorteerd = _.orderBy(
            result,
            ['IsPersoonPrimair', 'Achternaam', 'Voorletters'],
            ['desc', 'asc', 'asc'],
          );
          break;
        case 2:
          resultGesorteerd = _.orderBy(
            result,
            ['IsRelatie', 'Achternaam', 'Voorletters'],
            ['desc', 'asc', 'asc'],
          );
          break;
      }
      this.contactpersonen = resultGesorteerd;
    }
  };

  public updateActiefContactpersoon = (persID: number | null) => {
    this.geselecteerdContactpersoonPersID = persID;
  };

  public verwijderenContactpersoon = async (persID: number[], relID: number) => {
    try {
      await api.v2.relatie.verwijderenContactpersoon({
        persID,
      });
      this.ophalenContactpersonenVoorlijst({ relID, sortering: 1 });
    } catch (err) {
      throw err;
    }
  };

  public verwijderenContactpersoonCheck = async (id: number) => {
    const persIDs = [id];
    try {
      const response = await api.v2.relatie.verwijderenContactpersoonCheck({ persIDs });
      return response;
    } catch (err) {
      throw err;
    }
  };

  public ophalenContactpersoon = async (params: IOphalenContactpersoonParams) => {
    try {
      const response = await api.v2.relatie.ophalenContactpersoon(params);
      this.geselecteerdContactpersoon = response;
    } catch (error) {
      console.log('error');
    }
  };

  public handlePersoonGewijzigd = async (msg: IPersoonGewijzigdMessageData) => {
    if (this.contactpersonen === null || !this.relatie || !this.relatie.RelID) {
      return;
    }

    const bestaatInLijst =
      this.contactpersonen.findIndex((ctp) => ctp.PersID === msg.persID) !== -1;
    if (!bestaatInLijst) {
      return;
    }

    this.ophalenContactpersonenVoorlijst({
      relID: this.relatie.RelID,
      sortering: 1,
    });
  };

  public handleContactpersoonGekoppeld = async (msg: IContactpersoonGekoppeldMessageData) => {
    if (this.contactpersonen === null || !this.relatie || this.relatie.RelID !== msg.relID) {
      return;
    }

    this.ophalenContactpersonenVoorlijst({
      relID: this.relatie.RelID,
      sortering: 1,
    });
  };

  public handleContactpersoonOntkoppeld = async (msg: IContactpersoonOntkoppeldMessageData) => {
    if (this.contactpersonen === null || !this.relatie || this.relatie.RelID !== msg.relID) {
      return;
    }

    this.ophalenContactpersonenVoorlijst({
      relID: this.relatie.RelID,
      sortering: 1,
    });
  };
}

export default KlantkaartStore;
