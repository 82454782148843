import styled from 'styled-components';
import { Kleur } from '../../bedrijfslogica/constanten';

const notificationWidth = 375;
export const notificationTransitionTimeMs = 350;
export const notificationTopOffset = 20;
const width = 450;

export const Wrapper = styled.div`
  .notificatie {
    cursor: pointer;
    position: absolute;
    right: -${notificationWidth}px;
    width: ${notificationWidth}px;
    z-index: 1000;
    top: ${notificationTopOffset}px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px 1px;
    transition: right ${notificationTransitionTimeMs}ms ease-in-out;
  }
  .notificatie-tonen {
    right: 30px;
  }
`;

export const Root = styled.div`
  position: absolute;
  right: -${width}px;
  top: 0;
  bottom: 0;
  z-index: 1050;

  .container {
    position: absolute;
    right: 0;
    height: 100vh;
    background: ${Kleur.Wit};
    width: ${width}px;
    transition: right 225ms ease-in-out;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .container-zichtbaar {
    right: ${width}px;
  }

  .scroll {
    overflow-y: scroll;
    position: absolute;
    top: 35px;
    right: 0;
    bottom: 0;
    width: 100%;
  }
`;

export const MenuBalk = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 35px;
  border-bottom: 1px solid gainsboro;
  padding: 5px;
`;
