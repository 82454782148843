import React, { useCallback, useMemo } from 'react';
import { IOphalenFacturenBasisResultElement } from '../../../../../shared/src/api/v2/factuur';
import MultiComboboxV2, { EnkeleProvider, Provider, IRepresentatieProps } from '../MultiComboboxV2';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import FormatteerBedrag from '../../MutatieBedrag';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import { IFacturenMuteren } from '../../boekhouding/boeking/BoekingDialoogV3/Regels/FactuurMuteren';
import { dossierIndicaties } from '../../../bedrijfslogica/conversie';

export enum EFactuurSelectieKolom {
  Nummer,
  Datum,
  Bedrag,
  Openstaand,
  Relatie,
  Indicaties,
}

export const enkeleProvider: EnkeleProvider<number, IFacturenMuteren> = async (id) => {
  const facturenResult = await api.v2.factuur.ophalenFacturenBasis({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: [id],
        },
      ],
    },
  });

  const factIDs = facturenResult.facturen.map((x) => x.FactID);

  // Dossiers ophalen
  const dossiersResult = await api.v2.factuur.ophalenFacturenDossiers({
    factIDs,
  });

  const facturen = facturenResult.facturen.map((x) => {
    const dossiers = dossiersResult.facturen.find((f) => f.FactID === x.FactID)!;
    return { ...x, dossiers };
  });

  return facturen[0];
};

export const StandaardReprensentatieComponent: React.ComponentType<IRepresentatieProps<
  IFacturenMuteren
>> = (props) => {
  return <span>{props.entiteit.Factuurnummer}</span>;
};

interface IProps {
  factID: number | null;
  onFactIDChange: (factID: number | null) => void;
  provider: Provider<EFactuurSelectieKolom, IFacturenMuteren>;
  representatieComponent?: React.ComponentType<IRepresentatieProps<IFacturenMuteren>>;
  disabled?: boolean;
  isWisbaar?: boolean;
}

const FactuurSelectie = (props: IProps) => {
  const keyExtractor = useCallback((row: IFacturenMuteren) => row.FactID, []);

  const kolommen = useMemo<ASPKolom<EFactuurSelectieKolom, IFacturenMuteren>[]>(
    () => [
      {
        key: EFactuurSelectieKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (x) =>
          x.Factuurnummer ?? (
            <span title="Niet definitief" className="text-muted">
              Niet def.
            </span>
          ),
      },
      {
        key: EFactuurSelectieKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (x) => format(new Date(x.Factuurdatum), 'dd-MM-yyyy'),
      },
      {
        key: EFactuurSelectieKolom.Bedrag,
        label: 'Bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => <FormatteerBedrag bedrag={x.Bedrag} />,
      },
      {
        key: EFactuurSelectieKolom.Openstaand,
        label: 'Openstaand',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => <FormatteerBedrag bedrag={x.Openstaand} />,
      },
      {
        key: EFactuurSelectieKolom.Indicaties,
        label: 'Indicaties',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (x) => <span>{dossierIndicaties(x.dossiers)}</span>,
      },
      {
        key: EFactuurSelectieKolom.Relatie,
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 275,
        renderer: (x) => <RelatieVisualisatie relID={x.RelID} />,
      },
    ],
    [],
  );

  const representatieComponent = useMemo(
    () => props.representatieComponent ?? StandaardReprensentatieComponent,
    [props.representatieComponent],
  );

  return (
    <MultiComboboxV2
      provider={props.provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.factID}
      onChange={props.onFactIDChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
    />
  );
};

export default FactuurSelectie;
