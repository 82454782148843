import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameOf from '../../../core/nameOf';
import RelatieSelectie from '../../formulier/RelatieSelectie';
import DatumKiezer from '../../formulier/DatumKiezer';
import TijdKiezer from '../../formulier/TijdKiezer';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import api from '../../../api';
import DatumEnTijdKiezer from '../../formulier/DatumEnTijdKiezer';
import BezigheidKnop, { BezigheidStatus, EGrootte } from '../../BezigheidKnop';
import { IInstellenBezigheidParams } from '../../../../../shared/src/api/v2/gebruiker';
import VinkVeld from '../../formulier/VinkVeld';

interface IDialoogResult {}

type DataOptions = {
  enum: 'CONTROL_TAAK';
  id: number;
};

interface IFormikValues {
  opvolgdatum: Date | null;
  notities: string;
  aspGebrID: number | null;
  relID: number | null;
  toewijzen: boolean;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  initieelRelatie?: {
    relID: number;
    muterenViaFormulierToestaan: boolean;
  };
  aspGebrID?: number;
}

const HandmatigControltaakToevoegenDialoog: React.FC<IProps> = (props) => {
  const [bezigheid, setBezigheid] = useState<IInstellenBezigheidParams | null>(null);

  const initialValues = useMemo<IFormikValues>(
    () => ({
      relID: props.initieelRelatie !== undefined ? props.initieelRelatie.relID : null,
      opvolgdatum: new Date(),
      notities: '',
      aspGebrID: null,
      toewijzen: false,
    }),
    [props.initieelRelatie],
  );
  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const toevoegenResult = await api.v2.control.toevoegenHandmatigEvent({
        relID: values.relID,
        opvolgdatum: values.opvolgdatum,
        notities: values.notities,
      });

      if (values.toewijzen) {
        await api.v2.control.muterenControltaakToewijzingen({
          controlTaakID: toevoegenResult.controlTaakID!,
          aspGebrIDs: [props.aspGebrID!],
        });
      }

      if (bezigheid != null && bezigheid.aspGebrID != -1 && toevoegenResult.controlTaakID != null) {
        // ook meteen bezigheid toevoegen
        const params = {
          bezigheid: maakBezigheidVanData({
            enum: 'CONTROL_TAAK',
            id: toevoegenResult.controlTaakID,
          }),
          aspGebrID: bezigheid.aspGebrID,
          status: BezigheidStatus.Opgepakt,
        };

        await api.v2.gebruiker.instellenBezigheid(params);
      }

      await props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess, bezigheid],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [nameOf<IFormikValues>('notities')]: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Notities' }),
        ),
      }),
    [],
  );

  const notitiesRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    notitiesRef.current?.focus();
  }, []);

  const maakBezigheidVanData = (data: DataOptions): IInstellenBezigheidParams['bezigheid'] => {
    switch (data.enum) {
      case 'CONTROL_TAAK':
        return {
          naamEnum: data.enum,
          id: data.id,
        };
    }
    throw new Error();
  };

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Handmatig controltaak toevoegen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, isSubmitting, submitForm, values } = formikProps;
          const relatieVeldWeergeven =
            props.initieelRelatie === undefined ||
            props.initieelRelatie.muterenViaFormulierToestaan;

          return (
            <>
              <ModalBody>
                <div className="row">
                  {relatieVeldWeergeven && (
                    <div className="col-12">
                      <label>Relatie</label>
                      <Field
                        name={nameOf<IFormikValues>('relID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <RelatieSelectie
                              relID={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                              isWisbaar
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className={`col-12 ${relatieVeldWeergeven ? 'mt-3' : ''}`}>
                    <label>Opvolgdatum</label>
                    <Field
                      name={nameOf<IFormikValues>('opvolgdatum')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <DatumEnTijdKiezer
                            waarde={field.value}
                            onChange={(x) => form.setFieldValue(field.name, x)}
                            isWisbaar
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={`col-12 ${relatieVeldWeergeven ? 'mt-3' : ''}`}>
                    <label>Opvolger</label>
                    <Field
                      name={nameOf<IFormikValues>('aspGebrID')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <BezigheidKnop
                            interactieToegestaan={true}
                            data={{ enum: 'CONTROL_TAAK', id: 0 }}
                            directToepassen={false}
                            aspGebrID={field.value}
                            onBezigheidGekozenNietToegepast={(waarde) => {
                              form.setFieldValue(field.name, waarde.aspGebrID);
                              setBezigheid(waarde);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Field
                      name={nameOf<IFormikValues>('toewijzen')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div className="d-flex align-items-center">
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              disabled={props.aspGebrID === undefined}
                            />
                            <span className="ml-2">Deze taak direct aan jezelf toewijzen</span>
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>Notities</label>
                    <Field
                      name={nameOf<IFormikValues>('notities')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <textarea
                            ref={notitiesRef}
                            {...field}
                            className="form-control"
                            rows={3}
                            placeholder={'Hier een verplicht een notitie invoeren'}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default HandmatigControltaakToevoegenDialoog;
