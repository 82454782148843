import TabelWeergave from '../TabelWeergave';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import TabelWeergavePlaceholder from '../TabelWeergavePlaceholder';
import {
  IOphalenBestandenResultElement,
  IOphalenBestandsmappenResultElement,
} from '../../../../../shared/src/api/v2/bestand/bestand';
import {
  naarVerkennerItemId,
  toonBevestigVerplaatsenVerkennerItemNaarMap,
  vanVerkennerItemId,
} from '../helpers';
import { EVerkennerItemKolom, EVerkennerItemType } from '../TabelWeergave/types';
import api, { IPaginatiePositie } from '../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import { IOrderSchema } from '../../../../../shared/src/models/order';
import IBestandXBestandslabelGemuteerdData from '../../../../../shared/src/realtime-manager/messages/bestand/bestandslabelsGemuteerd';
import useStateControl from '../../../core/useStateControl';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';
import ZoekVeld from '../ZoekVeld';
import Zoekvenster from '../Zoekvenster';
import { ESortering, IAspKolomSorteringItem } from '../../tabel/ASPTabel/types';
import { useRealtimeListener } from '../../../one-off-components/realtime/RealtimeIntegratie';
import Actiebalk from '../Actiebalk';
import BestandsmappenboomBreadcrumb from '../BestandsmappenboomBreadcrumb';
import MapToevoegenDialoog from '../MapToevoegenDialoog';
import { Kleur } from '../../../bedrijfslogica/constanten';
import verkennerItemHernoemen from '../verkennerItemHernoemen';
import verkennerItemsVerwijderen from '../verkennerItemsVerwijderen';
import {
  IBestandVerkennerItem,
  IMapVerkennerItem,
  IVerkennerItem,
  IVerkennerItemBase,
  IVerkennerItemState,
} from '../types';
import { bepalenVerkennerItemsVanMappenEnBestandenResult } from '../bepalenVerkennerItemsVanMappenEnBestanden';
import WeergaveKeuze from '../../WeergaveKeuze';
import { IconGridView, IconGroepWeergave } from '../../Icons';
import GridWeergave from '../GridWeergave';

interface IMapToevoegenDialoogState {
  bovenliggendeBestandsmapID: number | null;
}

interface IItemsData {
  items: Record<number, IVerkennerItem>;
  totaalAantal: number;
  bestandsmappenboom: IOphalenBestandsmappenResultElement[] | null;
  lijstWeergaveScrollNaarIndex: number | null;
}

export const STANDAARD_PAGINATIE_AANTAL = 50;

export type VerkennerSortering = Array<IAspKolomSorteringItem<EVerkennerItemKolom>>;

const verkennerSorteringNaarOrderSchema = (
  verkennerSortering: VerkennerSortering,
): IOrderSchema => {
  const verkennerItemKolomNaarOrderSchemaNaam = (kolom: EVerkennerItemKolom): string => {
    switch (kolom) {
      case EVerkennerItemKolom.Naam:
        return 'NAAM';
      case EVerkennerItemKolom.Grootte:
        return 'GROOTTE';
      case EVerkennerItemKolom.Type:
        return 'MEDIA_TYPE_NAAM_MET_TERUGVAL_OP_MEDIA_TYPE';
      case EVerkennerItemKolom.DatumToegevoegd:
        return 'RECORD_TOEGEVOEGD';
      case EVerkennerItemKolom.Opslagdienst:
        return 'OPSLAG_DIENST_NAAM';
    }
    throw new Error();
  };

  return {
    orders: verkennerSortering.map(({ key, sortering }) => ({
      naam: verkennerItemKolomNaarOrderSchemaNaam(key),
      richting: sortering === ESortering.Ascending ? 'ASC' : 'DESC',
    })),
  };
};

export enum EWeergaveKeuze {
  Lijst,
  Iconen,
}

interface IProps {
  defaultBestandsmapID?: number | null;
  bestandsmapID?: number | null;
  onBestandsmapIDChange?: (bestandsmapID: number | null) => void;

  defaultGefocustItemID?: string | null;
  gefocustItemID?: string | null;
  onGefocustItemIDChange?: (gefocusItemID: string | null) => void;

  defaultSelectie?: string[];
  selectie?: string[];
  onSelectieChange?: (selectie: string[]) => void;

  defaultSortering?: VerkennerSortering;
  sortering?: VerkennerSortering;
  onSorteringChange?: (sortering: VerkennerSortering) => void;

  defaultZoekterm?: string;
  zoekterm?: string;
  onZoektermChange?: (zoekterm: string) => void;

  paginatieAantal?: number;
  magItemSelecteren?: (item: IVerkennerItem) => boolean;

  weergaveKeuze?: EWeergaveKeuze;
  onWeergaveKeuzeChange?: (weergaveKeuze: EWeergaveKeuze) => void;
}

const Verkenner = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const rowsRenderedRef = useRef<{ startIndex: number; stopIndex: number } | null>(null);

  const [selectie, setSelectie, selectieRef] = useStateControl<string[]>(
    useMemo(
      () => ({
        defaultUncontrolledState: props.defaultSelectie,
        controlledState: props.selectie,
        onControlledStateChange: props.onSelectieChange,
      }),
      [props.selectie, props.onSelectieChange],
    ),
  );
  const [bestandsmapID, _setBestandsmapID, bestandsmapIDRef] = useStateControl<number | null>(
    useMemo(
      () => ({
        defaultUncontrolledState: props.defaultBestandsmapID,
        controlledState: props.bestandsmapID,
        onControlledStateChange: props.onBestandsmapIDChange,
      }),
      [props.bestandsmapID, props.onBestandsmapIDChange],
    ),
  );
  const setBestandsmapID = useCallback(
    (bestandsmapID) => {
      _setBestandsmapID(bestandsmapID);
    },
    [_setBestandsmapID, setSelectie],
  );
  const [gefocustItemID, setGefocustItemID, gefocustItemIDRef] = useStateControl<string | null>(
    useMemo(
      () => ({
        defaultUncontrolledState: props.defaultGefocustItemID,
        controlledState: props.gefocustItemID,
        onControlledStateChange: props.onGefocustItemIDChange,
      }),
      [props.gefocustItemID, props.onGefocustItemIDChange],
    ),
  );
  const [sortering, setSortering, sorteringRef] = useStateControl<VerkennerSortering>(
    useMemo(
      () => ({
        defaultUncontrolledState: props.defaultSortering,
        controlledState: props.sortering,
        onControlledStateChange: props.onSorteringChange,
      }),
      [props.sortering, props.onSorteringChange],
    ),
  );
  const [zoekterm, setZoekterm, zoektermRef] = useStateControl<string>(
    useMemo(
      () => ({
        defaultUncontrolledState: props.defaultZoekterm,
        controlledState: props.zoekterm,
        onControlledStateChange: props.onZoektermChange,
      }),
      [props.zoekterm, props.onZoektermChange],
    ),
  );

  const [
    mapToevoegenDialoogState,
    setMapToevoegenDialoogState,
  ] = useState<IMapToevoegenDialoogState | null>(null);

  const [itemState, setItemState] = useState<Record<string, IVerkennerItemState>>({});
  // tslint:disable-next-line:variable-name
  const [itemsData, _setItemsData] = useState<IRemoteData<IItemsData>>(createPendingRemoteData());
  const itemsDataRef = useRef<IRemoteData<IItemsData>>(itemsData);
  const setItemsData = useCallback(
    (itemData: IRemoteData<IItemsData>) => {
      itemsDataRef.current = itemData;
      _setItemsData(itemData);
    },
    [_setItemsData],
  );
  const [filterSchema, setFilterSchema] = useState<IFilterSchema>({});
  const orderSchema = useMemo(() => verkennerSorteringNaarOrderSchema(sortering), [
    JSON.stringify(sortering),
  ]);

  // const [scrollNaarIndex, setScrollNaarIndex] = useState<number | null>(null);

  const bepalenItems = useCallback(
    async (
      bestandsmapID: number | null,
      paginatie: IPaginatiePositie,
      toevoegenAanItems: Record<number, IVerkennerItem> = {},
    ): Promise<{ totaalAantal: number; items: Record<number, IVerkennerItem> }> => {
      const mergedFilterSchema: IFilterSchema = {
        uitgebreideFilter: {
          and: [
            {
              filter: {
                naam: 'BIJHORENDE_BESTANDSMAP_ID',
                data: bestandsmapID,
              },
            },
            ...(filterSchema.filters?.map((filter) => ({
              filter,
            })) ?? []),
            ...(filterSchema.uitgebreideFilter === undefined
              ? []
              : [filterSchema.uitgebreideFilter!]),
          ],
        },
      };

      // Mappen en bestanden voor de huidige bestandsmap
      const mappenEnBestandenResult = await api.v2.bestand.ophalenBestandenEnMappen({
        paginatie,
        filterSchema: mergedFilterSchema,
        orderSchema,
      });

      return await bepalenVerkennerItemsVanMappenEnBestandenResult(
        mappenEnBestandenResult,
        paginatie,
        toevoegenAanItems,
      );
    },
    [filterSchema, orderSchema],
  );

  const handleOrderSchemaGewijzigd = useCallback(async () => {
    const data = await bepalenItems(bestandsmapID, {
      index: rowsRenderedRef.current === null ? 0 : rowsRenderedRef.current!.startIndex,
      aantal:
        rowsRenderedRef.current === null
          ? props.paginatieAantal ?? STANDAARD_PAGINATIE_AANTAL
          : rowsRenderedRef.current!.stopIndex - rowsRenderedRef.current!.startIndex,
    });
    setItemsData(
      createReadyRemoteData({
        items: data.items,
        totaalAantal: data.totaalAantal,
        bestandsmappenboom: itemsDataRef.current!.data!.bestandsmappenboom,
        lijstWeergaveScrollNaarIndex: null,
      }),
    );
  }, [filterSchema, orderSchema, bestandsmapID, props.paginatieAantal, setItemsData]);

  useBijGewijzigdEffect(() => {
    handleOrderSchemaGewijzigd();
  }, [orderSchema]);

  const handleInitieelEnBestandsmapGewijzigd = useCallback(
    async (bestandsmapID: number | null) => {
      const maakBestandsmappenboomPromise = async (): Promise<
        IOphalenBestandsmappenResultElement[] | null
      > => {
        if (bestandsmapID === null) {
          return null;
        }

        const result = await api.v2.bestand.ophalenBestandsmappenboom({
          onderliggendeBestandsmapID: bestandsmapID,
        });
        return result.boom;
      };

      const maakScrollNaarIndexPromise = async (): Promise<number | null> => {
        if (gefocustItemIDRef.current === null) {
          return null;
        }
        const itemID = gefocustItemIDRef.current!;
        const { id, type } = vanVerkennerItemId(itemID);

        const items: Array<{
          Soort: 'B' | 'M';
          ID: number;
        }> = [];
        switch (type) {
          case EVerkennerItemType.Map:
            items.push({
              Soort: 'M',
              ID: id,
            });
            break;
          case EVerkennerItemType.Bestand:
            items.push({
              Soort: 'B',
              ID: id,
            });
            break;
          default:
            throw new Error('Onbekend item type zoekvensteritem gekozen');
        }

        const positieResult = await api.v2.bestand.bepalenPositieBinnenBestandenEnMappenVoorBestandsmap(
          {
            bestandsmapID,
            items,
            orderSchema,
            filterSchema,
          },
        );

        const positieItem = positieResult.posities[0];
        if (positieItem === undefined) {
          throw new Error('Positie van item niet gevonden');
        }

        return positieItem.Positie;
      };

      const [data, bestandsmappenboom, positie] = await Promise.all([
        bepalenItems(bestandsmapID, {
          index: 0,
          aantal: props.paginatieAantal ?? STANDAARD_PAGINATIE_AANTAL,
        }),
        maakBestandsmappenboomPromise() as any,
        maakScrollNaarIndexPromise(),
      ]);

      setItemsData(
        createReadyRemoteData({
          items: data.items,
          totaalAantal: data.totaalAantal,
          bestandsmappenboom,
          lijstWeergaveScrollNaarIndex: positie,
        }),
      );
    },
    [props.paginatieAantal, setItemsData, bepalenItems],
  );

  useEffect(() => {
    handleInitieelEnBestandsmapGewijzigd(bestandsmapID);
  }, [bestandsmapID]);

  const handleItemHernoemd = useCallback(
    async (id: string, naam: string) => {
      await verkennerItemHernoemen({
        id,
        naam,
        checkStore,
        items: itemsDataRef.current!.data!.items,
        onItemsChange: (items) =>
          setItemsData(
            createReadyRemoteData({
              ...itemsDataRef.current!.data!,
              items,
            }),
          ),
      });
    },
    [setItemsData],
  );

  const handleItemsVerwijderd = useCallback(
    async (ids: string[]) => {
      await verkennerItemsVerwijderen({
        ids,
        checkStore,
        data: itemsDataRef.current!.data!,
        onDataChange: (data) =>
          setItemsData(
            createReadyRemoteData({
              ...itemsDataRef.current!.data!,
              ...data,
            }),
          ),
      });
    },
    [setItemsData],
  );

  const handleItemInBestandsmapToegevoegd = useCallback(
    async (item: IVerkennerItem) => {
      const result = await api.v2.bestand.bepalenPositieBinnenBestandenEnMappenVoorBestandsmap({
        bestandsmapID,
        items: [
          {
            Soort: item.type === EVerkennerItemType.Bestand ? 'B' : 'M',
            ID: vanVerkennerItemId(item.id).id,
          },
        ],
        orderSchema,
        filterSchema,
      });

      const positieItem = result.posities[0];
      if (positieItem === undefined) {
        throw new Error('Positie van item niet gevonden');
      }

      const currentItemsData = itemsDataRef.current.data!;

      // Hoog alle items op waarvan de positie hoger is dan de positie van het item dat is toegevoegd
      const newItems = Object.keys(currentItemsData.items)
        .map(Number)
        .reduce((acc, idx) => {
          const item = currentItemsData.items[idx];
          if (idx < positieItem.Positie) {
            return acc;
          }

          return {
            ...acc,
            [idx + 1]: item,
          };
        }, currentItemsData.items);

      newItems[positieItem.Positie] = item;

      setItemsData(
        createReadyRemoteData({
          ...currentItemsData,
          items: newItems,
          totaalAantal: currentItemsData.totaalAantal + 1,
        }),
      );
    },
    [bestandsmapID, itemsData, setItemsData, orderSchema, filterSchema],
  );

  const handleItemVerplaatst = useCallback(
    async (verplaatstNaarBestandsmapID: number | null, itemId: string) => {
      const currentItemsData = itemsDataRef.current.data!;

      const itemIdx = Object.keys(currentItemsData.items).findIndex(
        (itemKey) => currentItemsData.items[Number(itemKey)].id === itemId,
      );
      if (itemIdx === undefined) {
        throw new Error('Item niet gevonden');
      }
      const item = currentItemsData.items[itemIdx]!;

      const newItems = Object.keys(currentItemsData.items)
        .map(Number)
        .reduce(
          (acc, idx) => {
            const item = currentItemsData.items[idx];
            if (item.id === itemId) {
              return {
                ...acc,
                offset: acc.offset + 1,
              };
            }

            return {
              ...acc,
              data: {
                ...acc.data,
                [idx - acc.offset]: item,
              },
            };
          },
          {
            data: {},
            offset: 0,
          },
        ).data;

      setItemsData(
        createReadyRemoteData({
          ...currentItemsData,
          items: newItems,
          totaalAantal: currentItemsData.totaalAantal - 1,
        }),
      );

      if (item.type === EVerkennerItemType.Map) {
        const mapItem = item as IMapVerkennerItem;
        const params = {
          bestandsmapID: mapItem.map.ID,
          naarBestandsmapID: verplaatstNaarBestandsmapID,
        };
        const checkData = await api.v2.bestand.checkVerplaatsBestandsmapNaarBestandsmap(params);

        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          // Herstellen
          setItemsData(createReadyRemoteData(currentItemsData));
          return;
        }
        await api.v2.bestand.verplaatsBestandsmapNaarBestandsmap(params);
      } else if (item.type === EVerkennerItemType.Bestand) {
        const bestandItem = item as IBestandVerkennerItem;
        const params = {
          bestandID: bestandItem.bestand.ID,
          bestandsmapID: verplaatstNaarBestandsmapID,
        };
        const checkData = await api.v2.bestand.checkVerplaatsBestandNaarBestandsmap(params);

        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          // Herstellen
          setItemsData(createReadyRemoteData(currentItemsData));
          return;
        }
        await api.v2.bestand.verplaatsBestandNaarBestandsmap(params);
      }
    },
    [],
  );

  const verplaatsBestandenNaarMap = useCallback(
    async (bestanden: IOphalenBestandenResultElement[], naarBestandsmapID: number | null) => {
      await Promise.all(
        bestanden.map(async (bestand) => {
          await api.v2.bestand.verplaatsBestandNaarBestandsmap({
            bestandID: bestand.ID,
            bestandsmapID: naarBestandsmapID,
          });

          const positieResult = await api.v2.bestand.bepalenPositieBinnenBestandenEnMappenVoorBestandsmap(
            {
              bestandsmapID: naarBestandsmapID,
              items: [
                {
                  ID: bestand.ID,
                  Soort: 'B',
                },
              ],
              orderSchema,
              filterSchema,
            },
          );

          const positieItem = positieResult.posities[0];
          if (positieItem === undefined) {
            throw new Error('Positie van item niet gevonden');
          }

          const currentItemsData = itemsDataRef.current.data!;

          // Hoog alle items op waarvan de positie hoger is dan de positie van het item dat is toegevoegd
          const newItems = Object.keys(currentItemsData.items)
            .map(Number)
            .reduce((acc, idx) => {
              const item = currentItemsData.items[idx];
              if (idx < positieItem.Positie) {
                return acc;
              }

              return {
                ...acc,
                [idx + 1]: item,
              };
            }, currentItemsData.items);

          newItems[positieItem.Positie] = {
            type: EVerkennerItemType.Bestand,
            id: naarVerkennerItemId(bestand.ID, EVerkennerItemType.Bestand),
            bestand,
            labels: [],
          };

          setItemsData(
            createReadyRemoteData({
              ...currentItemsData,
              items: newItems,
              totaalAantal: currentItemsData.totaalAantal + 1,
            }),
          );
        }),
      );
    },
    [handleItemInBestandsmapToegevoegd, orderSchema, filterSchema],
  );

  const handleLokaleBestandenUploadenNaarMap = useCallback(
    async (lokaleBestanden: File[], naarBestandsmapID: number | null) => {
      const bestanden = await Promise.all(
        lokaleBestanden.map(
          async (file) =>
            await api.v2.bestand.upload(
              {
                file,
                naam: file.name,
                grootte: file.size,
                url: URL.createObjectURL(file),
                mediaType: file.type,
              },
              (percentage) => {
                // TODO
              },
            ),
        ),
      );

      await verplaatsBestandenNaarMap(bestanden, naarBestandsmapID);
    },
    [verplaatsBestandenNaarMap],
  );

  const handleReportRowsRendered = useCallback(
    (index: { startIndex: number; stopIndex: number }) => {
      rowsRenderedRef.current = index;
    },
    [],
  );

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      const result = await bepalenItems(
        bestandsmapIDRef.current,
        positie,
        itemsDataRef.current!.data!.items,
      );
      setItemsData(
        createReadyRemoteData({
          ...itemsDataRef.current.data!,
          items: result.items,
          totaalAantal: result.totaalAantal,
        }),
      );
    },
    [setItemsData, bepalenItems],
  );

  const [zoekveldFocused, setZoekveldFocused] = useState(false);
  const isZoekvensterActief = useMemo(() => {
    return zoekveldFocused || zoekterm !== '';
  }, [zoekveldFocused, zoekterm]);

  useRealtimeListener((naamEnum: string, data: any) => {
    if (naamEnum === 'BESTAND_X_BESTANDSLABEL_GEMUTEERD') {
      const d = data as IBestandXBestandslabelGemuteerdData;

      if (itemsDataRef.current!.state === ERemoteDataState.Pending) {
        return;
      }

      // Kijk of we dit bestand hebben opgehaald
      const key = Object.keys(itemsDataRef.current!.data!.items).find((key) => {
        const item = itemsData.data!.items[Number(key)];
        return (
          item !== undefined &&
          item.type === EVerkennerItemType.Bestand &&
          item.bestand.ID === d.bestandID
        );
      });

      if (key === undefined) {
        return;
      }

      const verkennerItem = itemsDataRef.current!.data!.items[Number(key)] as IVerkennerItemBase &
        IBestandVerkennerItem;

      // Als we dit bestand hebben, dan labels bijwerken
      const newItemsData: IItemsData = {
        ...itemsDataRef.current!.data!,
        items: {
          ...itemsDataRef.current!.data!.items,
          [Number(key)]: {
            ...verkennerItem,
            labels: d.bestandXBestandslabels,
          },
        },
      };
      setItemsData(createReadyRemoteData(newItemsData));
    }
  });

  const handleBestandsmappenboomBreadcrumVerkennerItemDrop = useCallback(
    async (bestandsmapID: number | null, droppedItem: IVerkennerItem) => {
      const mapItem =
        bestandsmapID === null
          ? null
          : itemsData.data!.bestandsmappenboom!.find((x) => x.ID === bestandsmapID)!;

      if (
        (
          await toonBevestigVerplaatsenVerkennerItemNaarMap(
            checkStore,
            droppedItem,
            mapItem === null
              ? null
              : {
                  map: mapItem,
                  type: EVerkennerItemType.Map,
                },
          )
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await handleItemVerplaatst(bestandsmapID, droppedItem.id);
    },
    [itemsData.data?.bestandsmappenboom, handleItemVerplaatst],
  );

  const handleBestandenDrop = useCallback(
    async (bestanden: File[]) => {
      await handleLokaleBestandenUploadenNaarMap(bestanden, bestandsmapID);
    },
    [handleLokaleBestandenUploadenNaarMap, bestandsmapID],
  );

  const handleBestandenToegevoegd = useCallback(
    async (bestanden: IOphalenBestandenResultElement[]) => {
      await verplaatsBestandenNaarMap(bestanden, bestandsmapID);
    },
    [verplaatsBestandenNaarMap, bestandsmapID],
  );

  const handleZoekvensterItemGekozen = useCallback(
    async (item: IVerkennerItem) => {
      let binnenBestandsmapID: number | null = null;
      switch (item.type) {
        case EVerkennerItemType.Map:
          binnenBestandsmapID = item.map.Bovenliggende_BestandsmapID;
          break;
        case EVerkennerItemType.Bestand:
          binnenBestandsmapID = item.bestand.BestandsmapID;
          break;
        default:
          throw new Error('Onbekend item type');
      }

      const mapWordtGewijzigd = binnenBestandsmapID !== bestandsmapIDRef.current;

      setZoekterm('');
      switch (item.type) {
        case EVerkennerItemType.Map: {
          setGefocustItemID(item.id);
          setBestandsmapID(item.map.ID);
          break;
        }
        case EVerkennerItemType.Bestand: {
          setGefocustItemID(item.id);
          setBestandsmapID(item.bestand.BestandsmapID);
          break;
        }
      }

      if (mapWordtGewijzigd || itemsDataRef.current.state === ERemoteDataState.Pending) {
        return;
      }

      setSelectie([]);

      // Als de data niet meer opnieuw geladen zal worden (omdat de map niet wijzigt), dan
      // moeten we de positie van het gefocuste item bepalen om deze binnen de huidige map
      // te kunnen scrollen
      let items: Array<{
        Soort: 'B' | 'M';
        ID: number;
      }> | null = null;
      switch (item.type) {
        case EVerkennerItemType.Map:
          items = [
            {
              Soort: 'M',
              ID: item.map.ID,
            },
          ];
          break;
        case EVerkennerItemType.Bestand:
          items = [
            {
              Soort: 'B',
              ID: item.bestand.ID,
            },
          ];
          break;
      }

      const positieResult = await api.v2.bestand.bepalenPositieBinnenBestandenEnMappenVoorBestandsmap(
        {
          bestandsmapID: binnenBestandsmapID,
          items,
          orderSchema,
          filterSchema,
        },
      );

      const positieItem = positieResult.posities[0];
      if (positieItem === undefined) {
        throw new Error('Positie van item niet gevonden');
      }

      const nieuweItemsData: IRemoteData<IItemsData> = createReadyRemoteData({
        ...itemsDataRef.current!.data!,
        lijstWeergaveScrollNaarIndex: positieItem.Positie,
      });
      setItemsData(nieuweItemsData);
    },
    [setZoekterm, setBestandsmapID, setGefocustItemID],
  );

  const handleBestandsmapChange = useCallback(
    (bestandsmapID: number | null) => {
      if (bestandsmapIDRef.current === bestandsmapID) {
        return;
      }
      setSelectie([]);
      setGefocustItemID(null);
      setBestandsmapID(bestandsmapID);
    },
    [setSelectie, setGefocustItemID, setBestandsmapID],
  );

  if (itemsData.state === ERemoteDataState.Pending) {
    return <TabelWeergavePlaceholder />;
  }

  return (
    <>
      <div
        className="d-flex flex-column flex-fill position-relative"
        style={{ backgroundColor: Kleur.Wit }}
      >
        <div className="p-2 pl-3 pr-3 d-flex flex-column">
          <div className="d-flex align-items-center">
            <BestandsmappenboomBreadcrumb
              bestandsmappenboom={itemsData.data!.bestandsmappenboom}
              onBestandsmapIDChange={handleBestandsmapChange}
              onVerkennerItemDrop={handleBestandsmappenboomBreadcrumVerkennerItemDrop}
            />
            <div className="flex-fill" />
          </div>

          <div className="d-flex align-items-center mt-2">
            <Actiebalk
              onMapToevoegen={async () => {
                setMapToevoegenDialoogState({
                  bovenliggendeBestandsmapID: bestandsmapID,
                });
              }}
              onVerwijderen={async () => {
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: 'Bevestig verwijderen bestand(en)/map(pen)',
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }
                await handleItemsVerwijderd(selectie);
                setSelectie([]);
              }}
              onBestandenToegevoegd={handleBestandenToegevoegd}
            />

            <div className="flex-fill" />

            <div className="d-flex align-items-center" style={{ position: 'relative', top: 3 }}>
              <div className="mr-3">
                <WeergaveKeuze
                  weergave={props.weergaveKeuze ?? EWeergaveKeuze.Lijst}
                  weergaven={[
                    {
                      key: EWeergaveKeuze.Lijst,
                      // naam: 'Gegroepeerd',
                      icon: (
                        <IconGroepWeergave style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
                      ),
                      tooltip: 'Lijst',
                    },
                    {
                      key: EWeergaveKeuze.Iconen,
                      // naam: 'Niet gegroepeerd',
                      icon: <IconGridView style={{ width: 18, height: 18, fill: Kleur.Grijs }} />,
                      tooltip: 'Iconen',
                    },
                  ]}
                  onChange={(x) => props.onWeergaveKeuzeChange?.(x)}
                />
              </div>

              <span>
                Totaal aantal&nbsp;<strong>{itemsData.data!.totaalAantal}</strong>
              </span>
            </div>
          </div>
        </div>

        {props.weergaveKeuze === EWeergaveKeuze.Lijst ? (
          <TabelWeergave
            gefocustItemId={gefocustItemID}
            onGefocustItemIdChange={setGefocustItemID}
            selectie={selectie}
            onSelectieChange={setSelectie}
            items={itemsData.data!.items}
            totaalAantalItems={itemsData.data!.totaalAantal}
            itemsState={itemState}
            onItemsStateChange={setItemState}
            onItemHernoemd={handleItemHernoemd}
            onItemsVerwijderd={handleItemsVerwijderd}
            onItemInBestandsmapToegevoegd={handleItemInBestandsmapToegevoegd}
            onItemVerplaatst={handleItemVerplaatst}
            onBestandenDrop={handleBestandenDrop}
            sortering={sortering}
            onSorteringChange={setSortering}
            onReportRowsRendered={handleReportRowsRendered}
            magItemSelecteren={props.magItemSelecteren ?? (() => true)}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
            onNavigerenBestandsmap={handleBestandsmapChange}
            scrollNaarIndex={itemsData.data!.lijstWeergaveScrollNaarIndex ?? undefined}
          />
        ) : props.weergaveKeuze === EWeergaveKeuze.Iconen ? (
          <GridWeergave
            gefocustItemId={gefocustItemID}
            onGefocustItemIdChange={setGefocustItemID}
            selectie={selectie}
            onSelectieChange={setSelectie}
            items={itemsData.data!.items}
            totaalAantalItems={itemsData.data!.totaalAantal}
            itemsState={itemState}
            onItemsStateChange={setItemState}
            onItemHernoemd={handleItemHernoemd}
            onItemsVerwijderd={handleItemsVerwijderd}
            onItemInBestandsmapToegevoegd={handleItemInBestandsmapToegevoegd}
            onItemVerplaatst={handleItemVerplaatst}
            onBestandenDrop={handleBestandenDrop}
            sortering={sortering}
            onSorteringChange={setSortering}
            onReportRowsRendered={handleReportRowsRendered}
            magItemSelecteren={props.magItemSelecteren ?? (() => true)}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
            onNavigerenBestandsmap={handleBestandsmapChange}
          />
        ) : (
          <div>Onbekende weergave</div>
        )}
        <Zoekvenster
          isActief={isZoekvensterActief}
          zoekterm={zoekterm}
          onItemGekozen={handleZoekvensterItemGekozen}
        />

        <div
          style={{
            position: 'absolute',
            top: 7,
            right: 15,
            zIndex: 3,
          }}
        >
          <ZoekVeld
            zoekterm={zoekterm}
            onZoektermChange={setZoekterm}
            onFocus={() => setZoekveldFocused(true)}
            onBlur={() => setZoekveldFocused(false)}
          />
        </div>
      </div>

      {mapToevoegenDialoogState !== null && (
        <MapToevoegenDialoog
          binnenBestandsmapID={mapToevoegenDialoogState.bovenliggendeBestandsmapID}
          open
          onSuccess={async (result) => {
            await handleItemInBestandsmapToegevoegd({
              type: EVerkennerItemType.Map,
              map: result.map,
              id: naarVerkennerItemId(result.map.ID, EVerkennerItemType.Map),
            });
            setMapToevoegenDialoogState(null);
          }}
          onAnnuleren={() => setMapToevoegenDialoogState(null)}
        />
      )}
    </>
  );
});

export default Verkenner;
