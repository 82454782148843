import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../../api';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import _ from 'lodash';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
import RelatieTab from './RelatieTab';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';

interface IProps extends IDialoogProps<{}> {
  relID: number;
}

enum ETablad {
  Relatie,
}
export interface IContext {
  relatie: IOphalenRelatiesResultElementV2 | null;
}
export const RelatieContext = React.createContext<IContext>({
  relatie: null,
});

const RelatieInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, relID } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Relatie);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Relatie,
        label: 'Relatie',
        content: RelatieTab,
      },
    ],
    [onSuccess],
  );

  const ophalenRelatie = useCallback(async () => {
    const relatie = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [relID] }] },
      })
    ).relaties[0];

    setRelatie(relatie);
  }, [relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  if (relatie === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center w-100">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Relatieinformatie</ModalTitle>
      </ModalHeader>
      <RelatieContext.Provider
        value={{
          relatie,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `550px` }}>
          <>
            <div className="d-flex flex-column flex-fill">
              <Tabblad
                tabbladen={tabbladen}
                geselecteerd={tabblad}
                onSelectieChange={(id) => {
                  setTabblad(id);
                }}
              />
            </div>
            <ModalFooter className="d-flex flex-row justify-content-start">
              <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
                Sluiten
              </button>
            </ModalFooter>
          </>
        </div>
      </RelatieContext.Provider>
    </Dialoog>
  );
};

export default RelatieInfoDialoog;
