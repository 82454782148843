import { makeObservable, observable } from 'mobx';
import api from '../api';
import {
  IOphalenBerichtContextenResult,
  IOphalenBerichtContextenResultElement,
} from '../../../shared/src/api/v2/bericht/Bericht';

const standaarBerichtContextNaamEnum = 'OVERIG';

class BerichtStore {
  public berichtContexten: IOphalenBerichtContextenResult | null = null;
  public standaardBerichtContext: IOphalenBerichtContextenResultElement | null = null;

  constructor() {
    makeObservable(this, {
      berichtContexten: observable,
    });
  }

  public ophalenBerichtContexten = async () => {
    this.berichtContexten = await api.v2.bericht.ophalenBerichtContexten({});

    this.standaardBerichtContext = this.berichtContexten.find(
      (x) => x.NaamEnum === standaarBerichtContextNaamEnum,
    )!;
  };
}

export default BerichtStore;
