import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import useUrlState, { genereerUrlStateQueryParam } from '../../../../core/useUrlState';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { DXTableCheckboxComponent, TypedColumnExtension } from '../../../../helpers/dxTableGrid';
import {
  DXCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedDataTypeProvider,
  TypedTableColumnWidthInfo,
  DXTableToggleCellComponent,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
  TableSelection,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';
import { format } from 'date-fns';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenAanmaningenResultElement } from '../../../../../../shared/src/api/v2/aanmaning';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import MenuLayout from '../../../../components/MenuLayout';
import SelectieVak from '../../../../components/SelectieVak';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import DefinitiefMakenDialoog from './DefinitiefMakenDialoog';
import { EResultType } from '../../../../stores/CheckStore';
import DetailComp from '../FacturenDetailComp';
import { IconToevoegen, IconVerwijderen, IconVlag } from '../../../../components/Icons';
import _ from 'lodash';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import {
  IUrlState as IAanmaningenUrlState,
  defaultUrlState as aanmaningenDefaultUrlState,
} from '../../../Klant/Klantkaart/Entiteiten/entiteiten/Facturen/Aanmaningen';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
};

const geenData = {
  noData: 'Geen aanmaningen gevonden',
};

const Voorstel: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [aanmaningen, setAanmaningen] = useState<IOphalenAanmaningenResultElement[] | null>(null);
  const [aanmaningenVoorRelatie, setAanmaningenVoorRelatie] = useState<
    IOphalenAanmaningenResultElement[] | null
  >(null);

  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const [definitiefDialoogTonen, setDefinitiefDialoogTonen] = useState<boolean>(false);
  const { checkStore } = useContext(RootStoreContext);

  const ophalenAanmaningen = useCallback(async () => {
    const aanmaningenResult = await api.v2.aanmaning.ophalenAanmaningen({
      filterSchema: {
        filters: [{ naam: 'DEFINITIEF', data: false }],
      },
    });

    const aanmaningenGesorteerd = aanmaningenResult.aanmaningen;
    setAanmaningen(aanmaningenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenAanmaningen();
  }, [ophalenAanmaningen]);

  // Alle aanmaningen van de relaties tbv bepalen meest recente aanmaning
  const ophalenAanmaningenVoorRelatie = useCallback(async () => {
    if (relaties === null) {
      return;
    }
    const aanmaningenResult = (
      await api.v2.aanmaning.ophalenAanmaningen({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: relaties.map((x) => x.RelID) }],
        },
      })
    ).aanmaningen;
    setAanmaningenVoorRelatie(aanmaningenResult);
  }, [relaties]);

  useEffect(() => {
    ophalenAanmaningenVoorRelatie();
  }, [ophalenAanmaningenVoorRelatie]);

  useEffect(() => {
    (async () => {
      if (aanmaningen === null) {
        return;
      }
      const result = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [{ naam: 'IDS', data: _.uniq(aanmaningen.map((x) => x.RelID)) }],
          },
        })
      ).relaties;

      setRelaties(result);
    })();
  }, [aanmaningen]);

  const handleVerwijderenAanmaningen = useCallback(
    async (aanmIDs: number[]) => {
      const checkData = await api.v2.aanmaning.checkVerwijderenAanmaningen({
        AanmIDs: aanmIDs,
      });
      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Aanmaning(en) verwijderen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.aanmaning.verwijderenAanmaningen({
        AanmIDs: aanmIDs,
      });

      ophalenAanmaningen();
      setUrlStateSync('selectie', []);
    },
    [urlState.selectie],
  );

  const handleToevoegenAanmaningen = useCallback(async () => {
    const instelling = await api.v2.instelling.ophalenInstellingen({});

    const resultaat = await checkStore.bevestigen({
      inhoud: (
        <span>
          Voorstel voor aanmaningen maken?
          <br />
          Facturen waarvoor de vervaldatum langer dan {instelling.AanmaningRespijttermijn} dagen
          geleden is zullen in het voorstel opgenomen worden.
          <br />
          <br />
          Het is raadzaam om deze functie pas uit te voeren als:
          <ul>
            <li>De meest recente bankmutaties ingelezen zijn</li>
            <li>Eventuele verrekeningen uitgevoerd zijn</li>
            <li>De stornoberichten verstuurd zijn</li>
          </ul>
          Let op, het kan even duren voor je resultaat ziet.
        </span>
      ),
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    // Bepaal de facturen die in aanmerking komen voor aanmanen
    const factIDs = (await api.v2.aanmaning.selecterenFacturenVoorAanmaning({})).factIDs;
    // Maak de aanmaningen voor de gevonden facturen
    const aanmaningenResult = await api.v2.aanmaning.makenAanmaningen({ factIDs });

    ophalenAanmaningen();
  }, []);

  const keyExtractor = useCallback((row: IOphalenAanmaningenResultElement) => row.AanmID, []);

  const kolommen = useMemo<TypedColumn<IOphalenAanmaningenResultElement>[]>(() => {
    return [
      {
        name: 'Aanmaningdatum',
        title: 'Aanm.datum',
        getCellValue: (x) => x.Aanmaningdatum,
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
        getCellValue: (x) => x.Vervaldatum,
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
        getCellValue: (x) => x.Bedrag,
      },
      {
        name: '__kosten' as any,
        title: 'Kosten',
        getCellValue: (x) => _.sum(x.facturen.map((x) => x.KostenAanmaning)),
      },
      {
        name: '__hoogstAantalAanmaningen' as any,
        title: (
          <span title={'Hoogst aantal definitieve aanmaningen van de facturen in de aanmaning'}>
            # Amn.
          </span>
        ) as any,
        getCellValue: (x) => {
          if (x.facturen.length === 0) {
            return <span></span>;
          }
          const maximaalAantalAanmaningen = _.max(x.facturen.map((f) => f.aantalKeerAangemaand));
          return maximaalAantalAanmaningen;
        },
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x) => {
          if (relaties === null) {
            return;
          }
          const relatie = relaties.find((r) => r.RelID === x.RelID) ?? null;
          return relatie !== null ? relatie.weergavenaam : null;
        },
      },
      {
        name: '__laatsteAanmaning' as any,
        title: 'Laatst aangemaand',
        getCellValue: (x) => {
          if (aanmaningenVoorRelatie === null) {
            return null;
          }
          return (
            _.max(
              aanmaningenVoorRelatie
                .filter((a) => a.RelID === x.RelID && a.AanmID !== x.AanmID)
                .map((x) => x.Aanmaningdatum),
            ) ?? null
          );
        },
      },
    ];
  }, [relaties, aanmaningenVoorRelatie]);

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenAanmaningenResultElement>[]
  >(() => {
    return [
      {
        columnName: 'Aanmaningdatum',
        width: 135,
      },
      {
        columnName: 'Vervaldatum',
        width: 135,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__kosten' as any,
        width: 100,
      },
      {
        columnName: '__hoogstAantalAanmaningen' as any,
        width: 85,
      },
      {
        columnName: '__relatie' as any,
        width: 275,
      },
      {
        columnName: '__laatsteAanmaning' as any,
        width: 200,
      },
    ];
  }, []);

  const kolomExtensies: TypedColumnExtension<IOphalenAanmaningenResultElement>[] = useMemo(() => {
    return [
      // {
      //   columnName: 'Bedrag',
      //   align: 'right',
      // },
      // {
      //   columnName: '__kosten' as any,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Voorstel Aanmaningen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  await handleToevoegenAanmaningen();
                  ophalenAanmaningen();
                }}
              >
                <span>
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span>
                <span className="ml-2">Toevoegen voorstel</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => setDefinitiefDialoogTonen(true)}
              >
                <span>
                  <IconVlag style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span>
                <span className="ml-2">Definitief maken</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => {
                  handleVerwijderenAanmaningen(urlState.selectie);
                }}
              >
                <span>
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span>
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>
            <div className="mt-2 d-flex align-items-center">
              {aanmaningen !== null && (
                <>
                  <SelectieVak
                    totaalAantal={aanmaningen.length}
                    aantal={urlState.selectie.length}
                    entiteitEnkelvoud="tarief"
                    entiteitMeervoud="tarieven"
                    onChange={(allesGeselecteerd) => {
                      if (allesGeselecteerd) {
                        setUrlStateSync('selectie', aanmaningen.map(keyExtractor));
                      } else {
                        setUrlStateSync('selectie', []);
                      }
                    }}
                  />
                </>
              )}
            </div>
          </>
        }
        body={
          aanmaningen === null || aanmaningenVoorRelatie === null ? (
            <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid getRowId={keyExtractor} rows={aanmaningen} columns={kolommen}>
                <DataTypeProvider
                  for={[nameof<IOphalenAanmaningenResultElement>('Aanmaningdatum')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IOphalenAanmaningenResultElement>('Vervaldatum')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <TypedDataTypeProvider
                  for={[nameof<IOphalenAanmaningenResultElement>('Bedrag')]}
                  formatterComponent={(formatterProps) => (
                    <FormatteerBedrag bedrag={formatterProps.value} />
                  )}
                />

                <TypedDataTypeProvider
                  for={['__kosten']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenAanmaningenResultElement;

                    const kosten = _.sum(rij.facturen.map((x) => x.KostenAanmaning));
                    if (kosten === 0) {
                      return <span></span>;
                    }

                    return <FormatteerBedrag bedrag={kosten} />;
                  }}
                />

                <TypedDataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenAanmaningenResultElement;

                    const urlState: IAanmaningenUrlState = {
                      ...aanmaningenDefaultUrlState,
                      selectie: [rij.AanmID],
                    };
                    const query = genereerUrlStateQueryParam(urlState);

                    const relID = formatterProps.row.RelID;

                    return (
                      <RelatieVisualisatie
                        relID={relID}
                        relatieLinkBuilder={(hoedanigheid, relID) =>
                          `/${
                            hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                          }/${relID}/facturen/aanmaningen?state=${query}`
                        }
                      />
                    );
                  }}
                />

                <TypedDataTypeProvider
                  for={['__laatsteAanmaning']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenAanmaningenResultElement;

                    const aanmaningdatum = _.max(
                      aanmaningenVoorRelatie
                        .filter((x) => x.RelID === rij.RelID && x.AanmID !== rij.AanmID)
                        .map((x) => x.Aanmaningdatum),
                    );
                    if (aanmaningdatum === undefined) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(aanmaningdatum), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                  }}
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                    // alert(id);
                    await handleVerwijderenAanmaningen([id]);
                  }}
                />

                <RowDetailState defaultExpandedRowIds={[]} />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable estimatedRowHeight={43} />
                <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  width={65}
                  // showAddCommand={true}
                  showEditCommand
                  showDeleteCommand
                  commandComponent={DXCommandComponent}
                />
                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />

      {definitiefDialoogTonen && (
        <DefinitiefMakenDialoog
          open
          aanmIDs={urlState.selectie}
          onSuccess={() => {
            setDefinitiefDialoogTonen(false);
            ophalenAanmaningen();
            setUrlStateSync('selectie', []);
          }}
          onAnnuleren={() => setDefinitiefDialoogTonen(false)}
        />
      )}
    </>
  );
});

export default withRouter(Voorstel);
