import api from '../../index';

import ICheckData from '../../../../../shared/src/models/ICheckData';
import { ISorterenParams } from '../../../../../shared/src/api/v2/sorteren';
import {
  ICheckToevoegenBlogCategorieParams,
  ICheckToevoegenBlogitemParams,
  ICheckWijzigenBlogCategorieParams,
  ICheckWijzigenBlogitemParams,
  IOphalenBlogCategoriesInternParams,
  IOphalenBlogCategoriesInternResult,
  IOphalenBlogitemsInternParams,
  IOphalenBlogitemsInternResult,
  IToevoegenBlogCategorieParams,
  IToevoegenBlogCategorieResult,
  IToevoegenBlogitemParams,
  IToevoegenBlogitemResult,
  IVerwijderenBlogCategorieParams,
  IVerwijderenBlogitemParams,
  IWijzigenBlogCategorieParams,
  IWijzigenBlogitemParams,
} from '../../../../../shared/src/api/v2/blog';
import {
  IOphalenGroepVragenParams,
  IOphalenGroepVragenResults,
} from '../../../../../shared/src/api/v2/faq';

const blog = {
  // ophalen
  ophalenBlogitem: async (
    params: IOphalenBlogitemsInternParams,
  ): Promise<IOphalenBlogitemsInternResult> => {
    return await api.post('/v2/blog/ophalen-blogitem', params);
  },

  // toevoegen
  checkToevoegenBlogitem: async (params: ICheckToevoegenBlogitemParams) => {
    return await api.post<ICheckData>('v2/blog/check-toevoegen-blogitem', params);
  },
  toevoegenBlogitem: async (params: IToevoegenBlogitemParams) => {
    return await api.post<IToevoegenBlogitemResult>('v2/blog/toevoegen-blogitem', params);
  },

  // wijzigen
  checkWijzigenBlogitem: async (params: ICheckWijzigenBlogitemParams) => {
    return await api.post<ICheckData>('v2/blog/check-wijzigen-blogitem', params);
  },
  wijzigenBlogitem: async (params: IWijzigenBlogitemParams) => {
    return await api.post<any>('v2/blog/wijzigen-blogitem', params);
  },

  // verwijderen
  checkVerwijderenBlogitem: async (params: IVerwijderenBlogitemParams) => {
    return await api.post<ICheckData>('v2/blog/check-verwijderen-blogitem', params);
  },
  verwijderenBlogitem: async (params: IVerwijderenBlogitemParams) => {
    return await api.post<any>('v2/blog/verwijderen-blogitem', params);
  },

  // ophalen categorie
  ophalenBlogCategorie: async (
    params: IOphalenBlogCategoriesInternParams,
  ): Promise<IOphalenBlogCategoriesInternResult> => {
    return await api.post('/v2/blog/ophalen-blog-categorie', params);
  },

  // toevoegen categorie
  checkToevoegenBlogCategorie: async (params: ICheckToevoegenBlogCategorieParams) => {
    return await api.post<ICheckData>('v2/blog/check-toevoegen-blog-categorie', params);
  },
  toevoegenBlogCategorie: async (params: IToevoegenBlogCategorieParams) => {
    return await api.post<IToevoegenBlogCategorieResult>(
      'v2/blog/toevoegen-blog-categorie',
      params,
    );
  },

  // wijzigen categorie
  checkWijzigenBlogCategorie: async (params: ICheckWijzigenBlogCategorieParams) => {
    return await api.post<ICheckData>('v2/blog/check-wijzigen-blog-categorie', params);
  },
  wijzigenBlogCategorie: async (params: IWijzigenBlogCategorieParams) => {
    return await api.post<any>('v2/blog/wijzigen-blog-categorie', params);
  },

  // verwijderen categorie
  checkVerwijderenBlogCategorie: async (params: IVerwijderenBlogCategorieParams) => {
    return await api.post<ICheckData>('v2/blog/check-verwijderen-blog-categorie', params);
  },
  verwijderenBlogCategorie: async (params: IVerwijderenBlogCategorieParams) => {
    return await api.post<any>('v2/blog/verwijderen-blog-categorie', params);
  },
};

export default blog;
