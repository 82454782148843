import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import api from '../../../../../../../api';
import useUrlState from '../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// import { KlantContext } from '../../Klant/index';
import {
  IOphalenReviewsResultElement,
  IOphalenUitnodigingenResultElement,
} from '../../../../../../../../../shared/src/api/v2/review/klant';
import { IconToevoegen, IconVerwijderen } from '../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../../shared/src/api/v2/relatie';
import nameof from '../../../../../../../core/nameOf';
import { format } from 'date-fns';
import PersoonVisualisatie from '../../../../../../../components/personalia/PersoonVisualisatie';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

export interface IRow extends IOphalenReviewsResultElement {}

const Reviews: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'selectie-review-uitnodigingen',
  );
  // const { klantkaartStore } = useContext(RootStoreContext);
  // const klantContext = useContext(KlantContext);
  const { checkStore } = useContext(RootStoreContext);

  const [reviews, setReviews] = useState<IOphalenReviewsResultElement[] | null>(null);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const ophalenBeoordelingen = useCallback(async () => {
    const reviews = (
      await api.v2.review.ophalenReviews({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [props.relID] }],
        },
      })
    ).reviews;

    setReviews(reviews);
  }, [props.relID]);

  useEffect(() => {
    ophalenBeoordelingen();
  }, [ophalenBeoordelingen]);

  const ophalenRelatie = useCallback(async () => {
    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.relID] }],
        },
      })
    ).relaties;

    setRelatie(relaties[0]);
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'DatumBeoordeling',
        title: 'Datum',
      },
      {
        name: 'Score',
        title: 'Score',
      },
      {
        name: 'Aanbevelen',
        title: 'Aanbev.',
      },
      {
        name: 'BeoordelingKort',
        title: 'Beoordeling kort',
      },
      {
        name: '__persoon' as any,
        title: 'Persoon',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'DatumBeoordeling',
        width: 110,
      },
      {
        columnName: 'Score',
        width: 75,
      },
      {
        columnName: 'Aanbevelen',
        width: 75,
      },
      {
        columnName: 'BeoordelingKort',
        width: 400,
      },
      {
        columnName: '__persoon' as any,
        width: 250,
      },
    ],
    [],
  );

  return reviews === null || relatie === null ? (
    <div className="d-flex flex-fill justify-content-center align-items-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex"></div>
      </div>

      {reviews === null || relatie === null ? (
        <LoadingSpinner />
      ) : (
        <>
          {relatie.GeenReviewUitnodiging && <span>Geen uitnodiging sturen voor deze relatie</span>}
          <GridStyleWrapper height={'calc(100vh - 150px)'}>
            <Grid rows={reviews} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={[nameof<IRow>('DatumBeoordeling')]}
                formatterComponent={(formatterProps) => {
                  return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameof<IRow>('Aanbevelen')]}
                formatterComponent={(formatterProps) => {
                  return <span>{formatterProps ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <DataTypeProvider
                for={['__persoon']}
                formatterComponent={(formatterProps) => {
                  const rij: IRow = formatterProps.row;
                  if (rij.PersID === null) {
                    return <span></span>;
                  }
                  return (
                    <PersoonVisualisatie
                      persID={rij.PersID}
                      options={{ geenLinkToepassen: true }}
                    />
                  );
                }}
              />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />

              <RowDetailState defaultExpandedRowIds={[]} />

              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />

              <EditingState
                onCommitChanges={(changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1];
                  alert(id);
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  // props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                  // alert(id);
                }}
              />

              <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={() => <span></span>}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}
    </div>
  );
});

export default withRouter(Reviews);
