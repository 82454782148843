import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import nameOf from '../../../../core/nameOf';
import useUrlState from '../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import {
  IOphalenFaqResultElement,
  IOphalenGroepenResult,
} from '../../../../../../shared/src/api/v2/faq';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';
import { withRouter } from 'react-router-dom';
import {
  IOphalenFaqGroepResult,
  IOphalenFaqGroepResultElement,
} from '../../../../../../shared/src/api/v2/faqGroep';
import { IOphalenProductsoortenResult } from '../../../../../../shared/src/api/v2/product/soort';
import { format } from 'date-fns';
import { IOphalenWebPaginasResult } from '../../../../../../shared/src/api/v2/website/pagina';
import WebpaginaFilter from './WebpaginaFilter';
import ProductsoortFilter from './ProductsoortFilter';
import FAQGroepFilter from './FAQGroepFilter';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
  Webpaginas = 'WEBPAGINA_IDS',
  Productsoorten = 'PRODSRT_IDS',
  Groepen = 'GROEP_IDS',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const Vragen = (props: IProps) => {
  const [webpaginas, setWebpaginas] = useState<IOphalenWebPaginasResult | null>(null);
  const [productsoorten, setProductsoorten] = useState<IOphalenProductsoortenResult | null>(null);
  const [faqgroepen, setFaqgroepen] = useState<IOphalenGroepenResult | null>(null);

  const ophalenWebPaginas = useCallback(async () => {
    const result = await api.v2.extern.pagina.ophalenWebPaginas({
      orderSchema: { orders: [{ naam: 'NAAM_ENUM', richting: 'ASC' }] },
    });
    setWebpaginas(result);
  }, []);

  useEffect(() => {
    ophalenWebPaginas();
  }, [ophalenWebPaginas]);

  const ophalenProductsoorten = useCallback(async () => {
    const results = await api.v2.productsoort.ophalenProductsoorten({});
    setProductsoorten(results);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const ophalenFaqGroepen = useCallback(async () => {
    const results = await api.v2.faq.ophalenGroepen({});
    setFaqgroepen(results);
  }, []);

  useEffect(() => {
    ophalenFaqGroepen();
  }, [ophalenFaqGroepen]);

  if (webpaginas === null || productsoorten === null || faqgroepen === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Inner
      {...props}
      webpaginas={webpaginas}
      productsoorten={productsoorten}
      faqgroepen={faqgroepen}
    />
  );
};

const Inner = (
  props: IProps & {
    webpaginas: IOphalenWebPaginasResult;
    productsoorten: IOphalenProductsoortenResult;
    faqgroepen: IOphalenGroepenResult;
  },
) => {
  const defaultUrlState: IUrlState = {
    selectie: [],
    filterdata: [
      {
        naam: EFilter.Zoekterm,
        data: '',
        isActief: false,
      },
      {
        naam: EFilter.Groepen,
        data: props.faqgroepen.groepen[0].ID,
        isActief: false,
      },
      {
        naam: EFilter.Productsoorten,
        data: props.productsoorten[0].ProdSrtID,
        isActief: false,
      },
      {
        naam: EFilter.Webpaginas,
        data: props.webpaginas.webPaginas.find((x) => x.NaamEnum === 'PROD-DETAIL')!.ID,
        isActief: false,
      },
    ],
    toevoegenDialoogState: null,
    wijzigenDialoogState: null,
  };

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [faq, setFaq] = useState<IOphalenFaqResultElement[] | null>(null);
  const [faqGroep, setFaqGroep] = useState<IOphalenFaqGroepResultElement[] | null>(null);
  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
      {
        naam: EFilter.Groepen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (props.faqgroepen === null) {
            return <LoadingSpinner />;
          }
          return <FAQGroepFilter {...weergaveProps} groepen={props.faqgroepen} />;
        },
      },
      {
        naam: EFilter.Productsoorten,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (props.productsoorten === null) {
            return <LoadingSpinner />;
          }
          return <ProductsoortFilter {...weergaveProps} productsoorten={props.productsoorten} />;
        },
      },
      {
        naam: EFilter.Webpaginas,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (props.webpaginas === null) {
            return <LoadingSpinner />;
          }
          return <WebpaginaFilter {...weergaveProps} webpaginas={props.webpaginas} />;
        },
      },
    ],
    [props.webpaginas, props.productsoorten, props.faqgroepen],
  );

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('toevoegenDialoogState', {});
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { IDs: urlState.selectie };

    const checkData = await api.v2.faq.checkVerwijderenFaq(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde teksten verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.faq.verwijderenFaq(params);
    setUrlStateSync('selectie', []);

    await ophalenFaq();
  }, [urlState.selectie]);

  const ophalenFaq = useCallback(async () => {
    const resultFaq = await api.v2.faq.ophalenFaq({
      filterSchema,
    });
    const faqGesorteerd = _.orderBy(resultFaq.faq, ['ID'], ['desc']);
    setFaq(faqGesorteerd);
  }, [filterSchema]);

  useEffect(() => {
    ophalenFaq();
  }, [ophalenFaq]);

  const ophalenFaqGroep = useCallback(async () => {
    const resultFaqGroep = await api.v2.faqGroep.ophalenFaqGroepen({});
    setFaqGroep(resultFaqGroep.faqGroepen);
  }, []);

  useEffect(() => {
    ophalenFaqGroep();
  }, [ophalenFaqGroep]);

  const ophalenTeksten = useCallback(async () => {
    if (faq === null) {
      return;
    }
    if (faqGroep === null) {
      return;
    }

    const tekstIDs = [
      ...faq.map((x) => x.Vraag_TekstID).filter((x) => x !== null),
      ...faq.map((x) => x.Antwoord_TekstID).filter((x) => x !== null),
      ...faqGroep.map((x) => x.Naam_TekstID).filter((x) => x !== null),
    ];

    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(resultTeksten.teksten);
  }, [faq, faqGroep]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten, faqGroep, faq]);

  const keyExtractor = useCallback((row: IOphalenFaqResultElement) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenFaqResultElement>[]>(
    () => [
      {
        name: 'vraag' as any,
        title: 'Vraag',
        getCellValue: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Vraag_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
      {
        name: 'antwoord' as any,
        title: 'Antwoord',
        getCellValue: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Antwoord_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
      {
        name: 'groepen' as any,
        title: 'Groepen',
        getCellValue: (rij) => {
          if (teksten === null) {
            return;
          }
          return rij.Groep_IDs.map((id) => {
            const groep = faqGroep?.find((groep) => groep.ID == id);
            const tekst = teksten.find((x) => x.TekstID === groep?.Naam_TekstID && x.TaalID === 1)!;
            return tekst !== undefined ? tekst.Tekst : '';
          }).join(', ');
        },
      },
      {
        name: '__productsoorten' as any,
        title: 'Productsoorten',
        getCellValue: (rij) => {
          if (props.productsoorten === null) {
            return;
          }

          return rij.ProdSrt_IDs.map((id) => {
            const soort = props.productsoorten.find((soort) => soort.ProdSrtID == id);
            return soort !== undefined ? soort.Naam : '';
          }).join(', ');
        },
      },
      {
        name: '__webpaginas' as any,
        title: 'Webpaginas',
        getCellValue: (rij: IOphalenFaqResultElement) => {
          if (props.webpaginas === null) {
            return;
          }

          return rij.Pagina_IDs.map((id) => {
            const paginas = props.webpaginas.webPaginas.find((pagina) => pagina.ID == id);
            return paginas !== undefined ? paginas.NaamEnum : '';
          }).join(', ');
        },
      },
      {
        name: 'RecordToegevoegd',
        title: 'Toegevoegd',
        getCellValue: (rij) => {
          return rij.RecordToegevoegd !== null
            ? format(new Date(rij.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
            : '';
        },
      },
    ],
    [teksten, props.productsoorten, props.webpaginas],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFaqResultElement>[]>(
    () => [
      {
        columnName: 'vraag' as any,
        width: 325,
      },
      {
        columnName: 'antwoord' as any,
        width: 450,
      },
      {
        columnName: 'groepen' as any,
        width: 300,
      },
      {
        columnName: '__productsoorten' as any,
        width: 275,
      },
      {
        columnName: '__webpaginas' as any,
        width: 300,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Website Faq Vragen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => {
                  setUrlStateSync('filterdata', x);
                }}
                onFilterSchemaChange={(x) => {
                  setFilterSchema(x);
                }}
              />
            </div>
          </div>
        }
        body={
          <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
            {faq === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 100px)">
                <Grid getRowId={keyExtractor} rows={faq} columns={kolommen}>
                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted !== undefined && changes.deleted.length > 0) {
                        const ids = changes.deleted as number[];
                      }
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogState', { id });
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={{ noData: 'Geen faq aanwezig' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow showSortingControls />
                  <TableEditColumn
                    width={35}
                    commandComponent={DXTableEditColumnCommandComponent}
                    cellComponent={DXTableEditColumnCellComponent}
                    showEditCommand
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('toevoegenDialoogState', null);
            await ophalenFaq();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            await ophalenFaq();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Vragen);
