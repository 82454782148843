import React from 'react';
import { IconPijlVolgend } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import styled from 'styled-components';

const Button = styled.button`
  background-color: rgba(0, 0, 255, 0.1);
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 255, 0.2);
  }
`;

interface IProps {
  onClick: (ev: React.MouseEvent) => void;
}

const NavigerenPijl = (props: IProps) => {
  return (
    <Button onClick={props.onClick}>
      <IconPijlVolgend style={{ fill: Kleur.Blauw, width: 16, height: 16 }} />
    </Button>
  );
};

export default NavigerenPijl;
