import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TableRowDetail as TRD } from '@devexpress/dx-react-grid';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import {
  IOphalenSponsoringVoorstelPersonenResult,
  IOphalenSponsoringVoorstelPersonenResultElement,
  IOphalenVoorstellenResultElement,
} from '../../../../../../shared/src/api/v2/sponsoring/voorstel';
import {
  IOphalenPersonenResult,
  IOphalenPersonenResultElementV2,
} from '../../../../../../shared/src/api/v2/persoon/persoon';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import { formatteerPersoonNaam } from '../../../../helpers';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import PersoonSelectieDialoog, {
  ETabblad,
} from '../../../../components/dialogen/PersoonSelectieDialoog';
import { gl } from 'date-fns/locale';
import WijzigenDialoog from './WijzigenDialoog';

type KolomId = 'naam' | 'functie';

const Detail = (props: TRD.ContentProps): JSX.Element => {
  const globaleRenderer = useContext(GlobaleRendererContext);

  const row: IOphalenVoorstellenResultElement = props.row;
  const [sponsoringVoorstelPersonenResult, setSponsoringVoorstelPersonenResult] = useState<
    IRemoteData<IOphalenSponsoringVoorstelPersonenResult>
  >(createPendingRemoteData());
  const [personenResult, setPersonenResult] = useState<IRemoteData<IOphalenPersonenResult | null>>(
    createPendingRemoteData(),
  );
  const ophalenPersonen = useCallback(async () => {
    const svpResult = await api.v2.sponsoring.voorstel.ophalenSponsoringVoorstellenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'SPON_VOORSTEL_IDS',
            data: [row.ID],
          },
        ],
      },
    });
    setSponsoringVoorstelPersonenResult(createReadyRemoteData(svpResult));
    if (svpResult.sponsoringVoorstelPersonen.length === 0) {
      setPersonenResult(createReadyRemoteData(null));
      return;
    }
    const personenResult = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: svpResult.sponsoringVoorstelPersonen.map((x) => x.PersID),
          },
        ],
      },
    });
    setPersonenResult(createReadyRemoteData(personenResult));
  }, []);
  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const personenBijID = useMemo<IRemoteData<{ [persID: number]: IOphalenPersonenResultElementV2 }>>(
    () =>
      personenResult.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            personenResult.data === null
              ? {}
              : personenResult.data!.personen.reduce(
                  (acc, curr) => ({
                    ...acc,
                    [curr.PersID]: curr,
                  }),
                  {},
                ),
          ),
    [personenResult],
  );

  const keyExtractor = useCallback(
    (rij: IOphalenSponsoringVoorstelPersonenResultElement) => rij.ID,
    [],
  );
  const kolommen = useMemo<ASPKolom<KolomId, IOphalenSponsoringVoorstelPersonenResultElement>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (rij) => {
          const persoon = personenBijID.data![rij.PersID];
          if (persoon === undefined) {
            return null;
          }

          return formatteerPersoonNaam({
            voorvoegsel: persoon.Voorvoegsel,
            voornaam: persoon.Voornaam,
            voorletters: persoon.Voorletters,
            achternaam: persoon.Achternaam,
            aanhefKort: persoon.geslacht.AanhefKort,
          });
        },
      },
      {
        key: 'functie',
        label: 'Functie',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => rij.Functie,
      },
    ],
    [personenBijID],
  );

  const handleToevoegenRij = useCallback(async () => {
    await globaleRenderer.render((renderProps) => {
      return (
        <PSDialoog
          sluiten={renderProps.destroy}
          ophalenPersonen={ophalenPersonen}
          sponVoorstelID={row.ID}
        />
      );
    });
  }, [row.ID, ophalenPersonen]);
  const handleWijzigenRij = useCallback(
    async (rij: IOphalenSponsoringVoorstelPersonenResultElement) => {
      await globaleRenderer.render((renderProps) => (
        <WijzigenDialoog
          sponsoringVoorstelPersoon={rij}
          open
          onSuccess={async () => {
            renderProps.destroy();

            await ophalenPersonen();
          }}
          onAnnuleren={() => renderProps.destroy()}
        />
      ));
    },
    [ophalenPersonen],
  );
  const handleVerwijderenRij = useCallback(
    async (rij: IOphalenSponsoringVoorstelPersonenResultElement) => {
      await api.v2.sponsoring.voorstel.verwijderenSponsoringVoorstelPersonen({
        ids: [rij.ID],
      });
      await ophalenPersonen();
    },
    [ophalenPersonen],
  );

  if (
    sponsoringVoorstelPersonenResult.state === ERemoteDataState.Pending ||
    personenBijID.state === ERemoteDataState.Pending
  ) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-fill flex-column" style={{ height: 350, background: 'white' }}>
      <ASPTabel
        rijen={sponsoringVoorstelPersonenResult.data!.sponsoringVoorstelPersonen}
        kolommen={kolommen}
        keyExtractor={keyExtractor}
        onWijzigenRij={handleWijzigenRij}
        onVerwijderenRij={handleVerwijderenRij}
        onToevoegenRij={handleToevoegenRij}
      />
    </div>
  );
};

interface IPSDialoogProps {
  sponVoorstelID: number;
  sluiten: () => void;
  ophalenPersonen: () => Promise<void>;
}

const PSDialoog = (props: IPSDialoogProps): JSX.Element => {
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.NieuwOfSelecteren);

  return (
    <PersoonSelectieDialoog
      open
      tabblad={tabblad}
      onTabbladChange={setTabblad}
      onSuccess={async (result) => {
        props.sluiten();
        await api.v2.sponsoring.voorstel.toevoegenSponsoringVoorstelPersoon({
          sponVoorstelID: props.sponVoorstelID,
          persID: result.persID,
          functie: null,
        });
        await props.ophalenPersonen();
      }}
      onAnnuleren={() => props.sluiten()}
    />
  );
};

export default Detail;
