import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../../api';
import {
  IOphalenTelefoonoproepContextenResult,
  IOphalenTelefoonoproepContextenResultElement,
} from '../../../../../shared/src/api/v2/telefonie';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../components/MenuLayout';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import useUrlState from '../../../core/useUrlState';
import ASPTabel from '../../../components/tabel/ASPTabel';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import { IOphalenFeedbacksResultElement } from '../../../../../shared/src/api/v2/feedback';
import { format } from 'date-fns';
import EmailComponent from '../../../components/communicatie/EmailComponent';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';

interface IProps extends RouteComponentProps {}

enum ETabelKolom {
  Datum,
  Inhoud,
  Email,
}

interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

const Feedbacks: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [feedbacks, setFeedbacks] = useState<IOphalenFeedbacksResultElement[] | null>(null);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const ophalenFeedbacks = useCallback(async () => {
    const result = await api.v2.website.feedback.ophalenFeedbacks({
      filterSchema: {
        // filters: [{ naam: 'IDS', data: [props.id] }],
      },
      orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
    });
    setFeedbacks(result.feedbacks);
  }, []);

  useEffect(() => {
    ophalenFeedbacks();
  }, [ophalenFeedbacks]);

  const tabelKolommen = useMemo<Array<ASPKolom<ETabelKolom, IOphalenFeedbacksResultElement>>>(
    () => [
      {
        key: ETabelKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 160,
        renderer: (rij) => format(new Date(rij.Datum), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: ETabelKolom.Inhoud,
        label: 'Inhoud',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 600,
        renderer: (rij) => (
          <span
            title={rij.Inhoud}
            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {rij.Inhoud}
          </span>
        ),
      },
      {
        key: ETabelKolom.Email,
        label: 'Email',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) =>
          rij.Email !== null ? <EmailComponent emailAdres={rij.Email} /> : <span />,
      },
    ],
    [],
  );

  const handleVerwijderen = useCallback(
    async (ids: number[]) => {
      const params = { ids };

      const checkData = await api.v2.website.feedback.checkVerwijderenFeedbacks(params);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }
      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je de geselecteerde feedbacks verwijderen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.website.feedback.verwijderenFeedbacks(params);
      setUrlStateSync('selectie', []);
      ophalenFeedbacks();
    },
    [ophalenFeedbacks],
  );

  return (
    <>
      {feedbacks === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => {
                  handleVerwijderen(urlState.selectie);
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>
          }
          body={
            <>
              <ASPTabel
                keyExtractor={(rij) => rij.ID}
                rijen={feedbacks}
                kolommen={tabelKolommen}
                selectie={urlState.selectie}
                onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                // onVerwijderenRij={async (x) => {
                //   const params = {
                //     ids: [x.ID],
                //   };
                //   //   await api.v2.telefonie.verwijderenTelefoonoproepContexten(params);
                //   await ophalenFeedbacks();
                // }}
                // verwijderenRijConfirmatie={async (x, verwijderen) => {
                //   const params = {
                //     ids: [x.ID],
                //   };

                //     const checkData = await api.v2.telefonie.checkVerwijderenTelefoonoproepContexten(
                //       params,
                //     );

                //     const checkResult = await checkStore.controleren({
                //       checkData,
                //     });
                //     if (checkResult.type === EResultType.Annuleren) {
                //       return;
                //     }

                //     if (
                //       (
                //         await checkStore.bevestigen({
                //           inhoud: 'Bevestig verwijderen context',
                //         })
                //       ).type === EResultType.Annuleren
                //     ) {
                //       return;
                //     }

                //   await verwijderen();
                // }}
                lokaalSorteren
              />
            </>
          }
        />
      )}
    </>
  );
};

export default Feedbacks;
