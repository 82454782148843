import { NavLink, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import PaginaHeader from '../../PaginaHeader';
import React from 'react';
import Metadata from './Metadata';

interface IProps extends RouteComponentProps {}

const Webpagina = (props: IProps) => {
  const { match } = props;
  return (
    <div className="d-flex flex-column flex-fill">
      <PaginaHeader>
        <ul>
          <li>
            <NavLink
              to={`${match.url}/metadata`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              &nbsp; Metadata
            </NavLink>
          </li>
        </ul>
      </PaginaHeader>

      <Route path={`${match.path}/metadata`} component={Metadata} />

      <Route exact path={match.path} render={() => <Redirect to={`${match.path}/metadata`} />} />
    </div>
  );
};

export default Webpagina;
