import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { IOphalenAdviezenResultElement } from '../../../../../../shared/src/api/v2/product/advies';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import postcode from '../../../../api/v2/dienst/postcodedata';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { EProductsoort, EProductstatus } from '../../../../bedrijfslogica/enums';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import ContractVisualisatie from '../../../../components/entiteitVisualisaties/ContractVisualisatie';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MultiSelect, { IOptie } from '../../../../components/formulier/MultiSelect';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import useUrlState from '../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';

interface IProps extends RouteComponentProps {}

enum EFilter {
  ProductsoortIds = 'PRODSRT_IDS',
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  filterdata: IFilterData<EFilter>[];
}

export interface IRegel extends IOphalenRelatiesResultElementV2 {
  producten: IOphalenProductenResultElementV2[];
  adviezen: IOphalenAdviezenResultElement[];
}

// const defaultUrlState: IUrlState = { selectie: [], uitgeklapt: [] };

const Staat: React.FC<IProps> = (props) => {
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      selectie: [],
      uitgeklapt: [],
      filterdata: [
        {
          naam: EFilter.ProductsoortIds,
          data:
            productsoorten !== null
              ? [productsoorten.find((x) => x.NaamEnum === EProductsoort.Droger)!.ProdSrtID]
              : [],
          isActief: false,
        },
      ],
    };
  }, [productsoorten]);

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      // filterSchema: { filters: [] },
      orderSchema: { orders: [{ naam: 'SORTNR', richting: 'ASC' }] },
    });
    setProductsoorten(result);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  useEffect(() => {
    setUrlState(defaultUrlState);
  }, [defaultUrlState]);

  const { checkStore } = useContext(RootStoreContext);

  const [relaties, setRelaties] = useState<IRegel[] | null>([]);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterdata), []),
  );

  const ophalenRelaties = useCallback(async () => {
    const productenResult = (
      await api.v2.product.ophalenProductenV2({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            { naam: 'LEEFTIJDGRENS_BEREIKT', data: true },
            { naam: 'PRODUCTSTATUS_NAAM_ENUMS', data: [EProductstatus.Verhuur] },
          ],
        },
      })
    ).producten.filter((x) => x.contractActueel !== null);

    const relIDs = productenResult.map((x) => x.contractActueel!.RelID);

    if (relIDs.length === 0) {
      setRelaties([]);
      return;
    }

    const relatiesResult = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: relIDs }],
        },
      })
    ).relaties;

    const adviezenResult = (
      await api.v2.product.advies.ophalenProductadviezen({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: relIDs }] },
      })
    ).adviezen;

    const relaties = relatiesResult.map((relatie) => {
      const producten = productenResult.filter((x) => x.contractActueel!.RelID === relatie.RelID);
      const adviezen = adviezenResult.filter((x) => x.RelID === relatie.RelID);
      return { ...relatie, producten, adviezen };
    });

    const relatiesGesorteerd = _.orderBy(
      relaties,
      [(x: IRegel) => _.min(x.producten.map((x) => x.DatumInBedrijf))],
      ['asc'],
    );

    setRelaties(relatiesGesorteerd);

    // setProducten(producten);
  }, [filterSchema]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  //   const ophalenAdviezen = useCallback(async () => {
  //     if (relaties === null) {
  //       return;
  //     }
  //     if (relaties.length === 0) {
  //       setAdviezen([]);
  //       return;
  //     }
  //     const adviezen = (
  //       await api.v2.product.advies.ophalenProductadviezen({
  //         filterSchema: { filters: [{ naam: 'REL_IDS', data: relaties.map((x) => x.RelID) }] },
  //       })
  //     ).adviezen;

  //     setAdviezen(adviezen);
  //   }, [relaties]);

  //   useEffect(() => {
  //     ophalenAdviezen();
  //   }, [ophalenAdviezen]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.ProductsoortIds,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Categorieen</span>
              {productsoorten === null ? (
                <LoadingSpinner />
              ) : (
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(data) => {
                    weergaveProps.onDataChange(data);
                    weergaveProps.toepassen();
                  }}
                  opties={productsoorten.map(
                    (x): IOptie<number> => {
                      return { key: x.ProdSrtID, weergave: x.Naam };
                    },
                  )}
                  isVerwijderbaar={false}
                />
              )}
            </span>
          );
        },
      },
    ],
    [productsoorten],
  );

  const keyExtractor = useCallback((row: IRegel) => row.RelID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellvalue: (x: IRegel) => x.weergavenaam,
      },
      {
        name: '__oudsteProduct' as any,
        title: 'Oudste product',
        getCellValue: (x: IRegel) => _.max(x.producten.map((x) => x.DatumInBedrijf)),
      },
      {
        name: '__leeftijd' as any,
        title: 'Leeftijd',
        getCellValue: (x) => {
          const producten: IOphalenProductenResultElementV2[] = _.orderBy(
            x.producten,
            ['DatumInBedrijf'],
            ['asc'],
          );
          const oudsteProduct = producten[0];

          return oudsteProduct.leeftijd;
        },
      },
      {
        name: '__contractnummer' as any,
        title: 'Contract',
      },
      {
        name: '__aantalProducten' as any,
        title: 'Atl. producten',
        getCellValue: (x: IRegel) => x.producten.length,
      },
      {
        name: '__soorten' as any,
        title: 'Categorieen',
        getCellValue: (x: IRegel) =>
          _.uniq(x.producten.map((x) => x.producttype.productsoort.Naam)),
      },
      {
        name: '__laatsteAdvies' as any,
        title: 'Laatste advies',
        getCellValue: (x) => _.max(x.adviezen.map((x) => x.DatumAdvies)),
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 300,
      },
      {
        columnName: '__aantalProducten' as any,
        width: 150,
      },
      {
        columnName: '__oudsteProduct' as any,
        width: 150,
      },
      {
        columnName: '__contractnummer' as any,
        width: 150,
      },
      {
        columnName: '__soorten' as any,
        width: 250,
      },
      {
        columnName: '__laatsteAdvies' as any,
        width: 135,
      },
      {
        columnName: '__leeftijd' as any,
        width: 100,
      },
    ],
    [],
  );

  if (relaties === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>ASP | Productadvies</Helmet>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              {/* <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              //   disabled={urlState.selectie.length === 0}
              onClick={async () => {
                if (
                  (
                    await checkStore.bevestigen({
                      titel: <span>Overzicht genereren?</span>,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }
                await ophalenRelaties();
              }}
            >
              <span></span>
              <span className="ml-2">Genereer overzicht</span>
            </button> */}
              <div className="d-flex flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          <>
            <div className="d-flex flex-column flex-fill">
              <AutoSizer style={{ flex: 1, width: '100%', height: '100%' }}>
                {(size) => {
                  return (
                    <GridStyleWrapper height={size.height}>
                      <Grid columns={kolommen} rows={relaties} getRowId={keyExtractor}>
                        <DataTypeProvider
                          for={['__relatie']}
                          formatterComponent={(formatterProps) => (
                            <RelatieVisualisatie
                              relID={formatterProps.row.RelID}
                              relatieLinkBuilder={(hoedanigheid, relID) =>
                                `/${
                                  hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                }/${relID}/productadvies/staat`
                              }
                            />
                          )}
                        />
                        <DataTypeProvider
                          for={['__aantalProducten']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRegel = formatterProps.row;
                            return <span>{rij.producten.length}</span>;
                          }}
                        />
                        <DataTypeProvider
                          for={['__oudsteProduct']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRegel = formatterProps.row;

                            const producten: IOphalenProductenResultElementV2[] = _.orderBy(
                              rij.producten,
                              ['DatumInBedrijf'],
                              ['asc'],
                            );
                            const oudsteProduct = producten[0];
                            return (
                              <span>
                                {format(new Date(oudsteProduct.DatumInBedrijf), 'dd-MM-yyyy')}
                              </span>
                            );
                          }}
                        />
                        <DataTypeProvider
                          for={['__contractnummer']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRegel = formatterProps.row;

                            const producten: IOphalenProductenResultElementV2[] = _.orderBy(
                              rij.producten,
                              ['DatumInBedrijf'],
                              ['asc'],
                            );
                            const oudsteProduct = producten[0];
                            return (
                              <ContractVisualisatie cntID={oudsteProduct.contractActueel!.CntID} />
                            );
                          }}
                        />
                        <DataTypeProvider
                          for={['__soorten']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRegel = formatterProps.row;
                            const soorten = _.uniq(
                              rij.producten.map((x) => x.producttype.productsoort.Naam),
                            ).join(', ');

                            return <span>{soorten}</span>;
                          }}
                        />
                        <DataTypeProvider
                          for={['__laatsteAdvies']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRegel = formatterProps.row;
                            const datum = _.max(rij.adviezen.map((x) => x.DatumAdvies));
                            if (datum === undefined) {
                              return <span></span>;
                            }
                            return <span>{format(new Date(datum), 'dd-MM-yyyy')}</span>;
                          }}
                        />

                        <DataTypeProvider
                          for={['__leeftijd']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRegel = formatterProps.row;

                            const producten: IOphalenProductenResultElementV2[] = _.orderBy(
                              rij.producten,
                              ['DatumInBedrijf'],
                              ['asc'],
                            );
                            const oudsteProduct = producten[0];

                            if (oudsteProduct.leeftijd === null) {
                              return <span></span>;
                            }
                            return <span>{maandenNaarJaren(oudsteProduct.leeftijd)}</span>;
                          }}
                        />

                        <RowDetailState
                          expandedRowIds={urlState.uitgeklapt}
                          onExpandedRowIdsChange={(x) =>
                            setUrlStateSync('uitgeklapt', x as number[])
                          }
                        />
                        <SortingState defaultSorting={[]} />
                        <IntegratedSorting />

                        <VirtualTable
                          messages={{ noData: 'Geen relaties gevonden voor de ingestelde filters' }}
                        />

                        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                        <TableHeaderRow showSortingControls />

                        {/* <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                    //   setUrlStateSync('wijzigenFactuurDialoogState', { inkFactID: id });
                  }}
                  onCommitChanges={() => null}
                /> */}
                        {/* <TableEditColumn
                    width={35}
                    // showAddCommand={true}
                    showEditCommand
                    commandComponent={DXCommandComponent}
                  /> */}

                        {/* <TableRowDetail
                    contentComponent={RowDetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  /> */}

                        <SelectionState
                          selection={urlState.selectie}
                          onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                        />
                        {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                      </Grid>
                    </GridStyleWrapper>
                  );
                }}
              </AutoSizer>
            </div>
          </>
        }
      />
    </>
  );
};

export default withRouter(Staat);
