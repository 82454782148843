import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import Combobox from '../../../../components/formulier/Combobox';
import Dialoog from '../../../../components/dialogen/Dialoog';
import * as Yup from 'yup';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenDubieuzeAdressenResultElement } from '../../../../../../shared/src/api/v2/relatie/acceptatie';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IProps extends IDialoogProps<null> {
  id: number;
}

export interface IFormikValues {
  notities: string;
  geblokkeerd: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  notities: 'Notities',
  geblokkeerd: 'Geblokkeerd',
};

const WijzigenDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [adres, setAdres] = useState<IOphalenDubieuzeAdressenResultElement | null>(null);

  const ophalenAdres = useCallback(async () => {
    const adres = (
      await api.v2.relatie.acceptatie.ophalenDubieuzeAdressen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).adressen[0];

    setAdres(adres);
  }, [props.id]);

  useEffect(() => {
    ophalenAdres();
  }, [ophalenAdres]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        id: props.id,
        notities: values.notities !== '' ? values.notities : null,
        geblokkeerd: values.geblokkeerd,
      };

      // const checkData = await api.v2.relatie.acceptatie.checkToevoegenDubieusAdres(params);
      // const checkResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (checkResult.type === EResultType.Annuleren) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      // if (
      //   (
      //     await checkStore.bevestigen({
      //       inhoud: <span>Adres toevoegen?</span>,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      await api.v2.relatie.acceptatie.wijzigenDubieusAdres(params);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (adres === null) {
      return null;
    }
    return {
      notities: adres.Notities !== null ? adres.Notities : '',
      geblokkeerd: adres.Geblokkeerd,
    };
  }, [adres]);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      notities: Yup.string(),
    };

    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen dubieus adres</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(formikProps: FormikProps<IFormikValues | null>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.notities}</label>
                        <Field
                          name={nameOf<IFormikValues>('notities')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <textarea {...field} className="form-control" rows={3} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('geblokkeerd')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Geblokkeerd</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default WijzigenDialoog;
