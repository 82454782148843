import React from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import Prolongatie from './Prolongaties';
import Vorderingen from './Vorderingen';
import Facturen from './Facturen';

const Facturering = ({ match }: RouteComponentProps) => (
  <div className="d-flex flex-column flex-fill">
    <PaginaHeader>
      <ul>
        <li>
          <NavLink
            to={`${match.url}/prolongaties`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
            &nbsp; Prolongaties
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${match.url}/vorderingen`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
            &nbsp; Vorderingen
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${match.url}/facturen`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
            &nbsp; Facturen
          </NavLink>
        </li>
      </ul>
    </PaginaHeader>

    <Route
      exact
      path={match.path}
      component={() => <Redirect to={`${match.path}/prolongaties`} />}
    />

    <Route path={`${match.path}/prolongaties`} component={Prolongatie} />
    <Route path={`${match.path}/vorderingen`} component={Vorderingen} />
    <Route path={`${match.path}/facturen`} component={Facturen} />
    <Route exact={true} path={match.path} render={() => <h3>selecteer een subpagina</h3>} />
  </div>
);

export default Facturering;
