import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';

import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenProductreferentiesOpgevraagdResultElement } from '../../../../../../shared/src/api/v2/transport/opdracht';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../helpers/dxTableGrid';
import {
  VirtualTable,
  Grid,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  SelectionState,
  EditingState,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { addDays, addMonths, format } from 'date-fns';
import nameof from '../../../../core/nameOf';
import WijzigenReferentieDialoog from './WijzigenReferentieDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import _ from 'lodash';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import {
  ERegelstatusTransport,
  ETransportopdrachtRegelsoort,
} from '../../../../bedrijfslogica/enums';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import ContractVisualisatie from '../../../../components/entiteitVisualisaties/ContractVisualisatie';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/transport';
import { IconSend } from '../../../../components/Icons';
import { EResultType } from '../../../../stores/CheckStore';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

enum EFilter {
  AlleenZonderReferentie = 'MET_PRODUCT_WAARVOOR_REFERENTIECODE_NOG_OPGEVEN',
  MetProductZonderReferentie = 'MET_PRODUCT_ZONDER_REFERENTIECODE',
}
interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.AlleenZonderReferentie,
      data: true,
      isActief: true,
    },
    {
      naam: EFilter.MetProductZonderReferentie,
      data: true,
      isActief: true,
    },
  ],
};
interface IRow extends IOphalenOpdrachtregelsResultElementV2 {
  Opdrachtnummer: string;
  dienst: IOphalenDienstenResultElement;
  locatie: { Plaatsnaam: string; Straatnaam: string; Huisnummer: number; Bisnummer: string | null };
}

const Referenties: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { instellingStore } = useContext(RootStoreContext);
  const { checkStore } = useContext(RootStoreContext);

  const [regels, setRegels] = useState<IRow[] | null>(null);
  const [wijzigenRegelID, setWijzigenRegelID] = useState<number | null>(null);
  const [opvragingen, setOpvragingen] = useState<
    IOphalenProductreferentiesOpgevraagdResultElement[] | null
  >(null);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const ophalenRegels = useCallback(async () => {
    // Max x dagen terug
    const ontbrekendeReferentiesMaxDagenTerug = instellingStore.OntbrekendeReferentiesMaxDagenTerug;
    const statusdatumVanaf = format(
      addDays(new Date(), -ontbrekendeReferentiesMaxDagenTerug),
      'yyyy-MM-dd',
    );

    const opdrachtregelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            { naam: 'REGELSTATUSSEN', data: [ERegelstatusTransport.UITGEVOERD] },
            { naam: 'STATUSDATUM_VANAF', data: statusdatumVanaf },
            { naam: 'REGELSOORTEN', data: [ETransportopdrachtRegelsoort.Levering] },
            { naam: 'UITGEVOERD_PRODUCT_IS_GEVULD', data: true },
            // { naam: 'REFERENTIECODE_IS_LEEG', data: true },
            // { naam: 'MET_PRODUCT_ZONDER_REFERENTIECODE', data: true },
          ],
        },
        // orderSchema: {
        //   orders: [
        //     {
        //       naam: 'REGELNUMMER',
        //       richting: 'ASC',
        //     },
        //   ],
        // },
      })
    ).regels;

    const trsOpdIDs = _.uniq(opdrachtregelsResult.map((x) => x.TrsOpdID));

    const opdrachtResult = await api.v2.transport.opdracht.ophalenOpdrachtenV2({
      filterSchema: {
        filters: [{ naam: 'IDS', data: trsOpdIDs }],
      },
    });

    const regels = opdrachtregelsResult.map((regel) => {
      const opdracht = opdrachtResult.opdrachten.find((x) => x.TrsOpdID === regel.TrsOpdID)!;
      return {
        ...regel,
        Opdrachtnummer: opdracht.Opdrachtnummer,
        dienst: opdracht.dienst,
        locatie: {
          Plaatsnaam: opdracht.locatie.Plaatsnaam,
          Straatnaam: opdracht.locatie.Straatnaam,
          Huisnummer: opdracht.locatie.Huisnummer,
          Bisnummer: opdracht.locatie.Bisnummer,
        },
      };
    });

    const regelsGesorteerd = _.orderBy(regels, ['Statusdatum'], ['desc']);

    setRegels(regelsGesorteerd);
  }, [filterSchema]);

  useEffect(() => {
    ophalenRegels();
  }, [ophalenRegels, filterSchema]);

  const ophalenProductreferentiesOpgevraagd = useCallback(async () => {
    if (regels === null) {
      return;
    }
    const result = await api.v2.transport.opdracht.ophalenProductreferentiesOpgevraagd({
      filterSchema: { filters: [{ naam: 'TRSREG_IDS', data: regels.map((x) => x.TrsRegID) }] },
    });
    setOpvragingen(result.regels);
  }, [regels]);

  useEffect(() => {
    ophalenProductreferentiesOpgevraagd();
  }, [ophalenProductreferentiesOpgevraagd]);

  const keyExtractor = useCallback((row: IRow) => row.TrsRegID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: '__opdrachtnummerMetregelnummer' as any,
        title: 'Opd.nr/rg',
        getCellValue: (x) => {
          return x.Opdrachtnummer + x.Regelnummer;
        },
      },
      {
        name: '__dienstnaam' as any,
        title: 'Dienst',
      },
      // {
      //   name: 'Regelnummer',
      //   title: 'Regel',
      // },

      {
        name: '__statusdatum' as any,
        title: 'Geleverd',
        getCellValue: (x) => x.status.Statusdatum,
      },
      {
        name: '__product' as any,
        title: 'Merk/type',
        getCellValue: (x) => {
          if (x.type !== null) {
            return x.type.Merknaam + ' ' + x.type.Typenaam;
          }
          if (x.product !== null) {
            return x.product.producttype.Merknaam + ' ' + x.product.producttype.Typenaam;
          }
          return '';
        },
      },
      {
        name: '__productsoort' as any,
        title: 'Srt.',
        getCellValue: (x) => {
          if (x.type !== null) {
            return x.type.ProductsoortnaamKort;
          }
          if (x.product !== null) {
            return x.product.producttype.ProductsoortnaamKort;
          }
          return '';
        },
      },
      {
        name: '__referentie' as any,
        title: 'Ref.',
        getCellValue: (x) => {
          return x.product!.Referentiecode != null ? x.product!.Referentiecode : '?';
        },
      },
      {
        name: 'GeenReferentiecode',
        title: 'Niet opgeven',
      },
      {
        name: '__locatie' as any,
        title: 'Locatie',
        getCellValue: (x) => {
          return (
            x.locatie.Plaatsnaam +
            ', ' +
            x.locatie.Straatnaam +
            ' ' +
            x.locatie.Huisnummer +
            (x.locatie.Bisnummer !== null ? ' ' + x.locatie.Bisnummer : '')
          );
        },
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__datumOpgevraagd' as any,
        title: 'Opgevraagd',
        getCellValue: (x) => {
          return x.product!.Referentiecode != null ? x.product!.Referentiecode : '?';
        },
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__opdrachtnummerMetregelnummer' as any,
        width: 100,
      },
      {
        columnName: '__dienstnaam' as any,
        width: 100,
      },
      // {
      //   columnName: 'Regelnummer',
      //   width: 60,
      // },
      {
        columnName: '__locatie' as any,
        width: 300,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__statusdatum' as any,
        width: 125,
      },
      {
        columnName: '__product' as any,
        width: 225,
      },
      {
        columnName: '__productsoort' as any,
        width: 75,
      },
      {
        columnName: '__referentie' as any,
        width: 100,
      },
      {
        columnName: 'ProductsoortnaamKort',
        width: 75,
      },
      {
        columnName: 'Referentiecode',
        width: 100,
      },
      {
        columnName: 'GeenReferentiecode',
        width: 125,
      },
      {
        columnName: '__datumOpgevraagd' as any,
        width: 125,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.AlleenZonderReferentie,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Alleen leveropdrachten waarvoor een referentie opgegeven moet worden</span>
            </span>
          );
        },
      },
      {
        naam: EFilter.MetProductZonderReferentie,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Met producten zonder referentie</span>
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Transport Ontbrekende referenties</title>
      </Helmet>

      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            disabled={urlState.selectie.length === 0}
            onClick={async () => {
              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Verzoek versturen?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              await api.v2.transport.opdracht.versturenVerzoekDoorgevenReferentiecodes({
                trsRegIDs: urlState.selectie,
              });

              ophalenRegels();
            }}
          >
            <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Verzoek doorgeven referentienummers</span>
          </button>
          <div className="d-flex flex-fill ml-3">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterData}
              onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
              onFilterSchemaChange={(x) => setFilterSchema(x)}
            />
          </div>
        </div>
        {/* <div className="d-flex mt-3">
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              // disabled={urlState.opdrachtenSelectie.length === 0}
              onClick={() => {}}
            >
              <IconVlag style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">knop</span>
            </button>
          </div> */}
      </div>
      {regels === null || opvragingen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 150px)'}>
          <Grid rows={regels} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__opdrachtnummerMetregelnummer']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return (
                  <span>
                    {rij.Opdrachtnummer} - {rij.Regelnummer}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__relatie']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return (
                  <span>{rij.RelID !== null ? <RelatieVisualisatie relID={rij.RelID} /> : ''}</span>
                );
              }}
            />

            <DataTypeProvider
              for={['__dienstnaam']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.dienst.NaamIdent}</span>;
              }}
            />

            <DataTypeProvider
              for={['__locatie']}
              formatterComponent={(formatterProps) => {
                const locatie = formatterProps.row.locatie;
                const adres =
                  locatie.Plaatsnaam +
                  ', ' +
                  locatie.Straatnaam +
                  ' ' +
                  locatie.Huisnummer +
                  (locatie.Bisnummer !== null ? ' ' + locatie.Bisnummer : '');
                return <span>{adres}</span>;
              }}
            />

            <DataTypeProvider
              for={['__soort']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return (
                  <span>
                    {rij.regelsoort.Code} {rij.OmruilID !== null ? '/O' : ''}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__product']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                if (rij.type !== null) {
                  return <span>{rij.type.Merknaam + ' ' + rij.type.Typenaam}</span>;
                }
                if (rij.product !== null) {
                  return (
                    <span>
                      {rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}
                    </span>
                  );
                }
                return <span></span>;
              }}
            />

            <DataTypeProvider
              for={['__productsoort']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                const product =
                  rij.type !== null ? (
                    <span>{rij.type.ProductsoortnaamKort}</span>
                  ) : rij.product !== null ? (
                    <span>{rij.product.producttype.ProductsoortnaamKort}</span>
                  ) : (
                    <span></span>
                  );
                return <span>{product}</span>;
              }}
            />

            <DataTypeProvider
              for={['__referentie']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                return (
                  <span>
                    {rij.product!.Referentiecode != null ? rij.product!.Referentiecode : '?'}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__contractnummer']}
              formatterComponent={(formatterProps) => {
                const regel: IRow = formatterProps.row;

                if (regel.contract === null) {
                  return <span></span>;
                }
                return <ContractVisualisatie cntID={regel.contract.CntID} />;
              }}
            />

            <DataTypeProvider
              for={['__statusdatum']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                return (
                  <span>
                    {rij.status.Statusdatum === null
                      ? ''
                      : format(new Date(rij.status.Statusdatum), 'dd-MM-yyyy')}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__datumOpgevraagd']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                const opgevragingen = opvragingen.filter((x) => x.TrsRegID === rij.TrsRegID);
                const datumOpgevraagd: Date | null =
                  opgevragingen.length > 0
                    ? _.max(opgevragingen.map((x) => x.DatumOpgevraagd))
                    : null;
                return (
                  <span>
                    {datumOpgevraagd !== null
                      ? format(new Date(datumOpgevraagd), 'dd-MM-yyyy')
                      : ''}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={[nameof('GeenReferentiecode')]}
              formatterComponent={(formatterProps) => {
                return <span>{formatterProps.value ? 'Ja' : ''}</span>;
              }}
            />

            <SelectionState
              selection={urlState.selectie}
              onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
            />
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable
              messages={{ noData: 'Er zijn regels gevonden voor de ingestelde filters' }}
            />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow showSortingControls />
            <EditingState
              onCommitChanges={(changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1];
                alert(id);
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                setWijzigenRegelID(id);
              }}
            />
            <TableEditColumn
              width={35}
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />

            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>
      )}

      {wijzigenRegelID !== null && (
        <WijzigenReferentieDialoog
          open
          trsRegID={wijzigenRegelID}
          onSuccess={() => {
            setWijzigenRegelID(null);
            ophalenRegels();
          }}
          onAnnuleren={() => setWijzigenRegelID(null)}
        />
      )}
    </>
  );
};

export default withRouter(Referenties);
