import {
  IBijwerkenAfbeeldingVanActieParams,
  IBijwerkenAfbeeldingVanActieResult,
  IOphalenActiesBijIDsParams,
  IOphalenActiesBijIDsResult,
  IOphalenActiesParams,
  IOphalenActiesResult,
  IToevoegenActieParams,
  IToevoegenActieResult,
  IToewijzenHoofdActieParams,
  IToewijzenHoofdActieResult,
  IVerwijderenActiesParams,
  IVerwijderenActiesResult,
  IWijzigenActiePeriodenVanActieParams,
  IWijzigenActiePeriodenVanActieResult,
  ICheckWijzigenActiePeriodenVanActieParams,
  ICheckWijzigenActiePeriodenVanActieResult,
  IWijzigenActieParams,
  IWijzigenActieResult,
  IWijzigenActiecodesVanActieParams,
  IWijzigenActiecodesVanActieResult,
  IWijzigenActietarievenAbonnementParams,
  IWijzigenActietarievenAbonnementResult,
  IOphalenTariefActieAbonnementenParams,
  IOphalenTariefActieAbonnementenResult,
} from '../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const acties = {
  ophalenActies: async (params: IOphalenActiesParams): Promise<IOphalenActiesResult> => {
    return await api.post('/v2/aanbod/tarieven/acties/ophalen-acties', params);
  },
  // ophalenActiesBijIDs: async (
  //   params: IOphalenActiesBijIDsParams,
  // ): Promise<IOphalenActiesBijIDsResult> => {
  //   return await api.post('/v2/aanbod/tarieven/acties/ophalen-acties-bij-ids', params);
  // },
  checkToevoegenActie: async (params: IToevoegenActieParams): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/tarieven/acties/check-toevoegen-actie', params);
  },
  toevoegenActie: async (params: IToevoegenActieParams): Promise<IToevoegenActieResult> => {
    return await api.post('/v2/aanbod/tarieven/acties/toevoegen-actie', params);
  },
  bijwerkenAfbeeldingVanActie: async (
    params: IBijwerkenAfbeeldingVanActieParams,
  ): Promise<IBijwerkenAfbeeldingVanActieResult> => {
    return await api.post('/v2/aanbod/tarieven/acties/bijwerken-afbeelding-van-actie', params);
  },
  toewijzenHoofdActie: async (
    params: IToewijzenHoofdActieParams,
  ): Promise<IToewijzenHoofdActieResult> => {
    return await api.post('/v2/aanbod/tarieven/acties/toewijzen-hoofd-actie', params);
  },
  checkVerwijderenActies: async (params: IVerwijderenActiesParams): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/tarieven/acties/check-verwijderen-acties', params);
  },
  verwijderenActies: async (
    params: IVerwijderenActiesParams,
  ): Promise<IVerwijderenActiesResult> => {
    return await api.post('/v2/aanbod/tarieven/acties/verwijderen-acties', params);
  },
  wijzigenActiePeriodenVanActie: async (
    params: IWijzigenActiePeriodenVanActieParams,
  ): Promise<IWijzigenActiePeriodenVanActieResult> => {
    return await api.post('v2/aanbod/tarieven/acties/wijzigen-actie-perioden-van-actie', params);
  },
  checkWijzigenActiePeriodenVanActie: async (
    params: ICheckWijzigenActiePeriodenVanActieParams,
  ): Promise<ICheckWijzigenActiePeriodenVanActieResult> => {
    return await api.post(
      'v2/aanbod/tarieven/acties/check-wijzigen-actie-perioden-van-actie',
      params,
    );
  },
  wijzigenActiecodesVanActie: async (
    params: IWijzigenActiecodesVanActieParams,
  ): Promise<IWijzigenActiecodesVanActieResult> => {
    return await api.post('v2/aanbod/tarieven/acties/wijzigen-actiecodes-van-actie', params);
  },
  checkWijzigenActiecodesVanActie: async (
    params: IWijzigenActiecodesVanActieParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/aanbod/tarieven/acties/check-wijzigen-actiecodes-van-actie', params);
  },
  checkWijzigenActie: async (params: IWijzigenActieParams): Promise<ICheckData> => {
    return await api.post('v2/aanbod/tarieven/acties/check-wijzigen-actie', params);
  },
  wijzigenActie: async (params: IWijzigenActieParams): Promise<IWijzigenActieResult> => {
    return await api.post('v2/aanbod/tarieven/acties/wijzigen-actie', params);
  },
  checkWijzigenActietarievenAbonnement: async (
    params: IWijzigenActietarievenAbonnementParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/aanbod/tarieven/acties/check-wijzigen-actietarieven-abonnement',
      params,
    );
  },
  wijzigenActietarievenAbonnement: async (
    params: IWijzigenActietarievenAbonnementParams,
  ): Promise<IWijzigenActietarievenAbonnementResult> => {
    return await api.post('v2/aanbod/tarieven/acties/wijzigen-actietarieven-abonnement', params);
  },
  ophalenTariefActieAbonnementen: async (
    params: IOphalenTariefActieAbonnementenParams,
  ): Promise<IOphalenTariefActieAbonnementenResult> => {
    return await api.post('v2/aanbod/tarieven/acties/ophalen-tarief-actie-abonnementen', params);
  },
};

export default acties;
