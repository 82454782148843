import api from '../../index';
import {
  ICheckToevoegenWebtekstParams,
  ICheckWijzigenWebtekstParams,
  IOphalenWebtekstenParams,
  IOphalenWebtekstenResult,
  IToevoegenWebtekstParams,
  IToevoegenWebtekstResult,
  IVerwijderenWebtekstenParams,
  IWijzigenWebtekstParams,
} from '../../../../../shared/src/api/v2/webtekst';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const webtekst = {
  // ophalen
  ophalenWebteksten: async (
    params: IOphalenWebtekstenParams,
  ): Promise<IOphalenWebtekstenResult> => {
    return await api.post('/v2/webtekst/ophalen-webteksten', params);
  },

  // toevoegen
  checkToevoegenWebtekst: async (params: ICheckToevoegenWebtekstParams) => {
    return await api.post<ICheckData>('v2/webtekst/check-toevoegen-webtekst', params);
  },
  toevoegenWebtekst: async (params: IToevoegenWebtekstParams) => {
    return await api.post<IToevoegenWebtekstResult>('v2/webtekst/toevoegen-webtekst', params);
  },

  // wijzigen
  checkWijzigenWebtekst: async (params: ICheckWijzigenWebtekstParams) => {
    return await api.post<ICheckData>('v2/webtekst/check-wijzigen-webtekst', params);
  },
  wijzigenWebtekst: async (params: IWijzigenWebtekstParams) => {
    return await api.post<any>('v2/webtekst/wijzigen-webtekst', params);
  },

  // verwijderen
  checkVerwijderenWebteksten: async (params: ICheckWijzigenWebtekstParams) => {
    return await api.post<ICheckData>('v2/webtekst/check-verwijderen-webteksten', params);
  },
  verwijderenWebteksten: async (params: IVerwijderenWebtekstenParams) => {
    return await api.post<any>('v2/webtekst/verwijderen-webteksten', params);
  },
};

export default webtekst;
