import React, { useContext, useMemo } from 'react';

import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import { InboekenContext, IRow } from '../index';
import api from '../../../../../api';
import useUpload from '../../../../../core/useUpload';
import BijlagenContainer, {
  BestandType,
  IExternBestand,
} from '../../../../../components/BijlagenContainer';
import BijlageKnop, { EOrientatie } from '../../../../../components/BijlageKnop';

const RowDetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IRow = props.row;
  const inboekenContext = useContext(InboekenContext);

  const {
    moetNogUploaden,
    isBezigMetUploaden,
    uploadProgresses,
    bestanden,
    muteerBestanden,
  } = useUpload(
    useMemo(
      () => ({
        initieleBestanden: row.bestanden.map(
          (bestand): IExternBestand => ({
            type: BestandType.ASPDrive,
            bestand,
          }),
        ),
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (ids: number[]) => {
          await api.v2.inkoopfactuur.wijzigenBestanden({
            inkFactID: row.InkFactID,
            bestandIDs: ids,
          });
          await inboekenContext.onRequestRefresh();
        },
      }),
      [row.bestanden, inboekenContext.onRequestRefresh],
    ),
  );

  return (
    <div className="p-2">
      <div className="row">
        <div className="col-2 d-flex">
          <div style={{ height: '100%' }} className="mr-2">
            <BijlageKnop
              disabled={isBezigMetUploaden}
              onBijgevoegd={(bijlagen) =>
                muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
              }
              orientatie={EOrientatie.Verticaal}
            />
          </div>
          <div>
            <BijlagenContainer
              bestanden={bestanden}
              uploadProgresses={uploadProgresses}
              onBestandenChange={(bijlagen) => muteerBestanden((_) => bijlagen)}
              bestandenMuterenToegestaan={!isBezigMetUploaden}
            />
          </div>
        </div>
        {/* <div className="col-10">
          <BoekingTabel
            boeking={row.boeking}
            crediteurRelID={row.RelID}
            inkFactID={row.InkFactID}
            regelsSelectie={[]}
            // onRegelsSelectieChange={(value: number[]) => setUrlStateSync('regelsSelectie', value)}
            onRequestRefresh={() => inboekenContext.onRequestRefresh()}
          />
        </div> */}
      </div>
    </div>
  );
};

export default RowDetailComp;
