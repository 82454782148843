import React from 'react';
import { Placement } from 'react-bootstrap/Overlay';

type ComponentOrElement = React.ReactInstance | Node;

export const parsePopupProps = <T extends IPopupProps<any>>(props: T): T => {
  return {
    ...props,
    blurIsImplicietAnnuleren:
      props.blurIsImplicietAnnuleren === undefined ? true : props.blurIsImplicietAnnuleren,
  };
};

export default interface IPopupProps<TData = any> {
  open: boolean;
  target: ComponentOrElement | ((props: object) => ComponentOrElement);
  onSuccess: (data: TData) => void;
  onAnnuleren: () => void;
  blurIsImplicietAnnuleren?: boolean;
  placement?: Placement;
}
