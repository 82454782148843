import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import {
  IOphalenMedewerkersResultElement,
  IOphalenMedewerkerVoorwaardenResultElement,
} from '../../../../../shared/src/api/v2/medewerker';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import MenuLayout from '../../../components/MenuLayout';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useUrlState from '../../../core/useUrlState';
import nameOf from '../../../core/nameOf';
import { format } from 'date-fns';
import Avatar from '../../../components/personalia/Avatar';
import RowDetailComp from './RowDetailComp';
import { AutoSizer } from 'react-virtualized';
import { IOphalenVerlofMutatiesResultElement } from '../../../../../shared/src/api/v2/medewerker/verlof';
import _ from 'lodash';
import { urenNaarDagen } from '../../../bedrijfslogica/teksten';
import { EResultType } from '../../../stores/CheckStore';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';
import WijzigenDialoog from './WijzigenDialoog';
import VerlofoverzichtDialoog from './VerlofoverzichtDialoog';

interface IProps extends RouteComponentProps {}

interface IWijzigenDialoogState {
  mdwID: number;
}
interface IOverzichtVersturenDialoogState {
  mdwIDs: number[];
}

interface IUrlState {
  selectie: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
  overzichtVersturenDialoogState: IOverzichtVersturenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenDialoogState: null,
  overzichtVersturenDialoogState: null,
};

export interface IRegel extends IOphalenMedewerkersResultElement {
  voorwaarden: IOphalenMedewerkerVoorwaardenResultElement[];
  verlofmutaties: IOphalenVerlofMutatiesResultElement[];
}

export interface IContext {
  onVerversenAangevraagd: () => void;
}

export const MedewerkerContext = React.createContext<IContext>(null as any);

const Medewerkers: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { gebruikerStore, profielStore, checkStore } = useContext(RootStoreContext);

  const [medewerkers, setMedewerkers] = useState<IRegel[] | null>(null);

  const ophalenMedewerkers = useCallback(async () => {
    const medewerkersResult = (
      await api.v2.medewerker.ophalenMedewerkers({
        filterSchema: {
          filters: [
            { naam: 'IN_DIENST', data: true },
            { naam: 'IS_ACTIEF', data: true },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'ACHTERNAAM',
              richting: 'ASC',
            },
          ],
        },
      })
    ).medewerkers;

    const mdwIDs = medewerkersResult.map((x) => x.MdwID);

    const voorwaardenResult = await api.v2.medewerker.ophalenMedewerkerVoorwaarden({
      filterSchema: { filters: [{ naam: 'MDW_IDS', data: mdwIDs }] },
    });

    const verlofmutatiesResult = await api.v2.medewerker.verlof.ophalenVerlofMutaties({
      filterSchema: { filters: [{ naam: 'MDW_IDS', data: mdwIDs }] },
    });

    const medewerkers = medewerkersResult.map((medewerker) => {
      const voorwaarden = voorwaardenResult.voorwaarden.filter((x) => x.MdwID === medewerker.MdwID);
      const verlofmutaties = verlofmutatiesResult.mutaties.filter(
        (x) => x.MdwID === medewerker.MdwID,
      );
      return { ...medewerker, voorwaarden, verlofmutaties };
    });

    setMedewerkers(medewerkers);
  }, []);

  useEffect(() => {
    ophalenMedewerkers();
  }, [ophalenMedewerkers]);

  const context = useMemo<IContext>(() => {
    return {
      onVerversenAangevraagd: async () => await ophalenMedewerkers(),
    };
  }, [ophalenMedewerkers]);

  const keyExtractor = useCallback((row: IRegel) => row.MdwID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Nummer',
        title: 'Mdw.nr.',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
      {
        name: '__naam' as any,
        title: 'Naam',
      },
      // {
      //   name: '__voornaam' as any,
      //   title: 'Voornaam',
      // },
      // {
      //   name: '__achternaam' as any,
      //   title: 'Achternaam',
      //   getCellValue: (x) => {
      //     return x.persoon!.Achternaam; //x.persoon !== null && x.persoon.Achternaam !== null ? x.persoon.Achternaam : '';
      //   },
      // },
      {
        name: '__emailadres' as any,
        title: 'Email',
      },
      {
        name: '__emailadresPrive' as any,
        title: 'Email prive',
      },
      {
        name: '__profielfoto' as any,
        title: 'Afb.',
      },
      {
        name: 'Extern',
        title: 'Int/Ext',
      },
      // {
      //   name: 'IBAN',
      //   title: 'IBAN',
      // },
      {
        name: 'DatumInDienst',
        title: 'In dienst',
      },
      {
        name: '__urenPerWeek' as any,
        title: 'Uren/week',
      },
      {
        name: '__saldoVerlofUren' as any,
        title: (
          <UitlegTooltip inhoud={'Actueel saldo verlofdagen in uren'}>
            <span>Saldo verlof (uren)</span>
          </UitlegTooltip>
        ) as any,
      },

      {
        name: '__saldoVerlofDagen' as any,
        title: (
          <UitlegTooltip inhoud={'Opgemaakt in dagen van 8 uur'}>
            <span>Saldo verlof (dgn/uren)</span>
          </UitlegTooltip>
        ) as any,
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Nummer',
        width: 90,
      },
      {
        columnName: 'Actief',
        width: 85,
      },
      {
        columnName: '__naam' as any,
        width: 175,
      },
      {
        columnName: '__voornaam' as any,
        width: 100,
      },
      {
        columnName: '__achternaam' as any,
        width: 125,
      },
      {
        columnName: '__profielfoto' as any,
        width: 65,
      },
      {
        columnName: 'Extern',
        width: 85,
      },
      {
        columnName: 'IBAN',
        width: 210,
      },
      {
        columnName: 'DatumInDienst',
        width: 110,
      },
      {
        columnName: '__urenPerWeek' as any,
        width: 110,
      },
      {
        columnName: '__saldoVerlofUren' as any,
        width: 170,
      },
      {
        columnName: '__saldoVerlofDagen' as any,
        width: 180,
      },
      {
        columnName: '__emailadresPrive' as any,
        width: 200,
      },
      {
        columnName: '__emailadres' as any,
        width: 175,
      },
      // {
      //   columnName: 'DatumUitDienst',
      //   width: 110,
      // },
    ],
    [],
  );

  return (
    <>
      {medewerkers === null ? (
        <div className="justify-content-center align-items-center d-flex flex-fill">
          <LoadingSpinner />
        </div>
      ) : (
        <MedewerkerContext.Provider value={context}>
          <MenuLayout
            menu={
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={false}
                  onClick={() => null}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Nieuw</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={() => null}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Verwijderen</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    setUrlStateSync('overzichtVersturenDialoogState', {
                      mdwIDs: urlState.selectie,
                    });
                  }}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Verlofoverzicht versturen</span>
                </button>
              </div>
            }
            body={
              <AutoSizer>
                {({ height, width }) => (
                  <GridStyleWrapper height={height ?? '100%'} style={{ width }}>
                    <Grid rows={medewerkers} columns={kolommen} getRowId={keyExtractor}>
                      <DataTypeProvider
                        for={['__profielfoto']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          if (rij.persoon === null || rij.persoon.profielAfbeelding === null) {
                            return <span></span>;
                          }
                          const downloadLink = rij.persoon.profielAfbeelding.url;
                          return (
                            <div style={{ marginRight: 5 }}>
                              <Avatar
                                imageSrc={
                                  rij.persoon !== null && rij.persoon.profielAfbeelding !== null
                                    ? downloadLink
                                    : undefined
                                }
                              >
                                {rij.persoon !== null &&
                                  rij.persoon.profielAfbeelding === null &&
                                  rij.persoon.Voorletters != null &&
                                  (
                                    rij.persoon!.Voorletters!.substr(0, 1) +
                                    rij.persoon!.Achternaam.substr(0, 1)
                                  ).toUpperCase()}
                              </Avatar>
                            </div>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IRegel>('Extern')]}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          return <span>{rij.Extern ? 'Extern' : 'Intern'}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IRegel>('Actief')]}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          return <span>{rij.Actief ? 'Ja' : 'Nee'}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__naam']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          const persoon = rij.persoon!;
                          const naam =
                            persoon.Voornaam +
                            (persoon.Voorvoegsel !== null ? ' ' + persoon.Voorvoegsel : '') +
                            ' ' +
                            persoon.Achternaam;
                          return <span>{naam}</span>;
                        }}
                      />

                      {/* <DataTypeProvider
                        for={['__voornaam']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          const persoon = rij.persoon !== null ? rij.persoon : null;
                          return <span>{persoon !== null ? persoon.Voornaam : ''}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__achternaam']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          const persoon = rij.persoon !== null ? rij.persoon : null;
                          return <span>{persoon !== null ? persoon.Achternaam : ''}</span>;
                        }}
                      /> */}

                      <DataTypeProvider
                        for={['__emailadresPrive']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          const email = rij.EmailPriveGebruiken
                            ? rij.EmailPrive
                            : rij.persoon!.Email;

                          // return (
                          //   <span>
                          //     {rij.EmailPriveGebruiken
                          //       ? rij.EmailPrive ?? '?'
                          //       : '(' + (rij.EmailPrive ?? '?') + ')'}
                          //   </span>
                          // );

                          return <span>{rij.EmailPrive}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__emailadres']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;

                          return <span>{rij.persoon!.Email}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__urenPerWeek']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;

                          if (rij.Extern) {
                            return <span>-</span>;
                          }

                          const jaar = new Date().getFullYear();
                          const peildatum = new Date().toISOString();

                          const voorwaardenResult = _.orderBy(
                            rij.voorwaarden.filter((x) => x.Mutatiedatum <= peildatum),
                            ['Mutatiedatum'],
                            ['asc'],
                          );

                          const voorwaarden =
                            voorwaardenResult.length !== 0
                              ? voorwaardenResult[voorwaardenResult.length - 1]
                              : null;

                          const urenPerWeek = voorwaarden !== null ? voorwaarden.StandaardUren : 0;

                          return <span>{urenPerWeek}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__saldoVerlofUren']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;

                          if (rij.Extern) {
                            return <span>-</span>;
                          }

                          const jaar = new Date().getFullYear();
                          const saldo: number = _.sum(
                            rij.verlofmutaties
                              .filter((x) => !x.soort.ProForma)
                              .filter((x) => new Date(x.DatumVem).getFullYear() === jaar)
                              .map((x) => x.AantalUren),
                          );

                          return <span>{saldo}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__saldoVerlofDagen']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;

                          if (rij.Extern) {
                            return <span>-</span>;
                          }

                          const jaar = new Date().getFullYear();
                          const saldo: number = _.sum(
                            rij.verlofmutaties
                              .filter((x) => !x.soort.ProForma)
                              .filter((x) => new Date(x.DatumVem).getFullYear() === jaar)
                              .map((x) => x.AantalUren),
                          );

                          return <span>{urenNaarDagen(saldo)}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IRegel>('DatumInDienst')]}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;

                          if (rij.Extern) {
                            return <span>-</span>;
                          }

                          return (
                            <span>
                              {formatterProps.value !== null
                                ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                                : 'Onbekend'}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IRegel>('DatumUitDienst')]}
                        formatterComponent={(formatterProps) => {
                          return (
                            <span>
                              {formatterProps.value === null
                                ? ''
                                : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                            </span>
                          );
                        }}
                      />

                      <EditingState
                        onCommitChanges={async (changes) => {
                          if (changes.deleted === undefined) {
                            return;
                          }
                          const deleted = changes.deleted;
                          const id = deleted[deleted.length - 1] as number;
                        }}
                        onEditingRowIdsChange={(rowIds) => {
                          const id = rowIds[rowIds.length - 1] as number;
                          setUrlStateSync('wijzigenDialoogState', { mdwID: id });
                        }}
                      />

                      <VirtualTable
                        messages={{
                          noData: 'Geen medewerkers',
                        }}
                      />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableEditColumn
                        width={35}
                        showEditCommand
                        // showDeleteCommand
                        cellComponent={DXTableEditColumnCellComponent}
                        commandComponent={DXTableEditColumnCommandComponent}
                      />
                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />
                      <TableHeaderRow showSortingControls />

                      <RowDetailState defaultExpandedRowIds={[]} />
                      <TableRowDetail
                        toggleCellComponent={DXTableToggleCellComponent}
                        contentComponent={RowDetailComp}
                      />
                      <SelectionState
                        selection={urlState.selectie}
                        onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                      />
                      <TableSelection cellComponent={DXTableCheckboxComponent} />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </AutoSizer>
            }
          />
        </MedewerkerContext.Provider>
      )}

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          mdwID={urlState.wijzigenDialoogState.mdwID}
          onSuccess={() => {
            ophalenMedewerkers();
            setUrlStateSync('wijzigenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}

      {urlState.overzichtVersturenDialoogState !== null && (
        <VerlofoverzichtDialoog
          open
          mdwIDs={urlState.overzichtVersturenDialoogState.mdwIDs}
          onSuccess={() => {
            setUrlStateSync('overzichtVersturenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('overzichtVersturenDialoogState', null)}
        />
      )}
    </>
  );
});

export default Medewerkers;
