import React from 'react';
import { IconMap } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import TekstOverflowCenterEllipsis from '../../../tekst/TekstOverflowCenterEllipsis';
import { IOphalenBestandsmappenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';

interface IProps {
  map: IOphalenBestandsmappenResultElement;
  width: number;
  height: number;
}

const MapWeergave = (props: IProps) => {
  return (
    <>
      <IconMap
        style={{
          fill: props.map.IsSysteemmap ? Kleur.Paars : Kleur.ZwembadBlauw,
          width: props.width * (2 / 3),
          height: props.height * (2 / 3),
        }}
      />
      <TekstOverflowCenterEllipsis
        tekst={props.map.Naam}
        width={props.width * (3 / 4)}
        maxLines={1}
        outerCss={{ marginTop: 3 }}
      />
    </>
  );
};

export default MapWeergave;
