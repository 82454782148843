import {
  IOphalenBestandslabelsParams,
  IOphalenBestandslabelsResult,
  IToevoegenBestandslabelParams,
  IVerwijderenBestandslabelsParams,
  IVerwijderenBestandslabelsResult,
  IToevoegenBestandslabelResult,
  IWijzigenBestandslabelParams,
  IWijzigenBestandslabelResult,
  IOphalenBestandXBestandslabelParams,
  IOphalenBestandXBestandslabelResult,
  IVerwijderenBestandXBestandslabelsParams,
  IVerwijderenBestandXBestandslabelsResult,
  IToevoegenBestandXBestandslabelParams,
  IToevoegenBestandXBestandslabelResult,
  IMuterenBestandXBestandslabelParams,
  IMuterenBestandXBestandslabelResult,
} from '../../../../../shared/src/api/v2/bestand/label';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const label = {
  async ophalenBestandslabels(
    params: IOphalenBestandslabelsParams,
  ): Promise<IOphalenBestandslabelsResult> {
    return await api.post('/v2/bestand-v2/label/ophalen-bestandslabels', params);
  },
  async verwijderenBestandslabels(
    params: IVerwijderenBestandslabelsParams,
  ): Promise<IVerwijderenBestandslabelsResult> {
    return await api.post('/v2/bestand-v2/label/verwijderen-bestandslabels', params);
  },
  async checkVerwijderenBestandslabels(
    params: IVerwijderenBestandslabelsParams,
  ): Promise<ICheckData> {
    return await api.post('/v2/bestand-v2/label/check-verwijderen-bestandslabels', params);
  },
  async toevoegenBestandslabel(
    params: IToevoegenBestandslabelParams,
  ): Promise<IToevoegenBestandslabelResult> {
    return await api.post('/v2/bestand-v2/label/toevoegen-bestandslabel', params);
  },
  async wijzigenBestandslabel(
    params: IWijzigenBestandslabelParams,
  ): Promise<IWijzigenBestandslabelResult> {
    return await api.post('/v2/bestand-v2/label/wijzigen-bestandslabel', params);
  },
  async ophalenBestandXBestandslabels(
    params: IOphalenBestandXBestandslabelParams,
  ): Promise<IOphalenBestandXBestandslabelResult> {
    return await api.post('/v2/bestand-v2/label/ophalen-bestand-x-bestandslabels', params);
  },
  async toevoegenBestandXBestandslabel(
    params: IToevoegenBestandXBestandslabelParams,
  ): Promise<IToevoegenBestandXBestandslabelResult> {
    return await api.post('/v2/bestand-v2/label/toevoegen-bestand-x-bestandslabel', params);
  },
  async verwijderenBestandXBestandslabels(
    params: IVerwijderenBestandXBestandslabelsParams,
  ): Promise<IVerwijderenBestandXBestandslabelsResult> {
    return await api.post('/v2/bestand-v2/label/verwijderen-bestand-x-bestandslabels', params);
  },
  async muterenBestandXBestandslabel(
    params: IMuterenBestandXBestandslabelParams,
  ): Promise<IMuterenBestandXBestandslabelResult> {
    return await api.post('/v2/bestand-v2/label/muteren-bestand-x-bestandslabel', params);
  },
};

export default label;
