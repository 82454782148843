import React from 'react';
import { Root } from './style';

interface IProps {
  attributenLinks: JSX.Element | null;
  attributenRechts: JSX.Element | null;
  invoerVeld: JSX.Element | null;
}

const InvoerVlak: React.FC<IProps> = (props) => {
  return (
    <Root>
      <div className="attributen-links">{props.attributenLinks}</div>
      <div className="invoer-veld">{props.invoerVeld}</div>
      <div className="attributen-rechts">{props.attributenRechts}</div>
    </Root>
  );
};

export default InvoerVlak;
