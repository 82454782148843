import React, { useCallback, useMemo } from 'react';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ITaalTekst from '../../../../../../../../../shared/src/models/talen/ITaalTekst';
import { Field, FieldProps, Formik } from 'formik';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../../../../core/nameOf';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';
import MeertaligTekstveld from '../../../../../../../components/formulier/MeertaligTekstveld';

interface IDialoogResult {
  opOverzicht: boolean;
  kenmerken: ITaalTekst[];
}

export interface IFormikValues {
  opOverzicht: boolean;
  kenmerken: ITaalTekst[];
}
const veldnamen: Record<keyof IFormikValues, string> = {
  opOverzicht: 'Op overzicht',
  kenmerken: 'Kenmerken',
};

interface IProps extends IDialoogProps<IDialoogResult> {
  initialValues?: IFormikValues;
}

const MuterenDialoog: React.FC<IProps> = (props) => {
  const defaultFormikValues = useMemo<IFormikValues>(
    () => ({
      opOverzicht: true,
      kenmerken: [],
    }),
    [],
  );
  const initialValues = useMemo<IFormikValues>(() => props.initialValues || defaultFormikValues, [
    props.initialValues,
    defaultFormikValues,
  ]);
  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      props.onSuccess({
        opOverzicht: values.opOverzicht,
        kenmerken: values.kenmerken,
      });
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Kenmerk</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={(formikProps) => {
          const { submitForm, isValid, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-12">
                    <Field
                      name={nameof<IFormikValues>('opOverzicht')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div className="d-flex align-items-center">
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                            <span className="ml-2">{veldnamen.opOverzicht}</span>
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>{veldnamen.kenmerken}</label>
                    <Field
                      name={nameof<IFormikValues>('kenmerken')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div className="d-flex flex-column">
                            <MeertaligTekstveld
                              waarden={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                              defaultUitgeklapt
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default MuterenDialoog;
