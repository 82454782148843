import services, { Filters, Sorteringen } from '../../index';
import { IPaginatiePositie } from '../../../index';

export type WhatsappBerichtKolommen =
  | 'id'
  | 'whatsappChatsessieId'
  | 'whatsappDienstId'
  | 'datum'
  | 'richting'
  | 'inhoud'
  | 'referentie'
  | 'bronReferentie'
  | 'custom'
  | 'whatsappMediaId'
  | 'relId'
  | 'recordToegevoegd'
  | 'recordGewijzigd'
  | 'verwijderdOp'
  | 'reactieOpId'
  | 'whatsappBerichttypeId';

export interface IOphalenBerichtenParams {
  filters?: Filters<WhatsappBerichtKolommen>;
  sorteringen?: Sorteringen<WhatsappBerichtKolommen>;
  paginatie?: IPaginatiePositie;
}

export interface IWhatsappBericht {
  id: number;
  whatsappChatsessieId: number;
  whatsappDienstId: number;
  datum: Date | string;
  richting: 'I' | 'U';
  inhoud: string | null;
  referentie: string;
  bronReferentie: string | null;
  custom: string | null;
  whatsappMediaId: number | null;
  relId: number | null;
  recordToegevoegd: Date | string | null;
  recordGewijzigd: Date | string | null;
  verwijderdOp: Date | string | null;
  reactieOpId: number | null;
  verstuurdDoorAspGebrId: number | null;
  whatsappBerichttypeId: number | null;
}

export interface IOphalenBerichtenOutput {
  totaalAantal: number;
  berichten: IWhatsappBericht[];
}

export type WhatsappChatsessieKolommen =
  | 'id'
  | 'telefoonnummer'
  | 'telefoonnummerBedrijf'
  | 'avatarBestandId'
  | 'status'
  | 'isAutomatischGekoppeld'
  | 'persId'
  | 'naam'
  | 'recordToegevoegd'
  | 'recordGewijzigd';

export interface IOphalenChatsessiesParams {
  filters?: Filters<WhatsappChatsessieKolommen>;
  sorteringen?: Sorteringen<WhatsappChatsessieKolommen>;
  paginatie?: IPaginatiePositie;
}

export interface IWhatsappChatsessie {
  id: number;
  telefoonnummer: string;
  telefoonnummerBedrijf: string;
  avatarBestandId: number | null;
  status: 'nieuw' | 'lopend' | 'afgehandeld';
  isAutomatischGekoppeld: boolean;
  persId: number | null;
  naam: string | null;
  recordToegevoegd: Date | string | null;
  recordGewijzigd: Date | string | null;
  opgepaktAspGebrId: number | null;
  ontvangstbevestigingSturen: boolean;
}

export interface IOphalenChatsessiesOutput {
  totaalAantal: number;
  chatsessies: IWhatsappChatsessie[];
}

export type WhatsappMediaKolommen =
  | 'id'
  | 'titel'
  | 'bestandId'
  | 'recordToegevoegd'
  | 'recordGewijzigd';

export interface IOphalenMediasParams {
  filters?: Filters<WhatsappMediaKolommen>;
  sorteringen?: Sorteringen<WhatsappMediaKolommen>;
  paginatie?: IPaginatiePositie;
}

export interface IWhatsappMedia {
  id: number;
  titel: string | null;
  bestandId: number;
  recordToegevoegd: Date | string | null;
  recordGewijzigd: Date | string | null;
}

export interface IOphalenMediasOutput {
  totaalAantal: number;
  medias: IWhatsappMedia[];
}

export interface IOphalenLaatsteBerichtenVoorChatsessiesParams {
  whatsappChatsessieIds: number[];
  filters?: Filters<WhatsappBerichtKolommen>;
}

export interface IOphalenLaatsteBerichtenVoorChatsessiesOutput {
  berichtenPerChatsessieId: Record<number, IWhatsappBericht>;
}

export type OphalenChatsessieUitgebreidKolommen =
  | 'id'
  | 'telefoonnummer'
  | 'telefoonnummerBedrijf'
  | 'avatarBestandId'
  | 'status'
  | 'isAutomatischGekoppeld'
  | 'persId'
  | 'naam'
  | 'recordToegevoegd'
  | 'recordGewijzigd'
  | 'meestRecenteBerichtDatum';

export interface IOphalenChatsessiesUitgebreidParams {
  filters?: Filters<OphalenChatsessieUitgebreidKolommen>;
  sorteringen?: Sorteringen<OphalenChatsessieUitgebreidKolommen>;
  paginatie?: IPaginatiePositie;
}

export interface IOphalenChatsessiesUitgebreidOutput {
  totaalAantal: number;
  chatsessies: IWhatsappChatsessie[];
}

interface IWijzigenChatsessieStatusParams {
  chatsessieId: number;
  status: 'nieuw' | 'lopend' | 'afgehandeld';
  metOpgepaktAspGebrId?: number | null;
}

// tslint:disable-next-line:no-empty-interface
interface IWijzigenChatsessieStatusOutput {}

interface IBepalenPositiesVanChatsessies {
  chatsessieIds: number[];
  filters?: Filters<OphalenChatsessieUitgebreidKolommen>;
  sorteringen?: Sorteringen<OphalenChatsessieUitgebreidKolommen>;
}

// tslint:disable-next-line:no-empty-interface
interface IBepalenPositiesVanChatsessiesOutput {
  positieBijChatsessieId: Record<number, number>;
}

interface ITekstBericht {
  type: 'tekst';
  tekst: string;
}

interface IMediaBericht {
  type: 'media';
  bestandId: number;
  naam?: string;
}

interface IVerstuurBerichtParams {
  chatsessieId: number;
  bericht: ITekstBericht | IMediaBericht;
}

interface IVerstuurBerichtOutput {
  id: number;
}

interface IWijzigenChatsessiePersoonParams {
  chatsessieId: number;
  persoonId: number | null;
}

// tslint:disable-next-line:no-empty-interface
interface IWijzigenChatsessiePersoonOutput {}

interface IWijzigenChatsessieOpgepaktParams {
  chatsessieId: number;
  opgepaktAspGebrId: number | null;
}

// tslint:disable-next-line:no-empty-interface
interface IWijzigenChatsessieOpgepaktOutput {}

interface IWijzigenChatsessieInstellingenParams {
  chatsessieId: number;
  ontvangstbevestigingSturen: boolean;
}

// tslint:disable-next-line:no-empty-interface
interface IWijzigenChatsessieInstellingenOutput {}

export type WhatsappTijdvensterAanvraagKolommen =
  | 'id'
  | 'chatsessieId'
  | 'datum'
  | 'aangevraagdDoorAspGebrId'
  | 'recordToegevoegd'
  | 'recordGewijzigd';

interface IOphalenTijdvensterAanvragenParams {
  filters?: Filters<WhatsappTijdvensterAanvraagKolommen>;
  sorteringen?: Sorteringen<WhatsappTijdvensterAanvraagKolommen>;
  paginatie?: IPaginatiePositie;
}

export interface IWhatsappTijdvensterAanvraag {
  id: number;
  chatsessieId: number;
  datum: Date | string;
  aangevraagdDoorAspGebrId: number;
  recordToegevoegd: Date | string | null;
  recordGewijzigd: Date | string | null;
}

interface IOphalenTijdvensterAanvragenOutput {
  totaalAantal: number;
  tijdvensterAanvragen: IWhatsappTijdvensterAanvraag[];
}

interface IConversatieVoorzettenAanvragenParams {
  chatsessieId: number;
}

// tslint:disable-next-line:no-empty-interface
interface IConversatieVoorzettenAanvragenOutput {
  berichtId: number;
  tijdvensterAanvraagId: number;
}

interface IZoekenChatsessiesParams {
  zoekterm: string;
  paginatie?: IPaginatiePositie;
}

interface IZoekenChatsessiesOutput {
  totaalAantal: number;
  chatsessies: IWhatsappChatsessie[];
}

interface IZoekenBerichtenParams {
  zoekterm: string;
  paginatie?: IPaginatiePositie;
}

interface IZoekenBerichtenOutput {
  totaalAantal: number;
  berichten: IWhatsappBericht[];
}

const whatsapp = {
  ophalenBerichten: async (
    params: IOphalenBerichtenParams,
    signal?: AbortSignal,
  ): Promise<IOphalenBerichtenOutput> => {
    return await services.call('/v1/whatsapp/ophalen-berichten', params, {
      signal,
    });
  },
  ophalenChatsessies: async (
    params: IOphalenChatsessiesParams,
    signal?: AbortSignal,
  ): Promise<IOphalenChatsessiesOutput> => {
    return await services.call('/v1/whatsapp/ophalen-chatsessies', params, {
      signal,
    });
  },
  ophalenMedias: async (
    params: IOphalenMediasParams,
    signal?: AbortSignal,
  ): Promise<IOphalenMediasOutput> => {
    return await services.call('/v1/whatsapp/ophalen-medias', params, {
      signal,
    });
  },
  ophalenTijdvensterAanvragen: async (
    params: IOphalenTijdvensterAanvragenParams,
    signal?: AbortSignal,
  ): Promise<IOphalenTijdvensterAanvragenOutput> => {
    return await services.call('/v1/whatsapp/ophalen-tijdvenster-aanvragen', params, {
      signal,
    });
  },
  ophalenLaatsteBerichtenVoorChatsessies: async (
    params: IOphalenLaatsteBerichtenVoorChatsessiesParams,
    signal?: AbortSignal,
  ): Promise<IOphalenLaatsteBerichtenVoorChatsessiesOutput> => {
    return await services.call('/v1/whatsapp/ophalen-laatste-berichten-voor-chatsessies', params, {
      signal,
    });
  },
  ophalenChatsessiesUitgebreid: async (
    params: IOphalenChatsessiesUitgebreidParams,
    signal?: AbortSignal,
  ): Promise<IOphalenChatsessiesUitgebreidOutput> => {
    return await services.call('/v1/whatsapp/ophalen-chatsessies-uitgebreid', params, {
      signal,
    });
  },
  wijzigenChatsessieStatus: async (
    params: IWijzigenChatsessieStatusParams,
    signal?: AbortSignal,
  ): Promise<IWijzigenChatsessieStatusOutput> => {
    return await services.call('/v1/whatsapp/wijzigen-chatsessie-status', params, {
      signal,
    });
  },
  wijzigenChatsessieInstellingen: async (
    params: IWijzigenChatsessieInstellingenParams,
    signal?: AbortSignal,
  ): Promise<IWijzigenChatsessieInstellingenOutput> => {
    return await services.call('/v1/whatsapp/wijzigen-chatsessie-instellingen', params, {
      signal,
    });
  },
  bepalenPositiesVanChatsessies: async (
    params: IBepalenPositiesVanChatsessies,
    signal?: AbortSignal,
  ): Promise<IBepalenPositiesVanChatsessiesOutput> => {
    return await services.call('/v1/whatsapp/bepalen-posities-van-chatsessies', params, {
      signal,
    });
  },
  verstuurBericht: async (params: IVerstuurBerichtParams): Promise<IVerstuurBerichtOutput> => {
    return await services.call('/v1/whatsapp/verstuur-bericht', params);
  },
  wijzigenChatsessiePersoon: async (
    params: IWijzigenChatsessiePersoonParams,
    signal?: AbortSignal,
  ): Promise<IWijzigenChatsessiePersoonOutput> => {
    return await services.call('/v1/whatsapp/wijzigen-chatsessie-persoon', params, {
      signal,
    });
  },
  wijzigenChatsessieOpgepakt: async (
    params: IWijzigenChatsessieOpgepaktParams,
    signal?: AbortSignal,
  ): Promise<IWijzigenChatsessieOpgepaktOutput> => {
    return await services.call('/v1/whatsapp/wijzigen-chatsessie-opgepakt', params, {
      signal,
    });
  },
  conversatieVoortzettenAanvragen: async (
    params: IConversatieVoorzettenAanvragenParams,
    signal?: AbortSignal,
  ): Promise<IConversatieVoorzettenAanvragenOutput> => {
    return await services.call('/v1/whatsapp/conversatie-voortzetten-aanvragen', params, {
      signal,
    });
  },
  zoekenChatsessies: async (
    params: IZoekenChatsessiesParams,
    signal?: AbortSignal,
  ): Promise<IZoekenChatsessiesOutput> => {
    return await services.call('/v1/whatsapp/zoeken-chatsessies', params, {
      signal,
    });
  },
  zoekenBerichten: async (
    params: IZoekenBerichtenParams,
    signal?: AbortSignal,
  ): Promise<IZoekenBerichtenOutput> => {
    return await services.call('/v1/whatsapp/zoeken-berichten', params, {
      signal,
    });
  },
};

export default whatsapp;
