import React, { useContext, useMemo, useState } from 'react';
import VeldWeergave from '../../formulier/VeldWeergave';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../layout/GegevensLayout';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IOphalenMeldingenResultElement } from '../../../../../shared/src/api/v2/service/melding';
import nameof from '../../../core/nameOf';
import { IconInformatie } from '../../Icons';
import ServicemeldingInfoDialoog from '../../service/MeldingInfoDialoog';
import _ from 'lodash';

interface IInfoDialoogState {
  servicemeldingID: number;
}

interface IProps {
  meldingen: IOphalenMeldingenResultElement[];
}

const MeldingenComp: React.FC<IProps> = (props) => {
  const [infoDialoogState, setInfoDialoogState] = useState<IInfoDialoogState | null>(null);

  const kolommenMelding = useMemo<TypedColumn<IOphalenMeldingenResultElement>[]>(
    () => [
      {
        name: 'Melddatum',
        title: 'Melddatum',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__locatie' as any,
        title: 'Locatie',
      },
      {
        name: 'Afgehandeld',
        title: 'Afg.',
      },
      {
        name: '__info' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolombreedtesMelding = useMemo<TypedTableColumnWidthInfo<IOphalenMeldingenResultElement>[]>(
    () => [
      {
        columnName: 'Melddatum',
        width: 90,
      },

      {
        columnName: 'Omschrijving',
        width: 300,
      },
      {
        columnName: 'Afgehandeld',
        width: 60,
      },
      {
        columnName: '__locatie' as any,
        width: 250,
      },
      {
        columnName: '__info' as any,
        width: 30,
      },
    ],
    [],
  );

  return (
    <>
      <VeldWeergave>
        <div className="p-1 font-weight-bold">Servicemeldingen</div>
        <div className="mt-1" style={{ backgroundColor: Kleur.Wit }}>
          <GridStyleWrapper maxHeight={210} rowAmount={props.meldingen.length}>
            <Grid rows={props.meldingen} columns={kolommenMelding}>
              <DataTypeProvider
                for={[nameof<IOphalenMeldingenResultElement>('Melddatum')]}
                formatterComponent={(formatterProps) => (
                  <span>
                    {formatterProps.value === null
                      ? null
                      : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                  </span>
                )}
              />

              <DataTypeProvider
                for={[nameof<IOphalenMeldingenResultElement>('Afgehandeld')]}
                formatterComponent={(formatterProps) => (
                  <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>
                )}
              />

              <DataTypeProvider
                for={[nameof<IOphalenMeldingenResultElement>('Omschrijving')]}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenMeldingenResultElement = formatterProps.row;
                  return <span>{rij.Omschrijving}</span>;
                }}
              />

              <DataTypeProvider
                for={['__info']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenMeldingenResultElement = formatterProps.row;
                  return (
                    <div>
                      <a
                        href="#"
                        className="ml-1"
                        style={{ color: Kleur.LichtBlauw }}
                        onClick={() => {
                          setInfoDialoogState({ servicemeldingID: row.ID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                      </a>
                    </div>
                  );
                }}
              />

              <DataTypeProvider
                for={['__locatie']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenMeldingenResultElement = formatterProps.row;
                  const adres =
                    (
                      rij.locatie.Straatnaam +
                      ' ' +
                      rij.locatie.Huisnummer +
                      ' ' +
                      (rij.locatie.Bisnummer !== null ? rij.locatie.Bisnummer : '')
                    ).trim() +
                    ', ' +
                    rij.locatie.Plaatsnaam;
                  return <span>{adres}</span>;
                }}
              />

              <VirtualTable
                // columnExtensions={kolombreedtesMelding}
                messages={{ noData: 'Geen servicemeldingen bekend' }}
              />
              <TableColumnResizing defaultColumnWidths={kolombreedtesMelding} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </div>
      </VeldWeergave>
      {infoDialoogState !== null && (
        <ServicemeldingInfoDialoog
          serviceMeldingID={infoDialoogState.servicemeldingID}
          open
          onSuccess={() => setInfoDialoogState(null)}
          onAnnuleren={() => setInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default MeldingenComp;
