import React, { useCallback, useMemo } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  IconAllesInklappen,
  IconAllesUitklappen,
  IconInklappen,
  IconUitklappen,
} from '../../Icons';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import InactiefOverlay from '../../InactiefOverlay';
import { Tooltip } from 'react-bootstrap';
import Badge from '../../Badge';

interface IStyledLinkProps {
  activeColor: string;
}

const StyledLink = styled(NavLink)<IStyledLinkProps>`
  li {
    //padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
      fill: ${Kleur.Wit};
    }
  }
  &:hover {
    text-decoration: none;
    color: white;

    div {
      background-color: rgba(1, 1, 1, 0.1);
    }
  }
  &.active {
    div {
      background-color: ${(props) => props.activeColor};
    }
  }
`;

const Knop = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 3px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(1, 1, 1, 0.1);
  }
`;

export interface IWeergaveProps {
  actief: boolean;
}

export enum ELink {
  Link,
  GroepLink,
}

export type Link<TGroepId extends string | number | symbol> = ILink | IGroepLink<TGroepId>;

export interface ILink {
  type: ELink.Link;
  path: string;
  navigatiePath?: string;
  weergave: React.ComponentType<IWeergaveProps>;
  heeftAutorisatie?: () => boolean;
  badgeAantal?: number;
  indicatieAantal?: number;
}

export interface IGroepLink<TGroepId extends string | number | symbol> {
  type: ELink.GroepLink;
  id: TGroepId;
  weergave: React.ComponentType<IWeergaveProps>;
  sublinks: Link<TGroepId>[];
  heeftAutorisatie?: () => boolean;
  badgeAantal?: number;
  indicatieAantal?: number;
}

const getActieveLinkPath = <TGroepId extends string | number | symbol>(
  links: Link<TGroepId>[],
  pathname: string,
): string | null => {
  return links.reduce<string | null>((path, link) => {
    if (path !== null) {
      return path;
    }

    if (link.type === ELink.Link) {
      if (pathname === link.path) {
        return link.path;
      }
      return null;
    }
    return getActieveLinkPath(link.sublinks, pathname);
  }, null);
};

interface IProps<TGroepId extends string | number | symbol> extends RouteComponentProps {
  links: Link<TGroepId>[];
  uitgeklapteGroepLinks: Partial<Record<TGroepId, boolean>>;
  onUitgeklapteGroepLinksChange:
    | ((uitgeklapteGroepLinks: Partial<Record<TGroepId, boolean>>) => void)
    | null;
  /**
   * Deze prop wordt gebruikt als het pad waar vanuit wordt genavigeerd niet de root van de app
   * is
   */
  pathPrefix?: string;
  actieveLinkBackgroundColor?: string;
  menuBalkIconKleur?: string;
}

const VerticaleNavigatieBalk = <TGroepId extends string | number | symbol>(
  props: IProps<TGroepId>,
) => {
  const actieveLinkPath = useMemo(() => {
    const pathname =
      props.pathPrefix === undefined
        ? props.location.pathname
        : props.location.pathname.replace(props.pathPrefix, '');
    return getActieveLinkPath(props.links, pathname);
  }, [props.links, props.location.pathname, props.pathPrefix]);

  const renderGeenAutorisatieTooltip = useCallback((p: any) => {
    return (
      <Tooltip id="geen-autorisatie-tooltip" {...p}>
        Je hebt geen autorisatie
      </Tooltip>
    );
  }, []);

  const renderLinks = useCallback<any>(
    (links: Link<TGroepId>[], iteratie: number = 0) => {
      return links.map((link) => {
        const Weergave = link.weergave;
        if (link.type === ELink.Link) {
          const heeftAutorisatie = link.heeftAutorisatie === undefined || link.heeftAutorisatie();

          return (
            <InactiefOverlay
              isInactief={!heeftAutorisatie}
              inactiefStyle={{
                backgroundColor: 'rgba(50, 50, 50, 0.2)',
              }}
              inactiefTooltip={renderGeenAutorisatieTooltip}
              element={
                <StyledLink
                  key={link.path}
                  // strict
                  to={`${props.pathPrefix || ''}${link.path!}`}
                  activeColor={props.actieveLinkBackgroundColor || Kleur.Actief}
                >
                  <div
                    style={{
                      margin: '3px 7px',
                      borderRadius: 5,
                      // backgroundColor: props.actieveLinkBackgroundColor,
                      padding: '7px 10px',
                    }}
                  >
                    <Weergave actief={false} />
                  </div>
                  {link.indicatieAantal !== undefined && link.indicatieAantal > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 18,
                        top: 6,
                        backgroundColor: 'transparent',
                      }}
                    >
                      <span style={{ color: Kleur.BeetjeLichtGrijs, fontSize: 12 }}>
                        {link.indicatieAantal}
                      </span>
                    </div>
                  )}
                  {link.badgeAantal !== undefined && link.badgeAantal > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 12,
                        top: 9,
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Badge aantal={link.badgeAantal!} maxAantal={999999} />
                    </div>
                  )}
                </StyledLink>
              }
            />
          );
        }

        const uitgeklapt = props.uitgeklapteGroepLinks[link.id];
        const heeftAutorisatie = link.heeftAutorisatie === undefined || link.heeftAutorisatie();

        const heeftBadge = link.badgeAantal !== undefined && link.badgeAantal > 0;

        return (
          <li style={{ listStyleType: 'none' }}>
            <InactiefOverlay
              isInactief={!heeftAutorisatie}
              inactiefStyle={{
                backgroundColor: 'rgba(50, 50, 50, 0.2)',
              }}
              inactiefTooltip={renderGeenAutorisatieTooltip}
              element={
                <div
                  className="d-flex align-items-center pl-3"
                  onClick={
                    props.onUitgeklapteGroepLinksChange === null
                      ? undefined
                      : () =>
                          props.onUitgeklapteGroepLinksChange!({
                            ...props.uitgeklapteGroepLinks,
                            [link.id]: !props.uitgeklapteGroepLinks[link.id],
                          })
                  }
                  style={{ cursor: 'pointer', height: 35, paddingRight: 10 }}
                >
                  <span style={{ color: Kleur.LichtGrijs }}>
                    <Weergave actief={false} />
                  </span>
                  <div className="flex-fill" />
                  {props.onUitgeklapteGroepLinksChange === null ? null : uitgeklapt ? (
                    <IconInklappen
                      style={{
                        fill: Kleur.LichtGrijs,
                      }}
                    />
                  ) : (
                    <IconUitklappen
                      style={{
                        fill: Kleur.LichtGrijs,
                      }}
                    />
                  )}
                  {heeftBadge && !uitgeklapt && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 35,
                        top: 9,
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Badge aantal={link.badgeAantal!} maxAantal={9999999} />
                    </div>
                  )}
                </div>
              }
            />
            {uitgeklapt && (
              <ul style={{ padding: 0, paddingLeft: 20, marginBottom: 0 }}>
                {renderLinks(link.sublinks, iteratie + 1)}
              </ul>
            )}
          </li>
        );
      });
    },
    [
      actieveLinkPath,
      props.pathPrefix,
      props.uitgeklapteGroepLinks,
      props.onUitgeklapteGroepLinksChange,
    ],
  );

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
      }}
    >
      {props.onUitgeklapteGroepLinksChange !== null && (
        <div className="d-flex align-items-center justify-content-end pl-2 pr-2">
          <Knop
            className="mr-1"
            title="Alles uitklappen"
            onClick={() => {
              const groepLinkIDsExtraheren = (link: Link<TGroepId>): TGroepId[] => {
                if (link.type === ELink.Link) {
                  return [];
                }
                return [link.id, ...link.sublinks.flatMap(groepLinkIDsExtraheren)];
              };
              const alleGroepLinkIDs = props.links.map(groepLinkIDsExtraheren).flat();
              const uitgeklapteGroepLinks = alleGroepLinkIDs.reduce(
                (acc, curr) => ({ ...acc, [curr]: true }),
                {},
              );
              props.onUitgeklapteGroepLinksChange!(uitgeklapteGroepLinks);
            }}
          >
            <IconAllesUitklappen height={21} width={21} fill={props.menuBalkIconKleur} />
          </Knop>

          <Knop title="Alles inklappen" onClick={() => props.onUitgeklapteGroepLinksChange!({})}>
            <IconAllesInklappen height={21} width={21} fill={props.menuBalkIconKleur} />
          </Knop>
        </div>
      )}
      <nav
        style={{
          overflowY: 'auto',
          padding: 0,
          listStyleType: 'none',
          flex: 1,
        }}
      >
        {renderLinks(props.links)}
      </nav>
    </div>
  );
};

export default withRouter(VerticaleNavigatieBalk);
