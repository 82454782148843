// Wegens problemen met CORS, deze tijdelijk disablen
// const downloadUrl = async (url: string, name: string | null) => {
//   const blob = await fetch(url, {
//     mode: 'no-cors',
//   }).then((r) => r.blob());
//   // const fileContents = await new Response(blob).text();
//
//   const link = document.createElement('a');
//   link.download = name || '';
//   link.href = URL.createObjectURL(blob);
//
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

const downloadUrl = async (url: string, name: string | null) => {
  // const blob = await fetch(url, {
  //   // mode: 'no-cors',
  // }).then((r) => r.blob());
  // const fileContents = await new Response(blob).text();

  const link = document.createElement('a');
  link.download = name || '';
  link.href = url;
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadUrl;
