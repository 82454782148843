import React, { useEffect, useMemo, useState } from 'react';
import api from '../../../api';
import {
  IOphalenTalenResult,
  IOphalenTalenResultElement,
} from '../../../../../shared/src/api/v2/taal';
import MultiCombobox from '../MultiCombobox';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import MultiSelect from '../MultiSelect';

interface IProps {
  taalIDs: number[];
  onChange: (taalIDs: number[]) => void;
  standaardTaalVoorselecteren?: boolean;
}

const TalenSelectie = (props: IProps) => {
  const standaardTaalVoorselecteren = props.standaardTaalVoorselecteren ?? false;

  const [talenResult, setTalenResult] = useState<IOphalenTalenResult | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.taal.ophalen({ filterSchema: { filters: [] } });
      setTalenResult(result);
    })();
  }, []);

  useEffect(() => {
    if (props.taalIDs.length !== 0 || !standaardTaalVoorselecteren) {
      return;
    }

    (async () => {
      const result = (
        await api.v2.taal.ophalen({
          filterSchema: { filters: [{ naam: 'NAAM_ENUMS', data: ['TAAL_N'] }] },
        })
      )[0];
      props.onChange([result.TaalID]);
    })();
  }, []);

  const opties = useMemo(() => {
    if (talenResult === null) {
      return null;
    }

    return talenResult.map((taal: IOphalenTalenResultElement) => ({
      key: taal.TaalID,
      weergave: taal.Naam,
    }));
  }, [talenResult]);

  if (opties === null) {
    return <LoadingSpinner />;
  }

  return <MultiSelect value={props.taalIDs} onChange={props.onChange} opties={opties} />;
};

export default TalenSelectie;
