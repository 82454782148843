import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuLayout from '../../../components/MenuLayout';
import {
  IOphalenAanmeldingStatussenResultElement,
  IOphalenAanmeldingenResultElement,
} from '../../../../../shared/src/api/v2/inkoop/retour';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IconInformatie, IconToevoegen } from '../../../components/Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../components/tabel/ASPTabel/types';
import ASPTabel from '../../../components/tabel/ASPTabel';
import { format } from 'date-fns';
import { IOphalenProductenResultElementV2 } from '../../../../../shared/src/api/v2/product';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/inkoop/opdracht';
import _ from 'lodash';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import ProductinfoDialoog from '../../../components/product/ProductinfoDialoog';
import VersturenDialoog from './VersturenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import { nummerRetouraanmelding } from '../../../bedrijfslogica/opdrachtnummers';

interface IProps extends RouteComponentProps {}

export interface IProductinfoDialoogState {
  id: number;
}
export interface IVersturenDialoogState {
  ids: number[];
}

export interface IWijzigenDialoogState {
  id: number;
}

export enum EFilter {
  // STATUS = 'STATUS',
  NIET_AFGEHANDELD = 'IS_NIET_AFGEHANDELD',
}

enum EKolom {
  Nummer,
  Relatie,
  Registratiedatum,
  Typenaam,
  Merknaam,
  Omruilen,
  AangemeldOp,
  Notities,
  Productsoort,
  Serienummer,
  Referentiecode,
  Productinfo,
  Afgehandeld,
  ExterneReferentie,
  Status,
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  wijzigDialoogState: IWijzigenDialoogState | null;
  sortering: IAspKolomSorteringItem<EKolom>[];
  infoDialoogState: IProductinfoDialoogState | null;
  versturenDialoogState: IVersturenDialoogState | null;
}
const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    // {
    //   naam: EFilter.STATUS,
    //   data: ['CONCEPT', 'UITHANDEN'],
    //   isActief: true,
    // },
    {
      naam: EFilter.NIET_AFGEHANDELD,
      isActief: true,
      data: true,
    },
  ],
  wijzigDialoogState: null,
  sortering: [
    {
      key: EKolom.Nummer,
      sortering: ESortering.Descending,
    },
  ],
  infoDialoogState: null,
  versturenDialoogState: null,
};

const Retouren: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [aanmeldingen, setAanmeldingen] = useState<IOphalenAanmeldingenResultElement[] | null>(
    null,
  );
  // const [statussen, setStatussen] = useState<IOphalenAanmeldingStatussenResultElement[] | null>(
  //   null,
  // );
  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);
  const [inkoopopdrachten, setInkoopopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);
  const [inkoopdiensten, setInkoopdiensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const filters: IFilter<EFilter>[] = useMemo(
    () => [
      {
        naam: EFilter.NIET_AFGEHANDELD,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen niet afgehandeld</span>;
        },
      },
    ],
    [],
  );

  const ophalenAanmeldingen = useCallback(async () => {
    const resultaat = await api.v2.inkoop.retour.ophalenAanmeldingen({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
    });
    setAanmeldingen(resultaat.aanmeldingen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenAanmeldingen();
  }, [ophalenAanmeldingen]);

  // const ophalenStatussen = useCallback(async () => {
  //   const resultaat = await api.v2.inkoop.retour.ophalenAanmeldingStatussen({
  //     // filterSchema: {
  //     //   filters: [],
  //     // },
  //   });
  //   setStatussen(resultaat.statussen);
  // }, []);

  // useEffect(() => {
  //   ophalenStatussen();
  // }, [ophalenStatussen]);

  // const ophalenProducten = useCallback(async () => {
  //   if (aanmeldingen === null) return;
  //   const resultaat = await api.v2.product.ophalenProductenV2({
  //     filterSchema: {
  //       filters: [{ naam: 'IDS', data: aanmeldingen.map((x) => x.ProdID) }],
  //     },
  //   });
  //   setProducten(resultaat.producten);
  // }, [aanmeldingen]);

  // useEffect(() => {
  //   ophalenProducten();
  // }, [ophalenProducten]);

  const keyExtractor = useCallback((row: IOphalenAanmeldingenResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenAanmeldingenResultElement>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nr.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
        renderer: (rij: IOphalenAanmeldingenResultElement) => (
          <span>{nummerRetouraanmelding(rij.Nummer)}</span>
        ),
      },
      {
        key: EKolom.Relatie,
        label: 'Leverancier',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij: IOphalenAanmeldingenResultElement) => {
          return rij.inkoopopdracht !== null ? (
            <RelatieVisualisatie relID={rij.inkoopopdracht.dienst.RelID} />
          ) : (
            <span></span>
          );
        },
      },
      // {
      //   key: EKolom.Registratiedatum,
      //   label: 'Reg.datum',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 125,
      //   renderer: (rij: IOphalenAanmeldingenResultElement) =>
      //     format(new Date(rij.Registratiedatum), 'dd-MM-yyyy'),
      // },
      {
        key: EKolom.Status,
        label: 'Status',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij: IOphalenAanmeldingenResultElement) => rij.status.Naam,
      },
      {
        key: EKolom.AangemeldOp,
        label: 'Aangemeld op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij: IOphalenAanmeldingenResultElement) =>
          rij.AangemeldOp !== null ? (
            format(new Date(rij.AangemeldOp), 'dd-MM-yyyy')
          ) : (
            <span>Nog niet</span>
          ),
      },
      {
        key: EKolom.ExterneReferentie,
        label: 'Externe ref.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij: IOphalenAanmeldingenResultElement) => {
          return rij.ExterneReferentie !== null ? rij.ExterneReferentie : <span></span>;
        },
      },
      {
        key: EKolom.Productsoort,
        label: 'Cat',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 85,
        renderer: (rij: IOphalenAanmeldingenResultElement) => {
          if (rij.product === null) {
            return <span></span>;
          }
          return rij.product.producttype.ProductsoortnaamKort;
        },
      },
      {
        key: EKolom.Merknaam,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (rij: IOphalenAanmeldingenResultElement) => {
          if (rij.product === null) {
            return <span></span>;
          }
          return rij.product.producttype.Merknaam;
        },
      },
      {
        key: EKolom.Typenaam,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij: IOphalenAanmeldingenResultElement) => {
          if (rij.product === null) {
            return <span></span>;
          }
          return rij.product.producttype.Typenaam;
        },
      },
      {
        key: EKolom.Referentiecode,
        label: 'Ref.code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij: IOphalenAanmeldingenResultElement) => {
          if (rij.product === null) {
            return <span></span>;
          }
          return rij.product.Referentiecode;
        },
      },
      // {
      //   key: EKolom.Serienummer,
      //   label: 'Serienummer',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 125,
      //   renderer: (rij: IOphalenAanmeldingenResultElement) => {
      //     return rij.product !== null ? rij.product.Serienummer : <span></span>;
      //   },
      // },
      {
        key: EKolom.Productinfo,
        label: ' ',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 60,
        renderer: (rij: IOphalenAanmeldingenResultElement) => {
          if (rij.product === null) {
            return <span></span>;
          }

          return (
            <div className="mb-1">
              <a
                href="#"
                // style={{ color: Kleur.DonkerGrijs }}
                onClick={() => {
                  setUrlStateSync('infoDialoogState', { id: rij.product!.ProdID });
                }}
              >
                <IconInformatie style={{ width: 17, height: 17, fill: EKleur.Blauw }} />
              </a>
            </div>
          );
        },
      },
      {
        key: EKolom.Omruilen,
        label: 'Omr./Cred.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (rij: IOphalenAanmeldingenResultElement) => (rij.Omruilen ? 'Omruil' : 'Credit'),
      },
      {
        key: EKolom.Afgehandeld,
        label: 'Afgeh.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 85,
        renderer: (rij: IOphalenAanmeldingenResultElement) =>
          rij.AfgehandeldOp !== null ? <span>Ja</span> : <span>Nee</span>,
      },
      {
        key: EKolom.Notities,
        label: 'Notities',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 2,
        renderer: (rij: IOphalenAanmeldingenResultElement) => (
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {rij.Notities}
          </span>
        ),
      },
    ],
    [producten, inkoopopdrachten, inkoopdiensten],
  );

  const rijen = useMemo<Record<number, IOphalenAanmeldingenResultElement>>(() => {
    return aanmeldingen === null
      ? {}
      : aanmeldingen.reduce((acc, curr, i) => {
          return {
            ...acc,
            [i]: curr,
          };
        }, {});
  }, [aanmeldingen]);

  const handleWijzigenRij = useCallback(async (rij: IOphalenAanmeldingenResultElement) => {
    setUrlStateSync('wijzigDialoogState', {
      id: rij.ID,
    });
  }, []);

  const handleVerwijderenRij = useCallback(
    async (rij: IOphalenAanmeldingenResultElement) => {
      await api.v2.inkoop.retour.verwijderenAanmeldingen({
        ids: [rij.ID],
      });
      await ophalenAanmeldingen();
    },
    [ophalenAanmeldingen],
  );

  return (
    <>
      <Helmet>
        <title>Retouren</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                onClick={() => {
                  setUrlStateSync('versturenDialoogState', { ids: urlState.selectie });
                }}
                disabled={urlState.selectie.length === 0}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Versturen aanmelding</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          <>
            {rijen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="d-flex flex-column flex-fill">
                  <ASPTabel
                    rijen={rijen}
                    kolommen={kolommen}
                    keyExtractor={keyExtractor}
                    onWijzigenRij={handleWijzigenRij ?? undefined}
                    onVerwijderenRij={handleVerwijderenRij}
                    sortering={urlState.sortering}
                    selectie={urlState.selectie}
                    onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  />
                </div>
              </>
            )}
          </>
        }
      />
      {urlState.infoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={urlState.infoDialoogState.id}
          onSuccess={() => {
            setUrlStateSync('infoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('infoDialoogState', null)}
        />
      )}
      {urlState.wijzigDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigDialoogState.id}
          onSuccess={() => {
            ophalenAanmeldingen();
            setUrlStateSync('wijzigDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('wijzigDialoogState', null)]}
        />
      )}
      {urlState.versturenDialoogState !== null && (
        <VersturenDialoog
          open
          ids={urlState.versturenDialoogState.ids}
          onSuccess={() => {
            ophalenAanmeldingen();
            setUrlStateSync('versturenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('versturenDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Retouren);
