import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IOphalenFacturenResultElement } from '../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenContractenResultElement } from '../../../../../shared/src/api/v2/sponsoring/contract';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import nameOf from '../../../core/nameOf';
import useUrlState from '../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';

export interface IDialoogResult {
  sponCntID: number;
}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  factuur?: IOphalenFacturenResultElement;
}

interface IUrlState {
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
};

interface IRegel extends IOphalenContractenResultElement {}

const ContractSelectieDialoog: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { children, onAnnuleren, onSuccess, open } = props;

  const [contracten, setContracten] = useState<IOphalenContractenResultElement[] | null>(null);

  const ophalenContracten = useCallback(async () => {
    const contracten = (
      await api.v2.sponsoring.contract.ophalenContracten({
        filterSchema: { filters: [] },
        orderSchema: { orders: [{ naam: 'ONZE_REFERENTIE', richting: 'DESC' }] },
        paginatie: { index: 0, aantal: 100 },
      })
    ).contracten;

    setContracten(contracten);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'OnzeReferentie',
        title: 'Cnt.ref.',
      },
      {
        name: 'Contractdatum',
        title: 'Contractdatum',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x) => x.dienst.relatie!.weergavenaam,
      },
      {
        name: 'Inhoud',
        title: 'Inhoud',
      },
      {
        name: '__kies_actie' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'OnzeReferentie',
        width: 100,
      },
      {
        columnName: 'Contractdatum' as any,
        width: 135,
      },
      {
        columnName: '__relatie' as any,
        width: 175,
      },
      {
        columnName: 'Inhoud',
        width: 275,
      },
      {
        columnName: '__kies_actie' as any,
        width: 40,
      },
    ],
    [],
  );

  return (
    <Dialoog modalProps={{ size: 'lg' }} index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Sponsorcontract selecteren</ModalTitle>
      </ModalHeader>
      {contracten === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <ModalBody className="p-0">
            <GridStyleWrapper rowAmount={contracten.length}>
              <Grid columns={kolommen} rows={contracten}>
                <DataTypeProvider
                  for={[nameOf<IRegel>('Contractdatum')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (rij.Contractdatum === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(rij.Contractdatum), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return (
                      <>
                        <RelatieVisualisatie
                          relID={rij.dienst.RelID}
                          // relatieLinkBuilder={(hoedanigheid, relID) =>
                          //   `/${
                          //     hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                          //   }/${relID}/facturen/overzicht`
                          // }
                        />
                      </>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__kies_actie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          // onGekozen(rij.RelID);
                        }}
                      >
                        Kies
                      </a>
                    );
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable messages={{ noData: 'Geen producten' }} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                <TableHeaderRow showSortingControls />

                {/* <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
              </Grid>
            </GridStyleWrapper>
          </ModalBody>
        </>
      )}

      <ModalFooter className="d-flex justify-content-start mt-2">
        {/* <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() =>
            props.onSuccess({
              sponCntID: 1, // TODO Gil
            })
          }
        >
          Ok
        </button> */}
        <button className="btn btn-secondary" style={{ width: 100 }} onClick={onAnnuleren}>
          Sluiten
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default withRouter(ContractSelectieDialoog);
