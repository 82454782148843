import { DataTypeProvider, RowDetailState, TableRowDetail } from '@devexpress/dx-react-grid';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TableRowDetailWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  IOphalenPotentieleInkoopfactuurBestandenResultElement,
  IOphalenPotentieleInkoopfactuurBestandGroepenResultElement,
} from '../../../../../../shared/src/api/v2/inkoopfactuur';
import { NogTeVerwerkenInkoopfacturenContext } from '..';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import nameof from '../../../../core/nameOf';
import { format } from 'date-fns';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import BestandVisualisatie from '../../../../components/entiteitVisualisaties/BestandVisualisatie';
import { IconKruis, IconToevoegen } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import api from '../../../../api';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import NieuweFactuurDialoogV2 from '../../Inkoopfacturen/Inboeken/NieuweFactuurDialoogV2';

enum Bestandstype {
  Inkoopfactuur = 1,
  Bijlage = 2,
}

interface INieuweFactuurDialoogState {
  potentieelInkoopfactuurBestandID: number;
  groep: IOphalenPotentieleInkoopfactuurBestandGroepenResultElement;
  bestandID: number;
  factuurnummer?: string;
  factuurdatum?: Date;
  vervaldatum?: Date;
  btwBedrag?: number;
  bedragInclusiefBtw?: number;
}

const RowDetail = (props: TableRowDetail.ContentProps) => {
  const context = useContext(NogTeVerwerkenInkoopfacturenContext);
  const row: IOphalenPotentieleInkoopfactuurBestandGroepenResultElement = props.row;

  const potentieleInkoopfactuurBestanden = useMemo(() => {
    return context.potentieleInkoopfactuurBestandenResult.elementen.filter(
      (x) => x.PotentieelBestInkFactGroepID === row.ID,
    );
  }, [context.potentieleInkoopfactuurBestandenResult.elementen, row.ID]);

  const keyExtractor = useCallback(
    (gegroepeerdElement: IOphalenPotentieleInkoopfactuurBestandenResultElement) =>
      gegroepeerdElement.ID,
    [],
  );
  const kolommen = useMemo<TypedColumn<IOphalenPotentieleInkoopfactuurBestandenResultElement>[]>(
    () => [
      {
        name: 'Type',
        title: 'Type',
      },
      {
        name: 'BestandID',
        title: 'Bestand',
      },
      {
        name: '__actie' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenPotentieleInkoopfactuurBestandenResultElement>[]
  >(
    () => [
      {
        columnName: 'Type',
        width: 150,
      },
      {
        columnName: 'BestandID',
        width: 250,
      },
      {
        columnName: '__actie' as any,
        width: 150,
      },
    ],
    [],
  );
  const [
    nieuweFactuurDialoogState,
    setNieuweFactuurDialoogState,
  ] = useState<INieuweFactuurDialoogState | null>(null);

  return (
    <>
      <TableRowDetailWrapper>
        <div className="d-flex">
          <TabelInspringBlok />
          <GridStyleWrapper rowAmount={potentieleInkoopfactuurBestanden.length} maxHeight={500}>
            <Grid
              rows={potentieleInkoopfactuurBestanden}
              columns={kolommen}
              getRowId={keyExtractor}
            >
              <DataTypeProvider
                for={[nameof<IOphalenPotentieleInkoopfactuurBestandenResultElement>('Type')]}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenPotentieleInkoopfactuurBestandenResultElement =
                    formatterProps.row;

                  switch (row.Type as Bestandstype) {
                    case Bestandstype.Inkoopfactuur:
                      return <span>Inkoopfactuur</span>;
                    case Bestandstype.Bijlage:
                      return <span>Bijlage</span>;
                  }

                  throw new Error('Niet ondersteund bestandstype');
                }}
              />

              <DataTypeProvider
                for={[nameof<IOphalenPotentieleInkoopfactuurBestandenResultElement>('BestandID')]}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenPotentieleInkoopfactuurBestandenResultElement =
                    formatterProps.row;

                  return <BestandVisualisatie bestandID={row.BestandID} />;
                }}
              />

              <DataTypeProvider
                for={['__actie']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenPotentieleInkoopfactuurBestandenResultElement =
                    formatterProps.row;

                  if ((row.Type as Bestandstype) === Bestandstype.Bijlage) {
                    return <span />;
                  }

                  if (row.IsVerwerkt) {
                    return (
                      <div className="d-flex align-items-center">
                        <span>Verwerkt</span>
                        <UitlegTooltip inhoud="Ongedaan maken">
                          <button
                            style={{
                              border: 0,
                              outline: 0,
                              background: 0,
                              position: 'relative',
                            }}
                            onClick={async (ev) => {
                              ev.stopPropagation();

                              await api.v2.inkoopfactuur.wijzigenVerwerkingsstatus({
                                ids: [row.ID],
                                isVerwerkt: false,
                              });
                              await context.onVerversenAangevraagd();
                            }}
                          >
                            <IconKruis style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                          </button>
                        </UitlegTooltip>
                      </div>
                    );
                  }

                  return (
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center"
                      style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                      onClick={async (ev) => {
                        ev.preventDefault();
                        const groep = context.potentieleInkoopfactuurBestandGroepenResult.elementen.find(
                          (x) => x.ID === row.PotentieelBestInkFactGroepID,
                        )!;

                        const result = await api.v2.inkoopfactuur.ophalenPotentieleInkoopfactuurBestandDatas(
                          {
                            filterSchema: {
                              filters: [
                                {
                                  naam: 'POTENTIEEL_BEST_INK_FACT_IDS',
                                  data: [row.ID],
                                },
                              ],
                            },
                          },
                        );
                        const data = result.datas[0];

                        setNieuweFactuurDialoogState({
                          potentieelInkoopfactuurBestandID: row.ID,
                          groep,
                          bestandID: row.BestandID,
                          vervaldatum:
                            data.Vervaldatum === null ? undefined : new Date(data.Vervaldatum),
                          btwBedrag: data.BtwBedrag ?? undefined,
                          bedragInclusiefBtw: data.Factuurbedrag ?? undefined,
                          factuurnummer: data.Factuurnummer ?? undefined,
                          factuurdatum:
                            data.Factuurdatum === null ? undefined : new Date(data.Factuurdatum),
                        });
                      }}
                    >
                      <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                      <span className="ml-2">Nieuwe inkoopfactuur</span>
                    </button>
                  );
                }}
              />

              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </div>
      </TableRowDetailWrapper>

      {nieuweFactuurDialoogState !== null && (
        <NieuweFactuurDialoogV2
          open
          onSuccess={async () => {
            await api.v2.inkoopfactuur.wijzigenVerwerkingsstatus({
              ids: [nieuweFactuurDialoogState!.potentieelInkoopfactuurBestandID],
              isVerwerkt: true,
            });
            await context.onVerversenAangevraagd();
            setNieuweFactuurDialoogState(null);
            // setUrlStateSync('nieuweFactuurDialoogState', null);
          }}
          onAnnuleren={() => setNieuweFactuurDialoogState(null)}
          relID={nieuweFactuurDialoogState.groep.RelID ?? undefined}
          inkoopfactuurBestandID={nieuweFactuurDialoogState.bestandID}
          factuurdatum={nieuweFactuurDialoogState.factuurdatum}
          factuurnummer={nieuweFactuurDialoogState.factuurnummer}
          bedragInclusiefBtw={nieuweFactuurDialoogState.bedragInclusiefBtw}
          btwBedrag={nieuweFactuurDialoogState.btwBedrag}
          vervaldatum={nieuweFactuurDialoogState.vervaldatum}
        />
      )}
    </>
  );
};

export default RowDetail;
