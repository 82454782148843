import styled from 'styled-components';

export const Root = styled.div``;

export const Message = styled.div`
  display: flex;
  //align-items: center;
  margin: 5px;

  .icon {
  }

  .content {
    margin-top: 2px;
  }
`;
