import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Kleur as EKleur, Kleur } from '../../../bedrijfslogica/constanten';
import {
  getIconFile,
  IconDownload,
  IconInformatie,
  IconMap,
  IconToevoegen,
  IconUitvergroten,
  IconVerwijderen,
  IconWijzigen,
} from '../../Icons';
import ActieMenuKnop from '../../ActieMenuKnop';
import {
  IBestandslabel,
  IBestandslabelToegevoegdMessageData,
  IOphalenBestandslabelsResult,
  IBestandslabelGewijzigdMessageData,
  IBestandslabelsVerwijderdMessageData,
} from '../../../../../shared/src/api/v2/bestand/label';
import filesize from 'filesize';
import downloadUrl from '../../../core/downloadUrl';
import api, { IPaginatiePositie } from '../../../api';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../stores/CheckStore';
import { EVerkennerItemKolom, EVerkennerItemType } from './types';
import Skeleton from 'react-loading-skeleton';
import { VerkennerSortering } from '../Verkenner';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import {
  IOphalenMediaTypesV2Result,
  IOphalenMediaTypesV2ResultElement,
} from '../../../../../shared/src/api/v2/mediatype';
import { mediaTypeNaarMediaWeergaveType } from '../../MediaWeergave';
import MediaWeergaveDialoog from '../../dialogen/MediaWeergaveDialoog';
import { format } from 'date-fns';
import ASPTabel from '../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType, ESorteringModus } from '../../tabel/ASPTabel/types';
import BodyComponent from './BodyComponent';
import TdComponent from './TdComponent';
import UitlegTooltip from '../../formulier/UitlegTooltip';
import LabelSelectieDialoog from './LabelSelectieDialoog';
import TableData from '../../tabel/ASPTabel/Body/TableData';
import { useRealtimeListener } from '../../../one-off-components/realtime/RealtimeIntegratie';
import Bestandslabel from './Bestandslabel';
import { IVerkennerItem, IVerkennerItemState } from '../types';
import { referentieNaamEnumNaarLeesbaarMap } from '../../../bedrijfslogica/bestand';
import { EReferentieNaamEnum } from '../../../bedrijfslogica/enums';
import LeegComponent from './LeegComponent';
import KorteBestandsbeschrijvingWijzigenDialoog from '../../dialogen/KorteBestandsbeschrijvingWijzigenDialoog';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import BestandsinformatieDialoog from '../BestandsinformatieDialoog';

export interface IHernoemenState {
  value: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface ILabelSelectieDialoogState {
  bestandID: number;
  bestandslabelIDs: number[];
}

export interface ITabelWeergaveContext {
  onBestandenDrop: (bestanden: File[]) => Promise<void>;
  onNavigerenBestandsmap?: (id: number | null) => void;
  onItemVerplaatst: (verplaatstNaarBestandsmapID: number | null, itemId: string) => Promise<void>;
}

export const TabelWeergaveContext = React.createContext<ITabelWeergaveContext>(null as any);

export interface IKolomConfig {
  weergeven?: boolean;
  kolom?: Partial<Exclude<ASPKolom<EVerkennerItemKolom, IVerkennerItem>, 'key'>>;
}

export type KolommenConfig = Partial<Record<EVerkennerItemKolom, IKolomConfig>>;

interface IProps {
  gefocustItemId: string | null;
  onGefocustItemIdChange: (id: string | null) => void;
  selectie: string[];
  onSelectieChange: (ids: string[]) => void;
  items: Record<number, IVerkennerItem>;
  totaalAantalItems: number;
  itemsState: Record<string, IVerkennerItemState>;
  onItemsStateChange: (itemsState: Record<string, IVerkennerItemState>) => void;
  onItemHernoemd: (id: string, naam: string) => Promise<void>;
  onItemsVerwijderd: (ids: string[]) => Promise<void>;
  onItemInBestandsmapToegevoegd: (item: IVerkennerItem) => Promise<void>;
  onItemVerplaatst: (verplaatstNaarBestandsmapID: number | null, itemId: string) => Promise<void>;
  onBestandenDrop: (bestanden: File[]) => Promise<void>;
  sortering: VerkennerSortering;
  onSorteringChange: (sortering: VerkennerSortering) => void;
  onReportRowsRendered: (index: { startIndex: number; stopIndex: number }) => void;
  magItemSelecteren: (item: IVerkennerItem) => boolean;
  magItemVerwijderen?: (item: IVerkennerItem) => boolean;
  magItemHernoemen?: (item: IVerkennerItem) => boolean;
  onExtraRijenAangevraagd: (positie: IPaginatiePositie) => Promise<void>;
  onNavigerenBestandsmap?: (bestandsmapID: number | null) => void;
  relID?: number;
  kolommenConfig?: KolommenConfig;
  kolommenVolgorde?: Array<EVerkennerItemKolom>;
  scrollNaarIndex?: number;
}

const TabelWeergave = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [
    labelSelectieDialoogState,
    setLabelSelectieDialoogState,
  ] = useState<ILabelSelectieDialoogState | null>(null);

  const [mediatypenResult, setMediatypenResult] = useState<IRemoteData<IOphalenMediaTypesV2Result>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    api.v2.mediaType
      .ophalenMediaTypes({})
      .then((result) => setMediatypenResult(createReadyRemoteData(result)));
  }, []);

  const mediatypenMap = useMemo<Record<number, IOphalenMediaTypesV2ResultElement> | null>(() => {
    if (mediatypenResult.state === ERemoteDataState.Pending) {
      return null;
    }
    return mediatypenResult.data!.mediaTypes.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ID]: curr,
      };
    }, {});
  }, [mediatypenResult]);

  // const [opslagdienstResult, setOpslagdienstResult] = useState<
  //   IRemoteData<IOphalenOpslagdienstenResult>
  // >(createPendingRemoteData());

  const [bestandslabelResult, setBestandslabelResult] = useState<
    IRemoteData<IOphalenBestandslabelsResult>
  >(createPendingRemoteData());
  const ophalenBestandslabels = useCallback(async () => {
    const result = await api.v2.bestand.label.ophalenBestandslabels({});
    setBestandslabelResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenBestandslabels();
  }, [ophalenBestandslabels]);

  const bestandslabelMap = useMemo<Record<number, IBestandslabel> | null>(() => {
    if (bestandslabelResult.state === ERemoteDataState.Pending) {
      return null;
    }
    return bestandslabelResult.data!.labels.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ID]: curr,
      };
    }, {});
  }, [bestandslabelResult]);

  useRealtimeListener(async (naamEnum, data) => {
    if (
      naamEnum === 'BESTANDSLABEL_TOEGEVOEGD' ||
      naamEnum === 'BESTANDSLABEL_GEWIJZIGD' ||
      naamEnum === 'BESTANDSLABELS_VERWIJDERD'
    ) {
      const d = data as
        | IBestandslabelToegevoegdMessageData
        | IBestandslabelGewijzigdMessageData
        | IBestandslabelsVerwijderdMessageData;

      await ophalenBestandslabels();
    }
  });

  const handleTableBodyFilesDrop = useCallback(
    async (files: File[]) => {
      await props.onBestandenDrop(files);
    },
    [props.onBestandenDrop],
  );

  const keyExtractor = useCallback((item: IVerkennerItem) => item.id, []);

  const kolommenVolgorde = useMemo<Array<EVerkennerItemKolom>>(
    () =>
      props.kolommenVolgorde ?? [
        EVerkennerItemKolom.SoortIcon,
        EVerkennerItemKolom.Naam,
        EVerkennerItemKolom.Inzien,
        EVerkennerItemKolom.Grootte,
        EVerkennerItemKolom.Type,
        EVerkennerItemKolom.DatumToegevoegd,
        EVerkennerItemKolom.Labels,
        EVerkennerItemKolom.Referenties,
        EVerkennerItemKolom.Opslagdienst,
        EVerkennerItemKolom.Acties,
      ],
    [props.kolommenVolgorde],
  );

  const standaardKolommenMap = useMemo<
    Record<EVerkennerItemKolom, ASPKolom<EVerkennerItemKolom, IVerkennerItem>>
  >(
    () => ({
      [EVerkennerItemKolom.SoortIcon]: {
        key: EVerkennerItemKolom.SoortIcon,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 40,
        renderer: (item) => {
          if (item.type === EVerkennerItemType.Bestand) {
            if (mediatypenMap === null) {
              return <Skeleton />;
            }

            const mediaType = mediatypenMap![item.bestand.MediaTypeID];
            if (mediaType === undefined) {
              return <Skeleton />;
            }
            const Svg = getIconFile(mediaType.MediaType);

            return <Svg style={{ width: 24, height: 24 }} />;
          } else if (item.type === EVerkennerItemType.Map) {
            return (
              <span title={item.map.IsSysteemmap ? 'Systeemmap' : 'Map'}>
                <IconMap
                  style={{
                    width: 28,
                    height: 28,
                    fill: item.map.IsSysteemmap ? Kleur.Paars : Kleur.ZwembadBlauw,
                  }}
                />
              </span>
            );
          }
          throw new Error();
        },
      },
      [EVerkennerItemKolom.Naam]: {
        key: EVerkennerItemKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (item) => {
          const state = props.itemsState[item.id] ?? {};

          const naam = item.type === EVerkennerItemType.Map ? item.map.Naam : item.bestand.Naam;

          if (state.hernoemen === undefined) {
            return (
              <span
                title={naam}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {naam}
              </span>
            );
          }

          return (
            <input
              type="text"
              value={state.hernoemen.value}
              onChange={(ev) => {
                props.onItemsStateChange({
                  ...props.itemsState,
                  [item.id]: {
                    ...state,
                    hernoemen: {
                      ...state.hernoemen,
                      value: ev.target.value,
                    },
                  },
                });
              }}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  const currentValue = state.hernoemen!.value;
                  const trimmedValue = currentValue.trim();
                  if (trimmedValue.length === 0) {
                    return;
                  }

                  props.onItemsStateChange({
                    ...props.itemsState,
                    [item.id]: {
                      ...state,
                      hernoemen: undefined,
                    },
                  });

                  props.onItemHernoemd(item.id, trimmedValue);
                }
              }}
              onBlur={(ev) => {
                props.onItemsStateChange({
                  ...props.itemsState,
                  [item.id]: {
                    ...state,
                    hernoemen: undefined,
                  },
                });
              }}
              onFocus={(ev) => {
                if (item.type === EVerkennerItemType.Map) {
                  ev.target.select();
                } else if (item.type === EVerkennerItemType.Bestand) {
                  const value = ev.target.value;
                  const extensionIdx = value.indexOf('.');
                  ev.target.setSelectionRange(
                    0,
                    extensionIdx === -1 ? ev.target.value.length : extensionIdx,
                  );
                }
              }}
              autoFocus
            />
          );
        },
      },
      [EVerkennerItemKolom.Inzien]: {
        key: EVerkennerItemKolom.Acties,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 25,
        renderer: (item) => {
          const itemState = props.itemsState[item.id] ?? {};

          if (item.type === EVerkennerItemType.Bestand) {
            const mediaWeergaveType = mediaTypeNaarMediaWeergaveType(item.bestand.MediaType);

            return (
              <>
                <div
                  className="d-flex"
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  {mediaWeergaveType !== null && (
                    <button
                      style={{ outline: 0, padding: '3px 3px', background: 0, border: 0 }}
                      onClick={(ev) => {
                        ev.stopPropagation();

                        props.onItemsStateChange({
                          ...props.itemsState,
                          [item.id]: {
                            ...props.itemsState[item.id],
                            mediaweergaveTonen: true,
                          },
                        });
                      }}
                    >
                      <IconUitvergroten style={{ fill: Kleur.Grijs, width: 17, height: 17 }} />
                    </button>
                  )}
                </div>
                {itemState.mediaweergaveTonen && (
                  <MediaWeergaveDialoog
                    defaultCurrent={item.id}
                    mediaWeergaven={[
                      {
                        id: item.id,
                        type: mediaWeergaveType!,
                        src: item.bestand.url,
                        title: item.bestand.Naam,
                        mediaType: item.bestand.MediaType,
                      },
                    ]}
                    open
                    onSuccess={() =>
                      props.onItemsStateChange({
                        ...props.itemsState,
                        [item.id]: {
                          ...props.itemsState[item.id],
                          mediaweergaveTonen: false,
                        },
                      })
                    }
                    onAnnuleren={() =>
                      props.onItemsStateChange({
                        ...props.itemsState,
                        [item.id]: {
                          ...props.itemsState[item.id],
                          mediaweergaveTonen: false,
                        },
                      })
                    }
                  />
                )}
              </>
            );
          }

          return null;
        },
        tdComponent: (tdProps) => <TableData {...tdProps} style={{ padding: 0 }} />,
      },
      [EVerkennerItemKolom.Grootte]: {
        key: EVerkennerItemKolom.Grootte,
        label: 'Grootte',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => {
          let content;
          if (item.type === EVerkennerItemType.Bestand) {
            content = item.bestand.Grootte === null ? '- -' : filesize(item.bestand.Grootte);
          } else if (item.type === EVerkennerItemType.Map) {
            content = `${item.map.aantalBestanden + item.map.aantalMappen} items`;
          }
          return (
            <span
              style={{
                fontSize: 12,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              className="justify-content-center align-items-end"
              title={content}
            >
              {content}
            </span>
          );
        },
      },
      [EVerkennerItemKolom.Type]: {
        key: EVerkennerItemKolom.Type,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => {
          if (item.type === EVerkennerItemType.Bestand) {
            if (mediatypenMap === null) {
              return <Skeleton />;
            }

            const mediaType = mediatypenMap![item.bestand.MediaTypeID];
            if (mediaType === undefined) {
              return <Skeleton />;
            }
            return (
              <span
                title={mediaType.Naam ?? mediaType.MediaType}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}
              >
                {mediaType.Naam ?? mediaType.MediaType}
              </span>
            );
          } else if (item.type === EVerkennerItemType.Map) {
            return (
              <span style={{ fontSize: 12 }}>{item.map.IsSysteemmap ? 'Systeemmap' : 'Map'}</span>
            );
          }
          throw new Error();
        },
      },
      [EVerkennerItemKolom.Labels]: {
        key: EVerkennerItemKolom.Labels,
        label: 'Labels',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        minimaleVasteBreedte: 225,
        tdComponent: (tdProps) => {
          return <TableData {...tdProps} style={{ overflow: 'hidden', overflowX: 'auto' }} />;
        },
        renderer: (item) => {
          if (item.type === EVerkennerItemType.Map) {
            return <span>-&nbsp;-</span>;
          }

          return (
            <div className="d-flex align-items-center" style={{ minWidth: 250, minHeight: 23 }}>
              {item.labels.map((bestandXBestandslabel, i) => {
                const label =
                  bestandslabelMap === null
                    ? null
                    : bestandslabelMap[bestandXBestandslabel.BestandslabelID] ?? null;

                if (label === null) {
                  return null;
                }

                return (
                  <>
                    <Bestandslabel
                      key={bestandXBestandslabel.ID}
                      label={label}
                      onRemoveClick={async () => {
                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: 'Confirmatie verwijderen label',
                              asynchroneActieNaBevestigingFn: async () => {
                                await api.v2.bestand.label.verwijderenBestandXBestandslabels({
                                  bestandXBestandslabelIDs: [bestandXBestandslabel.ID],
                                });
                              },
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }
                      }}
                    />
                    {i !== item.labels.length - 1 && <span>&nbsp;</span>}
                  </>
                );
              })}
              <UitlegTooltip inhoud="Label toevoegen">
                <button
                  className={item.labels.length > 0 ? 'ml-2' : ''}
                  style={{
                    outline: 0,
                    border: 0,
                    background: 0,
                    padding: 0,
                    position: 'relative',
                    top: -2,
                  }}
                  onClick={(ev) => {
                    ev.stopPropagation();

                    setLabelSelectieDialoogState({
                      bestandID: item.bestand.ID,
                      bestandslabelIDs: item.labels.map((x) => x.BestandslabelID),
                    });
                  }}
                >
                  {item.labels.length === 0 ? (
                    <IconToevoegen style={{ fill: EKleur.Grijs, width: 15, height: 15 }} />
                  ) : (
                    <IconWijzigen style={{ fill: EKleur.Grijs, width: 15, height: 15 }} />
                  )}
                </button>
              </UitlegTooltip>
            </div>
          );
        },
      },
      [EVerkennerItemKolom.DatumToegevoegd]: {
        key: EVerkennerItemKolom.DatumToegevoegd,
        label: 'Datum toegevoegd',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item) => {
          const datumToegevoegd =
            item.type === EVerkennerItemType.Bestand
              ? item.bestand.RecordToegevoegd
              : item.type === EVerkennerItemType.Map
              ? item.map.RecordToegevoegd
              : null;

          return (
            <span
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 12,
              }}
            >
              {datumToegevoegd === null ? (
                <span>-&nbsp;-</span>
              ) : (
                format(new Date(datumToegevoegd), 'dd-MM-yyyy HH:mm')
              )}
            </span>
          );
        },
      },
      [EVerkennerItemKolom.Referenties]: {
        key: EVerkennerItemKolom.Referenties,
        label: 'Referenties',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 275,
        renderer: (item) => {
          if (item.type === EVerkennerItemType.Bestand) {
            // const aantal = Object.keys(item.bestand.referenties).reduce((acc, key) => {
            //   return acc + item.bestand.referenties[key];
            // }, 0);

            return (
              <a
                href="#"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}
                onClick={async (ev) => {
                  ev.preventDefault();

                  const result = await api.v2.bestand.bepalenBestandsreferenties({
                    bestandIDs: [item.bestand.ID],
                  });
                  console.log(result);
                }}
              >
                {Object.keys(item.bestand.referenties)
                  .map((r) => referentieNaamEnumNaarLeesbaarMap[r as EReferentieNaamEnum])
                  .join(', ')}
              </a>
            );
          }

          const aantal = Object.keys(item.map.referenties).reduce((acc, key) => {
            return acc + item.map.referenties[key];
          }, 0);
          const aantalZonderBestandenOfMappen =
            aantal - item.map.aantalMappen - item.map.aantalBestanden;

          return (
            <span
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 12,
              }}
            >
              {aantalZonderBestandenOfMappen === 0 ? null : aantalZonderBestandenOfMappen}
            </span>
          );
        },
      },
      [EVerkennerItemKolom.Opslagdienst]: {
        key: EVerkennerItemKolom.Opslagdienst,
        label: 'Opslagdienst',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item) => {
          if (item.type === EVerkennerItemType.Bestand) {
            return <span style={{ fontSize: 12 }}>Google Cloud Storage</span>;
          } else if (item.type === EVerkennerItemType.Map) {
            return <span>-&nbsp;-</span>;
          }
          throw new Error();
        },
      },
      [EVerkennerItemKolom.Acties]: {
        key: EVerkennerItemKolom.Acties,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 25,
        renderer: (item) => {
          const itemState = props.itemsState[item.id] ?? {};

          if (item.type === EVerkennerItemType.Bestand) {
            const mediaWeergaveType = mediaTypeNaarMediaWeergaveType(item.bestand.MediaType);

            return (
              <>
                <div
                  className="d-flex"
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <ActieMenuKnop
                    acties={[
                      {
                        text: 'Downloaden',
                        onClick: async () => {
                          await downloadUrl(item.bestand.url, item.bestand.Naam);
                        },
                        icon: <IconDownload style={{ width: 14, height: 14, fill: Kleur.Grijs }} />,
                      },
                      {
                        text: 'Hernoemen',
                        onClick: () => {
                          props.onItemsStateChange({
                            ...props.itemsState,
                            [item.id]: {
                              ...props.itemsState[item.id],
                              hernoemen: {
                                value: item.bestand.Naam,
                              },
                            },
                          });
                        },
                        icon: <IconWijzigen style={{ width: 14, height: 14, fill: Kleur.Grijs }} />,
                      },
                      {
                        text: 'Wijzigen korte beschrijving',
                        onClick: async () => {
                          await globaleRenderer.render((renderProps) => (
                            <KorteBestandsbeschrijvingWijzigenDialoog
                              open
                              onSuccess={() => renderProps.destroy()}
                              onAnnuleren={() => renderProps.destroy()}
                              bestandID={item.bestand.ID}
                            />
                          ));
                        },
                        icon: <IconWijzigen style={{ width: 14, height: 14, fill: Kleur.Grijs }} />,
                      },
                      {
                        text: 'Informatie',
                        onClick: async () => {
                          await globaleRenderer.render((renderProps) => (
                            <BestandsinformatieDialoog
                              bestand={item.bestand}
                              open
                              onSuccess={() => renderProps.destroy()}
                              onAnnuleren={() => renderProps.destroy()}
                            />
                          ));
                        },
                        icon: (
                          <IconInformatie style={{ width: 14, height: 14, fill: Kleur.Grijs }} />
                        ),
                      },
                      {
                        text: 'Verwijderen',
                        onClick: async () => {
                          const currentItems = props.items;
                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: 'Bevestig verwijderen bestand(en)',
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          await props.onItemsVerwijderd([item.id]);
                        },
                        icon: (
                          <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        ),
                        disabled: Object.keys(item.bestand.referenties).length > 0,
                        title:
                          Object.keys(item.bestand.referenties).length > 0
                            ? 'Dit bestand is in gebruik door een of meer andere objecten'
                            : undefined,
                      },
                    ]}
                  />
                </div>
              </>
            );
          } else if (item.type === EVerkennerItemType.Map) {
            return (
              <div
                className="d-flex"
                style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}
              >
                <ActieMenuKnop
                  acties={[
                    {
                      text: 'Hernoemen',
                      onClick: () => {
                        props.onItemsStateChange({
                          ...props.itemsState,
                          [item.id]: {
                            ...props.itemsState[item.id],
                            hernoemen: {
                              value: item.map.Naam,
                            },
                          },
                        });
                      },
                      icon: <IconWijzigen style={{ width: 14, height: 14, fill: Kleur.Grijs }} />,
                      disabled: item.map.IsSysteemmap,
                      title: item.map.IsSysteemmap
                        ? 'Een systeemmap kan niet worden gemuteerd, deze wordt beheerd door het systeem'
                        : undefined,
                    },
                    {
                      text: 'Verwijderen',
                      onClick: async () => {
                        // const currentItems = props.items;
                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: 'Bevestig verwijderen map(pen)',
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }
                        await props.onItemsVerwijderd([item.id]);
                      },
                      icon: (
                        <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                      ),
                      disabled: item.map.IsSysteemmap,
                      title: item.map.IsSysteemmap
                        ? 'Een systeemmap kan niet worden gemuteerd, deze wordt beheerd door het systeem'
                        : undefined,
                    },
                  ]}
                />
              </div>
            );
          }
          throw new Error();
        },
        tdComponent: (tdProps) => <TableData {...tdProps} style={{ padding: 0 }} />,
      },
    }),
    [
      props.itemsState,
      props.onItemsStateChange,
      props.onItemHernoemd,
      mediatypenMap,
      bestandslabelMap,
    ],
  );

  const kolommen = useMemo<ASPKolom<EVerkennerItemKolom, IVerkennerItem>[]>(
    () =>
      kolommenVolgorde
        .filter((x) => props.kolommenConfig?.[x]?.weergeven ?? true)
        .map((x) => {
          const kolom = standaardKolommenMap[x];
          const config = props.kolommenConfig?.[x];

          return {
            ...kolom,
            ...config?.kolom,
          } as ASPKolom<EVerkennerItemKolom, IVerkennerItem>;
        }),
    [kolommenVolgorde, standaardKolommenMap, props.kolommenConfig],
  );

  const magSelectieVerwijderen = useMemo(() => {
    return (
      props.selectie.length > 0 &&
      props.selectie.every((id) => {
        const key = Object.keys(props.items).find((key) => props.items[Number(key)].id === id);
        if (key === undefined) {
          return false;
        }
        const item = props.items[Number(key)];
        if (item.type === EVerkennerItemType.Bestand) {
          // Heeft referenties?
          if (
            Object.keys(item.bestand.referenties).reduce(
              (acc, key) => acc + item.bestand.referenties[key],
              0,
            ) > 0
          ) {
            return false;
          }
        }

        if (item.type === EVerkennerItemType.Map) {
          if (item.map.IsSysteemmap) {
            return false;
          }
          // Heeft referenties, los van onderliggende bestanden/mappen?
          if (
            Object.keys(item.map.referenties).reduce(
              (acc, key) => acc + item.map.referenties[key],
              0,
            ) >
            item.map.aantalMappen + item.map.aantalBestanden
          ) {
            return false;
          }
        }

        return true;
      })
    );
  }, [props.selectie]);

  const handleMagRijWijzigen = useCallback(
    (rij: IVerkennerItem) => {
      if (props.magItemHernoemen !== undefined) {
        return props.magItemHernoemen(rij);
      }

      if (rij.type === EVerkennerItemType.Bestand) {
        return true;
      }
      return !rij.map.IsSysteemmap;
    },
    [props.magItemHernoemen],
  );
  const handleMagRijVerwijderen = useCallback(
    (rij: IVerkennerItem) => {
      if (props.magItemVerwijderen !== undefined) {
        return props.magItemVerwijderen(rij);
      }
      if (rij.type === EVerkennerItemType.Bestand) {
        return true;
      }
      return !rij.map.IsSysteemmap;
    },
    [props.magItemVerwijderen],
  );

  const context = useMemo<ITabelWeergaveContext>(
    () => ({
      onBestandenDrop: handleTableBodyFilesDrop,
      onNavigerenBestandsmap: props.onNavigerenBestandsmap,
      onItemVerplaatst: props.onItemVerplaatst,
    }),
    [handleTableBodyFilesDrop, props.onNavigerenBestandsmap, props.onItemVerplaatst],
  );

  return (
    <TabelWeergaveContext.Provider value={context}>
      <Root>
        <div className="d-flex flex-fill flex-column">
          <ASPTabel
            rijen={props.items}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            selectie={props.selectie}
            onSelectieChange={props.onSelectieChange}
            magRijSelecteren={props.magItemSelecteren}
            totaalAantalRijen={props.totaalAantalItems}
            sortering={props.sortering}
            onSorteringChange={props.onSorteringChange}
            sorteringOpties={{
              modus: ESorteringModus.Whitelist,
              kolomOpties: {
                [EVerkennerItemKolom.Naam]: {
                  magSorteren: true,
                },
                [EVerkennerItemKolom.Grootte]: {
                  magSorteren: true,
                },
                [EVerkennerItemKolom.Type]: {
                  magSorteren: true,
                },
                [EVerkennerItemKolom.DatumToegevoegd]: {
                  magSorteren: true,
                },
                [EVerkennerItemKolom.Opslagdienst]: {
                  magSorteren: true,
                },
              },
            }}
            tdComponent={TdComponent}
            gefocustRij={props.gefocustItemId ?? undefined}
            ongefocustRijChange={props.onGefocustItemIdChange}
            onExtraRijenAangevraagd={props.onExtraRijenAangevraagd}
            bodyComponent={BodyComponent}
            leegComponent={LeegComponent}
            onWijzigenRij={async (item) => {
              const naam =
                item.type === EVerkennerItemType.Bestand ? item.bestand.Naam : item.map.Naam;

              props.onItemsStateChange({
                ...props.itemsState,
                [item.id]: {
                  ...props.itemsState[item.id],
                  hernoemen: {
                    value: naam,
                  },
                },
              });
            }}
            onVerwijderenRij={async (item) => {
              await props.onItemsVerwijderd([item.id]);
            }}
            magRijWijzigen={handleMagRijWijzigen}
            magRijVerwijderen={handleMagRijVerwijderen}
            verwijderenRijConfirmatie={async (item, verwijderen) => {
              if (item.type === EVerkennerItemType.Bestand) {
                await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen bestand(en)',
                  asynchroneActieNaBevestigingFn: verwijderen,
                });
              } else if (item.type === EVerkennerItemType.Map) {
                await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen map(pen)',
                  asynchroneActieNaBevestigingFn: verwijderen,
                });
              }
            }}
            scrollNaarIndex={props.scrollNaarIndex}
          />
        </div>
      </Root>
      {labelSelectieDialoogState !== null && (
        <LabelSelectieDialoog
          open
          onSuccess={async (result) => {
            await api.v2.bestand.label.muterenBestandXBestandslabel({
              bestandID: labelSelectieDialoogState!.bestandID,
              bestandslabelIDs: result.bestandslabelIDs,
            });

            setLabelSelectieDialoogState(null);
          }}
          onAnnuleren={() => setLabelSelectieDialoogState(null)}
          bestandslabelIDs={labelSelectieDialoogState.bestandslabelIDs}
          relID={props.relID}
        />
      )}
    </TabelWeergaveContext.Provider>
  );
});

export default TabelWeergave;
