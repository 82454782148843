import React, { useRef, useState } from 'react';
import { IFormikValues, veldnamen } from '../index';
import { Field, FieldProps } from 'formik';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import Avatar from '../../../../../components/personalia/Avatar';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import {
  IconCloud,
  IconSearch,
  IconToevoegen,
  IconVerwijderen,
} from '../../../../../components/Icons';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import api from '../../../../../api';
import InactiefOverlay from '../../../../../components/InactiefOverlay';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenBestandenResultElement } from '../../../../../../../shared/src/api/v2/bestand/bestand';
import DriveBestandsselectieDialoog from '../../../../../components/drive/DriveBestandsselectieDialoog';
import { ESelectieModus } from '../../../../../components/BijlageKnop';

interface IProps {}

interface IProfielAfbeeldingASPDriveDialoogState {}

const GegevensTabblad = (props: IProps) => {
  const profielAfbeeldingInputContainerRef = useRef<HTMLDivElement>(null);
  const profielAfbeeldingInputRef = useRef<HTMLInputElement>(null);

  const [profielAfbeeldingInputHovered, setProfielAfbeeldingInputHovered] = useState(false);
  const [
    profielAfbeeldingBestandsbronPopoverTonen,
    setProfielAfbeeldingBestandsbronPopoverTonen,
  ] = useState(false);
  const [
    profielAfbeeldingASPDriveDialoogState,
    setProfielAfbeeldingASPDriveDialoogState,
  ] = useState<IProfielAfbeeldingASPDriveDialoogState | null>(null);
  const [profielAfbeeldingUploadProgress, setProfielAfbeeldingUploadProgress] = useState<
    number | null
  >(null);

  return (
    <div className="p-3">
      <div className="row">
        <div className="col-12">
          <label>{veldnamen.profielAfbeelding}</label>
          <Field
            name={nameof<IFormikValues>('profielAfbeelding')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              const value: IOphalenBestandenResultElement | null = field.value;
              return (
                <>
                  <InactiefOverlay
                    isInactief={profielAfbeeldingUploadProgress !== null}
                    element={
                      <>
                        <div className="d-flex align-items-center justify-content-center w-100 flex-column">
                          <div
                            ref={profielAfbeeldingInputContainerRef}
                            style={{ cursor: 'pointer' }}
                            onMouseEnter={() => setProfielAfbeeldingInputHovered(true)}
                            onMouseLeave={() => setProfielAfbeeldingInputHovered(false)}
                            onClick={() => setProfielAfbeeldingBestandsbronPopoverTonen(true)}
                          >
                            {profielAfbeeldingUploadProgress === null ? (
                              <Avatar
                                imageSrc={value === null ? undefined : value.url}
                                size="75px"
                                backgroundColor={
                                  profielAfbeeldingInputHovered ? Kleur.LichterGrijs : undefined
                                }
                              >
                                {value === null ? (
                                  <IconToevoegen
                                    style={{ width: 20, height: 20, fill: Kleur.Grijs }}
                                  />
                                ) : null}
                              </Avatar>
                            ) : (
                              <div
                                style={{ width: 75, height: 75 }}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <LoadingSpinner />
                              </div>
                            )}
                            <input
                              ref={profielAfbeeldingInputRef}
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={async (ev) => {
                                if (ev.target.files === null) {
                                  return;
                                }
                                const file = ev.target.files[0];
                                if (file === undefined) {
                                  return;
                                }
                                const uploadResult = await api.v2.bestand.upload(
                                  {
                                    naam: file.name,
                                    grootte: file.size,
                                    mediaType: file.type,
                                    url: URL.createObjectURL(file),
                                    file,
                                  },
                                  (percentage) => setProfielAfbeeldingUploadProgress(percentage),
                                );
                                setProfielAfbeeldingUploadProgress(null);
                                form.setFieldValue(field.name, uploadResult);
                              }}
                            />
                          </div>
                          {value !== null && (
                            <button
                              className="btn btn-sm btn-light mt-2 d-flex align-items-center"
                              style={{
                                border: `1px solid ${Kleur.LichtGrijs}`,
                              }}
                              onClick={() => form.setFieldValue(field.name, null)}
                            >
                              <IconVerwijderen
                                style={{ width: 14, height: 14, fill: Kleur.Grijs }}
                              />
                              <span className="ml-2" style={{ fontSize: 11 }}>
                                Verwijderen
                              </span>
                            </button>
                          )}
                          <span className="text-muted mt-2" style={{ fontSize: 11 }}>
                            Klik op de avatar&nbsp;
                            {value === null ? 'om toe te voegen' : 'om te wijzigen'}
                          </span>
                        </div>
                        <FormikVeldFout fieldProps={fieldProps} />
                      </>
                    }
                  />

                  <Overlay
                    show={profielAfbeeldingBestandsbronPopoverTonen}
                    target={profielAfbeeldingInputContainerRef.current!}
                    placement="bottom"
                    onHide={() => setProfielAfbeeldingBestandsbronPopoverTonen(false)}
                    rootClose
                  >
                    <Popover id="profiel-afbeelding-bestandsbron">
                      <ul className="list-group">
                        <li
                          className="list-group-item"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setProfielAfbeeldingBestandsbronPopoverTonen(false);
                            profielAfbeeldingInputRef.current!.click();
                          }}
                        >
                          <IconSearch style={{ fill: Kleur.Grijs, width: 20, height: 20 }} />
                          <span className="ml-2">Op deze computer zoeken</span>
                        </li>
                        <li
                          className="list-group-item"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setProfielAfbeeldingBestandsbronPopoverTonen(false);
                            setProfielAfbeeldingASPDriveDialoogState({});
                          }}
                        >
                          <IconCloud style={{ fill: Kleur.Grijs, width: 20, height: 20 }} />
                          <span className="ml-2">In ASP Drive zoeken</span>
                        </li>
                      </ul>
                    </Popover>
                  </Overlay>

                  {profielAfbeeldingASPDriveDialoogState !== null && (
                    <DriveBestandsselectieDialoog
                      selectieModus={ESelectieModus.Enkel}
                      open
                      onSuccess={async (result) => {
                        const bestandenResult = await api.v2.bestand.ophalenBestanden({
                          filterSchema: {
                            filters: [
                              {
                                naam: 'IDS',
                                data: result.bestandIDs,
                              },
                            ],
                          },
                        });
                        const bestand = bestandenResult.bestanden[0];
                        form.setFieldValue(field.name, bestand);
                        setProfielAfbeeldingASPDriveDialoogState(null);
                      }}
                      onAnnuleren={() => setProfielAfbeeldingASPDriveDialoogState(null)}
                    />
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GegevensTabblad;
