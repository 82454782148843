import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../components/MenuLayout';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import { IOphalenContractenResultElement } from '../../../../../shared/src/api/v2/contract';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

const Overzicht: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [contracten, setContracten] = useState<IOphalenContractenResultElement[] | null>(null);

  return (
    <>
      {contracten != null ? (
        <LoadingSpinner />
      ) : (
        <MenuLayout menu={<span></span>} body={<span>Overzicht</span>} />
      )}
    </>
  );
});

export default Overzicht;
