import {
  IOphalenContextProvidersParams,
  IToevoegenContextProviderParams,
  IToevoegenContextProviderResult,
  IWijzigenContextProviderParams,
  IWijzigenContextProviderResult,
  IVerwijderenContextProvidersParams,
  IVerwijderenContextProvidersResult,
  IOphalenContextProvidersResult,
} from '../../../../../../shared/src/api/v2/bulkbericht/contextProvider';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const contextProvider = {
  ophalenContextProviders: async (
    params: IOphalenContextProvidersParams,
  ): Promise<IOphalenContextProvidersResult> => {
    return await api.post('/v2/bulkbericht/context-provider/ophalen-context-providers', params);
  },
  checkToevoegenContextProvider: async (
    params: IToevoegenContextProviderParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/context-provider/check-toevoegen-context-provider',
      params,
    );
  },
  toevoegenContextProvider: async (
    params: IToevoegenContextProviderParams,
  ): Promise<IToevoegenContextProviderResult> => {
    return await api.post('/v2/bulkbericht/context-provider/toevoegen-context-provider', params);
  },
  checkWijzigenContextProvider: async (
    params: IWijzigenContextProviderParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/context-provider/check-wijzigen-context-provider',
      params,
    );
  },
  wijzigenContextProvider: async (
    params: IWijzigenContextProviderParams,
  ): Promise<IWijzigenContextProviderResult> => {
    return await api.post('/v2/bulkbericht/context-provider/wijzigen-context-provider', params);
  },
  checkVerwijderenContextProviders: async (
    params: IVerwijderenContextProvidersParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/context-provider/check-verwijderen-context-provideren',
      params,
    );
  },
  verwijderenContextProviders: async (
    params: IVerwijderenContextProvidersParams,
  ): Promise<IVerwijderenContextProvidersResult> => {
    return await api.post(
      '/v2/bulkbericht/context-provider/verwijderen-context-provideren',
      params,
    );
  },
};

export default contextProvider;
