import React, { useMemo } from 'react';
import Combobox, { IOptie } from '../Combobox';

export const bepaalHuidigJaar = (): number => new Date().getFullYear();

interface IProps {
  jaar: number | null;
  onJaarChange: (jaar: number | null) => void;
  max?: number;
  min?: number;
}

const JaarSelectie: React.FC<IProps> = (props) => {
  const max = useMemo(() => (props.max !== undefined ? props.max : new Date().getFullYear()), [
    props.max,
  ]);
  const min = useMemo(
    () => (props.min !== undefined ? props.min : new Date().getFullYear() - 100),
    [props.min],
  );

  const opties = useMemo<IOptie<number>[]>(
    () =>
      new Array(max - min + 1).fill(null).map((_, i) => {
        const jaar = max - i;
        return {
          id: jaar,
          label: String(jaar),
        };
      }),
    [max, min],
  );

  return (
    <Combobox geselecteerd={props.jaar} onSelectieChange={props.onJaarChange} opties={opties} />
  );
};

export default JaarSelectie;
