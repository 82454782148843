import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../api';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { IconLink, IconVerwijderen } from '../../../../../components/Icons';
import MenuLayout from '../../../../../components/MenuLayout';
import nameOf from '../../../../../core/nameOf';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import KoppelenDialoog from './KoppelenDialoog';
import { ISponsorEventPlaats } from '../../../../../../../shared/src/api/v2/sponsoringv2/event';

interface IProps {
  koppelBasisID: number;
}

interface IKoppelenDialoogState {
  koppelBasisID: number;
}

const Item: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<ISponsorEventPlaats[] | null>(null);
  const [koppelenDialoogState, setKoppelenDialoogState] = useState<IKoppelenDialoogState | null>(
    null,
  );
  const [selectieState, setSelectieState] = useState<number[]>([]);

  const ophalenRijen = async () => {
    const results = await api.v2.sponsoringv2.event.ophalenPlaatsen({
      filterSchema: {
        filters: [{ naam: 'EVENT_IDS', data: [props.koppelBasisID] }],
      },
    });

    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, []);

  const handleKoppelen = useCallback(async () => {
    setKoppelenDialoogState({ koppelBasisID: props.koppelBasisID });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: selectieState };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde plaatsen ontkoppelen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.sponsoringv2.event.verwijderenPlaatsen(params);
    setSelectieState([]);

    await ophalenRijen();
  }, [selectieState]);

  const keyExtractor = useCallback((row: ISponsorEventPlaats) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<TypedColumn<ISponsorEventPlaats>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<ISponsorEventPlaats>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 400,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <span style={{ marginRight: 10 }}>Gekoppelde plaatsen</span>
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleKoppelen()}
            >
              <IconLink style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Nieuw plaats koppelen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={selectieState.length == 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Ontkoppelen</span>
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
            {rijen === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="200px">
                <Grid getRowId={keyExtractor} rows={rijen} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameOf<ISponsorEventPlaats>('Naam')]}
                    formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                  />
                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={{ noData: 'Geen items aanwezig' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow />
                  <SelectionState
                    selection={selectieState}
                    onSelectionChange={(x) => setSelectieState(x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {koppelenDialoogState !== null && (
        <KoppelenDialoog
          open
          koppelBasisID={koppelenDialoogState.koppelBasisID}
          onSuccess={async () => {
            setKoppelenDialoogState(null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setKoppelenDialoogState(null)}
        />
      )}
    </>
  );
};

export default Item;
