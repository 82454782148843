import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import DatumKiezer from '../../../../../../../../components/formulier/DatumKiezer';
import nameOf from '../../../../../../../../core/nameOf';
import ContactSelectie, {
  EType,
} from '../../../../../../../../components/formulier/ContactSelectie';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../../../../../components/MutatieBedrag';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../../shared/src/api/v2/transport/opdracht';
import { IInitToevoegenRetouropdrachtResult } from '../../../../../../../../../../shared/src/api/v2/transport/opdracht/retour';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/contract';
import { Kleur as EKleur } from '../../../../../../../../bedrijfslogica/constanten';
import MultiCombobox, { IKolom } from '../../../../../../../../components/formulier/MultiCombobox';
import { addDays, addMonths, format } from 'date-fns';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import UitlegTooltip from '../../../../../../../../components/formulier/UitlegTooltip';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import { dagDatum } from '../../../../../../../../helpers/datum';
import _ from 'lodash';
import {
  IOpdracht,
  IRegel,
  IToevoegenOpdrachtenParams,
} from '../../../../../../../../../../shared/src/api/v2/transport/opdracht/nieuw';
import {
  EMutatiebron,
  ETransportopdrachtRegelsoort,
} from '../../../../../../../../bedrijfslogica/enums';

enum ESoort {
  MetVerkoop,
  ZonderVerkoop,
}
interface IFormikValues {
  // soort: ESoort;
  retouropdracht: boolean;
  einddatum: Date | null;
  cntRdEndID: number | null;
  persID: number | null;
  bevestigingSturen: boolean;
  toevoegenAanOpdracht: boolean;
  trsOpdID: number | null;
  interneMutatie: boolean;
}

interface IProps extends IDialoogProps<null> {
  relID: number;
  cntIDs: number[];
}

const veldnamen = {
  // soort: 'Soort',
  retouropdracht: 'Retouropdracht',
  einddatum: 'Einddatum',
  cntRdEndID: 'Reden',
  persID: 'Aanvrager',
  bevestigingSturen: 'Bevestiging sturen',
  toevoegenAanOpdracht: 'Toevoegen aan opdracht',
  trsOpdID: 'Toevoegen aan:',
  interneMutatie: 'Interne mutatie',
};

// const emailValidatie = Yup.string()
//   .email(
//     teksten.formulier.E_EMAIL_VELD({
//       veldnaam: veldnamen.reden,
//     }),
//   )
//   .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.email }));

// const smsValidatie = Yup.string().required(
//   teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.sms }),
// );

const BeeindigenContractDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, relID, cntIDs } = props;
  const { checkStore, contractBeeindigingStore, klantkaartStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);
  const [kostenVTB, setKostenVTB] = useState<number | null>(null);
  const [einddatum, setEinddatum] = useState<Date | null>(null);
  const [initdata, setInitdata] = useState<IInitToevoegenRetouropdrachtResult | null>(null);

  // Contracten ophalen
  useEffect(() => {
    (async () => {
      const contractenResult = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: cntIDs }] },
        })
      ).contracten;
      setContracten(contractenResult);
    })();
  }, [cntIDs]);

  // Redenen beeindiging
  useEffect(() => {
    if (contractBeeindigingStore.redenenBeeindiging !== null) {
      return;
    }
    contractBeeindigingStore.ophalenRedenenBeeindiging();
  }, [contractBeeindigingStore.redenenBeeindiging]);

  // Bereken de minimale einddatum
  useEffect(() => {
    (async () => {
      // default = minimale datum
      // const minimaleEinddatum = await api.v2.contract.beeindiging.ophalenMinimaleEinddatum({
      //   cntIDs,
      //   peildatum: null,
      // });
      // setEinddatum(new Date(minimaleEinddatum));

      // default = vandaag + 1 maand
      const minimaleEinddatum = dagDatum(addMonths(new Date(), 1));

      setEinddatum(minimaleEinddatum);

      formikRef.current!.setFieldValue(nameOf<IFormikValues>('einddatum'), minimaleEinddatum);
    })();
  }, [cntIDs]);

  // Bereken de kosten Vtb
  const bepalenKostenVTB = useCallback(
    async (einddatum: Date) => {
      const kosten = await api.v2.contract.beeindiging.ophalenKostenVtb({
        cntIDs,
        einddatum,
      });

      setKostenVTB(kosten.totaalKostenVtb);
    },
    [einddatum, cntIDs, setKostenVTB],
  );

  useEffect(() => {
    if (einddatum === null) {
      return;
    }

    bepalenKostenVTB(einddatum);
  }, [cntIDs, einddatum]);

  // Bepaal welke locaties er voorkomen bij de contracten
  const bepalenLocaties = useCallback(async () => {
    if (contracten === null) {
      return null;
    }
    const locIDs = contracten.map((x) => x.basis.locatie.LocID);
    const opdrachtenResult = await api.v2.transport.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          { naam: 'LOC_IDS', data: locIDs },
          { naam: 'STATUSSEN', data: [1] },
        ],
      },
    });

    setOpdrachten(opdrachtenResult.opdrachten);
  }, [contracten]);

  useEffect(() => {
    bepalenLocaties();
  }, [bepalenLocaties]);

  // const ophalenInitdata = useCallback(async () => {
  //   // Controle op bestaande opdracht wordt alleen uitgevoerd voor het 1e contract in de opgegeven CntIDs
  //   const result = await api.v2.transport.opdracht.retour.initToevoegenRetouropdracht({
  //     cntID: cntIDs[0],
  //   });

  //   setInitdata(result);
  // }, [cntIDs]);

  // useEffect(() => {
  //   ophalenInitdata();
  // }, [cntIDs]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      setHasSubmitted(true);

      // Controle stap validatie (voor foutmeldingen na OK)
      const errors = await actions.validateForm(values);
      const hasError = Object.keys(errors).some((key) => (errors as any)[key] !== null);
      if (hasError) {
        return;
      }

      actions.setSubmitting(true);

      const tekst = values.retouropdracht;

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Contract(en) beeindigen?
                {values.bevestigingSturen && (
                  <span>
                    <br />
                    <br />
                    Er zal direct een bevestiging naar de klant gestuurd worden.
                  </span>
                )}
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        setHasSubmitted(false);
        actions.setSubmitting(false);
        return;
      }

      await api.v2.contract.beeindiging.beeindigenContracten({
        cntIDs,
        persID: values.persID!,
        einddatum: values.einddatum!,
        cntRdEndID: values.cntRdEndID!,
        mutatiebron: EMutatiebron.Intern,
        interneMutatie: values.interneMutatie,
      });

      if (values.retouropdracht) {
        // Direct de Retouropdrachten maken

        const opdrachtenParams: IToevoegenOpdrachtenParams = { opdrachten: [] };

        const persoon = (
          await api.v2.persoon.ophalenPersonen({
            filterSchema: { filters: [{ naam: 'IDS', data: [values.persID!] }] },
          })
        ).personen[0];

        const contractenResult = (
          await api.v2.contract.ophalenContractenV2({
            filterSchema: { filters: [{ naam: 'IDS', data: cntIDs }] },
          })
        ).contracten;
        const locIDs = _.uniq(contractenResult.map((x) => x.basis.locatie.LocID));
        // const locaties = _.uniq(contractenResult.map((x) => x.basis.locatie));

        // Opdrachten per locatie maken
        for (const locID of locIDs) {
          let opdracht: IOpdracht = {
            trsDienstID: null,
            magID: null,
            locID,
            persID: persoon.PersID,
            telefoon: persoon.TelefoonMobiel !== null ? persoon.TelefoonMobiel : undefined,
            email: persoon.Email !== null ? persoon.Email : undefined,
            bezoekdatum: null,
            aantalAfvoer: 0,
          };

          // Per contract een Retouropdracht maken
          const contracten = contractenResult.filter((x) => x.basis.locatie.LocID === locID);

          let regels: IRegel[] = [];

          for (const contract of contracten) {
            const product = contract.producten.length === 1 ? contract.producten[0] : null;

            let regel: IRegel = {
              regelsoortNaamEnum: ETransportopdrachtRegelsoort.Retour,
              // typeID: undefined,
              prodID: product !== null ? product.ProdID : undefined,
              // bulkproduct: false,
              // gereserveerd: false,
              aantal: 1,
              relID: contract.RelID,
              cntID: contract.CntID,
              verdieping:
                contract.basis.Verdieping !== null ? contract.basis.Verdieping : undefined,
            };

            regels.push(regel);
          }

          opdrachtenParams.opdrachten.push({ opdracht, regels });
        }

        const result = await api.v2.transport.opdracht.nieuw.toevoegenOpdrachten(opdrachtenParams);
      }

      if (values.bevestigingSturen) {
        await api.v2.contract.beeindiging.versturenBevestiging({
          relID,
          persID: values.persID!,
          cntIDs,
        });
      }

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess, relID, cntIDs, setHasSubmitted],
  );

  const redenenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (contractBeeindigingStore.redenenBeeindiging === null) {
      return null;
    }

    return contractBeeindigingStore.redenenBeeindiging.map(
      (reden): IOptie<number> => ({
        id: reden.CntRdEndID,
        label: reden.Naam,
      }),
    );
  }, [contractBeeindigingStore.redenenBeeindiging]);

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      retouropdracht: true,
      einddatum: null,
      cntRdEndID: null,
      persID: klantkaartStore.relatie!.persoon!.PersID,
      bevestigingSturen: true,
      toevoegenAanOpdracht: true,
      trsOpdID: null,
      interneMutatie: false,
    };
  }, [ESoort.ZonderVerkoop]);

  const contractwisselKolommen = useMemo<IKolom<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        key: 'Opdrachtnummer',
        label: 'Opdr.nr.',
        breedte: 75,
        formatFabriek: (rij) => rij.Opdrachtnummer,
      },
      {
        key: 'Bezoekdatum',
        label: 'Bezoekdatum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum =
            rij.Bezoekdatum !== null
              ? format(new Date(rij.Bezoekdatum), 'dd-MM-yyyy')
              : 'Geen datum';
          return datum;
        },
      },
    ],
    [],
  );

  const validatieschema = Yup.object().shape({
    einddatum: Yup.date().required(),
    cntRdEndID: Yup.number().required(),
  });

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Beeïndigen contract</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        ref={formikRef}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        // validate={validatie}
        validationSchema={validatieschema}
        validateOnChange
        validateOnBlur
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>Aanvrager</label>
                      <Field
                        name="persID"
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <ContactSelectie
                                alleenVoorRelIDs={[relID]}
                                value={
                                  field.value === null
                                    ? null
                                    : {
                                        persID: field.value,
                                        orgID: null,
                                      }
                                }
                                onChange={(x) =>
                                  form.setFieldValue(field.name, x?.persoon?.PersID ?? null)
                                }
                                options={{
                                  types: [EType.Persoon],
                                  bijEnkeleDirectVoorselecteren: true,
                                }}
                              />
                              <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.cntRdEndID}</label>
                      <Field
                        name="cntRdEndID"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <Combobox<number>
                              geselecteerd={
                                fieldProps.field.value === null ? null : fieldProps.field.value
                              }
                              opties={redenenOpties === null ? [] : redenenOpties}
                              onSelectieChange={(id) =>
                                fieldProps.form.setFieldValue(fieldProps.field.name, id)
                              }
                              legeOptieTonen
                              options={{
                                legeOptieTekst: 'Geen reden',
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 mt-2 d-flex align-items-center">
                      <Field
                        name="interneMutatie"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <VinkVeld
                              aangevinkt={fieldProps.field.value}
                              onGewijzigd={(x) => {
                                fieldProps.form.setFieldValue(fieldProps.field.name, x);
                                fieldProps.form.setFieldValue(
                                  'bevestigingSturen',
                                  x ? false : true,
                                );
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                      <UitlegTooltip inhoud="Intern wil zeggen dat de mutatie op ons initiatief gedaan is">
                        <label className="ml-2 mt-1">{veldnamen.interneMutatie}</label>
                      </UitlegTooltip>
                    </div>

                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-end">
                        <div>
                          <label>{veldnamen.einddatum}</label>
                          <Field
                            name="einddatum"
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              const minimaleDatum = addDays(new Date(), -365);
                              // const maximaleDatum = addDays(new Date(), +365);
                              return (
                                <div className="d-flex align-items-center">
                                  <DatumKiezer
                                    onGewijzigd={(x) => {
                                      setEinddatum(x);
                                      form.setFieldValue(field.name, x);
                                    }}
                                    waarde={field.value}
                                    isClearable={true}
                                    determineValidDate={(date) => {
                                      return date >= minimaleDatum;
                                    }}
                                    determineNextValidDate={(date) => {
                                      const nieuweDatum = addDays(date, +1);
                                      return nieuweDatum;
                                    }}
                                    determinePreviousValidDate={(date) => {
                                      const nieuweDatum = addDays(date, -1);
                                      if (nieuweDatum >= minimaleDatum) {
                                        return nieuweDatum;
                                      }
                                      return null;
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                        {kostenVTB !== null && (
                          <div className="flex-fill ml-3 mb-2 d-flex align-items-center">
                            <span className="mr-1">Kosten VTB:</span>
                            <FormatteerBedrag bedrag={kostenVTB} />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row col-12">
                      <div className="col-3 mt-3 d-flex align-items-center">
                        <Field
                          name="retouropdracht"
                          render={(fieldProps: FieldProps<IFormikValues>) => (
                            <>
                              <VinkVeld
                                aangevinkt={fieldProps.field.value}
                                onGewijzigd={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                              <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                            </>
                          )}
                        />
                        <label className="ml-2 mt-1">{veldnamen.retouropdracht}</label>
                      </div>
                    </div>

                    {values.retouropdracht && (
                      <div className="col-12">
                        <div>
                          <label>{veldnamen.trsOpdID}</label>
                        </div>

                        <div>
                          <Field
                            name={nameOf<IFormikValues>('trsOpdID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <MultiCombobox<number, IOphalenOpdrachtenResultElement>
                                  sleutelExtractor={(row) => row.TrsOpdID}
                                  representatieFabriek={(row) =>
                                    row.Opdrachtnummer +
                                    ' - ' +
                                    (row.Bezoekdatum !== null
                                      ? format(new Date(row.Bezoekdatum), 'dd-MM-yyyy')
                                      : 'Geen datum') +
                                    ' - ' +
                                    row.locatie.Straatnaam +
                                    ' ' +
                                    row.locatie.Huisnummer +
                                    ' ' +
                                    row.locatie.Bisnummer
                                  }
                                  waarde={field.value}
                                  onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                  opties={opdrachten}
                                  kolommen={contractwisselKolommen}
                                  isWisbaar
                                  options={{
                                    geenWaardeBericht: 'Nieuwe opdracht',
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/* <div className="col-6">
                      <label>{veldnamen.kostenVTB}</label>
                      <Field
                        name="kostenVTB"
                        render={(fieldProps: FieldProps<IFormikValues>) => <>: {kostenVTB}</>}
                      />
                    </div> */}

                    <div className="col-12 mt-3 d-flex align-items-center">
                      <Field
                        name="bevestigingSturen"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <VinkVeld
                              aangevinkt={fieldProps.field.value}
                              onGewijzigd={(x) =>
                                fieldProps.form.setFieldValue(fieldProps.field.name, x)
                              }
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                      <label className="ml-2 mt-1">{veldnamen.bevestigingSturen}</label>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default BeeindigenContractDialoog;
