export type RapportageType =
  | 'OPENSTAANDE_DEBITEUREN'
  | 'OPENSTAANDE_CREDITEUREN'
  | 'GEGEVENS_TEN_BEHOEVE_VAN_JAARWERK'
  | 'WINST_EN_VERLIES_REKENING'
  | 'LIJST_ACTIVAREGISTER'
  | 'LIJST_PRODUCTEN_UIT_BEDRIJF';

export enum EFilter {
  Peildatum,
  Jaar,
  Periode,
  AlleenVervallenFacturen,
}

export type ExportMedium = 'PDF' | 'CSV';
