import React, { useCallback, useContext, useState } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { getIconFile, IconMap, IconToevoegen, IconVerwijderen } from '../../Icons';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import BestandenDragAndDropDialoog, {
  IBestandenDragAndDropDialoogResult,
} from '../../BestandenDragAndDropDialoog';
import { EBestandDragAndDropZoneBronModus } from '../../BestandDragAndDropZone';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';

interface IProps {
  onMapToevoegen: () => Promise<void>;
  magToevoegen?: boolean;
  onVerwijderen: () => Promise<void>;
  magVerwijderen?: boolean;
  onBestandenToegevoegd: (bestanden: IOphalenBestandenResultElement[]) => Promise<void>;
}

const BestandIconImg = getIconFile('application/octet-stream');

const Actiebalk = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [isBezig, setIsBezig] = useState(false);

  const handleBestandToevoegenClick = useCallback(async () => {
    setIsBezig(true);
    const result = await globaleRenderer.render<IBestandenDragAndDropDialoogResult>(
      (renderProps) => (
        <BestandenDragAndDropDialoog
          open
          onSuccess={(result) => renderProps.destroy(result)}
          onAnnuleren={() => renderProps.destroy()}
          bronModus={EBestandDragAndDropZoneBronModus.LokaleBestanden}
        />
      ),
    );
    if (result === undefined) {
      setIsBezig(false);
      return;
    }
    await props.onBestandenToegevoegd(result.bestanden);
    setIsBezig(false);
  }, [props.onBestandenToegevoegd]);

  return (
    <div className="d-flex align-items-center mt-2" style={{ columnGap: 15 }}>
      <button
        className="btn btn-sm btn-light d-flex align-items-center"
        style={{
          border: `1px solid ${Kleur.LichtGrijs}`,
        }}
        onClick={props.onMapToevoegen}
        disabled={isBezig || !(props.magToevoegen ?? true)}
      >
        <IconMap style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
        <IconToevoegen
          style={{
            fill: Kleur.Grijs,
            width: 13,
            height: 13,
            position: 'relative',
            top: -3,
            left: -1,
            marginRight: -3,
          }}
        />
        <span className="ml-1">Map toevoegen</span>
      </button>

      <button
        className="btn btn-sm btn-light d-flex align-items-center"
        style={{
          border: `1px solid ${Kleur.LichtGrijs}`,
        }}
        onClick={handleBestandToevoegenClick}
        disabled={isBezig}
      >
        <BestandIconImg style={{ fill: Kleur.Grijs, width: 15, height: 15 }} />
        <IconToevoegen
          style={{
            fill: Kleur.Grijs,
            width: 13,
            height: 13,
            position: 'relative',
            top: -3,
            left: -2,
            marginRight: -3,
          }}
        />
        <span className="ml-1">Bestand toevoegen</span>
      </button>

      <button
        className="btn btn-sm btn-light d-flex align-items-center"
        style={{
          border: `1px solid ${Kleur.LichtGrijs}`,
        }}
        onClick={props.onVerwijderen}
        disabled={isBezig || !(props.magVerwijderen ?? true)}
      >
        <IconVerwijderen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
        <span className="ml-1">Verwijderen</span>
      </button>
    </div>
  );
};

export default Actiebalk;
