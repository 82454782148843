import {
  IOphalenBestandenEnMappenResult,
  IOphalenBestandenResultElement,
  IOphalenBestandsmappenResultElement,
} from '../../../../shared/src/api/v2/bestand/bestand';
import api, { IPaginatiePositie } from '../../api';
import { naarVerkennerItemId } from './helpers';
import { EVerkennerItemType } from './TabelWeergave/types';
import { IBestandXBestandslabel } from '../../../../shared/src/api/v2/bestand/label';
import { IVerkennerItem } from './types';

export const bepalenVerkennerItemsVanMappenEnBestandenResult = async (
  result: IOphalenBestandenEnMappenResult,
  paginatie: IPaginatiePositie,
  toevoegenAanItems: Record<number, IVerkennerItem> = {},
): Promise<{ totaalAantal: number; items: Record<number, IVerkennerItem> }> => {
  const maakBestandenPromise = async (): Promise<IOphalenBestandenResultElement[]> => {
    const bestandIDs = result.resultaten.filter((x) => x.Soort === 'B').map((x) => x.ID);
    if (bestandIDs.length === 0) {
      return [];
    }

    const bestandenResult = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bestandIDs,
          },
        ],
      },
    });

    return bestandenResult.bestanden;
  };
  const maakBestandsmappenPromise = async (): Promise<IOphalenBestandsmappenResultElement[]> => {
    const bestandsmappenIDs = result.resultaten.filter((x) => x.Soort === 'M').map((x) => x.ID);
    if (bestandsmappenIDs.length === 0) {
      return [];
    }

    const bestandenResult = await api.v2.bestand.ophalenBestandsmappen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bestandsmappenIDs,
          },
        ],
      },
    });

    return bestandenResult.mappen;
  };
  const maakBestandslabelsPromise = async (): Promise<IBestandXBestandslabel[]> => {
    const bestandIDs = result.resultaten.filter((x) => x.Soort === 'B').map((x) => x.ID);
    if (bestandIDs.length === 0) {
      return [];
    }

    const r = await api.v2.bestand.label.ophalenBestandXBestandslabels({
      filterSchema: {
        filters: [
          {
            naam: 'BESTAND_IDS',
            data: bestandIDs,
          },
        ],
      },
    });

    return r.bestandXBestandslabels;
  };

  const [bestanden, bestandsmappen, bestandXBestandlabels]: [
    IOphalenBestandenResultElement[],
    IOphalenBestandsmappenResultElement[],
    IBestandXBestandslabel[],
  ] = await Promise.all([
    maakBestandenPromise(),
    maakBestandsmappenPromise(),
    maakBestandslabelsPromise(),
  ]);

  const items = result.resultaten.reduce<Record<number, IVerkennerItem>>((acc, resultaat, i) => {
    if (resultaat.Soort === 'B') {
      const bestand = bestanden.find((x) => x.ID === resultaat.ID)!;
      const labels = bestandXBestandlabels.filter((x) => x.BestandID === resultaat.ID);
      const id = naarVerkennerItemId(bestand.ID, EVerkennerItemType.Bestand);
      return {
        ...acc,
        [paginatie.index + i]: {
          type: EVerkennerItemType.Bestand,
          id,
          bestand,
          labels,
        },
      };
    } else if (resultaat.Soort === 'M') {
      const map = bestandsmappen.find((x) => x.ID === resultaat.ID)!;
      const id = naarVerkennerItemId(map.ID, EVerkennerItemType.Map);
      return {
        ...acc,
        [paginatie.index + i]: {
          type: EVerkennerItemType.Map,
          id,
          map,
        },
      };
    }

    throw new Error('Not implemented');
  }, toevoegenAanItems);

  return {
    items,
    totaalAantal: result.totaalAantal,
  };
};
