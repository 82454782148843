import React, { useMemo } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { format } from 'date-fns';

interface IProps {
  datum: Date;
  runDatum: Date;
}

const LaatstGesignaleerdWeergave: React.FC<IProps> = (props) => {
  const isLaatstGeconstateerd = useMemo(
    () => new Date(props.runDatum).getTime() === props.datum.getTime(),
    [props.runDatum, props.datum],
  );

  return (
    <span style={{ color: isLaatstGeconstateerd ? Kleur.Oranje : 'inherit' }}>
      {format(props.datum, 'dd-MM-yyyy HH:mm')}
    </span>
  );
};

export default LaatstGesignaleerdWeergave;
