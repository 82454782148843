import React from 'react';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenBetaalwijzenResultElement } from '../../../../../../shared/src/api/v2/betaalwijze';
import { IFormikValues } from '..';

export interface IContext {
  factuur: IOphalenFacturenResultElement | null;
  betaalwijzen: IOphalenBetaalwijzenResultElement[];
}

export const FactuurContext = React.createContext<IContext>({
  factuur: null,
  betaalwijzen: [],
});

export interface IFormulierContext {
  values: IFormikValues;
  setFieldValue: (field: string, value: any) => void;
}

export const FormulierContext = React.createContext<IFormulierContext>(null as any);

// export default FactuurContext;
