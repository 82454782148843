import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  Field,
  FieldProps,
  Formik,
  FormikActions,
  FormikErrors,
  FormikProps,
  yupToFormErrors,
} from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Combobox, { IOptie } from '../../../../../../components/formulier/Combobox';
import * as Yup from 'yup';
import api from '../../../../../../api';
import { IOphalenInkoopgegevensResult } from '../../../../../../../../shared/src/api/v2/inkoop/aanbod';
import { addDays, format } from 'date-fns';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';
import nameof from '../../../../../../core/nameOf';
import DatumKiezer from '../../../../../../components/formulier/DatumKiezer';
import BedragInput from '../../../../../../components/formulier/BedragInput';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import InactiefOverlay from '../../../../../../components/InactiefOverlay';
import { ValidationError } from 'yup';
import { dagDatum } from '../../../../../../helpers/datum';

interface IProps extends IDialoogProps<null> {
  TypeID: number;
  InkDienstID: number;
}

interface IFormikValues {
  directLeverbaar: boolean;
  datumVerwacht: Date | null;
  prioriteit: boolean;
  inkoopprijs: number | null;
  inkoopprijsActie: number | null;
  actieMinimaalAantal: number | null;
  isactieAangevinkt: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  directLeverbaar: 'Direct leverbaar',
  datumVerwacht: 'Datum Verwacht',
  prioriteit: 'Prioriteitsleverancier',
  inkoopprijs: 'Inkoopprijs',
  inkoopprijsActie: 'Actieprijs',
  actieMinimaalAantal: 'Minimaal aantal',
  isactieAangevinkt: 'Is actie?',
};

const InkoopgegevensDialoog: React.FC<IProps> = observer((props) => {
  const { onAnnuleren, onSuccess, TypeID, InkDienstID } = props;

  const [inkoopgegevens, setInkoopgegevens] = useState<IOphalenInkoopgegevensResult | null>(null);

  useEffect(() => {
    (async () => {
      const inkoopgegevens = await api.v2.inkoop.aanbod.ophalenInkoopgegevens({
        typeID: TypeID,
        inkDienstID: InkDienstID,
      });

      setInkoopgegevens(inkoopgegevens);
    })();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (inkoopgegevens == null) {
      return null;
    }

    return {
      directLeverbaar: inkoopgegevens.DirectLeverbaar,
      datumVerwacht:
        inkoopgegevens.DatumVerwacht !== null
          ? dagDatum(new Date(inkoopgegevens.DatumVerwacht))
          : null,
      prioriteit: inkoopgegevens.Prioriteit,
      inkoopprijs: inkoopgegevens.Inkoopprijs,
      inkoopprijsActie:
        inkoopgegevens.InkoopprijsActie !== null ? inkoopgegevens.InkoopprijsActie : 0,
      actieMinimaalAantal:
        inkoopgegevens.InkoopprijsActie !== null ? inkoopgegevens.ActieMinimaalAantal : 1,
      isactieAangevinkt:
        inkoopgegevens.InkoopprijsActie !== null && inkoopgegevens.InkoopprijsActie > 0
          ? true
          : false,
    };
  }, [inkoopgegevens]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      directLeverbaar: Yup.boolean(),
      datumVerwacht: Yup.date().nullable(),
      prioriteit: Yup.boolean(),
      inkoopprijs: Yup.number().nullable(),
      inkoopprijsActie: Yup.number().nullable(),
      actieMinimaalAantal: Yup.number().nullable(),
      isactieAangevinkt: Yup.boolean(),
    };
    return Yup.object()
      .shape(fields)
      .test('inkoopprijs-mag-niet-nul-zijn', '', (values: IFormikValues) => {
        if (values.isactieAangevinkt && values.inkoopprijsActie === 0) {
          return new ValidationError(
            'Inkoopprijs mag geen 0 zijn bij actie',
            null,
            nameof<IFormikValues>('inkoopprijs'),
          );
        }
        return false;
      });
  }, []);

  // const handleValidate = useCallback(
  //   (values: IFormikValues) => {
  //     let errors: FormikErrors<IFormikValues> = {};
  //
  //     try {
  //       const validationOutput = validationSchema.validateSync(values);
  //
  //     } catch (err) {
  //
  //       errors = {
  //         ...errors,
  //         ...err.errors,
  //       };
  //     }
  //
  //     if (values.isactieAangevinkt && values.isactieAangevinkt) {
  //       errors.inkoopprijsActie = 'Mag geen 0 zijn bij actie.';
  //     }
  //
  //     return errors;
  //   },
  //   [validationSchema],
  // );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const params = {
        typeID: TypeID,
        inkDienstID: InkDienstID,
        directLeverbaar: values.directLeverbaar,
        datumVerwacht:
          values.datumVerwacht !== null
            ? format(new Date(values.datumVerwacht), 'yyyy-MM-dd')
            : null,
        prioriteit: values.prioriteit,
        inkoopprijs: values.inkoopprijs,
        inkoopprijsActie: values.isactieAangevinkt ? values.inkoopprijsActie : null,
        actieMinimaalAantal: values.isactieAangevinkt ? values.actieMinimaalAantal : null,
      };

      await api.v2.inkoop.aanbod.wijzigenInkoopgegevens(params);

      onSuccess(null);
    },
    [],
  );

  return (
    <Dialoog index={0}>
      <ModalHeader>
        <ModalTitle>Wijzigen inkoopgegevens</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid, values, errors } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="row mt-2">
                    <div className="col-4">
                      <Field
                        name={nameof<IFormikValues>('directLeverbaar')}
                        render={(x: FieldProps<IFormikValues>) => {
                          return (
                            <div className="d-flex">
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                              <label className="ml-1">{veldnamen.directLeverbaar}</label>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <Field
                          name={nameof<IFormikValues>('datumVerwacht')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex">
                                <label className="mr-3">{veldnamen.datumVerwacht}</label>
                                <div className="position-relative" style={{ top: `-4px` }}>
                                  <DatumKiezer
                                    isClearable={true}
                                    waarde={field.value}
                                    onGewijzigd={(value) => form.setFieldValue(field.name, value)}
                                    determineValidDate={(date) => {
                                      return date >= new Date();
                                    }}
                                    determineNextValidDate="ONBEGRENST"
                                    determinePreviousValidDate={(date) => {
                                      const minDate = addDays(new Date(), -1);
                                      const newDate = addDays(date, -1);
                                      if (newDate >= minDate) {
                                        return newDate;
                                      }
                                      return null;
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <div className="form-group">
                        <label>{veldnamen.inkoopprijs}</label>
                        <Field
                          name={nameof<IFormikValues>('inkoopprijs')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <BedragInput
                                value={field.value}
                                onChange={(bedrag) => {
                                  form.setFieldValue(field.name, bedrag);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>{veldnamen.inkoopprijsActie}</label>
                        <div className="d-flex items-align-center">
                          <Field
                            name={nameof<IFormikValues>('isactieAangevinkt')}
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              return (
                                <div style={{ position: `relative`, top: `4px` }} className="mr-2">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(aangevinkt: boolean) =>
                                      form.setFieldValue(field.name, aangevinkt)
                                    }
                                  />
                                </div>
                              );
                            }}
                          />
                          <InactiefOverlay
                            isInactief={!values.isactieAangevinkt}
                            element={
                              <Field
                                name={nameof<IFormikValues>('inkoopprijsActie')}
                                render={({ field, form }: FieldProps<IFormikValues>) => {
                                  return (
                                    <BedragInput
                                      value={field.value}
                                      onChange={(bedrag) => {
                                        form.setFieldValue(field.name, bedrag);
                                      }}
                                    />
                                  );
                                }}
                              />
                            }
                          ></InactiefOverlay>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>{veldnamen.actieMinimaalAantal}</label>
                        <InactiefOverlay
                          isInactief={!values.isactieAangevinkt}
                          element={
                            <Field
                              name={nameof<IFormikValues>('actieMinimaalAantal')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                return (
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(bedrag) => {
                                      form.setFieldValue(field.name, bedrag);
                                    }}
                                  />
                                );
                              }}
                            />
                          }
                        ></InactiefOverlay>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <Field
                          name={nameof<IFormikValues>('prioriteit')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(aangevinkt: boolean) =>
                                    form.setFieldValue(field.name, aangevinkt)
                                  }
                                />
                                <label className="ml-3">{veldnamen.prioriteit}</label>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      onAnnuleren();
                    }}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default InkoopgegevensDialoog;
