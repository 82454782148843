import api from '../index';
import ICheckData from '../../../../shared/src/models/ICheckData';
import {
  IVerzoekOproepParams,
  IVerzoekOproepResult,
  IOphalenTelefoonOproepenParams,
  IOphalenTelefoonOproepenV2Params,
  IOphalenTelefoonOproepenResult,
  IOphalenTelefoonOproepenV2Result,
  IOphalenTelefoonoproepIndexenParams,
  IOphalenTelefoonoproepIndexenResult,
  IOphalenTelefoonEventsParams,
  IOphalenTelefoonEventsResult,
  IOphalenTelefoonNummersParams,
  IOphalenTelefoonNummersResult,
  IOphalenTelefoonOproepenVanPersonenParams,
  IOphalenTelefoonOproepenVanPersonenResult,
  IWijzigenTelefoonOproepParams,
  IWijzigenTelefoonOproepResult,
  IOphalenTelefoonPinsParams,
  IOphalenTelefoonPinsResult,
  IToevoegenTelefoonpinParams,
  IToevoegenTelefoonpinResult,
  IVerwijderenTelefoonpinsParams,
  IVerwijderenTelefoonpinsResult,
  IMarkerenTelefoonoproepAlsBeeindigdParams,
  IMarkerenTelefoonoproepAlsBeeindigdResult,
  IBijwerkenSorteerdatumTelefoonoproepParams,
  IBijwerkenSorteerdatumTelefoonoproepResult,
  OphalenTelefoonOproepenV2Keys,
  OphalenTelefoonNummersV2Keys,
  OphalenTelefoonEventsV2Keys,
  IInstellenVoipAccountVigerendParams,
  IInstellenVoipAccountVigerendResult,
  IOphalenTelefoonoproepContextenParams,
  IOphalenTelefoonoproepContextenResult,
  IOphalenTelefoonoproepXContextParams,
  IOphalenTelefoonoproepXContextResult,
  IToevoegenTelefoonoproepContextParams,
  IToevoegenTelefoonoproepContextResult,
  IToevoegenTelefoonoproepXContextParams,
  IToevoegenTelefoonoproepXContextResult,
  IVerwijderenTelefoonoproepXContextParams,
  IVerwijderenTelefoonoproepXContextResult,
  IEvent,
  IOphalenVoipAccountsResult,
  IOphalenVoipAccountsVigerendParams,
  IOphalenVoipAccountsVigerendResult,
  IOphalenVoipAccountsParams,
  IAspGebruikerBepalenViaTelefoonInternParams,
  IAspGebruikerBepalenViaTelefoonInternResult,
  IOphalenEntiteitResultElement,
  IOphalenTelefoonEventsResultElement,
  IOphalenTelefoonNummersResultElement,
  IOphalenTelefoonoproepContextenResultElement,
  IOphalenTelefoonOproepenV2ResultElement,
  IOphalenTelefoonoproepXContextResultElement,
  IOphalenTelefoonParams,
  IOproep,
  IOphalenTelefoonResult,
  ITelefoonPin,
  IOphalenTelefoonResultElement,
  IToevoegenTelefoonoproepResult,
  ITelefoonoproepBeeindigdMessageData,
  IToevoegenTelefoonoproepParams,
  IVerwijderenVoipAccountsVigerendParams,
  IVerwijderenVoipAccountsVigerendResult,
  IVoipAccountsVigerendVerwijderdMessageData,
  IVerzoekOproepResultData,
  IVoipAccount,
  IVoipAccountVigerend,
  IVoipAccountVigerendIngesteldMessageData,
  IVerwijderenTelefoonoproepContextenParams,
  IVerwijderenTelefoonoproepContextenResult,
  IWijzigenTelefoonoproepContextParams,
  IWijzigenTelefoonoproepContextResult,
} from '../../../../shared/src/api/v2/telefonie';

const telefonie = {
  verzoekOproep: async <TProviderData = any>(params: IVerzoekOproepParams) => {
    return api.post<IVerzoekOproepResult>('v2/telefonie/verzoek-oproep', params);
  },
  ophalenTelefoonOproepen: async (
    params: IOphalenTelefoonOproepenParams,
  ): Promise<IOphalenTelefoonOproepenResult> => {
    return api.post<IOphalenTelefoonOproepenResult>(
      'v2/telefonie/ophalen-telefoon-oproepen',
      params,
    );
  },
  ophalenTelefoonOproepenV2: async (
    params: IOphalenTelefoonOproepenV2Params,
  ): Promise<IOphalenTelefoonOproepenV2Result> => {
    return api.post<IOphalenTelefoonOproepenV2Result>(
      'v2/telefonie/ophalen-telefoon-oproepen-v2',
      params,
    );
  },
  ophalenTelefoonoproepIndexen: async (
    params: IOphalenTelefoonoproepIndexenParams,
  ): Promise<IOphalenTelefoonoproepIndexenResult> => {
    return api.post('v2/telefonie/ophalen-telefoonoproep-indexen', params);
  },
  ophalenTelefoonEvents: async (
    params: IOphalenTelefoonEventsParams,
  ): Promise<IOphalenTelefoonEventsResult> => {
    return api.post<IOphalenTelefoonEventsResult>('v2/telefonie/ophalen-telefoon-events', params);
  },
  ophalenTelefoonNummers: async (
    params: IOphalenTelefoonNummersParams,
  ): Promise<IOphalenTelefoonNummersResult> => {
    return api.post<IOphalenTelefoonNummersResult>('v2/telefonie/ophalen-telefoon-nummers', params);
  },
  ophalenTelefoonOproepenVanPersonen: async (
    params: IOphalenTelefoonOproepenVanPersonenParams,
  ): Promise<IOphalenTelefoonOproepenVanPersonenResult> => {
    return api.post<IOphalenTelefoonOproepenVanPersonenResult>(
      'v2/telefonie/ophalen-telefoon-oproepen-van-personen',
      params,
    );
  },
  wijzigenTelefoonOproep: async (
    params: IWijzigenTelefoonOproepParams,
  ): Promise<IWijzigenTelefoonOproepResult> => {
    return api.post<IWijzigenTelefoonOproepResult>('v2/telefonie/wijzigen-telefoon-oproep', params);
  },
  ophalenVoipAccounts: async (
    params: IOphalenVoipAccountsParams,
  ): Promise<IOphalenVoipAccountsResult> => {
    return api.post('v2/telefonie/ophalen-voip-accounts', params);
  },
  ophalenVoipAccountsVigerend: async (
    params: IOphalenVoipAccountsVigerendParams,
  ): Promise<IOphalenVoipAccountsVigerendResult> => {
    return api.post('v2/telefonie/ophalen-voip-accounts-vigerend', params);
  },
  instellenVoipAccountVigerend: async (
    params: IInstellenVoipAccountVigerendParams,
  ): Promise<IInstellenVoipAccountVigerendResult> => {
    return api.post('v2/telefonie/instellen-voip-account-vigerend', params);
  },
  ophalenTelefoonPins: async (
    params: IOphalenTelefoonPinsParams,
  ): Promise<IOphalenTelefoonPinsResult> => {
    return api.post('v2/telefonie/ophalen-telefoon-pins', params);
  },
  checkToevoegenTelefoonpin: async (params: IToevoegenTelefoonpinParams): Promise<ICheckData> => {
    return api.post('v2/telefonie/check-toevoegen-telefoonpin', params);
  },
  toevoegenTelefoonpin: async (
    params: IToevoegenTelefoonpinParams,
  ): Promise<IToevoegenTelefoonpinResult> => {
    return api.post('v2/telefonie/toevoegen-telefoonpin', params);
  },
  ophalenTelefoonoproepContexten: async (
    params: IOphalenTelefoonoproepContextenParams,
  ): Promise<IOphalenTelefoonoproepContextenResult> => {
    return api.post('v2/telefonie/ophalen-telefoonoproep-contexten', params);
  },
  ophalenTelefoonoproepXContext: async (
    params: IOphalenTelefoonoproepXContextParams,
  ): Promise<IOphalenTelefoonoproepXContextResult> => {
    return api.post('v2/telefonie/ophalen-telefoonoproep-x-context', params);
  },
  toevoegenTelefoonoproepContext: async (
    params: IToevoegenTelefoonoproepContextParams,
  ): Promise<IToevoegenTelefoonoproepContextResult> => {
    return api.post('v2/telefonie/toevoegen-telefoonoproep-context', params);
  },
  toevoegenTelefoonoproepXContext: async (
    params: IToevoegenTelefoonoproepXContextParams,
  ): Promise<IToevoegenTelefoonoproepXContextResult> => {
    return api.post('v2/telefonie/toevoegen-telefoonoproep-x-context', params);
  },
  verwijderenTelefoonoproepXContext: async (
    params: IVerwijderenTelefoonoproepXContextParams,
  ): Promise<IVerwijderenTelefoonoproepXContextResult> => {
    return api.post('v2/telefonie/verwijderen-telefoonoproep-x-context', params);
  },
  verwijderenTelefoonpins: async (
    params: IVerwijderenTelefoonpinsParams,
  ): Promise<IVerwijderenTelefoonpinsResult> => {
    return api.post('v2/telefonie/verwijderen-telefoonpins', params);
  },
  markerenTelefoonoproepAlsBeeindigd: async (
    params: IMarkerenTelefoonoproepAlsBeeindigdParams,
  ): Promise<IMarkerenTelefoonoproepAlsBeeindigdResult> => {
    return api.post('v2/telefonie/markeren-telefoonoproep-als-beeindigd', params);
  },
  checkVerwijderenTelefoonoproepContexten: async (
    params: IVerwijderenTelefoonoproepContextenParams,
  ): Promise<ICheckData> => {
    return api.post('v2/telefonie/check-verwijderen-telefoonoproep-contexten', params);
  },
  verwijderenTelefoonoproepContexten: async (
    params: IVerwijderenTelefoonoproepContextenParams,
  ): Promise<IVerwijderenTelefoonoproepContextenResult> => {
    return api.post('v2/telefonie/verwijderen-telefoonoproep-contexten', params);
  },
  checkWijzigenTelefoonoproepContext: async (
    params: IWijzigenTelefoonoproepContextParams,
  ): Promise<ICheckData> => {
    return api.post('v2/telefonie/check-wijzigen-telefoonoproep-context', params);
  },
  wijzigenTelefoonoproepContext: async (
    params: IWijzigenTelefoonoproepContextParams,
  ): Promise<IWijzigenTelefoonoproepContextResult> => {
    return api.post('v2/telefonie/wijzigen-telefoonoproep-context', params);
  },
};

export default telefonie;
