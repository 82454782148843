import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  IOphalenMutatiesResultElement,
  IVerwijderenMutatiesParams,
} from '../../../../../../../shared/src/api/v2/bank/mutaties';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../../../core/nameOf';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import api from '../../../../../api';
import { IconKruis, IconToevoegen, IconVink, IconWijzigen } from '../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import DetailComp from './DetailComp';
import { IDagboekenProvider } from '../../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/dagboeken';
import {
  IRegelsProvider,
  standaardRegelsProvider,
} from '../../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/regels';
import { IBoekdatumProvider } from '../../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/boekdatum';
import { dagDatum } from '../../../../../helpers/datum';
import { EDagboeksoort } from '../../../../../bedrijfslogica/enums';
import {
  ERegelboekstukType,
  ERegelstate,
  Regel,
} from '../../../../../components/boekhouding/boeking/BoekingDialoogV3/types';
import { maakNieuweRegel } from '../../../../../components/boekhouding/boeking/BoekingDialoogV3/helpers';
import BoekingDialoogV3 from '../../../../../components/boekhouding/boeking/BoekingDialoogV3';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import SelecteerBoekingVoorBoestukDialoog, {
  EBoekstukType,
  IDialoogOutput,
} from '../../../../../components/boekhouding/boeking/SelecteerBoekingVoorBoestukDialoog';
import WijzigenBankmutatieDialoog from '../../WijzigenBankmutatieDialoog';
import { IDagboekVoorselectieProvider } from '../../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/dagboekVoorselectie';
import { IOphalenRekeningenResultElement } from '../../../../../../../shared/src/api/v2/bank/bank';

interface IProps {
  mutaties: IOphalenMutatiesResultElement[];
  height: number;
  onVerversenAangevraagd: () => void;
  selectie: number[];
  onSelectieChange: (x: number[]) => void;
  uitgeklapt: number[];
  onUitgeklaptChange: (x: number[]) => void;
  gekozenRekening: IOphalenRekeningenResultElement | null;
}

interface IBoekingDialoogState {
  boekingID: number | null;
  bankMutID: number;
}

interface IBoekingdialoogProviders {
  dagboeken: IDagboekenProvider;
  regels: IRegelsProvider;
  boekdatum: IBoekdatumProvider;
  dagboekVoorselectie: IDagboekVoorselectieProvider;
}

interface ISaldoCorrigerenDialoogState {
  bankRekID: number;
}

interface IWijzigenBankmutatieDialoogState {
  bankMutID: number;
}

const geenData = {
  noData: 'Geen bankopdrachten',
};

const MutatiesTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [boekingdialoogState, setBoekingdialoogState] = useState<IBoekingDialoogState | null>(null);
  const [
    wijzigenBankmutatieDialoogState,
    setWijzigenBankmutatieDialoogState,
  ] = useState<IWijzigenBankmutatieDialoogState | null>(null);

  const [
    saldoCorrigerenDialoogState,
    setSaldoCorrigerenDialoogState,
  ] = useState<ISaldoCorrigerenDialoogState | null>(null);

  const keyExtractor = useCallback((row: IOphalenMutatiesResultElement) => row.BankMutID, []);
  const kolommen = useMemo<TypedColumn<IOphalenMutatiesResultElement>[]>(
    () => [
      {
        name: 'Mutatiedatum',
        title: 'Datum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Rekeningnaam',
        title: 'Naam',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__soort' as any,
        title: 'Bankopd.',
      },
      {
        name: '__geboekt' as any,
        title: 'Geboekt',
      },
      {
        name: '__boeking' as any,
        title: 'Boeking',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );
  const kolomBreedtes: Array<TypedTableColumnWidthInfo<IOphalenMutatiesResultElement>> = useMemo(
    () => [
      {
        columnName: 'Mutatiedatum',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Rekeningnaam',
        width: 275,
      },
      {
        columnName: 'Omschrijving',
        width: 500,
      },
      {
        columnName: '__soort' as any,
        width: 135,
      },
      {
        columnName: '__geboekt' as any,
        width: 100,
      },
      {
        columnName: '__boeking' as any,
        width: 125,
      },
      {
        columnName: 'Notities' as any,
        width: 200,
      },
    ],
    [],
  );
  const kolomExtensies: Array<VirtualTable.ColumnExtension> = useMemo(
    () => [
      // {
      //   columnName: nameof<IOphalenMutatiesResultElement>('Bedrag'),
      //   align: 'right',
      // },
    ],
    [],
  );

  const boekingdialoogProviders = useMemo<IBoekingdialoogProviders | null>(() => {
    if (boekingdialoogState === null || props.gekozenRekening === null) {
      return null;
    }

    return {
      boekdatum: {
        provide: async () => {
          const result = await api.v2.bank.mutatie.ophalenMutaties({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: [boekingdialoogState.bankMutID],
                },
              ],
            },
          });
          const mutatie = result.mutaties[0];

          return dagDatum(new Date(mutatie.Mutatiedatum));
        },
      },
      dagboeken: {
        provide: async () => {
          const result = await api.v2.boeking.dagboek.ophalenDagboeken({
            filterSchema: {
              filters: [
                {
                  naam: 'DAGBOEKSOORT_NAAM_ENUMS',
                  data: [EDagboeksoort.BANK],
                },
              ],
            },
          });
          return result.dagboeken;
        },
      },
      regels: {
        provide: async (boekingID, dagboek) => {
          if (boekingID === null) {
            const [grootboekenResult, mutatiesResult] = await Promise.all([
              api.v2.boeking.grootboek.ophalenGrootboeken({
                filterSchema: {
                  filters: [
                    {
                      naam: 'IDS',
                      data: [dagboek.Tegenrekening_GrbRekID!],
                    },
                  ],
                },
              }),
              api.v2.bank.mutatie.ophalenMutaties({
                filterSchema: {
                  filters: [
                    {
                      naam: 'IDS',
                      data: [boekingdialoogState.bankMutID],
                    },
                  ],
                },
              }),
            ]);

            const mutatie = mutatiesResult.mutaties[0];
            const grootboek = grootboekenResult.grootboeken[0];

            const regel: Regel = {
              ...maakNieuweRegel(),
              state: ERegelstate.Weergave,
              bedrag: mutatie.Bedrag,
              grootboekID: grootboek.ID,
              regelboekstuk: {
                type: ERegelboekstukType.Bankmutatie,
                bankMutID: mutatie.BankMutID,
              },
            };

            return [
              regel,
              {
                ...maakNieuweRegel(),
                bedrag: -regel.bedrag,
              },
            ];
          }

          return await standaardRegelsProvider.provide(boekingID, dagboek);
        },
      },
      dagboekVoorselectie: {
        provide: async (dagboeken) => {
          return props.gekozenRekening!.DagboekID;
        },
      },
    };
  }, [boekingdialoogState, props.gekozenRekening]);

  return (
    <>
      <GridStyleWrapper height={props.height}>
        <Grid getRowId={keyExtractor} rows={props.mutaties} columns={kolommen}>
          <DataTypeProvider
            for={[nameof<IOphalenMutatiesResultElement>('Mutatiedatum')]}
            formatterComponent={(formatterProps) => {
              const datum = new Date(formatterProps.value);
              return <span>{format(datum, 'dd-MM-yyyy')}</span>;
            }}
          />
          <DataTypeProvider
            for={[nameof<IOphalenMutatiesResultElement>('Bedrag')]}
            formatterComponent={(formatterProps) => {
              const bedrag: number = formatterProps.value;
              return <FormatteerBedrag bedrag={bedrag} />;
            }}
          />

          {/* <DataTypeProvider
            for={[nameof<IOphalenMutatiesResultElement>('Definitief')]}
            formatterComponent={(formatterProps) => {
              const isDefinitief: boolean = formatterProps.value;
              return <span>{isDefinitief ? 'Definitief' : 'Concept'}</span>;
            }}
          /> */}

          <DataTypeProvider
            for={['__soort']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenMutatiesResultElement = formatterProps.row;

              if (rij.BankBatchID !== null) {
                return rij.Bedrag > 0 ? <span>Incasso</span> : <span>VZ-Betaling</span>;
              }
              if (rij.BankOpdID !== null) {
                return rij.Bedrag > 0 ? <span>Storno (VZ)</span> : <span>Storno</span>;
              }

              return <span></span>;
            }}
          />

          <DataTypeProvider
            for={['__geboekt']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenMutatiesResultElement = formatterProps.row;

              if (rij.Geboekt) {
                return (
                  <IconVink
                    style={{
                      width: 18,
                      height: 18,
                      fill: EKleur.Groen,
                    }}
                  />
                );
              }

              return (
                <IconKruis
                  style={{
                    width: 18,
                    height: 18,
                    fill: EKleur.Rood,
                  }}
                />
              );

              // return (
              //   <IconVink
              //     style={{
              //       width: 18,
              //       height: 18,
              //       fill: rij.boeking!.Definitief ? EKleur.Groen : EKleur.Blauw,
              //     }}
              //   />
              // );
            }}
          />

          <DataTypeProvider
            for={['__boeking']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenMutatiesResultElement = formatterProps.row;

              return (
                <a
                  href="#"
                  onClick={async (ev) => {
                    ev.preventDefault();

                    const boekingenResult = await api.v2.boeking.ophalenBoekingen({
                      filterSchema: {
                        filters: [
                          {
                            naam: 'BOEKSTUK_BANK_MUT_IDS',
                            data: [rij.BankMutID],
                          },
                        ],
                      },
                    });

                    let boekingID: number | null = null;
                    if (boekingenResult.boekingen.length === 1) {
                      boekingID = boekingenResult.boekingen[0].ID;
                    } else if (boekingenResult.boekingen.length > 1) {
                      const output = await globaleRenderer.render<IDialoogOutput | null>(
                        (renderProps) => (
                          <SelecteerBoekingVoorBoestukDialoog
                            boekstuk={{
                              type: EBoekstukType.Bank,
                              bankMutID: rij.BankMutID,
                            }}
                            boekingen={boekingenResult.boekingen}
                            open
                            onSuccess={(r) => renderProps.destroy(r)}
                            onAnnuleren={() => renderProps.destroy(null)}
                          />
                        ),
                      );
                      if (output !== null) {
                        boekingID = output!.boekingID;
                      }
                    }

                    setBoekingdialoogState({
                      boekingID,
                      bankMutID: rij.BankMutID,
                    });
                  }}
                >
                  Muteren
                </a>
              );
            }}
          />

          <SortingState defaultSorting={[]} />
          <IntegratedSorting />

          <EditingState
            onCommitChanges={async (changeset) => {
              if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                const ids = changeset.deleted as number[];

                const params: IVerwijderenMutatiesParams = { bankMutIDs: ids };
                const checkData = await api.v2.bank.mutatie.checkVerwijderenMutaties(params);
                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }
                if (
                  (await checkStore.bevestigen({ inhoud: 'Verwijderen?' })).type ===
                  EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.bank.mutatie.verwijderenMutaties(params);
                props.onVerversenAangevraagd();
              }
            }}
            onEditingRowIdsChange={(x) => {
              const id = x[x.length - 1] as number;
              setWijzigenBankmutatieDialoogState({ bankMutID: id });
            }}
          />

          <SelectionState
            selection={props.selectie}
            onSelectionChange={(x) => props.onSelectieChange(x as number[])}
          />
          <RowDetailState
            expandedRowIds={props.uitgeklapt}
            onExpandedRowIdsChange={(x) => props.onUitgeklaptChange(x as number[])}
          />

          <VirtualTable columnExtensions={kolomExtensies} messages={geenData} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <TableHeaderRow showSortingControls />

          <TableEditColumn
            width={65}
            showDeleteCommand
            showEditCommand
            cellComponent={DXTableEditColumnCellComponent}
            commandComponent={DXTableEditColumnCommandComponent}
          />

          <TableRowDetail
            contentComponent={DetailComp}
            toggleCellComponent={DXTableToggleCellComponent}
          />
          <TableSelection cellComponent={DXTableCheckboxComponent} />
        </Grid>
      </GridStyleWrapper>

      {boekingdialoogState !== null && boekingdialoogProviders !== null && (
        <BoekingDialoogV3
          open
          onSuccess={async () => {
            setBoekingdialoogState(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => setBoekingdialoogState(null)}
          boekingID={boekingdialoogState.boekingID ?? undefined}
          dagboekenProvider={boekingdialoogProviders.dagboeken}
          regelsProvider={boekingdialoogProviders.regels}
          boekdatumProvider={boekingdialoogProviders.boekdatum}
          dagboekVoorselectieProvider={boekingdialoogProviders.dagboekVoorselectie}
        />
      )}
      {wijzigenBankmutatieDialoogState !== null && (
        <WijzigenBankmutatieDialoog
          open
          id={wijzigenBankmutatieDialoogState.bankMutID}
          onSuccess={async (result) => {
            setWijzigenBankmutatieDialoogState(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => setWijzigenBankmutatieDialoogState(null)}
        />
      )}
    </>
  );
});

export default MutatiesTabel;
