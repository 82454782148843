import React, { useCallback, useMemo, useRef, useState, useEffect, useContext } from 'react';
import GeslachtSelectie from '../GeslachtSelectie';
import TaalSelectie from '../TaalSelectie';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import VinkVeld from '../VinkVeld';
import DatumKiezer from '../DatumKiezer';
import { addYears } from 'date-fns';
import api from '../../../api';
import Combobox from '../Combobox';
import TelefoonnummerInput, {
  genereerRecenteOproepenSuggestiesProvider,
  ISuggestiesProvider,
} from '../TelefoonnummerInput';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';

export interface IFormValues {
  email: string;
  telefoon: string | null;
  telefoonExtra: string | null;
  achternaam: string;
  voorletters: string;
  voorvoegsel: string;
  voornaam: string;
  geslacht: number | null;
  taalID: number | null;
  geboortedatum: Date | null;

  geboorteplaats: string;
  overleden: boolean;
  aanheftype: number;
  notities: string;
}

export const defaultFormValues: IFormValues = {
  email: '',
  telefoon: null,
  telefoonExtra: null,
  achternaam: '',
  voorletters: '',
  voorvoegsel: '',
  voornaam: '',
  geslacht: null,
  taalID: null,
  geboortedatum: null,
  geboorteplaats: '',
  overleden: false,
  aanheftype: 0,
  notities: '',
};

interface IProps {
  values: IFormValues;
  onChange: (values: IFormValues) => void;
  onIsValidChange: (valid: boolean) => void;
}

const veldnamen = {
  email: 'Email',
  telefoon: 'Telefoon',
  telefoonExtra: 'Telefoon extra (NIET van huisgenoot)',
  achternaam: 'Achternaam',
  voorletters: 'Voorletters',
  voorvoegsel: 'Voorvoegsel',
  voornaam: 'Voornaam',
  geslacht: 'Geslacht',
  taalID: 'Taal',
  geboortedatum: 'Geboortedatum',
  geboorteplaats: 'Geboorteplaats',
  overleden: 'Overleden',
  aanheftype: 'Aanheftype',
  notities: 'Notities',
};

const minimaleGeboortedatum = addYears(new Date(), -16);

const PersoonFormulier: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const { values } = props;
  const {
    achternaam,
    email,
    geslacht,
    taalID,
    telefoon,
    telefoonExtra,
    voorletters,
    voornaam,
    voorvoegsel,
    geboortedatum,
    geboorteplaats,
    overleden,
    aanheftype,
    notities,
  } = values;

  const [aanheftypes, setAanheftypes] = useState<any[] | null>(null);

  const currentValues = useRef<IFormValues>(props.values);

  useEffect(() => {
    (async () => {
      if (values.geslacht === null) {
        return;
      }

      const resultaat = await api.v2.persoon.ophalenGeslachten({
        filterSchema: {
          filters: [
            {
              naam: 'GESLACHT',
              data: values.geslacht,
            },
          ],
        },
      });
      const geslacht = resultaat.geslachten[0];

      const aanheftypes = [
        {
          id: 0,
          label: 'Standaard',
        },
        {
          id: 1,
          label: `${geslacht.AanhefTitel} ${values.achternaam}`,
        },
        {
          id: 2,
          label: `${values.voornaam}`,
        },
        {
          id: 3,
          label: `${values.voornaam}${
            values.voorvoegsel !== null ? ' ' + values.voorvoegsel : ''
          } ${values.achternaam}`,
        },
      ];

      setAanheftypes(aanheftypes);
    })();
  }, [values.geslacht]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(
          teksten.formulier.E_EMAIL_VELD({
            veldnaam: veldnamen.email,
          }),
        ),
        telefoon: Yup.string().nullable(),
        telefoonExtra: Yup.string().nullable(),
        voorvoegsel: Yup.string(),
        achternaam: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.achternaam,
          }),
        ),
        voornaam: Yup.string(),
        voorletters: Yup.string(),
        geslacht: Yup.number(),
        taalID: Yup.number(),
        geboorteplaats: Yup.string(),
        overleden: Yup.bool(),
        notities: Yup.string(),
      }),
    [],
  );

  const onChange = useCallback(
    async (v: IFormValues) => {
      // @ts-ignore
      currentValues.current = v;
      props.onChange(v);
      const isValid = await validationSchema.isValid(v);
      props.onIsValidChange(isValid);
    },
    [props.onChange, props.onIsValidChange, validationSchema],
  );

  const [
    telefoonnummerInputSuggestiesProvider,
    setTelefoonnummerInputSuggestiesProvider,
  ] = useState<ISuggestiesProvider | null>(null);

  useEffect(() => {
    if (gebruikerStore.gebruiker === null) {
      return;
    }

    genereerRecenteOproepenSuggestiesProvider(gebruikerStore.gebruiker.AspGebrID).then((provider) =>
      setTelefoonnummerInputSuggestiesProvider(provider),
    );
  }, [gebruikerStore.gebruiker]);

  const handleTelefoonInputChange = useCallback(
    (x: string | null) => {
      onChange({
        ...currentValues.current!,
        telefoon: x,
      });
    },
    [onChange],
  );

  const handleTelefoonExtraInputChange = useCallback(
    (x: string | null) => {
      onChange({
        ...currentValues.current!,
        telefoonExtra: x,
      });
    },
    [onChange],
  );

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-6">
          <label>{veldnamen.voornaam}</label>
          <input
            className="form-control"
            autoFocus
            type="text"
            value={voornaam}
            onChange={async (ev) => {
              const voornaam = ev.target.value;
              if (voornaam.length === 1) {
                const firstChar = voornaam.substr(0, 1);
                values.voorletters = `${firstChar.toUpperCase()}.`;
              }

              onChange({
                ...values,
                voornaam,
              });
            }}
            // onBlur={async () => {
            //   const result = await api.v2.persoon.ophalenGeslachtssuggestie({
            //     voornaam: values.voornaam,
            //   });
            //   if (result.geslacht === null) return;
            //   onChange({
            //     ...currentValues.current!,
            //     geslacht: result.geslacht,
            //   });
            // }}
            placeholder="Jan"
          />
        </div>
        <div className="col-3">
          <label>{veldnamen.voorletters}</label>
          <input
            className="form-control"
            type="text"
            value={voorletters}
            onChange={(ev) =>
              onChange({
                ...values,
                voorletters: ev.target.value,
              })
            }
            placeholder="J."
          />
        </div>
        <div className="col-3">
          <label>{veldnamen.geslacht}</label>
          <GeslachtSelectie
            geslacht={geslacht}
            onChange={(x) =>
              onChange({
                ...values,
                geslacht: x,
              })
            }
          />
        </div>

        <div className="col-3 mt-3">
          <label>{veldnamen.voorvoegsel}</label>
          <input
            className="form-control"
            type="text"
            value={voorvoegsel}
            onChange={(ev) =>
              onChange({
                ...values,
                voorvoegsel: ev.target.value,
              })
            }
          />
        </div>
        <div className="col-9 mt-3">
          <label>{veldnamen.achternaam}</label>
          <input
            className="form-control"
            type="text"
            value={achternaam}
            onChange={(ev) =>
              onChange({
                ...values,
                achternaam: ev.target.value,
              })
            }
            placeholder="Jansen"
          />
        </div>

        <div className="col-6 mt-3">
          <label>{veldnamen.email}</label>
          <input
            className="form-control"
            type="email"
            value={email}
            onChange={(ev) =>
              onChange({
                ...values,
                email: ev.target.value,
              })
            }
            placeholder="jan.jansen@gmail.com"
          />
        </div>

        <div className="col-6 mt-3">
          <label>{veldnamen.taalID}</label>
          <TaalSelectie
            taalID={taalID}
            onChange={(x) =>
              onChange({
                ...values,
                taalID: x,
              })
            }
            options={{ standaardVoorselecteren: true }}
          />
        </div>

        <div className="col-6 mt-3">
          <label>{veldnamen.telefoon}</label>
          {telefoonnummerInputSuggestiesProvider === null ? (
            <LoadingSpinner />
          ) : (
            <TelefoonnummerInput
              telefoonnummer={telefoon}
              onChange={handleTelefoonInputChange}
              suggestiesProvider={telefoonnummerInputSuggestiesProvider}
            />
          )}
          {/*<input*/}
          {/*  className="form-control"*/}
          {/*  type="text"*/}
          {/*  value={telefoon}*/}
          {/*  onChange={(ev) =>*/}
          {/*    onChange({*/}
          {/*      ...values,*/}
          {/*      telefoon: ev.target.value,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  placeholder="0612345678"*/}
          {/*/>*/}
        </div>

        <div className="col-6 mt-3">
          <label>{veldnamen.telefoonExtra}</label>
          {telefoonnummerInputSuggestiesProvider === null ? (
            <LoadingSpinner />
          ) : (
            <TelefoonnummerInput
              telefoonnummer={telefoonExtra}
              onChange={handleTelefoonExtraInputChange}
              suggestiesProvider={telefoonnummerInputSuggestiesProvider}
            />
          )}
          {/*<input*/}
          {/*  className="form-control"*/}
          {/*  type="text"*/}
          {/*  value={telefoon}*/}
          {/*  onChange={(ev) =>*/}
          {/*    onChange({*/}
          {/*      ...values,*/}
          {/*      telefoon: ev.target.value,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  placeholder="0612345678"*/}
          {/*/>*/}
        </div>

        <div className="col-6 mt-3">
          <label>{veldnamen.geboortedatum}</label>
          <DatumKiezer
            waarde={geboortedatum === null ? null : new Date(geboortedatum)}
            onGewijzigd={(x) =>
              onChange({
                ...values,
                geboortedatum: x as any,
              })
            }
            determineValidDate={(date) => {
              return date < minimaleGeboortedatum;
            }}
            isClearable
          />
        </div>

        <div className="col-6 mt-3">
          <label>&nbsp;</label>
          <div className="d-flex align-items-center">
            <VinkVeld
              aangevinkt={overleden}
              onGewijzigd={(x) =>
                onChange({
                  ...values,
                  overleden: x,
                })
              }
            />
            <span className="ml-2">{veldnamen.overleden}</span>
          </div>
        </div>

        <div className="col-6 mt-3">
          <label>{veldnamen.geboorteplaats}</label>
          <input
            className="form-control"
            type="text"
            value={geboorteplaats}
            onChange={(ev) =>
              onChange({
                ...values,
                geboorteplaats: ev.target.value,
              })
            }
          />
        </div>
        <div className="col-6 mt-3">
          {aanheftypes !== null && (
            <>
              <label>{veldnamen.aanheftype}</label>
              <Combobox
                onSelectieChange={(x) => {
                  onChange({
                    ...values,
                    aanheftype: x === null ? 0 : x,
                  });
                }}
                geselecteerd={aanheftype}
                opties={aanheftypes!}
              />
            </>
          )}
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.notities}</label>
          <textarea
            className="form-control"
            // type="text"
            value={notities}
            onChange={(ev) =>
              onChange({
                ...values,
                notities: ev.target.value,
              })
            }
            rows={2}
          />
        </div>
      </div>
    </div>
  );
});

export default PersoonFormulier;
