import { makeObservable, observable } from 'mobx';
import api from '../api';
import {
  IOphalenProductsoortenResult,
  IToevoegenProductsoortParams,
  IOphalenProductsoortenResultElement,
  IWijzigenProductsoortParams,
} from '../../../shared/src/api/v2/product/soort';
import { herorderArraySortNr } from '../helpers/tabel';

// tslint:disable no-console
class ProductsoortenStore {
  public productsoorten: IOphalenProductsoortenResult | null = null;
  public isAanHetLaden: boolean = false;
  public geselecteerdeProductsoort: IOphalenProductsoortenResultElement | null = null;
  private laastOpgehaaldeFilter?: string;

  constructor() {
    makeObservable(this, {
      productsoorten: observable,
      geselecteerdeProductsoort: observable,
      isAanHetLaden: observable,
    });
  }

  public herordenenProductsoorten = async (oldIndex: number, newIndex: number) => {
    const { newArr: nieuweProductsoorten, sortNrWijziging } = herorderArraySortNr(
      this.productsoorten!,
      oldIndex,
      newIndex,
    );

    this.productsoorten = nieuweProductsoorten;

    try {
      await api.v2.productsoort.herordenenLijst({
        nieuwSortNr: sortNrWijziging.nieuwSortNr,
        huidigSortNr: sortNrWijziging.huidigSortNr,
      });
      await this.ophalenProductsoorten(this.laastOpgehaaldeFilter);
    } catch (error) {
      console.error(error);
    }
  };

  public resetGeselecteerdeProductsoort = () => {
    this.geselecteerdeProductsoort = null;
  };

  public ophalenProductsoorten = async (filter?: string) => {
    this.laastOpgehaaldeFilter = filter;
    this.isAanHetLaden = true;
    try {
      const param: any = filter === 'ACTIEF' ? true : null;
      const response = await api.v2.productsoort.ophalenProductsoorten({
        filterSchema: { filters: [{ naam: 'ACTIEF', data: param }] },
      });
      this.productsoorten = response;
    } catch (error) {
      console.log(error);
    }
  };

  public toevoegenProductsoort = async (params: IToevoegenProductsoortParams, filter: string) => {
    try {
      await api.v2.productsoort.toevoegenProductsoort(params);
      this.ophalenProductsoorten(filter);
    } catch (err) {
      throw err;
    }
  };

  public ophalenProductsoort = async (id: number) => {
    const params = {
      prodSrtID: id,
    };
    this.isAanHetLaden = true;
    try {
      const response = (
        await api.v2.productsoort.ophalenProductsoorten({
          filterSchema: { filters: [{ naam: 'IDS', data: [id] }] },
        })
      )[0];
      this.geselecteerdeProductsoort = response;
      this.isAanHetLaden = false;
    } catch (error) {
      console.log(error);
    }
  };

  public verwijderenProductsoort = async (id: number, filter: string) => {
    const params = {
      prodSrtIDs: [id],
    };
    try {
      await api.v2.productsoort.verwijderenProductsoorten(params);
      this.ophalenProductsoorten(filter);
    } catch (err) {
      throw err;
    }
  };

  public wijzigenProductsoort = async (params: IWijzigenProductsoortParams, filter: string) => {
    try {
      await api.v2.productsoort.wijzigenProductsoort(params);
      this.ophalenProductsoorten(filter);
      this.geselecteerdeProductsoort = null;
    } catch (err) {
      throw err;
    }
  };
}

export default ProductsoortenStore;
