import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, ProductContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import GegevensLayout from '../../../layout/GegevensLayout';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';

interface IProducttype {
  PrioNr: number;
  Merknaam: string;
  Typenaam: string;
  Kenmerk: string | null;
  InkoopToegestaan: boolean;
}

const DetailTab: React.FC<IProps> = (props) => {
  const { productmodel, bedrijf } = useContext<IContext>(ProductContext);

  const producttypen =
    productmodel !== null
      ? productmodel.producttypen.map((x) => {
          return {
            PrioNr: x.PrioNr,
            Merknaam: x.Merknaam,
            Typenaam: x.Typenaam,
            Kenmerk: x.Kenmerk,
            InkoopToegestaan: x.InkoopToegestaan,
          };
        })
      : [];

  const kolommen = useMemo<TypedColumn<IProducttype>[]>(
    () => [
      {
        name: 'PrioNr',
        title: 'Prio.',
      },
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
      {
        name: 'InkoopToegestaan',
        title: 'IW',
      },
    ],
    [],
  );

  const columnExtensions = useMemo<Table.ColumnExtension[]>(
    () => [
      {
        columnName: 'PrioNr',
        width: 50,
      },
      {
        columnName: 'Merknaam',
        width: 90,
      },
      {
        columnName: 'Typenaam',
        width: 135,
      },
      {
        columnName: 'Kenmerk',
        width: 175,
      },
      {
        columnName: 'InkoopToegestaan',
        width: 50,
      },
    ],
    [],
  );

  return (
    <>
      {productmodel === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="d-flex flex-column">
            <div className="row mt-3">
              <div className="col-12 d-flex">
                <div className="col-9">
                  <VeldWeergave>
                    <div>
                      {productmodel.Modelcode} - {productmodel.Modelnaam}
                    </div>
                  </VeldWeergave>
                </div>
                <div className="col-3">
                  <VeldWeergave>
                    <div>
                      {productmodel.Actief ? (
                        <span style={{ color: Kleur.Groen }}>Actief</span>
                      ) : (
                        <span style={{ color: Kleur.Rood }}>Niet actief</span>
                      )}
                    </div>
                  </VeldWeergave>
                </div>
              </div>

              {bedrijf !== null && (
                <div className="col-12">
                  <div className="col-12">
                    <VeldWeergave>
                      <div>
                        <a
                          href="#"
                          className="hover-only"
                          onClick={(ev) => {
                            ev.stopPropagation();

                            // const url = `http://localhost:81/nl/product/${productmodel.ProdModID}`;
                            const url = `${bedrijf.website.url}/product/${productmodel.ProdModID}`;

                            window.open(url, '_blank');
                          }}
                        >
                          Ga naar productdetailpagina op de website
                        </a>
                      </div>
                    </VeldWeergave>
                  </div>
                </div>
              )}

              <div className="col-12 d-flex mt-3">
                <div className="col-8">
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Merk',
                          waarde: productmodel.Huismodel ? '-' : productmodel.Productmerknaam,
                        },
                        {
                          label: 'Kenmerk',
                          waarde: productmodel.Kenmerk,
                        },
                        null,
                      ]}
                    />
                  </VeldWeergave>
                </div>
                <div className="col-4">
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Staat',
                          waarde: productmodel.GebruiktProduct ? 'Gebruikt' : 'Nieuw',
                        },
                        null,
                        null,
                      ]}
                    />
                  </VeldWeergave>
                </div>
              </div>

              <div className="col-12 d-flex mt-3">
                <div className="col-6">
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Max. gebr. Tarief',
                          waarde: (
                            <span>
                              {productmodel.AantalGebruikersTarief !== null
                                ? productmodel.AantalGebruikersTarief
                                : productmodel.productsoort.AantalGebruikersTarief}
                            </span>
                          ),
                        },
                        {
                          label: 'Max. toegestaan',
                          waarde: (
                            <span>
                              {productmodel.AantalGebruikersMax !== null
                                ? productmodel.AantalGebruikersMax
                                : productmodel.productsoort.AantalGebruikersMax}
                            </span>
                          ),
                        },
                        null,
                      ]}
                    />
                  </VeldWeergave>
                </div>
                <div className="col-6">
                  <VeldWeergave>
                    <GegevensLayout gegevens={[null, null, null]} />
                  </VeldWeergave>
                </div>
              </div>
            </div>

            {/* <div className="mt-2 pl-3 pr-3">
              <VeldWeergave>
                <div className="p-1 font-weight-bold">Gekoppelde producttypen</div>
                <div className="mt-1" style={{ backgroundColor: Kleur.Wit }}>
                  <GridStyleWrapper maxHeight={250} rowAmount={producttypen.length}>
                    <Grid rows={producttypen} columns={kolommen}>
                      <DataTypeProvider
                        for={[nameOf<IProducttype>('InkoopToegestaan')]}
                        formatterComponent={(formatterProps) => {
                          return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                        }}
                      />
                      <Table columnExtensions={columnExtensions} />
                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </VeldWeergave>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default DetailTab;
