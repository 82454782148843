import {
  IBijwerkenContactParams,
  IBijwerkenContactResult,
  IOphalenBerichtenParams,
  IOphalenBerichtenResult,
  IOphalenBerichtenVoorContactParams,
  IOphalenBerichtenVoorContactResult,
  IOphalenContactenParams,
  IOphalenContactenV2Params,
  IOphalenContactenV2Result,
  IOphalenContactenVoorZoektermParams,
  IOphalenKlantkaartContactenParams,
  IOphalenRijbronVoorSmsOpstellenParams,
  IOphalenRijbronVoorSmsOpstellenResult,
  IVersturenBijNieuwContactParams,
  IVersturenBijNieuwContactResult,
  IVersturenSMSBerichtParams,
  IVersturenSMSBerichtResult,
  OphalenContactenResult,
} from '../../../../../shared/src/api/v2/sms/Sms';

import {
  IOphalenDienstenParams,
  IOphalenDienstenResult,
  IWijzigenDienstParams,
  IWijzigenDienstResult,
} from '../../../../../shared/src/api/v2/dienst/sms/sms';

import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const sms = {
  ophalenBerichten: async (params: IOphalenBerichtenParams): Promise<IOphalenBerichtenResult> => {
    return await api.post('/v2/dienst/sms/ophalen-berichten', params);
  },
  async ophalenContacten(params: IOphalenContactenParams): Promise<OphalenContactenResult> {
    return await api.post('/v2/dienst/sms/ophalen-contacten', params);
  },
  async ophalenContactenV2(params: IOphalenContactenV2Params): Promise<IOphalenContactenV2Result> {
    return await api.post('/v2/dienst/sms/ophalen-contacten-v2', params);
  },
  async ophalenContactenVoorZoekterm(
    params: IOphalenContactenVoorZoektermParams,
  ): Promise<OphalenContactenResult> {
    return await api.post('/v2/dienst/sms/ophalen-contacten-voor-zoekterm', params);
  },
  async ophalenKlantkaartContacten(
    params: IOphalenKlantkaartContactenParams,
  ): Promise<OphalenContactenResult> {
    return await api.post('/v2/dienst/sms/ophalen-klantkaart-contacten', params);
  },
  async ophalenBerichtenVoorContact(
    params: IOphalenBerichtenVoorContactParams,
  ): Promise<IOphalenBerichtenVoorContactResult> {
    return await api.post('/v2/dienst/sms/ophalen-berichten-voor-contact', params);
  },
  async versturenBericht(params: IVersturenSMSBerichtParams): Promise<IVersturenSMSBerichtResult> {
    return await api.post('/v2/dienst/sms/versturen-bericht', params);
  },
  async ophalenRijbronVoorSmsOpstellen(
    params: IOphalenRijbronVoorSmsOpstellenParams,
  ): Promise<IOphalenRijbronVoorSmsOpstellenResult> {
    return await api.post('/v2/dienst/sms/ophalen-rijbron-voor-sms-opstellen', params);
  },
  async versturenBijNieuwContact(
    params: IVersturenBijNieuwContactParams,
  ): Promise<IVersturenBijNieuwContactResult> {
    return await api.post('/v2/dienst/sms/versturen-bij-nieuw-contact', params);
  },
  async bijwerkenContact(params: IBijwerkenContactParams): Promise<IBijwerkenContactResult> {
    return await api.post('/v2/dienst/sms/bijwerken-contact', params);
  },
  ophalenDiensten: async (params: IOphalenDienstenParams): Promise<IOphalenDienstenResult> => {
    return await api.post('v2/dienst/sms/ophalen-diensten', params);
  },

  checkWijzigenDienst: async (params: IWijzigenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/sms/check-wijzigen-dienst', params);
  },
  wijzigenDienst: async (params: IWijzigenDienstParams): Promise<IWijzigenDienstResult> => {
    return await api.post('v2/dienst/sms/wijzigen-dienst', params);
  },
};

export default sms;
