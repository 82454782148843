import React, { useContext, useMemo } from 'react';
import { IWeergaveProps } from '../../../../../../../components/FilterBalkV2';
import ContactpersoonSelectie, {
  IOverlayState,
  Key,
} from '../../../../../../../components/formulier/ContactpersoonSelectie';
import { Provider } from '../../../../../../../components/formulier/MultiComboboxV2';
import { IOphalenContactpersonenResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../../../../../../../api';
import { ContractenOverzichtContext } from './index';

const PersoonFilter = (props: IWeergaveProps<any>) => {
  const contractenOverzichtContext = useContext(ContractenOverzichtContext);

  const relPersIDs = props.data as number[];
  const relPersID = useMemo<number | null>(() => (relPersIDs.length === 0 ? null : relPersIDs[0]), [
    relPersIDs,
  ]);

  const provider = useMemo<Provider<Key, IOphalenContactpersonenResultElement, IOverlayState>>(
    () => async (params) => {
      const result = await api.v2.relatie.ophalenContactpersonen({
        paginatie: params.paginatie,
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [contractenOverzichtContext.relID],
            },
          ],
        },
      });

      const items = result.contactpersonen.reduce(
        (acc, curr, i) => ({
          ...acc,
          [i + params.paginatie.index]: curr,
        }),
        {},
      );

      return {
        totaalAantal: result.totaalAantal,
        items,
      };
    },
    [contractenOverzichtContext.relID],
  );

  return (
    <div className="d-flex align-items-center">
      <span className="mr-2">Persoon</span>
      <ContactpersoonSelectie
        relPersID={relPersID}
        onChange={(x) => {
          props.onDataChange(x === null ? [] : [x]);
          props.setIsActief(true);
          props.toepassen();
        }}
        provider={provider}
      />
    </div>
  );
};

export default PersoonFilter;
