import { ERegelboekstukType, ERegelIDType, ERegelstate, IWeergaveRegel, Regel } from '../types';
import api from '../../../../../api';
import { IOphalenDagboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { v4 as uuidv4 } from 'uuid';
import { EDagboeksoort } from '../../../../../bedrijfslogica/enums';
import { IOphalenBoekingRegelsResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';

export interface IRegelsProvider {
  provide: (boekingID: number | null, dagboek: IOphalenDagboekenResultElement) => Promise<Regel[]>;
}

export const standaardRegelsProvider: IRegelsProvider = {
  provide: async (boekingID) => {
    if (boekingID === null) {
      return [];
    }
    const boekingenResult = await api.v2.boeking.ophalenBoekingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [boekingID],
          },
        ],
      },
    });
    const boeking = boekingenResult.boekingen[0];

    const boekingregelsResult = await api.v2.boeking.ophalenBoekingregels({
      filterSchema: {
        filters: [
          {
            naam: 'BOEKING_IDS',
            data: [boekingID],
          },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'REGELNUMMER',
            richting: 'ASC',
          },
        ],
      },
    });

    const grootboekenResult = await api.v2.boeking.grootboek.ophalenGrootboeken({
      filterSchema: {
        filters: [
          {
            naam: 'NAAM_ENUMS',
            data: ['SPLITSEN'],
          },
        ],
      },
    });
    const splitsenGrootboek = grootboekenResult.grootboeken[0];

    const gesplitsteRegels = boekingregelsResult.regels.filter(
      (x) => x.C_GrbRekID === splitsenGrootboek.ID,
    );
    const gecomprimeerdeRegels = boekingregelsResult.regels.filter(
      (x) => x.C_GrbRekID !== splitsenGrootboek.ID,
    );

    // Splitsen
    if (gecomprimeerdeRegels.length > 0) {
      const gesplitsteWeergaveRegels = gesplitsteRegels.map(
        (regel): IWeergaveRegel => ({
          id: {
            type: ERegelIDType.Extern,
            waarde: regel.ID,
          },
          state: ERegelstate.Weergave,
          relID: regel.RelID,
          bedrag: regel.Bedrag,
          btwSoortID: regel.BtwSrtID,
          grootboekID: regel.D_GrbRekID,
          omschrijving: regel.Omschrijving,
          storno: regel.Storno,
          werkkostenregelingID: regel.WkrRglID,
          regelboekstuk:
            regel.InkFactID !== null
              ? {
                  type: ERegelboekstukType.Inkoopfactuur,
                  inkFactID: regel.InkFactID,
                }
              : regel.FactID !== null
              ? {
                  type: ERegelboekstukType.Factuur,
                  factID: regel.FactID,
                }
              : regel.BankMutID !== null
              ? {
                  type: ERegelboekstukType.Bankmutatie,
                  bankMutID: regel.BankMutID,
                }
              : regel.BetRglID !== null
              ? {
                  type: ERegelboekstukType.Betalingsregeling,
                  betRglID: regel.BetRglID,
                }
              : null,
        }),
      );

      if (boeking.dagboek.dagboekSoort.NaamEnum === EDagboeksoort.BANK) {
        if (gecomprimeerdeRegels.some((x) => x.BankMutID === null)) {
          throw new Error(
            `Comprimeerde regels moeten allemaal een bankmutatie hebben bij dagboeksoort bank, of ons algoritme klopt niet!`,
          );
        }

        const gegroepeerdeRegels = gecomprimeerdeRegels.reduce<
          Record<number, IOphalenBoekingRegelsResultElement[]>
        >(
          (acc, curr) => ({
            ...acc,
            [curr.BankMutID!]: [...(acc[curr.BankMutID!] ?? []), curr],
          }),
          {},
        );
        const bedragPerBankMutID = Object.keys(gegroepeerdeRegels)
          .map(Number)
          .reduce<Record<number, number>>(
            (acc, bankMutID) => ({
              ...acc,
              [bankMutID]: gegroepeerdeRegels[bankMutID].reduce(
                (acc1, curr) => acc1 + curr.Bedrag,
                0,
              ),
            }),
            {},
          );
        const volgordeBankMutIDs = Object.keys(bedragPerBankMutID)
          .map(Number)
          .sort((bankMutIDLhs, bankMutIDRhs) => {
            const lhs = bedragPerBankMutID[bankMutIDLhs];
            const rhs = bedragPerBankMutID[bankMutIDRhs];

            return rhs - lhs;
          });

        const comprimeerdNaarGesplitseWeergaveRegels = volgordeBankMutIDs.flatMap(
          (bankMutID): IWeergaveRegel[] => {
            const regels = gegroepeerdeRegels[bankMutID];
            const eersteGegroepeerdeRegel = regels[0];
            const bedrag = bedragPerBankMutID[bankMutID];

            const eersteRegel: IWeergaveRegel = {
              id: {
                type: ERegelIDType.Lokaal,
                waarde: uuidv4(),
              },
              state: ERegelstate.Weergave,
              relID: null,
              bedrag: -bedrag,
              btwSoortID: null,
              grootboekID: eersteGegroepeerdeRegel.C_GrbRekID,
              omschrijving: null,
              storno: false,
              werkkostenregelingID: null,
              regelboekstuk: {
                type: ERegelboekstukType.Bankmutatie,
                bankMutID,
              },
            };

            return [
              eersteRegel,
              ...regels.map(
                (regel): IWeergaveRegel => ({
                  id: {
                    type: ERegelIDType.Extern,
                    waarde: regel.ID,
                  },
                  state: ERegelstate.Weergave,
                  relID: regel.RelID,
                  bedrag: regel.Bedrag,
                  btwSoortID: regel.BtwSrtID,
                  grootboekID: regel.D_GrbRekID,
                  omschrijving: regel.Omschrijving,
                  storno: regel.Storno,
                  werkkostenregelingID: regel.WkrRglID,
                  regelboekstuk:
                    regel.InkFactID !== null
                      ? {
                          type: ERegelboekstukType.Inkoopfactuur,
                          inkFactID: regel.InkFactID,
                        }
                      : regel.FactID !== null
                      ? {
                          type: ERegelboekstukType.Factuur,
                          factID: regel.FactID,
                        }
                      : regel.BetRglID !== null
                      ? {
                          type: ERegelboekstukType.Betalingsregeling,
                          betRglID: regel.BetRglID,
                        }
                      : null,
                }),
              ),
            ];
          },
        );

        return [...comprimeerdNaarGesplitseWeergaveRegels, ...gesplitsteWeergaveRegels];
      }

      const comprimeerdNaarGesplitseWeergaveRegels = boekingregelsResult.regels.flatMap(
        (regel): IWeergaveRegel[] => {
          const eersteRegel: IWeergaveRegel = {
            id: {
              type: ERegelIDType.Extern,
              waarde: regel.ID,
            },
            state: ERegelstate.Weergave,
            relID: regel.RelID,
            bedrag: regel.Bedrag,
            btwSoortID: regel.BtwSrtID,
            grootboekID: regel.D_GrbRekID,
            omschrijving: regel.Omschrijving,
            storno: regel.Storno,
            werkkostenregelingID: regel.WkrRglID,
            regelboekstuk:
              regel.InkFactID !== null
                ? {
                    type: ERegelboekstukType.Inkoopfactuur,
                    inkFactID: regel.InkFactID,
                  }
                : regel.FactID !== null
                ? {
                    type: ERegelboekstukType.Factuur,
                    factID: regel.FactID,
                  }
                : regel.BankMutID !== null
                ? {
                    type: ERegelboekstukType.Bankmutatie,
                    bankMutID: regel.BankMutID,
                  }
                : regel.BetRglID !== null
                ? {
                    type: ERegelboekstukType.Betalingsregeling,
                    betRglID: regel.BetRglID,
                  }
                : null,
          };
          const tweedeRegel: IWeergaveRegel = {
            id: {
              type: ERegelIDType.Lokaal,
              waarde: uuidv4(),
            },
            state: ERegelstate.Weergave,
            relID: null,
            bedrag: -regel.Bedrag,
            btwSoortID: null,
            grootboekID: regel.C_GrbRekID,
            omschrijving: null,
            storno: false,
            werkkostenregelingID: null,
            regelboekstuk:
              eersteRegel.regelboekstuk !== null &&
              (eersteRegel.regelboekstuk.type === ERegelboekstukType.Factuur ||
                eersteRegel.regelboekstuk.type === ERegelboekstukType.Inkoopfactuur ||
                eersteRegel.regelboekstuk.type === ERegelboekstukType.Betalingsregeling) &&
              regel.BankMutID !== null
                ? {
                    type: ERegelboekstukType.Bankmutatie,
                    bankMutID: regel.BankMutID,
                  }
                : null,
          };

          return [eersteRegel, tweedeRegel];
        },
      );

      return [...comprimeerdNaarGesplitseWeergaveRegels, ...gesplitsteWeergaveRegels];

      // console.log('gecomprimeerdeRegels', gecomprimeerdeRegels);
      //
      // throw new Error(
      //   `Regels zijn ondervonden als gecomprimeerd maar ons algoritme weet niet hoe we deze situatie kunnen splitsen.`,
      // );
    }

    return boekingregelsResult.regels.map(
      (x) =>
        ({
          id: {
            type: ERegelIDType.Extern,
            waarde: x.ID,
          },
          state: ERegelstate.Weergave,
          relID: x.RelID,
          bedrag: x.Bedrag,
          btwSoortID: x.BtwSrtID,
          grootboekID: x.D_GrbRekID,
          omschrijving: x.Omschrijving,
          storno: x.Storno,
          werkkostenregelingID: x.WkrRglID,
          regelboekstuk:
            x.InkFactID !== null
              ? {
                  type: ERegelboekstukType.Inkoopfactuur,
                  inkFactID: x.InkFactID,
                }
              : x.FactID !== null
              ? {
                  type: ERegelboekstukType.Factuur,
                  factID: x.FactID,
                }
              : x.BankMutID !== null
              ? {
                  type: ERegelboekstukType.Bankmutatie,
                  bankMutID: x.BankMutID,
                }
              : x.BetRglID !== null
              ? {
                  type: ERegelboekstukType.Betalingsregeling,
                  betRglID: x.BetRglID,
                }
              : null,
        } as IWeergaveRegel),
    );
  },
};
