import React, { useCallback, useMemo, useState } from 'react';
import { IconEmail } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IEmailGeadresseerde from '../../../../../shared/src/models/email/IEmailGeadresseerde';
import { setCommunicatieOverlayState } from '../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../one-off-components/CommunicatieOverlay/MenuHandle';
import KopieerNaarKlembord from '../../KopieerNaarKlembord';
import { EUitstelling } from '../EmailWerkblad/OpstellenFormulier/VersturenKnop';

interface IOptions {
  icoonWeergeven?: boolean;
  copyToClipboard?: boolean;
}

const defaultOptions: IOptions = {
  icoonWeergeven: true,
  copyToClipboard: true,
};

interface IProps extends RouteComponentProps {
  emailAdres: string;
  textComponent?: React.ComponentType;
  orgID?: number;
  persID?: number;
  options?: IOptions;
  defaultFromEmailGebrID?: number;
  defaultSubject?: string;
  defaultBody?: string;
  defaultCC?: IEmailGeadresseerde[];
  defaultBCC?: IEmailGeadresseerde[];
}

const EmailComponent: React.FC<IProps> = observer((props) => {
  const options = useMemo<IOptions>(() => {
    return { ...defaultOptions, ...props.options };
  }, [props.options]);
  const {
    children,
    emailAdres,
    persID,
    orgID,
    defaultBCC,
    defaultBody,
    defaultCC,
    defaultFromEmailGebrID,
    defaultSubject,
    textComponent,
  } = props;

  const [hovered, setHoverd] = useState(false);

  const handleClick = useCallback(async () => {
    setCommunicatieOverlayState!((prevState) => ({
      ...prevState,
      geselecteerdTabblad: ECommunicatieTabblad.Email,
      emailContext: {
        ...prevState.emailContext,
        formulier: {
          emailBerID: null,
          fromEmailGebrID: null,
          recipients: [
            {
              emailAdres,
              persID: persID === undefined ? null : persID,
              orgID: orgID === undefined ? null : orgID,
            },
          ],
          CC: [],
          BCC: [],
          bijlagen: [],
          subject: '',
          body: '',
          sjabBasisID: null,
          taalID: null,
          contextRelIDs: [],
          uitstelling: EUitstelling.Direct,
        },
      },
    }));
    // const urlState = genereerUrlStateQueryParam<IUrlState>({
    //   showOpstellenFormulier: true,
    //   mailboxesState: null,
    //   emailID: null,
    //   showMailboxesSection: false,
    //   defaultRecipients: [
    //     {
    //       emailAdres,
    //       persID: persID === undefined ? null : persID,
    //       relID: relID === undefined ? null : relID,
    //     },
    //   ],
    //   defaultCC: defaultCC || null,
    //   defaultBody: defaultBody || null,
    //   defaultBCC: defaultBCC || null,
    //   defaultSubject: defaultSubject || null,
    //   defaultFromEmailGebrID: defaultFromEmailGebrID || null,
    //   activeMailbox: null,
    // });
    //
    // props.history.push({
    //   pathname: '/communicatie/email',
    //   search: `state=${urlState}`,
    // });
  }, [
    emailAdres,
    orgID,
    persID,
    defaultBCC,
    defaultBody,
    defaultCC,
    defaultFromEmailGebrID,
    defaultSubject,
  ]);

  const TextComponent = useMemo(
    () => (textComponent !== undefined ? textComponent : () => <span>{emailAdres}</span>),
    [textComponent, emailAdres],
  );

  return (
    <span
      className="d-flex"
      onMouseEnter={() => {
        setHoverd(true);
      }}
      onMouseLeave={() => {
        setHoverd(false);
      }}
    >
      <a href="#" className="d-flex align-items-center" onClick={handleClick}>
        {options.icoonWeergeven && (
          <IconEmail style={{ width: 15, height: 15, fill: Kleur.LichtBlauw, marginTop: 2 }} />
        )}
        <span className={classNames([options.icoonWeergeven ? 'ml-2' : null])}>
          <TextComponent />
        </span>
      </a>
      {options.copyToClipboard && hovered && (
        <span className="ml-2">
          <KopieerNaarKlembord waarde={emailAdres} />
        </span>
      )}
    </span>
  );
});

export default withRouter(EmailComponent);
