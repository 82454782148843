import { makeObservable, observable } from 'mobx';
import api from '../api';

class InstellingStore {
  public instellingenOpgehaald = false;

  public MaxAantalRecent: number | null = null;
  public TrsLevID: number | null = null;
  public ServLevID: number | null = null;
  public SmsDienstID: number | null = null;
  public EmailDienstID: number | null = null;
  public VoipDienstID: number | null = null;
  public OpslagDienstID: number | null = null;
  public AfzenderSMS: number | null = null;
  // tslint:disable-next-line:variable-name
  public Email_SjabID: number | null = null;
  // tslint:disable-next-line:variable-name
  public SMS_SjabID: number | null = null;
  public BankRekID: number | null = null;
  public MagID: number | null = null;
  public CronTabGeneratorPaginaURL: string | null = null;
  public ProdSrtID: number | null = null;
  public TaalID: number | null = null;
  public Voorraad_MagID: number | null = null;
  public Revisie_MagID: number | null = null;
  public StandaardVerzendwijzeFacturen: number | null = null;
  public MaxAantalExtraAfvoerProducten: number = 0;
  public OntbrekendeReferentiesMaxDagenTerug: number = 0;
  public MaximaalAantalAanmaningen: number = 0;
  public RelatieMemosDelen: boolean = false;
  public IsBulkverzenderActief: boolean = false;
  public EenmaligeKostenBijWissel: boolean = true;

  constructor() {
    makeObservable(this, {
      instellingenOpgehaald: observable,
      MaxAantalRecent: observable,
      TrsLevID: observable,
      ServLevID: observable,
      SmsDienstID: observable,
      EmailDienstID: observable,
      VoipDienstID: observable,
      OpslagDienstID: observable,
      AfzenderSMS: observable,
      Email_SjabID: observable,
      SMS_SjabID: observable,
      BankRekID: observable,
      MagID: observable,
      CronTabGeneratorPaginaURL: observable,
      ProdSrtID: observable,
      TaalID: observable,
      Voorraad_MagID: observable,
      Revisie_MagID: observable,
      MaxAantalExtraAfvoerProducten: observable,
      MaximaalAantalAanmaningen: observable,
      RelatieMemosDelen: observable,
      IsBulkverzenderActief: observable,
      EenmaligeKostenBijWissel: observable,
    });
  }

  public ophalenInstellingen = async () => {
    try {
      const result = await api.v2.instelling.ophalenInstellingen({});

      this.AfzenderSMS = result.AfzenderSMS;
      this.Email_SjabID = result.Email_SjabID;
      this.SMS_SjabID = result.SMS_SjabID;
      this.OpslagDienstID = result.OpslagDienstID;
      this.VoipDienstID = result.VoipDienstID;
      this.EmailDienstID = result.EmailDienstID;
      this.SmsDienstID = result.SmsDienstID;
      this.ServLevID = result.ServLevID;
      this.TrsLevID = result.TrsLevID;
      this.MaxAantalRecent = result.MaxAantalRecent;
      this.BankRekID = result.BankRekID;
      this.MagID = result.MagID;
      this.CronTabGeneratorPaginaURL = result.CronTabGeneratorPaginaURL;
      this.ProdSrtID = result.ProdSrtID;
      this.TaalID = result.TaalID;
      this.Voorraad_MagID = result.Voorraad_MagID;
      this.Revisie_MagID = result.Revisie_MagID;
      this.StandaardVerzendwijzeFacturen = result.StandaardVerzendwijzeFacturen;
      this.MaxAantalExtraAfvoerProducten = result.MaxAantalExtraAfvoerProducten;
      this.OntbrekendeReferentiesMaxDagenTerug = result.OntbrekendeReferentiesMaxDagenTerug;
      this.MaximaalAantalAanmaningen = result.MaximaalAantalAanmaningen;
      this.RelatieMemosDelen = result.RelatieMemosDelen;
      this.IsBulkverzenderActief = result.IsBulkverzenderActief;
      this.EenmaligeKostenBijWissel = result.EenmaligeKostenBijWissel;

      this.instellingenOpgehaald = true;
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
    }
  };
}

export default InstellingStore;
