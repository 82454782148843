import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../../components/kaart/EntiteitContainer';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
} from '../../../../../../../components/tabel/ASPTabel/types';
import { IOphalenKostenplaatsenResultElement } from '../../../../../../../../../shared/src/api/v2/rubriek/kostenplaats';
import MenuLayout from '../../../../../../../components/MenuLayout';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../../components/tabel/ASPTabel';
import useUrlState from '../../../../../../../core/useUrlState';
import { Sorting } from '@devexpress/dx-react-grid';

interface IProps extends RouteComponentProps {
  relID: number;
}

enum EKolom {
  Naam,
}

export interface IUrlState {
  selectie: number[];
  // sortering: Sorting[];
}

// const defaultUrlState: IUrlState = {
//   selectie: [],
//   // sortering: [
//   //   {
//   //     columnName: 'Naam',
//   //     direction: 'asc',
//   //   },
//   // ],
// };

const Kostenplaatsen: React.FC<IProps> = (props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      // sortering: [
      //   {
      //     key: EKolom.Naam,
      //     sortering: ESortering.Ascending,
      //   },
      // ],
    }),
    [],
  );

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [kostenplaatsen, setKostenplaatsen] = useState<
    IOphalenKostenplaatsenResultElement[] | null
  >(null);

  const ophalenKostenplaatsen = useCallback(async () => {
    const result = await api.v2.factuur.rubriek.kostenplaats.ophalenKostenplaatsen({
      filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
    });

    setKostenplaatsen(result.kostenplaatsen);
  }, []);

  useEffect(() => {
    ophalenKostenplaatsen();
  }, [ophalenKostenplaatsen]);

  const kostenplaatsenRijen = useMemo(() => {
    if (kostenplaatsen === null) {
      return null;
    }

    const kostenplaatsenRijen = kostenplaatsen.reduce((acc, kostenplaats, idx) => {
      return {
        ...acc,
        [idx]: kostenplaats,
      };
    }, {});

    return kostenplaatsenRijen;
  }, [kostenplaatsen]);

  const keyExtractor = useCallback((item: IOphalenKostenplaatsenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenKostenplaatsenResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        // vasteBreedte: 300,
        flex: 1,
        renderer: (item: IOphalenKostenplaatsenResultElement) => {
          return item.Naam;
        },
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        body={
          <div className="d-flex flex-column flex-fill">
            {kostenplaatsenRijen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={kostenplaatsenRijen}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  // sortering={urlState.sortering}
                  // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  // onWijzigenRij={async (x) => {}}
                  // onVerwijderenRij={async (x) => {
                  //   await new Promise((resolve) => setTimeout(resolve, 3000));
                  // }}
                  // lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default withRouter(Kostenplaatsen);
