import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IRelatieDuplicaatSuggestieDuplicaat } from '../../../../../../../../shared/src/api/v2/relatie/duplicaat';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  Column,
  DataTypeProvider,
  SelectionState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import { DXTableCheckboxComponent, GridStyleWrapper } from '../../../../../../helpers/dxTableGrid';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../shared/src/api/v2/relatie';
import api from '../../../../../../api';
import { formatteerAdresV2 } from '../../../../../../helpers';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../shared/src/api/v2/contract';
import { format } from 'date-fns';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';

interface IProps {
  duplicaten: IRelatieDuplicaatSuggestieDuplicaat[];
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  vigerendeRelID: number | null;
  onVigerendeRelIDChange: (vigerendeRelID: number | null) => void;
}

const DuplicatenTabel: React.FC<IProps> = (props) => {
  const { duplicaten, selectie, onSelectieChange, vigerendeRelID, onVigerendeRelIDChange } = props;

  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);
  const ophalenRelaties = useCallback(async () => {
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: duplicaten.map((x) => x.RelID),
          },
        ],
      },
    });
    setRelaties(result.relaties);
  }, [duplicaten]);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const ophalenContracten = useCallback(async () => {
    const result = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: duplicaten.map((x) => x.RelID),
            },
          ],
        },
      })
    ).contracten;

    setContracten(result);
  }, [duplicaten]);
  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const keyExtractor = useCallback((row: IRelatieDuplicaatSuggestieDuplicaat) => row.RelID, []);

  const kolommen = useMemo<Column[]>(
    () => [
      {
        name: '__vigerend',
        title: 'Vigerend',
      },
      {
        name: '__relNr',
        title: 'RelNr',
      },
      {
        name: 'Geblokkeerd',
        title: 'Geblok.',
      },
      {
        name: '__naam',
        title: 'Naam',
      },
      {
        name: '__geboortedatum' as any,
        title: 'Geb.dtm.',
      },
      {
        name: '__aantalContracten',
        title: 'Atl. cnt.',
      },
      {
        name: '__adres',
        title: 'Adres',
      },
      {
        name: '__recordToegevoegd',
        title: 'Record toegevoegd',
      },
      {
        name: '__notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TableColumnWidthInfo[]>(
    () => [
      {
        columnName: '__vigerend',
        width: 100,
      },
      {
        columnName: '__relNr',
        width: 75,
      },
      {
        columnName: 'Geblokkeerd',
        width: 75,
      },
      {
        columnName: '__naam',
        width: 250,
      },
      {
        columnName: '__geboortedatum',
        width: 110,
      },
      {
        columnName: '__aantalContracten',
        width: 100,
      },
      {
        columnName: '__adres',
        width: 300,
      },
      {
        columnName: '__recordToegevoegd',
        width: 150,
      },
      {
        columnName: '__notities',
        width: 375,
      },
    ],
    [],
  );

  const handleSelectieChange = useCallback(
    (selectie: number[]) => {
      onSelectieChange(selectie);

      if (vigerendeRelID !== null && selectie.indexOf(vigerendeRelID) === -1) {
        onVigerendeRelIDChange(null);
      } else if (vigerendeRelID === null && selectie.length === 1) {
        const [relID] = selectie;
        onVigerendeRelIDChange(relID);
      }
    },
    [onSelectieChange, selectie, onVigerendeRelIDChange, vigerendeRelID],
  );

  if (relaties === null || contracten === null) {
    return <LoadingSpinner />;
  }

  return (
    <GridStyleWrapper maxHeight={500} rowAmount={duplicaten.length}>
      <Grid rows={duplicaten} columns={kolommen} getRowId={keyExtractor}>
        <DataTypeProvider
          for={['__vigerend']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const isVigerend = row.RelID === vigerendeRelID;
            const isGeselecteerd = selectie.indexOf(row.RelID) !== -1;

            return (
              <div className="d-flex flex-fill align-items-center">
                {isVigerend ? (
                  'Ja'
                ) : isGeselecteerd ? (
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onVigerendeRelIDChange(row.RelID);
                    }}
                  >
                    Kies...
                  </a>
                ) : null}
              </div>
            );
          }}
        />
        <DataTypeProvider
          for={['__relNr']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const relatie = relaties!.find((x) => x.RelID === row.RelID)!;

            return <span>{relatie.Relatienummer}</span>;
          }}
        />
        <DataTypeProvider
          for={['__naam']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            return (
              <span className="d-flex">
                <RelatieVisualisatie relID={row.RelID} />
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__geboortedatum']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const relatie = relaties!.find((x) => x.RelID === row.RelID)!;
            const datum = relatie.persoon!.Geboortedatum;

            return (
              <span className="d-flex">
                {datum !== null ? format(new Date(datum), 'dd-MM-yyyy') : ''}
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__aantalContracten']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const cnts = contracten!.filter((x) => x.RelID === row.RelID);
            return <span className="d-flex">{cnts.length}</span>;
          }}
        />
        <DataTypeProvider
          for={['__adres']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const relatie = relaties!.find((x) => x.RelID === row.RelID)!;
            const a = relatie.adres;

            return (
              <span className="d-flex">
                {a &&
                  formatteerAdresV2({
                    bisnummer: a.Bisnummer,
                    postcode: a.Postcode,
                    straatnaam: a.Straatnaam,
                    huisnummer: a.Huisnummer,
                    landnaamEnum: a.LandnaamEnum,
                    landnaamKort: a.LandnaamKort,
                    plaatsnaam: a.Plaatsnaam,
                  })}
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__recordToegevoegd']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const relatie = relaties!.find((x) => x.RelID === row.RelID)!;

            return (
              <span className="d-flex">
                {relatie.RecordToegevoegd === null
                  ? 'Onbekend'
                  : format(new Date(relatie.RecordToegevoegd), 'dd-MM-yyyy HH:mm')}
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__notities']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const relatie = relaties!.find((x) => x.RelID === row.RelID)!;

            return <span className="d-flex">{relatie.Notities}</span>;
          }}
        />

        <DataTypeProvider
          for={['Geblokkeerd']}
          formatterComponent={(formatterProps) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = formatterProps.row;
            const relatie = relaties!.find((x) => x.RelID === row.RelID)!;

            return <span className="d-flex">{relatie.Geblokkeerd ? 'Ja' : ''}</span>;
          }}
        />

        <SelectionState
          selection={selectie}
          onSelectionChange={(x) => handleSelectieChange(x as number[])}
        />

        <VirtualTable
          rowComponent={(props) => {
            const row: IRelatieDuplicaatSuggestieDuplicaat = props.row;
            const isVigerend = row.RelID === vigerendeRelID;
            return (
              <tr
                {...props}
                style={{ backgroundColor: isVigerend ? Kleur.DoorschijnendLichtBlauw : undefined }}
              />
            );
          }}
        />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow />
        <TableSelection cellComponent={DXTableCheckboxComponent} />
      </Grid>
    </GridStyleWrapper>
  );
};

export default DuplicatenTabel;
