import React from 'react';
import TableData from '../TableData';
import { IconInklappen, IconUitklappen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export interface IUitklapTableDataProps<TRow> {
  row?: TRow;
  isGefocust?: boolean;
  isGehovered?: boolean;
  heeftUitgeklapteRijOnderZich: boolean;
  onUitgeklaptChange: (uitgeklapt: boolean) => void;
}

const StandaardUitklapTableData = <TRow extends any>(props: IUitklapTableDataProps<TRow>) => {
  const Icon = props.heeftUitgeklapteRijOnderZich ? IconInklappen : IconUitklappen;

  return (
    <TableData
      tableKey={null}
      heeftUitgeklapteRijOnderZich={props.heeftUitgeklapteRijOnderZich}
      style={{ padding: 0 }}
      isGehovered={props.isGehovered}
      isGefocust={props.isGefocust}
      rowIndex={-1}
    >
      <button
        style={{ outline: 0, border: 0, background: 0 }}
        onClick={(ev) => props.onUitgeklaptChange(!props.heeftUitgeklapteRijOnderZich)}
      >
        <Icon style={{ width: 24, height: 24, fill: Kleur.Grijs }} />
      </button>
    </TableData>
  );
};

export default StandaardUitklapTableData;
