import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../../api';
import nameOf from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Combobox from '../../../../../components/formulier/Combobox';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import * as Yup from 'yup';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import { IOphalenBtwSoortenResultElement } from '../../../../../../../shared/src/api/v2/btw';
import { dagDatum } from '../../../../../helpers/datum';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import { EBtwsoort } from '../../../../../bedrijfslogica/enums';
import { IOphalenWerkkostenRegelingenResult } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';

interface IProps extends IDialoogProps<null> {}

export interface IFormikValues {
  btwSrtID: number;
  ingangsdatum: Date | string;
  percentage: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  btwSrtID: 'BtwSoort',
  ingangsdatum: 'Ingangsdatum',
  percentage: 'Percentage',
};

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [btwsoorten, setBtwsoorten] = useState<IOphalenBtwSoortenResultElement[] | null>(null);
  useEffect(() => {
    (async () => {
      const result = (
        await api.v2.btw.ophalenBtwSoorten({
          filterSchema: {
            filters: [{ naam: 'NAAM_ENUMS', data: [EBtwsoort.Geen], inverteren: true }],
          },
        })
      ).btwSoorten;

      setBtwsoorten(result);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        btwSrtID: values.btwSrtID,
        ingangsdatum: values.ingangsdatum,
        percentage: values.percentage,
      };

      const checkData = await api.v2.btw.checkToevoegenBtwtarief(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Btw-tarief toevoegen?
                <br />
                <br />
                Een ouder tarief voor dezelfde btwsoort zal automtisch beeindigd worden.
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.btw.toevoegenBtwtarief(params);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (btwsoorten === null) {
      return null;
    }

    return {
      btwSrtID:
        btwsoorten.find((x) => x.NaamEnum === EBtwsoort.AfdragenHoog)?.ID ?? btwsoorten[0].ID,
      ingangsdatum: dagDatum(new Date()),
      percentage: 0,
    };
  }, [btwsoorten]);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      btwSrtID: Yup.number().required(),
      ingangsdatum: Yup.date().required(),
      percentage: Yup.number().required(),
    };

    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen btwtarief</ModalTitle>
      </ModalHeader>

      {initialValues === null || btwsoorten === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(formikProps: FormikProps<IFormikValues | null>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.btwSrtID}</label>
                        <Field
                          name={nameOf<IFormikValues>('btwSrtID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (btwsoorten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={btwsoorten.map((btwsoort) => {
                                  return {
                                    id: btwsoort.ID,
                                    label: btwsoort.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.ingangsdatum}</label>
                        <Field
                          name={nameOf<IFormikValues>('ingangsdatum')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  determineValidDate={() => {
                                    return true;
                                  }}
                                  determinePreviousValidDate="ONBEGRENST"
                                  determineNextValidDate="ONBEGRENST"
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-4 mt-3">
                        <label>{veldnamen.percentage}</label>
                        <Field
                          name={nameOf<IFormikValues>('percentage')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <input {...field} className="form-control" type="number" min={0} />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default ToevoegenDialoog;
