import React from 'react';
import { EventProps } from 'react-big-calendar';
import { IVisualisatieEvent } from '../index';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconWarning } from '../../../../components/Icons';

interface IProps extends EventProps<IVisualisatieEvent> {}

const EventComp = (props: IProps) => {
  const { event } = props;
  const { taak } = event;
  return (
    <div
      style={
        {
          // color: taak.Actief ? Kleur.Blauw : Kleur.Rood,
        }
      }
    >
      <div className="d-flex align-items-center">
        {!taak.Actief && (
          <div title="Taak is niet actief">
            <IconWarning style={{ fill: Kleur.LichtGrijs, width: 15, height: 15 }} />
          </div>
        )}
        <span
          style={{ color: taak.Actief ? 'inherit' : Kleur.Grijs }}
          className={taak.Actief ? '' : 'ml-2'}
        >
          {taak.Naam}
        </span>
      </div>
    </div>
  );
};

export default EventComp;
