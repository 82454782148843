import { format } from 'date-fns';

export const telefoonNrRegex = /(\+\d{2})?\d{1,2}-?\d{8}/;

export const dagnaam = (datum: Date | string) => {
  // Deze werkt niet omdat d de dag van de maand geeft ipv de dag van de week
  let naam: string = '';
  switch (Number(format(new Date(datum), 'd'))) {
    case 0:
      naam = 'Zondag';
      break;
    case 1:
      naam = 'Maandag';
      break;
    case 2:
      naam = 'Dinsdag';
      break;
    case 3:
      naam = 'Woensdag';
      break;
    case 4:
      naam = 'Donderdag';
      break;
    case 5:
      naam = 'Vrijdag';
      break;
    case 6:
      naam = 'Zaterdag';
      break;
  }

  const result = naam;
  return result;
};

interface ITijdStringNaarDatumOptions {
  datum?: Date;
}

export const tijdStringNaarDatum = (tijdString: string, options?: ITijdStringNaarDatumOptions) => {
  const tijdDate = new Date('2000-01-01T' + tijdString);

  const d = new Date(options?.datum ?? new Date());
  d.setHours(tijdDate.getHours());
  d.setMinutes(tijdDate.getMinutes());
  d.setSeconds(tijdDate.getSeconds());
  d.setMilliseconds(tijdDate.getMilliseconds());
  return d;
};
