export enum EDataType {
  String,
  Number,
  Date,
  Boolean,
  // Object,
  // Array,
}

export interface IVeldOmschrijving<TEntiteit extends {}> {
  dataType: EDataType;
  weergaveNaam: string | JSX.Element;
  // omschrijving?: TEntiteit[TKey];
}

export type Omschrijving<TEntiteit> = Partial<
  Record<keyof TEntiteit, IVeldOmschrijving<TEntiteit>>
>;
export type FilterFn<TId, TEntiteit> = (entiteiten: TEntiteit[]) => Promise<TId[]>;
export type KeyExtractor<TId, TEntiteit> = (entiteit: TEntiteit) => TId;

export enum EStringVergelijking {
  Gelijk,
  ZitIn,
  // ZitInFuzzy,
  BegintMet,
  EindigtMet,
}
export enum ENumberVergelijking {
  Gelijk,
  GroterDan,
  KleinerDan,
}
export enum EDateVergelijking {
  Gelijk,
  Voor,
  Na,
}
export enum EBooleanVergelijking {
  Gelijk,
}

export type Vergelijking =
  | {
      type: EDataType.String;
      vergelijking: EStringVergelijking;
    }
  | {
      type: EDataType.Number;
      vergelijking: ENumberVergelijking;
    }
  | {
      type: EDataType.Date;
      vergelijking: EDateVergelijking;
    }
  | {
      type: EDataType.Boolean;
      vergelijking: EBooleanVergelijking;
    };

export interface IFilter<TId, TEntiteit> {
  veld: keyof TEntiteit;
  vergelijking: Vergelijking;
  waarde: any;
}

export interface ICustomFilter<TFilterId, TId, TEntiteit> {
  id: TFilterId;
  weergaveNaam: string;
  filter: (entities: TEntiteit[]) => Promise<TId[]>;
  altijdWeergevenInBalk?: boolean;
}
