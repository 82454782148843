import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IContentProps } from '../../shared';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenLocatiesResult } from '../../../../../../shared/src/api/v2/locatie/locatie';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { formatteerAdresV2 } from '../../../../helpers';
import { IOphalenContractenResultV3 } from '../../../../../../shared/src/api/v2/contract';
import NavigerenPijl from '../../Rechts/NavigerenPijl';

const LocatieContent = (props: IContentProps) => {
  const [locatiesResult, setLocatiesResult] = useState<IRemoteData<IOphalenLocatiesResult>>(
    createPendingRemoteData(),
  );
  const ophalenLocaties = useCallback(async () => {
    const result = await api.v2.locatie.ophalenLocaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: props.ids,
          },
        ],
      },
    });
    setLocatiesResult(createReadyRemoteData(result));
  }, [props.ids]);
  useEffect(() => {
    ophalenLocaties();
  }, [ophalenLocaties]);

  const [contractenResult, setContractenResult] = useState<IRemoteData<IOphalenContractenResultV3>>(
    createPendingRemoteData(),
  );
  const ophalenContracten = useCallback(async () => {
    const result = await api.v2.contract.ophalenContractenV3({
      selectieSchema: {
        velden: ['CNT_ID'],
      },
      filterSchema: {
        filters: [
          {
            naam: 'LOC_IDS',
            data: props.ids,
          },
        ],
      },
    });
    setContractenResult(createReadyRemoteData(result));
  }, [props.ids]);
  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  // const contractenBijLocID = useMemo<
  //   IRemoteData<{ [locID: number]: Partial<IOphalenContractenResultElementV3>[] }>
  // >(() => {
  //   if (contractenResult.state === ERemoteDataState.Pending) {
  //     return createPendingRemoteData();
  //   }
  //   const map = contractenResult.data!.contracten.reduce((acc, curr) => ({
  //     ...acc,
  //     [curr.L]
  //   }), {});
  // }, [contractenResult]);

  const handleNavigeren = useCallback(() => {}, []);

  return (
    <div style={{ paddingLeft: 35, paddingRight: 20 }} className="d-flex flex-column">
      {locatiesResult.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        locatiesResult.data!.locaties.map((locatie, i) => (
          <div key={locatie.LocID} className="p-2 d-flex align-items-center">
            <span className="flex-fill">
              {formatteerAdresV2({
                straatnaam: locatie.Straatnaam,
                postcode: locatie.Postcode,
                plaatsnaam: locatie.Plaatsnaam,
                landnaamKort: locatie.LandnaamKort,
                landnaamEnum: locatie.LandnaamEnum,
                huisnummer: locatie.Huisnummer,
                bisnummer: locatie.Bisnummer,
              })}
            </span>
            <NavigerenPijl onClick={handleNavigeren} />
          </div>
        ))
      )}
    </div>
  );
};

export default LocatieContent;
