import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  handleTelefoonnummerEnEmailVoorvullen,
  IFormikValues,
  veldnamen,
  WijzigenDialoogContext,
} from '..';
import ModalBody from 'react-bootstrap/ModalBody';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../core/nameOf';
import VinkVeld from '../../../formulier/VinkVeld';
import ContactSelectie, { EType } from '../../../formulier/ContactSelectie';
import DatumKiezer, { IDetermineValidDateProps } from '../../../formulier/DatumKiezer';
import TijdvakVeld from '../../../formulier/TijdvakVeld';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { dagDatum } from '../../../../helpers/datum';
import { format } from 'date-fns';
import { generateDetermineValidDatePropsByValidDateArray } from '../../../formulier/DatumKiezer/helpers';
import TelefoonnummerInput, {
  genereerRecenteOproepenSuggestiesProvider,
  ISuggestiesProvider,
} from '../../../formulier/TelefoonnummerInput';
import { RootStoreContext } from '../../../../stores/RootStore';
import { values } from 'lodash';
import Combobox from '../../../formulier/Combobox';

const OpdrachtTab = () => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const { opdracht, bezoekdagen, vragenTerugNaarPlanning, magazijnen } = useContext(
    WijzigenDialoogContext,
  );

  const relIDs = useMemo<number[] | null>(() => {
    if (opdracht === null) {
      return null;
    }
    return opdracht.regels
      .map((x) => (x.RelID === null ? null : x.RelID))
      .filter((x) => x !== null) as number[];
  }, [opdracht]);

  const bezoekdatumDatumKiezerDetermineProps = useMemo<IDetermineValidDateProps | null>(
    () =>
      bezoekdagen === null
        ? null
        : generateDetermineValidDatePropsByValidDateArray(
            bezoekdagen.map((x) => dagDatum(new Date(x))),
          ),
    [bezoekdagen],
  );

  const [
    telefoonnummerInputSuggestiesProvider,
    setTelefoonnummerInputSuggestiesProvider,
  ] = useState<ISuggestiesProvider | null>(null);

  useEffect(() => {
    if (gebruikerStore.gebruiker === null) {
      return;
    }
    genereerRecenteOproepenSuggestiesProvider(gebruikerStore.gebruiker.AspGebrID).then((provider) =>
      setTelefoonnummerInputSuggestiesProvider(provider),
    );
  }, [gebruikerStore.gebruiker]);

  return (
    <ModalBody>
      <div style={{ minHeight: `400px` }}>
        <div className="form-group">
          <div className="row ">
            {' '}
            <div className="col-12">
              <label>{veldnamen.magID}</label>
              <Field
                name={nameOf<IFormikValues>('magID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (magazijnen === null) {
                    return <LoadingSpinner />;
                  }

                  return (
                    <div>
                      <Combobox
                        geselecteerd={field.value}
                        opties={magazijnen.map((magazijn) => {
                          return {
                            id: magazijn.MagID,
                            label:
                              magazijn.Naam !== null
                                ? magazijn.NaamKort + ' - ' + magazijn.Naam
                                : magazijn.NaamKort,
                          };
                        })}
                        onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="row col-12 mt-3">
            {bezoekdatumDatumKiezerDetermineProps !== null && (
              <div className="col-4">
                <label>{veldnamen.bezoekDatum}</label>
                <Field
                  name={nameOf<IFormikValues>('bezoekDatum')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    if (form.values.alleenToepasselijkeBezoekdatums) {
                      return (
                        <DatumKiezer
                          waarde={field.value}
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          isClearable
                          {...bezoekdatumDatumKiezerDetermineProps}
                        />
                      );
                    }

                    return (
                      <DatumKiezer
                        waarde={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        isClearable
                      />
                    );
                  }}
                />
              </div>
            )}

            <div className="col-4">
              <label>{veldnamen.voorkeurTijdvak}</label>
              <Field
                name={nameOf<IFormikValues>('voorkeurTijdvak')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <TijdvakVeld
                      value={field.value}
                      onChange={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                      enkeleToestaan
                    />
                  );
                }}
              />
            </div>

            <div className="col-4 d-flex align-items-center pt-4">
              <Field
                name={nameOf<IFormikValues>('indicatieAAA')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.indicatieAAA}</span>
            </div>
          </div>

          <div className="row col-12 mt-3">
            <Field
              name={nameOf<IFormikValues>('alleenToepasselijkeBezoekdatums')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.alleenToepasselijkeBezoekdatums}</span>
          </div>

          <div className="row mt-3">
            <div className="col-6 pt-4">
              <label>{veldnamen.persID}</label>
              {relIDs === null ? (
                <LoadingSpinner />
              ) : (
                <Field
                  name={nameOf<IFormikValues>('persID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <ContactSelectie
                        value={
                          field.value === null
                            ? null
                            : {
                                persID: field.value,
                                orgID: null,
                              }
                        }
                        onChange={async (x) => {
                          const persID = x?.persoon?.PersID ?? null;
                          form.setFieldValue(field.name, persID);
                          if (x === null) {
                            return;
                          }
                          await handleTelefoonnummerEnEmailVoorvullen(persID, form);
                        }}
                        options={{
                          types: [EType.Persoon],
                        }}
                        alleenVoorRelIDs={relIDs}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-6">
              <label>{veldnamen.email}</label>
              <Field
                name={nameOf<IFormikValues>('email')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return <input type="text" className="form-control" {...field} />;
                }}
              />
            </div>
            <div className="col-6 ">
              <label>{veldnamen.telefoon}</label>
              <Field
                name={nameOf<IFormikValues>('telefoon')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return telefoonnummerInputSuggestiesProvider === null ? (
                    <LoadingSpinner />
                  ) : (
                    <TelefoonnummerInput
                      telefoonnummer={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      suggestiesProvider={telefoonnummerInputSuggestiesProvider}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="row pt-4">
            <div className="col-9">
              <label>{veldnamen.infoPlanning}</label>
              <Field
                name={nameOf<IFormikValues>('infoPlanning')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return <textarea {...field} className="form-control" rows={3} />;
                }}
              />
            </div>
          </div>

          <div className="row pt-2">
            <div className="col-9">
              <label>{veldnamen.bezoekInstructies}</label>
              <Field
                name={nameOf<IFormikValues>('bezoekInstructies')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return <textarea {...field} className="form-control" rows={2} />;
                }}
              />
            </div>
          </div>

          <div className="row pt-2">
            <div className="col-6 d-flex align-items-center pt-2">
              <Field
                name={nameOf<IFormikValues>('telefonischVoorberichtBezoek')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.telefonischVoorberichtBezoek}</span>
            </div>
          </div>

          <div className="row pt-4">
            <div className="col-9">
              <label>{veldnamen.notities}</label>
              <Field
                name={nameOf<IFormikValues>('notities')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return <textarea {...field} className="form-control" rows={1} />;
                }}
              />
            </div>
          </div>

          {vragenTerugNaarPlanning && (
            <div className="row pt-4">
              <div className="col-12 d-flex align-items-center">
                <Field
                  name={nameOf<IFormikValues>('terugzettenNaarPlanning')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">{veldnamen.terugzettenNaarPlanning}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalBody>
  );
};

export default OpdrachtTab;
