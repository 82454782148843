import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../../../api';
import { ISectieProps } from '../index';
import GegevensLayout, {
  IGegeven,
} from '../../../../../../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../../../../../../components/layout/VerticaleScheidingslijn';
import HorizontaleScheidingslijn from '../../../../../../../../../components/layout//HorizontaleScheidingslijn';
import { format } from 'date-fns';
import { formatteerBedrag } from '../../../../../../../../../helpers';
import { formatteerPersoonNaam, formatteerAdresV2 } from '../../../../../../../../../helpers';
import { maandenNaarJaren } from '../../../../../../../../../bedrijfslogica/teksten';
import {
  IconToevoegen,
  IconTransport,
  IconWijzigen,
} from '../../../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import ServiceMeldingDialoog from '../../../../Service/Meldingen/NieuweMeldingDialoog';
import { EResultType } from '../../../../../../../../../stores/CheckStore';
import VersturenDocumentatieDialoog from '../../../../../../../../../components/product/VersturenDocumentatieDialoog';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../../../shared/src/api/v2/product';
import { EProductstatus, ERedenUitBedrijf } from '../../../../../../../../../bedrijfslogica/enums';
import WijzigenProductDialoog from '../../../../../../../../Magazijn/Depot/WijzigenProductDialoog';
import UitBedrijfDialoog from './UitBedrijfDialoog';
import { IBerekenVerkoopbedragResult } from '../../../../../../../../../../../shared/src/api/v2/product/verkoop';

interface IProps extends ISectieProps {}

interface IServicemeldingDialoogState {
  prodID: number;
}
interface IServicemeldingDialoogState2 {
  prodID: number;
}
interface IVersturenDocumentatieDialoogState {
  prodID: number;
}
interface IProductUitBedrijfDialoogStateState {
  prodID: number;
}

interface IProduct extends IOphalenProductenResultElementV2 {
  verkoopprijs: number | null;
}

const Product: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const contract = props.contract!;

  const [
    servicemeldingDialoogState,
    setServicemeldingDialoogState,
  ] = useState<IServicemeldingDialoogState | null>(null);

  const [wijzigenProdID, setWijzigenProdID] = useState<number | null>(null);

  const [
    versturenDocumentatieDialoogState,
    setVersturenDocumentatieDialoogState,
  ] = useState<IVersturenDocumentatieDialoogState | null>(null);

  const [
    productUitBedrijfDialoogState,
    setProductUitBedrijfDialoogStateState,
  ] = useState<IProductUitBedrijfDialoogStateState | null>(null);

  const [verkoopbedragen, setVerkoopbedragen] = useState<IBerekenVerkoopbedragResult | null>(null);
  const ophalenVerkoopbedragen = useCallback(async () => {
    const verkoopbedragen = await api.v2.product.verkoop.berekenVerkoopbedrag({
      prodIDs: contract.producten.map((x) => x.ProdID),
    });
    setVerkoopbedragen(verkoopbedragen);
  }, [contract]);
  useEffect(() => {
    ophalenVerkoopbedragen();
  }, [ophalenVerkoopbedragen]);

  const producten = useMemo<IProduct[] | null>(() => {
    if (verkoopbedragen === null) {
      return null;
    }

    return props.producten.map((product) => {
      const verkoopprijsResult = verkoopbedragen.verkoopbedragen.find(
        (x) => x.ProdID === product.ProdID,
      )!;

      return { ...product, verkoopprijs: verkoopprijsResult.bedrag };
    });
  }, [props.producten, verkoopbedragen]);

  // const handleVersturenDocumentatie = useCallback(async (prodID: number, persID: number) => {
  //   const params = { prodID, persID };
  //   if (
  //     (
  //       await checkStore.bevestigen({
  //         titel: <div>Wil je productdocumentatie versturen?</div>,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   await api.v2.product.documentatie.versturenProductdocumentatie(params);
  // }, []);

  return (
    <>
      {producten !== null && producten.length !== 0 && (
        <div className="d-flex flex-column pt-2 pb-3 pl-4 pr-4">
          {producten.map((product: IProduct, i) => {
            return (
              <div key={product.ProdID} className={producten!.length - 1 === i ? '' : 'mb-4'}>
                {producten!.length > 1 && (
                  <div className="font-weight-bold mb-1">
                    {product.producttype.Merknaam} {product.producttype.Typenaam}{' '}
                    {product.Referentiecode}
                  </div>
                )}
                <div className="d-flex">
                  <div style={{ width: 225 }}>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Merk/type',
                          waarde: `${product.producttype.Merknaam} ${product.producttype.Typenaam}`,
                        },
                        {
                          label: 'Soort',
                          waarde: product.producttype.Productsoortnaam,
                        },
                        {
                          label: 'Ref.code',
                          waarde: product.Referentiecode,
                        },
                        {
                          label: 'leeftijd',
                          waarde: `${
                            product.leeftijd !== null ? maandenNaarJaren(product.leeftijd) : ''
                          }`,
                        },
                      ]}
                    />
                  </div>

                  <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                    <VerticaleScheidingslijn height={85} />
                  </div>

                  <div style={{ width: 175 }}>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Status',
                          waarde: `${product.productstatus.Naam}`,
                        },
                        product.productstatus.Naam === EProductstatus.UitBedrijf
                          ? {
                              label: 'Reden UB',
                              waarde: `${product.redenUB !== null ? product.redenUB : ''}`,
                            }
                          : null,
                        // {
                        //   label: 'Doelstatus',
                        //   waarde: `${
                        //     x.retourstatus !== null ? x.retourstatus.Retourstatusnaam : '-'
                        //   }`,
                        // },
                        {
                          label: 'In bedrijf',
                          waarde: `${format(new Date(product.DatumInBedrijf), 'dd-MM-yyyy')}`,
                        },
                        {
                          label: 'Uit bedrijf',
                          waarde: `${
                            product.DatumUitBedrijf !== null
                              ? format(new Date(product.DatumUitBedrijf), 'dd-MM-yyyy')
                              : ''
                          }`,
                        },
                        // {
                        //   label: 'Inkoopprijs',
                        //   waarde: `${formatteerBedrag(x.InkoopPrijs)}`,
                        // },
                      ]}
                    />
                  </div>

                  <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                    <VerticaleScheidingslijn height={85} />
                  </div>

                  <div style={{ width: 150 }}>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Ink.prijs ex.',
                          waarde: `${
                            product.Inkoopprijs !== null
                              ? formatteerBedrag(product.Inkoopprijs)
                              : ''
                          }`,
                        },
                        {
                          label: 'Verk.prijs inc.',
                          waarde: `${
                            product.verkoopprijs !== null
                              ? formatteerBedrag(product.verkoopprijs)
                              : ''
                          }`,
                        },
                      ]}
                    />
                  </div>

                  <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                    <VerticaleScheidingslijn height={85} />
                  </div>

                  <div style={{ width: 250 }}>
                    <div>
                      {product.locatie !== null
                        ? formatteerAdresV2({
                            bisnummer: product.locatie.Bisnummer,
                            straatnaam: product.locatie.Straatnaam,
                            huisnummer: product.locatie.Huisnummer,
                            postcode: product.locatie.Postcode,
                            plaatsnaam: product.locatie.Plaatsnaam,
                            landnaamEnum: product.locatie.LandnaamEnum,
                          })
                        : ''}
                    </div>
                    <div>{product.Notities !== null ? product.Notities : 'Geen notities'}</div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center"
                      style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                      onClick={() => setWijzigenProdID(product.ProdID)}
                    >
                      <IconWijzigen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                      &nbsp; Wijzigen product
                    </button>
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center ml-3"
                      style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                      onClick={() => {
                        if (product.locatie === null) {
                          return;
                        }
                        return setServicemeldingDialoogState({
                          prodID: product.ProdID,
                        });
                      }}
                    >
                      <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                      &nbsp; Servicemelding
                    </button>

                    <button
                      className="btn btn-sm btn-light d-flex align-items-center ml-3"
                      style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                      onClick={() =>
                        setVersturenDocumentatieDialoogState({ prodID: product.ProdID })
                      }
                    >
                      <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                      &nbsp; Documentatie
                    </button>

                    {product.productstatus.NaamEnum === EProductstatus.Verhuur && (
                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        onClick={() =>
                          setProductUitBedrijfDialoogStateState({ prodID: product.ProdID })
                        }
                      >
                        <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        &nbsp; Uit bedrijf melden
                      </button>
                    )}

                    {product.productstatus.NaamEnum !== EProductstatus.Verhuur && (
                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        onClick={async () => {
                          const params = { prodIDs: [product.ProdID] };
                          const checkData = await api.v2.product.checkMarkerenInBedrijf(params);

                          const controleResult = await checkStore.controleren({ checkData });
                          if (controleResult.type === EResultType.Annuleren) {
                            return;
                          }

                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: (
                                  <span>
                                    Wil je het product weer In bedrijf melden?
                                    <br />
                                    Het product komt daarmee weer met status Verhuur In bedrijf.
                                  </span>
                                ),
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          await api.v2.product.markerenInBedrijf(params);

                          props.vernieuwenContracten();
                        }}
                      >
                        <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        &nbsp; In bedrijf melden
                      </button>
                    )}

                    {product.productstatus.NaamEnum === EProductstatus.Verhuur ||
                      (product.productstatus.NaamEnum === EProductstatus.UitBedrijf && (
                        <button
                          className="btn btn-sm btn-light d-flex align-items-center ml-3"
                          style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                          onClick={async () => {
                            const params = { prodIDs: [product.ProdID] };
                            const checkData = await api.v2.contract.product.checkOntkoppelenProducten(
                              params,
                            );
                            if (
                              (await checkStore.controleren({ checkData })).type ===
                              EResultType.Annuleren
                            ) {
                              return;
                            }

                            if (
                              (
                                await checkStore.bevestigen({
                                  inhoud: (
                                    <span>
                                      Wil je het product ontkoppelen van het contract?
                                      <br />
                                      <br />
                                      Het product kom daarna in het standaard voorraadmagazijn en
                                      krijgt de status Geparkeerd.
                                    </span>
                                  ),
                                })
                              ).type === EResultType.Annuleren
                            ) {
                              return;
                            }

                            await api.v2.contract.product.ontkoppelenProducten(params);

                            props.vernieuwenContracten();
                          }}
                        >
                          <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                          &nbsp; Ontkoppelen
                        </button>
                      ))}

                    {/* <button
                          className="btn btn-sm btn-light d-flex align-items-center ml-3"
                          style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                          onClick={() => null}
                        >
                          &nbsp; Verkoopvoorstel
                        </button> */}
                    {/* <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={() => null}
                >
                  <IconTransport style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  &nbsp; Omruil product
                </button> */}
                  </div>
                </div>
                {/*<div className="mb-3 mt-3">*/}
                {/*  <HorizontaleScheidingslijn />*/}
                {/*</div>*/}
              </div>
            );
          })}
        </div>
      )}

      {servicemeldingDialoogState !== null && (
        <ServiceMeldingDialoog
          open
          prodID={servicemeldingDialoogState.prodID}
          cntID={contract.CntID}
          relID={props.relID}
          // locID={servicemeldingDialoogState.locID}
          onSuccess={async () => {
            props.vernieuwenContracten();
            setServicemeldingDialoogState(null);
          }}
          onAnnuleren={() => setServicemeldingDialoogState(null)}
        />
      )}

      {wijzigenProdID !== null && (
        <WijzigenProductDialoog
          open
          prodID={wijzigenProdID}
          onSuccess={() => {
            props.vernieuwenContracten();
            setWijzigenProdID(null);
          }}
          onAnnuleren={() => {
            setWijzigenProdID(null);
          }}
        />
      )}
      {versturenDocumentatieDialoogState !== null && (
        <VersturenDocumentatieDialoog
          open
          prodID={versturenDocumentatieDialoogState.prodID}
          relID={props.relID}
          onSuccess={() => {
            props.vernieuwenContracten();
            setVersturenDocumentatieDialoogState(null);
          }}
          onAnnuleren={() => {
            setVersturenDocumentatieDialoogState(null);
          }}
        />
      )}
      {productUitBedrijfDialoogState !== null && (
        <UitBedrijfDialoog
          open
          prodIDs={[productUitBedrijfDialoogState.prodID]}
          // redenUB_NaamEnum={ERedenUitBedrijf.NietRetourKlant}
          onSuccess={async () => {
            props.vernieuwenContracten();
            setProductUitBedrijfDialoogStateState(null);
          }}
          onAnnuleren={() => {
            setProductUitBedrijfDialoogStateState(null);
          }}
        />
      )}
    </>
  );
};

export default Product;
