import React, { useCallback, useContext, useMemo, useState } from 'react';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/creditmanagement';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import * as Yup from 'yup';
import nameof from '../../../../../../core/nameOf';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';

interface IFormikValues {
  naamEnum: string;
  parameters: string;
}

const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  naamEnum: 'NaamEnum',
  parameters: 'Parameters',
};

const validationSchema = Yup.object().shape({
  naamEnum: Yup.string().nullable(),
  parameters: Yup.string().nullable(),
});

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const creditmanagementdienst = context.creditmanagementdienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      naamEnum: creditmanagementdienst.NaamEnum ?? '',
      parameters: creditmanagementdienst.Parameters || '',
    };
  }, [creditmanagementdienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        id: creditmanagementdienst.CmgDienstID,
        parameters: values.parameters === '' ? null : values.parameters,
        naamEnum: values.naamEnum,
      };

      const checkData = await api.v2.dienst.creditmanagement.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.creditmanagement.wijzigenDienst(params);
      context.onVerversenCreditmanagementdienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, creditmanagementdienst, context.onVerversenSmsdienstAangevraagd],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={
                    <div className="p-3">
                      <div className="row mb-3">
                        <div className="col-6">
                          <label>{veldnamen.naamEnum}</label>
                          <Field
                            name={nameof<IFormikValues>('naamEnum')}
                            render={(x: FieldProps<IFormikValues>) => (
                              <>
                                <input className="form-control" {...x.field} />
                                <FormikVeldFout fieldProps={x} />
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12">
                          <label>{veldnamen.parameters}</label>
                          <Field
                            name={nameof<IFormikValues>('parameters')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return <textarea {...field} className="form-control" rows={2} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
