import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox, { IOptie } from '../../../formulier/Combobox';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { IOphalenRelatieSelectieRecentResultElement } from '../../../../../../shared/src/api/v2/relatie/Selectie';
import api, { IPaginatiePositie } from '../../../../api';
import {
  EHoedanigheid,
  ERelatieSoort,
  hoedanigheidKeyMap,
  hoedanigheidMap,
  RelatieSelectieDialoogContext,
} from '../index';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import VanMijOfIemandAndersSelectie from '../../../VanMijOfIemandAndersSelectie';
import ASPTabel from '../../../tabel/ASPTabel';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import { formatteerAdresV2, formatteerRelatieNaam } from '../../../../helpers';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconOrganisatie, IconPersoon } from '../../../Icons';
import { format } from 'date-fns';

interface IResult {
  relID: number;
  persID?: number;
}

enum EKolom {
  Relatiesoort,
  Naam,
  Adres,
  Relatienummer,
  GerefereerdOp,
  Actie,
}

interface IData {
  relaties: Record<number, IOphalenRelatieSelectieRecentResultElement>;
  totaalAantal: number;
}

interface IProps {}

const RecentTabblad: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const { koppelOpties, onGekozen, hoedanigheid, onHoedanigheidChange } = useContext(
    RelatieSelectieDialoogContext,
  );

  const [gebruikerId, setGebruikerId] = useState<number>(gebruikerStore.gebruiker!.AspGebrID);
  const dataRef = useRef<IRemoteData<IData>>(createPendingRemoteData());
  const [dataState, setDataState] = useState(dataRef.current);
  const data = useMemo(() => dataState, [dataState]);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setDataState(data);
  }, []);

  const ophalenData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
      const filters: IFilterSchemaFilter[] = [];
      if (koppelOpties.relatieSoort !== ERelatieSoort.Beide) {
        filters.push({
          naam: 'RELATIESOORT',
          data: koppelOpties.relatieSoort === ERelatieSoort.Persoon ? 'PERSOON' : 'ORGANISATIE',
        });
      }
      if (hoedanigheid !== EHoedanigheid.Beide) {
        filters.push({
          naam: 'HOEDANIGHEID_NAAM_ENUMS',
          data: [hoedanigheidKeyMap[hoedanigheid]!],
        });
      }

      const result = await api.v2.selectie.ophalenRelatieselectieRecent({
        aspGebrID: gebruikerId,
        filterSchema: {
          filters,
        },
        relatieFilterSchema: koppelOpties.relatieFilterSchema,
        paginatie,
      });

      const newData: IData = {
        totaalAantal: result.totaalAantal,
        relaties: result.relaties.reduce(
          (acc, relatie, i) => ({
            ...acc,
            [paginatie.index + i]: relatie,
          }),
          uitbreiden ? dataRef.current?.data?.relaties ?? {} : {},
        ),
      };

      setData(createReadyRemoteData(newData));
    },
    [gebruikerId, hoedanigheid, koppelOpties.relatieFilterSchema, setData],
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenData({ index: 0, aantal: 50 }, false);
  }, [ophalenData]);

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie: IPaginatiePositie) => {
      await ophalenData(paginatie, true);
    },
    [ophalenData],
  );

  const gebruikersRijbron = useMemo(() => {
    if (gebruikerStore.gebruikers === null) {
      return null;
    }

    return gebruikerStore.gebruikers.filter(
      (gebr) => gebr.AspGebrID !== gebruikerStore.gebruiker!.AspGebrID,
    );
  }, [gebruikerStore.gebruikers, gebruikerStore.gebruiker]);

  const keyExtractor = useCallback(
    (row: IOphalenRelatieSelectieRecentResultElement) => row.RelID,
    [],
  );

  const hoedanigheidOpties = useMemo<IOptie<EHoedanigheid>[]>(() => {
    return Object.keys(EHoedanigheid)
      .map((x) => Number(x) as EHoedanigheid)
      .filter((x) => !isNaN(x))
      .map((hoedanigheid) => ({
        id: hoedanigheid,
        label: hoedanigheidMap[hoedanigheid],
      }));
  }, [gebruikersRijbron]);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenRelatieSelectieRecentResultElement>[]>(
    () => [
      {
        key: EKolom.Relatiesoort,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 40,
        renderer: (entiteit) => {
          const iconStyle: React.CSSProperties = {
            width: 18,
            height: 18,
            fill: Kleur.Grijs,
          };
          return (
            <span className="ml-2">
              {entiteit.Relatiesoort === 'P' ? (
                <IconPersoon style={iconStyle} />
              ) : (
                <IconOrganisatie style={iconStyle} />
              )}
            </span>
          );
        },
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (entiteit) => {
          return formatteerRelatieNaam({
            voorvoegsel: entiteit.Voorvoegsel,
            voornaam: entiteit.Voornaam,
            voorletters: entiteit.Voorletters,
            achternaam: entiteit.Achternaam || undefined,
            aanhefKort: entiteit.AanhefKort || undefined,
            organisatienaam: entiteit.OrganisatienaamKort,
            relatiesoort: entiteit.Relatiesoort,
          });
        },
      },
      {
        key: EKolom.Adres,
        label: 'Adres',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (entiteit) => {
          const a = entiteit.adres;
          if (a === null) {
            return null;
          }
          return formatteerAdresV2({
            plaatsnaam: a.Plaatsnaam,
            landnaamKort: a.LandnaamKort,
            landnaamEnum: a.LandnaamEnum,
            huisnummer: a.Huisnummer,
            straatnaam: a.Straatnaam,
            postcode: a.Postcode,
            bisnummer: a.Bisnummer,
          });
        },
      },
      {
        key: EKolom.Relatienummer,
        label: 'Rel.nr.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (entiteit) => {
          return entiteit.Relatienummer;
        },
      },
      {
        key: EKolom.GerefereerdOp,
        label: 'Gerefereerd',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (entiteit) => {
          return format(new Date(entiteit.Referentiedatum), 'dd-MM HH:mm');
        },
      },
      {
        key: EKolom.Actie,
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (entiteit) => {
          return (
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                onGekozen(entiteit.RelID);
              }}
            >
              Kies
            </a>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <div className="d-flex">
        <VanMijOfIemandAndersSelectie
          selectieAspGebrID={gebruikerId}
          onSelectieAspGebrIDChange={(x) => setGebruikerId(x!)}
        />

        <div className="flex-fill" />
        <div className="d-flex align-items-center p-2 pr-4">
          <div className="d-flex align-items-center">
            <span>Hoedanigheid</span>
          </div>
          <div className="d-flex align-items-center ml-2">
            <Combobox<EHoedanigheid>
              geselecteerd={hoedanigheid}
              onSelectieChange={(x) => onHoedanigheidChange(x!)}
              legeOptieTonen={false}
              opties={hoedanigheidOpties}
            />
          </div>
        </div>
      </div>

      <div className="p-0 mt-2 d-flex flex-column" style={{ height: 500 }}>
        {data.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ASPTabel
            rijen={data.data!.relaties}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            totaalAantalRijen={data.data!.totaalAantal}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
          />
        )}
      </div>
    </div>
  );
});

export default RecentTabblad;
