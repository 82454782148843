import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { v4 as uuid_v4 } from 'uuid';

interface IRenderProps<T> {
  destroy: (result?: T) => void;
}

interface IGlobaleRendererContext {
  render: <T>(comp: React.ComponentType<IRenderProps<T>>) => Promise<T | undefined>;
}

export const GlobaleRendererContext = React.createContext<IGlobaleRendererContext>(null as any);

interface IEntry<T> {
  id: string;
  resolve: (result?: T) => void;
  comp: React.ComponentType<IRenderProps<T>>;
}

interface IProps {}

const GlobaleRenderer: React.FC<IProps> = (props) => {
  const [entries, setEntries] = React.useState<Record<string, IEntry<any>>>({});

  const render = useCallback(
    (comp: React.ComponentType<IRenderProps<any>>) => {
      const id = uuid_v4();
      let resolve: (result?: any) => void;
      const promise = new Promise((r) => {
        resolve = r;
      });
      const entry: IEntry<any> = {
        id,
        comp,
        resolve: resolve!,
      };

      setEntries((prev) => {
        return {
          ...prev,
          [id]: entry,
        };
      });

      return promise;
    },
    [setEntries],
  );

  const contextValue = useMemo(
    (): IGlobaleRendererContext => ({
      render: render as any,
    }),
    [render],
  );

  return (
    <>
      <GlobaleRendererContext.Provider value={contextValue}>
        {props.children}
        <div className="globale-renderer-portal">
          {Object.keys(entries).map((key, index) => {
            const entry = entries[key];
            const Comp = entry.comp;
            return (
              <div key={key}>
                <Comp
                  destroy={(result: any) => {
                    entry.resolve(result);
                    setEntries((prev) => {
                      const cpy = { ...prev };
                      delete cpy[key];
                      return cpy;
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </GlobaleRendererContext.Provider>
    </>
  );
};

export default GlobaleRenderer;
