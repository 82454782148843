import {
  IOphalenOntvangersselectiesParams,
  IToevoegenOntvangersselectieParams,
  IToevoegenOntvangersselectieResult,
  IWijzigenOntvangersselectieParams,
  IWijzigenOntvangersselectieResult,
  IVerwijderenOntvangersselectiesParams,
  IVerwijderenOntvangersselectiesResult,
  IOphalenOntvangersselectiesResult,
} from '../../../../../../shared/src/api/v2/bulkbericht/ontvangersselectie';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IOphalenEmailadressenViaSelectieQueryParams,
  IOphalenEmailadressenViaSelectieQueryResult,
} from '../../../../../../shared/src/api/v2/bulkbericht';

const ontvangersselectie = {
  ophalenOntvangersselecties: async (
    params: IOphalenOntvangersselectiesParams,
  ): Promise<IOphalenOntvangersselectiesResult> => {
    return await api.post('/v2/bulkbericht/ontvangersselectie/ophalen-ontvangersselecties', params);
  },
  checkToevoegenOntvangersselectie: async (
    params: IToevoegenOntvangersselectieParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/ontvangersselectie/check-toevoegen-ontvangersselectie',
      params,
    );
  },
  toevoegenOntvangersselectie: async (
    params: IToevoegenOntvangersselectieParams,
  ): Promise<IToevoegenOntvangersselectieResult> => {
    return await api.post(
      '/v2/bulkbericht/ontvangersselectie/toevoegen-ontvangersselectie',
      params,
    );
  },
  checkWijzigenOntvangersselectie: async (
    params: IWijzigenOntvangersselectieParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/ontvangersselectie/check-wijzigen-ontvangersselectie',
      params,
    );
  },
  wijzigenOntvangersselectie: async (
    params: IWijzigenOntvangersselectieParams,
  ): Promise<IWijzigenOntvangersselectieResult> => {
    return await api.post('/v2/bulkbericht/ontvangersselectie/wijzigen-ontvangersselectie', params);
  },
  checkVerwijderenOntvangersselecties: async (
    params: IVerwijderenOntvangersselectiesParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/ontvangersselectie/check-verwijderen-ontvangersselecties',
      params,
    );
  },
  verwijderenOntvangersselecties: async (
    params: IVerwijderenOntvangersselectiesParams,
  ): Promise<IVerwijderenOntvangersselectiesResult> => {
    return await api.post(
      '/v2/bulkbericht/Ontvangersselectie/verwijderen-ontvangersselecties',
      params,
    );
  },
};

export default ontvangersselectie;
