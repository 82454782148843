import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../../core/useUrlState';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { DXTableCheckboxComponent, TypedColumnExtension } from '../../../../helpers/dxTableGrid';
import {
  DXCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedDataTypeProvider,
  TypedTableColumnWidthInfo,
  DXTableToggleCellComponent,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
  TableSelection,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import MenuLayout from '../../../../components/MenuLayout';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import SelectieVak from '../../../../components/SelectieVak';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../core/nameOf';
import { addMonths, format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import * as _ from 'lodash';
import { EResultType } from '../../../../stores/CheckStore';
import DetailComp from '../FacturenDetailComp';
import {
  IOphalenAanmaningenResultElement,
  IVersturenAanmaningenResult,
} from '../../../../../../shared/src/api/v2/aanmaning';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import KeuzeDialoog, {
  EKeuzeType,
  IOptie,
  ISelectieResult,
} from '../../../../components/dialogen/KeuzeDialoog';
import { EKanaal } from '../../../../bedrijfslogica/enums';
import { IconVerwijderen } from '../../../../components/Icons';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import { Helmet } from 'react-helmet';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import WijzigenAanmaningdatumDialoog from '../../../../components/debiteuren/WijzigenAanmaningdatumDialoog';

interface IProps extends RouteComponentProps {}

interface IWijzigenAanmaningdatumDialoogState {
  ids: number[];
}

interface IUrlState {
  selectie: number[];
  wijzigenAanmaningdatumDialoogState: IWijzigenAanmaningdatumDialoogState | null;
}
const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenAanmaningdatumDialoogState: null,
};

interface IVersturenAanmaningenDialoogState {}

const geenData = {
  noData: 'Geen aanmaningen gevonden',
};

const Definitief: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [aanmaningen, setAanmaningen] = useState<IOphalenAanmaningenResultElement[] | null>(null);
  const { checkStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);

  const [filterNogVersturen, setFilterNogVersturen] = useState<boolean>(true);
  const [
    versturenAanmaningenDialoogState,
    setVersturenAanmaningenDialoogState,
  ] = useState<IVersturenAanmaningenDialoogState | null>(null);

  const ophalenAanmaningen = useCallback(async () => {
    const datumVanaf = addMonths(new Date(), -6);

    const aanmaningen = (
      await api.v2.aanmaning.ophalenAanmaningen({
        filterSchema: {
          filters: [
            { naam: 'DEFINITIEF', data: true },
            { naam: 'AANMANINGDATUM_VANAF', data: datumVanaf },
            filterNogVersturen ? { naam: 'VERSTUURD', data: false } : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        orderSchema: { orders: [{ naam: 'DATUMVERSTUURD', richting: 'DESC' }] },
      })
    ).aanmaningen;

    // const aanmaningenGesorteerd = _.orderBy(aanmaningen, ['Aanmaningdatum'], ['desc']);

    setAanmaningen(aanmaningen);
  }, [filterNogVersturen]);

  useEffect(() => {
    ophalenAanmaningen();
  }, [ophalenAanmaningen]);

  const handleVersturenDialoogOnSuccess = useCallback(
    async (result: ISelectieResult<EKanaal>) => {
      setVersturenAanmaningenDialoogState(null);

      const params = { aanmIDs: urlState.selectie, kanalen: result.keuze };

      const achtergrondproces = await api.v2.aanmaning.versturenAanmaningenAchtergrond({
        aanmIDs: params.aanmIDs,
      });

      const procesResult = await achtergrondProcesAfwachten<IVersturenAanmaningenResult>(
        achtergrondproces.id,
        realtimeContext,
      );
      if (procesResult.type === 'ERROR') {
        await checkStore.melden({
          titel: 'Er heeft een fout plaatsgevonden',
        });
        // actions.setSubmitting(false);
        return;
      }
      if (procesResult.type === 'TIMEOUT') {
        await checkStore.melden({
          titel: 'Het proces duurde te lang en is daarom afgebroken',
        });
        // actions.setSubmitting(false);
        return;
      }

      setUrlStateSync('selectie', []);
      ophalenAanmaningen();
    },
    [setVersturenAanmaningenDialoogState, urlState.selectie],
  );

  const handleVerwijderenAanmaningen = useCallback(
    async (aanmIDs: number[]) => {
      const checkData = await api.v2.aanmaning.checkVerwijderenAanmaningen({
        AanmIDs: aanmIDs,
      });
      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Aanmaning(en) verwijderen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.aanmaning.verwijderenAanmaningen({
        AanmIDs: aanmIDs,
      });

      ophalenAanmaningen();
      setUrlStateSync('selectie', []);
    },
    [urlState.selectie],
  );

  const keyExtractor = useCallback((row: IOphalenAanmaningenResultElement) => row.AanmID, []);

  const kolommen = useMemo<TypedColumn<IOphalenAanmaningenResultElement>[]>(() => {
    return [
      {
        name: 'Aanmaningdatum',
        title: 'Aanm.datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__kosten' as any,
        title: 'Kosten',
        getCellValue: (x) => _.sum(x.facturen.map((x) => x.KostenAanmaning)),
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
      {
        name: 'DatumVerstuurd',
        title: 'Verstuurd',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenAanmaningenResultElement>[]
  >(() => {
    return [
      {
        columnName: 'Aanmaningdatum',
        width: 100,
      },
      {
        columnName: 'Vervaldatum',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__kosten' as any,
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 300,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
      {
        columnName: 'DatumVerstuurd',
        width: 150,
      },
    ];
  }, []);

  const kolomExtensies: TypedColumnExtension<IOphalenAanmaningenResultElement>[] = useMemo(() => {
    return [
      // {
      //   columnName: 'Bedrag',
      //   align: 'right',
      // },
      // {
      //   columnName: '__kosten' as any,
      //   align: 'right',
      // },
    ];
  }, []);

  const kanaalOpties = useMemo<IOptie<EKanaal>[]>(() => {
    return [
      {
        id: EKanaal.Email,
        label: 'Email',
      },

      {
        id: EKanaal.SMS,
        label: 'SMS',
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Definitieve Aanmaningen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const params = { aanmIDs: urlState.selectie };
                  const checkData = await api.v2.aanmaning.checkVersturenAanmaningen(params);
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  setVersturenAanmaningenDialoogState({});
                }}
              >
                {/* <span>
                  <Icon style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span> */}
                <span className="ml-2">Versturen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => {
                  setUrlStateSync('wijzigenAanmaningdatumDialoogState', { ids: urlState.selectie });
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Aanmaningsdatum aanpassen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => {
                  handleVerwijderenAanmaningen(urlState.selectie);
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="d-flex align-items-center ml-3">
                <VinkVeld
                  aangevinkt={filterNogVersturen}
                  onGewijzigd={(x) => {
                    setFilterNogVersturen(x);
                  }}
                />
                <span className="ml-2">Nog te versturen</span>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              {aanmaningen !== null && (
                <>
                  <SelectieVak
                    totaalAantal={aanmaningen.length}
                    aantal={urlState.selectie.length}
                    entiteitEnkelvoud="tarief"
                    entiteitMeervoud="tarieven"
                    onChange={(allesGeselecteerd) => {
                      if (allesGeselecteerd) {
                        setUrlStateSync('selectie', aanmaningen.map(keyExtractor));
                      } else {
                        setUrlStateSync('selectie', []);
                      }
                    }}
                  />
                  <span className="ml-2">Totaal {aanmaningen.length} aanmaningen</span>
                </>
              )}
            </div>
          </>
        }
        body={
          aanmaningen === null ? (
            <LoadingSpinner />
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid getRowId={keyExtractor} rows={aanmaningen} columns={kolommen}>
                <DataTypeProvider
                  for={[nameOf<IOphalenAanmaningenResultElement>('Aanmaningdatum')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenAanmaningenResultElement>('Vervaldatum')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <TypedDataTypeProvider
                  for={['Bedrag']}
                  formatterComponent={(formatterProps) => (
                    <FormatteerBedrag bedrag={formatterProps.value} />
                  )}
                />

                <TypedDataTypeProvider
                  for={['__kosten']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenAanmaningenResultElement;

                    const kosten = _.sum(rij.facturen.map((x) => x.KostenAanmaning));
                    if (kosten === 0) {
                      return <span></span>;
                    }

                    return <FormatteerBedrag bedrag={kosten} />;
                  }}
                />

                <TypedDataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => (
                    <RelatieVisualisatie
                      relID={formatterProps.row.RelID}
                      relatieLinkBuilder={(hoedanigheid, relID) =>
                        `/${
                          hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                        }/${relID}/facturen/aanmaningen`
                      }
                    />
                  )}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenAanmaningenResultElement>('DatumVerstuurd')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenAanmaningenResultElement>('RecordToegevoegd')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                  }}
                  onCommitChanges={() => null}
                />

                <RowDetailState defaultExpandedRowIds={[]} />

                <VirtualTable
                  messages={geenData}
                  columnExtensions={kolomExtensies}
                  estimatedRowHeight={43}
                />

                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />
                <TableEditColumn
                  width={35}
                  // showAddCommand={true}
                  // showEditCommand
                  showDeleteCommand
                  commandComponent={DXCommandComponent}
                />
                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />

      {versturenAanmaningenDialoogState && (
        <KeuzeDialoog
          titel="Aanmaningen versturen"
          type={EKeuzeType.Enkel}
          opties={kanaalOpties}
          open
          onSuccess={handleVersturenDialoogOnSuccess}
          onAnnuleren={() => setVersturenAanmaningenDialoogState(null)}
          // meerOptions={{
          //   beginSelectie: [EKanaal.Email],
          // }}
        />
      )}
      {urlState.wijzigenAanmaningdatumDialoogState !== null && (
        <WijzigenAanmaningdatumDialoog
          open
          ids={urlState.wijzigenAanmaningdatumDialoogState.ids}
          onSuccess={() => {
            setUrlStateSync('wijzigenAanmaningdatumDialoogState', null);
            // setUrlStateSync('selectie', []);
            ophalenAanmaningen();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenAanmaningdatumDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Definitief);
