import React, { SVGProps, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { ETelefoonLand } from '../../../../../../bedrijfslogica/enums';
import {
  VlagBE,
  VlagBR,
  VlagCN,
  VlagDE,
  VlagEN,
  VlagES,
  VlagFR,
  VlagIT,
  VlagMX,
  VlagNL,
  VlagRU,
  VlagUS,
} from '../../../../../../components/Icons';
import useBijGewijzigdEffect from '../../../../../../core/useBijGewijzigdEffect';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  display: flex;
  flex: 1 auto;

  input {
    border: 0;
    outline: 0;
    padding: 3px 3px;
    background: 0;
    font-size: 17px;
    width: 100%;
    margin-left: 5px;

    ::placeholder {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

interface IProps {
  telefoonnummer: string | null;
  onChange: (telefoonnummer: string | null) => void;
}

const TelefoonnummerInput = (props: IProps) => {
  // const [isFocussed, setIsFocussed] = useState(false);
  const [text, setText] = useState<string>(props.telefoonnummer ?? '');

  const telefoonLandVanTelefoonnummer = useMemo(() => {
    if (props.telefoonnummer === null) {
      return null;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    const nummer = phoneUtil.parseAndKeepRawInput(props.telefoonnummer, 'NL');
    const code = nummer.getCountryCode();

    switch (code) {
      case 31:
        return ETelefoonLand.Nederland;
      case 49:
        return ETelefoonLand.Duitsland;
      case 33:
        return ETelefoonLand.Frankrijk;
      case 44:
        return ETelefoonLand.VerenigdKoninkrijk;
      case 39:
        return ETelefoonLand.Italie;
      case 32:
        return ETelefoonLand.Belgie;
      case 34:
        return ETelefoonLand.Spanje;
      case 86:
        return ETelefoonLand.China;
      case 7:
        return ETelefoonLand.Rusland;
      case 1:
        return ETelefoonLand.VerenigdeStaten;
      case 55:
        return ETelefoonLand.Brazilie;
      case 52:
        return ETelefoonLand.Mexico;
    }

    return null;
  }, [props.telefoonnummer]);

  useBijGewijzigdEffect(() => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    let nummer: PhoneNumber | null = null;
    try {
      nummer = phoneUtil.parseAndKeepRawInput(text);
    } catch (err) {
      try {
        nummer = phoneUtil.parseAndKeepRawInput(text, 'NL');
      } catch (err) {}
    }

    if (nummer !== null) {
      const nummerString = phoneUtil.format(nummer, PhoneNumberFormat.E164);
      props.onChange(nummerString);
    } else {
      props.onChange(null);
    }
  }, [text]);

  useBijGewijzigdEffect(() => {
    setText(props.telefoonnummer ?? '');
  }, [props.telefoonnummer]);

  const Vlag = useMemo<React.ComponentType<SVGProps<any>> | null>(() => {
    if (telefoonLandVanTelefoonnummer === null) {
      return null;
    }

    switch (telefoonLandVanTelefoonnummer) {
      case ETelefoonLand.Italie:
        return VlagIT;
      case ETelefoonLand.Belgie:
        return VlagBE;
      case ETelefoonLand.Spanje:
        return VlagES;
      case ETelefoonLand.China:
        return VlagCN;
      case ETelefoonLand.Rusland:
        return VlagRU;
      case ETelefoonLand.VerenigdeStaten:
        return VlagUS;
      case ETelefoonLand.Nederland:
        return VlagNL;
      case ETelefoonLand.Duitsland:
        return VlagDE;
      case ETelefoonLand.Frankrijk:
        return VlagFR;
      case ETelefoonLand.VerenigdKoninkrijk:
        return VlagEN;
      case ETelefoonLand.Brazilie:
        return VlagBR;
      case ETelefoonLand.Mexico:
        return VlagMX;
    }
  }, [telefoonLandVanTelefoonnummer]);

  return (
    <Root>
      <div
        style={{
          outline: 0,
          border: 0,
          background: 0,
          padding: '6px 6px',
          fontSize: 12,
        }}
      >
        {Vlag !== null ? (
          <Vlag style={{ width: 21, height: 14 }} />
        ) : (
          <div
            style={{
              width: 21,
              height: 15,
              backgroundColor: Kleur.LichtGrijs,
              position: 'relative',
              top: 3,
            }}
          />
        )}
      </div>
      <input
        value={text}
        onChange={(ev) => setText(ev.target.value)}
        className="flex-fill"
        placeholder="Voer een telefoonnummer in of zoek een persoon of organisatie op naam..."
      />
    </Root>
  );
};

export default TelefoonnummerInput;
