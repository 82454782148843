import {
  IBepalenEnVastleggenProlongatiesParams,
  IBepalenEnVastleggenProlongatiesResult,
  IBepalenProlongatiegegevensParams,
  IBepalenProlongatiegegevensResult,
  IVerwerkenProlongatiesParams,
  IVerwerkenProlongatiesResult,
  IVerwerkenProlongatievorderingenParams,
  IVerwerkenProlongatievorderingenResult,
  IVerwijderenProlongatieRunsParams,
  IVerwijderenProlongatieRunsResult,
} from '../../../../../shared/src/api/v2/prolongatieV2';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const prolongatieV2 = {
  bepalenEnVastleggenProlongatiesAchtergrondProces: async (
    params: IBepalenEnVastleggenProlongatiesParams,
  ): Promise<IAchtergrondProces<IBepalenEnVastleggenProlongatiesResult>> => {
    return await api.post(
      '/v2/prolongatie-v2/bepalen-en-vastleggen-prolongaties-achtergrond-process',
      params,
    );
  },
  checkVerwijderenProlongatieRuns: async (
    params: IVerwijderenProlongatieRunsParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/prolongatie-v2/check-verwijderen-prolongatie-runs', params);
  },
  verwijderenProlongatieRuns: async (
    params: IVerwijderenProlongatieRunsParams,
  ): Promise<IVerwijderenProlongatieRunsResult> => {
    return await api.post('/v2/prolongatie-v2/verwijderen-prolongatie-runs', params);
  },
  verwerkenProlongatievorderingen: async (
    params: IVerwerkenProlongatievorderingenParams,
  ): Promise<IVerwerkenProlongatievorderingenResult> => {
    return await api.post('/v2/prolongatie-v2/verwerken-prolongatie-vorderingen', params);
  },
  checkVerwerkenProlongaties: async (params: IVerwerkenProlongatiesParams): Promise<ICheckData> => {
    return await api.post('/v2/prolongatie-v2/check-verwerken-prolongaties', params);
  },
  verwerkenProlongaties: async (
    params: IVerwerkenProlongatiesParams,
  ): Promise<IVerwerkenProlongatiesResult> => {
    return await api.post('/v2/prolongatie-v2/verwerken-prolongaties', params);
  },
  verwerkenProlongatiesAchtergrondProces: async (
    params: IVerwerkenProlongatiesParams,
  ): Promise<IAchtergrondProces<IVerwerkenProlongatiesResult>> => {
    return await api.post('/v2/prolongatie-v2/verwerken-prolongaties-achtergrond-proces', params);
  },
  bepalenProlongatiegegevens: async (
    params: IBepalenProlongatiegegevensParams,
  ): Promise<IBepalenProlongatiegegevensResult> => {
    return await api.post('/v2/prolongatie-v2/bepalen-prolongatiegegevens', params);
  },
};

export default prolongatieV2;
