import styled from 'styled-components';
import { Kleur } from '../../bedrijfslogica/constanten';

const width = 300;

export const Root = styled.div`
  position: absolute;
  right: -${width}px;
  top: 0;
  bottom: 0;
  z-index: 1050;

  .container {
    position: absolute;
    right: 0;
    height: 100vh;
    background: ${Kleur.Wit};
    width: ${width}px;
    transition: right 225ms ease-in-out;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .container-zichtbaar {
    right: ${width}px;
  }

  .scroll {
    padding: 10px;
    overflow-y: scroll;
    position: absolute;
    top: 35px;
    right: 0;
    bottom: 0;
    width: 100%;
  }
`;
