import React from 'react';
import PaginaHeader from '../PaginaHeader';
import { CommunicatieNavigatie } from '../Communicatie/styles';
import { NavLink } from 'react-router-dom';
import { EyeIcon, IconLijst } from '../../components/Icons';
import { Redirect, Route } from 'react-router';
import { RouteComponentProps } from 'react-router';
import Beheer from '../Beheer/Gebruikers';
import Autorisatie from './Autorisatie';

interface IProps extends RouteComponentProps {}

const ASPGebruikers: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <CommunicatieNavigatie>
          <li>
            <NavLink
              to={`${match.url}/beheer`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconLijst />
              &nbsp; Beheer
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/autorisatie`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <EyeIcon />
              &nbsp; Autorisatie
            </NavLink>
          </li>
        </CommunicatieNavigatie>
      </PaginaHeader>

      <Route path={`${match.path}/beheer`} component={Beheer} />
      <Route path={`${match.path}/autorisatie`} component={Autorisatie} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/beheer`} />}
      />
    </div>
  );
};

export default ASPGebruikers;
