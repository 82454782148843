import React, { useCallback, useEffect, useState, useMemo } from 'react';
import api from '../../../../api';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IRegel } from '../../../../../../shared/src/api/v2/factuur';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import MenuLayout from '../../../../components/MenuLayout';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import contract from '../../../../api/v2/contract';

type Props = any; // TableSummaryRow.ContentProps;

const RowDetailComp: React.FunctionComponent<Props> = (props) => {
  const row = props.row as any;

  const [regels, setRegels] = useState<IRegel[] | null>(null);

  const ophalenRegels = useCallback(async () => {
    const { regels } = await api.v2.factuur.ophalenFactuurregels({
      filterSchema: { filters: [{ naam: 'FACT_IDS', data: [props.row.FactID] }] },
    });

    setRegels(regels);
  }, [props.row.FactID]);

  useEffect(() => {
    ophalenRegels();
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.FactRegID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr.',
      },
      {
        name: '__periodeVan' as any,
        title: 'Van',
      },
      {
        name: '__periodeTot' as any,
        title: 'Tot',
      },

      {
        name: '__locatie',
        title: 'Locatie',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Omschrijving',
        width: 400,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: '__periodeVan' as any,
        width: 100,
      },
      {
        columnName: '__periodeTot' as any,
        width: 100,
      },
      {
        columnName: 'Kenmerk' as any,
        width: 250,
      },
      {
        columnName: '__locatie' as any,
        width: 250,
      },
    ],
    [],
  );

  return (
    <>
      {regels === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <MenuLayout
            body={
              <div className="d-flex">
                <TabelInspringBlok />
                <GridStyleWrapper maxHeight={500} rowAmount={regels.length}>
                  <Grid columns={kolommen} getRowId={keyExtractor} rows={regels!}>
                    <DataTypeProvider
                      for={['Bedrag']}
                      formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                    />

                    <DataTypeProvider
                      for={['__contractnummer']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegel = formatterProps.row;
                        if (rij.contract === null) {
                          return <span />;
                        }

                        return (
                          <span>
                            {rij.contract.Basisnummer}.{rij.contract.Volgnummer}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__periodeVan']}
                      formatterComponent={(props) => {
                        const van =
                          props.row.periodeVan !== null
                            ? format(new Date(props.row.periodeVan), 'dd-MM-yyyy')
                            : '';
                        return <span>{van}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__periodeTot']}
                      formatterComponent={(props) => {
                        const tot =
                          props.row.periodeTot !== null
                            ? format(new Date(props.row.periodeTot), 'dd-MM-yyyy')
                            : '';
                        return <span>{tot}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__locatie']}
                      formatterComponent={(props) => {
                        const locatie =
                          props.row.contract !== null ? props.row.contract.locatie : null;
                        const adres =
                          locatie !== null
                            ? locatie.Straatnaam +
                              ' ' +
                              locatie.Huisnummer +
                              (locatie.Bisnummer !== null ? ' ' + locatie.Bisnummer : '')
                            : '';
                        return <span>{adres}</span>;
                      }}
                    />

                    <VirtualTable />

                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default RowDetailComp;
