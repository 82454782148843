import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { ITabbladProps } from '../../Aanbod';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import useUrlState from '../../../../core/useUrlState';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  RowDetailState,
  SelectionState,
  EditingState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  IconToevoegen,
  IconVerwijderen,
  IconSend,
  IconInformatie,
} from '../../../../components/Icons';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import NieuwOpdrachtDialoog from '../NieuwOpdrachtDialoog';
import WijzigenOpdrachtDialoog from '../WijzigenOpdrachtDialoog';
import { format } from 'date-fns';
import VersturenDialoog from '../VersturenDialoog';
import SelectieVak from '../../../../components/SelectieVak';
import * as _ from 'lodash';
import nameOf from '../../../../core/nameOf';
import VoorraadTypeInfoDialoog from '../../../../components/transport/VoorraadTypeInfoDialoog';
import { IOphalenProducttypenInkoopdienstenResultElement } from '../../../../../../shared/src/api/v2/inkoop/aanbod';
import ProducttypeVisualisatie from '../../../../components/entiteitVisualisaties/ProducttypeVisualisatie';
import { Helmet } from 'react-helmet';

interface IProps extends ITabbladProps, RouteComponentProps {}

interface IUrlState {
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
};

const geenData = {
  noData: 'Geen inkoopopdrachten',
};

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

interface IRegel extends IOphalenOpdrachtenResultElement {}

const NieuwTab: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const [opdrachten, setOpdrachten] = useState<IRegel[] | null>(null);
  const [opdrachtenUitstaand, setOpdrachtenUitstaand] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);
  const [tonenNieuwOpdrachtDialoog, setTonenNieuwOpdrachtDialoog] = useState<boolean>(false);
  const [inkOpdID, setInkOpdID] = useState<number | null>(null);
  const [tonenVersturenDialoog, setTonenVersturenDialoog] = useState<boolean>(false);
  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);
  const [inkoopgegevens, setInkoopgegevens] = useState<
    IOphalenProducttypenInkoopdienstenResultElement[] | null
  >(null);

  const keyExtractor = useCallback((row: IOphalenOpdrachtenResultElement) => row.InkOpdID, []);

  const ophalenOpdrachten = useCallback(async () => {
    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'IS_VERSTUURD', data: false }] },
    });

    setOpdrachten(result.inkoopopdrachten);
  }, []);

  useEffect(() => {
    ophalenOpdrachten();
  }, []);

  useEffect(() => {
    (async () => {
      if (opdrachten === null) {
        return;
      }
      const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            { naam: 'IS_VERSTUURD', data: true },
            { naam: 'IS_AFGEHANDELD', data: false },
          ],
        },
      });

      setOpdrachtenUitstaand(result.inkoopopdrachten);
    })();
  }, [opdrachten]);

  // Inkoopgegevens voor de type/inkoopdienst combinaties
  useEffect(() => {
    (async () => {
      if (opdrachten === null) {
        return;
      }

      const params = {
        producttypenInkoopdiensten: opdrachten.map((x) => {
          return { typeID: x.producttype.TypeID, inkDienstID: x.dienst.ID };
        }),
      };
      const result = await api.v2.inkoop.aanbod.ophalenProducttypenInkoopdiensten(params);
      setInkoopgegevens(result.producttypenInkoopdiensten);
    })();
  }, [opdrachten]);

  const handleVerwijderen = useCallback(async () => {
    const params = { inkOpdIDs: urlState.selectie };
    const checkData = await api.v2.inkoop.opdracht.checkVerwijderenOpdrachten(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Inkoopopdrachten verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.inkoop.opdracht.verwijderenOpdrachten(params);

    setUrlStateSync('selectie', []);
    await ophalenOpdrachten();
  }, [urlState.selectie]);

  const handleMakenVoorstel = useCallback(async () => {
    const checkData = await api.v2.inkoop.opdracht.checkToevoegenOpdrachtenVoorstel({});
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Voorstel maken?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setUrlStateSync('selectie', []);
    const result = await api.v2.inkoop.opdracht.toevoegenOpdrachtenVoorstel({});
    await checkStore.melden({
      titel: `Er zijn ${
        result.aantalOpdrachten === 0 ? 'geen' : result.aantalOpdrachten
      } opdrachten gemaakt`,
    });

    await ophalenOpdrachten();
  }, [urlState.selectie]);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      // {
      //   name: '__merknaam' as any,
      //   title: 'Merk',
      //   getCellValue: (x) => x.producttype.Merknaam,
      // },
      // {
      //   name: '__typenaam' as any,
      //   title: 'Type',
      //   getCellValue: (x) => x.producttype.Typenaam,
      // },
      {
        name: '__producttype' as any,
        title: 'Product',
      },
      {
        name: '__voorraadInfo' as any,
        title: 'Vrd.info',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
        getCellValue: (x) => x.producttype.ProductsoortnaamKort,
      },
      {
        name: '__leveranciernaamKort' as any,
        title: 'Leverancier',
        getCellValue: (x) => x.dienst.relatie?.organisatie?.Naam,
      },
      {
        name: 'Aantal',
        title: 'Aantal',
      },
      {
        name: 'Referentie',
        title: 'Referentie',
      },
      {
        name: '__magazijnnaamKort',
        title: 'Magazijn',
      },
      {
        name: 'DatumGewenst',
        title: 'Gew. leverdatum',
      },
      {
        name: '__leverstatusType' as any,
        title: 'Leverstatus Type',
      },
      {
        name: '__ookUitstaandeOpdrachten' as any,
        title: 'Atl. uitst.',
      },
      {
        name: 'Voorstel',
        title: 'Bron',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 115,
      },
      {
        columnName: '__typenaam' as any,
        width: 185,
      },
      {
        columnName: '__producttype' as any,
        width: 250,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 95,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 100,
      },
      {
        columnName: '__leveranciernaamKort' as any,
        width: 150,
      },
      {
        columnName: 'Aantal',
        width: 100,
      },
      {
        columnName: 'Referentie',
        width: 125,
      },
      {
        columnName: '__magazijnnaamKort',
        width: 100,
      },
      {
        columnName: 'DatumGewenst',
        width: 150,
      },
      {
        columnName: '__leverstatusType' as any,
        width: 200,
      },
      {
        columnName: '__ookUitstaandeOpdrachten' as any,
        width: 115,
      },
      {
        columnName: 'Voorstel',
        width: 115,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 200,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Nieuwe Inkoopopdrachten</title>
      </Helmet>
      <div>
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => setTonenNieuwOpdrachtDialoog(true)}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Nieuwe inkoopopdracht</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => handleMakenVoorstel()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Voorstel maken</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                // const checkData = await api.v2.inkoop.opdracht.checkVersturenOpdrachten({
                //   inkOpdIDs: urlState.selectie,
                //   opdWijzeID: null,
                // });
                // if (
                //   (
                //     await checkStore.controleren({
                //       checkData,
                //     })
                //   ).type === EResultType.Annuleren
                // ) {
                //   return;
                // }

                return setTonenVersturenDialoog(true);
              }}
            >
              <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Versturen</span>
            </button>
          </div>
          <div className="mt-2 d-flex align-items-center">
            {opdrachten !== null && (
              <>
                <SelectieVak
                  totaalAantal={opdrachten.length}
                  aantal={urlState.selectie.length}
                  entiteitEnkelvoud="opdracht"
                  entiteitMeervoud="opdrachten"
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      setUrlStateSync('selectie', opdrachten!.map(keyExtractor));
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                />
                <span className="ml-2">Totaal {opdrachten.length} opdrachten</span>
              </>
            )}
          </div>
        </div>

        <div
          style={{
            backgroundColor: Kleur.Wit,
          }}
        >
          {opdrachten === null || opdrachtenUitstaand === null || inkoopgegevens === null ? (
            <LoadingSpinner />
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid columns={kolommen} getRowId={keyExtractor} rows={opdrachten}>
                <DataTypeProvider
                  for={['__magazijnnaamKort']}
                  formatterComponent={(props) => {
                    return <span>{props.row.magazijn.NaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__leveranciernaamKort']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;
                    return <span>{rij.dienst.relatie?.organisatie?.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__productsoortnaamKort']}
                  formatterComponent={(props) => {
                    return <span>{props.row.producttype.ProductsoortnaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__merknaam']}
                  formatterComponent={(props) => {
                    return <span>{props.row.producttype.Merknaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__leverstatusType']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;

                    const inkoopdata = inkoopgegevens.find(
                      (x) => x.TypeID === rij.producttype.TypeID && x.InkDienstID === rij.dienst.ID,
                    );

                    if (inkoopdata === undefined) {
                      return <span></span>;
                    }

                    const verwacht =
                      inkoopdata.DatumVerwacht !== null
                        ? format(new Date(inkoopdata.DatumVerwacht), 'dd-MM-yyyy')
                        : null;

                    return (
                      <span style={{ color: !inkoopdata.DirectLeverbaar ? Kleur.Rood : undefined }}>
                        {inkoopdata.DirectLeverbaar
                          ? `Direct leverbaar`
                          : verwacht !== null
                          ? `Verwacht rond ${verwacht}`
                          : `Onbekend`}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__typenaam']}
                  formatterComponent={(props) => {
                    return <span>{props.row.producttype.Typenaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__producttype']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;

                    return <ProducttypeVisualisatie typeID={rij.producttype.TypeID} />;
                  }}
                />

                <DataTypeProvider
                  for={['DatumGewenst']}
                  formatterComponent={(props) => {
                    const datum =
                      props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy') : 'Z.s.m.';
                    return (
                      <>
                        <span>{datum}</span>
                        {/*<span className="ml-2">{format(date, 'HH:mm')}</span>*/}
                      </>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['RecordToegevoegd']}
                  formatterComponent={(props) => {
                    return (
                      <>
                        <span>{format(new Date(props.value), 'dd-MM-yyyy HH:mm')}</span>
                      </>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenOpdrachtenResultElement>('Voorstel')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>
                        {formatterProps.value ? <span>Voorstel</span> : <span>Opdracht</span>}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__voorraadInfo']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      <div className="d-flex">
                        <a
                          href="#"
                          onClick={() => {
                            setVoorraadTypeInfoDialoogState({
                              typeID: rij.producttype.TypeID,
                              magID: rij.magazijn.MagID,
                            });
                          }}
                        >
                          <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                        </a>
                      </div>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__ookUitstaandeOpdrachten']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const aantal = _.sum(
                      opdrachtenUitstaand
                        .filter(
                          (x) =>
                            x.magazijn.MagID === rij.magazijn.MagID &&
                            x.producttype.TypeID === rij.producttype.TypeID,
                        )
                        .map((x) => x.Aantal - x.AantalGeleverdeProducten),
                    );
                    return <span>{aantal !== 0 ? aantal : ''}</span>;
                  }}
                />

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <EditingState
                  onCommitChanges={() => null}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    //alert(id);
                    setInkOpdID(id);
                  }}
                />
                <RowDetailState defaultExpandedRowIds={[]} />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable messages={geenData} />

                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  width={35}
                  showEditCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
                {/* <Table messages={geenData} /> */}
                {/* <TableRowDetail contentComponent={opdrachtDetailComp} /> */}
              </Grid>
            </GridStyleWrapper>
          )}
        </div>
      </div>
      {tonenNieuwOpdrachtDialoog && (
        <NieuwOpdrachtDialoog
          prodSrtID={instellingStore.ProdSrtID}
          open
          onSuccess={() => {
            setTonenNieuwOpdrachtDialoog(false);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => {
            setTonenNieuwOpdrachtDialoog(false);
          }}
        />
      )}
      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
      {inkOpdID !== null && (
        <WijzigenOpdrachtDialoog
          inkOpdID={inkOpdID}
          open
          onSuccess={() => {
            setInkOpdID(null);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => setInkOpdID(null)}
        />
      )}
      {tonenVersturenDialoog && (
        <VersturenDialoog
          inkOpdIDs={urlState.selectie}
          open
          onSuccess={() => {
            setTonenVersturenDialoog(false);
            setUrlStateSync('selectie', []);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => {
            setTonenVersturenDialoog(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(NieuwTab);
