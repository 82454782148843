import * as React from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Dialoog from '../../dialogen/Dialoog';
import { useCallback, useContext, useMemo } from 'react';
import { dagDatum } from '../../../helpers/datum';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { ModalBody, ModalTitle } from 'react-bootstrap';
import BedragInput from '../../formulier/BedragInput';
import nameOf from '../../../core/nameOf';
import DatumKiezer from '../../formulier/DatumKiezer';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../api';
import { addDays } from 'date-fns';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';

interface IDialoogOutput {}

interface IProps extends IDialoogProps<IDialoogOutput> {
  initieleWaarden?: Partial<INieuwContractTariefFormikValues>;
  cntBasisIDs: number[];
}

export interface INieuwContractTariefFormikValues {
  maandhuur: number;
  ingangsdatum: Date;
  reden: string;
}

const NieuwContractTariefDialoog = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const initialValues: INieuwContractTariefFormikValues = useMemo(
    () => ({
      maandhuur: 0,
      ingangsdatum: dagDatum(new Date()),
      reden: '',
      ...props.initieleWaarden,
    }),
    [props.initieleWaarden],
  );

  const handleSubmit = useCallback(
    async (
      values: INieuwContractTariefFormikValues,
      actions: FormikActions<INieuwContractTariefFormikValues>,
    ) => {
      actions.setSubmitting(true);

      const params = {
        ingangsdatum: values.ingangsdatum,
        reden: values.reden,
        maandhuur: values.maandhuur,
        cntBasisIDs: props.cntBasisIDs,
      };
      const checkData = await api.v2.contract.tarief.checkToevoegenContracttarief(params);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.contract.tarief.toevoegenContracttarief({
        ingangsdatum: values.ingangsdatum,
        reden: values.reden,
        maandhuur: values.maandhuur,
        cntBasisIDs: props.cntBasisIDs,
      });

      const output: IDialoogOutput = {};
      props.onSuccess(output);

      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Nieuw contract tarief</ModalTitle>
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isInitialValid
        render={(formikProps) => (
          <Formulier onAnnuleren={props.onAnnuleren} formikProps={formikProps} />
        )}
      />
    </Dialoog>
  );
};

interface IFormulierProps {
  formikProps: FormikProps<INieuwContractTariefFormikValues>;
  onAnnuleren: () => void;
}

const Formulier = (props: IFormulierProps): JSX.Element => {
  const { isSubmitting, isValid, submitForm } = props.formikProps;

  return (
    <>
      <ModalBody>
        <div className="form-group">
          <label htmlFor="tarief">Tarief</label>
          <Field
            name={nameOf<INieuwContractTariefFormikValues>('maandhuur')}
            render={({ field, form }: FieldProps<INieuwContractTariefFormikValues>) => (
              <BedragInput
                value={field.value}
                onChange={(x) => form.setFieldValue(field.name, x)}
              />
            )}
          />
        </div>
        <div className="form-group">
          <label htmlFor="ingangsdatum">Ingangsdatum</label>
          <Field
            name={nameOf<INieuwContractTariefFormikValues>('ingangsdatum')}
            render={({ field, form }: FieldProps<INieuwContractTariefFormikValues>) => {
              const minimaleDatum = addDays(new Date(), -100);
              const maximaleDatum = addDays(new Date(), +370);
              return (
                <DatumKiezer
                  waarde={field.value}
                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  determineValidDate={(date) => {
                    // Vandaag en de toekomst
                    return date >= dagDatum(new Date());
                  }}
                  determineNextValidDate={(date) => {
                    const nieuweDatum = addDays(date, +1);
                    if (nieuweDatum <= maximaleDatum) {
                      return nieuweDatum;
                    }
                    return null;
                  }}
                  determinePreviousValidDate={(date) => {
                    const nieuweDatum = addDays(date, -1);
                    if (nieuweDatum >= minimaleDatum) {
                      return nieuweDatum;
                    }
                    return null;
                  }}
                />
              );
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reden">Reden</label>
          <Field
            name={nameOf<INieuwContractTariefFormikValues>('reden')}
            render={({ field }: FieldProps<INieuwContractTariefFormikValues>) => (
              <textarea
                {...field}
                id="reden"
                name="reden"
                className="form-control"
                rows={2}
                placeholder="Bijv.: n.a.v. trouwen klant"
              />
            )}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default NieuwContractTariefDialoog;
