import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import api from '../../../../api';
import { Kleur as EKleur, Kleur } from '../../../../bedrijfslogica/constanten';
import BankopdrachtInfoDialoog from '../../../../components/bankopdracht/BankopdrachtInfoDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IconInformatie, IconSend } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import nameOf from '../../../../core/nameOf';
import useUrlState from '../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';

interface IProps extends RouteComponentProps {}

enum EFilter {
  SpecificatieIsVerstuurd = 'SPECIFICATIE_IS_VERSTUURD',
}

export interface IBankopdrachtInfoDialoogState {
  bankOpdID: number;
}

interface IUrlState {
  selectie: number[];
  bankopdrachtInfoDialoogState: IBankopdrachtInfoDialoogState | null;
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  bankopdrachtInfoDialoogState: null,
  filterData: [
    {
      naam: EFilter.SpecificatieIsVerstuurd,
      data: false,
      isActief: true,
    },
  ],
};

const Specificaties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);

  const ophalenOpdrachten = useCallback(async () => {
    const batches = await api.v2.bank.opdracht.ophalenBatches({
      filterSchema: {
        filters: [
          { naam: 'IS_VERSTUURD', data: true },
          { naam: 'IS_UITGEVOERD', data: false },
          { naam: 'SOORT', data: 2 },
        ],
      },
    });

    if (batches.length === 0) {
      setOpdrachten([]);
      return;
    }
    const batchIDs = batches.map((x) => x.BatchID);

    const opdrachtenResult = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [...filterSchema.filters!, { naam: 'BATCH_IDS', data: batchIDs }],
      },
      //   orderSchema: { orders: [{ naam: 'UITVOERDATUM', richting: 'DESC' }] },
      paginatie: { index: 0, aantal: 100 },
    });

    const relIDs = _.uniq(opdrachtenResult.opdrachten.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          { naam: 'IDS', data: relIDs },
          { naam: 'BETAALSPECIFICATIE_VERSTUREN', data: true },
        ],
      },
    });

    const opdrachtenWaarvoorSpecificatie = opdrachtenResult.opdrachten.filter(
      (x) =>
        x.RelID !== null && relatiesResult.relaties.map((x) => x.RelID).indexOf(x.RelID) !== -1,
    );

    // const result = _.orderBy(
    //   batchesResult,
    //   [
    //     (x: IOphalenBatchesResultElement) => {
    //       return x.UitgevoerdOp === null ? 0 : 1;
    //     },
    //     (x: IOphalenBatchesResultElement) => {
    //       return format(new Date(x.Uitvoerdatum!), 'yyyy-MM-dd');
    //     },
    //     'Batchnummer',
    //   ],
    //   ['asc', 'desc', 'desc'],
    // );

    setOpdrachten(opdrachtenWaarvoorSpecificatie);
  }, [filterSchema.filters]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const kolommen = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(() => {
    return [
      {
        name: 'Uitvoerdatum',
        title: 'Uitvoerdatum',
        // getCellValue: (x) => x.Uitvoerdatum,
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Betalingskenmerk',
        title: 'Betalingskenmerk',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        // getCellValue: (x) => x.Uitvoerdatum,
      },
      {
        name: 'SpecificatieVerstuurd' as any,
        title: 'Verstuurd',
      },
      {
        name: '__bankopdrachtinfo' as any,
        title: ' ',
      },
    ];
  }, []);

  const keyExtractor = useCallback((row: IOphalenOpdrachtenResultElement) => row.BankOpdID, []);

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]
  >(() => {
    return [
      {
        columnName: 'Uitvoerdatum',
        width: 130,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: 'Bedrag' as any,
        width: 110,
      },
      {
        columnName: 'Betalingskenmerk',
        width: 175,
      },
      {
        columnName: 'Omschrijving',
        width: 350,
      },
      {
        columnName: 'SpecificatieVerstuurd',
        width: 150,
      },
      {
        columnName: '__bankopdrachtinfo' as any,
        width: 85,
      },
    ];
  }, []);

  const handleVersturen = useCallback(async () => {
    const params = { bankOpdIDs: urlState.selectie };

    const checkData = await api.v2.bank.opdracht.checkVersturenBetaalspecificaties(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je voor de geselecteerde ${urlState.selectie.length} bankopdrachten een betaalspecificatie versturen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const result = await api.v2.bank.opdracht.versturenBetaalspecificaties(params);

    setUrlStateSync('selectie', []);
    ophalenOpdrachten();
  }, [urlState.selectie]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.SpecificatieIsVerstuurd,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Nog te versturen</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      {opdrachten === null ? (
        <div className="">
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Specificaties</title>
          </Helmet>
          <MenuLayout
            menu={
              <>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center"
                      style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                      disabled={urlState.selectie.length === 0}
                      onClick={() => handleVersturen()}
                    >
                      <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                      <span className="ml-2">Versturen Specificaties</span>
                    </button>
                  </div>
                  <div className="d-flex flex-fill ml-3">
                    <FilterBalkV2
                      filters={filters}
                      filterData={urlState.filterData}
                      onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                      onFilterSchemaChange={(x) => setFilterSchema(x)}
                    />
                  </div>
                </div>
              </>
            }
            body={
              <>
                {opdrachten === null ? (
                  <div className="d-flex flex-fill align-items-center justify-content-center p-5 pl-4 pr-4">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <AutoSizer
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    {(size) => (
                      <GridStyleWrapper height={size.height}>
                        <Grid rows={opdrachten} columns={kolommen} getRowId={keyExtractor}>
                          <DataTypeProvider
                            for={['Uitvoerdatum']}
                            formatterComponent={(formatterProps) => {
                              const rij = formatterProps.row as IOphalenOpdrachtenResultElement;
                              if (rij.Uitvoerdatum === null) {
                                return <span></span>;
                              }
                              return (
                                <>
                                  <span>{format(new Date(rij.Uitvoerdatum), 'dd-MM-yyyy')}</span>
                                  {/*<span className="ml-2">{format(date, 'HH:mm')}</span>*/}
                                </>
                              );
                            }}
                          />

                          <DataTypeProvider
                            for={[nameOf<IOphalenOpdrachtenResultElement>('SpecificatieVerstuurd')]}
                            formatterComponent={(formatterProps) => {
                              if (formatterProps.value === null) {
                                return <span>Niet verstuurd</span>;
                              }
                              return (
                                <>
                                  <span>
                                    {format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}
                                  </span>
                                </>
                              );
                            }}
                          />

                          <DataTypeProvider
                            for={['__relatie']}
                            formatterComponent={(formatterProps) => {
                              const rij = formatterProps.row as IOphalenOpdrachtenResultElement;
                              return (
                                <span className="d-flex">
                                  <RelatieVisualisatie relID={rij.RelID!} />
                                </span>
                              );
                            }}
                          />

                          <DataTypeProvider
                            for={['Bedrag']}
                            formatterComponent={(formatterProps) => (
                              <FormatteerBedrag bedrag={formatterProps.value} />
                            )}
                          />

                          <DataTypeProvider
                            for={['__bankopdrachtinfo']}
                            formatterComponent={(formatterProps) => {
                              const rij = formatterProps.row as IOphalenOpdrachtenResultElement;

                              return (
                                <>
                                  <a
                                    href="#"
                                    className="ml-1"
                                    style={{
                                      color: Kleur.LichtBlauw,
                                      position: 'relative',
                                      bottom: 2,
                                    }}
                                    onClick={() => {
                                      setUrlStateSync('bankopdrachtInfoDialoogState', {
                                        bankOpdID: rij.BankOpdID,
                                      });
                                    }}
                                  >
                                    <IconInformatie
                                      style={{ width: 15, height: 15, fill: Kleur.Blauw }}
                                    />
                                  </a>
                                </>
                              );
                            }}
                          />

                          <SelectionState
                            selection={urlState.selectie}
                            onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                          />
                          {/* <RowDetailState
                            expandedRowIds={props.uitgeklapteOpdrachten}
                            onExpandedRowIdsChange={(value) =>
                              props.onUitgeklapteOpdrachtenChange(value as number[])
                            }
                          /> */}

                          <SortingState defaultSorting={[]} />
                          <IntegratedSorting />
                          <VirtualTable
                            messages={{
                              noData: 'Geen betaalspecificaties voor het ingestelde filter',
                            }}
                          />
                          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                          <TableHeaderRow showSortingControls />

                          {/* <EditingState
                            onCommitChanges={(changes) => {
                              if (changes.deleted === undefined) {
                                return;
                              }
                              const deleted = changes.deleted;
                              const id = deleted[deleted.length - 1];
                            }}
                            onEditingRowIdsChange={(rowIds) => {
                              const id = rowIds[rowIds.length - 1] as number;
                              props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                            }}
                          /> */}

                          {/* <TableRowDetail
                            toggleCellComponent={DXTableToggleCellComponent}
                            contentComponent={Regels}
                          /> */}
                          <TableSelection cellComponent={DXTableCheckboxComponent} />
                        </Grid>
                      </GridStyleWrapper>
                    )}
                  </AutoSizer>
                )}
              </>
            }
          />
        </>
      )}
      {urlState.bankopdrachtInfoDialoogState !== null && (
        <BankopdrachtInfoDialoog
          open
          bankOpdID={urlState.bankopdrachtInfoDialoogState.bankOpdID}
          onSuccess={() => null}
          onAnnuleren={() => setUrlStateSync('bankopdrachtInfoDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Specificaties);
