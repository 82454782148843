import React, { useCallback } from 'react';
import { IContentProps } from '../../shared';
import NavigerenPijl from '../../Rechts/NavigerenPijl';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';

const RelatieContent = (props: IContentProps) => {
  const handleNavigeren = useCallback(() => {}, []);

  return (
    <div style={{ paddingLeft: 35, paddingRight: 20 }} className="d-flex flex-column">
      {props.ids.map((relID, i) => (
        <div key={relID} className="p-2 d-flex align-items-center">
          <span className="flex-fill">
            <RelatieVisualisatie relID={relID} />
          </span>
          <NavigerenPijl onClick={handleNavigeren} />
        </div>
      ))}
    </div>
  );
};

export default RelatieContent;
