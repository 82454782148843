import React, { useCallback, useContext, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as dienstFormulierValidationSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/incasso';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import nameof from '../../../../../../core/nameOf';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import TijdVeld, { ITijdVeldWaarde } from '../../../../../../components/formulier/TijdVeld';
import * as Yup from 'yup';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import TelefoonnummerInput from '../../../../../../components/formulier/TelefoonnummerInput';

interface IFormikValues extends IDienstFormulierFormikValues {
  emailOpdrachten: string | null;
  emailKlanten: string | null;
  telefoonKlanten: string | null;
}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  emailOpdrachten: 'Email tbv opdrachten',
  emailKlanten: 'Email tbv klanten',
  telefoonKlanten: 'Telefoon tbv klanten',
};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const incassodienst = context.incassodienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [incassoUitgeklapt, setIncassoUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      actief: incassodienst.Actief,
      naam: incassodienst.Naam || '',
      naamEnum: incassodienst.NaamEnum ?? '',
      opdWijzeID: incassodienst.OpdWijzeID ?? null,
      parameters: incassodienst.Parameters || '',

      emailOpdrachten: incassodienst.EmailOpdrachten || '',
      emailKlanten: incassodienst.EmailKlanten || '',
      telefoonKlanten: incassodienst.TelefoonKlanten,
    };
  }, [incassodienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        id: incassodienst.ID,
        parameters: values.parameters === '' ? null : values.parameters,
        opdWijzeID: values.opdWijzeID,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,
        emailOpdrachten: values.emailOpdrachten === '' ? null : values.emailOpdrachten,
        emailKlanten: values.emailKlanten === '' ? null : values.emailKlanten,
        telefoonKlanten: values.telefoonKlanten === '' ? null : values.telefoonKlanten,
      };

      const checkData = await api.v2.dienst.incasso.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.incasso.wijzigenDienst(params);
      context.onVerversenIncassodienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, incassodienst, context.onVerversenIncassodienstAangevraagd],
  );

  const validationSchema = useMemo(
    () =>
      dienstFormulierValidationSchema.concat(
        Yup.object().shape({
          emailOpdrachten: Yup.string(),
          emailKlanten: Yup.string().nullable(),
          telefoonKlanten: Yup.string().nullable(),
        }),
      ),
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="Incasso"
                  uitgeklapt={incassoUitgeklapt}
                  onUitgeklaptChange={setIncassoUitgeklapt}
                  formulier={
                    <div className="p-3">
                      <div className="row mb-3">
                        {/* <div className="col-6">
                          <label>{veldnamen.levertermijn}</label>
                          <Field
                            name={nameof<IFormikValues>('levertermijn')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={0}
                                    max={99}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div> */}
                        {/* <div className="col-6">
                          <label>{veldnamen.tijdUiterlijkOpdracht}</label>
                          <Field
                            name={nameof<IFormikValues>('tijdUiterlijkOpdracht')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <TijdVeld
                                    waarde={field.value}
                                    onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div> */}
                      </div>
                      <div className="row d-flex flex-column">
                        <div className="col-6">
                          <label>{veldnamen.emailOpdrachten}</label>
                          <Field
                            name={nameof<IFormikValues>('emailOpdrachten')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-6 mt-3">
                          <label>{veldnamen.emailKlanten}</label>
                          <Field
                            name={nameof<IFormikValues>('emailKlanten')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-6 mt-3">
                          <label>{veldnamen.telefoonKlanten}</label>
                          <Field
                            name={nameof<IFormikValues>('telefoonKlanten')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;

                              return (
                                <TelefoonnummerInput
                                  telefoonnummer={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  // suggestiesProvider={
                                  //   telefoonnummerInputSuggestiesProvider
                                  // }
                                />
                              );

                              // return (
                              //   <div>
                              //     <input className="form-control" {...fieldProps.field} />

                              //     <FormikVeldFout fieldProps={fieldProps} />
                              //   </div>
                              // );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
