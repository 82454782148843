import Overzicht from './Overzicht';

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../EntiteitContainer';

export interface ITabbladComponentProps {
  relID: number;
}

interface IProps extends IEntiteitProps, RouteComponentProps {}

const Communicatie: React.FC<IProps> = (props) => {
  return (
    <div className="d-flex flex-column flex-fill">
      <Overzicht relID={props.relID} />
    </div>
  );
};

export default withRouter(Communicatie);
