import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialoog from '../Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import AfbeeldingKader from '../../weergave/AfbeeldingKader';
import { IconKruis } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import MediaWeergave, { MediaWeergaveItem, MediaID } from '../../MediaWeergave';

interface IProps extends IDialoogProps {
  mediaWeergaven: MediaWeergaveItem[];
  current?: MediaID;
  onCurrentChange?: (id: MediaID) => void;
  defaultCurrent?: MediaID;
  title?: string;
  navigatieTonenMetEnkeleMediaWeergave?: boolean;
}

const MediaWeergaveDialoog: React.FC<IProps> = (props) => {
  const {
    children,
    current,
    defaultCurrent,
    dialoogIndex,
    mediaWeergaven,
    onAnnuleren,
    onCurrentChange,
    onSuccess,
    open,
    title,
    navigatieTonenMetEnkeleMediaWeergave,
  } = props;
  const [mediaID, setMediaID] = useState<MediaID | null>(defaultCurrent ?? null);

  const currentMediaWeergave = useMemo(() => {
    const currentID = current ?? mediaID;
    return mediaWeergaven.find((x) => x.id === currentID)!;
  }, [current, mediaWeergaven, mediaID]);

  const modalTitle = useMemo(() => {
    if (currentMediaWeergave.title !== undefined) {
      return currentMediaWeergave.title;
    }
    if (title !== undefined) {
      return title;
    }

    return 'Mediaweergave';
  }, [currentMediaWeergave, title]);

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        size: 'lg',
      }}
      onBackdropClick={onAnnuleren}
    >
      <ModalHeader>
        <ModalTitle>{modalTitle}</ModalTitle>
        <div className="flex-fill" />
        <a href="#" onClick={() => onAnnuleren()}>
          <IconKruis style={{ width: 23, height: 23, fill: Kleur.Grijs }} />
        </a>
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <MediaWeergave
          {...props}
          current={currentMediaWeergave?.id ?? undefined}
          onCurrentChange={(id) => {
            if (current === undefined) {
              setMediaID(id);
              return;
            }
            onCurrentChange?.(id);
          }}
          imageStyle={{
            maxHeight: 600,
          }}
        />
      </ModalBody>
    </Dialoog>
  );
};

export default MediaWeergaveDialoog;
