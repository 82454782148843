import React from 'react';
import { Content, Root } from './style';

interface IProps {
  rootProps?: React.HTMLAttributes<HTMLDivElement>;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
  linkerKolom?: JSX.Element;
  rechterKolom?: JSX.Element;
}

const Tegel: React.FC<IProps> = (props) => {
  const { linkerKolom, rechterKolom } = props;

  return (
    <Root {...props.rootProps}>
      {linkerKolom !== undefined && <div className="d-flex flex-column">{linkerKolom}</div>}
      <Content className="d-flex flex-column" {...props.contentProps}>
        {props.children}
      </Content>
      {rechterKolom !== undefined && <div className="d-flex flex-column">{rechterKolom}</div>}
    </Root>
  );
};

export default Tegel;
