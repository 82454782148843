import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { CrediteurContext, IContext } from '..';
import { IOphalenRekeningenResultElement } from '../../../../../../shared/src/api/v2/relatie';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import GegevensLayout from '../../../layout/GegevensLayout';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import { ITabbladProps } from '../../../product/ProductinfoDialoog';
import {
  IOphalenBtwSoortenResult,
  IOphalenBtwSoortenResultElement,
} from '../../../../../../shared/src/api/v2/btw';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';

interface IProps extends ITabbladProps, RouteComponentProps {}

const CrediteurTab: React.FC<IProps> = (props) => {
  const { relatie } = useContext<IContext>(CrediteurContext);

  const crediteur = relatie!.crediteur;

  const [btwSoortenResult, setBtwSoortenResult] = useState<
    IRemoteData<IOphalenBtwSoortenResult | null>
  >(createPendingRemoteData());
  const ophalenBtwSoorten = useCallback(async () => {
    if (crediteur === null) {
      setBtwSoortenResult(createPendingRemoteData());
      return;
    }
    const ids = [crediteur.BtwSrtBank_BtwSrtID, crediteur.BtwSrtInkoop_BtwSrtID].filter(
      (x) => x !== null,
    ) as number[];
    if (ids.length === 0) {
      setBtwSoortenResult(createReadyRemoteData(null));
      return;
    }
    const result = await api.v2.btw.ophalenBtwSoorten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: ids,
          },
        ],
      },
    });
    setBtwSoortenResult(createReadyRemoteData(result));
  }, [crediteur?.BtwSrtBank_BtwSrtID, crediteur?.BtwSrtInkoop_BtwSrtID]);
  useEffect(() => {
    ophalenBtwSoorten();
  }, [ophalenBtwSoorten]);

  const crediteurBtwSoortBank = useMemo<IRemoteData<IOphalenBtwSoortenResultElement | null>>(() => {
    if (crediteur === null || btwSoortenResult.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (crediteur.BtwSrtBank_BtwSrtID === null) {
      return createReadyRemoteData(null);
    }
    const btwSoort = btwSoortenResult.data!.btwSoorten.find(
      (x) => x.ID === crediteur.BtwSrtBank_BtwSrtID,
    )!;
    return createReadyRemoteData(btwSoort);
  }, [btwSoortenResult, crediteur?.BtwSrtBank_BtwSrtID]);

  const crediteurBtwSoortInkoop = useMemo<
    IRemoteData<IOphalenBtwSoortenResultElement | null>
  >(() => {
    if (crediteur === null || btwSoortenResult.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (crediteur.BtwSrtInkoop_BtwSrtID === null) {
      return createReadyRemoteData(null);
    }
    const btwSoort = btwSoortenResult.data!.btwSoorten.find(
      (x) => x.ID === crediteur.BtwSrtInkoop_BtwSrtID,
    )!;
    return createReadyRemoteData(btwSoort);
  }, [btwSoortenResult, crediteur?.BtwSrtInkoop_BtwSrtID]);

  // Standaardrekening bovenaan zetten
  const rekeningen: IOphalenRekeningenResultElement[] = _.orderBy(
    relatie!.financieel.rekeningen,
    [
      (r: any) => (r.RelRekID === relatie!.financieel.StandaardRekening_RelRekID ? 0 : 1),
      (r: any) => r.IBAN,
    ],
    ['asc', 'asc'],
  );

  const standaardrekening =
    rekeningen.find((x) => x.RelRekID === relatie!.financieel.StandaardRekening_RelRekID) ?? null;

  const Rekeningen = () => {
    return (
      <>
        {' '}
        <div style={{ width: '100%', overflow: 'auto' }}>
          <table style={{ width: '100%' }}>
            {rekeningen.map((r: any) => (
              <tr key={r.RelRekID}>
                <td>{r.IBAN}</td>
                <td>{r.Rekeningnaam}</td>
                <td>
                  {r.RelRekID === relatie!.financieel.StandaardRekening_RelRekID ? 'Standaard' : ''}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </>
    );
  };

  return (
    <div className="d-flex flex-column flex-fill">
      {crediteur === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center p-4">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-column p-3 flex-fill" style={{ rowGap: 10 }}>
          <div>
            <div>
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte={200}
                  gegevens={[
                    {
                      label: 'Naam',
                      waarde: (
                        <RelatieVisualisatie
                          relID={crediteur.RelID}
                          options={{ geenLinkToepassen: false }}
                        />
                      ),
                    },
                    {
                      label: 'Naam kort',
                      waarde: crediteur.NaamKort,
                    },
                    // {
                    //   label: 'Naam',
                    //   waarde: '',
                    // },
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="mt-3">
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte={200}
                  gegevens={[
                    {
                      label: 'Betalingstermijn',
                      waarde: `${crediteur.Betalingstermijn} dagen`,
                    },
                    {
                      label: 'Betalingskorting',
                      waarde: `${crediteur.KortingsPercentage}%`,
                    },
                    {
                      label: 'Termijn',
                      waarde: `${crediteur.Betalingstermijn} dagen`,
                    },
                    {
                      label: 'Betaalspecificatie sturen',
                      waarde: crediteur.BetaalspecificatieVersturen ? 'Ja' : 'Nee',
                    },
                    {
                      label: 'Email',
                      waarde: crediteur.Email,
                    },
                    {
                      label: 'Standaard Btw-soort bank',
                      waarde:
                        crediteurBtwSoortBank.data === null ? '' : crediteurBtwSoortBank.data!.Naam,
                    },
                    {
                      label: 'Standaard Btw-soort inkoop',
                      waarde:
                        crediteurBtwSoortInkoop.data === null
                          ? ''
                          : crediteurBtwSoortInkoop.data!.Naam,
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            {/* <div className="mt-3">
              <VeldWeergave>
                <Rekeningen />
              </VeldWeergave>
            </div> */}

            <div className="mt-3">
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte={200}
                  gegevens={[
                    {
                      label: 'IBAN',
                      waarde: standaardrekening !== null ? standaardrekening.IBAN : '-',
                    },
                    {
                      label: 'Rekeningnaam',
                      waarde: standaardrekening !== null ? standaardrekening.Rekeningnaam : '',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="mt-3">
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte={200}
                  gegevens={[
                    {
                      label: 'Ons klantnummer',
                      waarde: crediteur.OnsKlantnummer ?? '-',
                    },
                    {
                      label: 'Vermelden',
                      waarde:
                        crediteur.OnsKlantnummer !== null
                          ? crediteur.OnsKlantnummerVermelden
                            ? 'Ja'
                            : 'Nee'
                          : '-',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            {crediteur.Notities !== null && (
              <div className="mt-3">
                <VeldWeergave>
                  <GegevensLayout
                    labelBreedte={200}
                    gegevens={[
                      crediteur.Notities !== null
                        ? {
                            label: 'Notities',
                            waarde: `${crediteur.Notities}`,
                          }
                        : null,
                    ]}
                  />
                </VeldWeergave>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CrediteurTab;
