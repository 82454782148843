import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenOpdrachtregelsResultElement } from '../../../../../../../shared/src/api/v2/transport/opdracht';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import _ from 'lodash';
import api from '../../../../../api';
import { IOphalenContractenResultElementV2 } from '../../../../../../../shared/src/api/v2/contract';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenPreviewBatchesResultElement } from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import format from 'date-fns/format';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';

interface IProps extends IDialoogProps<{}> {
  bankOpdIDs?: number[];
}

export interface IRegel extends IOphalenPreviewBatchesResultElement {}

export interface IFormikValues {}

export const veldnamen: Record<keyof IFormikValues, string> = {};

const PreviewBatchDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [previewBatches, setPreviewBatches] = useState<IRegel[] | null>(null);
  const [totaalbedrag, setTotaalbedrag] = useState<number | null>(null);

  const ophalenPreviewBatches = useCallback(async () => {
    const regels = (
      await api.v2.bank.opdracht.ophalenPreviewBatches({
        bankOpdIDs: props.bankOpdIDs,
      })
    ).batches;

    setPreviewBatches(regels);
    const totaal = _.sum(regels.map((x) => x.Bedrag));

    setTotaalbedrag(totaal);
  }, []);

  useEffect(() => {
    ophalenPreviewBatches();
  }, [ophalenPreviewBatches]);

  const initialFormikValues = useMemo<IFormikValues>(() => {
    return {};
  }, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Uitvoerdatum',
        title: 'Uitvoerdatum',
      },
      {
        name: 'Soort',
        title: 'Soort',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Aantal',
        title: 'Aantal',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Uitvoerdatum',
        width: 135,
      },
      {
        columnName: 'Soort',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 150,
      },
      {
        columnName: 'Aantal',
        width: 100,
      },
    ],
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ dialogClassName: `dialog-width-550` }}>
      <ModalHeader>
        <ModalTitle>Preview batches</ModalTitle>
      </ModalHeader>
      {previewBatches === null || totaalbedrag === null ? (
        <div className="d-flex align-items-center justify-content-center flex-fill p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <ModalBody>
            <div>
              <div className="d-flex mb-3">
                <span className="mr-1">Totaalbedrag:</span>
                <FormatteerBedrag bedrag={totaalbedrag} />
                <span className="ml-2">
                  {' '}
                  {totaalbedrag > 0 ? '(te ontvangen)' : '(te betalen)'}
                </span>
              </div>
              <GridStyleWrapper maxHeight={500} rowAmount={previewBatches.length}>
                <Grid rows={previewBatches} columns={kolommen}>
                  <DataTypeProvider
                    for={['Uitvoerdatum']}
                    formatterComponent={(formatterProps) => {
                      if (formatterProps.value === null) {
                        return <span>Z.s.m.</span>;
                      }
                      return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(formatterProps) => {
                      return <FormatteerBedrag bedrag={formatterProps.value} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['Soort']}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value === 'I' ? 'Incasso' : 'Betaling'}</span>;
                    }}
                  />

                  <VirtualTable />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-start">
            <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
              Sluiten
            </button>
          </ModalFooter>
        </>
      )}
    </Dialoog>
  );
};

export default PreviewBatchDialoog;
