import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import VeldWeergave from '../../../formulier/VeldWeergave';

interface IProps {
  vasteInfo: string;
}

const VasteInfo: React.FC<IProps> = observer((props) => {
  return (
    <div className="p-3">
      <VeldWeergave style={{ maxHeight: 130, overflow: 'auto', fontSize: 15 }}>
        <span dangerouslySetInnerHTML={{ __html: props.vasteInfo.replace(/\n/g, '<br>') }} />
      </VeldWeergave>
      {/* <textarea
        style={{ padding: 3 }}
        value={props.vasteInfo}
        rows={props.vasteInfo.length > 150 ? 5 : 2}
        className="form-control"
        disabled={true}
      /> */}
    </div>
  );
  // return <VasteInfoSectie>{props.vasteInfo}</VasteInfoSectie>;
});

export default VasteInfo;
