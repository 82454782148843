import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import HorizontaleScheidingslijn from '../../layout/HorizontaleScheidingslijn';
import FormatteerBedrag, { StandaardMutatieBedragOpmaker } from '../../MutatieBedrag';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import MenuLayout from '../../MenuLayout';
import nameOf from '../../../core/nameOf';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { DataTypeProvider, EditingState, TableRowDetail } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import TabelInspringBlok from '../../layout/TabelInspringBlok';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import api from '../../../api';
import WijzigenInkoopfactuurDialoog from './WijzigenInkoopfactuurDialoog';
import FactuurinfoDialoog from '../../factuur/FactuurinfoDialoog';
import { EFunctioneleIcon, functioneleIconMap } from '../../Icons';

interface IProps extends TableRowDetail.ContentProps {
  onVerversenAangevraagd: () => void;
}

export interface IDebiteurFactuurRegel {
  ID: number;
  FactID: number;
  Bedrag: number;
  Openstaand: number;
  Factuurdatum: Date;
  Vervaldatum: Date;
  Factuurnummer: string | null;
}

export interface ICrediteurFactuurRegel {
  ID: number;
  InkFactID: number;
  Bedrag: number;
  BedragKorting: number;
  Factuurdatum: Date;
  Vervaldatum: Date;
  Factuurnummer: string | null;
  Factuurbedrag: number;
}

export interface IWijzigenInkoopfacturenDialoogState {
  bankOpdID: number;
  inkFactID: number;
}

export interface IFactuurInfoDialoogState {
  factID: number;
}

const IconProductInfo = functioneleIconMap[EFunctioneleIcon.ProductInfo];

const RowDetailFacturen = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [
    wijzigenInkoopfactuurInOpdracht,
    setWijzigenInkoopfactuurInOpdracht,
  ] = useState<IWijzigenInkoopfacturenDialoogState | null>(null);
  const [
    factuurInfoDialoogState,
    setFactuurInfoDialoogState,
  ] = useState<IFactuurInfoDialoogState | null>(null);

  const row: IOphalenOpdrachtenResultElement = props.row;
  const debiteurFactuurRegels: IDebiteurFactuurRegel[] = row.debiteurFacturen.map((x) => {
    return {
      ID: x.ID,
      FactID: x.FactID,
      Bedrag: x.Bedrag,
      Openstaand: x.Openstaand,
      Factuurdatum: new Date(x.Factuurdatum),
      Vervaldatum: new Date(x.Vervaldatum),
      Factuurnummer: x.Factuurnummer,
    };
  });
  const crediteurFactuurRegels: ICrediteurFactuurRegel[] = row.crediteurFacturen.map((x) => {
    return {
      ID: x.ID,
      InkFactID: x.InkFactID,
      Bedrag: x.Bedrag,
      BedragKorting: x.BedragKorting,
      Factuurdatum: new Date(x.Factuurdatum),
      Vervaldatum: new Date(x.Vervaldatum),
      Factuurnummer: x.Factuurnummer,
      Factuurbedrag: x.Factuurbedrag,
    };
  });

  const debiteurFactuurKeyExtractor = useCallback((row: IDebiteurFactuurRegel) => row.ID, []);
  const crediteurFactuurKeyExtractor = useCallback((row: ICrediteurFactuurRegel) => row.ID, []);

  const debiteurFactuurKolommen = useMemo<TypedColumn<IDebiteurFactuurRegel>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: '__factuurinfo' as any,
        title: ' ',
      },
    ],
    [],
  );
  const crediteurFactuurKolommen = useMemo<TypedColumn<ICrediteurFactuurRegel>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag in opdracht',
      },
      {
        name: 'BedragKorting',
        title: 'Toegepaste korting',
      },
      {
        name: 'Factuurbedrag',
        title: 'Factuurbedrag',
      },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
    ],
    [],
  );

  const debiteurFactuurKolombreedtes = useMemo<TypedTableColumnWidthInfo<IDebiteurFactuurRegel>[]>(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Openstaand',
        width: 100,
      },
      {
        columnName: 'Factuurdatum',
        width: 150,
      },
      {
        columnName: 'Vervaldatum',
        width: 150,
      },
      {
        columnName: '__factuurinfo' as any,
        width: 75,
      },
    ],
    [],
  );
  const crediteurFactuurKolombreedtes = useMemo<
    TypedTableColumnWidthInfo<ICrediteurFactuurRegel>[]
  >(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 150,
      },
      {
        columnName: 'BedragKorting',
        width: 150,
      },
      {
        columnName: 'Factuurbedrag',
        width: 120,
      },
      {
        columnName: 'Factuurdatum',
        width: 150,
      },
      {
        columnName: 'Vervaldatum',
        width: 150,
      },
    ],
    [],
  );

  const debiteurFactuurKolomExtensies: VirtualTable.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
    ];
  }, []);
  const crediteurFactuurKolomExtensies: VirtualTable.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `BedragKorting`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Factuurbedrag`,
      //   align: 'right',
      // },
    ];
  }, []);

  const debiteurWeergave =
    debiteurFactuurRegels === null || debiteurFactuurRegels.length === 0 ? null : (
      <GridStyleWrapper maxHeight={350} rowAmount={debiteurFactuurRegels.length} zIndex={499}>
        <Grid
          rows={debiteurFactuurRegels}
          columns={debiteurFactuurKolommen}
          getRowId={debiteurFactuurKeyExtractor}
        >
          <DataTypeProvider
            for={['Bedrag']}
            formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
          />

          <DataTypeProvider
            for={['Openstaand']}
            formatterComponent={(formatterProps) => {
              const rij: IDebiteurFactuurRegel = formatterProps.row;

              return (
                <span className="d-flex">
                  <FormatteerBedrag bedrag={rij.Openstaand} />
                  {rij.Openstaand !== rij.Bedrag ? (
                    <span className="ml-1" style={{ color: EKleur.Rood }}>
                      !
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={[
              nameOf<IDebiteurFactuurRegel>('Factuurdatum'),
              nameOf<IDebiteurFactuurRegel>('Vervaldatum'),
            ]}
            formatterComponent={(formatterProps) => (
              <span>{format(formatterProps.value, 'dd-MM-yyyy')}</span>
            )}
          />

          <DataTypeProvider
            for={['__factuurinfo']}
            formatterComponent={(formatterProps) => {
              const rij: IDebiteurFactuurRegel = formatterProps.row;
              return (
                <a
                  href="#"
                  className="ml-1"
                  style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                  onClick={() => {
                    setFactuurInfoDialoogState({ factID: rij.FactID });
                  }}
                >
                  <IconProductInfo style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                </a>
              );
            }}
          />

          <EditingState
            onCommitChanges={async (changes) => {
              if (changes.deleted === undefined) {
                return;
              }
              const deleted = changes.deleted;
              const id = deleted[deleted.length - 1] as number;

              const verkoopfactuur = debiteurFactuurRegels.find((x) => x.ID === id)!;

              const params = { bankOpdID: row.BankOpdID, factIDs: [verkoopfactuur.FactID] };

              const checkData = await api.v2.bank.opdracht.checkVerwijderenVerkoopfacturenUitOpdracht(
                params,
              );
              if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: `Wil je de factuur uit de opdracht verwijderen?`,
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }
              const result = await api.v2.bank.opdracht.verwijderenVerkoopfacturenUitOpdracht(
                params,
              );
              props.onVerversenAangevraagd();
            }}
          />

          <VirtualTable columnExtensions={debiteurFactuurKolomExtensies} />
          <TableColumnResizing defaultColumnWidths={debiteurFactuurKolombreedtes} />

          <TableEditColumn
            width={35}
            showDeleteCommand
            cellComponent={DXTableEditColumnCellComponent}
            commandComponent={DXTableEditColumnCommandComponent}
          />
          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>
    );

  const crediteurWeergave =
    crediteurFactuurRegels === null || crediteurFactuurRegels.length === 0 ? null : (
      <GridStyleWrapper maxHeight={350} rowAmount={crediteurFactuurRegels.length} zIndex={499}>
        <Grid
          rows={crediteurFactuurRegels}
          columns={crediteurFactuurKolommen}
          getRowId={crediteurFactuurKeyExtractor}
        >
          <DataTypeProvider
            for={['Bedrag']}
            formatterComponent={(props) => (
              <FormatteerBedrag
                bedrag={props.value}
                opmaakComponent={(opmaakProps) => {
                  return <StandaardMutatieBedragOpmaker {...opmaakProps} color={EKleur.Blauw} />;
                }}
              />
            )}
          />
          <DataTypeProvider
            for={['BedragKorting']}
            formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
          />
          <DataTypeProvider
            for={['Factuurbedrag']}
            formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
          />

          <DataTypeProvider
            for={[
              nameOf<ICrediteurFactuurRegel>('Factuurdatum'),
              nameOf<ICrediteurFactuurRegel>('Vervaldatum'),
            ]}
            formatterComponent={(formatterProps) => (
              <span>{format(formatterProps.value, 'dd-MM-yyyy')}</span>
            )}
          />

          <EditingState
            onCommitChanges={async (changes) => {
              if (changes.deleted === undefined) {
                return;
              }
              const deleted = changes.deleted;
              const id = deleted[deleted.length - 1] as number;

              const inkoopfactuur = crediteurFactuurRegels.find((x) => x.ID === id)!;

              const params = { bankOpdID: row.BankOpdID, inkFactIDs: [inkoopfactuur.InkFactID] };

              const checkData = await api.v2.bank.opdracht.checkVerwijderenInkoopfacturenUitOpdracht(
                params,
              );
              if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: `Wil je de factuur uit de opdracht verwijderen?`,
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }
              const result = await api.v2.bank.opdracht.verwijderenInkoopfacturenUitOpdracht(
                params,
              );
              props.onVerversenAangevraagd();
            }}
            onEditingRowIdsChange={(ids) => {
              const id = ids[ids.length - 1]! as number;

              const inkoopfactuur = crediteurFactuurRegels.find((x) => x.ID === id)!;

              setWijzigenInkoopfactuurInOpdracht({
                bankOpdID: row.BankOpdID,
                inkFactID: inkoopfactuur.InkFactID,
              });
            }}
          />

          <VirtualTable columnExtensions={crediteurFactuurKolomExtensies} />
          <TableColumnResizing defaultColumnWidths={crediteurFactuurKolombreedtes} />

          <TableEditColumn
            width={65}
            showEditCommand
            showDeleteCommand
            cellComponent={DXTableEditColumnCellComponent}
            commandComponent={DXTableEditColumnCommandComponent}
          />

          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>
    );

  const heeftDebiteurEnCrediteurRegels = debiteurWeergave !== null && crediteurWeergave !== null;

  let body = (
    <div className="d-flex flex-column">
      {row.Groep === 'M' && <div className="p-3">Geen detailinformatie beschikbaar.</div>}
      <div>
        {/* {row.Groep === 'D' && <div className="p-1 pl-2 font-weight-bold">Debiteur</div>} */}
        {debiteurWeergave}
      </div>

      <div>
        {/* {row.Groep === 'C' && <div className="p-1 pl-2 font-weight-bold">Crediteur</div>} */}
        {crediteurWeergave}
      </div>
    </div>
  );

  // const heeftSignaleringen = useMemo(() => row.warnings.length + row.errors.length > 0, [
  //   row.warnings,
  //   row.errors,
  // ]);
  // if (heeftSignaleringen) {
  //   body = (
  //     <MenuLayout
  //       menu={
  //         <div>
  //           <div className="p-1 pl-2 font-weight-bold">Signaleringen</div>
  //           <div className="ml-2">
  //             {[...row.warnings, ...row.errors].map((signalering, i) => (
  //               <div key={i}>{signalering}</div>
  //             ))}
  //           </div>
  //         </div>
  //       }
  //       body={body}
  //     />
  //   );
  // }

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        {body}
      </div>
      {factuurInfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurInfoDialoogState.factID}
          onSuccess={() => {
            setFactuurInfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurInfoDialoogState(null)}
        ></FactuurinfoDialoog>
      )}
      {wijzigenInkoopfactuurInOpdracht !== null && (
        <WijzigenInkoopfactuurDialoog
          open
          bankOpdID={wijzigenInkoopfactuurInOpdracht.bankOpdID}
          inkFactID={wijzigenInkoopfactuurInOpdracht.inkFactID}
          onSuccess={() => {
            props.onVerversenAangevraagd();
            setWijzigenInkoopfactuurInOpdracht(null);
          }}
          onAnnuleren={() => setWijzigenInkoopfactuurInOpdracht(null)}
        />
      )}
    </>
  );
};

export default RowDetailFacturen;
