import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../../components/MenuLayout';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import useUrlState from '../../../../core/useUrlState';
import ExporterenDialoog from './ExporterenDialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { IOphalenExportsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';

import { format } from 'date-fns';
import api from '../../../../api';
import { IconFileDownload, IconVerwijderen } from '../../../../components/Icons';
import DownloadKnop from '../../../../components/DownloadKnop';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../models/IRemoteData';
import * as _ from 'lodash';
import {
  IOphalenDagboekenResult,
  IOphalenDagboekenResultElement,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import Skeleton from 'react-loading-skeleton';
import { AutoSizer } from 'react-virtualized';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { Helmet } from 'react-helmet';

export interface IExporterenDialoogState {}

export interface IUrlState {
  exporterenDialoogState: IExporterenDialoogState | null;
  selectie: number[];
}

interface IProps extends RouteComponentProps {}

const Export: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      exporterenDialoogState: null,
      selectie: [],
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [exports, setExports] = useState<IOphalenExportsResultElement[] | null>(null);
  const [bestanden, setBestanden] = useState<IOphalenBestandenResultElement[] | null>(null);

  const alleDagboekIDs = useMemo<IRemoteData<number[]>>(() => {
    if (exports === null) {
      return createPendingRemoteData();
    }
    const dagboekIDs = _.uniq(exports.map((x) => x.filter?.dagboekIDs ?? []).flat());
    return createReadyRemoteData(dagboekIDs);
  }, [exports]);

  const [dagboekenResult, setDagboekenResult] = useState<
    IRemoteData<IOphalenDagboekenResult | null>
  >(createPendingRemoteData());
  const dagboekenBijID = useMemo<
    IRemoteData<Record<number, IOphalenDagboekenResultElement>>
  >(() => {
    return mapRemoteData(dagboekenResult, (result) => {
      if (result === null) {
        return {};
      }
      return result.dagboeken.reduce(
        (acc, dagboek) => ({
          ...acc,
          [dagboek.ID]: dagboek,
        }),
        {},
      );
    });
  }, [dagboekenResult]);

  useEffect(() => {
    if (alleDagboekIDs.state === ERemoteDataState.Pending) {
      setDagboekenResult(createPendingRemoteData());
      return;
    }
    if (alleDagboekIDs.data!.length === 0) {
      setDagboekenResult(createReadyRemoteData(null));
      return;
    }
    api.v2.boeking.dagboek
      .ophalenDagboeken({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: alleDagboekIDs.data!,
            },
          ],
        },
      })
      .then((result) => {
        setDagboekenResult(createReadyRemoteData(result));
      });
  }, [alleDagboekIDs]);

  const ophalenExports = useCallback(async () => {
    const exportsResult = await api.v2.boeking.ophalenExports({
      filterSchema: {
        filters: [{ naam: 'RECORDTOEGEVOEGD_IS_GEVULD', data: true }],
      },
      paginatie: { index: 0, aantal: 500 },
    });

    setExports(exportsResult.exports);
  }, []);

  useEffect(() => {
    ophalenExports();
  }, [ophalenExports]);

  const ophalenBestanden = useCallback(async () => {
    if (exports === null) {
      return;
    }

    const bestanden = (
      await api.v2.bestand.ophalenBestanden({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [exports.map((x) => x.BestandID)] }],
        },
      })
    ).bestanden;

    setBestanden(bestanden);
  }, [exports]);

  useEffect(() => {
    ophalenBestanden();
  }, [ophalenBestanden]);

  const kolommen = useMemo<TypedColumn<IOphalenExportsResultElement>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Exportdatum',
      },
      {
        name: 'AantalBoekingsregels' as any,
        title: 'Aantal boekingsregels',
      },
      {
        name: '__bestand' as any,
        title: 'Bestand',
      },
      {
        name: '__proef' as any,
        title: 'Proef',
      },
      {
        name: '__filterBoekdatumVan' as any,
        title: 'Boekdatum van',
      },
      {
        name: '__filterBoekdatumTem' as any,
        title: 'Boekdatum t/m',
      },
      {
        name: '__filterOngeachtEerderGeexporteerd' as any,
        title: 'Ongeacht eerder geexp.',
      },
      {
        name: '__filterDagboekIDs' as any,
        title: 'Dagboeken / selectie',
      },
      {
        name: '__wijzigenStatusGeimporteerd' as any,
        title: 'Importstatus',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenExportsResultElement>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 150,
      },
      {
        columnName: '__proef' as any,
        width: 90,
      },
      {
        columnName: 'AantalBoekingsregels' as any,
        width: 160,
      },
      {
        columnName: '__bestand' as any,
        width: 100,
      },
      {
        columnName: '__filterBoekdatumVan' as any,
        width: 130,
      },
      {
        columnName: '__filterBoekdatumTem' as any,
        width: 130,
      },
      {
        columnName: '__filterOngeachtEerderGeexporteerd' as any,
        width: 195,
      },
      {
        columnName: '__filterDagboekIDs' as any,
        width: 500,
      },
      {
        columnName: '__wijzigenStatusGeimporteerd' as any,
        width: 125,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: '__aantalPosten',
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Boekingen Export</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => setUrlStateSync('exporterenDialoogState', {})}
            >
              <IconFileDownload style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Exporteren boekingen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={async () => {
                const params = { ids: urlState.selectie };
                const checkData = await api.v2.boeking.checkVerwijderenExports(params);
                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Geselecteerde exports verwijderen?
                          <br />
                          <br />
                          De gekoppelde boekingen worden daarbij gemarkeerd als niet-geexporteerd
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.boeking.verwijderenExports(params);

                setUrlStateSync('selectie', []);
                await ophalenExports();
              }}
              disabled={urlState.selectie.length === 0}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen Exports</span>
            </button>
          </div>
        }
        body={
          <div className="flex-fill d-flex flex-column">
            {exports === null || bestanden === null ? (
              <div className="justify-content-center align-items-center d-flex flex-fill">
                <LoadingSpinner />
              </div>
            ) : (
              <AutoSizer disableWidth className="flex-fill">
                {(size) => (
                  <GridStyleWrapper height={size.height}>
                    <Grid rows={exports} columns={kolommen} getRowId={(x) => x.ID}>
                      <DataTypeProvider
                        for={['Datum']}
                        formatterComponent={(props) => {
                          return <span>{format(new Date(props.value), 'dd-MM-yyyy HH:mm')}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__proef']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;
                          return <span>{rij.Proef ? 'Ja' : ''}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__aantalPosten']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;
                          return <span>{rij.boekingen.length}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__bestand']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;

                          return (
                            <>
                              <DownloadKnop
                                onDownloadAangevraagd={async () => {
                                  const bestand = bestanden.find((x) => x.ID === rij.BestandID)!;
                                  // await downloadUrl(downloadLink, rij.bestand.Naam);
                                  window.open(bestand.url, '_blank');
                                }}
                              />
                            </>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__filterDagboekIDs']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;
                          if (rij.filter === null) {
                            return <span>-&nbsp;-</span>;
                          }

                          if (rij.filter.boekingIDs ?? null !== null) {
                            return (
                              <span
                                style={{
                                  color: Kleur.TextMuted,
                                }}
                              >
                                Selectie
                              </span>
                            );
                          }

                          if (rij.filter.dagboekIDs === null) {
                            return (
                              <span
                                style={{
                                  color: Kleur.TextMuted,
                                }}
                              >
                                Alle dagboeken
                              </span>
                            );
                          }
                          if (dagboekenBijID.state === ERemoteDataState.Pending) {
                            return <Skeleton />;
                          }
                          const dagboeken = rij.filter.dagboekIDs.map(
                            (x) => dagboekenBijID.data![x] ?? null,
                          );
                          if (dagboeken.some((x) => x === null)) {
                            return <Skeleton />;
                          }
                          return <span>{dagboeken.map((x) => x.Naam).join(', ')}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__filterBoekdatumVan']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;
                          if (rij.filter === null) {
                            return <span>-&nbsp;-</span>;
                          }
                          return (
                            <span>
                              {rij.filter.boekdatumVan !== null
                                ? format(new Date(rij.filter.boekdatumVan), 'dd-MM-yyyy')
                                : 'Niet opgegeven'}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__filterBoekdatumTem']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;
                          if (rij.filter === null) {
                            return <span>-&nbsp;-</span>;
                          }
                          return (
                            <span>
                              {rij.filter.boekdatumTem !== null
                                ? format(new Date(rij.filter.boekdatumTem), 'dd-MM-yyyy')
                                : 'Niet opgegeven'}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__filterOngeachtEerderGeexporteerd']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;
                          if (rij.filter === null) {
                            return <span>-&nbsp;-</span>;
                          }
                          return (
                            <span>{rij.filter.ongeachtEerderGeexporteerd ? 'Ja' : 'Nee'}</span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__wijzigenStatusGeimporteerd']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenExportsResultElement = formatterProps.row;

                          return (
                            <a
                              href="#"
                              onClick={async (ev) => {
                                ev.stopPropagation();
                                await api.v2.boekhouding.boeking.wijzigenStatusGeimporteerd({
                                  ids: [rij.ID],
                                });
                                ophalenExports();
                              }}
                            >
                              {rij.IsGeimporteerd ? 'Geimporteerd' : 'Nog importeren'}
                            </a>
                          );
                        }}
                      />

                      <VirtualTable columnExtensions={kolomExtensies} />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow />
                      <SelectionState
                        selection={urlState.selectie}
                        onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                      />
                      <TableSelection cellComponent={DXTableCheckboxComponent} />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </AutoSizer>
            )}
          </div>
        }
      />
      {urlState.exporterenDialoogState !== null && (
        <ExporterenDialoog
          open
          onSuccess={async (result) => {
            await ophalenExports();
            setUrlStateSync('exporterenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('exporterenDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Export);
