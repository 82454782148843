import {
  IOphalenVerkoopmodellenParams,
  IOphalenVerkoopmodellenResult,
  IToevoegenVerkoopmodelParams,
  IToevoegenVerkoopmodelResult,
  IWijzigenVerkoopmodelParams,
  IWijzigenVerkoopmodelResult,
  IVerwijderenVerkoopmodellenParams,
  IVerwijderenVerkoopmodellenResult,
} from '../../../../../../shared/src/api/v2/aanbod/verkoop';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const verkoop = {
  ophalenVerkoopmodellen: async (
    params: IOphalenVerkoopmodellenParams,
  ): Promise<IOphalenVerkoopmodellenResult> => {
    return api.post('v2/aanbod/verkoop/ophalen-verkoopmodellen', params);
  },
  checkToevoegenVerkoopmodel: async (params: IToevoegenVerkoopmodelParams): Promise<ICheckData> => {
    return api.post('v2/aanbod/verkoop/check-toevoegen-verkoopmodel', params);
  },
  toevoegenVerkoopmodel: async (
    params: IToevoegenVerkoopmodelParams,
  ): Promise<IToevoegenVerkoopmodelResult> => {
    return api.post('v2/aanbod/verkoop/toevoegen-verkoopmodel', params);
  },
  wijzigenVerkoopmodel: async (
    params: IWijzigenVerkoopmodelParams,
  ): Promise<IWijzigenVerkoopmodelResult> => {
    return api.post('v2/aanbod/verkoop/wijzigen-verkoopmodel', params);
  },
  checkVerwijderenVerkoopmodellen: async (
    params: IVerwijderenVerkoopmodellenParams,
  ): Promise<ICheckData> => {
    return api.post('v2/aanbod/verkoop/check-verwijderen-verkoopmodellen', params);
  },
  verwijderenVerkoopmodellen: async (
    params: IVerwijderenVerkoopmodellenParams,
  ): Promise<IVerwijderenVerkoopmodellenResult> => {
    return api.post('v2/aanbod/verkoop/verwijderen-verkoopmodellen', params);
  },
};

export default verkoop;
