import React, { useMemo } from 'react';
import { EDataType } from '../../types';
import { IconKruis, IconVink } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';

interface IProps {
  type: EDataType;
  onClick: () => void;
}

const DataTypeIndicatie = (props: IProps) => {
  const Icon = useMemo(() => {
    switch (props.type) {
      case EDataType.Onbepaald:
        return IconKruis;
      case EDataType.Bepaald:
        return IconVink;
    }
  }, [props.type]);
  const kleur = useMemo(() => {
    switch (props.type) {
      case EDataType.Onbepaald:
        return Kleur.Rood;
      case EDataType.Bepaald:
        return Kleur.Groen;
    }
  }, [props.type]);

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      onClick={props.onClick}
      title={props.type === EDataType.Onbepaald ? 'Klik om af te ronden' : undefined}
    >
      <Icon style={{ fill: kleur, width: 18, height: 18, marginRight: 10 }} />
    </div>
  );
};

export default DataTypeIndicatie;
