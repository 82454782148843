import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Bankopdrachten from './Bankopdrachten';
import { IEntiteitProps } from '../../EntiteitContainer';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Bankopdrachten = 1,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Bankopdrachten]: '/bankopdrachten',
};

const Bankzaken: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Bankopdrachten]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Bankopdrachten,
        label: 'Bankopdrachten',
        content: Bankopdrachten,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              relID: props.relID,
            },
          }}
        />
      )}
    </div>
  );
};

export default withRouter(Bankzaken);
