import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  IOphalenTalenResult,
  IOphalenTalenResultElement,
} from '../../../../../shared/src/api/v2/taal';
import api from '../../../api';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import TaalVeld, { IInputComponentProps, IMultilineConfig } from './TaalVeld';
import classNames from 'classnames';
import { IconInklappen, IconUitklappen, VlagDE, VlagEN, VlagFR, VlagNL } from '../../Icons';
import VinkVeld from '../VinkVeld';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Kleur } from '../../../bedrijfslogica/constanten';
import ITaalTekst from '../../../../../shared/src/models/talen/ITaalTekst';
import SlugInput, { MultilineSlugInput } from '../SlugInput';

interface IProps {
  waarden: ITaalTekst[];
  onChange: (waarden: ITaalTekst[]) => void;
  taalVeldPostfix?: JSX.Element;
  standaardtaalOptioneel?: boolean;
  defaultUitgeklapt?: boolean;
  inputComponent?: 'singleline' | 'multiline' | React.ComponentType<IInputComponentProps>;
  multilineConfig?: IMultilineConfig;
}

const taalNaamKortVlagMap: Record<string, React.ComponentType> = {
  N: VlagNL,
  F: VlagFR,
  D: VlagDE,
  E: VlagEN,
};

const uitklapSvgStyle: React.CSSProperties = { fill: Kleur.Grijs, width: 20, height: 20 };

const MeertaligTekstveld: React.FC<IProps> = observer((props) => {
  const { instellingStore } = useContext(RootStoreContext);
  const [talen, setTalen] = useState<IOphalenTalenResult | null>(null);
  const [waarden, setWaarden] = useState<ITaalTekst[]>(props.waarden);
  const [uitgeklapt, setUitgeklapt] = useState(props.defaultUitgeklapt || false);

  useEffect(() => {
    (async () => {
      const result = await api.v2.taal.ophalen({
        filterSchema: { filters: [] },
      });
      setTalen(result);
    })();
  }, []);

  useEffect(() => {
    if (JSON.stringify(waarden) === JSON.stringify(props.waarden)) {
      return;
    }
    props.onChange(waarden);
  }, [waarden]);

  useEffect(() => {
    if (JSON.stringify(waarden) === JSON.stringify(props.waarden)) {
      return;
    }
    setWaarden(waarden);
  }, [props.waarden]);

  const maakStandaardTekstveldWaarde = useCallback(
    (taal: IOphalenTalenResultElement): ITaalTekst => {
      return {
        taalID: taal.TaalID,
        toepassen: !props.standaardtaalOptioneel && taal.TaalID === instellingStore.TaalID,
        tekst: '',
      };
    },
    [instellingStore.TaalID, props.standaardtaalOptioneel],
  );

  // Toepassen forceren op standaard taal tekst waarbij standaard toepassen = ja
  useEffect(() => {
    if (props.standaardtaalOptioneel) {
      return;
    }

    const standaardTaalTekst = waarden.find((x) => x.taalID === instellingStore.TaalID);
    if (standaardTaalTekst === undefined || standaardTaalTekst.toepassen) {
      return;
    }

    setWaarden((waarden) =>
      waarden.map((waarde) => {
        if (waarde.taalID === instellingStore.TaalID) {
          return {
            ...waarde,
            toepassen: true,
          };
        }
        return waarde;
      }),
    );
  }, [waarden, props.standaardtaalOptioneel]);

  if (talen === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex">
      <div className="flex-fill">
        {talen
          .filter((taal) => {
            if (uitgeklapt) {
              return true;
            }
            return taal.TaalID === instellingStore.TaalID;
          })
          .map((taal, i) => {
            const tekstveldWaarde =
              waarden.find((x) => x.taalID === taal.TaalID) || maakStandaardTekstveldWaarde(taal);
            const VlagComp = taalNaamKortVlagMap[taal.NaamKort];

            const setWaarde = (waarde: ITaalTekst) => {
              setWaarden((curr: ITaalTekst[]) => [
                waarde,
                ...curr.filter((x) => x.taalID !== taal.TaalID),
              ]);
            };

            return (
              <div
                key={taal.TaalID}
                style={{ position: `relative` }}
                className={classNames(['d-flex align-items-center', i > 0 && 'mt-2'])}
              >
                <div>
                  <VinkVeld
                    aangevinkt={tekstveldWaarde.toepassen}
                    onGewijzigd={(toepassen) => {
                      const nieuweWaarde: ITaalTekst = {
                        ...tekstveldWaarde,
                        toepassen,
                      };
                      setWaarde(nieuweWaarde);
                    }}
                    disabled={
                      !props.standaardtaalOptioneel && taal.TaalID === instellingStore.TaalID
                    }
                  />
                </div>
                <div
                  style={{ width: 15, height: 12, position: `absolute`, left: 40, top: 5 }}
                  title={taal.Naam}
                >
                  <VlagComp />
                </div>
                <span className="flex-fill ml-2">
                  <TaalVeld
                    waarde={tekstveldWaarde.tekst}
                    onChange={(x) => {
                      const nieuweWaarde: ITaalTekst = {
                        ...tekstveldWaarde,
                        tekst: x,
                        toepassen:
                          tekstveldWaarde.tekst === '' && x !== ''
                            ? true
                            : tekstveldWaarde.toepassen,
                      };
                      setWaarde(nieuweWaarde);
                    }}
                    inputComponent={props.inputComponent}
                    multilineConfig={props.multilineConfig}
                  />
                </span>
                {props.taalVeldPostfix !== undefined && props.taalVeldPostfix}
              </div>
            );
          })}
      </div>
      <div
        className="ml-2 d-flex flex-column align-items-center"
        style={{ backgroundColor: Kleur.HeelLichtGrijs }}
      >
        <button
          style={{
            border: 0,
            backgroundColor: 'transparent',
            padding: 5,
          }}
          onClick={() => setUitgeklapt(!uitgeklapt)}
        >
          {uitgeklapt ? (
            <IconInklappen style={uitklapSvgStyle} />
          ) : (
            <IconUitklappen style={uitklapSvgStyle} />
          )}
        </button>
      </div>
    </div>
  );
});

export const MeertaligTekstveldSlugInputComponent = (props: IInputComponentProps) => {
  if (props.multilineConfig?.rows !== undefined) {
    return (
      <MultilineSlugInput
        slug={props.value}
        onChange={props.onChange}
        textAreaProps={
          {
            style: props.style,
            rows: props.multilineConfig.rows,
          } as any
        }
      />
    );
  }

  return (
    <SlugInput slug={props.value} onChange={props.onChange} inputProps={{ style: props.style }} />
  );
};

export default MeertaligTekstveld;
