import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CommunicatieOverlayContext } from '../../index';
import { RootStoreContext } from '../../../../stores/RootStore';
import {
  IContact,
  IConversation,
  INewContact,
} from '../../../../components/communicatie/SMSWerkblad';
import api from '../../../../api';
import { IAsyncObservableAction } from '../../../../core/asyncObservableAction';
import ContactsSection, {
  IShouldFetchMoreContactsResult,
} from '../../../../components/communicatie/SMSWerkblad/ContactsSection';
import ConversationSection from '../../../../components/communicatie/SMSWerkblad/ConversationSection';
import { IconPijlVorig } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../components/layout/HorizontaleScheidingslijn';
import { observer } from 'mobx-react-lite';
import PersoonVisualisatie from '../../../../components/personalia/PersoonVisualisatie';
import { v4 as uuidv4 } from 'uuid';

interface IProps {}

const SmsTabblad = observer(
  forwardRef<{}, IProps>((props, forwardedRef) => {
    const { smsContext, width } = useContext(CommunicatieOverlayContext);
    const { smsStore } = useContext(RootStoreContext);

    const [newContacts, setNewContacts] = useState<INewContact[] | null>(null);
    const isFetchingContactsRef = useRef<boolean>(false);
    const hasRequestedFilterOnce = useRef<boolean>(false);

    const contacts = useMemo<Array<IContact | INewContact> | null>(() => {
      const contacten =
        smsStore.contactenVoorZoekterm !== null && hasRequestedFilterOnce.current
          ? smsStore.contactenVoorZoekterm
          : smsStore.contacten;
      if (contacten === null) {
        return null;
      }

      const existingContacts = contacten.map((contact) => {
        return {
          id: contact.ID,
          phoneNumber: contact.Telefoonnummer,
          name: null,
          lastMessage: {
            id: contact.laatsteBericht.ID,
            isIncoming: contact.laatsteBericht.Richting === 'I',
            content: contact.laatsteBericht.Inhoud,
            time: new Date(contact.laatsteBericht.Datum),
          },
          persID: contact.PersID,
        };
      });

      const newContactsWithoutExisting = (newContacts || []).filter(
        (contact) =>
          existingContacts.findIndex((x) => x.phoneNumber === contact.phoneNumber) === -1,
      );

      return [...newContactsWithoutExisting, ...existingContacts];
    }, [smsStore.contacten, smsStore.contactenVoorZoekterm, newContacts]);

    const handleNewContact = useCallback(
      (contact: INewContact) => {
        setNewContacts([contact, ...(newContacts || [])]);
      },
      [newContacts, setNewContacts],
    );

    const probeerOphalenContactVoorTelefoonnummer = useCallback(
      async (telefoonnummer: string) => {
        const result = await api.v2.dienst.sms.ophalenContacten({
          filterSchema: {
            filters: [
              {
                naam: 'NUMMERS',
                data: [telefoonnummer],
              },
            ],
          },
        });
        const [contact] = result;
        // Nieuw contact
        if (contact === undefined) {
          const id = uuidv4();
          handleNewContact({
            phoneNumber: telefoonnummer,
            persID: smsContext.persID === undefined ? null : smsContext.persID,
            tempId: id,
          });
          smsStore.berichten[id] = [];
          return;
        }
        // Bestaand contact
        smsStore.contacten = [contact, ...(smsStore.contacten || [])];
      },
      [handleNewContact, smsStore.berichten, smsStore.contacten, smsContext.persID],
    );

    useEffect(() => {
      if (contacts === null || smsContext.telefoonnummer === null) {
        return;
      }
      const contact = contacts.find((x) => x.phoneNumber === smsContext.telefoonnummer);
      if (contact !== undefined) {
        return;
      }
      probeerOphalenContactVoorTelefoonnummer(smsContext.telefoonnummer);
    }, [contacts, smsContext.telefoonnummer]);

    useEffect(() => {
      if (contacts !== null || isFetchingContactsRef.current) {
        return;
      }
      isFetchingContactsRef.current = true;
      (async () => {
        await smsStore.ophalenInitieleContacten();
        isFetchingContactsRef.current = false;
      })();
    }, [contacts]);

    const conversation = useMemo<IConversation | null>(() => {
      if (contacts === null || smsContext.telefoonnummer === null) {
        return null;
      }
      const contact = contacts.find((x) => x.phoneNumber === smsContext.telefoonnummer);
      if (contact === undefined) {
        // smsContext.onTelefoonnummerChange(null);
        return null;
      }

      const id =
        (contact as IContact).id !== undefined
          ? (contact as IContact).id
          : (contact as INewContact).tempId;
      const isNewContact = (contact as IContact).id === undefined;

      if (isNewContact) {
        return {
          contact,
          messages: [],
        };
      }

      const berichten = smsStore.berichten[id];
      if (berichten === undefined) {
        return null;
      }

      const messages = berichten.map((bericht) => {
        return {
          id: bericht.ID,
          content: bericht.Inhoud,
          isIncoming: bericht.Richting === 'I',
          time: new Date(bericht.Datum),
        };
      });

      return {
        contact,
        messages,
      };
    }, [contacts, smsContext.telefoonnummer, smsStore.berichten]);

    useEffect(() => {
      if (contacts === null || smsContext.telefoonnummer === null) {
        return;
      }
      const contact = contacts.find((x) => x.phoneNumber === smsContext.telefoonnummer);
      if (contact === undefined) {
        return;
      }

      const id =
        (contact as IContact).id !== undefined
          ? (contact as IContact).id
          : (contact as INewContact).tempId;

      const berichten = smsStore.berichten[id];
      // Als id een string is, dan is het een nieuw contact (die heeft nog geen bestaande berichten)
      if (berichten !== undefined || typeof id === 'string') {
        return;
      }

      (async () => {
        await smsStore.ophalenBerichtenVoorContact(id);
      })();
    }, [smsContext.telefoonnummer, contacts, smsStore.berichten]);

    const handleContactChange = useCallback(
      async (contact: IContact | INewContact) => {
        const isNewContact = (contact as IContact).id === undefined;

        if (isNewContact) {
          setNewContacts(
            newContacts!.map((c) => {
              if (c.tempId === (contact as INewContact).tempId) {
                return contact as INewContact;
              }
              return c;
            }),
          );
        } else {
          const c = contact as IContact;
          await api.v2.dienst.sms.bijwerkenContact({
            ID: c.id,
            telefoonNummer: c.phoneNumber,
            persID: c.persID,
          });
        }
      },
      [newContacts, setNewContacts],
    );

    const handleShouldFetchMoreContacts = useCallback(
      async (action: IAsyncObservableAction<IShouldFetchMoreContactsResult, any>) => {
        try {
          const canFetchMore = await smsStore.ophalenVolgendeContacten();
          action.resolver({
            canFetchMore,
          });
        } catch (err) {
          action.rejector(err);
        }
      },
      [smsStore.ophalenVolgendeContacten],
    );

    const handleRequestFilter = useCallback((search: string | null) => {
      if (search === null) {
        smsStore.contactenVoorZoekterm = null;
        return;
      }
      hasRequestedFilterOnce.current = true;
      smsStore.ophalenContactenVoorZoekterm(search);
    }, []);

    // const chatSessies =
    //   tabbladContext.chatSessies === null
    //     ? null
    //     : tabbladContext.chatSessies.filter((x) => x.status === EChatSessieStatus.Nieuw);

    return (
      <div className="d-flex flex-column flex-fill">
        {conversation === null ? (
          <ContactsSection
            onNewContact={handleNewContact}
            contacts={contacts}
            selectedContactNumber={smsContext.telefoonnummer}
            onSelectedContactNumberChange={smsContext.onTelefoonnummerChange}
            onContactChange={handleContactChange}
            shouldFetchMoreContacts={handleShouldFetchMoreContacts}
            onRequestFilter={handleRequestFilter}
            options={{}}
            // options={options}
          />
        ) : (
          <div className="d-flex flex-column flex-fill">
            <div className="d-flex w-100">
              <div
                className="p-3"
                style={{ cursor: 'pointer' }}
                onClick={() => smsContext.onTelefoonnummerChange(null)}
              >
                <IconPijlVorig style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
              </div>
              <div className="ml-3 flex-fill d-flex align-items-center">
                {conversation.contact.persID !== null ? (
                  <div className="mr-3" style={{ position: 'relative', top: 1 }}>
                    <PersoonVisualisatie persID={conversation.contact.persID} />
                  </div>
                ) : null}
                {conversation.contact.phoneNumber}
              </div>
            </div>
            <HorizontaleScheidingslijn />
            <div className="d-flex flex-column flex-fill" style={{ position: 'relative' }}>
              <ConversationSection
                conversation={conversation}
                selectedContactNumber={smsContext.telefoonnummer}
                message={smsContext.bericht}
                onMessageChange={(bericht) => smsContext.onBerichtChange(bericht)}
              />
            </div>
          </div>
        )}
        {/*{chatSessies === null ? (*/}
        {/*  <div className="d-flex flex-fill align-items-center justify-content-center">*/}
        {/*    <LoadingSpinner />*/}
        {/*  </div>*/}
        {/*) : chatSessies.length === 0 ? (*/}
        {/*  <div className="d-flex flex-column flex-fill align-items-center justify-content-center">*/}
        {/*    <span>Geen sms contacten beschikbaar.</span>*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <div*/}
        {/*    className="d-flex flex-column flex-fill list-group list-group-flush"*/}
        {/*    style={{*/}
        {/*      position: 'absolute',*/}
        {/*      top: 45,*/}
        {/*      width,*/}
        {/*      height: `calc(100% - 40px)`,*/}
        {/*      overflowY: `auto`,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {chatSessies.map((chatSessie) => (*/}
        {/*      <div key={chatSessie.ID}>*/}
        {/*        <ChatSessieItem*/}
        {/*          chatSessie={chatSessie}*/}
        {/*          onClick={() => whatsappContext.onChatSessieIDChange(chatSessie.ID)}*/}
        {/*          onStatusGewijzigd={(status) => {*/}
        {/*            switch (status) {*/}
        {/*              case EChatSessieStatus.Lopend:*/}
        {/*                whatsappContext.onTabbladChange(EWhatsappTabblad.Lopend);*/}
        {/*                break;*/}
        {/*            }*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    );
  }),
);

export default SmsTabblad;
