import {
  IOphalenTekstenInAlleTalenParams,
  IOphalenTekstenInAlleTalenResult,
  IOphalenTekstenParams,
  IOphalenTekstenResult,
  IWijzigenTekstParams,
  IWijzigenTekstResult,
} from '../../../../../shared/src/api/v2/tekst';
import api from '../../index';

const tekst = {
  ophalenTeksten: async (params: IOphalenTekstenParams): Promise<IOphalenTekstenResult> => {
    return await api.post('/v2/tekst/ophalen-teksten', params);
  },
  ophalenTekstenInAlleTalen: async (
    params: IOphalenTekstenInAlleTalenParams,
  ): Promise<IOphalenTekstenInAlleTalenResult> => {
    return await api.post('/v2/tekst/ophalen-teksten-in-alle-talen', params);
  },
  wijzigenTekst: async (params: IWijzigenTekstParams): Promise<IWijzigenTekstResult> => {
    return await api.post('/v2/tekst/wijzigen-tekst', params);
  },
};

export default tekst;
