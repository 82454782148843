import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import Leveropdrachten from './Leveropdrachten';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Relaties = 1,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Relaties]: '/leveropdrachten',
};

const Opvolging: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Relaties]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Relaties,
        label: 'Opvolging Leveropdrachten',
        content: Leveropdrachten,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              relID: props.relID,
            },
          }}
        />
      )}
    </div>
  );
};

export default withRouter(Opvolging);
