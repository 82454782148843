import styled from 'styled-components';
import { boxShadow, colors } from '../../globalstyles/variables';

export const Wrapper = styled.div`
  background-color: ${colors.backgroundGrey};
  min-height: 100vh;
  padding-top: 100px;
`;

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  form {
    padding: 2rem;
    background-color: red;
    border-radius: 4px;
    background-color: white;
    box-shadow: ${boxShadow};
  }
`;

export const FormHeader = styled.header`
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
`;

export const Error = styled.p`
  margin-top: 20px;
  color: red;
`;
