import React from 'react';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../ItemTypes';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EVerkennerItemType } from '../../TabelWeergave/types';
import { IconMap } from '../../../Icons';
import { IVerkennerItem } from '../../types';

interface IProps {
  onClick?: (ev: React.MouseEvent<HTMLAnchorElement>) => void;
  isActive?: boolean;
  naam: string;
  onVerkennerItemDrop?: (item: IVerkennerItem) => void;
  mapID: number | null;
  isSysteemmap?: boolean;
}

const Breadcrumb = (props: IProps) => {
  const [{ isDropActive }, drop] = useDrop(
    () => ({
      accept: ItemTypes.VerkennerItem,
      collect: (monitor) => ({
        isDropActive: monitor.canDrop() && monitor.isOver(),
      }),
      canDrop: (object, monitor) => {
        const item = object as IVerkennerItem;

        if (item.type === EVerkennerItemType.Map) {
          if (item.map.ID === props.mapID) {
            // Kan niet in zichzelf droppen
            return false;
          }

          if (item.map.Bovenliggende_BestandsmapID === props.mapID) {
            // Zit al in deze map
            return false;
          }
        }

        if (item.type === EVerkennerItemType.Bestand) {
          if (item.bestand.BestandsmapID === props.mapID) {
            // Zit al in deze map
            return false;
          }
        }

        return true;
      },
      drop(object, monitor) {
        const item = object as IVerkennerItem;
        props.onVerkennerItemDrop?.(item);
      },
    }),
    [props.onVerkennerItemDrop],
  );

  return drop(
    <div
      className={classnames(['d-flex align-items-center', props.isActive && 'active'])}
      style={{
        padding: isDropActive ? '2px 5px' : undefined,
        border: isDropActive ? `3px dashed ${Kleur.LichtGrijs}` : 'none',
        cursor: isDropActive ? 'copy' : undefined,
      }}
    >
      <IconMap
        style={{
          width: 19,
          height: 19,
          fill: props.isSysteemmap ? Kleur.Paars : Kleur.ZwembadBlauw,
          marginRight: 5,
        }}
      />
      {props.isActive ? (
        <span>{props.naam}</span>
      ) : (
        <a
          href="#"
          onClick={(ev) => {
            ev.preventDefault();
            props.onClick?.(ev);
          }}
        >
          {props.naam}
        </a>
      )}
    </div>,
  );
};

export default Breadcrumb;
