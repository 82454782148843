import React from 'react';
import { IconInklappen, IconUitklappen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {
  uitgeklapt: boolean;
  onUitgeklaptChange: (state: boolean) => void;
  label: string | JSX.Element;
  formulier: JSX.Element;
}

const UitklapFormulier: React.FC<IProps> = (props) => {
  const Icon = props.uitgeklapt ? IconInklappen : IconUitklappen;

  return (
    <div>
      <a
        href="#"
        style={{ color: Kleur.LichtBlauw, textDecoration: 'none' }}
        onClick={(ev) => {
          ev.stopPropagation();
          props.onUitgeklaptChange(!props.uitgeklapt);
        }}
      >
        {props.label}
        <Icon style={{ width: 17, height: 17, fill: Kleur.LichtBlauw }} />
      </a>
      {props.uitgeklapt && <div className="mt-1 p-2">{props.formulier}</div>}
    </div>
  );
};

export default UitklapFormulier;
