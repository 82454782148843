import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useUrlState from '../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { IOphalenBestellingenResult } from '../../../../../../../../shared/src/api/v2/website/bestelling';
import api from '../../../../../../api';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import {
  IOphalenEventsResult,
  IOphalenEventsResultElement,
} from '../../../../../../../../shared/src/api/v2/appEvent';
import MenuLayout from '../../../../../../components/MenuLayout';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, EditingState, SelectionState } from '@devexpress/dx-react-grid';
import nameof from '../../../../../../core/nameOf';
import { format } from 'date-fns';
import { Kleur, mutatiebronIconMap } from '../../../../../../bedrijfslogica/constanten';
import { EMutatiebron } from '../../../../../../bedrijfslogica/enums';

interface IProps extends IEntiteitProps, RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
};

export interface IRow extends IOphalenEventsResultElement {}

const geenData = {
  noData: 'Geen events gevonden',
};

const Events: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore } = useContext(RootStoreContext);

  const [eventsResult, setEventsResult] = useState<IOphalenEventsResult | null>(null);

  const ophalenEvents = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setEventsResult(null);
      return;
    }
    const result = await api.v2.appEvent.ophalenEvents({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
      orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
    });

    setEventsResult(result);
  }, [props.relID]);

  useEffect(() => {
    ophalenEvents();
  }, [ophalenEvents]);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: 'EventNaam',
        title: 'Naam',
      },
      {
        name: '__mutatiebron' as any,
        title: 'Mutatiebron',
      },
      // {
      //   name: 'Data',
      //   title: 'Data',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 150,
      },
      {
        columnName: 'EventNaam',
        width: 400,
      },
      {
        columnName: '__mutatiebron' as any,
        width: 100,
      },
      // {
      //   columnName: 'Data',
      //   width: 475,
      // },
    ],
    [],
  );

  return (
    <MenuLayout
      // menu={<span></span>}
      body={
        eventsResult === null ? (
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid getRowId={(x) => x.ID} rows={eventsResult.events} columns={kolommen}>
              <DataTypeProvider
                for={[nameof<IRow>('Datum')]}
                formatterComponent={(formatterProps) => {
                  return (
                    <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm:ss')}</span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__mutatiebron']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;
                  const Icon = mutatiebronIconMap[row.Mutatiebron as EMutatiebron];
                  return (
                    <span>
                      <Icon style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
                    </span>
                  );
                }}
              />

              <EditingState
                onAddedRowsChange={() => {}}
                onEditingRowIdsChange={(x) => {
                  const id = x[x.length - 1] as number;
                }}
                onCommitChanges={() => null}
              />

              <VirtualTable messages={geenData} />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />

              {/* <TableEditColumn
                width={35}
                showAddCommand={true}
                showEditCommand
                showDeleteCommand
                commandComponent={DXCommandComponent}
              /> */}

              {/* <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                /> */}

              {/* <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
            </Grid>
          </GridStyleWrapper>
        )
      }
    />
  );
};

export default withRouter(Events);
