import React, { useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { IContext, PersoonContext } from '..';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import GegevensLayout from '../../../layout/GegevensLayout';
import { ITabbladProps } from '../../../product/ProductinfoDialoog';
import { EHoedanigheid } from '../../RelatieSelectieDialoog';
import RelatiesVisualisaties from '../../RelatiesVisualisaties';

interface IProps {}

const PersoonTab: React.FC<IProps> = (props) => {
  const { persoon, persoonRelaties } = useContext<IContext>(PersoonContext);

  const Relaties = () => {
    if (persoonRelaties === null) {
      return <span></span>;
    }

    return (
      <RelatiesVisualisaties
        relIDs={persoonRelaties.map((x) => x.RelID)}
        relatieLinkBuilder={(hoedanigheid, relID) =>
          `/${
            hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
          }/${relID}/contactpersonen`
        }
      />
    );
  };

  return (
    <div className="d-flex flex-column flex-fill">
      {persoon === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-column p-3 flex-fill" style={{ rowGap: 10 }}>
          <div>
            {persoon.Overleden ? (
              <div className="mb-3">
                <VeldWeergave>
                  <span className="mt-5 mb-5" style={{ color: EKleur.Rood }}>
                    <b>Persoon is overleden</b>
                  </span>
                </VeldWeergave>
              </div>
            ) : (
              <></>
            )}

            <div>
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Geslacht',
                      waarde: `${persoon.geslacht.AanhefKort}`,
                    },
                    {
                      label: 'Voornaam',
                      waarde: `${persoon.Voornaam ?? ''}`,
                    },
                    {
                      label: 'Voorletters',
                      waarde: `${persoon.Voorletters ?? ''}`,
                    },
                    {
                      label: 'Voorvoegsel',
                      waarde: `${persoon.Voorvoegsel ?? ''}`,
                    },
                    {
                      label: 'Achternaam',
                      waarde: `${persoon.Achternaam ?? ''}`,
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="mt-3">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Telefoon mobiel',
                      waarde: `${persoon.TelefoonMobiel ?? ''}`,
                    },
                    {
                      label: 'Telefoon extra',
                      waarde: `${persoon.TelefoonExtra ?? ''}`,
                    },
                    {
                      label: 'Email',
                      waarde: `${persoon.Email ?? ''}`,
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="mt-3">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Notities',
                      waarde: `${persoon.Notities ?? 'Geen'}`,
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="mt-3">
              <VeldWeergave>
                <div> Is contactpersoon voor:</div>
                <Relaties />
              </VeldWeergave>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersoonTab;
