import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  IOphalenInventarisatiesResultElement,
  IVerwijderenInventarisatiesParams,
} from '../../../../../shared/src/api/v2/magazijn/inventarisatie';
import { ILadenExterneInventarisatieResult } from '../../../../../shared/src/api/v2/magazijn/inventarisatie/lijst/extern';
import api from '../../../api';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import nameOf from '../../../core/nameOf';
import { format } from 'date-fns';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../core/useUrlState';
import Overzicht from './Overzicht';
import ToevoegenInventarisatieDialoog from './ToevoegenInventarisatieDialoog';
import _ from 'lodash';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import Lijst from './Lijst';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import BestandenDragAndDropDialoog, {
  IBestandenDragAndDropDialoogResult,
} from '../../../components/BestandenDragAndDropDialoog';
import { EBestandDragAndDropZoneSelectieModus } from '../../../components/BestandDragAndDropZone';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import Document from './Document';
import ActieMenuKnop from '../../../components/ActieMenuKnop';
import Transport from './Transport';
import Correcties from './Correcties';
import WijzigenDialoog from './WijzigenDialoog';
import LijstExtern from './Lijst/Extern';
import InventarisatieTegel from './InventarisatieTegel';
import { IconToevoegen } from '../../../components/Icons';
import InactiefOverlay from '../../../components/InactiefOverlay';
import MutatiesZoekmeldingen from './MutatiesZoekmeldingen';

export enum ETabblad {
  Overzicht,
  Inventarisatie,
  LijstIntern,
  LijstExtern,
  Transport,
  Pendel,
  Producten,
  Document,
  Correcties,
  MutatiesZoekmeldingen,
}

interface IWijzigenDialoogState {
  magInvID: number;
}

interface IUrlState {
  actiefInventarisatieID: number | null;
  tabblad: ETabblad;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  actiefInventarisatieID: null,
  tabblad: ETabblad.Inventarisatie,
  wijzigenDialoogState: null,
};

export interface ITabbladComponentProps {
  inventarisatieID: number;
  onVerversenAangevraagd: () => Promise<void>;
}

interface IProps extends RouteComponentProps {}

export interface IToevoegenInventarisatieDialoogState {}

const Inventarisatie: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const [isBezig, setIsBezig] = useState(false);

  const [
    toevoegenInventarisatieDialoogState,
    setToevoegenInventarisatieDialoogState,
  ] = useState<IToevoegenInventarisatieDialoogState | null>(null);
  const [inventarisatieResult, setInventarisatieResult] = useState<
    IOphalenInventarisatiesResultElement[] | null
  >(null);

  const ophalenInventarisaties = useCallback(async () => {
    const result = (
      await api.v2.magazijn.inventarisatie.ophalenInventarisaties({
        filterSchema: {
          filters: [],
        },
      })
    ).inventarisaties;
    const resultGesorteerd = _.orderBy(result, ['[Registratiedatum]'], ['desc']);

    setInventarisatieResult(resultGesorteerd);
  }, []);
  useEffect(() => {
    ophalenInventarisaties();
  }, [ophalenInventarisaties]);

  const keyExtractor = useCallback((row: IOphalenInventarisatiesResultElement) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenInventarisatiesResultElement>[]>(
    () => [
      {
        name: 'Inventarisatienummer',
        title: 'Nr.',
      },
      {
        name: 'Inventarisatiedatum',
        title: 'Datum',
      },
      {
        name: '__magazijnNaam' as any,
        title: 'Magazijn',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      // {
      //   name: '__actieInlezenExtern' as any,
      //   title: ' ',
      // },
      // {
      //   name: '__actieInlezenDocument' as any,
      //   title: ' ',
      // },
      // {
      //   name: '__acties' as any,
      //   title: ' ',
      // },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenInventarisatiesResultElement>[]>(
    () => [
      {
        columnName: 'Inventarisatienummer',
        width: 45,
      },
      {
        columnName: '__magazijnNaam' as any,
        width: 90,
      },
      {
        columnName: 'Inventarisatiedatum',
        width: 105,
      },
      {
        columnName: '__status' as any,
        width: 85,
      },
      {
        columnName: '__actieInlezenExtern' as any,
        width: 60,
      },
      {
        columnName: '__actieInlezenDocument' as any,
        width: 70,
      },
      {
        columnName: '__acties' as any,
        width: 20,
      },
    ],
    [],
  );

  const tabbladen = useMemo<ITabblad<ETabblad, ITabbladComponentProps>[]>(
    () => [
      {
        id: ETabblad.Overzicht,
        label: 'Overzicht',
        content: Overzicht,
      },
      {
        id: ETabblad.Inventarisatie,
        label: 'Inventarisatie',
        content: Lijst,
      },
      {
        id: ETabblad.LijstExtern,
        label: 'Externe Lijst',
        content: LijstExtern,
      },
      {
        id: ETabblad.MutatiesZoekmeldingen,
        label: 'Zoekmeldingen',
        content: MutatiesZoekmeldingen,
      },
      // {
      //   id: ETabblad.LijstIntern,
      //   label: 'Interne Lijst',
      //   content: LijstIntern,
      // },
      // {
      //   id: ETabblad.Correcties,
      //   label: 'Correcties',
      //   content: Correcties,
      // },
      {
        id: ETabblad.Transport,
        label: 'Transport',
        content: Transport,
      },
      {
        id: ETabblad.Document,
        label: 'Document',
        content: Document,
      },
    ],
    [],
  );

  const handleVerversenAangevraagd = useCallback(async () => {
    await ophalenInventarisaties();
  }, [ophalenInventarisaties]);

  return (
    <>
      <Helmet>
        <title>Inventarisatie</title>
      </Helmet>
      <div className="d-flex flex-fill" style={{ backgroundColor: Kleur.Wit }}>
        {inventarisatieResult === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div
              className="d-flex flex-column"
              style={{ width: 385, borderRight: `1px solid ${Kleur.LichtGrijs}` }}
            >
              <div
                className="w-100 p-2 pl-3 pr-3"
                style={{
                  borderBottom: `1px solid ${Kleur.LichtGrijs}`,
                  backgroundColor: Kleur.HeelLichtGrijs,
                }}
              >
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{
                    border: `1px solid ${Kleur.LichtGrijs}`,
                  }}
                  disabled={isBezig}
                  onClick={() => setToevoegenInventarisatieDialoogState({})}
                >
                  <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                  <span className="ml-1">Toevoegen</span>
                </button>
              </div>
              {inventarisatieResult.map((inventarisatie) => {
                const isActief = inventarisatie.ID === urlState.actiefInventarisatieID;

                return (
                  <div
                    key={inventarisatie.ID}
                    style={{
                      borderBottom: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                  >
                    <InactiefOverlay
                      isInactief={isBezig}
                      element={
                        <InventarisatieTegel
                          inventarisatie={inventarisatie}
                          isActief={isActief}
                          onClick={() =>
                            setUrlStateSync('actiefInventarisatieID', inventarisatie.ID)
                          }
                          onWijzigen={async () => {
                            setUrlStateSync('wijzigenDialoogState', {
                              magInvID: inventarisatie.ID,
                            });
                          }}
                          onVerwijderen={async () => {
                            setIsBezig(true);

                            const params: IVerwijderenInventarisatiesParams = {
                              ids: [inventarisatie.ID],
                            };
                            const checkData = await api.v2.magazijn.inventarisatie.checkVerwijderenInventarisaties(
                              params,
                            );
                            if (
                              (await checkStore.controleren({ checkData })).type ===
                              EResultType.Annuleren
                            ) {
                              setIsBezig(false);
                              return;
                            }

                            if (
                              (
                                await checkStore.bevestigen({
                                  inhoud: `Wil je deze inventarisatie verwijderen?`,
                                })
                              ).type === EResultType.Annuleren
                            ) {
                              setIsBezig(false);
                              return;
                            }

                            await api.v2.magazijn.inventarisatie.verwijderenInventarisaties(params);
                            await ophalenInventarisaties();

                            setUrlStateSync('actiefInventarisatieID', null);

                            setIsBezig(false);
                          }}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-column flex-fill">
              {urlState.actiefInventarisatieID === null ? (
                <div className="d-flex flex-fill align-items-center justify-content-center">
                  <h3>Selecteer een inventarisatie</h3>
                </div>
              ) : (
                <Tabblad<ETabblad, ITabbladComponentProps>
                  geselecteerd={urlState.tabblad}
                  onSelectieChange={(x) => setUrlStateSync('tabblad', x as ETabblad)}
                  tabbladen={tabbladen}
                  options={{
                    tabbladComponentProps: {
                      inventarisatieID: urlState.actiefInventarisatieID,
                      onVerversenAangevraagd: handleVerversenAangevraagd,
                    },
                  }}
                />
              )}
            </div>
          </>
        )}

        {toevoegenInventarisatieDialoogState && (
          <ToevoegenInventarisatieDialoog
            open
            onSuccess={async (result) => {
              setToevoegenInventarisatieDialoogState(null);
              await ophalenInventarisaties();
              setUrlStateSync('actiefInventarisatieID', result.ID);
            }}
            onAnnuleren={() => setToevoegenInventarisatieDialoogState(null)}
          />
        )}
        {urlState.wijzigenDialoogState && (
          <WijzigenDialoog
            open
            id={urlState.wijzigenDialoogState.magInvID}
            onSuccess={(result) => {
              setUrlStateSync('wijzigenDialoogState', null);
              ophalenInventarisaties();
            }}
            onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(Inventarisatie);
