import React, { useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IUrlState as IMeldingenOverzichtUrlState,
  defaultUrlState as meldingenOverzichtDefaultUrlState,
} from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Service/Meldingen';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IconService } from '../../Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import MeldingstatusIndicatie, { EMeldingstatus } from '../../service/MeldingstatusIndicatie';

interface IProps extends RouteComponentProps {
  servMeldID: number;
  geenMeldingstatusIndicatie?: boolean;
}

const ServicemeldingVisualisatie: React.FC<IProps> = observer((props) => {
  const { servicemeldingStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (
      servicemeldingStore.servicemeldingen.state === ERemoteDataState.Pending ||
      servicemeldingStore.servicemeldingen.data![props.servMeldID] === undefined
    ) {
      servicemeldingStore.enqueueOphalenServicemeldingen([props.servMeldID]);
    }
  }, [props.servMeldID]);

  const melding = useMemo(() => {
    if (
      servicemeldingStore.servicemeldingen.state === ERemoteDataState.Pending ||
      servicemeldingStore.servicemeldingen.data![props.servMeldID] === undefined
    ) {
      return null;
    }

    return servicemeldingStore.servicemeldingen.data![props.servMeldID]!;
  }, [props.servMeldID, servicemeldingStore.servicemeldingen]);

  if (melding === null) {
    return <span>Laden...</span>;
  }

  return (
    <a
      href="#"
      className="d-flex align-items-center"
      onClick={(ev) => {
        ev.preventDefault();

        if (melding.RelID !== null) {
          const urlState: IMeldingenOverzichtUrlState = {
            ...meldingenOverzichtDefaultUrlState,
            selectie: [melding.ID],
          };
          const query = genereerUrlStateQueryParam(urlState);
          props.history.push(`/klant/${melding.RelID}/service/melding?state=${query}`);
        }
      }}
    >
      <span className="d-flex">
        {/* <IconService style={{ width: 10, height: 10, fill: EKleur.Grijs }} /> */}
        {!props.geenMeldingstatusIndicatie ? (
          <MeldingstatusIndicatie
            status={melding.Afgehandeld ? EMeldingstatus.Groen : EMeldingstatus.Blauw}
          />
        ) : (
          <span></span>
        )}

        <span className="ml-2">{melding.Meldnummer}</span>
      </span>
    </a>
  );
});

export default withRouter(ServicemeldingVisualisatie);
