import { RouteComponentProps } from 'react-router';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SMSWerkblad, {
  IContact,
  IConversation,
  INewContact,
} from '../../../components/communicatie/SMSWerkblad';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { IAsyncObservableAction } from '../../../core/asyncObservableAction';
import { IShouldFetchMoreContactsResult } from '../../../components/communicatie/SMSWerkblad/ContactsSection';
import { Helmet } from 'react-helmet';

const SELECTED_PHONE_NUMBER_KEY = 'telefoonnummer';
const DEFAULT_MESSAGE_KEY = 'defaultMessage';

interface IProps extends RouteComponentProps<{}> {}

const SMS: React.FC<IProps> = observer((props) => {
  const { smsStore } = useContext(RootStoreContext);
  const [newContacts, setNewContacts] = useState<INewContact[] | null>(null);
  const isFetchingContactsRef = useRef<boolean>(false);
  const hasRequestedFilterOnce = useRef<boolean>(false);

  const search = useMemo(() => new URLSearchParams(props.location.search), [props.location.search]);
  const selectedPhoneNumber = useMemo(() => {
    const value = search.get(SELECTED_PHONE_NUMBER_KEY);
    return value || null;
  }, [search]);

  const defaultMessage = useMemo(() => {
    const value = search.get(DEFAULT_MESSAGE_KEY);
    return value || null;
  }, [search]);

  const contacts = useMemo<Array<IContact | INewContact> | null>(() => {
    const contacten =
      smsStore.contactenVoorZoekterm !== null && hasRequestedFilterOnce.current
        ? smsStore.contactenVoorZoekterm
        : smsStore.contacten;
    if (contacten === null) {
      return null;
    }

    const existingContacts = contacten.map((contact) => {
      return {
        id: contact.ID,
        phoneNumber: contact.Telefoonnummer,
        name: null,
        lastMessage: {
          id: contact.laatsteBericht.ID,
          isIncoming: contact.laatsteBericht.Richting === 'I',
          content: contact.laatsteBericht.Inhoud,
          time: new Date(contact.laatsteBericht.Datum),
        },
        persID: contact.PersID,
      };
    });

    const newContactsWithoutExisting = (newContacts || []).filter(
      (contact) => existingContacts.findIndex((x) => x.phoneNumber === contact.phoneNumber) === -1,
    );

    return [...newContactsWithoutExisting, ...existingContacts];
  }, [smsStore.contacten, smsStore.contactenVoorZoekterm, newContacts]);

  useEffect(() => {
    if (contacts !== null || isFetchingContactsRef.current) {
      return;
    }
    isFetchingContactsRef.current = true;
    (async () => {
      await smsStore.ophalenInitieleContacten();
      isFetchingContactsRef.current = false;
    })();
  }, [contacts]);

  const conversation = useMemo<IConversation | null>(() => {
    if (contacts === null || selectedPhoneNumber === null) {
      return null;
    }
    const contact = contacts.find((x) => x.phoneNumber === selectedPhoneNumber);
    if (contact === undefined) {
      search.delete(SELECTED_PHONE_NUMBER_KEY);
      props.history.push({
        pathname: props.location.pathname,
        search: search.toString(),
      });
      return null;
    }

    const id =
      (contact as IContact).id !== undefined
        ? (contact as IContact).id
        : (contact as INewContact).tempId;
    const isNewContact = (contact as IContact).id === undefined;

    if (isNewContact) {
      return {
        contact,
        messages: [],
      };
    }

    const berichten = smsStore.berichten[id];
    if (berichten === undefined) {
      return null;
    }

    const messages = berichten.map((bericht) => {
      return {
        id: bericht.ID,
        content: bericht.Inhoud,
        isIncoming: bericht.Richting === 'I',
        time: new Date(bericht.Datum),
      };
    });

    return {
      contact,
      messages,
    };
  }, [contacts, selectedPhoneNumber, smsStore.berichten]);

  useEffect(() => {
    if (contacts === null || selectedPhoneNumber === null) {
      return;
    }
    const contact = contacts.find((x) => x.phoneNumber === selectedPhoneNumber);
    if (contact === undefined) {
      return;
    }

    const id =
      (contact as IContact).id !== undefined
        ? (contact as IContact).id
        : (contact as INewContact).tempId;

    const berichten = smsStore.berichten[id];
    // Als id een string is, dan is het een nieuw contact (die heeft nog geen bestaande berichten)
    if (berichten !== undefined || typeof id === 'string') {
      return;
    }

    (async () => {
      await smsStore.ophalenBerichtenVoorContact(id);
    })();
  }, [selectedPhoneNumber, contacts, smsStore.berichten]);

  const handleNewContact = useCallback(
    (contact: INewContact) => {
      setNewContacts([contact, ...(newContacts || [])]);
    },
    [newContacts, setNewContacts],
  );

  const handleContactChange = useCallback(
    async (contact: IContact | INewContact) => {
      const isNewContact = (contact as IContact).id === undefined;

      if (isNewContact) {
        setNewContacts(
          newContacts!.map((c) => {
            if (c.tempId === (contact as INewContact).tempId) {
              return contact as INewContact;
            }
            return c;
          }),
        );
      } else {
        const c = contact as IContact;
        await api.v2.dienst.sms.bijwerkenContact({
          ID: c.id,
          telefoonNummer: c.phoneNumber,
          persID: c.persID,
        });
      }
    },
    [newContacts, setNewContacts],
  );

  const handleShouldFetchMoreContacts = useCallback(
    async (action: IAsyncObservableAction<IShouldFetchMoreContactsResult, any>) => {
      try {
        const canFetchMore = await smsStore.ophalenVolgendeContacten();
        action.resolver({
          canFetchMore,
        });
      } catch (err) {
        action.rejector(err);
      }
    },
    [smsStore.ophalenVolgendeContacten],
  );

  const handleRequestFilter = useCallback((search: string | null) => {
    if (search === null) {
      smsStore.contactenVoorZoekterm = null;
      return;
    }
    hasRequestedFilterOnce.current = true;
    smsStore.ophalenContactenVoorZoekterm(search);
  }, []);

  return (
    <>
      <Helmet>
        <title>SMS</title>
      </Helmet>
      <div style={{ display: 'flex', flex: 1 }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: 1,
            // padding: 10,
            // borderRadius: 5,
          }}
        >
          <SMSWerkblad
            contacts={contacts}
            conversation={conversation}
            selectedContactNumber={selectedPhoneNumber}
            onSelectedContactNumberChange={(value) => {
              if (value === null) {
                search.delete(SELECTED_PHONE_NUMBER_KEY);
              } else {
                search.set(SELECTED_PHONE_NUMBER_KEY, String(value));
              }
              props.history.push({
                pathname: props.location.pathname,
                search: search.toString(),
              });
            }}
            onNewContact={handleNewContact}
            onContactChange={handleContactChange}
            defaultMessage={defaultMessage}
            shouldFetchMoreContacts={handleShouldFetchMoreContacts}
            onRequestFilter={handleRequestFilter}
          />
        </div>
      </div>
    </>
  );
});

export default SMS;
