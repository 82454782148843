import _ from 'lodash';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import LocatieSelectie, {
  IOptions,
} from '../../../../../../../components/formulier/LocatieSelectie';
import React, { useEffect, useMemo } from 'react';
import { IWeergaveProps } from '../../../../../../../components/FilterBalkV2';
import { ERemoteDataState } from '../../../../../../../models/IRemoteData';
import { useOverzichtV2Store } from './store';
import { ILocatie } from '../../../../../../../../../shared/src/api/v2/relatie/contract';

const LocatieFilter = (weergaveProps: IWeergaveProps<any>) => {
  const store = useOverzichtV2Store();

  const locIDs = weergaveProps.data as number[];
  const locID = useMemo<number | null>(() => (locIDs.length === 0 ? null : locIDs[0]), [locIDs]);
  const mogelijkeLocIDs = useMemo<number[] | null>(() => {
    if (store.data.state === ERemoteDataState.Pending) {
      return null;
    }
    return store.data.data!.result.locaties.map((x: ILocatie) => x.LocID);
  }, [store.data]);

  useEffect(() => {
    // Gekozen locID bevindt zich niet meer in de mogelijke locIDs (e.g. RelID is veranderd)
    if (locID !== null && mogelijkeLocIDs !== null && mogelijkeLocIDs.indexOf(locID) === -1) {
      weergaveProps.onDataChange([]);
      weergaveProps.toepassen();
    }
  }, [locID, mogelijkeLocIDs]);

  const locatieSelectieOptions = useMemo<IOptions>(
    () => ({
      bijEnkeleDirectVoorselecteren: false,
      mogelijkeLocIDs: mogelijkeLocIDs!,
      zonderToevoegen: true,
      zonderMuteren: true,
    }),
    [mogelijkeLocIDs],
  );

  return (
    <span className="d-flex align-items-center">
      <span className="mr-2">Locatie</span>
      {mogelijkeLocIDs === null ? (
        <LoadingSpinner />
      ) : (
        <LocatieSelectie
          locID={locID}
          onChange={(x) => {
            const nieuweLocIDs = x === null ? [] : [x];
            weergaveProps.onDataChange(nieuweLocIDs);
            weergaveProps.setIsActief(true);
            weergaveProps.toepassen();
          }}
          options={locatieSelectieOptions}
        />
      )}
    </span>
  );
};

export default LocatieFilter;
