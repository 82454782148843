import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AcceptatieContext } from '..';
import { IOphalenProceduresResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/acceptatie';
import api from '../../../../../../../api';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { EProcedureStatus } from '../../../../../../../bedrijfslogica/enums';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen } from '../../../../../../../components/Icons';
import MenuLayout from '../../../../../../../components/MenuLayout';
import nameof from '../../../../../../../core/nameOf';
import useUrlState from '../../../../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import DetailComp from './DetailComp';
import WijzigenStatusDialoog from './WijzigenStatusDialoog';
import VersturenVerzoekDocumentenDialoog from './VersturenDocumentenDialoog';
import { values } from 'lodash';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IToevoegenProcedureDialoogState {}

interface IWijzigenStatusDialoogState {
  id: number;
}

interface IVersturenDocumenten {
  aprDocID: number;
}

export interface IAcceptatieProcedureUrlState {
  selectie: number[];
  uitgeklapt: number[];
  toevoegenProcedureDialoogState: IToevoegenProcedureDialoogState | null;
  wijzigenStatusDialoogState: IWijzigenStatusDialoogState | null;
  versturenDocumenten: IVersturenDocumenten | null;
}

const defaultUrlState: IAcceptatieProcedureUrlState = {
  selectie: [],
  uitgeklapt: [],
  toevoegenProcedureDialoogState: null,
  wijzigenStatusDialoogState: null,
  versturenDocumenten: null,
};

export interface IRegel extends IOphalenProceduresResultElement {}

export interface IContext {
  onVerversenAangevraagd: () => void;
}

export const ProcedureContext = React.createContext<IContext>(null as any);

const Procedure: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const acceptatieContext = useContext(AcceptatieContext);

  const [procedures, setProcedures] = useState<IRegel[] | null>(null);

  const ophalenProcedures = useCallback(async () => {
    // const peildatum = addMonths(new Date(), -2);
    const procedures = (
      await api.v2.relatie.acceptatie.ophalenProcedures({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [acceptatieContext.relID] }],
        },
        orderSchema: {
          orders: [
            {
              naam: 'DATUM',
              richting: 'DESC',
            },
          ],
        },
      })
    ).procedures;

    setProcedures(procedures);
  }, [acceptatieContext.relID]);

  useEffect(() => {
    ophalenProcedures();
  }, [ophalenProcedures]);

  const context = useMemo<IContext>(() => {
    return {
      onVerversenAangevraagd: async () => await ophalenProcedures(),
    };
  }, [ophalenProcedures]);

  const handleVerwijderen = useCallback(async (id: number) => {
    const params = { ids: [id] };

    const checkData = await api.v2.relatie.acceptatie.checkVerwijderenProcedures(params);
    const checkResult = await checkStore.controleren({ checkData });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: `Acceptatie-procedure verwijderen ?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.relatie.acceptatie.verwijderenProcedures(params);
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: '__documenten' as any,
        title: 'Documenten',
      },
      {
        name: '__documentenStatus' as any,
        title: 'Documentenstatus',
      },
      {
        name: 'VerzoekVerstuurdOp',
        title: 'Verzoek verstuurd',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 115,
      },
      {
        columnName: '__status' as any,
        width: 125,
      },
      {
        columnName: '__documenten' as any,
        width: 225,
      },
      {
        columnName: '__documentenStatus' as any,
        width: 200,
      },
      {
        columnName: 'VerzoekVerstuurdOp',
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      <ProcedureContext.Provider value={context}>
        <MenuLayout
          menu={
            <>
              <div className="mt-2 d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={false}
                  onClick={() => setUrlStateSync('toevoegenProcedureDialoogState', {})}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Nieuwe procedure</span>
                </button>

                <button
                  className="btn btn-sm btn-light ml-3"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length !== 1}
                  onClick={async () => {
                    // const params = { id: urlState.selectie[0], sjabloonNaamEnum:values. };
                    // const checkData = await api.v2.relatie.acceptatie.checkVersturenVerzoekDocumenten(
                    //   params,
                    // );

                    // if (
                    //   (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    // ) {
                    //   return;
                    // }

                    // if (
                    //   (
                    //     await checkStore.bevestigen({
                    //       inhoud: `Verzoek om documenten versturen?`,
                    //     })
                    //   ).type === EResultType.Annuleren
                    // ) {
                    //   return;
                    // }

                    // const result = await api.v2.relatie.acceptatie.versturenVerzoekDocumenten(
                    //   params,
                    // );

                    setUrlStateSync('versturenDocumenten', {
                      aprDocID: urlState.selectie[0],
                    });

                    await ophalenProcedures();
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Versturen bericht</span>
                </button>

                {/* <button
                  className="btn btn-sm btn-light ml-2"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length !== 1}
                  onClick={async () => {
                    const params = { aprProcID: urlState.selectie[0] };
                    const checkData = await api.v2.relatie.acceptatie.checkVersturenAfwijzing(
                      params,
                    );
                    const controleResult = await checkStore.controleren({ checkData });
                    if (controleResult.type === EResultType.Annuleren) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: `Afwijzing sturen?`,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }
                    const result = await api.v2.relatie.acceptatie.versturenAfwijzing(params);

                    await ophalenProcedures();
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Afwijzing versturen</span>
                </button> */}
              </div>
            </>
          }
          body={
            <>
              {procedures === null ? (
                <div className="d-flex flex-fill align-items-center justify-content-center pt-4">
                  <LoadingSpinner />
                </div>
              ) : (
                <GridStyleWrapper height={'calc(100vh - 150px)'}>
                  <Grid rows={procedures} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={[nameof<IRegel>('Datum')]}
                      formatterComponent={(props) => (
                        <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={[nameof<IRegel>('VerzoekVerstuurdOp')]}
                      formatterComponent={(props) => {
                        if (props.value === null) {
                          return <span>Nog niet</span>;
                        }
                        return <span>{format(new Date(props.value), 'dd-MM-yyyy HH:mm')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__status']}
                      formatterComponent={(props) => {
                        const rij: IRegel = props.row;

                        if (rij.status.NaamEnum === EProcedureStatus.Geaccepteerd) {
                          return <span style={{ color: EKleur.Groen }}>{rij.status.Naam}</span>;
                        }
                        if (rij.status.NaamEnum === EProcedureStatus.Geweigerd) {
                          return <span style={{ color: EKleur.Rood }}>{rij.status.Naam}</span>;
                        }
                        return <span>{rij.status.Naam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__documenten']}
                      formatterComponent={(props) => {
                        const rij: IRegel = props.row;
                        const documenten = rij.documenten
                          .map((x) => {
                            return x.NaamKort;
                          })
                          .join(', ');
                        return <span>{documenten}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__documentenStatus']}
                      formatterComponent={(props) => {
                        const rij: IRegel = props.row;

                        if (
                          rij.status.NaamEnum !== EProcedureStatus.Concept &&
                          rij.status.NaamEnum !== EProcedureStatus.Uitstaand
                        ) {
                          return <span></span>;
                        }

                        if (rij.documenten.every((x) => !x.Ontvangen)) {
                          return <span>Nog niets ontvangen</span>;
                        }
                        if (rij.documenten.every((x) => x.Ontvangen)) {
                          return <span>Alles ontvangen</span>;
                        }
                        return <span>Deels ontvangen</span>;
                      }}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />

                    <RowDetailState
                      expandedRowIds={urlState.uitgeklapt}
                      onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                    />

                    <VirtualTable messages={{ noData: 'Geen procedures aanwezig' }} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />

                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;

                        await handleVerwijderen(id);

                        setUrlStateSync('selectie', []);
                        await ophalenProcedures();
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        setUrlStateSync('wijzigenStatusDialoogState', { id });
                      }}
                    />

                    <TableEditColumn
                      width={65}
                      showEditCommand
                      showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                    <TableRowDetail
                      toggleCellComponent={DXTableToggleCellComponent}
                      contentComponent={DetailComp}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              )}
            </>
          }
        />
      </ProcedureContext.Provider>

      {urlState.toevoegenProcedureDialoogState !== null && (
        <ToevoegenDialoog
          open
          relID={props.relID}
          onSuccess={(result) => {
            ophalenProcedures();
            setUrlStateSync('uitgeklapt', [result.ID]);
            setUrlStateSync('toevoegenProcedureDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('toevoegenProcedureDialoogState', null);
          }}
        />
      )}
      {urlState.wijzigenStatusDialoogState !== null && (
        <WijzigenStatusDialoog
          open
          id={urlState.wijzigenStatusDialoogState.id}
          onSuccess={() => {
            ophalenProcedures();
            setUrlStateSync('wijzigenStatusDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenStatusDialoogState', null);
          }}
        />
      )}
      {urlState.versturenDocumenten !== null && (
        <VersturenVerzoekDocumentenDialoog
          open
          aprDocID={urlState.versturenDocumenten.aprDocID}
          onSuccess={() => {
            ophalenProcedures();
            setUrlStateSync('versturenDocumenten', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('versturenDocumenten', null);
          }}
        />
      )}
    </>
  );
};

export default withRouter(Procedure);
