import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../api';
import { IOphalenAbonnementenResultElement } from '../../../../../../../shared/src/api/v2/aanbod/Abonnement';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import AbonnementFormulier, { IFormikValues } from '../AbonnementFormulier';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps {
  abonID: number | null;
}

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [abonnement, setAbonnement] = React.useState<IOphalenAbonnementenResultElement | null>(
    null,
  );
  const [huidigFormulier, setHuidigFormulier] = React.useState<IFormikValues | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleWijzigenClick = useCallback(async () => {
    setIsSubmitting(true);

    const checkData = await api.v2.abonnement.checkWijzigenAbonnement({
      abonID: abonnement!.AbonID,
      naam: huidigFormulier!.naam,
      naamKort: huidigFormulier!.naamKort,
      eenmaligBedrag: huidigFormulier!.eenmaligBedrag,
      minimaleTermijn: huidigFormulier!.minimaleTermijn,
      actief: huidigFormulier!.actief,
    });
    const controleResultaat = await checkStore.controleren({ checkData });

    if (controleResultaat.type === EResultType.Annuleren) {
      setIsSubmitting(false);
      return;
    }

    const resultaat = await api.v2.abonnement.wijzigenAbonnement({
      abonID: abonnement!.AbonID,
      naam: huidigFormulier!.naam,
      naamKort: huidigFormulier!.naamKort,
      eenmaligBedrag: huidigFormulier!.eenmaligBedrag,
      minimaleTermijn: huidigFormulier!.minimaleTermijn,
      actief: huidigFormulier!.actief,
    });

    setIsSubmitting(false);
    props.onSuccess(resultaat);
    setAbonnement(null);
  }, [huidigFormulier, setIsSubmitting, props.onSuccess, abonnement]);

  const handleAnnulerenClick = useCallback(() => {
    props.onAnnuleren();
    setAbonnement(null);
  }, [props.onAnnuleren, setAbonnement]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (abonnement === null) {
      return null;
    }

    return {
      actief: abonnement.Actief,
      eenmaligBedrag: abonnement.EenmaligBedrag,
      minimaleTermijn: abonnement.MinimaleTermijn,
      naam: abonnement.Naam,
      naamKort: abonnement.NaamKort,
    };
  }, [abonnement]);

  useEffect(() => {
    if (props.abonID === null) {
      return;
    }

    const fn = async () => {
      const resultaat = await api.v2.abonnement.ophalenAbonnementen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.abonID!] }] },
      });

      setAbonnement(resultaat.abonnementen[0]);
    };

    fn();
  }, [props.abonID]);

  return (
    <Modal show={props.open}>
      <ModalHeader>
        <ModalTitle>Wijzig abonnement</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ padding: '2rem' }}>
        {abonnement === null ? (
          <LoadingSpinner />
        ) : (
          <AbonnementFormulier
            initieleWaarden={initieleWaarden!}
            onChange={(waarden: IFormikValues | null) => {
              setHuidigFormulier(waarden);
            }}
          />
        )}
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={handleWijzigenClick}
          style={{ width: 100 }}
          disabled={isSubmitting || huidigFormulier === null}
        >
          Wijzigen
        </button>
        <button className="btn btn-secondary" onClick={handleAnnulerenClick} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Modal>
  );
});

export default WijzigenDialoog;
