import api from '../../../index';
import {
  IOphalenPermissiesParams,
  IOphalenPermissiesResult,
} from '../../../../../../shared/src/api/v2/autorisatie/permissie/index';

const permissie = {
  ophalenPermissies: async (
    params: IOphalenPermissiesParams,
  ): Promise<IOphalenPermissiesResult> => {
    return await api.post('/v2/autorisatie/permissie/ophalen-permissies', params);
  },
};

export default permissie;
