import React, { useMemo } from 'react';
import { IActieProps } from '../index';
import ContactverzoekVisualisatie from '../../../components/entiteitVisualisaties/ContactverzoekVisualisatie';

interface IData {
  id: number;
}

const CONTACTVERZOEK: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return <ContactverzoekVisualisatie ctcVerzoekID={data.id} />;
};

export default CONTACTVERZOEK;
