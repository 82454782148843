import api from '../../../..';
import {
  IOphalenFactuurkenmerkenParams,
  IOphalenFactuurkenmerkenResult,
  IToevoegenFactuurkenmerkParams,
  IToevoegenFactuurkenmerkResult,
  IVerwijderenFactuurkenmerkenParams,
  IVerwijderenFactuurkenmerkenResult,
  IWijzigenFactuurkenmerkParams,
  IWijzigenFactuurkenmerkResult,
} from '../../../../../../../shared/src/api/v2/rubriek/factuurkenmerk';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const factuurkenmerk = {
  ophalenFactuurkenmerken: async (
    params: IOphalenFactuurkenmerkenParams,
  ): Promise<IOphalenFactuurkenmerkenResult> => {
    return await api.post('/v2/factuur/rubriek/factuurkenmerk/ophalen-factuurkenmerken', params);
  },

  checkToevoegenFactuurkenmerk: async (
    params: IToevoegenFactuurkenmerkParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/factuur/rubriek/factuurkenmerk/check-toevoegen-factuurkenmerk',
      params,
    );
  },

  toevoegenFactuurkenmerk: async (
    params: IToevoegenFactuurkenmerkParams,
  ): Promise<IToevoegenFactuurkenmerkResult> => {
    return await api.post('/v2/factuur/rubriek/factuurkenmerk/toevoegen-factuurkenmerk', params);
  },

  checkWijzigenFactuurkenmerk: async (
    params: IWijzigenFactuurkenmerkParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/factuur/rubriek/factuurkenmerk/check-wijzigen-factuurkenmerk',
      params,
    );
  },

  wijzigenFactuurkenmerk: async (
    params: IWijzigenFactuurkenmerkParams,
  ): Promise<IWijzigenFactuurkenmerkResult> => {
    return await api.post('/v2/factuur/rubriek/factuurkenmerk/wijzigen-factuurkenmerk', params);
  },

  checkVerwijderenFactuurkenmerken: async (
    params: IVerwijderenFactuurkenmerkenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/factuur/rubriek/factuurkenmerk/check-verwijderen-factuurkenmerken',
      params,
    );
  },

  verwijderenFactuurkenmerken: async (
    params: IVerwijderenFactuurkenmerkenParams,
  ): Promise<IVerwijderenFactuurkenmerkenResult> => {
    return await api.post(
      '/v2/factuur/rubriek/factuurkenmerk/verwijderen-factuurkenmerken',
      params,
    );
  },
};

export default factuurkenmerk;
