import {
  IWijzigenLocatieVoorAspGebrParams,
  IWijzigenLocatieVoorAspGebrResult,
  IOphalenBedrijfslocatiesParams,
  IOphalenBedrijfslocatiesResult,
  IOphalenTelefoonnummersResult,
  IOphalenTelefoonnummersParams,
  IOphalenParams,
  IOphalenResult,
} from '../../../../shared/src/api/v2/bedrijf';
import api from '../index';

const bedrijf = {
  wijzigenLocatieVoorAspGebr: async (
    params: IWijzigenLocatieVoorAspGebrParams,
  ): Promise<IWijzigenLocatieVoorAspGebrResult> => {
    return await api.post('/v2/bedrijf/wijzigen-locatie-voor-aspgebr', params);
  },
  ophalenBedrijfslocaties: async (
    params: IOphalenBedrijfslocatiesParams,
  ): Promise<IOphalenBedrijfslocatiesResult> => {
    return await api.post('/v2/bedrijf/ophalen-bedrijfslocaties', params);
  },
  ophalenTelefoonnummers: async (
    params: IOphalenTelefoonnummersParams,
  ): Promise<IOphalenTelefoonnummersResult> => {
    return await api.post('/v2/bedrijf/ophalen-telefoonnummers', params);
  },
  ophalenBedrijf: async (params: IOphalenParams): Promise<IOphalenResult> => {
    return await api.post('/v2/bedrijf/ophalen-bedrijf', params);
  },
};

export default bedrijf;
