import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  Sorting,
  SortingState,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../shared/src/api/v2/service/opdracht';
import TabelInspringBlok from '../../../../../../../components/layout/TabelInspringBlok';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { ServiceContext } from '../index';
import nameOf from '../../../../../../../core/nameOf';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';
import useUrlState from '../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { Kleur as EKleur, Kleur } from '../../../../../../../bedrijfslogica/constanten';
import WijzigenDialoog from '../../../../../../Service/Opdrachten/WijzigenDialoog';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconInformatie,
  IconSend,
  IconToevoegen,
} from '../../../../../../../components/Icons';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';
import WerkbonMutatieDialoog from '../../../../../../../components/service/WerkbonMutatieDialoog';
import { setCommunicatieOverlayState } from '../../../../../../../one-off-components/CommunicatieOverlay';
import { defaultOpstellenFormulierFormikValues } from '../../../../../../../components/communicatie/EmailWerkblad/OpstellenFormulier';
import { ECommunicatieTabblad } from '../../../../../../../one-off-components/CommunicatieOverlay/MenuHandle';
import _ from 'lodash';
import ICheckData from '../../../../../../../../../shared/src/models/ICheckData';
import VersturenContactgegevensDialoog from '../../../../../../../components/transport/VersturenContactgegevensDialoog';
import OpdrachtInfoDialoog from '../../../../../../../components/service/OpdrachtInfoDialoog';
import AnnulerenOpdrachtDialoog from '../../../../../../../components/service/AnnulerenOpdrachtDialoog';
import IPaginatiePositie from '../../../../../../../../../shared/src/models/IPaginatiePositie';

interface IProps extends RouteComponentProps {}

export interface IWijzigenDialoogState {
  id: number;
}

export interface IWerkbonToevoegenDialoogState {
  servOpdID: number;
}

export interface IVersturenContactgegevensDialoogState {
  relID?: number;
}

export interface IServiceopdrachtInfoDialoogState {
  servOpdID: number;
}
export interface IAnnulerenOpdrachtDialoogState {
  servOpdIDs: number[];
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
  sortering: Sorting[];
  versturenContactgegevensDialoogState: IVersturenContactgegevensDialoogState | null;
  serviceopdrachtInfoDialoogState: IServiceopdrachtInfoDialoogState | null;
  annulerenOpdrachtDialoogState: IAnnulerenOpdrachtDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  wijzigenDialoogState: null,
  sortering: [
    {
      columnName: 'Bezoekdatum',
      direction: 'asc',
    },
  ],
  versturenContactgegevensDialoogState: null,
  serviceopdrachtInfoDialoogState: null,
  annulerenOpdrachtDialoogState: null,
};

interface IRegel extends IOphalenOpdrachtenResultElement {}

const IconAnnuleren = functioneleIconMap[EFunctioneleIcon.Annuleren];

const Opdrachten = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const serviceContext = useContext(ServiceContext);

  const [isBezig, setIsBezig] = useState(false);

  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);
  const [
    werkbonToevoegenDialoogState,
    setWerkbonToevoegenDialoogState,
  ] = useState<IWerkbonToevoegenDialoogState | null>(null);

  const ophalenOpdrachten = useCallback(async () => {
    // const datum = format(addDays(new Date(), -60), 'yyyy-MM-dd');
    const cntBasisIDs = _.uniq(
      (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [serviceContext.relID],
              },
            ],
          },
        })
      ).contracten.map((x) => x.basis.CntBasisID),
    );

    const paginatie: IPaginatiePositie = {
      index: 0,
      aantal: 100,
    };

    const meldingenResult = await api.v2.service.ophalenMeldingen({
      filterSchema: {
        // filters: [{ naam: 'RELID', data: [serviceContext.relID] }],
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'RELID',
                data: [serviceContext.relID],
              },
            },
            {
              filter: {
                naam: 'CNTBASIS_IDS',
                data: cntBasisIDs,
              },
            },
          ],
        },
      },
    });

    // const result = await api.v2.service.ophalenOpdrachten({
    //   filterSchema: { filters: [{ naam: 'REL_IDS', data: [serviceContext.relID] }] },
    //   paginatie: { index: 0, aantal: 100 },
    //   orderSchema: { orders: [{ naam: 'DATUM_VERSTUURD', richting: 'DESC' }] },
    // });

    const result = await api.v2.service.ophalenOpdrachten({
      filterSchema: {
        filters: [
          {
            naam: 'SERVMELD_IDS',
            data: meldingenResult.meldingen.map((x) => x.ID),
          },
        ],
      },
      paginatie: { index: 0, aantal: 100 },
      orderSchema: {
        orders: [{ naam: 'DATUM_VERSTUURD', richting: 'DESC' }],
      },
    });

    setOpdrachten(result.opdrachten);
  }, []);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const handleVersturenOpdrachten = async (ids: number[]) => {
    const checkData = await api.v2.service.checkVersturenServiceOpdrachten({
      ids,
    });
    const controleResultaat = await checkStore.controleren({
      checkData,
    });
    if (controleResultaat.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: `Reparatieopdracht(en) versturen ?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.service.versturenServiceOpdrachten({
      ids,
    });

    ophalenOpdrachten();
  };

  const handleVersturenBevestigingenServiceOpdrachtNaarRelatie = async (ids: number[]) => {
    const resultaat = await checkStore.bevestigen({
      inhoud: (
        <span>
          Bevestiging naar relatie sturen?
          <br />
          (contactpersoon voor de serviceopdracht)
        </span>
      ),
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.service.versturenBevestigingenServiceOpdrachtNaarRelatie({
      ids,
    });

    ophalenOpdrachten();
  };

  const handleAnnulerenOpdrachten = async (ids: number[]) => {
    const checkData = await api.v2.service.checkAnnulerenServiceOpdrachten({
      ids,
    });
    const controleResultaat = await checkStore.controleren({
      checkData,
    });
    if (controleResultaat.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: `Reparatieopdracht(en) annuleren?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.service.annulerenServiceOpdrachten({
      ids,
    });

    await api.v2.service.versturenAnnuleringsbericht({
      ids,
    });

    ophalenOpdrachten();
  };

  const handleVerwijderen = useCallback(async (id: number) => {
    const resultaat = await checkStore.bevestigen({
      inhoud: `Service-opdracht verwijderen?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    const checkData = await api.v2.service.checkVerwijderenServiceOpdracht({
      IDs: [id],
    });
    const controleResult = await checkStore.controleren({
      checkData,
    });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.service.verwijderenServiceOpdracht({
      IDs: [id],
    });

    ophalenOpdrachten();
  }, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'DatumVerstuurd',
        title: 'Verstuurd',
        getCellValue: (x) => {
          return x.DatumVerstuurd === null ? x.DatumVerstuurd : '2099-01-01';
        },
      },
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr.',
      },
      { name: '__opdrachtinfo' as any, title: ' ' },

      {
        name: '__product' as any,
        title: 'Product',
        getCellValue: (x) => {
          const product = x.melding.product;
          return product !== null
            ? product.producttype.Merknaam + ' ' + product.producttype.Typenaam
            : null;
        },
      },
      {
        name: '__referentiecode' as any,
        title: 'Ref.code',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x) => {
          return x.melding.RelID !== null ? (
            <RelatieVisualisatie relID={x.melding.RelID} />
          ) : (
            <span></span>
          );
        },
      },
      {
        name: '__adres' as any,
        title: 'Locatie',
        getCellValue: (x) => {
          const locatie = x.melding.locatie;
          return locatie.Straatnaam + locatie.Huisnummer;
        },
      },
      // {
      //   name: '__plaatsnaam' as any,
      //   title: 'Plaats',
      //   getCellValue: (x) => {
      //     const locatie = x.melding.locatie;
      //     return locatie.Plaatsnaam;
      //   },
      // },
      // {
      //   name: 'Garantieclaim',
      //   title: 'Gar.',
      // },
      {
        name: '__leverancier' as any,
        title: 'Rep.dienst',
        getCellValue: (x) => {
          return x.dienst.relatie!.weergavenaam;
        },
      },

      //   {
      //     name: 'Omschrijving',
      //     title: 'Klacht',
      //   },
      //   {
      //     name: '__aantalWerkbonnen',
      //     title: '# Bon.',
      //   },
      {
        name: '__status' as any,
        title: 'Status',
        getCellValue: (x) => {
          return x.status.Naam;
        },
      },
      {
        name: 'Afgehandeld',
        title: 'Afg.',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'DatumVerstuurd',
        width: 105,
      },
      {
        columnName: '__opdrachtnummer' as any,
        width: 85,
      },
      { columnName: '__opdrachtinfo', width: 50 },

      {
        columnName: '__leverancier' as any,
        width: 125,
      },
      {
        columnName: '__relatie' as any,
        width: 175,
      },
      {
        columnName: 'Garantieclaim',
        width: 80,
      },
      {
        columnName: '__adres' as any,
        width: 200,
      },
      // {
      //   columnName: '__plaatsnaam' as any,
      //   width: 200,
      // },
      {
        columnName: '__product' as any,
        width: 190,
      },
      {
        columnName: '__referentiecode' as any,
        width: 95,
      },
      //   {
      //     columnName: 'Omschrijving' as any,
      //     width: 350,
      //   },
      {
        columnName: '__status' as any,
        width: 140,
      },
      //   {
      //     columnName: '__aantalWerkbonnen' as any,
      //     width: 90,
      //   },
      {
        columnName: 'Afgehandeld',
        width: 65,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((x: IRegel) => x.ServOpdID, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `Bedrag`,
        align: 'right',
      },
    ];
  }, []);

  const handleRPBVersturen = useCallback(async () => {
    if (opdrachten === null) {
      return null;
    }

    const opdrachtenResult = opdrachten!.filter(
      (opdracht) => urlState.selectie.indexOf(opdracht.ServOpdID) !== -1,
    );
    const servOpdIDs = opdrachtenResult.map((x) => x.ServOpdID);

    const servDienstIDs = _.uniq(
      opdrachtenResult.map((opdracht) => opdracht.dienst.ID),
    ) as number[];
    if (servDienstIDs.length > 1) {
      throw new Error('Verschillende servicediensten geselecteerd');
    }
    const [servDienstID] = servDienstIDs;

    const dienstenResult = await api.v2.dienst.service.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [servDienstID],
          },
        ],
      },
    });
    const [dienst] = dienstenResult.diensten;

    const contextdatasResult = await api.v2.sjabloon.ophalenContextdatas({
      contextBepaalData: [
        {
          naamEnum: 'SERVICEDIENST',
          data: {
            servDienstID,
          },
        },
        {
          naamEnum: 'SERVICEOPDRACHT',
          data: {
            servOpdID: servOpdIDs[0],
          },
        },
      ],
    });
    const resolveResult = await api.v2.sjabloon.resolve({
      communicatieKanaalSelectie: {
        naamEnum: 'EMAIL',
      },
      sjabloonSelectie: {
        naamEnum: 'service.rpb',
      },
      contexten: contextdatasResult.contexten,
    });

    setCommunicatieOverlayState!((curr) => ({
      ...curr,
      geselecteerdTabblad: ECommunicatieTabblad.Email,
      emailContext: {
        ...curr.emailContext,
        formulier: {
          ...defaultOpstellenFormulierFormikValues,
          taalID: resolveResult.taalID,
          subject: resolveResult.onderwerp ?? '',
          recipients:
            dienst.EmailOpdracht === null
              ? []
              : [
                  {
                    emailAdres: dienst.EmailOpdracht,
                    orgID: dienst.relatie?.organisatie?.OrgID ?? null,
                    persID: null,
                  },
                ],
          body: resolveResult.inhoud ?? '',
          contextRelIDs: [serviceContext.relID],
        },
      },
    }));
  }, [urlState.selectie, opdrachten, serviceContext.relID]);

  return opdrachten === null ? (
    <div className="d-flex flex-fill align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <div className="d-flex">
        <MenuLayout
          menu={
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={isBezig || urlState.selectie.length === 0}
                onClick={() => handleVersturenOpdrachten(urlState.selectie)}
              >
                <IconSend
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Versturen naar servicedienst</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={isBezig || urlState.selectie.length === 0}
                onClick={() =>
                  handleVersturenBevestigingenServiceOpdrachtNaarRelatie(urlState.selectie)
                }
              >
                <IconSend
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Bevestiging naar relatie</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={isBezig || urlState.selectie.length === 0}
                onClick={() => {
                  setUrlStateSync('annulerenOpdrachtDialoogState', {
                    servOpdIDs: urlState.selectie,
                  });
                }}
              >
                <IconAnnuleren
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Annuleren</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={isBezig || urlState.selectie.length === 0}
                onClick={async () => {
                  const werkbonnenResult = api.v2.service.ophalenWerkbonnen({
                    filterSchema: {
                      filters: [
                        {
                          naam: 'SERVOPD_IDS',
                          data: urlState.selectie,
                        },
                      ],
                    },
                  });

                  if (
                    (await werkbonnenResult).werkbonnen.length !== 0 &&
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Er is reeds een werkbon aan deze opdracht gekoppeld, toch doorgaan?
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  setWerkbonToevoegenDialoogState({
                    servOpdID: urlState.selectie[0],
                  });
                }}
              >
                <IconAnnuleren
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Werkbon toevoegen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
                disabled={isBezig || urlState.selectie.length === 0}
                onClick={async () => {
                  setIsBezig(true);

                  const opdrachten = (
                    await api.v2.service.ophalenOpdrachten({
                      filterSchema: {
                        filters: [
                          {
                            naam: 'IDS',
                            data: urlState.selectie,
                          },
                        ],
                      },
                    })
                  ).opdrachten;
                  const dienstIDs = _.uniq(opdrachten.map((x) => x.dienst.ID));

                  const checkData: ICheckData = {
                    errors: [],
                    warnings: [],
                  };
                  if (dienstIDs.length !== 1) {
                    checkData.errors.push(
                      'De geselecteerde opdrachten zijn niet voor dezelfde servicedienst',
                    );
                  }
                  if (
                    (
                      await checkStore.controleren({
                        checkData,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    setIsBezig(false);
                    return;
                  }

                  await handleRPBVersturen();
                  setIsBezig(false);
                }}
              >
                <IconSend
                  style={{
                    width: 16,
                    height: 16,
                    fill: Kleur.Grijs,
                  }}
                />
                <span className="ml-2">RPB versturen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                // disabled={urlState.opdrachtenSelectie.length === 0}
                onClick={() => {
                  setUrlStateSync('versturenContactgegevensDialoogState', {
                    relID: serviceContext.relID,
                  });
                }}
                disabled={isBezig}
              >
                {/* <IconBevestiging style={{ width: 16, height: 16, fill: EKleur.Grijs }} /> */}
                <span className="ml-2">Contactgegevens versturen</span>
              </button>

              {/* <div className="ml-2 flex-fill">{props.filterbalk}</div> */}
            </div>
          }
          body={
            <>
              <GridStyleWrapper height={'calc(100vh - 100px)'}>
                <Grid rows={opdrachten} columns={kolommen} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={[nameOf<IRegel>('DatumVerstuurd')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      const datum =
                        rij.DatumVerstuurd !== null
                          ? format(new Date(rij.DatumVerstuurd), 'dd-MM-yyyy')
                          : !rij.Afgehandeld
                          ? rij.status.NaamEnum === 'VERSTUURD'
                            ? '(' + format(new Date(rij.Opdrachtdatum), 'dd-MM-yyyy') + ')'
                            : 'Nog versturen'
                          : 'Niet verstuurd';
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__status']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return <span>{rij.status.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__opdrachtnummer']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return (
                        <span>
                          {rij.melding.Meldnummer}-{rij.Volgnummer}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__opdrachtinfo']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return (
                        <a
                          href="#"
                          onClick={() =>
                            setUrlStateSync('serviceopdrachtInfoDialoogState', {
                              servOpdID: rij.ServOpdID,
                            })
                          }
                        >
                          <IconInformatie
                            style={{
                              width: 15,
                              height: 15,
                              fill: EKleur.Blauw,
                            }}
                          />
                        </a>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRegel>('Afgehandeld')]}
                    formatterComponent={(formatterProps) => {
                      return (
                        <span
                          style={{
                            color: formatterProps.value ? Kleur.Rood : Kleur.Blauw,
                          }}
                        >
                          {formatterProps.value ? 'Ja' : 'Nee'}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__aantalWerkbonnen']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      return (
                        <span>{rij.werkbonnen.length !== 0 ? rij.werkbonnen.length : ''}</span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__leverancier']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return <RelatieVisualisatie relID={rij.dienst.relatie!.RelID} />;
                      // return <span>{rij.dienst.relatie.weergavenaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Garantieclaim']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return <span>{rij.Garantieclaim ? 'Ja' : ''}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__adres']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      const locatie = rij.melding.locatie;
                      return (
                        <span>
                          {locatie.Straatnaam +
                            ' ' +
                            locatie.Huisnummer +
                            (locatie.Bisnummer !== null ? ' ' + locatie.Bisnummer : '')}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__plaatsnaam']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      const locatie = rij.melding.locatie;
                      return <span>{locatie.Plaatsnaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__product']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      const product = rij.melding.product;

                      return (
                        <span>
                          {product !== null
                            ? product.producttype.Merknaam + ' ' + product.producttype.Typenaam
                            : ''}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__referentiecode']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return <span>{rij.melding.Referentiecode}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__status']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return <span>{rij.status.Naam}</span>;
                    }}
                  />

                  <SortingState sorting={urlState.sortering} />

                  <IntegratedSorting
                    columnExtensions={[
                      {
                        columnName: nameOf<IRegel>('DatumVerstuurd'),
                        compare: (links, rechts) => {
                          const linksDatum = links === null ? null : new Date(links);
                          const rechtsDatum = links === null ? null : new Date(rechts);

                          if (linksDatum === null) {
                            return +1;
                          }
                          if (rechtsDatum === null) {
                            return -1;
                          }

                          return linksDatum.getTime() - rechtsDatum.getTime();
                        },
                      },
                    ]}
                  />
                  <VirtualTable
                    columnExtensions={kolomExtensies}
                    messages={{
                      noData: 'Geen reparatie-opdrachten',
                    }}
                  />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  {/* <TableHeaderRow showSortingControls /> */}
                  <TableHeaderRow showSortingControls />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={(x) => {
                      const id = x[x.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogState', { id });
                    }}
                    onCommitChanges={(changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;
                      handleVerwijderen(id);
                    }}
                  />
                  <TableEditColumn
                    width={65}
                    // showAddCommand={true}
                    showEditCommand
                    showDeleteCommand
                    commandComponent={DXCommandComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(selectie) =>
                      setUrlStateSync('selectie', selectie as number[])
                    }
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            </>
          }
        />
      </div>
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenDialoogState', null);
          }}
        />
      )}
      {werkbonToevoegenDialoogState !== null && (
        <WerkbonMutatieDialoog
          open={true}
          serviceopdracht={
            opdrachten.find((x) => x.ServOpdID === werkbonToevoegenDialoogState.servOpdID)!
          }
          werkbonID={null}
          onSuccess={() => {
            setWerkbonToevoegenDialoogState(null);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => setWerkbonToevoegenDialoogState(null)}
        />
      )}
      {urlState.versturenContactgegevensDialoogState !== null && (
        <VersturenContactgegevensDialoog
          open
          relID={urlState.versturenContactgegevensDialoogState.relID}
          onSuccess={() => {
            setUrlStateSync('versturenContactgegevensDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('versturenContactgegevensDialoogState', null)}
        />
      )}
      {urlState.serviceopdrachtInfoDialoogState !== null && (
        <OpdrachtInfoDialoog
          id={urlState.serviceopdrachtInfoDialoogState.servOpdID}
          open
          onSuccess={() => setUrlStateSync('serviceopdrachtInfoDialoogState', null)}
          onAnnuleren={() => setUrlStateSync('serviceopdrachtInfoDialoogState', null)}
        />
      )}
      {urlState.annulerenOpdrachtDialoogState !== null && (
        <AnnulerenOpdrachtDialoog
          servOpdIDs={urlState.annulerenOpdrachtDialoogState.servOpdIDs}
          open
          onSuccess={() => {
            ophalenOpdrachten();
            setUrlStateSync('annulerenOpdrachtDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('annulerenOpdrachtDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Opdrachten);
