import React, { useMemo } from 'react';
import { Centen, Euros, Root } from './style';

export interface IOpmaakProps {
  isNegatief: boolean;
  euros: number;
  centen: number;
  eurosMetDuizendtallen: string;
  rechtsUitlijnen: boolean;
  color?: string;
  fontSize: number;
  style?: React.CSSProperties;
}

export const StandaardMutatieBedragOpmaker: React.FC<IOpmaakProps> = (props) => {
  const { isNegatief, eurosMetDuizendtallen, centen, rechtsUitlijnen, color } = props;
  return (
    <Root
      isNegatief={isNegatief}
      style={{
        justifyContent: rechtsUitlijnen ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        ...props.style,
      }}
      color={color}
      fontSize={props.fontSize}
    >
      {isNegatief ? '-' : <span>&nbsp;</span>}
      <span className="valuta">&euro;</span>
      &nbsp;
      <Euros fontSize={props.fontSize}>{eurosMetDuizendtallen},</Euros>
      <Centen fontSize={props.fontSize}>{String(centen).padStart(2, '0')}</Centen>
    </Root>
  );
};

interface IProps {
  bedrag: number;
  rechtsUitlijnen?: boolean;
  opmaakComponent?: React.ComponentType<IOpmaakProps>;
  fontSize?: number;
  style?: React.CSSProperties;
}

const FormatteerBedrag: React.FC<IProps> = (props) => {
  const isNegatief = useMemo(() => props.bedrag < 0, [props.bedrag]);
  const absWaarde = useMemo(() => Math.abs(props.bedrag), [props.bedrag]);
  const euros = useMemo(() => Math.floor(absWaarde), [absWaarde]);
  const centen = useMemo(() => Math.round(Number((absWaarde - euros).toFixed(2)) * 100), [
    absWaarde,
    euros,
  ]);

  const eurosMetDuizendtallen = useMemo(() => {
    const str = String(euros);
    return str
      .split('')
      .reverse()
      .map((x, i) => {
        if (i !== 0 && i % 3 === 0) {
          return `${x}.`;
        }
        return x;
      })
      .reverse()
      .join('');
  }, [euros]);

  const Opmaker = useMemo(() => {
    if (props.opmaakComponent !== undefined) {
      return props.opmaakComponent;
    }

    return StandaardMutatieBedragOpmaker;
  }, [props.opmaakComponent]);

  return (
    <Opmaker
      isNegatief={isNegatief}
      euros={euros}
      centen={centen}
      eurosMetDuizendtallen={eurosMetDuizendtallen}
      rechtsUitlijnen={props.rechtsUitlijnen !== undefined && props.rechtsUitlijnen}
      fontSize={props.fontSize ?? 14}
      style={props.style}
    />
  );
};

export default FormatteerBedrag;
