import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useUrlState from '../../../../core/useUrlState';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { IEntiteitProps } from '../../EntiteitContainer';
import MenuLayout from '../../../MenuLayout';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, EditingState, SelectionState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { IOphalenBoekingRegelsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import nameOf from '../../../../core/nameOf';
import FormatteerBedrag from '../../../MutatieBedrag';
import _ from 'lodash';
import FactuurVisualisatie from '../../../entiteitVisualisaties/FactuurVisualisatie';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../FilterBalkV2';
import Combobox, { IOptie } from '../../../formulier/Combobox';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { IOphalenGrootboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import MultiSelect from '../../../formulier/MultiSelect';
import { IKolom } from '../../../formulier/MultiCombobox';
import { IOphalenDagboekSoortenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { EDagboeksoort, EPermissie } from '../../../../bedrijfslogica/enums';
import BoekingInfoDialoog from '../../../boekhouding/boeking/BoekingInfoDialoog';
import { IconInformatie, IconToevoegen, IconVerwijderen, IconWijzigen } from '../../../Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import BoekingregelInfoDialoog from '../../../boekhouding/boeking/BoekingregelInfoDialoog';
import InkoopfactuurVisualisatie from '../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import BoekingDialoogV3 from '../../../boekhouding/boeking/BoekingDialoogV3';
import { EResultType } from '../../../../stores/CheckStore';
import heeftAutorisatie, { useAutorisatieBewaker } from '../../../../helpers/heeftAutorisatie';
import relatie from '../../../../api/v2/relatie';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum EFilter {
  Dagboeksoort = 'DAGBOEKSRT_IDS',
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  filterData: IFilterData<EFilter>[];
  boekingDialoogV3State: IBoekingDialoogV3State | null;
}

// const defaultUrlState: IUrlState = {
//   selectie: [],
//   uitgeklapt: [],
//   filterData: [
//     {
//       naam: EFilter.Grootboekrekeningen,
//       data: [], // TODO
//       isActief: false,
//     },
//   ],
// };

export interface IRegel extends IOphalenBoekingRegelsResultElement {}

interface IBoekingInfoDialoogState {
  boekingID: number;
}
interface IBoekingregelInfoDialoogState {
  id: number;
}

interface IBoekingDialoogV3State {
  id: number;
}

const geenData = {
  noData: 'Geen boekingen gevonden',
};

const Boekingen: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { autorisatieStore, meldingIndicatieStore } = useContext(RootStoreContext);

  const [dagboeksoorten, setDagboeksoorten] = useState<
    IOphalenDagboekSoortenResultElement[] | null
  >(null);

  const [
    boekingregelInfoDialoogState,
    setBoekingregelInfoDialoogState,
  ] = useState<IBoekingregelInfoDialoogState | null>(null);

  const [
    boekingInfoDialoogState,
    setBoekingInfoDialoogState,
  ] = useState<IBoekingInfoDialoogState | null>(null);

  const defaultUrlState = useMemo<IUrlState>(() => {
    // if (dagboeksoorten === null) {
    //   return null;
    // }

    // const ids = dagboeksoorten!
    //   .filter((x) => x.NaamEnum === EDagboeksoort.BANK || x.NaamEnum === EDagboeksoort.MEMORIAAL)
    //   .map((x) => x.ID);

    const ids = [3, 4]; // TODO Dennis

    return {
      selectie: [],
      uitgeklapt: [],
      filterData: [
        {
          naam: EFilter.Dagboeksoort,
          data: ids,
          isActief: true,
        },
      ],
      boekingDialoogV3State: null,
    };
  }, [dagboeksoorten]);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );

  // const [grootboekrekeningenFilterOpties, setGrootboekrekeningenFilterOpties] = useState<
  //   IOptie<number>[] | null
  // >(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenBoekingregels = useCallback(async () => {
    const regelsResult = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
        orderSchema: {
          orders: [
            { naam: 'BOEKDATUM', richting: 'DESC' },
            { naam: 'BOEKNUMMER', richting: 'DESC' },
            { naam: 'REGELNUMMER', richting: 'DESC' },
          ],
        },
      })
    ).regels;

    setBoekingregels(regelsResult);
  }, [props.relID, filterSchema.filters]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const ophalenDagboeksoorten = useCallback(async () => {
    const result = (
      await api.v2.boeking.dagboek.ophalenDagboekSoorten({
        filterSchema: {
          // filters: [...filterSchema.filters!],
        },
      })
    ).soorten;

    setDagboeksoorten(result);
  }, [filterSchema.filters]);

  useEffect(() => {
    ophalenDagboeksoorten();
  }, [ophalenDagboeksoorten]);

  // Rijbron Grootboekrekeningen
  // useEffect(() => {
  //   (async () => {
  //     if (boekingregels === null) {
  //       return;
  //     }

  //     // Alleen de eerste keer uitvoeren
  //     if (dagboeksoorten !== null) {
  //       return;
  //     }

  //     const grbRekIDs = _.uniq([
  //       ...boekingregels.map((x) => x.C_GrbRekID),
  //       ...boekingregels.map((x) => x.C_GrbRekID),
  //     ]);

  //     const grootboekrekeningenResult = (
  //       await api.v2.boekhouding.boeking.grootboek.ophalenGrootboeken({
  //         filterSchema: {
  //           filters: [
  //             {
  //               naam: 'IDS',
  //               data: grbRekIDs,
  //             },
  //             // {
  //             //   naam: 'NUMMERS',
  //             //   data: [EGrootboekrekening.Vooruitbetaald, EGrootboekrekening.Debiteuren],
  //             // },
  //           ],
  //         },
  //       })
  //     ).grootboeken.map((x) => {
  //       return { ID: x.ID, Naam: x.Naam };
  //     });

  //     const grootboekrekeningenGesorteerd = _.orderBy(grootboekrekeningenResult, ['Naam'], ['asc']);

  //     setGrootboekrekeningenFilterOpties(grootboekrekeningenGesorteerd);
  //   })();
  // }, [boekingregels]);

  const grootboekrekeningKolommen = useMemo<IKolom<IOphalenGrootboekenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 125,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      // {
      //   naam: EFilter.Grootboekrekeningen,
      //   altijdWeergevenInBalk: true,
      //   weergave: (weergaveProps) => {
      //     if (grootboekrekeningenFilterOpties === null) {
      //       return null;
      //     }
      //     const data: number[] = weergaveProps.data;

      //     return (
      //       <div className="d-flex align-items-center">
      //         <span className="mr-2">Grootboekrekening</span>
      //         <MultiCombobox<number, IOphalenGrootboekenResultElement>
      //           sleutelExtractor={(row) => row.ID}
      //           waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
      //           onWaardeChange={(value: number | null) => {
      //             weergaveProps.onDataChange(value === null ? null : [value]);
      //             weergaveProps.setIsActief(true);
      //             weergaveProps.toepassen();
      //           }}
      //           representatieFabriek={(entiteit) => entiteit.Naam}
      //           opties={grootboekrekeningenFilterOpties}
      //           kolommen={grootboekrekeningKolommen}
      //         />
      //         {/* <Combobox
      //           geselecteerd={data.length === 0 ? null : data[0]}
      //           onSelectieChange={(x) => {
      //             const newData = x === null ? [] : [x];
      //             weergaveProps.onDataChange(newData);
      //             weergaveProps.setIsActief(true);
      //             weergaveProps.toepassen();
      //           }}
      //           opties={grootboekrekeningenFilterOpties}
      //         /> */}
      //       </div>
      //     );
      //   },
      // },
      {
        naam: EFilter.Dagboeksoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (dagboeksoorten === null) {
            return null;
          }

          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Dagboeksoorten</span>
              {false === null ? (
                <LoadingSpinner />
              ) : (
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(data) => {
                    weergaveProps.onDataChange(data);
                    weergaveProps.toepassen();
                  }}
                  // opties={grootboekrekeningenFilterOpties}
                  // opties={grootboekrekeningenFilterOpties.map(
                  //   (status): IOptie<number> => {
                  //     return { key: status.ID, weergave: status.Naam };
                  //   },
                  // )}
                  opties={dagboeksoorten.map((x) => ({
                    key: x.ID,
                    weergave: x.Naam,
                  }))}
                  isVerwijderbaar={false}
                />
              )}
            </span>
          );
        },
      },
    ],
    [dagboeksoorten],
  );

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__datum' as any,
        title: 'Datum',
      },
      {
        name: '__boeknummer' as any,
        title: 'Boeknr',
      },
      {
        name: '__grootboekrekeningDB' as any,
        title: 'DB-rek.',
      },
      {
        name: '__grootboekrekeningCR' as any,
        title: 'CR-rek.',
      },
      {
        name: '__naamGrootboekrekeningCR' as any,
        title: 'Naam',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__factuurnummer' as any,
        title: 'Factuur',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__dagboek' as any,
        title: 'Dagboek',
      },
      {
        name: '__boekinginfo' as any,
        title: ' ',
      },
      // {
      //   name: '__regelinfo' as any,
      //   title: ' ',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__datum' as any,
        width: 115,
      },
      {
        columnName: '__boeknummer' as any,
        width: 85,
      },
      {
        columnName: '__grootboekrekeningDB' as any,
        width: 75,
      },
      {
        columnName: '__grootboekrekeningCR' as any,
        width: 75,
      },
      {
        columnName: '__naamGrootboekrekeningCR' as any,
        width: 175,
      },
      {
        columnName: 'Bedrag',
        width: 125,
      },
      {
        columnName: '__factuurnummer' as any,
        width: 125,
      },
      {
        columnName: 'Omschrijving',
        width: 150,
      },
      {
        columnName: '__dagboek' as any,
        width: 100,
      },
      {
        columnName: '__boekinginfo' as any,
        width: 60,
      },
      {
        columnName: '__regelinfo' as any,
        width: 60,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                // disabled={}
                onClick={() => {}}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Afboeken vooruitontvangen bedrag</span>
              </button>
              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={async () => {
                  const boeking = (
                    await api.v2.boekhouding.boeking.ophalenBoekingen({
                      filterSchema: {
                        filters: [{ naam: 'BOEKINGREG_IDS', data: [urlState.selectie[0]] }],
                      },
                    })
                  ).boekingen[0];

                  setUrlStateSync('boekingDialoogV3State', { id: boeking.ID });
                  return;
                }}
              >
                <IconWijzigen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Wijzigen boeking</span>
              </button> */}

              {/* <button
                      className="btn btn-sm btn-light d-flex align-items-center"
                      style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                      onClick={() => setBoekingdialoogState({})}
                    >
                      <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                      <span className="ml-2">Nieuwe boeking</span>
                    </button> */}
              {/* <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-2"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                    disabled={urlState.selectie.length !== 1}
                    onClick={() => {
                      alert('TODO');
                      // const boekingID = props.boekingsregels?.find(
                      //   (x) => x.ID === props.boekingsregelsSelectie[0],
                      // )!.BoekingID!;
                      // handleVerwijderen([boekingID]);
                    }}
                  >
                    <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                    <span className="ml-2">Verwijderen boeking</span>
                  </button> */}
              <div className="d-flex flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          boekingregels === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center p-4">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid getRowId={(x) => x.ID} rows={boekingregels} columns={kolommen}>
                <DataTypeProvider
                  for={['__boeknummer']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.boeking.Boeknummer}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__grootboekrekeningDB']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.dbRekening.Nummer}</span>;
                  }}
                />
                <DataTypeProvider
                  for={['__grootboekrekeningCR']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.crRekening.Nummer}</span>;
                  }}
                />
                <DataTypeProvider
                  for={['__naamGrootboekrekeningCR']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.crRekening.Naam}</span>;
                  }}
                />
                <DataTypeProvider
                  for={[nameOf<IRegel>('Bedrag')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <FormatteerBedrag bedrag={formatterProps.value} />;
                  }}
                />

                <DataTypeProvider
                  for={['__factuurnummer']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    if (rij.FactID !== null) {
                      return <FactuurVisualisatie factID={rij.FactID} />;
                    }
                    if (rij.InkFactID !== null) {
                      return <InkoopfactuurVisualisatie inkFactID={rij.InkFactID} />;
                    }
                    return <span></span>;
                  }}
                />

                <DataTypeProvider
                  for={['__datum']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return <span>{format(new Date(rij.boeking.Boekdatum), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__dagboek']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.boeking.dagboek.NaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__boekinginfo']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      <a
                        href="#"
                        onClick={() => {
                          setBoekingInfoDialoogState({ boekingID: rij.BoekingID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                      </a>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__regelinfo']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      <a
                        href="#"
                        onClick={() => {
                          setBoekingregelInfoDialoogState({ id: rij.ID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                      </a>
                    );
                  }}
                />

                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={async (x) => {
                    const id = x[x.length - 1] as number;

                    const geautoriseerd = heeftAutorisatie({
                      isAdmin: autorisatieStore.isAdmin!,
                      gebruikersPermissies: autorisatieStore.permissies!,
                      vereistePermissies: [EPermissie.BoekingWijzigen],
                    }).isToegestaan;

                    if (!geautoriseerd) {
                      return await checkStore.melden({
                        titel: 'Je bent hiertoe niet geautoriseerd',
                      });
                    }

                    const boeking = (
                      await api.v2.boekhouding.boeking.ophalenBoekingen({
                        filterSchema: {
                          filters: [{ naam: 'BOEKINGREG_IDS', data: [id] }],
                        },
                      })
                    ).boekingen[0];

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: (
                            <span>
                              Hiermee kun je boeking (#{boeking.Boeknummer}) aanpassen waarin deze
                              boekingsregel voorkomt.
                              <br />
                              <br />
                              Doe dit alleen als je weet wat je doet!
                            </span>
                          ),
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    setUrlStateSync('boekingDialoogV3State', { id: boeking.ID });
                  }}
                  onCommitChanges={() => null}
                />
                <VirtualTable messages={geenData} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                {/* TODO: Ivm verkeerd voorgevuld dagboek mag er momenteel niet gewijzigd worden */}
                <TableEditColumn
                  width={35}
                  // showEditCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />
                <TableHeaderRow />

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {boekingInfoDialoogState !== null && (
        <BoekingInfoDialoog
          open
          boekingID={boekingInfoDialoogState.boekingID}
          onSuccess={async () => {
            setBoekingInfoDialoogState(null);
          }}
          onAnnuleren={() => setBoekingInfoDialoogState(null)}
        />
      )}
      {boekingregelInfoDialoogState !== null && (
        <BoekingregelInfoDialoog
          open
          id={boekingregelInfoDialoogState.id}
          onSuccess={async () => {
            setBoekingregelInfoDialoogState(null);
          }}
          onAnnuleren={() => setBoekingregelInfoDialoogState(null)}
        />
      )}

      {urlState.boekingDialoogV3State !== null && (
        <BoekingDialoogV3
          open
          onSuccess={async () => {
            ophalenBoekingregels();
            setUrlStateSync('boekingDialoogV3State', null);
          }}
          onAnnuleren={() => setUrlStateSync('boekingDialoogV3State', null)}
          boekingID={urlState.boekingDialoogV3State.id}
          // dagboekenProvider={props.boekingdialoogDagboekenProvider}
          // regelsProvider={props.boekingdialoogRegelsProvider}
          // dagboekVoorselectieProvider={props.boekingdialoogDagboekVoorselectieProvider}
          // boekdatumProvider={boekingdialoogBoekdatumProvider!}
        />
      )}
    </>
  );
};

export default withRouter(Boekingen);
