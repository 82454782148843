import {
  IOphalenContractWeergaveParams,
  IOphalenContractWeergaveResult,
  ITransportOpdrachtMakenParams,
  ITransportOpdrachtMakenResult,
} from '../../../../../../shared/src/api/v2/relatie/contract';
import api from '../../../index';

const contract = {
  ophalenContractWeergave: async (
    params: IOphalenContractWeergaveParams,
    signal?: AbortSignal,
  ): Promise<IOphalenContractWeergaveResult> => {
    return await api.post('/v2/relatie/contract/ophalen-contract-weergave', params, {
      signal,
    });
  },
  transportOpdrachtenMaken: async (
    params: ITransportOpdrachtMakenParams,
    signal?: AbortSignal,
  ): Promise<ITransportOpdrachtMakenResult> => {
    return await api.post('/v2/relatie/contract/transport-opdrachten-maken', params, {
      signal,
    });
  },
};

export default contract;
