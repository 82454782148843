import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { formatteerBestandsgrootte } from '../../../../../helpers';
import { IconUitvergroten } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IBijlageVisualisatieInfo } from '../../../index';
import { Root } from './style';

interface IProps {
  hovered: boolean;
  onHoveredChange: (hovered: boolean) => void;
  imageSrc: string;
  info: IBijlageVisualisatieInfo | null;
  uploadProgress?: number;
  acties: JSX.Element;
}

const AfbeeldingBijlageItemVisualisatie = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  return (
    <Root
      onMouseEnter={() => props.onHoveredChange(true)}
      onMouseLeave={() => props.onHoveredChange(false)}
      ref={ref}
      backgroundImageSrc={props.imageSrc}
    >
      <div className="d-flex flex-column flex-fill">
        <div className="flex-fill" />

        {props.info === null ? (
          <Spinner animation="border" />
        ) : (
          <div
            className="d-flex align-items-center pl-2"
            style={{
              backgroundColor: `rgba(255, 255, 255, 0.85)`,
            }}
          >
            <div className="d-flex flex-column ml-1" style={{ marginTop: 3, marginBottom: 3 }}>
              <span
                style={{
                  textOverflow: 'ellipsis',
                  width: 195,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                title={props.info.naam}
              >
                {props.info.naam}
              </span>
              <div className="d-flex" style={{}}>
                <span className="text-muted" style={{ fontSize: 11 }}>
                  {props.info.grootte === null
                    ? 'Bestandsgrootte onbekend'
                    : formatteerBestandsgrootte(props.info.grootte)}
                </span>
                <div className="flex-fill" />
                <div style={{ marginTop: -4 }}>{props.acties}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {props.uploadProgress && (
        <div
          style={{
            height: 1,
            width: `${props.uploadProgress}%`,
            backgroundColor: Kleur.Blauw,
            marginTop: 5,
            marginBottom: 3,
          }}
        />
      )}
    </Root>
  );
});

export default AfbeeldingBijlageItemVisualisatie;
