import React, { useContext, useMemo } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import { IWhatsappMedia } from '../../../../../../../api/services/v1/whatsapp';
import LoadingSpinner from '../../../../../../Gedeeld/LoadingSpinner';
import { useWhatsappV2Store } from '../../../../store';
import { IOphalenBestandenResultElement } from '../../../../../../../../../shared/src/api/v2/bestand/bestand';
import useBijGewijzigdEffect from '../../../../../../../core/useBijGewijzigdEffect';
import { IOphalenMediaTypesV2ResultElement } from '../../../../../../../../../shared/src/api/v2/mediatype';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { getIconFile, IconDownload } from '../../../../../../Icons';
import filesize from 'filesize';
import downloadUrl from '../../../../../../../core/downloadUrl';
import Knop from '../../../../Knop';
import { GlobaleRendererContext } from '../../../../../../../one-off-components/GlobaleRenderer';
import MediaWeergaveDialoog from '../../../../../../dialogen/MediaWeergaveDialoog';
import { EMediaWeergaveType } from '../../../../../../MediaWeergave';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(150, 150, 150, 0.1);
  border-radius: 4px;
  margin: 5px 5px 0;
  border: 1px solid rgba(200, 200, 200, 0.3);
`;

export interface IMediaGrootte {
  width: number;
  height: number;
}

interface IProps {
  media: IRemoteData<IWhatsappMedia>;
  onLayoutHerberekenen: () => void;
  onMediaGrootteBepaald: (mediaGrootte: IMediaGrootte) => void;
}

const MediaWeergave = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const store = useWhatsappV2Store();

  const bestand = useMemo<IRemoteData<IOphalenBestandenResultElement | null>>(() => {
    if (props.media.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (props.media.data!.bestandId === null) {
      return createReadyRemoteData(null);
    }
    const bestand = store.bestandBijID[props.media.data!.bestandId];
    if (bestand === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(bestand);
  }, [props.media, store.bestandBijID]);

  const mediaType = useMemo<IRemoteData<IOphalenMediaTypesV2ResultElement | null>>(() => {
    if (bestand.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (bestand.data === null || bestand.data!.MediaTypeID === null) {
      return createReadyRemoteData(null);
    }
    const mediaType = store.mediaTypeBijID[bestand.data!.MediaTypeID];
    if (mediaType === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(mediaType);
  }, [bestand, store.mediaTypeBijID]);

  useBijGewijzigdEffect(() => {
    props.onLayoutHerberekenen();
  }, [props.media.data?.id, bestand.data?.ID, mediaType.data?.ID]);

  if (
    props.media.state === ERemoteDataState.Pending ||
    bestand.state === ERemoteDataState.Pending ||
    mediaType.state === ERemoteDataState.Pending
  ) {
    return (
      <Root>
        <div className="d-flex flex-fill align-items-center justify-content-center p-3">
          <LoadingSpinner />
        </div>
      </Root>
    );
  }

  if (bestand.data === null) {
    return (
      <Root>
        <div className="d-flex align-items-center justify-content-center p-3 pl-4 pr-4">
          <span className="text-muted">Het gekoppelde bestand is niet meer beschikbaar.</span>
        </div>
      </Root>
    );
  }

  const isAfbeelding = mediaType.data!.MediaType.startsWith('image');
  const isVideo = mediaType.data!.MediaType.startsWith('video');
  if (isAfbeelding || isVideo) {
    return (
      <Root>
        <div className="d-flex align-items-center justify-content-center">
          {isAfbeelding ? (
            <img
              src={bestand.data!.url}
              style={{ maxWidth: '100%', maxHeight: 500, borderRadius: 4, cursor: 'zoom-in' }}
              alt={bestand.data!.Naam}
              onLoadedMetadata={(ev) => {
                const img = ev.currentTarget as HTMLImageElement;
                const imgWidth = img.naturalWidth;
                const imgHeight = img.naturalHeight;

                props.onMediaGrootteBepaald({ width: imgWidth, height: imgHeight });
              }}
              onError={() => {
                props.onLayoutHerberekenen();
              }}
              onClick={async () => {
                await globaleRenderer.render((renderProps) => (
                  <MediaWeergaveDialoog
                    mediaWeergaven={[
                      {
                        mediaType: mediaType.data!.MediaType,
                        src: bestand.data!.url,
                        type: EMediaWeergaveType.Afbeelding,
                        title: props.media.data!.titel ?? bestand.data!.Naam,
                        id: 1,
                      },
                    ]}
                    defaultCurrent={1}
                    open
                    onSuccess={() => renderProps.destroy()}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
            />
          ) : (
            <video
              style={{ maxWidth: '100%', maxHeight: 500, borderRadius: 4 }}
              onLoadedMetadata={(ev) => {
                const video = ev.currentTarget as HTMLVideoElement;
                const videoWidth = video.videoWidth;
                const videoHeight = video.videoHeight;

                props.onMediaGrootteBepaald({ width: videoWidth, height: videoHeight });
              }}
              controls
              onError={() => {
                props.onLayoutHerberekenen();
              }}
            >
              <source src={bestand.data!.url} type={mediaType.data!.MediaType} />
            </video>
          )}
        </div>
      </Root>
    );
  }

  const Icon = getIconFile(mediaType.data!.MediaType);

  return (
    <Root>
      <div className="d-flex align-items-center p-2 flex-fill pl-4">
        <Icon style={{ width: 32, height: 32 }} />
        <div className="ml-3 flex-fill d-flex flex-column">
          <a href={bestand.data!.url} target="_blank">
            {bestand.data!.Naam}
          </a>
          <span className="text-muted" style={{ fontSize: 12 }}>
            {bestand.data!.Grootte === null
              ? 'Grootte onbekend'
              : filesize(bestand.data!.Grootte, { base: 2, standard: 'jedec' })}
          </span>
        </div>

        <div className="flex-fill d-flex align-items-center justify-content-end">
          <Knop onClick={() => downloadUrl(bestand.data!.url, bestand.data!.Naam)}>
            <IconDownload style={{ fill: Kleur.ZwembadBlauw, width: 20, height: 20 }} />
          </Knop>
        </div>
      </div>
    </Root>
  );
};

export default MediaWeergave;
