import styled from 'styled-components';

export interface IRootProps {
  selected: boolean;
}
export const Root = styled.div<IRootProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#e8f1fe' : 'inherit')};

  p {
    margin: 0;
    padding: 0;
  }
`;

export const NaamContainer = styled.p`
  font-size: 15px;
  font-weight: bold;
`;
export const TelefoonnummerContainer = styled.p`
  font-size: 13px;
`;
export const PreviewContainer = styled.p`
  font-size: 13px;
  color: #858585;
  text-overflow: ellipsis;
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
`;
export const PersoonContainer = styled.div``;
