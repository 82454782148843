import React, { useContext } from 'react';
import { Main, StyledPaginaHeader, UserContainer } from './styles';
import { withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../stores/RootStore';
import NotificatieKnop from './NotificatieKnop';

// interface MatchParams {
//   children: React.ReactNode;
// }

// interface IProps extends RouteComponentProps<MatchParams> {}

// interface IProps {
//   children: React.ReactNode;
//   // match: RouteComponentProps;
// }

const PaginaHeader = (props: any) => {
  return (
    <>
      <StyledPaginaHeader>
        <Main>
          <div style={{ height: '100%' }}>{props.children}</div>
        </Main>

        {/*<div className="d-flex justify-content-center align-items-center mr-2">*/}
        {/*  <NotificatieKnop />*/}
        {/*  <UserContainer*/}
        {/*    onClick={(ev) => {*/}
        {/*      ev.stopPropagation();*/}
        {/*      profielStore.setOverlayState(true);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {gebruikerStore.gebruiker!.GebruikersNaam}*/}
        {/*  </UserContainer>*/}
        {/*  <a href="#" onClick={() => gebruikerStore.logout(props.history)}>*/}
        {/*    Uitloggen*/}
        {/*  </a>*/}
        {/*</div>*/}
      </StyledPaginaHeader>
    </>
  );
};

export default withRouter(PaginaHeader);
