import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Body, ButtonGroep, Header, NotitieSectie, Titel } from './style';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import Memo, { ISuggestie } from '../../../Memo';
import { EFunctioneleIcon, functioneleIconMap } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import useGebruikersStempel, { IPlaatsStempelOutput } from '../../../../core/useGebruikersStempel';
import { INotitieParams } from '../../../../models/relatie';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IOphalenMemosResultElement } from '../../../../../../shared/src/api/v2/memo';
import MemoVanAnder from './MemoVanAnder';

interface IProps {
  relatie: IOphalenRelatiesResultElementV2;
}

const Notitie: React.FC<IProps> = observer((props) => {
  const { Notities, RelID, VasteInfo } = props.relatie;
  const { klantkaartStore, gebruikerStore, memoStore, instellingStore } = useContext(
    RootStoreContext,
  );
  const geannuleerdRef = useRef<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [notitieText, setNotitieText] = useState('');

  const { plaatsStempel, wijzigenZonderStempel, bezigMetStempelen } = useGebruikersStempel(
    useMemo(
      () => ({
        inputRef: textareaRef,
        text: notitieText,
        onTextChange: setNotitieText,
      }),
      [notitieText, setNotitieText],
    ),
  );

  useEffect(() => {
    // zet formulier input waarde -> Notities props
    setNotitieText(Notities || '');
  }, [Notities]);

  const [suggesties, setSuggesties] = useState<ISuggestie[] | null>(null);

  const ophalenMemoLabels = useCallback(async () => {
    const result = await api.v2.memo.ophalenMemoLabels({});

    setSuggesties(
      result.labels.map((x: any) => {
        return { label: x.Label, inhoud: x.Inhoud };
      }),
    );
  }, []);

  useEffect(() => {
    ophalenMemoLabels();
  }, [ophalenMemoLabels]);

  const MemoIcon = useMemo(() => functioneleIconMap[EFunctioneleIcon.Memo], []);

  const memo = useMemo<IRemoteData<IOphalenMemosResultElement | null>>(() => {
    if (memoStore.memos.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const x = memoStore.memos.data!.find(
      (memo) =>
        memo.AspGebrID === gebruikerStore.gebruiker!.AspGebrID &&
        memo.RelID === props.relatie.RelID,
    );
    return x === undefined ? createReadyRemoteData(null) : createReadyRemoteData(x);
  }, [memoStore.memos, gebruikerStore.gebruiker, props.relatie.RelID]);

  const memosVanAnderen = useMemo<IRemoteData<IOphalenMemosResultElement[]>>(() => {
    if (memoStore.memos.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const x = memoStore.memos.data!.filter(
      (memo) =>
        memo.AspGebrID !== gebruikerStore.gebruiker!.AspGebrID &&
        memo.RelID === props.relatie.RelID,
    );
    return createReadyRemoteData(x);
  }, [memoStore.memos, gebruikerStore.gebruiker, props.relatie.RelID]);

  const stempelRef = useRef<IPlaatsStempelOutput | null>(null);

  return (
    <NotitieSectie>
      {suggesties !== null && memo.state === ERemoteDataState.Ready && memo.data !== null && (
        <div>
          <Header>
            <Titel>Memo</Titel>
            <div className="flex-fill" />
            <a
              href="#"
              style={{ fontSize: 12 }}
              className="mr-2"
              onClick={async (ev) => {
                ev.preventDefault();
                await memoStore.verwijderMemo(memo.data!.ID);
              }}
            >
              Memo verwijderen
            </a>
          </Header>
          <Memo
            memo={memo.data!.Inhoud || ''}
            onMemoChange={(x) => {
              memoStore.wijzigMemo(memo.data!.ID, {
                ...memo.data!,
                Inhoud: x,
              });
            }}
            disabled={memoStore.memoIDsInVerwerking.findIndex((x) => x === memo.data!.ID) !== -1}
            suggesties={suggesties}
          />
        </div>
      )}
      {instellingStore.RelatieMemosDelen &&
        memosVanAnderen.state === ERemoteDataState.Ready &&
        memosVanAnderen.data!.length > 0 && (
          <div className="mb-3">
            <Header>
              <Titel>Memo's van anderen</Titel>
              <div className="flex-fill" />
            </Header>
            <div className="d-flex flex-column" style={{ rowGap: 8 }}>
              {memosVanAnderen.data!.map((memo) => (
                <MemoVanAnder
                  key={memo.AspGebrID}
                  inhoud={memo.Inhoud}
                  aspGebrID={memo.AspGebrID}
                />
              ))}
            </div>
          </div>
        )}
      <Header>
        <Titel>Notities</Titel>
        <div className="flex-fill" />
        {memo.state === ERemoteDataState.Ready && memo.data === null && (
          <button
            style={{
              border: 0,
              outline: 0,
              background: 0,
            }}
            onClick={async (ev) => {
              ev.preventDefault();

              await api.v2.memo.toevoegenMemo({
                inhoud: '',
                relID: props.relatie.RelID,
                aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
              });
            }}
          >
            <MemoIcon style={{ fill: Kleur.DonkerOranje }} />
          </button>
        )}
        {bezigMetStempelen ? (
          <>
            <ButtonGroep>
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();

                  geannuleerdRef.current = true;
                  stempelRef.current!.herstellen();
                  stempelRef.current = null;
                }}
                className="mr-2"
              >
                Annuleren
              </a>
              {/*<a*/}
              {/*  href="#"*/}
              {/*  onClick={(ev) => {*/}
              {/*    ev.preventDefault();*/}
              {/*    stempelRef.current!.doorvoeren();*/}
              {/*    stempelRef.current = null;*/}

              {/*    const notitieParams: INotitieParams = {*/}
              {/*      relID: RelID,*/}
              {/*      notities: notitieText,*/}
              {/*      vasteInfo: VasteInfo || '',*/}
              {/*    };*/}
              {/*    klantkaartStore.wijzigNotities(notitieParams);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Opslaan*/}
              {/*</a>*/}
            </ButtonGroep>
          </>
        ) : (
          <>
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                stempelRef.current = plaatsStempel();
              }}
              style={{ fontSize: 12 }}
            >
              {Notities === null || Notities.length === 0 ? 'Toevoegen' : 'Toevoegen'}
            </a>
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                stempelRef.current = wijzigenZonderStempel();
              }}
              style={{ fontSize: 12 }}
              className="ml-2"
            >
              Corrigeren
            </a>
          </>
        )}
      </Header>

      <Body>
        <textarea
          value={notitieText}
          onChange={(ev) => setNotitieText(ev.target.value)}
          rows={notitieText.length > 200 ? 11 : 7}
          ref={textareaRef}
          className="form-control"
          disabled={!bezigMetStempelen}
          onBlur={(ev) => {
            ev.preventDefault();
            setTimeout(() => {
              if (geannuleerdRef.current) {
                geannuleerdRef.current = false;
                return;
              }

              stempelRef.current!.doorvoeren();
              stempelRef.current = null;

              const notitieParams: INotitieParams = {
                relID: RelID,
                notities: notitieText,
                vasteInfo: VasteInfo || '',
              };
              klantkaartStore.wijzigNotities(notitieParams);
            }, 50);
          }}
        />
      </Body>
    </NotitieSectie>
  );
});

export default Notitie;
