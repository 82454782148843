import {
  IHandmatigAfhandelenTerugbelverzoekParams,
  IHandmatigAfhandelenTerugbelverzoekResult,
  IOphalenTerugbelverzoekenParams,
  IOphalenTerugbelverzoekenResult,
  IVersturenContactverzoekenParams,
  IVersturenContactverzoekenResult,
  IVerwijderenTerugbelverzoekenParams,
  IVerwijderenTerugbelverzoekenResult,
} from '../../../../../shared/src/api/v2/terugbelverzoek';
import api from '../../index';

const terugbelverzoek = {
  ophalenTerugbelverzoeken: async (
    params: IOphalenTerugbelverzoekenParams,
    signal?: AbortSignal,
  ): Promise<IOphalenTerugbelverzoekenResult> => {
    return await api.post('/v2/terugbelverzoek/ophalen-terugbelverzoeken', params, {
      signal,
    });
  },
  verwijderenTerugbelverzoeken: async (
    params: IVerwijderenTerugbelverzoekenParams,
  ): Promise<IVerwijderenTerugbelverzoekenResult> => {
    return await api.post('/v2/terugbelverzoek/verwijderen-terugbelverzoeken', params);
  },
  handmatigAfhandelenTerugbelverzoek: async (
    params: IHandmatigAfhandelenTerugbelverzoekParams,
  ): Promise<IHandmatigAfhandelenTerugbelverzoekResult> => {
    return await api.post('/v2/terugbelverzoek/handmatig-afhandelen-terugbelverzoek', params);
  },
  versturenContactverzoeken: async (
    params: IVersturenContactverzoekenParams,
  ): Promise<IVersturenContactverzoekenResult> => {
    return await api.post('/v2/terugbelverzoek/versturen-contactverzoeken', params);
  },
};

export default terugbelverzoek;
