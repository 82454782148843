import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ASPTabel from '../../tabel/ASPTabel';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import {
  IOphalenOpdrachtenResult,
  IOphalenOpdrachtenResultElement,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import { formatteerAdresV2 } from '../../../helpers';
import RelatiesVisualisaties from '../../personalia/RelatiesVisualisaties';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../FilterBalkV2';
import { dagDatum } from '../../../helpers/datum';
import DatumKiezer from '../../formulier/DatumKiezer';
import { format } from 'date-fns';

enum EFilter {
  Bezoekdatum = 'BEZOEKDATUM',
}

enum EKey {
  Opdrachtnummer,
  Locatie,
  Bezoekdatum,
  Relaties,
}

export interface ITransportopdrachtSelectieDialoogResult {
  trsOpdID: number;
}

interface IProps extends IDialoogProps<ITransportopdrachtSelectieDialoogResult> {}

const TransportopdrachtSelectieDialoog = (props: IProps) => {
  const [data, setData] = useState<IRemoteData<IOphalenOpdrachtenResult>>(
    createPendingRemoteData(),
  );
  const [filterData, setFilterData] = useState<IFilterData<EFilter>[]>([
    {
      naam: EFilter.Bezoekdatum,
      isActief: true,
      data: dagDatum(new Date()),
    },
  ]);
  const filterSchema = useMemo(() => maakFilterSchema(filterData), [filterData]);
  const [gekozenOpdracht, setGekozenOpdracht] = useState<number | null>(null);

  const ophalenData = useCallback(async () => {
    const result = await api.v2.transport.opdracht.ophalenOpdrachten({
      paginatie: {
        index: 0,
        aantal: 50,
      },
      filterSchema,
    });
    setData(createReadyRemoteData(result));
  }, [filterSchema]);

  useEffect(() => {
    ophalenData();
  }, [ophalenData]);

  const rijenData = useMemo(() => {
    if (data.state === ERemoteDataState.Ready) {
      return {
        totaalAantal: data.data!.totaalAantal,
        rijen: data.data!.opdrachten.reduce((acc, curr, i) => ({ ...acc, [i]: curr }), {}),
      };
    }

    return {
      totaalAantal: 5,
      rijen: {},
    };
  }, [data]);

  const keyExtractor = useCallback((item: IOphalenOpdrachtenResultElement) => item.TrsOpdID, []);

  const kolommen = useMemo<ASPKolom<EKey, IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        key: EKey.Opdrachtnummer,
        label: 'Opdrachtnr.',
        renderer: (item) => item.Opdrachtnummer,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
      },
      {
        key: EKey.Bezoekdatum,
        label: 'Bezoekdatum',
        renderer: (item) =>
          item.Bezoekdatum === null ? (
            <span>&ndash;&nbsp;&ndash;</span>
          ) : (
            format(new Date(item.Bezoekdatum), 'dd-MM-yyyy')
          ),
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
      },
      {
        key: EKey.Locatie,
        label: 'Locatie',
        renderer: (item) =>
          formatteerAdresV2({
            straatnaam: item.locatie.Straatnaam,
            huisnummer: item.locatie.Huisnummer,
            postcode: item.locatie.Postcode,
            bisnummer: item.locatie.Bisnummer,
            plaatsnaam: item.locatie.Plaatsnaam,
            landnaamEnum: item.locatie.LandnaamEnum,
            landnaamKort: item.locatie.LandnaamKort,
          }),
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
      },
      {
        key: EKey.Relaties,
        label: 'Relaties',
        renderer: (item) => 'TODO',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Bezoekdatum,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Bezoekdatum</span>
            <DatumKiezer
              waarde={weergaveProps.data}
              onGewijzigd={(datum) => {
                weergaveProps.onDataChange(datum);
                weergaveProps.setIsActief(true);
                weergaveProps.toepassen();
              }}
              determineNextValidDate="ONBEGRENST"
              determinePreviousValidDate="ONBEGRENST"
            />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Selecteer transportopdracht</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ height: 500, padding: 0 }} className="d-flex flex-column">
        <div className="pl-3 pr-3 p-2 w-100">
          <FilterBalkV2
            filters={filters}
            filterData={filterData}
            onFilterDataChange={setFilterData}
          />
        </div>
        <div style={{ height: 500 }} className="d-flex flex-column w-100">
          <ASPTabel
            rijen={rijenData.rijen}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            totaalAantalRijen={rijenData.totaalAantal}
            gefocustRij={gekozenOpdracht ?? undefined}
            ongefocustRijChange={(rij) => setGekozenOpdracht(rij ?? null)}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() => {
            props.onSuccess({
              trsOpdID: gekozenOpdracht!,
            });
          }}
          style={{ width: 100 }}
          disabled={gekozenOpdracht === null}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default TransportopdrachtSelectieDialoog;
