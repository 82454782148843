import React, { useEffect, useMemo, useRef, useState } from 'react';
import Chip from '../Chip';
import { Kleur } from '../../bedrijfslogica/constanten';
import styled from 'styled-components';

export interface ISuggestie {
  label: string;
  inhoud: string;
}

interface IProps {
  memo: string;
  onMemoChange: (memo: string) => void;
  suggesties?: ISuggestie[];
  geenAutoFocus?: boolean;
  disabled?: boolean;
}

const MemoTextarea = styled.textarea`
  ::-webkit-input-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }
  :-moz-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }
  ::-moz-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }
  :-ms-input-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }

  :disabled {
    background-color: ${Kleur.MemoGeelDisabled};
  }

  outline: 0;
  padding: 5px 10px 10px 10px;
  border: 1px solid ${Kleur.LichtGrijs};
  background-color: ${Kleur.MemoGeel};
  font-size: 13px;
  width: 100%;
`;

const Memo: React.FC<IProps> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // useEffect(() => {
  //   if (memo === null) {
  //     return;
  //   }
  //   textareaRef.current!.focus();
  // }, [memo]);

  return (
    <div>
      {props.memo === '' && props.suggesties !== undefined && (
        <div className="flex-wrap mt-1" style={{ display: 'inline-flex', gap: 5 }}>
          {props.suggesties.map((suggestie, i) => (
            <button
              key={i}
              style={{ border: 0, outline: 0, padding: 0, background: 0 }}
              onClick={() => {
                props.onMemoChange(suggestie.inhoud + ' ');
                textareaRef.current!.focus();
              }}
            >
              <Chip>
                <span style={{ fontSize: 11 }} className="ml-1 mr-1">
                  {suggestie.label}
                </span>
              </Chip>
            </button>
          ))}
        </div>
      )}
      <div className="d-flex flex-column align-items-end">
        <MemoTextarea
          ref={textareaRef}
          value={props.memo}
          onChange={(ev) => {
            if (ev.target.value.length > 250) {
              return;
            }
            props.onMemoChange(ev.target.value);
          }}
          rows={3}
          className="mt-1"
          placeholder="Memo is toegevoegd, voeg optioneel nog iets toe"
          autoFocus={!props.geenAutoFocus}
          disabled={props.disabled}
        />
        <span style={{ fontSize: 11, marginRight: 5, top: 3 }} className="text-muted">
          {props.memo.length} / 250
        </span>
      </div>
    </div>
  );
};

export default Memo;
