import React, { useMemo } from 'react';
import classNames from 'classnames';

interface IProps {
  /**
   * Percentage van de progressiebalk.
   * 0.0 is 0% en 1.0 is 100%.
   */
  percentage: number;
  aantalDecimalen?: number;
}

const ProgressieBalk = (props: IProps) => {
  const value = useMemo(() => props.percentage * 100, [props.percentage]);
  const percentageWeergave = useMemo(() => `${value.toFixed(props.aantalDecimalen ?? 0)}%`, [
    value,
    props.aantalDecimalen,
  ]);

  const className = classNames([
    'progress-bar',
    props.percentage <= 1 && 'progress-bar-striped progress-bar-animated',
  ]);

  return (
    <div className="progress" style={{ height: 26 }}>
      <div
        className={className}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{
          width: `${value}%`,
          height: 27,
          fontSize: 14,
          fontWeight: 'bold',
        }}
      >
        {percentageWeergave}
      </div>
    </div>
  );
};

export default ProgressieBalk;
