import React, { CSSProperties, useCallback, useMemo } from 'react';
import { Root } from './style';
import { CheckboxChecked, CheckboxIndeterminate, CheckboxOutline } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {
  aangevinkt: boolean | 'onbestemd';
  onGewijzigd?: (aangevinkt: boolean) => void;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

const VinkVeld: React.FC<IProps> = (props) => {
  const svgStyle = useMemo<CSSProperties>(
    () => ({
      fill: props.disabled ? Kleur.LichtGrijs : Kleur.Blauw,
      width: 23,
      height: 23,
    }),
    [props.disabled],
  );

  const handleClick = useCallback(() => {
    if (props.onGewijzigd === undefined || props.disabled) {
      return;
    }
    props.onGewijzigd(props.aangevinkt === 'onbestemd' || !props.aangevinkt);
  }, [props.aangevinkt, props.onGewijzigd, props.disabled]);

  return (
    <Root
      onClick={handleClick}
      disabled={Boolean(props.disabled)}
      style={props.style}
      className={props.className}
    >
      {props.aangevinkt === 'onbestemd' ? (
        <CheckboxIndeterminate
          style={{
            ...svgStyle,
          }}
        />
      ) : props.aangevinkt ? (
        <CheckboxChecked style={svgStyle} />
      ) : (
        <CheckboxOutline style={svgStyle} />
      )}
      {/*<input type="checkbox" checked={props.aangevinkt} hidden />*/}
    </Root>
  );
};

export default VinkVeld;
