import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { Field, FieldProps } from 'formik';
import { ESoortOpdracht, FormulierContext, IFormikValues, ILeverProduct, veldnamen } from '..';
import ModalBody from 'react-bootstrap/ModalBody';
import FormikVeldFout from '../../../formulier/FormikVeldFout';
import RadioKnop from '../../../formulier/RadioKnop';
import nameof from '../../../../core/nameOf';
import MultiCombobox, { IKolom } from '../../../formulier/MultiCombobox';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import VeldWeergave from '../../../formulier/VeldWeergave';
import { IconKruis } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
// import KoppelVoorraadDialoog, {
//   ETabblad,
// } from '../../../../paginas/Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import LocatieSelectie from '../../../formulier/LocatieSelectie';
import DatumKiezer from '../../../formulier/DatumKiezer';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';
import { format } from 'date-fns';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/transport/opdracht';
import TijdvakVeld from '../../../formulier/TijdvakVeld';
import VinkVeld from '../../../formulier/VinkVeld';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import Combobox from '../../../formulier/Combobox';
import api from '../../../../api';
import HorizontaleScheidingslijn from '../../../layout/HorizontaleScheidingslijn';
import KoppelenProductDialoog, {
  EKoppelenProductDialoogTabblad,
  EKoppelenProductResultType,
  ILocatieResult,
  IVoorraadResult,
} from '../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog';
import { EVoorraadTabblad } from '../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog/VoorraadTabblad';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';

export interface IKoppelenProductStateDialoog {
  cntID: number | null;
  locID: number | null;
  tabblad: EKoppelenProductDialoogTabblad;
  voorraadTabblad?: EVoorraadTabblad;
}

interface IProductWeergaveProps {
  leverProduct: ILeverProduct | null;
  onLeverProductChange: (leverProduct: ILeverProduct | null) => void;
}

const Productweergave = (props: IProductWeergaveProps) => {
  const { leverProduct } = props;

  const [type, setType] = useState<IRemoteData<IOphalenProducttypenResultElement | null>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    if (leverProduct === null || leverProduct.typeID === null) {
      setType(createReadyRemoteData(null));
      return;
    }

    api.v2.product.type
      .ophalenProducttypen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [leverProduct.typeID],
            },
          ],
        },
      })
      .then((result) => {
        setType(createReadyRemoteData(result.producttypen[0]));
      });
  }, [leverProduct?.typeID]);

  const [product, setProduct] = useState<IRemoteData<IOphalenProductenResultElementV2 | null>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    if (leverProduct === null || leverProduct.prodID === null) {
      setProduct(createReadyRemoteData(null));
      return;
    }

    api.v2.product
      .ophalenProductenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [leverProduct.prodID],
            },
          ],
        },
      })
      .then((result) => {
        setProduct(createReadyRemoteData(result.producten[0]));
      });
  }, [leverProduct?.prodID]);

  return (
    <VeldWeergave>
      <div className="d-flex flex-fill">
        {leverProduct !== null ? (
          <>
            {leverProduct.typeID !== null && type.data !== null && (
              <span>
                {type.data!.Merknaam}&nbsp;{type.data!.Typenaam}
              </span>
            )}
            {leverProduct.prodID !== null && product.data !== null && (
              <span>
                {product.data!.producttype.Merknaam}&nbsp;{product.data!.producttype.Typenaam}
                ,&nbsp;{product.data!.Referentiecode}
              </span>
            )}

            <div className="d-flex flex-fill" />
            <button
              style={{
                outline: 0,
                border: 0,
                background: 0,
              }}
              onClick={() => {
                props.onLeverProductChange(null);
              }}
            >
              <IconKruis style={{ fill: Kleur.Grijs, width: 20, height: 20 }} />
            </button>
          </>
        ) : (
          <span>Nog type of product reserveren</span>
        )}
      </div>
    </VeldWeergave>
  );
};

const RegelTab: React.FC = (props) => {
  const {
    soortOpdracht,
    relID,
    cntID,
    values,
    retourproducten,
    leverProduct,
    retourProductID,
    retourstatusID,
    locatieID,
    opdrachten,
    verdiepingen,
    retourstatussen,
    defect,
    setDefect,
    setDienstID,
    setFieldValue,
    setSoortOpdracht,
    setLeverProduct,
    setRetourProductID,
    setRetourstatusID,
    setLocatieID,
  } = useContext(FormulierContext);

  // const [reserverenProductDialoogTonen, setReserverenProductDialoogTonen] = useState(false);
  const [
    koppelenProductStateDialoog,
    setKoppelenProductStateDialoog,
  ] = useState<IKoppelenProductStateDialoog | null>(null);

  const productKolommen = useMemo<IKolom<IOphalenProductenResultElementV2>[]>(() => {
    return [
      {
        key: '__merknaam' as any,
        label: 'Merk',
        breedte: 100,
      },
      {
        key: '__typenaam' as any,
        label: 'Type',
        breedte: 100,
      },
      {
        key: 'Referentiecode',
        label: 'Ref.code',
        breedte: 90,
      },
    ];
  }, []);

  const opdrachtKolommen = useMemo<IKolom<IOphalenOpdrachtenResultElement>[]>(() => {
    return [
      {
        key: 'Opdrachtnummer',
        label: 'Opd.nr.',
        breedte: 90,
      },
      {
        key: 'Bezoekdatum',
        label: 'Bezoekdatum',
        formatFabriek: (rij) => {
          const datum =
            rij.Bezoekdatum !== null ? format(new Date(rij.Bezoekdatum), 'dd-MM-yyyy') : 'Z.s.m.';
          return datum;
        },
        breedte: 125,
      },
      {
        key: '__locatie' as any,
        label: 'Locatie',
        formatFabriek: (rij: IOphalenOpdrachtenResultElement) => {
          return (
            rij.locatie.Straatnaam +
            ' ' +
            rij.locatie.Huisnummer +
            (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '')
          );
        },
        breedte: 200,
      },
      // {
      //   key: 'RecordToegevoegd',
      //   label: 'Geregistreerd',
      //   breedte: 125,
      // },
    ];
  }, []);

  return (
    <ModalBody>
      <>
        <div style={{ minHeight: `500px` }}>
          <div className="row">
            <div className="col-12 pt-3 pb-3">
              <Field
                name="soortOpdracht"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <div className="d-flex" style={{ width: 250 }}>
                        <span className="d-flex align-items-center">
                          <RadioKnop
                            aangevinkt={soortOpdracht === ESoortOpdracht.Levering}
                            onAangevinkt={() => {
                              setSoortOpdracht(ESoortOpdracht.Levering);
                              return form.setFieldValue(field.name, ESoortOpdracht.Levering);
                            }}
                          />
                          Levering
                        </span>
                        <span className="d-flex align-items-center ml-4">
                          <RadioKnop
                            aangevinkt={soortOpdracht === ESoortOpdracht.Retour}
                            onAangevinkt={() => {
                              setSoortOpdracht(ESoortOpdracht.Retour);
                              return form.setFieldValue(field.name, ESoortOpdracht.Retour);
                            }}
                          />
                          Retour
                        </span>
                        <span className="d-flex align-items-center ml-4">
                          <RadioKnop
                            aangevinkt={soortOpdracht === ESoortOpdracht.Omruil}
                            onAangevinkt={() => {
                              setSoortOpdracht(ESoortOpdracht.Omruil);
                              return form.setFieldValue(field.name, ESoortOpdracht.Omruil);
                            }}
                          />
                          Omruil
                        </span>
                      </div>
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <label>{veldnamen.locatieID}</label>
              <Field
                name="locatieID"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <LocatieSelectie
                        locID={locatieID.data}
                        onChange={(locID) => {
                          setLocatieID(createReadyRemoteData(locID));
                          form.setFieldValue(field.name, locID);
                        }}
                        relIDs={[relID]}
                        options={{
                          bijEnkeleDirectVoorselecteren: true,
                        }}
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="row col-12 mt-3">
              <div className="col-6">
                <label>{veldnamen.opdrachtID}</label>

                <Field
                  name={nameof<IFormikValues>('opdrachtID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    // if (betaalwijzen === null) {
                    //   return <LoadingSpinner />;
                    // }
                    return (
                      <>
                        <MultiCombobox<number, IOphalenOpdrachtenResultElement>
                          sleutelExtractor={(x) => x.TrsOpdID}
                          representatieFabriek={(x) => {
                            return (
                              x.Opdrachtnummer +
                              ' - ' +
                              (x.Bezoekdatum !== null
                                ? format(new Date(x.Bezoekdatum), 'dd-MM-yyyy')
                                : 'Zsm')
                            );
                          }}
                          waarde={field.value}
                          onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                          opties={opdrachten === null ? [] : opdrachten}
                          kolommen={opdrachtKolommen!}
                          isWisbaar
                          options={{
                            geenWaardeBericht: 'Nieuwe opdracht',
                          }}
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </>
                    );
                  }}
                />
              </div>
            </div>

            {koppelenProductStateDialoog !== null && (
              <div className="row col-12 mt-4">
                <KoppelenProductDialoog
                  open
                  tabblad={koppelenProductStateDialoog.tabblad}
                  voorraadTabblad={koppelenProductStateDialoog.voorraadTabblad}
                  cntID={koppelenProductStateDialoog.cntID ?? undefined}
                  locID={
                    koppelenProductStateDialoog.locID !== null
                      ? koppelenProductStateDialoog.locID
                      : undefined
                  }
                  onSuccess={async (result) => {
                    switch (result.result.type) {
                      case EKoppelenProductResultType.Locatie: {
                        const locatieResult = result.result as ILocatieResult;
                        setLeverProduct({ typeID: null, prodID: locatieResult.prodID });
                        break;
                      }
                      case EKoppelenProductResultType.Voorraad: {
                        const voorraadResult = result.result as IVoorraadResult;

                        setLeverProduct({
                          typeID: voorraadResult.typeID ?? null,
                          prodID: voorraadResult.prodID ?? null,
                          bulk:
                            (result.result as IVoorraadResult).tabblad === EVoorraadTabblad.Bulk,
                        });
                        break;
                      }
                    }

                    // if (result.tabblad === ETabblad.Ingepakt) {
                    //   setLeverProduct({ typeID: result.typeID, prodID: null });
                    // } else {
                    //   setLeverProduct({ typeID: null, prodID: result.prodID });
                    // }

                    setKoppelenProductStateDialoog(null);
                  }}
                  onAnnuleren={() => setKoppelenProductStateDialoog(null)}
                />
              </div>
            )}

            {(values.soortOpdracht === ESoortOpdracht.Levering ||
              values.soortOpdracht === ESoortOpdracht.Omruil) && (
              <>
                <div className="row col-12 mt-4">
                  <div className="col-10">
                    <label>{veldnamen.leverProduct}</label>
                    <Productweergave
                      leverProduct={leverProduct}
                      onLeverProductChange={(x) => {
                        setLeverProduct(x);
                        setFieldValue(nameof<IFormikValues>('leverProduct'), x);
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <label>&nbsp;</label>
                    <button
                      className="btn btn-light btn-sm d-flex align-items-center justify-content-center"
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      onClick={() => {
                        return setKoppelenProductStateDialoog({
                          cntID,
                          locID: locatieID.data,
                          tabblad: EKoppelenProductDialoogTabblad.Voorraad,
                        });
                      }}
                    >
                      Selecteren
                    </button>
                  </div>
                </div>

                <div className="col-12 mt-3">
                  <span className="d-flex align-items-center">
                    <Field
                      name={nameof<IFormikValues>('aansluitenTrekschakelaar')}
                      render={(fieldProps: FieldProps) => {
                        const { field, form } = fieldProps;
                        return (
                          <VinkVeld
                            aangevinkt={field.value}
                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          />
                        );
                      }}
                    />
                    <span className="ml-2">{veldnamen.aansluitenTrekschakelaar}</span>
                  </span>
                </div>
              </>
            )}

            {(values.soortOpdracht === ESoortOpdracht.Retour ||
              values.soortOpdracht === ESoortOpdracht.Omruil) && (
              <>
                <div className="col-12 mt-4">
                  <label>{veldnamen.retourProductID}</label>

                  <Field
                    name={nameof<IFormikValues>('retourProductID')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;

                      return (
                        <>
                          <MultiCombobox<number, IOphalenProductenResultElementV2>
                            sleutelExtractor={(x) => x.ProdID}
                            representatieFabriek={(x) => {
                              const naam =
                                x.producttype.Merknaam +
                                ' ' +
                                x.producttype.Typenaam +
                                (x.Referentiecode !== null ? `, Ref. ${x.Referentiecode}` : ``);
                              return field.value !== null ? naam : '';
                            }}
                            waarde={retourProductID}
                            onWaardeChange={(x) => {
                              setRetourProductID(x);
                              return form.setFieldValue(field.name, x);
                            }}
                            opties={retourproducten}
                            kolommen={productKolommen!}
                            isWisbaar
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="row col-12 mt-2">
                  {/* <div className="col-5">
                    <label>{veldnamen.retourstatus_ProdStatID}</label>
                    <Field
                      name="retourstatus_ProdStatID"
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <Combobox
                              geselecteerd={retourstatusID}
                              opties={retourstatussen!.map((x: any) => {
                                return {
                                  id: x.ProdStatID,
                                  label: x.Naam,
                                };
                              })}
                              onSelectieChange={(id) => {
                                setRetourstatusID(id);
                                fieldProps.form.setFieldValue(fieldProps.field.name, id);
                              }}
                              legeOptieTonen
                              options={{
                                legeOptieTekst: 'Geen status',
                              }}
                            />
                          </>
                        );
                      }}
                    />
                  </div> */}

                  {/* <span className="col-4 d-flex align-items-center pt-4">
                    <Field
                      name={nameof<IFormikValues>('defect')}
                      render={(fieldProps: FieldProps) => {
                        const { field, form } = fieldProps;
                        return (
                          <VinkVeld
                            aangevinkt={defect!}
                            onGewijzigd={(x) => {
                              setDefect(x);
                              return form.setFieldValue(field.name, x);
                            }}
                          />
                        );
                      }}
                    />
                    <span className="ml-2">{veldnamen.defect}</span>
                  </span> */}
                </div>
              </>
            )}

            {/* <div className="col-12 mt-3">
              <label>{veldnamen.locatieID}</label>
              <Field
                name="locatieID"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <LocatieSelectie
                        locID={locatieID.data}
                        onChange={(locID) => {
                          setLocatieID(createReadyRemoteData(locID));
                          form.setFieldValue(field.name, locID);
                        }}
                        relID={relID}
                        options={{
                          bijEnkeleDirectVoorselecteren: true,
                        }}
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div> */}

            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-5">
                  <label>{veldnamen.verdieping}</label>
                  <Field
                    name={nameof<IFormikValues>('verdieping')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;

                      if (verdiepingen === null) {
                        return <LoadingSpinner />;
                      }

                      return (
                        <>
                          <Combobox
                            legeOptieTonen
                            geselecteerd={field.value}
                            onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            opties={verdiepingen!.map((x) => {
                              return {
                                id: x.Verdieping,
                                label: x.Naam,
                              };
                            })}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {reserverenProductDialoogTonen && (
          <KoppelVoorraadDialoog
            open
            // prodModID={128}
            cntID={cntID !== null ? cntID : undefined}
            onSuccess={(result) => {
              switch (result.tabblad) {
                case ETabblad.Ingepakt:
                  setLeverProduct({ typeID: result.typeID, prodID: null, bulk: false });
                  break;
                case ETabblad.Bulk:
                  setLeverProduct({ typeID: result.typeID, prodID: null, bulk: true });
                  break;
                case ETabblad.Uitgepakt:
                  setReserverenProductDialoogTonen(false);
                  break;
              }

              // if (result.tabblad === ETabblad.Ingepakt) {
              //   setLeverProduct({ typeID: result.typeID, prodID: null });
              // } else {
              //   setLeverProduct({ typeID: null, prodID: result.prodID });
              // }
              // setReserverenProductDialoogTonen(false);
            }}
            onAnnuleren={() => setReserverenProductDialoogTonen(false)}
          />
        )} */}
      </>
    </ModalBody>
  );
};

export default RegelTab;
