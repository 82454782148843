import api from '../../..';
import {
  IOphalenDashboardInfoParams,
  IOphalenDashboardInfoResult,
  IOphalenDebiteurenParams,
  IOphalenDebiteurenResult,
} from '../../../../../../shared/src/api/v2/debiteur/overzicht';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const overzicht = {
  ophalenDebiteuren: async (
    params: IOphalenDebiteurenParams,
  ): Promise<IOphalenDebiteurenResult> => {
    return await api.post('/v2/debiteur/overzicht/ophalen-debiteuren', params);
  },
  ophalenDashboardInfo: async (
    params: IOphalenDashboardInfoParams,
  ): Promise<IOphalenDashboardInfoResult> => {
    return await api.post('/v2/debiteur/overzicht/ophalen-dashboard-info', params);
  },
};

export default overzicht;
