import { Field, FieldProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../../shared/src/api/v2/dienst';
import api from '../../../../../api';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import nameof from '../../../../../core/nameOf';
import { IFormikValues } from '../../Sponsoring/MutatieDialoog';

const SponsoringFormulier: React.FC = (props) => {
  // const [opdrachtWijzen, setOpdrachtWijzen] = useState<
  //   IOphalenOpdrachtwijzenResultElement[] | null
  // >(null);

  // const ophalenOpdrachtwijzen = useCallback(async () => {
  //   const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
  //     filterSchema: { filters: [] },
  //   });

  //   setOpdrachtWijzen(opdrachtwijzenResult.opdrachtwijzen);
  // }, []);

  // useEffect(() => {
  //   ophalenOpdrachtwijzen();
  // }, []);

  return (
    <div className="p-3">
      <div className="row">
        <div className="col-12 mt-3">
          <label>Slug</label>
          <Field
            name={nameof<IFormikValues>('slug')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return <input type="text" className="form-control" {...field} />;
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <label>Landingspagina Tekst</label>
          <Field
            name={nameof<IFormikValues>('LandingsPaginaTekst')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  defaultUitgeklapt
                  inputComponent={'multiline'}
                  multilineConfig={{ rows: 4 }}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SponsoringFormulier;
