import React, { useCallback, useContext, useMemo, useState } from 'react';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
// import { IOphalenMeldingenResultElement } from '../../../../../../shared/src/api/v2/service/melding';
import nameOf from '../../../../core/nameOf';
import { IconInformatie } from '../../../Icons';
import ServicemeldingInfoDialoog from '../../../service/MeldingInfoDialoog';
import _ from 'lodash';
import {
  IOphalenBoekingRegelsResult,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import FormatteerBedrag from '../../../MutatieBedrag';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import MenuLayout from '../../../MenuLayout';
import ASPTabel from '../../../tabel/ASPTabel';
import IRemoteData from '../../../../models/IRemoteData';
import FactuurVisualisatie from '../../../entiteitVisualisaties/FactuurVisualisatie';
import InkoopfactuurVisualisatie from '../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';

interface IInfoDialoogState {
  servicemeldingID: number;
}

interface IProps {
  regels: IOphalenBoekingRegelsResultElement[];
  facturen: IOphalenFacturenBasisResultElement[];
  inkoopfacturen: IOphalenFacturenResultElement[];
}
enum EKolom {
  Bedrag,
  Omschrijving,
}

const BoekingregelsComp: React.FC<IProps> = (props) => {
  const [infoDialoogState, setInfoDialoogState] = useState<IInfoDialoogState | null>(null);

  const kolommen = useMemo<TypedColumn<IOphalenBoekingRegelsResultElement>[]>(
    () => [
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'BtwBedrag',
        title: 'Btw',
      },
      {
        name: '__btwCode' as any,
        title: 'Cd.',
      },
      {
        name: 'Storno',
        title: 'St.',
      },
      {
        name: '__dbRekening' as any,
        title: 'DB',
      },
      {
        name: '__crRekening' as any,
        title: 'CR',
      },
      {
        name: '__omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__factuur' as any,
        title: 'Factuur',
      },
      {
        name: '__werkkostenregeling' as any,
        title: 'WKR',
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenBoekingRegelsResultElement>[]>(
    () => [
      {
        columnName: 'Bedrag',
        width: 90,
      },
      {
        columnName: 'BtwBedrag',
        width: 80,
      },
      {
        columnName: '__btwCode' as any,
        width: 50,
      },
      {
        columnName: 'Storno',
        width: 50,
      },
      {
        columnName: '__dbRekening' as any,
        width: 135,
      },
      {
        columnName: '__crRekening' as any,
        width: 100,
      },
      {
        columnName: '__omschrijving' as any,
        width: 225,
      },
      {
        columnName: '__relatie' as any,
        width: 150,
      },
      {
        columnName: '__factuur' as any,
        width: 100,
      },
      {
        columnName: '__werkkostenregeling' as any,
        width: 100,
      },
    ],
    [],
  );

  // const keyExtractor = useCallback((row: IOphalenBoekingRegelsResultElement) => row.ID, []);
  // const kolommen = useMemo<ASPKolom<EKolom, IOphalenBoekingRegelsResultElement>[]>(
  //   () => [
  //     {
  //       key: EKolom.Bedrag,
  //       label: 'Bedrag',
  //       breedteType: EAspKolomBreedteType.Vast,
  //       vasteBreedte: 90,
  //       renderer: (row: IOphalenBoekingRegelsResultElement) => {
  //         return <FormatteerBedrag bedrag={row.Bedrag} />;
  //       },
  //     },
  //     {
  //       key: EKolom.Omschrijving,
  //       label: 'Omschrijving',
  //       breedteType: EAspKolomBreedteType.Vast,
  //       vasteBreedte: 200,
  //       renderer: (row: IOphalenBoekingRegelsResultElement) => {
  //         return row.Omschrijving;
  //       },
  //     },
  //   ],
  //   [],
  // );

  // const rijen = useMemo(() => {
  //   return props.regels.reduce((acc, curr, idx) => {
  //     return {
  //       ...acc,
  //       [idx]: curr,
  //     };
  //   }, {});
  // }, [props.regels]);

  // return (
  //   <>
  //     <ASPTabel
  //       rijen={rijen}
  //       kolommen={kolommen}
  //       keyExtractor={keyExtractor}
  //       totaalAantalRijen={
  //         10 //data.state === ERemoteDataState.Pending ? 10 : Object.keys(rijen).length
  //       }
  //     />
  //   </>
  // );

  return (
    <>
      <div className="mt-2 pl-3 pr-3 mb-4">
        <VeldWeergave>
          <div className="p-1 font-weight-bold">Boekingsregels</div>
          <GridStyleWrapper maxHeight={400} rowAmount={props.regels.length}>
            <Grid rows={props.regels} columns={kolommen}>
              <DataTypeProvider
                for={[
                  nameOf<IOphalenBoekingRegelsResultElement>('Bedrag'),
                  nameOf<IOphalenBoekingRegelsResultElement>('BtwBedrag'),
                ]}
                formatterComponent={(formatterProps) => (
                  <FormatteerBedrag bedrag={formatterProps.value} />
                )}
              />

              <DataTypeProvider
                for={['__btwCode']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  return <span>{rij.btwsoort !== null ? rij.btwsoort.Code : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenBoekingRegelsResultElement>('Storno')]}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  return <span>{rij.Storno ? 'Ja' : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={['__werkkostenregeling']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  return (
                    <span>
                      {rij.werkkostenregeling !== null ? rij.werkkostenregeling.Naam : ''}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__dbRekening']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  return (
                    <UitlegTooltip inhoud={<span>{rij.dbRekening.Naam}</span>}>
                      <span>
                        {rij.dbRekening.Nummer} - {rij.dbRekening.Naam}
                      </span>
                    </UitlegTooltip>
                  );
                }}
              />

              <DataTypeProvider
                for={['__crRekening']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  if (props.regels.length === 1) {
                    return (
                      <UitlegTooltip inhoud={<span>{rij.crRekening.Naam}</span>}>
                        <span>
                          {rij.crRekening.Nummer} - {rij.crRekening.Naam}
                        </span>
                      </UitlegTooltip>
                    );
                  }
                  return (
                    <UitlegTooltip inhoud={<span>{rij.crRekening.Naam}</span>}>
                      <span>{rij.crRekening.Nummer}</span>
                    </UitlegTooltip>
                  );
                }}
              />

              {/* <DataTypeProvider
                for={['__crRekening']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  return <span>{rij.crRekening.Nummer}</span>;
                }}
              /> */}

              <DataTypeProvider
                for={['__factuur']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;

                  if (rij.FactID !== null) {
                    return <FactuurVisualisatie factID={rij.FactID} />;
                  }
                  if (rij.InkFactID !== null) {
                    return <InkoopfactuurVisualisatie inkFactID={rij.InkFactID} />;
                  }
                  return <span></span>;
                }}
              />

              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  if (rij.RelID === null) {
                    return <span></span>;
                  }

                  return (
                    <>
                      <RelatieVisualisatie
                        relID={rij.RelID}
                        // relatieLinkBuilder={(hoedanigheid, relID) =>
                        //   `/${
                        //     hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                        //   }/${relID}/facturen/overzicht`
                        // }
                      />
                    </>
                  );
                }}
              />

              {/* <DataTypeProvider
                for={['__omschrijving']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  const omschrijving = rij.Omschrijving ?? '';
                  return <span>{omschrijving}</span>;
                }}
              /> */}

              <DataTypeProvider
                for={['__omschrijving']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;

                  if (rij.Omschrijving !== null) {
                    return <span>{rij.Omschrijving}</span>;
                  }

                  let omschrijving = '';

                  if (rij.InkFactID !== null) {
                    const inkoopfactuur = props.inkoopfacturen.find(
                      (x) => x.InkFactID === rij.InkFactID,
                    );

                    omschrijving =
                      inkoopfactuur !== undefined && inkoopfactuur.Onderwerp !== null
                        ? inkoopfactuur.Onderwerp
                        : '';
                  } else {
                    if (rij.FactID !== null) {
                      const factuur = props.facturen.find((x) => x.FactID === rij.FactID)!;
                      omschrijving =
                        factuur !== undefined && factuur.Onderwerp !== null
                          ? factuur.Onderwerp
                          : '';
                    }
                  }

                  return (
                    <span
                      style={{
                        // fontStyle:
                        //   rij.Omschrijving === null && omschrijving !== null
                        //     ? 'italic'
                        //     : '',
                        color:
                          rij.Omschrijving === null && omschrijving !== null
                            ? EKleur.Grijs
                            : undefined,
                      }}
                    >
                      {omschrijving}
                    </span>
                  );

                  // if (rij.WkrRglID !== null) {
                  //   const werkkostenregeling = werkkostenregelingenResult?.regelingen.find(
                  //     (x) => x.ID === rij.WkrRglID,
                  //   );
                  //   if (werkkostenregeling === undefined) {
                  //     return <span />;
                  //   }
                  //   omschrijving += ` [WKR ${werkkostenregeling.Naam}]`;
                  // }
                }}
              />

              {/* <DataTypeProvider
                for={['__info']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenBoekingRegelsResultElement = formatterProps.row;
                  return (
                    <div>
                      <a
                        href="#"
                        className="ml-1"
                        style={{ color: Kleur.LichtBlauw }}
                        onClick={() => {
                          setInfoDialoogState({ servicemeldingID: row.ID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                      </a>
                    </div>
                  );
                }}
              /> */}

              <Table
                // columnExtensions={kolombreedtesMelding}
                messages={{ noData: 'Geen boekingregels bekend' }}
              />
              <TableColumnResizing defaultColumnWidths={kolombreedtes} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </VeldWeergave>
      </div>

      {infoDialoogState !== null && (
        <ServicemeldingInfoDialoog
          serviceMeldingID={infoDialoogState.servicemeldingID}
          open
          onSuccess={() => setInfoDialoogState(null)}
          onAnnuleren={() => setInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default BoekingregelsComp;
