import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IOphalenTakenResult } from '../../../../../shared/src/api/v2/taken';
import useUrlState from '../../../core/useUrlState';
import { RootStoreContext } from '../../../stores/RootStore';
import api from '../../../api';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../bedrijfslogica/constanten';
import EventsWeergave from './EventsWeergave';
import VerticaleScheidingslijn from '../../../components/layout/VerticaleScheidingslijn';
import HorizontaleScheidingslijn from '../../../components/layout/HorizontaleScheidingslijn';
import AlleEventsWeergave from './AlleEventsWeergave';

interface IUrlState {
  actieveTaakID: number | null;
}
const defaultUrlState: IUrlState = {
  actieveTaakID: null,
};

interface IProps extends RouteComponentProps {}

const breedteKolom = 325;

const TaakEvents: React.FC<IProps> = (props) => {
  const [takenResult, setTakenResult] = useState<IOphalenTakenResult | null>(null);

  const [urlState, _, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const ophalenTaken = useCallback(async () => {
    const result = await api.v2.taken.ophalenTaken({});
    setTakenResult(result);
  }, []);

  useEffect(() => {
    ophalenTaken();

    const intervalId = setInterval(() => ophalenTaken(), 30000);
    return () => clearInterval(intervalId);
  }, [ophalenTaken]);

  if (takenResult === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="d-flex flex-fill">
      <div className="d-flex flex-column">
        <div
          className="d-flex flex-column"
          style={{ backgroundColor: Kleur.Wit, minWidth: breedteKolom, maxWidth: breedteKolom }}
        >
          <div
            className="p-3 pl-4 pr-4"
            style={{
              backgroundColor: urlState.actieveTaakID === null ? Kleur.HeelLichtBlauw : undefined,

              cursor: 'pointer',
            }}
            onClick={() => setUrlStateSync('actieveTaakID', null)}
          >
            <span className="font-weight-bold">Alles</span>
          </div>
        </div>
        <HorizontaleScheidingslijn width={breedteKolom} />
        <div
          style={{
            backgroundColor: Kleur.Wit,
            minWidth: breedteKolom,
            maxWidth: breedteKolom,
            overflowY: 'auto',
            height: 0,
            flex: '1 1 auto',
          }}
        >
          {takenResult.taken.map((taak) => {
            const isActief = taak.ID === urlState.actieveTaakID;
            return (
              <div
                key={taak.ID}
                className="p-3 pl-4 pr-4"
                style={{
                  backgroundColor: isActief ? Kleur.HeelLichtBlauw : undefined,

                  cursor: 'pointer',
                }}
                onClick={() => setUrlStateSync('actieveTaakID', taak.ID)}
              >
                <span>{taak.Naam}</span>
              </div>
            );
          })}
        </div>
      </div>
      <VerticaleScheidingslijn />
      <div className="d-flex flex-fill flex-column">
        {urlState.actieveTaakID === null ? (
          <AlleEventsWeergave takenResult={takenResult} />
        ) : (
          <EventsWeergave taakID={urlState.actieveTaakID} />
        )}
      </div>
    </div>
  );
};

export default TaakEvents;
