import React, { useCallback, useMemo } from 'react';
import {
  IRepresentatieProps,
  Provider,
} from '../../../../../../../components/formulier/MultiComboboxV2';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../../../components/formulier/MultiComboboxV2/MetZoeken';
import api from '../../../../../../../api';
import { IOphalenIndexeringenResultElement } from '../../../../../../../../../shared/src/api/v2/indexering';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';

type Kolom = 'indexeringsdatum' | 'percentage' | 'verwerkt_op';

interface IProps {
  indexeringID: number | null;
  onIndexeringIDChange: (id: number | null) => void;
}

const IndexeringSelectie = (props: IProps) => {
  const provider = useMemo<
    Provider<Kolom, IOphalenIndexeringenResultElement, IMultiComboboxV2MetZoekenOverlayState>
  >(
    () => async (params) => {
      // const filters: IFilterSchemaFilter[] = [
      //   {
      //     naam: 'ONTVANGERSSOORT_IDS',
      //     data: [props.ontvangerssoortID],
      //   },
      // ];

      // const zoekterm = params.overlayContainerState.zoekterm.trim();
      // if (zoekterm.length > 0) {
      //   filters.push({
      //     naam: 'NAAM_UITGEBREID',
      //     data: {
      //       precisie: 'ONGEVEER',
      //       waarde: zoekterm,
      //     } as ITekstPrecisieData,
      //   });
      // }

      const result = await api.v2.indexering.ophalenIndexeringen({
        paginatie: params.paginatie,
        // filterSchema: {
        //   filters,
        // },
      });

      const items = result.indexeringen.reduce((acc, item, i) => {
        acc[params.paginatie.index + i] = item;
        return acc;
      }, params.huidigeBron);

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [],
  );
  const enkeleProvider = useMemo(
    () => async (indexeringID: number) => {
      const result = await api.v2.indexering.ophalenIndexeringen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [indexeringID],
            },
          ],
        },
      });
      return result.indexeringen[0];
    },
    [],
  );
  const keyExtractor = useCallback((item: IOphalenIndexeringenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IOphalenIndexeringenResultElement>[]>(
    () => [
      {
        key: 'indexeringsdatum',
        label: 'Indexeringsdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => format(new Date(item.Indexeringsdatum), 'dd-MM-yyyy'),
      },
      {
        key: 'percentage',
        label: 'Percentage',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item) => `${item.Percentage.toString().replace('.', ',')}%`,
      },
      {
        key: 'verwerkt_op',
        label: 'Verwerkt op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) =>
          item.VerwerktOp ? format(new Date(item.VerwerktOp), 'dd-MM-yyyy') : '- -',
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2MetZoeken
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.indexeringID}
      onChange={props.onIndexeringIDChange}
      representatieComponent={Representatie}
      kolommen={kolommen}
    />
  );
};

const Representatie = (props: IRepresentatieProps<IOphalenIndexeringenResultElement>) => {
  return (
    <span>
      {props.entiteit.Percentage.toString().replace('.', ',')}% op{' '}
      {format(new Date(props.entiteit.Indexeringsdatum), 'dd-MM-yyyy')}
    </span>
  );
};

export default IndexeringSelectie;
