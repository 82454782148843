import React, { useCallback, useContext, useMemo, useState } from 'react';
import NavigatieOverlay from '../NavigatieOverlay';
import HorizontaleScheidingslijn from '../../../components/layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  IconDatumreeks,
  IconToevoegen,
  IconVerwijderen,
  IconWasmachine,
  IconZoeken,
} from '../../../components/Icons';
import styled from 'styled-components';
import GlobaleRenderer, {
  GlobaleRendererContext,
} from '../../../one-off-components/GlobaleRenderer';
import BezoekdagenInfoDialoog from '../../../components/transport/BezoekdagenInfoDialoog';
import HandmatigControltaakToevoegenDialoog from '../../../components/control/HandmatigControltaakToevoegenDialoog';
import ProductSelectieDialoog from '../../../components/product/ProductSelectieDialoog';
import ProductinfoDialoog from '../../../components/product/ProductinfoDialoog';
import GrootboekrekeningSaldiInfoDialoog from '../../Boekhouding/Boekingen/GrootboekrekeningSaldiInfoDialoog';
import api from '../../../api';
import toast from 'react-hot-toast';

const Item = styled.div`
  cursor: pointer;
  height: 35px;
  display: flex;
  align-items: center;

  &:hover {
    //background-color: ${Kleur.SelectieGrijs};
    background-color: rgba(1, 1, 1, 0.1);
  }
`;

interface IProps {}

const FunctiesOverlay: React.FC<IProps> = (props) => {
  const globaleRenderer = useContext(GlobaleRendererContext);

  const handleCacheLegenClick = async () => {
    try {
      await api.v2.extern.leegCache({});
      toast.success('Cache van website geleegd');
    } catch (err) {
      toast.error('Cache van website legen mislukt (zie logs voor meer info)');
    }
  };

  const functies = useMemo(
    () => [
      {
        icon: (
          <IconZoeken
            style={{
              width: 15,
              height: 15,
              fill: Kleur.HeelLichtGrijs,
            }}
          />
        ),
        naam: 'Productinformatie',
        onClick: async () => {
          await globaleRenderer.render((productinfoRenderProps) => (
            <ProductinfoDialoog
              open
              id={null}
              metSelectie
              onSuccess={() => productinfoRenderProps.destroy()}
              onAnnuleren={() => productinfoRenderProps.destroy()}
            />
          ));
        },
      },
      {
        icon: (
          <IconToevoegen
            style={{
              width: 15,
              height: 15,
              fill: Kleur.HeelLichtGrijs,
            }}
          />
        ),
        naam: 'Taak toevoegen',
        onClick: () => {
          globaleRenderer.render((renderProps) => {
            return (
              <HandmatigControltaakToevoegenDialoog
                open
                onSuccess={() => renderProps.destroy()}
                onAnnuleren={() => renderProps.destroy()}
              />
            );
          });
        },
      },
      {
        icon: (
          <IconDatumreeks
            style={{
              width: 15,
              height: 15,
              fill: Kleur.HeelLichtGrijs,
            }}
          />
        ),
        naam: 'Bezoekdagen Info',
        onClick: () => {
          globaleRenderer.render((renderProps) => {
            return (
              <BezoekdagenInfoDialoog
                open
                onSuccess={() => renderProps.destroy()}
                onAnnuleren={() => renderProps.destroy()}
              />
            );
          });
        },
      },
      {
        icon: (
          <IconDatumreeks
            style={{
              width: 15,
              height: 15,
              fill: Kleur.HeelLichtGrijs,
            }}
          />
        ),
        naam: 'Grootboekrekening saldi',
        onClick: () => {
          globaleRenderer.render((renderProps) => {
            return (
              <GrootboekrekeningSaldiInfoDialoog
                open
                id={1} // TODO
                onSuccess={() => renderProps.destroy()}
                onAnnuleren={() => renderProps.destroy()}
              />
            );
          });
        },
      },
      {
        icon: (
          <IconVerwijderen
            style={{
              width: 15,
              height: 15,
              fill: Kleur.HeelLichtGrijs,
            }}
          />
        ),
        naam: 'Website cache legen',
        onClick: () => handleCacheLegenClick(),
      },
    ],
    [globaleRenderer.render],
  );

  return (
    <>
      <NavigatieOverlay>
        <div style={{ fontSize: 12, minWidth: 250 }}>
          {functies.map((functie, i) => (
            <React.Fragment key={i}>
              <Item
                className="pl-3 pr-3"
                onClick={(ev) => {
                  ev.preventDefault();
                  // Sluit het overlay
                  document.body.click();
                  functie.onClick();
                }}
              >
                {functie.icon}
                <span className="ml-2">{functie.naam}</span>
              </Item>
              {i !== functies.length - 1 && <HorizontaleScheidingslijn color={Kleur.Navigatie} />}
            </React.Fragment>
          ))}
        </div>
      </NavigatieOverlay>
    </>
  );
};

export default FunctiesOverlay;
