import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  TableEditColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenProductmerkenResultElement } from '../../../../../../shared/src/api/v2/product/merk';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen } from '../../../../components/Icons';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import BooleanWeergave from '../../../../components/tabel/BooleanWeergave';
import WijzigDialoog from './WijzigDialoog';
import { format } from 'date-fns';
import nameOf from '../../../../core/nameOf';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/service';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ToevoegenDialoog from './ToevoegenDialoog';
import { Helmet } from 'react-helmet';

enum EFilter {
  Actief = 'IS_ACTIEF',
}

interface IProps extends RouteComponentProps {}

export interface IToevoegenDialoogState {}

interface IUrlState {
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
}

export interface IRow extends IOphalenProductmerkenResultElement {}

const Productmerken: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      toevoegenDialoogState: null,
      filterdata: [
        {
          naam: EFilter.Actief,
          data: true,
          isActief: true,
        },
      ],
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const [nieuwDialoogOpen, setNieuwDialoogOpen] = useState<boolean>(false);
  const [productmerken, setProductmerken] = useState<IRow[] | null>(null);
  const [selectie, setSelectie] = useState<number[]>([]);
  const [productMerkID, setProductMerkID] = useState<number | null>(null);
  const [servicediensten, setServicediensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  const ophalenProductmerken = useCallback(async () => {
    const result = await api.v2.product.merk.ophalenProductmerken({
      filterSchema,
      orderSchema: { orders: [{ naam: 'MERKNAAM', richting: 'ASC' }] },
    });

    setProductmerken(result.productmerken);
  }, [filterSchema]);

  useEffect(() => {
    ophalenProductmerken();
  }, [ophalenProductmerken]);

  useEffect(() => {
    (async () => {
      const diensten = (
        await api.v2.dienst.service.ophalenDiensten({
          filterSchema: {
            filters: [
              {
                naam: 'IS_ACTIEF',
                data: true,
              },
            ],
          },
        })
      ).diensten;

      setServicediensten(diensten);
    })();
  }, []);

  const handleVerwijderen = useCallback(async (ids: number[]) => {
    const checkData = await api.v2.product.merk.checkVerwijderen({
      merkIDs: ids,
    });
    const controleResult = await checkStore.controleren({ checkData });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: `Merk verwijderen?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.product.merk.verwijderen({
      merkIDs: ids,
    });

    ophalenProductmerken();
  }, []);

  const keyExtractor = useCallback((row: IRow) => row.MerkID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Merknaam',
        title: 'Merknaam',
        // getCellValue: (x) => (x.Merkloos ? '0' + x.Merknaam : '1' + x.Merknaam),
      },
      // {
      //   name: 'Merkloos',
      //   title: 'Merkloos',
      // },
      {
        name: 'GarantiePeriode',
        title: 'Gar.per.',
      },
      {
        name: '__relatieGarantieService' as any,
        title: 'Garantie-dienst',
        getCellValue: (x) => {
          if (servicediensten === null || x.ServDienstID_Garantie === null) {
            return null;
          }
          const dienst = servicediensten.find((d) => d.ID === x.ServDienstID_Garantie)!;
          return dienst.NaamIdent;
        },
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
      {
        name: 'RecordGewijzigd',
        title: 'Gewijzigd',
      },
    ],
    [servicediensten],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Merknaam',
        width: 150,
      },
      {
        columnName: 'Actief',
        width: 90,
      },
      {
        columnName: 'Merkloos',
        width: 100,
      },
      {
        columnName: 'GarantiePeriode',
        width: 90,
      },
      {
        columnName: '__relatieGarantieService' as any,
        width: 225,
      },
      {
        columnName: 'RecordGewijzigd',
        width: 135,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen actieve merken</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Productmerken</title>
      </Helmet>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={() => {
              setUrlStateSync('toevoegenDialoogState', {});
            }}
          >
            <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Nieuw merk</span>
          </button>
          <div className="ml-2 flex-fill">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterdata}
              onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
              onFilterSchemaChange={(x) => setFilterSchema(x)}
            />
          </div>
        </div>
      </div>
      {productmerken === null || servicediensten === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid rows={productmerken} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={['Actief']}
                formatterComponent={(formatterProps) => {
                  return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <DataTypeProvider
                for={['__relatieGarantieService']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRow;
                  if (rij.ServDienstID_Garantie === null) {
                    return <span />;
                  }

                  const dienst = servicediensten.find((x) => x.ID === rij.ServDienstID_Garantie)!;

                  return <RelatieVisualisatie relID={dienst.RelID} />;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IRow>('Merkloos')]}
                formatterComponent={(formatterProps) => (
                  <BooleanWeergave waarde={formatterProps.value} />
                )}
              />

              <DataTypeProvider
                for={[nameOf<IRow>('Merknaam')]}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRow;
                  if (rij.Merkloos) {
                    return <span>[{formatterProps.value}]</span>;
                  }
                  return <span>{formatterProps.value}</span>;
                }}
              />

              <DataTypeProvider
                for={['GarantiePeriode']}
                formatterComponent={(formatterProps) => <>{formatterProps.value}</>}
              />

              <DataTypeProvider
                for={['RecordGewijzigd']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;
                  return (
                    <span>
                      {row.RecordGewijzigd !== null
                        ? format(new Date(row.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                        : null}
                    </span>
                  );
                }}
              />

              <EditingState
                onCommitChanges={async (changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1] as number;
                  await handleVerwijderen([id]);
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  setProductMerkID(id);
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <TableHeaderRow showSortingControls />

              <TableEditColumn
                width={65}
                showEditCommand
                showDeleteCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />
            </Grid>
          </GridStyleWrapper>
        </>
      )}
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          onSuccess={() => {
            setUrlStateSync('toevoegenDialoogState', null);
            ophalenProductmerken();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
          open={true}
        />
      )}
      {productMerkID !== null && (
        <WijzigDialoog
          onSuccess={() => {
            setProductMerkID(null);
            ophalenProductmerken();
          }}
          merkID={productMerkID}
          onAnnuleren={() => setProductMerkID(null)}
          open={true}
        />
      )}
    </>
  );
};

export default withRouter(Productmerken);
