import React, { useContext, useMemo } from 'react';
import { IContext, IPendelregels, ITabbladProps, ProductContext } from '../index';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import TransportopdrachtregelsComp from '../../../transport/TransportopdrachtregelsComp';
import { IOphalenPendelregelsResultElement } from '../../../../../../shared/src/api/v2/pendel';
import VeldWeergave from '../../../formulier/VeldWeergave';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { format } from 'date-fns';

interface IProps extends ITabbladProps {}

const TransportTab: React.FC<IProps> = (props) => {
  const { transportopdrachtregels, pendelregels } = useContext<IContext>(ProductContext);

  const kolommen = useMemo<TypedColumn<IOphalenPendelregelsResultElement>[]>(
    () => [
      // {
      //   name: 'Opdrachtnummer',
      //   title: 'Opd.nr.',
      // },
      {
        name: '__datumVerstuurd' as any,
        title: 'Verstuurd',
      },
      {
        name: '__uitvoerdatum' as any,
        title: 'Uitgevoerd',
      },
      {
        name: '__magazijnLaden' as any,
        title: 'Laden',
      },
      {
        name: '__magazijnLossen' as any,
        title: 'Lossen',
      },
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr.',
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IPendelregels>[]>(
    () => [
      // {
      //   columnName: 'Opdrachtnummer' as any,
      //   width: 90,
      // },
      {
        columnName: '__datumVerstuurd' as any,
        width: 125,
      },
      {
        columnName: '__uitvoerdatum' as any,
        width: 125,
      },
      {
        columnName: '__magazijnLaden' as any,
        width: 100,
      },
      {
        columnName: '__magazijnLossen' as any,
        width: 100,
      },
      {
        columnName: '__opdrachtnummer' as any,
        width: 100,
      },
    ],
    [],
  );

  if (transportopdrachtregels === null || pendelregels === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column">
      <div className="mt-2  pt-2 mb-4">
        <TransportopdrachtregelsComp regels={transportopdrachtregels} />

        <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
          <VeldWeergave>
            <div className="p-1 font-weight-bold">Pendelopdrachten</div>
            <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
              <GridStyleWrapper maxHeight={400} rowAmount={pendelregels!.length}>
                <Grid rows={pendelregels!} columns={kolommen}>
                  <DataTypeProvider
                    for={['__datumVerstuurd']}
                    formatterComponent={(formatterProps) => {
                      const rij: IPendelregels = formatterProps.row;
                      if (rij.opdracht.DatumVerstuurd === null) {
                        return <span>Nog versturen</span>;
                      }
                      return (
                        <span>{format(new Date(rij.opdracht.DatumVerstuurd), 'dd-MM-yyyy')}</span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__uitvoerdatum']}
                    formatterComponent={(formatterProps) => {
                      const rij: IPendelregels = formatterProps.row;
                      if (rij.opdracht.DatumUitgevoerd === null) {
                        return <span>Nog uitvoeren</span>;
                      }
                      return (
                        <span>{format(new Date(rij.opdracht.DatumUitgevoerd), 'dd-MM-yyyy')}</span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__magazijnLaden']}
                    formatterComponent={(formatterProps) => {
                      const rij: IPendelregels = formatterProps.row;
                      return <span>{rij.opdracht.magazijn.laden.NaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__magazijnLossen']}
                    formatterComponent={(formatterProps) => {
                      const rij: IPendelregels = formatterProps.row;
                      return <span>{rij.opdracht.magazijn.lossen.NaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__opdrachtnummer']}
                    formatterComponent={(formatterProps) => {
                      const rij: IPendelregels = formatterProps.row;
                      return <span>{rij.opdracht.Opdrachtnummer}</span>;
                    }}
                  />

                  <VirtualTable
                    columnExtensions={kolombreedtes}
                    messages={{ noData: 'Geen pendelopdrachten bekend' }}
                  />
                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            </div>
          </VeldWeergave>
        </div>
      </div>
    </div>
  );
};

export default TransportTab;
