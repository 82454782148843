import api from '../..';
import {
  IGenereerContractPDFParams,
  IGenereerContractPDFResult,
  IOphalenBezoekdagenParams,
  IOphalenBezoekdagenResult,
  IOphalenContractLocatiesParams,
  IOphalenContractLocatiesResult,
  IWijzigenLocatieVanContractenParams,
  IWijzigenLocatieVanContractenResult,
  IBepalenTeReserverenProductParams,
  IBepalenTeReserverenProductResult,
  IOphalenContractenParamsV2,
  IOphalenContractenResultV2,
  IWijzigenInterneLocatieVanContractenParams,
  IWijzigenInterneLocatieVanContractenResult,
  IWijzigenModelEnAbonnementVanContractenParams,
  IWijzigenModelEnAbonnementVanContractenResult,
  IKopierenContractenParams,
  IKopierenContractenResult,
  IOphalenContractenParamsV3,
  IOphalenContractenResultV3,
  IToepassenActieOpContractenParams,
  IToepassenActieOpContractenResult,
  IVerwijderenActieOpContractenParams,
  IVerwijderenActieOpContractenResult,
  IOphalenContractenParamsV4,
  IOphalenContractenResultV4,
  IOphalenMaandhuurContractenParams,
  IOphalenMaandhuurContractenResult,
  IWijzigenFactuurkenmerkVanContractenParams,
  IWijzigenFactuurkenmerkVanContractenResult,
} from '../../../../../shared/src/api/v2/contract';
import {
  IVerwijderenAanbiedenContractenParams,
  IVerwijderenAanbiedenContractenResult,
} from '../../../../../shared/src/api/v2/contract/overdracht';
import { ICheckSelectieAanbiedenContracten } from '../../../../../shared/src/api/v2/contract/aanbieding';

import aanbieding from './aanbieding';
import wissel from './wissel';
import beeindiging from './beeindiging';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import {
  IToevoegenContractParams,
  IToevoegenContractResult,
  IVerwijderenContractenParams,
  IVersturenBevestigingNieuwParams,
  IVersturenBevestigingNieuwResult,
} from '../../../../../shared/src/api/v2/contract/nieuw/nieuw';
import {
  IWijzigenContractParams,
  IWijzigenContractResult,
  IOphalenContractenParams,
  OphalenContractenResult,
  IOphalenAbonnementenVoorModelParams,
  IOphalenAbonnementenVoorModelResult,
  IOphalenContractstatussenResult,
  IOphalenContractstatussenParams,
} from '../../../../../shared/src/api/v2/contract';
import { IOphalenRedenenOverdrachtResult } from '../../../../../shared/src/api/v2/contract';
import annulering from './annulering';
import nieuw from './nieuw';
import overname from './overname';
import afkeuring from './afkeuring';
import product from './product';
import tarief from './tarief';

const contract = {
  afkeuring,
  aanbieding,
  wissel,
  beeindiging,
  annulering,
  nieuw,
  overname,
  product,
  tarief,
  checkWijzigenModelEnAbonnementVanContracten: async (
    params: IWijzigenModelEnAbonnementVanContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/check-wijzigen-model-en-abonnement-van-contracten', params);
  },
  wijzigenModelEnAbonnementVanContracten: async (
    params: IWijzigenModelEnAbonnementVanContractenParams,
  ): Promise<IWijzigenModelEnAbonnementVanContractenResult> => {
    return await api.post('/v2/contract/wijzigen-model-en-abonnement-van-contracten', params);
  },
  checkWijzigenContract: async (params: IWijzigenContractParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/check-wijzigen-contract', params);
  },
  wijzigenContract: async (params: IWijzigenContractParams): Promise<IWijzigenContractResult> => {
    return await api.post('/v2/contract/wijzigen-contract', params);
  },
  ophalenAbonnementenVoorModel: async (
    params: IOphalenAbonnementenVoorModelParams,
  ): Promise<IOphalenAbonnementenVoorModelResult> => {
    return await api.post('/v2/contract/ophalen-abonnementen-voor-model', params);
  },
  ophalenContracten: async (params: IOphalenContractenParams): Promise<OphalenContractenResult> => {
    return await api.post('/v2/contract/ophalen-contracten', params);
  },
  ophalenContractenV2: async (
    params: IOphalenContractenParamsV2,
  ): Promise<IOphalenContractenResultV2> => {
    return await api.post('/v2/contract/ophalen-contracten-V2', params);
  },
  ophalenContractenV3: async (
    params: IOphalenContractenParamsV3,
  ): Promise<IOphalenContractenResultV3> => {
    return await api.post('/v2/contract/ophalen-contracten-V3', params);
  },
  ophalenContractenV4: async (
    params: IOphalenContractenParamsV4,
  ): Promise<IOphalenContractenResultV4> => {
    return await api.post('/v2/contract/ophalen-contracten-V4', params);
  },
  ophalenBezoekdagen: async (
    params: IOphalenBezoekdagenParams,
  ): Promise<IOphalenBezoekdagenResult> => {
    return await api.post('/v2/contract/beeindiging/ophalen-bezoekdagen', params);
  },
  ophalenRedenenOverdracht: async (params: {}): Promise<IOphalenRedenenOverdrachtResult> => {
    return await api.post('/v2/contract/ophalen-redenen-overdracht', params);
  },
  checkSelectieAanbiedenContracten: async (
    params: ICheckSelectieAanbiedenContracten,
  ): Promise<any> => {
    return await api.post('/v2/contract/aanbieding/check-selectie-aanbieden-contracten', params);
  },
  verwijderenAanbiedenContracten: async (
    params: IVerwijderenAanbiedenContractenParams,
  ): Promise<IVerwijderenAanbiedenContractenResult> => {
    return await api.post('/v2/contract/overdracht/verwijderen-aanbieden-contracten', params);
  },
  toevoegenContract: async (
    params: IToevoegenContractParams,
  ): Promise<IToevoegenContractResult> => {
    return await api.post('/v2/contract/nieuw/toevoegen-contract', params);
  },
  checkToevoegenContract: async (params: IToevoegenContractParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/nieuw/check-toevoegen-contract', params);
  },
  versturenBevestigingNieuw: async (
    params: IVersturenBevestigingNieuwParams,
  ): Promise<IVersturenBevestigingNieuwResult> => {
    return await api.post('/v2/contract/nieuw/versturen-bevestiging-nieuw', params);
  },
  ophalenContractstatussen: async (
    params: IOphalenContractstatussenParams,
  ): Promise<IOphalenContractstatussenResult> => {
    return await api.post('/v2/contract/ophalen-contractstatussen', params);
  },
  ophalenContractLocaties: async (
    params: IOphalenContractLocatiesParams,
  ): Promise<IOphalenContractLocatiesResult> => {
    return await api.post('/v2/contract/ophalen-contract-locaties', params);
  },
  wijzigenLocatieVanContracten: async (
    params: IWijzigenLocatieVanContractenParams,
  ): Promise<IWijzigenLocatieVanContractenResult> => {
    return await api.post('/v2/contract/wijzigen-locatie-van-contracten', params);
  },
  wijzigenInterneLocatieVanContracten: async (
    params: IWijzigenInterneLocatieVanContractenParams,
  ): Promise<IWijzigenInterneLocatieVanContractenResult> => {
    return await api.post('/v2/contract/wijzigen-interne-locatie-van-contracten', params);
  },
  wijzigenFactuurkenmerkVanContracten: async (
    params: IWijzigenFactuurkenmerkVanContractenParams,
  ): Promise<IWijzigenFactuurkenmerkVanContractenResult> => {
    return await api.post('/v2/contract/wijzigen-factuurkenmerk-van-contracten', params);
  },
  genereerContractPDF: async (
    params: IGenereerContractPDFParams,
  ): Promise<IGenereerContractPDFResult> => {
    return await api.post('/v2/contract/genereer-contract-pdf', params);
  },
  bepalenTeReserverenProduct: async (
    params: IBepalenTeReserverenProductParams,
  ): Promise<IBepalenTeReserverenProductResult> => {
    return await api.post('/v2/contract/bepalen-te-reserveren-product', params);
  },
  checkKopierenContracten: async (params: IKopierenContractenParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/check-kopieren-contracten', params);
  },
  kopierenContracten: async (
    params: IKopierenContractenParams,
  ): Promise<IKopierenContractenResult> => {
    return await api.post('/v2/contract/kopieren-contracten', params);
  },
  checkToepassenActieOpContracten: async (
    params: IToepassenActieOpContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/check-toepassen-actie-op-contracten', params);
  },
  toepassenActieOpContracten: async (
    params: IToepassenActieOpContractenParams,
  ): Promise<IToepassenActieOpContractenResult> => {
    return await api.post('/v2/contract/toepassen-actie-op-contracten', params);
  },
  checkVerwijderenActieOpContracten: async (
    params: IVerwijderenActieOpContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/check-verwijderen-actie-op-contracten', params);
  },
  verwijderenActieOpContracten: async (
    params: IVerwijderenActieOpContractenParams,
  ): Promise<IVerwijderenActieOpContractenResult> => {
    return await api.post('/v2/contract/verwijderen-actie-op-contracten', params);
  },
  ophalenMaandhuurContracten: async (
    params: IOphalenMaandhuurContractenParams,
  ): Promise<IOphalenMaandhuurContractenResult> => {
    return await api.post('/v2/contract/ophalen-maandhuur-contracten', params);
  },
};

export default contract;
