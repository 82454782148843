import api from '../../..';
import {
  IOphalenRechtzakenParams,
  IOphalenRechtzakenResult,
  IToevoegenRechtzaakParams,
  IToevoegenRechtzaakResult,
  IVerwijderenRechtzakenParams,
  IVerwijderenRechtzakenResult,
  IWijzigenRechtzaakParams,
  IWijzigenRechtzaakResult,
} from '../../../../../../shared/src/api/v2/debiteur/rechtzaak';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const zaak = {
  ophalenRechtzaken: async (
    params: IOphalenRechtzakenParams,
  ): Promise<IOphalenRechtzakenResult> => {
    return await api.post('/v2/debiteur/rechtzaak/ophalen-rechtzaken', params);
  },
  checkVerwijderennRechtzaken: async (
    params: IVerwijderenRechtzakenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/rechtzaak/check-verwijderen-rechtzaken', params);
  },
  verwijderenRechtzaken: async (
    params: IVerwijderenRechtzakenParams,
  ): Promise<IVerwijderenRechtzakenResult> => {
    return await api.post('/v2/debiteur/rechtzaak/verwijderen-rechtzaken', params);
  },
  toevoegenRechtzaak: async (
    params: IToevoegenRechtzaakParams,
  ): Promise<IToevoegenRechtzaakResult> => {
    return await api.post('/v2/debiteur/rechtzaak/toevoegen-rechtzaak', params);
  },
  wijzigenRechtzaak: async (
    params: IWijzigenRechtzaakParams,
  ): Promise<IWijzigenRechtzaakResult> => {
    return await api.post('/v2/debiteur/rechtzaak/wijzigen-rechtzaak', params);
  },
};

export default zaak;
