import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Root } from './style';
import Tegel from '../../../../../../../../components/tegel/Tegel';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import AfbeeldingKader from '../../../../../../../../components/weergave/AfbeeldingKader';
import ActieMenuKnop from '../../../../../../../../components/ActieMenuKnop';
import {
  IconCopy,
  IconInformatie,
  IconSend,
  IconTransport,
  IconVerwijderen,
  IconWijzigen,
} from '../../../../../../../../components/Icons';
import {
  Kleur as EKleur,
  mutatiebronIconMap,
} from '../../../../../../../../bedrijfslogica/constanten';
import TegelUitklapMenu, {
  ISectie,
} from '../../../../../../../../components/tegel/TegelUitklapMenu';
import HorizontaleScheidingslijn from '../../../../../../../../components/layout/HorizontaleScheidingslijn';
import MediaSectie from './MediaSectie';
import ReparatieopdrachtSectie from './ReparatieopdrachtSectie';
import DetailsSectie from './DetailsSectie';
import { maandenNaarJaren } from '../../../../../../../../bedrijfslogica/teksten';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import api from '../../../../../../../../api';
import { differenceInMonths, format } from 'date-fns';
import NieuwOpdrachtDialoog from '../opdracht/NieuwOpdrachtDialoog';
import WijzigenMeldingDialoog from '../../../../../../../../components/service/WijzigenMeldingDialoog';
import ContractVisualisatie from '../../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';
import { formatteerPersoonNaam } from '../../../../../../../../helpers';
import ProductinfoDialoog, {
  ETablad as ProductinfoETabblad,
} from '../../../../../../../../components/product/ProductinfoDialoog';
import { IDocumentenDialoogState } from '../../../Contracten/Overzicht/ContractTegel';
import VersturenDocumentatieDialoog from '../../../../../../../../components/product/VersturenDocumentatieDialoog';
import { IRow } from '..';
import ContactKoppelComponent from '../../../../../../../../components/ContactKoppelComponent';
import TelefoonComponent from '../../../../../../../../components/communicatie/TelefoonComponent';
import EmailComponent from '../../../../../../../../components/communicatie/EmailComponent';
import VeldWeergave from '../../../../../../../../components/formulier/VeldWeergave';
import Chip from '../../../../../../../../components/Chip';
import ProductSelectieDialoog from '../../../../../../../../components/product/ProductSelectieDialoog';
import { ETekstPrecisie } from '../../../../../../../../components/formulier/TekstPrecisieSelectie';
import NieuweTransportopdrachtDialoog from '../../../../../../../../components/transport/NieuweOpdrachtDialoog';
import RelatieVisualisatie from '../../../../../../../../components/personalia/RelatieVisualisatie';
import { EMutatiebron } from '../../../../../../../../bedrijfslogica/enums';
import TerugkoppelingSectie from './TerugkoppelingSectie';
import { toast } from 'react-hot-toast';

interface IProps {
  melding: IRow;
  selected: boolean;
  onSelectedChange: (state: boolean) => void;
  onRequestRefresh: () => void;
  relID: number;
}

enum ESectie {
  Details,
  Media,
  Reparatieopdrachten,
  Terugkoppeling,
}

export interface ISectieProps {
  melding: IRow;
  onRequestRefresh: () => void;
}

const sectieMapping: Record<ESectie, React.ComponentType<ISectieProps>> = {
  [ESectie.Details]: DetailsSectie,
  [ESectie.Media]: MediaSectie,
  [ESectie.Reparatieopdrachten]: ReparatieopdrachtSectie,
  [ESectie.Terugkoppeling]: TerugkoppelingSectie,
};

export interface IToevoegenTransportopdrachtDialoogState {
  relID: number;
  cntID: number | null;
}

const MeldingTegel: React.FC<IProps> = observer((props) => {
  const { melding, onSelectedChange, selected, onRequestRefresh } = props;
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);
  const [uitgeklapteSectie, setUitgeklapteSectie] = useState<ESectie | null>(null);
  const [reparatieOpdrachtDialoogTonen, setReparatieOpdrachtDialoogTonen] = useState(false);
  const [wijzigenDialoogTonen, setWijzigenDialoogTonen] = useState(false);
  const [productInfoDialoogTonen, setProductInfoDialoogTonen] = useState<boolean>(false);
  const [productKoppelDialoogTonen, setProductKoppelDialoogTonen] = useState<boolean>(false);
  const [
    toevoegenTransportopdrachtDialoogTonen,
    setToevoegenTransportopdrachtDialoogTonen,
  ] = useState<IToevoegenTransportopdrachtDialoogState | null>(null);

  const IconMutatiebron = mutatiebronIconMap[melding.Mutatiebron as EMutatiebron];

  const [
    versturenDocumentatieDialoogState,
    setVersturenDocumentatieDialoogState,
  ] = useState<IDocumentenDialoogState | null>(null);

  const secties = useMemo<ISectie<ESectie>[]>(
    () =>
      [
        {
          id: ESectie.Details,
          label: `Details`,
        },
        {
          id: ESectie.Media,
          label: `Media (${melding.bijlagen.length})`,
        },
        {
          id: ESectie.Reparatieopdrachten,
          label: `Reparatieopdrachten (${melding.reparatieopdrachten.length})`,
        },
        melding.terugkoppeling === null
          ? null
          : {
              id: ESectie.Terugkoppeling,
              label: `Terugkoppeling`,
            },
      ].filter((x) => x !== null) as ISectie<ESectie>[],
    [melding.bijlagen, melding.reparatieopdrachten, melding.terugkoppeling],
  );

  const SectieComp = useMemo<React.ComponentType<ISectieProps> | null>(() => {
    if (uitgeklapteSectie === null) {
      return null;
    }
    return sectieMapping[uitgeklapteSectie];
  }, [uitgeklapteSectie]);

  const locatie = melding.locatie;

  const meldDatum = useMemo(() => new Date(melding.Melddatum), [melding.Melddatum]);
  const meldDatumWeergave = useMemo(() => format(meldDatum, 'dd-MM-yyyy HH:mm'), [meldDatum]);
  const relatieveLeeftijdInMaanden = useMemo<number | null>(() => {
    if (melding.product === null) {
      return null;
    }
    return differenceInMonths(meldDatum, new Date(melding.product.DatumInBedrijf));
  }, [melding]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.service.checkVerwijderenMeldingen({
      IDs: [melding.ID],
    });
    const controleResult = await checkStore.controleren({
      checkData,
    });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Wil je de meldingen verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.verwijderenMeldingen({
      IDs: [melding.ID],
    });
  }, [melding]);

  return (
    <>
      <Root>
        <Tegel
          linkerKolom={
            <div style={{ paddingTop: 2 }}>
              <VinkVeld aangevinkt={selected} onGewijzigd={(x) => onSelectedChange(x)} />
            </div>
          }
          rechterKolom={
            <div className="ml-2">
              <ActieMenuKnop
                acties={[
                  // {
                  //   text: 'Reparatie opdracht',
                  //   // icon: <IconVerwijderen style={{ width: 15, height: 15, fill: Kleur.Grijs }} />,
                  //   onClick: () => setReparatieOpdrachtDialoogTonen(true),
                  // },
                  {
                    text: 'Link voor terugkoppelen kopiëren',
                    icon: <IconCopy style={{ width: 15, height: 15, fill: EKleur.Grijs }} />,
                    onClick: async () => {
                      const result = await api.v2.service.genereerLinkVoorTerugkoppeling({
                        servMeldID: melding.ID,
                      });

                      await navigator.clipboard.writeText(result.url);

                      toast.success('Link gekopiëerd');
                    },
                  },
                  {
                    text: 'Wijzigen',
                    icon: <IconWijzigen style={{ width: 15, height: 15, fill: EKleur.Grijs }} />,
                    onClick: () => setWijzigenDialoogTonen(true),
                  },
                  {
                    text: 'Productdocumentatie versturen',
                    icon: <IconSend style={{ width: 15, height: 15, fill: EKleur.Grijs }} />,
                    onClick: () => {
                      return setVersturenDocumentatieDialoogState({
                        prodID: melding.product!.ProdID,
                        relID: props.melding.RelID ?? undefined,
                        persID:
                          props.melding.persoon !== null ? props.melding.persoon.PersID : undefined,
                      });
                    },
                    disabled: melding.product === null,
                  },
                  {
                    text: 'Omruilopdracht',
                    icon: <IconTransport style={{ width: 15, height: 15, fill: EKleur.Grijs }} />,
                    onClick: () =>
                      setToevoegenTransportopdrachtDialoogTonen({
                        relID: melding.RelID!,
                        cntID: melding.contract !== null ? melding.contract.CntID : null,
                      }),
                    disabled: melding.product === null,
                  },
                  {
                    text: 'Verwijderen',
                    icon: <IconVerwijderen style={{ width: 15, height: 15, fill: EKleur.Grijs }} />,
                    onClick: async () => {
                      handleVerwijderen();
                    },
                  },
                  {
                    text: 'Product koppelen',
                    // icon: <IconWijzigen style={{ width: 15, height: 15, fill: EKleur.Grijs }} />,
                    onClick: () => setProductKoppelDialoogTonen(true),
                  },
                  {
                    text: 'Product ontkoppelen',
                    disabled: melding.product === null,
                    // icon: <IconWijzigen style={{ width: 15, height: 15, fill: EKleur.Grijs }} />,
                    onClick: async () => {
                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: 'Wil je het product ontkoppelen van deze melding?',
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.product.bijwerkenLijstRecent({
                        prodID: melding.product!.ProdID,
                        aspGebrID: null,
                      });

                      const result = await api.v2.service.koppelenAanProduct({
                        servMeldID: melding.ID,
                        prodID: null,
                      });
                      props.onRequestRefresh();
                    },
                  },
                ]}
              />
            </div>
          }
        >
          <div
            className="ml-3"
            style={{
              display: 'grid',
              gridTemplateAreas: `
            "meldnummer melddatum   adres     adres"
            "afbeelding productinfo persoon         melding"
            "afbeelding productinfo persoon         melding"
          `,
              gap: 5,
              gridTemplateColumns: `auto auto auto 1fr`,
              gridTemplateRows: 'auto auto auto',
            }}
          >
            <div
              style={{ gridArea: 'melddatum' }}
              className="d-flex pl-3 d-flex align-items-center"
            >
              <span className="text-muted">{meldDatumWeergave}</span>
              <span className="ml-2 d-flex align-items-center">
                <IconMutatiebron style={{ width: 12, height: 12, fill: EKleur.Grijs }} />
              </span>
              <span className="ml-2">
                {melding.aspGebruiker !== null ? melding.aspGebruiker.NaamKort : null}
              </span>
            </div>

            <div style={{ gridArea: 'meldnummer' }} className="d-flex align-items-center">
              SM-{melding.Meldnummer}
            </div>

            <div style={{ gridArea: 'afbeelding' }}>
              <AfbeeldingKader
                afbeelding={
                  melding.product !== null && melding.product.afbeeldingen.length !== 0 ? (
                    <img
                      alt="Productafbeelding kon niet worden geladen"
                      src={melding.product.afbeeldingen[0].url}
                      // width={60}
                      // height={67}
                      width={70}
                      height={78}
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  ) : null
                }
                isVergrootbaar={
                  melding.product !== null && melding.product.afbeeldingen.length !== 0
                }
                imgSrc={
                  melding.product !== null && melding.product.afbeeldingen.length !== 0
                    ? melding.product.afbeeldingen[0].url
                    : undefined
                }
                width={70}
                height={78}
              />
            </div>

            <div style={{ gridArea: 'productinfo', width: 225 }} className="pl-3 pr-2">
              {melding.product === null ? (
                <>
                  {melding.Productomschrijving !== null && (
                    <div>
                      <span className="text-muted">{melding.Productomschrijving}</span>
                    </div>
                  )}
                  {melding.productsoort !== null && (
                    <div>
                      <span className="text-muted">{melding.productsoort.Naam}</span>
                    </div>
                  )}
                  <div>
                    <span className="text-muted">Geen gekoppeld product</span>
                  </div>
                </>
              ) : (
                <div>
                  <div className="d-flex align-items-center">
                    {melding.product!.producttype.Merknaam} {melding.product!.producttype.Typenaam}
                    <span
                      className="ml-2 d-flex align-items-center"
                      onClick={() => {
                        setProductInfoDialoogTonen(true);
                      }}
                    >
                      <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                    </span>
                  </div>
                  <div>{melding.product!.producttype.Productsoortnaam}</div>
                  <div>Ref. {melding.product!.Referentiecode}</div>
                  <div></div>
                  <div>
                    Lft.{' '}
                    {melding.product!.leeftijd !== null
                      ? maandenNaarJaren(melding.product!.leeftijd)
                      : ''}
                    {relatieveLeeftijdInMaanden !== null
                      ? ' (' + maandenNaarJaren(relatieveLeeftijdInMaanden) + ')'
                      : ''}
                  </div>
                </div>
              )}
            </div>

            {/*<div style={{ gridArea: 'contract' }} className="p-1 pl-2 pr-2">*/}
            {/*  {melding.contract === null ? null : (*/}
            {/*    <div>*/}
            {/*      {melding.contract!.Basisnummer}.{melding.contract!.Volgnummer}*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}

            <div
              style={{
                gridArea: 'melding',
              }}
              className="pb-1 pt-1 d-flex justify-content-end"
            >
              <VeldWeergave
                style={{
                  flex: 1,
                  maxWidth: 500,
                }}
              >
                {melding.Omschrijving}
              </VeldWeergave>
            </div>

            <div
              style={{
                gridArea: 'persoon',
              }}
            >
              {/* <ContactKoppelComponent
                persID={melding.persoon !== null ? melding.persoon.PersID : null} // {row.persoon === null ? null : row.persoon.PersID}
                orgID={null} // {row.organisatie === null ? null : row.organisatie.OrgID}
                onChange={async (persID, orgID) => {
                  await api.v2.service.koppelenAanPersoon({
                    servMeldID: melding.ID,
                    persID,
                  });
                  props.onRequestRefresh();
                }}
                ongekoppeldComponent={(p) => {
                  const naam =
                    melding.Voornaam === null || melding.Achternaam === null
                      ? null
                      : `${melding.Voornaam} ${melding.Achternaam}`;

                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();

                        p.showKoppelDialoog();
                      }}
                      className={naam === null ? 'font-italic' : ''}
                    >
                      {naam === null ? 'Geen melder bekend' : naam}
                    </a>
                  );
                }}
                persoonVisualisatieProps={{
                  options: {
                    geenLinkToepassen: true,
                  },
                }}
                organisatieVisualisatieProps={{
                  geenLinkToepassen: true,
                }}
              >
                xx
              </ContactKoppelComponent> */}

              {melding.persoon !== null ? (
                <div>
                  {formatteerPersoonNaam({
                    voornaam: melding.persoon.Voornaam!,
                    achternaam: melding.persoon.Achternaam!,
                    voorletters: melding.persoon.Voorletters!,
                    aanhefKort: melding.persoon.AanhefKort!,
                    voorvoegsel: melding.persoon.Voorvoegsel!,
                  })}
                </div>
              ) : (
                <span style={{ fontStyle: 'italic', color: EKleur.LichtGrijs }}>
                  {melding.Achternaam !== null
                    ? melding.Voornaam +
                      (melding.Voorvoegsel !== null ? ' ' + melding.Voorvoegsel : '') +
                      ' ' +
                      melding.Achternaam
                    : 'Geen contactpersoon'}{' '}
                </span>
              )}

              <div>
                {melding.Telefoon !== null ? (
                  <TelefoonComponent
                    telefoonNummer={melding.Telefoon}
                    options={{ icoonWeergeven: false }}
                  />
                ) : (
                  <span></span>
                )}
              </div>
              <div>
                {melding.Email !== null ? (
                  <EmailComponent
                    emailAdres={melding.Email}
                    options={{ icoonWeergeven: false }}
                    // orgID={props.orgID}
                  />
                ) : (
                  <span></span>
                )}
              </div>
            </div>

            <div style={{ gridArea: 'adres' }} className="p-1 pl-2 pr-3">
              <div className="d-flex">
                <div className="d-flex">
                  {melding.contract !== null ? (
                    <div className="mr-2">
                      <ContractVisualisatie cntID={melding.contract.CntID} />
                    </div>
                  ) : (
                    <span className="mr-2">(Geen contract)</span>
                  )}
                </div>

                <div>
                  <span className="d-flex">
                    <span>
                      {locatie.Straatnaam} {locatie.Huisnummer}
                      {locatie.Bisnummer !== null ? ' ' + locatie.Bisnummer : ''},{' '}
                      {locatie.Postcode} {locatie.Plaatsnaam}
                    </span>
                    {/* <span>
                      <RelatieVisualisatie relID={melding.RelID!} />
                    </span> */}
                  </span>

                  {/* {true ? (
                    <span>
                      {' '}
                      - <RelatieVisualisatie relID={melding.RelID!} />
                    </span>
                  ) : (
                    <span></span>
                  )} */}

                  {/* <AdresWeergave
                    adres={{
                      straatnaam: locatie.Straatnaam,
                      landnaamKort: locatie.LandnaamKort,
                      landnaamEnum: locatie.LandnaamEnum,
                      plaatsnaam: locatie.Plaatsnaam,
                      postcode: locatie.Postcode,
                      bisnummer: locatie.Bisnummer,
                      huisnummer: locatie.Huisnummer,
                    }}
                    enkeleLijn
                  /> */}
                </div>

                {/* {melding.persoon !== null && (
                  <div className="ml-2">
                    {' '}
                    &ndash;{' '}
                    {formatteerPersoonNaam({
                      voorletters: melding.persoon.Voorletters,
                      aanhefKort: melding.persoon.AanhefKort,
                      achternaam: melding.persoon.Achternaam,
                      voornaam: melding.persoon.Voornaam,
                      voorvoegsel: melding.persoon.Voorvoegsel,
                    })}
                  </div>
                )} */}

                <div className="flex-fill" />

                <span className="font-weight-bold">{melding.status.Naam}</span>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <TegelUitklapMenu<ESectie>
              secties={secties}
              uitgeklaptId={uitgeklapteSectie}
              onUitgeklaptIdChange={(x) => setUitgeklapteSectie(x)}
            />
          </div>

          {SectieComp !== null && (
            <>
              <div className="mt-3 mb-2">
                <HorizontaleScheidingslijn />
              </div>
              <SectieComp melding={melding} onRequestRefresh={onRequestRefresh} />
            </>
          )}
        </Tegel>
      </Root>

      {toevoegenTransportopdrachtDialoogTonen !== null && (
        <NieuweTransportopdrachtDialoog
          open
          relID={toevoegenTransportopdrachtDialoogTonen.relID}
          contractID={toevoegenTransportopdrachtDialoogTonen.cntID ?? undefined}
          leverProduct={null}
          onSuccess={() => {
            {
              setToevoegenTransportopdrachtDialoogTonen(null);
              props.onRequestRefresh();
            }
          }}
          onAnnuleren={() => setToevoegenTransportopdrachtDialoogTonen(null)}
        />
      )}

      {reparatieOpdrachtDialoogTonen && (
        <NieuwOpdrachtDialoog
          open
          onSuccess={async () => {
            setReparatieOpdrachtDialoogTonen(false);
          }}
          onAnnuleren={() => setReparatieOpdrachtDialoogTonen(false)}
          servMeldID={melding.ID}
          relID={props.relID}
          cntID={null}
        />
      )}

      {wijzigenDialoogTonen && (
        <WijzigenMeldingDialoog
          open
          onSuccess={async () => {
            setWijzigenDialoogTonen(false);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => {
            setWijzigenDialoogTonen(false);
          }}
          servMeldID={melding.ID}
        />
      )}

      {productInfoDialoogTonen && (
        <ProductinfoDialoog
          open
          id={melding.product!.ProdID}
          tabblad={ProductinfoETabblad.Service}
          onSuccess={() => {
            setProductInfoDialoogTonen(false);
          }}
          onAnnuleren={() => setProductInfoDialoogTonen(false)}
        />
      )}

      {versturenDocumentatieDialoogState !== null && (
        <VersturenDocumentatieDialoog
          open
          prodID={versturenDocumentatieDialoogState.prodID}
          relID={versturenDocumentatieDialoogState.relID}
          persID={versturenDocumentatieDialoogState.persID}
          onSuccess={() => {
            setVersturenDocumentatieDialoogState(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => {
            setVersturenDocumentatieDialoogState(null);
          }}
        />
      )}

      {productKoppelDialoogTonen && (
        <ProductSelectieDialoog
          open
          koppelOpties={{
            defaultSelecterenValues: {
              referentiecode: melding.product?.Referentiecode || '',
              referentiecodeTekstprecisie: ETekstPrecisie.Exact,
              postcode: melding.locatie.Postcode || '',
              typenaam: '',
              typenaamTekstprecisie: ETekstPrecisie.StartMet,
              ookProductenUitBedrijf: true,
              notities: '',
              notitiesTekstprecisie: ETekstPrecisie.Ongeveer,
            },
          }}
          onSuccess={async (data) => {
            const result = await api.v2.service.koppelenAanProduct({
              servMeldID: melding.ID,
              prodID: data.prodID,
              contractMeenemen: true,
              relatieMeenemen: false,
            });

            const product = (
              await api.v2.product.ophalenProductenV2({
                filterSchema: { filters: [{ naam: 'IDS', data: [data.prodID] }] },
              })
            ).producten[0];

            await api.v2.product.bijwerkenLijstRecent({
              prodID: data.prodID,
              aspGebrID: null,
            });

            if (product.contractActueel !== null) {
            }

            props.onRequestRefresh();
            setProductKoppelDialoogTonen(false);
          }}
          onAnnuleren={() => setProductKoppelDialoogTonen(false)}
        />
      )}
    </>
  );
});

export default MeldingTegel;
