import React, { useCallback, useMemo } from 'react';
import DatumKiezer from '../../../../formulier/DatumKiezer';
import addDays from 'date-fns/addDays';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import MultiCombobox, { IKolom } from '../../../../formulier/MultiCombobox';
import { IOphalenDagboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import VeldWeergave from '../../../../formulier/VeldWeergave';
import { IOphalenBoekingenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenGrootboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import VinkVeld from '../../../../formulier/VinkVeld';
import FormatteerBedrag from '../../../../MutatieBedrag';

interface IProps {
  isDefinitief: IRemoteData<boolean>;
  onIsDefinitiefChange: (data: IRemoteData<boolean>) => void;
  boekdatum: IRemoteData<Date | null>;
  onBoekdatumChange: (boekdatum: Date | null) => void;
  dagboeken: IRemoteData<IOphalenDagboekenResultElement[]>;
  grootboeken: IRemoteData<IOphalenGrootboekenResultElement[]>;
  geselecteerdDagboekID: IRemoteData<number>;
  onGeselecteerdDagboekChange: (dagboekID: number) => void;
  dagboek: IRemoteData<IOphalenDagboekenResultElement | null>;
  boeking: IRemoteData<IOphalenBoekingenResultElement | null>;
  nogTeBoeken: IRemoteData<number>;
  isDefinitiefWeergeven: IRemoteData<boolean>;
  isDefinitiefMuteerbaar: IRemoteData<boolean>;
}

const Boekinggegevens = (props: IProps) => {
  const dagboekSleutelExtractor = useCallback(
    (dagboek: IOphalenDagboekenResultElement) => dagboek.ID,
    [],
  );
  const dagboekRepresentatieFabriek = useCallback(
    (dagboek: IOphalenDagboekenResultElement) => dagboek.Naam,
    [],
  );
  const dagboekKolommen = useMemo<IKolom<IOphalenDagboekenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 200,
      },
    ],
    [],
  );

  const vasteTegenrekening = useMemo<IRemoteData<IOphalenGrootboekenResultElement | null>>(() => {
    if (
      props.grootboeken.state === ERemoteDataState.Pending ||
      props.dagboek.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    if (props.dagboek.data === null || props.dagboek.data!.Tegenrekening_GrbRekID === null) {
      return createReadyRemoteData(null);
    }
    return createReadyRemoteData(
      props.grootboeken.data!.find((x) => x.ID === props.dagboek.data!.Tegenrekening_GrbRekID)!,
    );
  }, [props.grootboeken, props.dagboek]);

  return (
    <div className="p-3">
      <div className="d-flex align-items-center">
        <h5>Boekinggegevens</h5>
        <div className="flex-fill" />
        {props.nogTeBoeken.state === ERemoteDataState.Pending ||
        props.isDefinitiefWeergeven.state === ERemoteDataState.Pending ||
        props.isDefinitiefMuteerbaar.state === ERemoteDataState.Pending ||
        props.isDefinitief.state === ERemoteDataState.Pending ? (
          <Skeleton width={250} />
        ) : props.isDefinitiefWeergeven.data! ? (
          <div className="d-flex align-items-center">
            <VinkVeld
              aangevinkt={props.isDefinitief.data!}
              onGewijzigd={(x) => props.onIsDefinitiefChange(createReadyRemoteData(x))}
              disabled={!props.isDefinitiefMuteerbaar.data!}
            />
            <span className="ml-2">Is definitief</span>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <span className="mr-2">Nog te boeken:</span>
            <FormatteerBedrag bedrag={props.nogTeBoeken.data!} />
          </div>
        )}
      </div>
      <div className="d-flex align-items-center mt-2">
        <span className="mr-3">Boekdatum</span>
        {props.boekdatum.state === ERemoteDataState.Pending ? (
          <Skeleton height={30} width={130} />
        ) : (
          <DatumKiezer
            waarde={props.boekdatum.data!}
            onGewijzigd={props.onBoekdatumChange}
            determinePreviousValidDate={(date) => {
              return addDays(date, -1);
            }}
            determineNextValidDate={(date) => {
              return addDays(date, +1);
            }}
            disabled={
              props.isDefinitief.state === ERemoteDataState.Pending || props.isDefinitief.data!
            }
          />
        )}
        <span className="ml-4 mr-3">Dagboek</span>
        {props.dagboeken.state === ERemoteDataState.Pending ||
        props.geselecteerdDagboekID.state === ERemoteDataState.Pending ? (
          <Skeleton height={25} width={250} />
        ) : (
          <div style={{ width: 250 }}>
            <MultiCombobox
              sleutelExtractor={dagboekSleutelExtractor}
              representatieFabriek={dagboekRepresentatieFabriek}
              waarde={props.geselecteerdDagboekID.data}
              onWaardeChange={(x) => props.onGeselecteerdDagboekChange(x!)}
              opties={props.dagboeken.data!}
              kolommen={dagboekKolommen}
              disabled={
                props.isDefinitief.state === ERemoteDataState.Pending || props.isDefinitief.data!
              }
            />
          </div>
        )}
        {vasteTegenrekening.state === ERemoteDataState.Ready && vasteTegenrekening.data !== null && (
          <div className="ml-3">
            <VeldWeergave>Tegenrekening {vasteTegenrekening.data!.Nummer}</VeldWeergave>
          </div>
        )}
        <div className="flex-fill" />
        <span className="ml-4 mr-3">Boeknummer</span>
        {props.boeking.state === ERemoteDataState.Pending ? (
          <Skeleton height={15} width={80} />
        ) : props.boeking.data === null ? (
          <span className="font-weight-bold">Nieuw</span>
        ) : (
          <span className="font-weight-bold">{props.boeking.data!.Boeknummer}</span>
        )}
      </div>
    </div>
  );
};

export default Boekinggegevens;
