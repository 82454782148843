import { ILeegCacheParams, ILeegCacheResult } from '../../../../../shared/src/api/v2/website';
import api from '../../index';
import bestelling from './bestelling';
import afbeelding from './afbeelding';
import betaling from './betaling';
import pagina from './pagina/indes';
import webtekst from '../webtekst';
import feedback from './feedback';
import { IThemaItem } from '../../../../../shared/src/api/v2/website/basis';
import { ISelectieGeneriekParams } from '../../../../../shared/src/api/v2/generiek';

const website = {
  feedback,
  afbeelding,
  bestelling,
  betaling,
  pagina,
  webtekst,
  leegCache: async (params: ILeegCacheParams): Promise<ILeegCacheResult> => {
    return await api.post('/v2/extern/leeg-cache', params);
  },

  ophalenThemaItems: async (): Promise<IThemaItem[]> => {
    return await api.post('/v2/website/ophalen-thema-items', {});
  },
  toevoegenOfWijzigenThemaItem: async (params: IThemaItem): Promise<any> => {
    return await api.post('/v2/website/toevoegen-of-wijzigen-thema-item', params);
  },
  verwijderenThemaItems: async (params: ISelectieGeneriekParams): Promise<any> => {
    return await api.post('/v2/website/verwijderen-thema-items', params);
  },
};

export default website;
