import React from 'react';
import { Route, RouteComponentProps, Redirect } from 'react-router-dom';
import Voorraad from '../Voorraad';
import Aanbod from './Aanbod';
import Opdrachten from './Opdrachten';
import Diensten from '../Diensten/Beheer/Inkoop';
import Retouren from './Retouren';

const Inkoop = ({ match }: RouteComponentProps) => (
  <>
    <Route exact path={match.path} component={() => <Redirect to={`${match.path}/opdrachten`} />} />
    <Route path={`${match.path}/aanbod`} component={Aanbod} />
    <Route path={`${match.path}/opdrachten`} component={Opdrachten} />
    <Route path={`${match.path}/retouren`} component={Retouren} />
    <Route path={`${match.path}/voorraad`} component={Voorraad} />
    <Route path={`${match.path}/diensten`} component={Diensten} />
    <Route exact={true} path={match.path} render={() => <h3>selecteer een subpagina</h3>} />
  </>
);

export default Inkoop;
