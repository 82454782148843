import React, { useMemo } from 'react';
import BankmutatieSelectie, {
  EBankmutatieSelectieKolom,
} from '../../../../../formulier/BankmutatieSelectie';
import { IMuterenRegel, ERegelboekstukType, Regel } from '../../types';
import { IOphalenMutatiesResultElement } from '../../../../../../../../shared/src/api/v2/bank/mutaties';
import { Provider } from '../../../../../formulier/MultiComboboxV2';
import api from '../../../../../../api';
import { IOphalenDagboekenResultElement } from '../../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';

interface IProps {
  bankMutID: number | null;
  regel: IMuterenRegel;
  onRijChange: (regel: Regel) => void;
  setBezigMetVerwerken: (bezigMetVerwerken: boolean) => void;
  bepaalBankmutatie: (bankMutID: number) => Promise<IOphalenMutatiesResultElement>;
  tegenrekeningDagboek: IOphalenDagboekenResultElement;
}

const BankmutatieMuteren = (props: IProps) => {
  const bankmutatiesProvider = useMemo<
    Provider<EBankmutatieSelectieKolom, IOphalenMutatiesResultElement>
  >(() => {
    return async (params) => {
      const rekeningenResult = await api.v2.bank.ophalenRekeningen({
        filterSchema: {
          filters: [
            {
              naam: 'DAGBOEK_IDS',
              data: [props.tegenrekeningDagboek.ID],
            },
          ],
        },
      });
      const rekening = rekeningenResult.rekeningen[0];

      const result = await api.v2.bank.mutatie.ophalenMutaties({
        filterSchema: {
          filters: [
            {
              naam: 'BANK_REK_IDS',
              data: [rekening.BankRekID],
            },
          ],
        },
        paginatie: params.paginatie,
        orderSchema: {
          orders: params.sortering.map((sortering) => {
            switch (sortering.key) {
              case EBankmutatieSelectieKolom.Mutatiedatum: {
                return {
                  naam: 'MUTATIEDATUM',
                  richting: sortering.sortering,
                };
              }
              case EBankmutatieSelectieKolom.Bedrag: {
                return {
                  naam: 'BEDRAG',
                  richting: sortering.sortering,
                };
              }
              case EBankmutatieSelectieKolom.Geboekt: {
                return {
                  naam: 'GEBOEKT',
                  richting: sortering.sortering,
                };
              }
              case EBankmutatieSelectieKolom.Omschrijving: {
                return {
                  naam: 'OMSCHRIJVING',
                  richting: sortering.sortering,
                };
              }
            }
          }),
        },
      });

      const items = result.mutaties.reduce(
        (acc, curr, idx) => ({
          ...acc,
          [idx + params.paginatie.index]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    };
  }, [props.tegenrekeningDagboek.ID]);

  return (
    <BankmutatieSelectie
      bankMutID={props.bankMutID}
      onBankMutIDChange={async (bankMutID: number | null) => {
        let nieuweRegel: IMuterenRegel = {
          ...props.regel,
          regelboekstuk:
            bankMutID === null
              ? null
              : {
                  type: ERegelboekstukType.Bankmutatie,
                  bankMutID,
                },
        };
        props.onRijChange(nieuweRegel);
        if (bankMutID === null) {
          return;
        }
        props.setBezigMetVerwerken(true);

        const bankmutatie = await props.bepaalBankmutatie(bankMutID);
        nieuweRegel = {
          ...nieuweRegel,
          bedrag: bankmutatie.Bedrag,
        };
        props.onRijChange(nieuweRegel);

        props.setBezigMetVerwerken(false);
      }}
      provider={bankmutatiesProvider}
    />
  );
};

export default BankmutatieMuteren;
