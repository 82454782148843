import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IEntiteitProps } from '../../../../../../../components/kaart/EntiteitContainer';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import styled from 'styled-components';
import { IOphalenSaldiGrootboekrekeningenResultElement } from '../../../../../../../../../shared/src/api/v2/boekhouding/boeking';
import SaldiTegel from './SaldiTegel';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../../../../stores/CheckStore';
import {
  EGrootboekrekening,
  EGrootboekrekeningNaam,
} from '../../../../../../../bedrijfslogica/enums';

export const OverzichtTitel = styled.h3``;

interface IUrlState {}

const defaultUrlState: IUrlState = {};

interface IProps extends IEntiteitProps, RouteComponentProps {}

export interface IHeaderdata {
  oudsteContractdatum: Date | string | null | undefined;
  aantalLopendeContracten: number;
  aantalContracten: number;
}

const Overzicht: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const [saldi, setSaldi] = useState<IRemoteData<IOphalenSaldiGrootboekrekeningenResultElement[]>>(
    createPendingRemoteData(),
  );

  const ophalenSaldiGrootboekrekeningen = useCallback(async () => {
    const result = await api.v2.boekhouding.boeking.ophalenSaldiGrootboekrekeningen({
      filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
    });
    setSaldi(createReadyRemoteData(result.saldi));
  }, [props.relID]);

  useEffect(() => {
    ophalenSaldiGrootboekrekeningen();
  }, [ophalenSaldiGrootboekrekeningen]);

  const saldoVU = useMemo<number | null>(() => {
    if (saldi.state !== ERemoteDataState.Ready) {
      return null;
    }
    const saldo =
      saldi.data!.find(
        (x) => x.grootboekrekening.NaamEnum === EGrootboekrekeningNaam.VooruitOntvangenDebiteuren,
      )?.saldo ?? 0;

    return saldo;
  }, [saldi]);

  return (
    <div className="d-flex flex-fill flex-column">
      {saldi.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {/* <Header informatie={informatie.data!} relID={props.relID} /> */}

          {/* <HorizontaleScheidingslijn /> */}
          <div className="p-3">
            <div className="row">
              {/* {informatie.data!.event !== null && ( */}
              <div className="col-6">
                <SaldiTegel data={saldi.data!} />
              </div>
              {/* )} */}
            </div>

            <div className="d-flex align-items-center mt-3 mb-1">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={saldoVU === null || saldoVU === 0}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Wil je het saldo Vooruitbetaald afboeken?
                            <br />
                            Je kunt dit eventueel ongedaan maken in het memoriaal.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.boekhouding.boeking.afboekenSaldoVooruitOntvangenBedragen({
                    relIDs: [props.relID],
                  });

                  ophalenSaldiGrootboekrekeningen();
                }}
              >
                {/* <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} /> */}
                <span className="ml-2">Afboeken saldo VU</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default withRouter(Overzicht);
