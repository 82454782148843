import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Placeholder from '../InfoCard/Placeholder';
import Data from '../InfoCard/Data';
import { IBulkBerichtTijdvakVisualisatieData } from '../index';
import IRemoteData, { ERemoteDataState } from '../../../../models/IRemoteData';

interface IProps {
  data: IRemoteData<IBulkBerichtTijdvakVisualisatieData>;
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
`;

const InfoCard = (props: IProps) => {
  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {props.data.state === ERemoteDataState.Pending ? (
        <Placeholder />
      ) : (
        <Data data={props.data.data!} />
      )}
    </Root>
  );
};

export default InfoCard;
