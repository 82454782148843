import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import PaginaHeader from '../PaginaHeader';
import { NavLink } from 'react-router-dom';
import Retourverwerking from './Depot';
import Pendelopdrachten from './Pendelopdrachten';
import Inventarisatie from './Inventarisatie';
import Mutaties from '../../components/magazijn/Mutaties';
import Voorraad from '../Voorraad';
import Afhaalopdrachten from './Afhaalopdrachten';
import MagazijnDiensten from '../Diensten/Beheer/Magazijn';
import PendelDiensten from '../Diensten/Beheer/Pendel';
import Beheer from './Beheer';
import Magazijnbeheer from './Beheer';

interface IProps extends RouteComponentProps<{}> {}

const Magazijn: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <>
      <Route path={`${match.path}/beheer`} component={Magazijnbeheer} />
      {/* <Route path={`${match.path}/beheer`} component={Retourverwerking} /> */}
      <Route path={`${match.path}/depot`} component={Retourverwerking} />
      <Route path={`${match.path}/pendelopdrachten`} component={Pendelopdrachten} />
      <Route path={`${match.path}/inventarisatie`} component={Inventarisatie} />
      <Route path={`${match.path}/Mutaties`} component={Mutaties} />
      <Route path={`${match.path}/voorraad`} component={Voorraad} />
      <Route path={`${match.path}/afhaalopdrachten`} component={Afhaalopdrachten} />
      <Route path={`${match.path}/magazijndiensten`} component={MagazijnDiensten} />
      <Route path={`${match.path}/pendeldiensten`} component={PendelDiensten} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/depot`} />}
      />
    </>
  );
};

export default Magazijn;
