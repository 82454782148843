import { TableRowDetail } from '@devexpress/dx-react-grid';
import React, { useContext } from 'react';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import Commissies from '../../Formulieren/CommissiesFormulier/Commissies';
import { DienstenContext, IRegel } from '../index';

type IProps = TableRowDetail.ContentProps;

const DetailComp: React.FC<IProps> = (props) => {
  const rij: IRegel = props.row;
  const dienstenContext = useContext(DienstenContext);

  return (
    <div className="d-flex flex-fill">
      <TabelInspringBlok></TabelInspringBlok>
      <Commissies id={rij.ID} relID={rij.RelID}></Commissies>
    </div>
  );
};

export default DetailComp;
