import React, { useCallback, useMemo, useEffect, useState, useContext } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Modal from 'react-bootstrap/Modal';
import api from '../../../api';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import DatumKiezer from '../../formulier/DatumKiezer';
import { format } from 'date-fns';
import { addDays } from 'date-fns/esm';
import { RootStoreContext } from '../../../stores/RootStore';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  relIDs?: number[];
}

interface IFormikValues {
  bezoekDatum: Date | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  bezoekDatum: 'Bezoekdatum',
};

/**
 *
 * Haalt de bezoektijden op voor de transportopdrachten die op de opgegeven datum uitgevoerd zullen worden
 * De opgehaalde tijden worden bj de betreffende opdrachten vastgelegd
 */

const BezoektijdenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [bezoekdatum, setBezoekdatum] = useState<Date | null>(null);

  useEffect(() => {
    (async () => {
      // Bepaal de eerst komende werkdag om voor te vullen
      const werkdagen = await api.v2.datum.ophalenWerkdagen({});
      const bezoekdatum =
        werkdagen[0] === format(new Date(), 'yyyy-MM-dd') ? werkdagen[1] : werkdagen[0];
      setBezoekdatum(new Date(bezoekdatum));
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // Haal de IDs opp van de transportopdrachten die op de opgegeven datum uitgevoerd zullen worden
      const bezoekdatum = values.bezoekDatum; //format(values.bezoekDatum!, 'YYY-MM-dd');
      const trsOpdIDs = await api.v2.transport.selecterenOpdrachtenVoorBezoektijden({
        bezoekdatum,
        relIDs: props.relIDs,
      });

      // Met de IDs worden nu de bezoektijden opgehaald en bijgewerkt bij de opdrachten
      if (trsOpdIDs.length !== 0) {
        const result = await api.v2.transport.bijwerkenBezoektijden({
          trsOpdIDs,
        });

        await checkStore.melden({
          titel: `Er zijn voor ${result.aantalWaarvoorTijdenGevonden} van de gevonden ${result.aantalOpdrachten} opdrachten tijden bijgewerkt`,
        });
      } else {
        await checkStore.melden({
          titel: `Er zijn geen opdrachten gevonden die op ${format(
            bezoekdatum!,
            'dd-MM-yyyy',
          )} uitgevoerd moeten worden`,
        });
      }

      actions.setSubmitting(false);

      onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (bezoekdatum === null) {
      return null;
    }

    return {
      bezoekDatum: new Date(bezoekdatum),
    };
  }, [bezoekdatum]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        bezoekDatum: Yup.date().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.bezoekDatum,
          }),
        ),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Bezoektijden ophalen</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isInitialValid
          enableReinitialize
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.bezoekDatum}</label>
                        <Field
                          name="bezoekDatum"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            const minimaleDatum = addDays(new Date(), -1);
                            const maximaleDatum = addDays(new Date(), +30);

                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                                determineValidDate={(date) => {
                                  return date >= minimaleDatum && date <= maximaleDatum;
                                }}
                                determineNextValidDate={(date) => {
                                  const nieuweDatum = addDays(date, +1);
                                  if (nieuweDatum <= maximaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const nieuweDatum = addDays(date, -1);
                                  if (nieuweDatum >= minimaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default BezoektijdenDialoog;
