import React, { useMemo } from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import KlantWerftKlant from './KlantWerftKlant';
import Winweek from './Winweek';

const Klantacties = (props: RouteComponentProps) => {
  const { match } = props;

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <ul>
          <li>
            <NavLink
              to={`${match.url}/winweek`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Winweek
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/klant-werft-klant`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Klant werft klant
            </NavLink>
          </li>
        </ul>
      </PaginaHeader>

      <Route exact path={match.path} component={() => <Redirect to={`${match.path}/winweek`} />} />

      <Route path={`${match.path}/winweek`} component={Winweek} />
      <Route path={`${match.path}/klant-werft-klant`} component={KlantWerftKlant} />

      {/* <Route path={`${match.path}/export`} component={Boekingen} /> */}
      <Route exact={true} path={match.path} render={() => <h3>selecteer een subpagina</h3>} />
    </div>
  );
};

export default Klantacties;
