import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import {
  IOphalenFacturenBasisResultElement,
  IOphalenVerzendwijzenResultElement,
} from '../../../../../../shared/src/api/v2/factuur';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { ITabbladProps } from '../index';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import FactuurregelsComp from '../Factuurregels';
import SelectieVak from '../../../../components/SelectieVak';
import { EFunctioneleIcon, functioneleIconMap } from '../../../../components/Icons';
import FactuurinfoDialoog from '../../../../components/factuur/FactuurinfoDialoog';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { IOphalenBetaalwijzenResultElement } from '../../../../../../shared/src/api/v2/betaalwijze';
import MenuLayout from '../../../../components/MenuLayout';
import { Helmet } from 'react-helmet';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';

interface IProps extends RouteComponentProps, ITabbladProps {}

const geenData = {
  noData: 'Geen facturen gevonden',
};

export interface IVersturenDialoogState {
  factIDs: number[];
}

export interface IFactuurinfoDialoogState {
  factID: number;
}

enum EFilter {
  Betaalwijze = 'BETAALWIJZE_IDS',
}

const IconFactuurInfo = functioneleIconMap[EFunctioneleIcon.FactuurInfo];

const Versturen: React.FC<IProps> = observer((props) => {
  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);

  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [versturenDialoogState, setVersturenDialoogState] = useState<IVersturenDialoogState | null>(
    null,
  );
  const [verzendwijzen, setVerzendwijzen] = useState<IOphalenVerzendwijzenResultElement[] | null>(
    null,
  );

  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  const [filterData, setFilterData] = useState<IFilterData<EFilter>[]>([
    {
      naam: EFilter.Betaalwijze,
      isActief: false,
      data: null,
    },
  ]);
  const filterSchema = useMemo(() => maakFilterSchema(filterData), [filterData]);

  const ophalenRelaties = useCallback(async () => {
    if (facturen === null) {
      return;
    }
    const relatiesResult = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: _.uniq(facturen.map((x) => x.RelID)) }] },
      })
    ).relaties;

    setRelaties(relatiesResult);
  }, [facturen]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const ophalenVerzendwijzen = useCallback(async () => {
    const result = await api.v2.factuur.ophalenVerzendwijzen({});
    setVerzendwijzen(result.verzendwijzen);
  }, []);

  useEffect(() => {
    ophalenVerzendwijzen();
  }, [ophalenVerzendwijzen]);

  const keyExtractor = useCallback((row: IOphalenFacturenBasisResultElement) => row.FactID, []);

  const ophalenFacturen = useCallback(async () => {
    const { facturen } = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          { naam: 'DEFINITIEF', data: true },
          { naam: 'VERSTUREN', data: true },
        ],
      },
    });

    setRelaties(null);

    setFacturen(facturen);
  }, [filterSchema.filters!]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  // const handleVerwijderen = useCallback(async () => {
  //   const checkData = await api.v2.factuur.voorstel.checkVerwijderenFacturenUitVoorstel({
  //     factIDs: props.voorstelSelectie,
  //   });
  //   if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
  //     return;
  //   }
  //   if (
  //     (
  //       await checkStore.bevestigen({
  //         titel: `Wil je de ${props.voorstelSelectie.length} facturen uit het voorstel verwijderen?`,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   await api.v2.factuur.voorstel.verwijderenFacturenUitVoorstel({
  //     factIDs: props.voorstelSelectie,
  //   });

  //   props.onVoorstelSelectieChange([]);

  //   ophalenFacturen();
  // }, [props.voorstelSelectie]);

  const handleWijzigenIndicatieVersturen = useCallback(async (factIDs: number[]) => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de ${factIDs.length} facturen niet meer versturen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const params = { factIDs, versturen: false };

    await api.v2.factuur.wijzigenIndicatieVersturen(params);

    props.onVersturenSelectieChange([]);
    ophalenFacturen();
  }, []);

  const handleVersturen = useCallback(async (factIDs: number[]) => {
    const params = { factIDs, verzendwijze: null };

    const checkData = await api.v2.factuur.checkVersturenFacturen(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde facturen versturen?</span>,
          asynchroneActieNaBevestigingFn: async () => {
            const achtergrondproces = await api.v2.factuur.versturenFacturen(params);
            await achtergrondProcesAfwachten(achtergrondproces.id, realtimeContext);

            props.onVersturenSelectieChange([]);
            await ophalenFacturen();
          },
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
  }, []);

  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalwijzenResultElement[] | null>(
    null,
  );

  const ophalenBetaalwijzen = useCallback(async () => {
    const result = await api.v2.betaalwijze.ophalenBetaalwijzen({});

    setBetaalwijzen(result.betaalwijzen);
  }, []);

  useEffect(() => {
    ophalenBetaalwijzen();
  }, [ophalenBetaalwijzen]);

  const kolommen = useMemo<TypedColumn<IOphalenFacturenBasisResultElement>[]>(
    () => [
      {
        name: '__debiteur' as any,
        title: 'Debiteur',
      },
      // {
      //   name: 'Factuurnummer',
      //   title: 'Fact.nr',
      // },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__betaalwijze' as any,
        title: 'Betaalwijze',
        getCellValue: (x) => x.betaalwijze.Naam,
      },
      {
        name: '__verzendwijze' as any,
        title: 'Verzendwijze',
      },
      {
        name: '__factuurInfo' as any,
        title: ' ',
      },
      {
        name: '__notities' as any,
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenBasisResultElement>[]>(
    () => [
      // {
      //   columnName: 'Factuurnummer',
      //   width: 100,
      // },
      {
        columnName: 'Factuurdatum',
        width: 125,
      },
      {
        columnName: 'Vervaldatum',
        width: 125,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__debiteur' as any,
        width: 250,
      },
      {
        columnName: '__betaalwijze' as any,
        width: 150,
      },
      {
        columnName: '__verzendwijze' as any,
        width: 150,
      },
      {
        columnName: '__factuurInfo' as any,
        width: 40,
      },
      {
        columnName: '__notities' as any,
        width: 650,
      },
    ],
    [],
  );

  const betaalwijzenKolommen = useMemo<IKolom<IOphalenBetaalwijzenResultElement>[]>(
    () => [
      {
        key: 'Naam' as any,
        label: 'Naam',
        breedte: 125,
        formatFabriek: (rij) => rij.Naam,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Betaalwijze,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Betaalwijzen</span>
              <span className="ml-2">
                <MultiCombobox<number, IOphalenBetaalwijzenResultElement>
                  sleutelExtractor={(row) => row.BetWijzeID}
                  waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
                  onWaardeChange={(value: number | null) => {
                    weergaveProps.onDataChange(value === null ? null : [value]);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  representatieFabriek={(x) => x.Naam}
                  opties={betaalwijzen}
                  kolommen={betaalwijzenKolommen}
                />
              </span>
            </span>
          );
        },
      },
    ],
    [betaalwijzen, betaalwijzenKolommen],
  );

  if (facturen === null || verzendwijzen === null || relaties === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Facturen Versturen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.versturenSelectie.length === 0}
                onClick={() => handleVersturen(props.versturenSelectie)}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Versturen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.versturenSelectie.length === 0}
                onClick={() => handleWijzigenIndicatieVersturen(props.versturenSelectie)}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Indicatie Versturen verwijderen</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={filterData}
                  onFilterDataChange={setFilterData}
                />
              </div>
            </div>

            <div className="mt-3 ml-3">
              <SelectieVak
                aantal={props.versturenSelectie.length}
                totaalAantal={facturen!.length}
                onChange={(alles) => {
                  if (alles) {
                    props.onVersturenSelectieChange(facturen!.map((x) => x.FactID));
                  } else {
                    props.onVersturenSelectieChange([]);
                  }
                }}
                entiteitEnkelvoud="factuur"
                entiteitMeervoud="facturen"
              />
            </div>
          </>
        }
        body={
          <>
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid columns={kolommen} getRowId={keyExtractor} rows={facturen!}>
                <DataTypeProvider
                  for={['Factuurdatum']}
                  formatterComponent={(props) => (
                    <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                  )}
                />
                <DataTypeProvider
                  for={['Vervaldatum']}
                  formatterComponent={(props) => (
                    <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                  )}
                />
                <DataTypeProvider
                  for={['Bedrag']}
                  formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                />
                <DataTypeProvider
                  for={['Openstaand']}
                  formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                />
                <DataTypeProvider
                  for={['__debiteur']}
                  formatterComponent={(props) =>
                    props.row.debiteur.RelID !== null ? (
                      <RelatieVisualisatie relID={props.row.debiteur.RelID} />
                    ) : (
                      <span></span>
                    )
                  }
                />
                <DataTypeProvider
                  for={['__notities']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenFacturenBasisResultElement = formatterProps.row;
                    const relatie = relaties.find((x) => x.RelID === rij.RelID)!;
                    return <span>{relatie.Notities}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__betaalwijze']}
                  formatterComponent={(props) => props.row.betaalwijze.Naam}
                />

                <DataTypeProvider
                  for={['__verzendwijze']}
                  formatterComponent={(props) => {
                    const row: IOphalenFacturenBasisResultElement = props.row;

                    if (row.debiteur.VerzendwijzeFacturen === null) {
                      return <span>Standaard</span>;
                    }

                    const naamVerzendwijze = verzendwijzen.find(
                      (x) => x.ID === row.debiteur.VerzendwijzeFacturen,
                    )!.Naam;

                    return <span>{naamVerzendwijze}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__factuurInfo']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenFacturenBasisResultElement = formatterProps.row;
                    return (
                      <a
                        href="#"
                        className="ml-1"
                        style={{ color: Kleur.LichtBlauw, position: 'relative', bottom: 2 }}
                        onClick={() => {
                          setFactuurinfoDialoogState({ factID: rij.FactID });
                        }}
                      >
                        <IconFactuurInfo style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                      </a>
                    );
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />
                <VirtualTable />
                <VirtualTable messages={geenData} />

                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                <SelectionState
                  selection={props.versturenSelectie}
                  onSelectionChange={(value) => props.onVersturenSelectieChange(value as number[])}
                />

                <TableHeaderRow showSortingControls />
                <RowDetailState defaultExpandedRowIds={[]} />
                <TableRowDetail
                  contentComponent={FactuurregelsComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          </>
        }
      />
      {facturen === null || verzendwijzen === null || relaties === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          ></div>
        </>
      )}

      {/* {versturenDialoogState !== null && (
        <VersturenDialoog
          open
          factIDs={versturenDialoogState.factIDs}
          onSuccess={() => {
            setVersturenDialoogState(null);
            ophalenFacturen();
          }}
          onAnnuleren={() => setVersturenDialoogState(null)}
        ></VersturenDialoog>
      )} */}

      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        />
      )}
    </>
  );
});

export default withRouter(Versturen);
