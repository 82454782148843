import React, { useContext } from 'react';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import { observer } from 'mobx-react-lite';
import MediaWeergaveDialoog from '../../../../dialogen/MediaWeergaveDialoog';
import { EMediaWeergaveType } from '../../../../MediaWeergave';
import { IconUitvergroten } from '../../../../Icons';

interface IProps {
  src: string;
  mediaType: string;
  width: number;
  height: number;
}

const Afbeelding = observer((props: IProps) => {
  const rendererContext = useContext(GlobaleRendererContext);
  const [isHovered, setIsHovered] = React.useState(false);

  const w = props.width * (2 / 3);
  const h = props.height * (2 / 3);

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: w,
        height: h,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'relative',
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <img src={props.src} style={{ width: w, height: h, objectFit: 'contain' }} alt="afbeelding" />

      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
          className="d-flex align-items-center justify-content-center"
          onClick={async (ev) => {
            ev.stopPropagation();

            await rendererContext.render((renderProps) => {
              return (
                <MediaWeergaveDialoog
                  current={1}
                  mediaWeergaven={[
                    {
                      id: 1,
                      src: props.src,
                      mediaType: props.mediaType,
                      type: EMediaWeergaveType.Afbeelding,
                    },
                  ]}
                  open
                  onSuccess={() => renderProps.destroy()}
                  onAnnuleren={() => renderProps.destroy()}
                />
              );
            });
          }}
        >
          <IconUitvergroten style={{ fill: Kleur.Wit, width: 32, height: 32 }} />
        </div>
      )}
    </div>
  );
});

export default Afbeelding;
