import React from 'react';
import styled from 'styled-components';
import { INavigatieItem } from '../../../shared';
import NavigatiePadItem from './NavigatiePadItem';
import { IconChevronRight } from '../../../../../components/Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IProps {
  navigatieItems: INavigatieItem[];
  termen: string[];
}

const NavigatiePad = (props: IProps) => {
  return (
    <Root>
      {props.navigatieItems.map((navItem, i, arr) => {
        const isLaatste = i === arr.length - 1;

        return (
          <div key={navItem.key} className="d-flex align-items-center">
            <NavigatiePadItem navigatieItem={navItem} termen={props.termen} />
            {!isLaatste && (
              <IconChevronRight
                style={{
                  width: 18,
                  height: 18,
                  fill: Kleur.LichtGrijs,
                  marginLeft: 3,
                  marginRight: 2,
                }}
              />
            )}
          </div>
        );
      })}
    </Root>
  );
};

export default NavigatiePad;
