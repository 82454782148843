import React, { useCallback, useMemo } from 'react';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  TableRowDetail,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import nameOf from '../../../../../core/nameOf';
import { IOphalenOpdrachtenResultElementV2 } from '../../../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenGekoppeldeTransportopdrachtenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur/transport';
import InkoopfactuurVisualisatie from '../../../../../components/entiteitVisualisaties/InkoopfactuurVisualisatie';
import _ from 'lodash';
import { formatteerBedrag } from '../../../../../helpers';
import TransportopdrachtVisualisatie from '../../../../../components/entiteitVisualisaties/TransportopdrachtVisualisatie';

interface IProps extends TableRowDetail.ContentProps {}

export interface IOpdracht extends IOphalenOpdrachtenResultElementV2 {}

const geenData = {
  noData: 'Geen transportopdrachten gevonden',
};

const OpdrachtenDetailComp: React.FC<IProps> = (props) => {
  const opdrachten: IOpdracht[] = props.row.opdrachten;
  const gekoppeldeFacturen: IOphalenGekoppeldeTransportopdrachtenResultElement[] =
    props.row.gekoppeldeFacturen;

  const keyExtractor = useCallback((row: IOpdracht) => row.TrsOpdID, []);

  const kolommen = useMemo<TypedColumn<IOpdracht>[]>(() => {
    return [
      // {
      //   name: 'Opdrachtnummer',
      //   title: 'Opd.nr.',
      // },
      {
        name: '__opdracht' as any,
        title: 'Opd.nr.',
      },
      {
        name: 'Bezoekdatum',
        title: 'Bezoekdatum',
      },
      {
        name: '__totaalGefactureerd' as any,
        title: 'Totaal gefactureerd',
      },
      {
        name: 'FacturatieBeoordeeldOp',
        title: 'Beoordeeld op',
      },
      {
        name: '__facturen' as any,
        title: 'Facturen',
      },
      {
        name: 'Notities',
        title: 'Notities bij opdracht',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOpdracht>[]>(() => {
    return [
      {
        columnName: 'Opdrachtnummer',
        width: 100,
      },
      {
        columnName: '__opdracht' as any,
        width: 100,
      },
      {
        columnName: 'Bezoekdatum',
        width: 150,
      },
      {
        columnName: '__facturen' as any,
        width: 200,
      },
      {
        columnName: '__totaalGefactureerd' as any,
        width: 160,
      },
      {
        columnName: 'FacturatieBeoordeeldOp',
        width: 160,
      },
      {
        columnName: 'Notities',
        width: 250,
      },
    ];
  }, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [{ columnName: '__facturen', align: 'left' }];
  }, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={opdrachten.length}>
          <Grid getRowId={keyExtractor} rows={opdrachten} columns={kolommen}>
            {/* <DataTypeProvider
              for={[nameOf<IOpdracht>('Opdrachtnummer')]}
              formatterComponent={(props) => {
                const rij: IOpdracht = props.row;

                return <span>{rij.Opdrachtnummer}</span>;
              }}
            /> */}

            <DataTypeProvider
              for={['__opdracht']}
              formatterComponent={(props) => {
                const rij: IOpdracht = props.row;

                return <TransportopdrachtVisualisatie trsOpdID={rij.TrsOpdID} />;
              }}
            />

            <DataTypeProvider
              for={[nameOf<IOpdracht>('Bezoekdatum')]}
              formatterComponent={(props) => {
                const rij: IOpdracht = props.row;

                return <span>{format(new Date(rij.Bezoekdatum!), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__facturen']}
              formatterComponent={(props) => {
                const rij: IOpdracht = props.row;

                const gekoppeldeFacturenVoorOpdracht = gekoppeldeFacturen.filter(
                  (x) => x.TrsOpdID === rij.TrsOpdID,
                );

                const inkFactIDs: number[] = _.uniq(
                  gekoppeldeFacturenVoorOpdracht.map((x) => x.InkFactID),
                );

                return (
                  <div className="d-flex flex-column">
                    {inkFactIDs.map((x) => (
                      <div key={x} className="d-flex align-items-center">
                        <span className="flex-fill">
                          <InkoopfactuurVisualisatie inkFactID={x!} />
                        </span>
                        {/* <NavigerenPijl onClick={handleNavigeren} /> */}
                      </div>
                    ))}
                  </div>
                );
              }}
            />

            <DataTypeProvider
              for={['__totaalGefactureerd']}
              formatterComponent={(props) => {
                const rij: IOpdracht = props.row;

                const gekoppeldeFacturenVoorOpdracht = gekoppeldeFacturen.filter(
                  (x) => x.TrsOpdID === rij.TrsOpdID,
                );

                const bedrag = gekoppeldeFacturenVoorOpdracht.reduce(
                  (prev, curr) => prev + curr.BedragTeFactureren!,
                  0,
                );

                return <span>{formatteerBedrag(bedrag)}</span>;
              }}
            />

            <DataTypeProvider
              for={['FacturatieBeoordeeldOp']}
              formatterComponent={(props) => {
                const rij: IOpdracht = props.row;

                return (
                  <span>
                    {props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy HH:mm') : ''}
                  </span>
                );
              }}
            />

            <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

            <Table />
            <TableColumnResizing />
            <TableHeaderRow />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
          </Grid>
        </GridStyleWrapper>
      </div>
    </>
  );
};

export default OpdrachtenDetailComp;
