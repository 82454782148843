import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import MenuLayout from '../../../components/MenuLayout';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import nameOf from '../../../core/nameOf';
import { format } from 'date-fns';
import ASPTabel from '../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import { IOphalenFeestdagenResultElement } from '../../../../../shared/src/api/v2/feestdag';
import { EResultType } from '../../../stores/CheckStore';
import { locale } from 'moment';
import { nl } from 'date-fns/locale';
import MutatieDialoog from './MutatieDialoog';

interface IProps extends RouteComponentProps {}

enum ETabelKolom {
  Datum,
  Naam,
  TijdOpenVan,
  TijdOpenTot,
}

export interface IToevoegenDialoogState {}
export interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
  toevoegenDialoogState: IToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenDialoogState: null,
  toevoegenDialoogState: null,
};

const Feestdagen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [feestdagen, setFeestdagen] = useState<IOphalenFeestdagenResultElement[] | null>(null);

  const ophalenFeestdagen = useCallback(async () => {
    const feestdagenResult = await api.v2.stambeheer.feestdag.ophalenFeestdagen({
      filterSchema: {
        filters: [],
      },
      orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
    });
    setFeestdagen(feestdagenResult.feestdagen);
  }, []);

  useEffect(() => {
    ophalenFeestdagen();
  }, [ophalenFeestdagen]);

  const tabelKolommen = useMemo<Array<ASPKolom<ETabelKolom, IOphalenFeestdagenResultElement>>>(
    () => [
      {
        key: ETabelKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => format(new Date(rij.Datum), 'dd-MM-yyyy EEEEEE', { locale: nl }),
      },
      {
        key: ETabelKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => rij.Naam,
      },
      // {
      //   key: ETabelKolom.TijdOpenVan,
      //   label: 'Open vanaf',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 200,
      //   renderer: (rij) => rij.TijdOpenVan,
      // },
      // {
      //   key: ETabelKolom.TijdOpenTot,
      //   label: 'Open tot',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 200,
      //   renderer: (rij) => rij.TijdOpenTot,
      // },
    ],
    [],
  );

  const handleWijzigenRij = useCallback(async (rij: IOphalenFeestdagenResultElement) => {
    setUrlStateSync('wijzigenDialoogState', { id: rij.ID });
  }, []);

  return (
    <>
      {feestdagen === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={false}
                onClick={() => {
                  setUrlStateSync('toevoegenDialoogState', {});
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Toevoegen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const params = { ids: urlState.selectie };

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: <span>Geselecteerde feestdagen verwijderen?</span>,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  await api.v2.stambeheer.feestdag.verwijderenFeestdagen(params);
                  ophalenFeestdagen();
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>
          }
          body={
            <>
              <ASPTabel
                keyExtractor={(rij) => rij.ID}
                rijen={feestdagen}
                kolommen={tabelKolommen}
                selectie={urlState.selectie}
                onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                onWijzigenRij={handleWijzigenRij}
                lokaalSorteren
              />
            </>
          }
        />
      )}

      {urlState.toevoegenDialoogState !== null && (
        <MutatieDialoog
          open
          id={null}
          onSuccess={() => {
            ophalenFeestdagen();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}
      {urlState.wijzigenDialoogState !== null && (
        <MutatieDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={() => {
            ophalenFeestdagen();
            setUrlStateSync('wijzigenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
});

export default Feestdagen;
