import React from 'react';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { Kleur } from '../../bedrijfslogica/constanten';
import ToggleButton from 'react-bootstrap/ToggleButton';
import classNames from 'classnames';

export interface IWeergave<TKey> {
  key: TKey;
  naam?: string;
  icon?: JSX.Element;
  tooltip?: string;
}

interface IProps<TKey> {
  weergave: TKey;
  onChange: (key: TKey) => void;
  weergaven: IWeergave<TKey>[];
}

const WeergaveKeuze = <TKey extends number | string>(props: IProps<TKey>) => {
  const { onChange, weergave, weergaven } = props;
  return (
    <ToggleButtonGroup
      type="radio"
      name="x"
      value={weergave}
      onChange={(x: TKey) => onChange(x)}
      style={{ border: `1px solid ${Kleur.LichtGrijs}`, borderRadius: 5 }}
    >
      {weergaven.map((item) => {
        return (
          <ToggleButton
            key={item.key}
            value={item.key}
            className="btn-light dx-g-bs4-cursor-pointer d-flex align-items-center"
            title={item.tooltip}
          >
            {item.icon}
            {item.naam !== undefined && (
              <span className={classNames([item.icon !== undefined && 'ml-2'])}>{item.naam}</span>
            )}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default WeergaveKeuze;
