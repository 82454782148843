import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenBlogCategoriesInternResultElement } from '../../../../../../shared/src/api/v2/blog';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import ZoektermFilter from './ZoektermFilter';

type Kolom = 'naam' | 'subtitel';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Item: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [categorieen, setCategorieen] = useState<
    IOphalenBlogCategoriesInternResultElement[] | null
  >(null);
  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );

  const [filterschema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('toevoegenDialoogState', {});
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { IDs: urlState.selectie };

    if (params.IDs.length == 0) return;

    const checkData = await api.v2.blog.checkVerwijderenBlogCategorie(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde items verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.blog.verwijderenBlogCategorie(params);
    setUrlStateSync('selectie', []);

    await ophalenCategorieen();
  }, [urlState.selectie]);

  const ophalenCategorieen = useCallback(async () => {
    const result = await api.v2.blog.ophalenBlogCategorie({
      filterschema,
    });
    setCategorieen(result.categorieen);
  }, [filterschema.filters]);

  useEffect(() => {
    ophalenCategorieen();
  }, [ophalenCategorieen]);

  const ophalenTeksten = useCallback(async () => {
    if (categorieen == null) {
      return;
    }
    const tekstIDs = [
      ...categorieen.map((x) => x.Naam_TekstID).filter((x) => x != null),
      ...categorieen.map((x) => x.Subtitel_TekstID).filter((x) => x != null),
    ];

    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(resultTeksten.teksten);
  }, [categorieen]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten, categorieen]);

  const keyExtractor = useCallback((row: IOphalenBlogCategoriesInternResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IOphalenBlogCategoriesInternResultElement>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Naam_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
      {
        key: 'subtitel',
        label: 'Subtitel',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Subtitel_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
    ],
    [teksten],
  );

  return (
    <>
      <Helmet>
        <title>Website Blog Categorieen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => {
                  setUrlStateSync('filterdata', x);
                }}
                onFilterSchemaChange={(x) => {
                  setFilterSchema(x);
                }}
              />
            </div>
          </div>
        }
        body={
          categorieen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={categorieen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              onWijzigenRij={async (rij) => {
                setUrlStateSync('wijzigenDialoogState', { id: rij.ID });
              }}
              // onVerwijderenRij={handleVerwijderen}
              verwijderenRijConfirmatie={null}
            />
          )
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('toevoegenDialoogState', null);
            await ophalenCategorieen();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            await ophalenCategorieen();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Item);
