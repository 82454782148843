import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IOphalenBoekingenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../components/MenuLayout';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { format } from 'date-fns';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
}

enum EKolom {
  Boekingnummer,
  Boekdatum,
  Dagboeksoort,
  Dagboeknaam,
  Dagboeknummer,
}

const NietGeexporteerd: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [boekingen, setBoekingen] = useState<IOphalenBoekingenResultElement[] | null>(null);

  const ophalenBoekingen = useCallback(async () => {
    const peildatum = new Date();

    const result = await api.v2.boekhouding.boeking.ophalenBoekingen({
      filterSchema: {
        filters: [
          { naam: 'IS_GEEXPORTEERD', data: false },
          { naam: 'NIET_EXPORTEREN', data: false },
          { naam: 'IS_DEFINITIEF', data: true },
          { naam: 'BOEKDATUM_TEM', data: '2023-05-01' },
          //   { naam: 'BOEKDATUM_VAN', data: '2023-01-01' },
        ],
      },
      orderSchema: {
        orders: [
          { naam: 'DAGBOEKSOORT', richting: 'DESC' },
          { naam: 'DAGBOEKNAAM', richting: 'DESC' },
        ],
      },
    });

    setBoekingen(result.boekingen);
  }, []);

  useEffect(() => {
    ophalenBoekingen();
  }, [ophalenBoekingen]);

  const keyExtractor = useCallback((item: IOphalenBoekingenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenBoekingenResultElement>[]>(
    () => [
      {
        key: EKolom.Boekingnummer,
        label: 'Boeknr',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IOphalenBoekingenResultElement) => item.Boeknummer,
      },
      {
        key: EKolom.Dagboeksoort,
        label: 'Dagboeksoort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (item: IOphalenBoekingenResultElement) => item.dagboek.dagboekSoort.Naam,
      },
      {
        key: EKolom.Dagboeknummer,
        label: 'Dagboek',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IOphalenBoekingenResultElement) => item.dagboek.Nummer,
      },
      {
        key: EKolom.Dagboeknaam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (item: IOphalenBoekingenResultElement) => item.dagboek.Naam,
      },
      {
        key: EKolom.Boekdatum,
        label: 'Boekdatum',
        breedteType: EAspKolomBreedteType.Flex,
        // vasteBreedte: 350,
        flex: 1,
        renderer: (item: IOphalenBoekingenResultElement) =>
          format(new Date(item.Boekdatum), 'dd-MM-yyyy'),
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Niet geexporteerd</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Berichten versturen?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.indexering.versturenBerichten({
                    indexeringBerichtIDs: urlState.selectie,
                  });

                  setUrlStateSync('selectie', []);

                  ophalenBerichten();
                }}
              >
                <IconEmail style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Versturen</span>
              </button> */}

              {/* <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                onFilterSchemaChange={setFilterSchema}
              /> */}
            </div>

            {/* <div className="d-flex align-items-center mt-3">
              <SelectieVak
                totaalAantal={berichten.length}
                aantal={urlState.selectie.length}
                onChange={(allesGeselecteerd) => {
                  if (allesGeselecteerd) {
                    setUrlStateSync('selectie', berichten.map(keyExtractor));
                  } else {
                    setUrlStateSync('selectie', []);
                  }
                }}
              />
            </div> */}
          </>
        }
        body={
          boekingen === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <ASPTabel
                rijen={boekingen}
                kolommen={kolommen}
                keyExtractor={keyExtractor}
                selectie={urlState.selectie}
                onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                // sortering={urlState.sortering}
                // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                // onWijzigenRij={handleWijzigenRij}
                // onVerwijderenRij={handleVerwijderen}
                // verwijderenRijConfirmatie={verwijderenRijConfirmatie}
                // rijHoogte={200}
                lokaalSorteren
                leegComponent={(leegProps) => (
                  <div
                    style={{
                      width: leegProps.width,
                      height: leegProps.height,
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <h4>Geen boekingen gevonden</h4>
                  </div>
                )}
              />
            </>
          )
        }
      />
    </>
  );
});

export default withRouter(NietGeexporteerd);
