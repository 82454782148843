import {
  OphalenRedenenAnnuleringResult,
  IOphalenKostenAnnuleringParams,
  IOphalenKostenAnnuleringResult,
  ICheckAnnulerenContractenParams,
  IAnnulerenContractenParams,
  IAnnulerenContractenResult,
  ITerugdraaienAnnulerenContractenParams,
  ITerugdraaienAnnulerenContractenResult,
  IVersturenBevestigingAnnuleringParams,
  IVersturenBevestigingAnnuleringResult,
} from '../../../../../shared/src/api/v2/contract/annulering';
import api from '../..';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const annulering = {
  checkAnnulerenContracten: async (
    params: ICheckAnnulerenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/annulering/check-annuleren-contracten', params);
  },
  annulerenContracten: async (
    params: IAnnulerenContractenParams,
  ): Promise<IAnnulerenContractenResult> => {
    return await api.post('/v2/contract/annulering/annuleren-contracten', params);
  },
  checkTerugdraaienAnnulerenContracten: async (
    params: ITerugdraaienAnnulerenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/contract/annulering/check-terugdraaien-annuleren-contracten',
      params,
    );
  },
  terugdraaienAnnulerenContracten: async (
    params: ITerugdraaienAnnulerenContractenParams,
  ): Promise<ITerugdraaienAnnulerenContractenResult> => {
    return await api.post('/v2/contract/annulering/terugdraaien-annuleren-contracten', params);
  },
  ophalenRedenenAnnulering: async (params: any): Promise<OphalenRedenenAnnuleringResult> => {
    return await api.post('/v2/contract/annulering/ophalen-redenen-annulering', params);
  },
  ophalenKostenAnnulering: async (
    params: IOphalenKostenAnnuleringParams,
  ): Promise<IOphalenKostenAnnuleringResult> => {
    return await api.post('/v2/contract/annulering/ophalen-kosten', params);
  },
  checkVersturenBevestigingAnnulering: async (
    params: IVersturenBevestigingAnnuleringParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/annulering/check-versturen-bevestiging-annulering', params);
  },
  versturenBevestigingAnnulering: async (
    params: IVersturenBevestigingAnnuleringParams,
  ): Promise<IVersturenBevestigingAnnuleringResult> => {
    return await api.post('/v2/contract/annulering/versturen-bevestiging-annulering', params);
  },
};

export default annulering;
