import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { IOphalenBestandenResult } from '../../../../../../../shared/src/api/v2/bestand/bestand';
import {
  IOphalenBlogCategoriesInternResultElement,
  IOphalenBlogitemsInternResultElement,
  IWijzigenBlogitemParams,
} from '../../../../../../../shared/src/api/v2/blog';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../shared/src/api/v2/tekst';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import Combobox, { IOptie } from '../../../../../components/formulier/Combobox';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameOf from '../../../../../core/nameOf';
import { dagDatum } from '../../../../../helpers/datum';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import BijlageKiezer from '../../../../../components/formulier/BijlageKiezer';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  ID: number;
  datum: string | Date;
  titel: ITaalTekst[];
  inhoud: ITaalTekst[];
  trefwoorden: ITaalTekst[];
  slug: ITaalTekst[];
  categorie: number | null;
  bestandID: number | undefined;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ID: 'ID',
  datum: 'Publicatiedatum',
  titel: 'Vraag',
  inhoud: 'Inhoud',
  trefwoorden: 'Trefwoorden',
  slug: 'Slug',
  categorie: 'Categorie',
  bestandID: 'Afbeelding',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [item, setBlogitem] = useState<IOphalenBlogitemsInternResultElement | null>(null);
  const [categorieen, setCategorieen] = useState<
    IOphalenBlogCategoriesInternResultElement[] | null
  >(null);
  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const [bestanden, setBestandenResult] = useState<IRemoteData<IOphalenBestandenResult | null>>(
    createPendingRemoteData(),
  );

  const ophalenblog = useCallback(async () => {
    const result = await api.v2.blog.ophalenBlogitem({
      filterschema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    setBlogitem(result.blogitems[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenblog();
  }, [ophalenblog]);

  const ophalenTeksten = useCallback(async () => {
    if (item == null || categorieen == null) {
      if (teksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [
      item.Titel_TekstID,
      item.Inhoud_TekstID,
      item.Trefwoorden_TekstID,
      item.Slug_TekstID,
      ...categorieen.map((x) => x.Naam_TekstID).filter((x) => x !== null),
    ].filter((x) => x !== null) as number[];

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(createReadyRemoteData(result));
  }, [item, categorieen]);

  const ophalenBestanden = useCallback(async () => {
    if (item === null) {
      return;
    }

    if (item.BestandID === null) {
      setBestandenResult(createReadyRemoteData(null));
      return;
    }

    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: item?.BestandID,
          },
        ],
      },
    });

    setBestandenResult(createReadyRemoteData(result));
  }, [item]);

  const ophalenCategorieen = useCallback(async () => {
    const result = await api.v2.blog.ophalenBlogCategorie({});

    setCategorieen(result.categorieen);
  }, []);

  useEffect(() => {
    ophalenCategorieen();
  }, [ophalenCategorieen]);

  useEffect(() => {
    ophalenBestanden();
  }, [ophalenBestanden]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const ophalenCategorieOpties = useCallback(() => {
    console.log('opties ophalen:');
    if (categorieen === null) return [];
    const result: IOptie<number>[] =
      categorieen == null
        ? []
        : categorieen
            .map((cat) => {
              return {
                id: cat.ID,
                label:
                  cat.Naam_TekstID == null
                    ? ''
                    : teksten.data!.teksten.find((x) => x.TekstID === cat.Naam_TekstID)!.Tekst!,
              };
            })
            .filter((optie) => optie.label != '');
    return result;
  }, [categorieen, teksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (item === null) {
        return;
      }

      actions.setSubmitting(true);

      const params: IWijzigenBlogitemParams = {
        ID: item.ID,
        Datum: values.datum,
        Titel: {
          tekstID: item.Titel_TekstID,
          taalTeksten: values.titel,
        },
        Inhoud: {
          tekstID: item.Inhoud_TekstID,
          taalTeksten: values.inhoud,
        },
        Trefwoorden: {
          tekstID: item.Trefwoorden_TekstID,
          taalTeksten: values.trefwoorden,
        },
        Slug: {
          tekstID: item.Slug_TekstID,
          taalTeksten: values.slug,
        },
        BlogItemCategorieID: values.categorie,
        BestandID: values.bestandID,
      };

      const checkData = await api.v2.blog.checkWijzigenBlogitem(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.blog.wijzigenBlogitem(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (
      item === null ||
      teksten.state === ERemoteDataState.Pending ||
      bestanden.state == ERemoteDataState.Pending ||
      categorieen == null
    ) {
      return null;
    }
    const titelTeksten =
      item.Titel_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === item.Titel_TekstID);

    const inhoudTeksten =
      item.Inhoud_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === item.Inhoud_TekstID);

    const trefwoordenTeksten =
      item.Inhoud_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === item.Trefwoorden_TekstID);

    const slugTeksten =
      item.Inhoud_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === item.Slug_TekstID);

    const datum: Date = item.Datum instanceof Date ? dagDatum(item.Datum) : new Date(item.Datum);
    return {
      ID: item.ID,
      datum: datum,
      titel:
        titelTeksten === null
          ? []
          : titelTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      inhoud:
        inhoudTeksten === null
          ? []
          : inhoudTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      trefwoorden:
        trefwoordenTeksten === null
          ? []
          : trefwoordenTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      slug:
        slugTeksten === null
          ? []
          : slugTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      categorie: item.BlogItemCategorieID,
      bestandID: item.BestandID,
    };
  }, [item, teksten, bestanden, categorieen]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen Blog Item</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.datum}</label>
                        <Field
                          name={nameOf<IFormikValues>('datum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Titel</label>
                        <Field
                          name={nameOf<IFormikValues>('titel')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                // defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Inhoud</label>
                        <Field
                          name={nameOf<IFormikValues>('inhoud')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={
                                  fieldProps.field.value != null ? fieldProps.field.value : []
                                }
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                // defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Trefwoorden</label>
                        <Field
                          name={nameOf<IFormikValues>('trefwoorden')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={
                                  fieldProps.field.value != null ? fieldProps.field.value : []
                                }
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                // defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Slug</label>
                        <Field
                          name={nameOf<IFormikValues>('slug')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={
                                  fieldProps.field.value != null ? fieldProps.field.value : []
                                }
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                // defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Afbeelding</label>
                        <Field
                          name={nameOf<IFormikValues>('bestandID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <BijlageKiezer
                                bestand={bestanden.data?.bestanden[0]}
                                onBestIDChange={async (bestID) => {
                                  console.log('bestand veranderd:' + bestID);
                                  fieldProps.form.setFieldValue(fieldProps.field.name, bestID);
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Categorie</label>
                        <Field
                          name={nameOf<IFormikValues>('categorie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <Combobox
                                geselecteerd={fieldProps.field.value}
                                opties={ophalenCategorieOpties()}
                                onSelectieChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
