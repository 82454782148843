import {
  IOphalenAanbodParams,
  IOphalenAanbodResult,
} from '../../../../../shared/src/api/v2/aanbod/index';

import {
  IOphalenProductmodelParams,
  IOphalenProductmodelResult,
} from '../../../../../shared/src/api/v2/aanbod/productmodel';

import api from '../..';
import tarieven from './tarieven';
import productmodel from '../product/model';
import specificatie from './specificatie';
import verkoop from './verkoop';

const aanbod = {
  productmodel,
  tarieven,
  specificatie,
  verkoop,

  ophalenProductmodel: async (params: IOphalenProductmodelParams) => {
    return await api.post<IOphalenProductmodelResult>(
      '/v2/aanbod/productmodel/ophalen-productmodel',
      params,
    );
  },
  ophalenAanbod: async (params: IOphalenAanbodParams): Promise<IOphalenAanbodResult> => {
    return await api.post('/v2/aanbod/ophalen-aanbod', params);
  },
};

export default aanbod;
