import * as React from 'react';
import styled from 'styled-components';
import Chats from './Chats';
import Conversatie from './Conversatie';
import { useWhatsappV2Store } from './store';
import { useEffect } from 'react';
import { ZoekenFocus } from './helpers';
import { EWhatsappTabblad } from './types';

const Root = styled.div`
  flex: 1;
  display: flex;
`;

interface IProps {
  tabblad: EWhatsappTabblad;
  onTabbladChange: (tabblad: EWhatsappTabblad) => void;
  chatsessieID: number | null;
  onChatsessieIDChange: (chatsessieID: number | null) => void;
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  zoekenFocus: ZoekenFocus | null;
  onZoekenFocusChange: (zoekenFocus: ZoekenFocus | null) => void;
}

const WhatsAppWerkbladV2 = (props: IProps) => {
  const store = useWhatsappV2Store();
  useEffect(() => {
    if (!store.isGeinitialiseerd) {
      // noinspection JSIgnoredPromiseFromCall
      store.initialiseer(props.chatsessieID);
    } else {
      // noinspection JSIgnoredPromiseFromCall
      store.initialiseerChatsessie(props.chatsessieID);
    }
  }, [props.chatsessieID]);

  return (
    <Root>
      <Chats
        tabblad={props.tabblad}
        onTabbladChange={props.onTabbladChange}
        chatsessieID={props.chatsessieID}
        onChatsessieIDChange={props.onChatsessieIDChange}
        zoekterm={props.zoekterm}
        onZoektermChange={props.onZoektermChange}
        zoekenFocus={props.zoekenFocus}
        onZoekenFocusChange={props.onZoekenFocusChange}
      />
      <Conversatie chatsessieID={props.chatsessieID} tabblad={props.tabblad} />
    </Root>
  );
};

export default WhatsAppWerkbladV2;
