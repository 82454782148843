import React, { useMemo } from 'react';
// import { Root } from './Opdrachten/style';
import { RouteComponentProps, withRouter } from 'react-router';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Premiums from './Premiums';

export enum ETabblad {
  Premiums = 1,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Premiums]: '/premiums',
};

interface IProps extends RouteComponentProps {}

const Opdrachten: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Premiums]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Premiums,
        label: 'Premiums',
        content: Premiums,
      },
    ],
    [],
  );

  return (
    <>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </>
  );
};

export default withRouter(Opdrachten);
