import React from 'react';
import TableData from '../TableData';
import { IconDragHandle, IconInklappen, IconUitklappen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { SortableHandle } from 'react-sortable-hoc';

export interface IDragHandleTableDataProps<TRow> {
  row?: TRow;
  isGefocust?: boolean;
  isGehovered?: boolean;
}

const DragHandle = SortableHandle(() => (
  <div className="flex-fill d-flex align-items-center justify-content-center">
    <IconDragHandle style={{ width: 21, height: 21, fill: Kleur.Grijs }} />
  </div>
));

const StandaardDragHandleTableData = <TRow extends any>(props: IDragHandleTableDataProps<TRow>) => {
  // const Icon = props.heeftUitgeklapteRijOnderZich ? IconInklappen : IconUitklappen;

  return (
    <TableData
      tableKey={null}
      style={{ padding: 0, display: 'flex', cursor: 'grab' }}
      isGehovered={props.isGehovered}
      isGefocust={props.isGefocust}
      rowIndex={-1}
    >
      <DragHandle />
    </TableData>
  );
};

export default StandaardDragHandleTableData;
