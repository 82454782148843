import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IOphalenFacturenResultElement,
  ISponsorcontract,
} from '../../../../../../../../shared/src/api/v2/inkoopfactuur';
import api from '../../../../../../api';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import VeldWeergave from '../../../../../../components/formulier/VeldWeergave';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { IconBetaling, IconToevoegen, IconVerwijderen } from '../../../../../../components/Icons';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import SelectieVak from '../../../../../../components/SelectieVak';
import ContractSelectieDialoog from '../../../../../../components/sponsoring/ContractSelectieDialoog';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../core/nameOf';
import useUrlState from '../../../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import WijzigenBedragGekoppeldDialoog from './WijzigenBedragGekoppeldDialoog';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  inkFactID: number;
}

export interface IWijzigenBedragGekoppeldDialoogState {
  bedragGekoppeld: number;
}
export interface IKoppelContractenDialoogState {
  factuur: IOphalenFacturenResultElement;
}

const urlStateKey = 'contractenSelectie';

export interface IUrlState {
  selectie: number[];
  wijzigenBedragGekoppeldDialoogState: IWijzigenBedragGekoppeldDialoogState | null;
  koppelContractenDialoogState: IKoppelContractenDialoogState | null;
}
const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenBedragGekoppeldDialoogState: null,
  koppelContractenDialoogState: null,
};

export interface IKoppelenContractenDialoogContext {
  factuur: IOphalenFacturenResultElement | null;
}
export const KoppelContractenDialoogContext = React.createContext<
  IKoppelenContractenDialoogContext
>(null as any);

export interface IRegel extends ISponsorcontract {}

const KoppelContractenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);
  const { checkStore } = useContext(RootStoreContext);

  const [factuur, setFactuur] = useState<IOphalenFacturenResultElement | null>(null);
  const [sponsorcontracten, setSponsorcontracten] = useState<ISponsorcontract[] | null>(null);

  // Inkoopfactuur
  useEffect(() => {
    (async () => {
      const facturenResultaat = await api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.inkFactID] }] },
      });
      const factuur = facturenResultaat.facturen[0];

      setFactuur(factuur);
    })();
  }, [props.inkFactID]);

  // Haal de gekoppelde contracten voor deze factuur op
  const ophalenContracten = useCallback(async () => {
    const result = (
      await api.v2.inkoopfactuur.ophalenSponsorcontractenVoorFacturen({
        inkFactIDs: [props.inkFactID],
      })
    ).inkoopfacturen;

    const sponsorcontracten = result[0].sponsorcontracten;

    // const bedragGekoppeld = _.sum(producten.map((x) => x.Bedrag || 0)).toFixed(2);
    // setBedragGekoppeld(bedragGekoppeld);

    setSponsorcontracten(sponsorcontracten);
  }, [props.inkFactID]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const context = useMemo<IKoppelenContractenDialoogContext>(() => {
    return {
      factuur,
    };
  }, [factuur]);

  const kolommenContracten = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Registratiedatum',
        title: 'Reg.datum',
      },
      {
        name: 'Contractdatum',
        title: 'Cnt.datum',
      },
      {
        name: 'BedragGekoppeld' as any,
        title: 'Bedrag',
      },
      {
        name: 'Inhoud',
        title: 'Inhoud',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Registratiedatum',
        width: 110,
      },
      {
        columnName: 'Contractdatum',
        width: 110,
      },
      {
        columnName: 'BedragGekoppeld',
        width: 100,
      },
      {
        columnName: 'Inhoud',
        width: 300,
      },
    ],
    [],
  );

  const wijzigenBedragGekoppeld = useCallback(
    async (sponCntIDs: number[]) => {
      if (sponsorcontracten === null) {
        return;
      }

      // Bepaal welke bedrag voorgevuld moet gaan worden
      const bedragen = _.uniq(
        sponsorcontracten
          .filter((x) => urlState.selectie.indexOf(x.ID) !== -1)
          .map((x) => x.BedragGekoppeld),
      );
      const bedrag = bedragen.length === 1 ? bedragen[0] : 0;

      setUrlStateSync('wijzigenBedragGekoppeldDialoogState', { bedragGekoppeld: bedrag });
    },
    [onSuccess, sponsorcontracten],
  );

  const verwijderenContracten = useCallback(
    async (prodIDs: number[]) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Gekoppelde contracten verwijderen?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const params = { inkFactID: props.inkFactID, prodIDs };

      // await api.v2.inkoopfactuur.verwijderenGekoppeldeProducten(params);

      setUrlStateSync('selectie', []);
      ophalenContracten();
    },
    [onSuccess],
  );

  return (
    <>
      <KoppelContractenDialoogContext.Provider value={context}>
        <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
          <ModalHeader>
            <ModalTitle>Koppelen sponsorcontracten</ModalTitle>
          </ModalHeader>

          <ModalBody>
            {factuur === null || sponsorcontracten === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="form-group">
                <div className="row">
                  <div className="col-12">
                    <VeldWeergave>
                      <div className="d-flex">
                        <div style={{ width: 375 }}>
                          <GegevensLayout
                            gegevens={[
                              {
                                label: 'Fact.nr.',
                                waarde: `${factuur.Factuurnummer}`,
                              },
                              {
                                label: 'Factuurdatum',
                                waarde: `${format(new Date(factuur.Factuurdatum), 'dd-MM-yyyy')}`,
                              },
                              {
                                label: 'Fact.bedrag',
                                waarde: <FormatteerBedrag bedrag={factuur.Bedrag} />,
                              },
                              {
                                label: 'Fact.bedrag Excl. btw',
                                waarde: (
                                  <FormatteerBedrag bedrag={factuur.Bedrag - factuur.BedragBtw} />
                                ),
                              },
                            ]}
                          />
                        </div>
                        <div style={{ width: 225 }}>
                          <GegevensLayout
                            gegevens={[
                              {
                                label: 'Bedrag gekoppeld',
                                waarde: (
                                  <FormatteerBedrag
                                    bedrag={_.sum(sponsorcontracten.map((x) => x.BedragGekoppeld))}
                                  />
                                ),
                              },
                              {
                                label: 'Aantal gekop. cnt.',
                                waarde: `${sponsorcontracten.length}`,
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div style={{ width: 500 }} className="mt-3">
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Crediteur',
                              waarde: (
                                <span>
                                  <RelatieVisualisatie relID={factuur.RelID} />
                                </span>
                              ),
                            },
                            {
                              label: 'Onderwerp',
                              waarde: `${factuur.Onderwerp}`,
                            },
                          ]}
                        />
                      </div>
                    </VeldWeergave>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-light d-flex align-items-center"
                        disabled={!true}
                        style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                        onClick={() => setUrlStateSync('koppelContractenDialoogState', { factuur })}
                      >
                        <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                        <span className="ml-2">Toevoegen</span>
                      </button>

                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        disabled={urlState.selectie.length === 0}
                        style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                        onClick={() => verwijderenContracten(urlState.selectie)}
                      >
                        <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                        <span className="ml-2">Verwijderen</span>
                      </button>

                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        disabled={urlState.selectie.length === 0}
                        style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                        onClick={() => {
                          wijzigenBedragGekoppeld(urlState.selectie);
                        }}
                      >
                        <IconBetaling style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                        <span className="ml-2">Wijzigen bedrag</span>
                      </button>
                    </div>
                    <div className="mt-3">
                      <SelectieVak
                        aantal={urlState.selectie.length}
                        totaalAantal={sponsorcontracten.length}
                        onChange={(alles) => {
                          if (alles) {
                            setUrlStateSync(
                              'selectie',
                              sponsorcontracten.map((x) => x.ID),
                            );
                          } else {
                            setUrlStateSync('selectie', []);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="d-flex flex-column flex-fill mt-2">
                      <>
                        <GridStyleWrapper maxHeight={500} rowAmount={sponsorcontracten.length}>
                          <Grid
                            getRowId={(x) => x.ID}
                            rows={sponsorcontracten}
                            columns={kolommenContracten}
                          >
                            <DataTypeProvider
                              for={['Registratiedatum']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRegel = formatterProps.row;
                                return (
                                  <span>
                                    {format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                                  </span>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={['Contractdatum']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRegel = formatterProps.row;

                                if (formatterProps.value === null) {
                                  return <span></span>;
                                }
                                return (
                                  <span>
                                    {format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                                  </span>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={[nameOf('BedragGekoppeld')]}
                              formatterComponent={(formatterProps) => {
                                const rij: IRegel = formatterProps.row;
                                return <FormatteerBedrag bedrag={formatterProps.value} />;
                              }}
                            />

                            <EditingState
                              onAddedRowsChange={() => null}
                              onCommitChanges={async (changes) => {
                                if (changes.deleted === undefined) {
                                  return;
                                }
                                const deleted = changes.deleted;
                                const id = deleted[deleted.length - 1] as number;
                                alert(id);
                              }}
                              onEditingRowIdsChange={(rowIds) => {
                                const id = rowIds[rowIds.length - 1] as number;
                              }}
                            />

                            <SortingState defaultSorting={[]} />
                            <IntegratedSorting />
                            <Table messages={{ noData: 'Geen gekoppelde contracten' }} />
                            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                            {/* <TableEditColumn
                                    width={35}
                                    // showAddCommand
                                    showEditCommand
                                    // showDeleteCommand
                                    cellComponent={DXTableEditColumnCellComponent}
                                    commandComponent={DXTableEditColumnCommandComponent}
                                  /> */}
                            <TableHeaderRow showSortingControls />

                            <SelectionState
                              selection={urlState.selectie}
                              onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                            />
                            <TableSelection cellComponent={DXTableCheckboxComponent} />
                          </Grid>
                        </GridStyleWrapper>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>

          <ModalFooter className="d-flex flex-row justify-content-start">
            <button
              className="btn btn-primary"
              onClick={onAnnuleren}
              style={{ width: 100 }}
              // disabled={isSubmitting}
            >
              Sluiten
            </button>
          </ModalFooter>
        </Dialoog>
      </KoppelContractenDialoogContext.Provider>

      {urlState.wijzigenBedragGekoppeldDialoogState !== null && (
        <WijzigenBedragGekoppeldDialoog
          open
          inkoopprijs={urlState.wijzigenBedragGekoppeldDialoogState.bedragGekoppeld}
          onSuccess={async (data) => {
            await api.v2.inkoopfactuur.wijzigenBedragGekoppeldSponsorcontract({
              inkFactID: props.inkFactID,
              sponCntIDs: urlState.selectie,
              bedrag: data.inkoopprijs,
            });
            setUrlStateSync('wijzigenBedragGekoppeldDialoogState', null);
            ophalenContracten();
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenBedragGekoppeldDialoogState', null);
          }}
        />
      )}

      {urlState.koppelContractenDialoogState !== null && (
        <ContractSelectieDialoog
          open
          factuur={factuur!}
          onSuccess={(data) => {
            setUrlStateSync('koppelContractenDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('koppelContractenDialoogState', null);
          }}
        />
      )}
    </>
  );
};

export default withRouter(KoppelContractenDialoog);
