import {
  IOphalenProductenParams,
  IOphalenProductenResult,
} from '../../../../../../shared/src/api/v2/retourverwerking/retourLeverancier';
import api from '../../../index';

const retourLeverancier = {
  ophalenProducten: async (params: IOphalenProductenParams): Promise<IOphalenProductenResult> => {
    return await api.post('/v2/retourverwerking/retour-leverancier/ophalen-producten', params);
  },
};

export default retourLeverancier;
