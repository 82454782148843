import { ILeegComponentProps } from '../../../tabel/ASPTabel/Body';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useContext } from 'react';
import { TabelWeergaveContext } from '..';

const LeegComponent = (props: ILeegComponentProps) => {
  const { onBestandenDrop } = useContext(TabelWeergaveContext);

  const [{ isDropActive }, drop] = useDrop(
    () => ({
      accept: NativeTypes.FILE,
      collect: (monitor) => ({
        isDropActive: monitor.canDrop() && monitor.isOver(),
      }),
      canDrop: (object, monitor) => {
        return true;
      },
      async drop(object, monitor) {
        const files = (object as any).files as File[];
        await onBestandenDrop(files);
      },
    }),
    [onBestandenDrop],
  );

  return (
    <div
      ref={drop}
      style={{ width: props.width, height: props.height }}
      className="d-flex align-items-center justify-content-center"
    >
      <span>Er zijn geen bestanden of mappen.</span>
    </div>
  );
};

export default LeegComponent;
