import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, OpdrachtContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import nameOf from '../../../../core/nameOf';
import { IOphalenBezoektijdenResultElement } from '../../../../../../shared/src/api/v2/transport/bezoek';
import { IOphalenOpdrachteventsResultElement } from '../../../../../../shared/src/api/v2/transport/opdracht';
import { formatteerAdresV2 } from '../../../../helpers';
import { EOpdrachtStatus } from '../../../../paginas/Transport/Opdrachten';

const BezoektijdenTab: React.FC<IProps> = (props) => {
  const { opdracht, regels, bezoektijden, events, premiums } = useContext<IContext>(
    OpdrachtContext,
  );

  const kolommen = useMemo<TypedColumn<IOphalenBezoektijdenResultElement>[]>(
    () => [
      {
        name: 'Peildatum',
        title: 'Peildatum',
      },
      {
        name: 'Bezoekdatum',
        title: 'Bezoekdatum',
      },
      {
        name: 'BezoekdatumVan',
        title: 'Tijd van',
      },
      {
        name: 'BezoekdatumTot',
        title: 'Tijd tot',
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenBezoektijdenResultElement>[]>(
    () => [
      {
        columnName: 'Peildatum',
        width: 160,
      },
      {
        columnName: 'Bezoekdatum',
        width: 125,
      },
      {
        columnName: 'BezoekdatumVan',
        width: 100,
      },
      {
        columnName: 'BezoekdatumTot',
        width: 100,
      },
    ],
    [],
  );

  const kolommenEvents = useMemo<TypedColumn<IOphalenOpdrachteventsResultElement>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum/tijd',
      },
      {
        name: '__soort' as any,
        title: 'Naam',
      },
    ],
    [],
  );

  const kolombreedtesEvents = useMemo<
    TypedTableColumnWidthInfo<IOphalenOpdrachteventsResultElement>[]
  >(
    () => [
      {
        columnName: 'Datum',
        width: 135,
      },
      {
        columnName: '__soort' as any,
        width: 250,
      },
    ],
    [],
  );

  if (bezoektijden === null || events === null || opdracht === null || premiums === null) {
    return <span></span>;
  }

  const locatie = opdracht.locatie;

  return (
    <div className="d-flex flex-column">
      <div className="col-12 mt-3">
        <VeldWeergave>
          <div className="row">
            <div className="col-12">
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Bezoekdatum',
                    waarde: `${
                      opdracht.Bezoekdatum !== null
                        ? format(new Date(opdracht.Bezoekdatum), 'dd-MM-yyyy')
                        : ''
                    }`,
                  },
                  {
                    label: 'Tijd van',
                    waarde: `${
                      opdracht.BezoekdatumVan !== null
                        ? format(new Date(opdracht.BezoekdatumVan), 'HH:mm')
                        : ''
                    }`,
                  },
                  {
                    label: 'Tijd tot',
                    waarde: `${
                      opdracht.BezoekdatumTot !== null
                        ? format(new Date(opdracht.BezoekdatumTot), 'HH:mm')
                        : ''
                    }`,
                  },
                  {
                    label: 'Adres',
                    waarde: `${formatteerAdresV2({
                      bisnummer: locatie.Bisnummer,
                      straatnaam: locatie.Straatnaam,
                      huisnummer: locatie.Huisnummer,
                      postcode: locatie.Postcode,
                      plaatsnaam: locatie.Plaatsnaam,
                      landnaamKort: locatie.LandnaamKort,
                      landnaamEnum: locatie.LandnaamEnum,
                    })}`,
                  },
                  {
                    label: 'Bezoekinstructies',
                    waarde: `${
                      opdracht.Bezoekinstructies !== null ? opdracht.Bezoekinstructies : ''
                    }`,
                  },
                ]}
              />
            </div>
            <div className="col-6">
              {opdracht.BezoektijdenActueel ? <div>Tijden zijn actueel</div> : <div></div>}
              {opdracht.BezoektijdenNietAutomatischBijwerken ? (
                <div>Tijden worden niet aut. bijgewerkt</div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </VeldWeergave>
      </div>

      <div className="col-6 mt-3">
        <VeldWeergave>
          <GegevensLayout
            gegevens={[
              {
                label: 'Indicatie premiums',
                waarde:
                  opdracht.opdrachtstatus.Status === EOpdrachtStatus.Planning ? (
                    opdracht.dienst.PremiumLeveren ? (
                      <span>{opdracht.IndicatiePremium.Naam}</span>
                    ) : (
                      <span className="d-flex">
                        <span style={{ color: EKleur.Grijs }}>
                          ({opdracht.IndicatiePremium.Naam})
                        </span>
                      </span>
                    )
                  ) : (
                    <span>-</span>
                  ),
              },
              {
                label: 'Premiums',
                waarde: `${premiums.map((x) => x.Naam).join(', ')}`,
              },
            ]}
          />
        </VeldWeergave>
      </div>

      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <VeldWeergave>
          <div className="p-1 font-weight-bold">Opgehaalde bezoektijden</div>
          <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
            <GridStyleWrapper maxHeight={500} rowAmount={bezoektijden.length}>
              <Grid rows={bezoektijden} columns={kolommen}>
                <DataTypeProvider
                  for={[nameOf<IOphalenBezoektijdenResultElement>('Peildatum')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenBezoektijdenResultElement>('Bezoekdatum')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[
                    nameOf<IOphalenBezoektijdenResultElement>('BezoekdatumVan'),
                    nameOf<IOphalenBezoektijdenResultElement>('BezoekdatumTot'),
                  ]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span>Geen</span>;
                    }
                    return <span>{format(new Date(formatterProps.value), 'HH:mm')}</span>;
                  }}
                />

                <VirtualTable
                  columnExtensions={kolombreedtes}
                  messages={{ noData: 'Geen bezoektijden bekend' }}
                />
                <TableColumnResizing defaultColumnWidths={kolombreedtes} />
                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        </VeldWeergave>
      </div>

      <div className="mt-3 pl-3 pr-3 pt-2 mb-4">
        <VeldWeergave>
          <div className="p-1 font-weight-bold">Opdrachtevents</div>
          <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
            <GridStyleWrapper maxHeight={200} rowAmount={events.length}>
              <Grid rows={events} columns={kolommenEvents}>
                <DataTypeProvider
                  for={[nameOf<IOphalenOpdrachteventsResultElement>('Datum')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__soort']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenOpdrachteventsResultElement = formatterProps.row;
                    return <span>{rij.eventsoort.Naam}</span>;
                  }}
                />

                <VirtualTable
                  columnExtensions={kolombreedtes}
                  messages={{ noData: 'Geen events opgegeven' }}
                />
                <TableColumnResizing defaultColumnWidths={kolombreedtesEvents} />

                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        </VeldWeergave>
      </div>
    </div>
  );
};

export default BezoektijdenTab;
