import { makeObservable, observable } from 'mobx';

interface IOverlayState {
  zichtbaar: boolean;
}

class ProfielStore {
  public overlayState: IOverlayState = { zichtbaar: false };

  public setOverlayState = (zichtbaar: boolean) =>
    (this.overlayState = { ...this.overlayState, zichtbaar });

  constructor() {
    makeObservable(this, {
      overlayState: observable,
    });
  }
}

export default ProfielStore;
