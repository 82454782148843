import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import { IRow, ProductmodellenContext } from '../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import api from '../../../../../api';
import { Sorting } from '@devexpress/dx-react-grid';
import ProducttypesField from './ProducttypesField';
import HorizontaleScheidingslijn from '../../../../../components/layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import BijlagenContainer, {
  Bestand,
  BestandType,
  EWeergaveFocus,
} from '../../../../../components/BijlagenContainer';
import useUpload from '../../../../../core/useUpload';
import BijlageKnop from '../../../../../components/BijlageKnop';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../shared/src/api/v2/tekst';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import KenmerkenField from './KenmerkenField';

export interface IProducttype {
  prodTypeID: number;
  prioNr: number;
}

export interface IKenmerk {
  // Als het een string is dan is het een tijdelijk lokaal gedefinieerd ID
  prodModKenID: number | string;
  opOverzicht: boolean;
  teksten: ITaalTekst[];
  sortNr: number;
}

export interface IFormikValues {
  bestanden: Bestand[];
  producttypes: IProducttype[];
  kenmerken: IKenmerk[];
}

export interface IDetailRegelContext {
  values: IFormikValues;
  setFieldValue: (field: string, value: any) => void;
  producttypeSortering: Sorting[];
  onProducttypeSorteringChange: (sortering: Sorting[]) => void;
  kenmerkenSortering: Sorting[];
  onKenmerkensorteringChange: (sortering: Sorting[]) => void;
  prodSrtID: number;
  merkID: number | null;
}
export const DetailRegelContext = React.createContext<IDetailRegelContext>(null as any);

const DetailRegel = (props: TableRowDetail.ContentProps) => {
  const row: IRow = props.row;
  const productmodellenContext = useContext(ProductmodellenContext)!;
  const {
    bestanden,
    isBezigMetUploaden,
    muteerBestanden,
    uploadProgresses,
    upload,
    moetNogUploaden,
  } = useUpload(
    useMemo(() => {
      return {
        initieleBestanden: row.bestandenVoorModel.map((x) => {
          return {
            type: BestandType.ASPDrive as BestandType.ASPDrive,
            bestand: x.bestand,
          };
        }),
        automatischUploaden: false,
        onAlleBestandenGeuploaded: () => {},
      };
    }, []),
  );
  const [kenmerkenTeksten, setKenmerkenTeksten] = useState<IOphalenTekstenInAlleTalenResult | null>(
    null,
  );
  const ophalenKenmerkenTeksten = useCallback(async () => {
    if (row.kenmerken.length === 0) {
      setKenmerkenTeksten({
        teksten: [],
      });
      return;
    }

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs: row.kenmerken.map((x) => x.Kenmerk_TekstID),
    });
    setKenmerkenTeksten(result);
  }, [JSON.stringify(row.kenmerken.map((x) => x.Kenmerk_TekstID))]);

  useEffect(() => {
    ophalenKenmerkenTeksten();
  }, [ophalenKenmerkenTeksten, row]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const uploadResult = await upload();
      if (uploadResult === null) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.aanbod.productmodel.wijzigenProductmodel({
        aantalGebruikersMax: row.AantalGebruikersMax,
        aantalGebruikersTarief: row.AantalGebruikersTarief,
        actief: row.Actief,
        openbaarAanbod: row.OpenbaarAanbod,
        afbeeldingSet: row.AfbeeldingSet,
        specificatieSet: row.SpecificatieSet,
        afbeeldingen: uploadResult.map((x, i) => ({
          bestandID: x.ID,
          sortNr: i + 1,
        })),
        gebruiktProduct: row.GebruiktProduct,
        huismodel: row.Huismodel,
        kenmerk: row.Kenmerk,
        energielabelID: row.EnergielabelID,
        merkID: row.MerkID,
        modelcode: row.Modelcode,
        modelnaam: row.Modelnaam,
        prodModID: row.ProdModID,
        prodSrtID: row.ProdSrtID,
        producttypen: values.producttypes.map((type) => ({
          typeID: type.prodTypeID,
          prioNr: type.prioNr,
        })),
        kenmerken: values.kenmerken.map((kenmerk) => {
          return {
            prodModKenID:
              typeof kenmerk.prodModKenID === 'number' ? (kenmerk.prodModKenID as number) : null,
            opOverzicht: kenmerk.opOverzicht,
            sortNr: kenmerk.sortNr,
            teksten: kenmerk.teksten,
          };
        }),
        modelnaamExtern: row.modelnaamExtern,
        modelinfoExtern: row.modelinfoExtern,
        slug: row.slug,
        titel: row.titel,
        metadata: row.metadata,
        toelichting: row.toelichting,
      });

      setKenmerkenTeksten(null);
      await productmodellenContext.onVerversenAangevraagd();

      actions.setSubmitting(false);
    },
    [row, productmodellenContext.onVerversenAangevraagd, upload, ophalenKenmerkenTeksten],
  );
  const initialValues = useMemo<IFormikValues | null>(() => {
    if (kenmerkenTeksten === null) {
      return null;
    }
    return {
      bestanden: bestanden!,
      producttypes: row.producttypen.map((x, i) => ({ prodTypeID: x.TypeID, prioNr: x.PrioNr })),
      kenmerken: row.kenmerken.map((x) => ({
        opOverzicht: x.OpOverzicht,
        teksten: kenmerkenTeksten.teksten
          .filter((tekst) => tekst.TekstID === x.Kenmerk_TekstID)
          .map((tekst) => ({
            taalID: tekst.TaalID,
            tekst: tekst.Tekst || '',
            toepassen: tekst.Toepassen,
          })),
        prodModKenID: x.ProdModKenID,
        sortNr: x.SortNr,
      })),
    };
  }, [bestanden, row, kenmerkenTeksten]);

  const [producttypeSortering, setProducttypeSortering] = useState<Sorting[]>([
    {
      columnName: 'prioNr',
      direction: 'asc',
    },
  ]);
  const [kenmerkenSortering, setKenmerkenSortering] = useState<Sorting[]>([
    {
      columnName: 'sortNr',
      direction: 'asc',
    },
  ]);

  if (initialValues === null) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const {
            submitForm,
            isSubmitting,
            isValid,
            dirty,
            resetForm,
            values,
            setFieldValue,
          } = formikProps;
          // const alleBestandenGeupload = values.bestanden.every((x) => x.aspDrive !== undefined);

          return (
            <DetailRegelContext.Provider
              value={{
                values,
                setFieldValue,
                producttypeSortering,
                onProducttypeSorteringChange: setProducttypeSortering,
                kenmerkenSortering,
                onKenmerkensorteringChange: setKenmerkenSortering,
                prodSrtID: row.ProdSrtID,
                merkID: row.MerkID,
              }}
            >
              <div className="d-flex flex-fill">
                <TabelInspringBlok />
                <div className="d-flex flex-fill flex-column">
                  <div className="row">
                    <div className="col-8">
                      <Field
                        name={nameof<IFormikValues>('producttypes')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          return (
                            <div>
                              <ProducttypesField />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />

                      <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />

                      <Field
                        name={nameof<IFormikValues>('bestanden')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { form, field } = fieldProps;
                          return (
                            <div className="flex-fill p-3">
                              <div className="d-flex align-items-center mb-2">
                                <h5 className="mr-4" style={{ position: 'relative', top: 2 }}>
                                  Productmodelafbeeldingen
                                </h5>
                                <BijlageKnop
                                  disabled={isBezigMetUploaden || isSubmitting}
                                  onBijgevoegd={async (bestanden) => {
                                    const value = await muteerBestanden((x) => [
                                      ...x,
                                      ...bestanden,
                                    ]);
                                    form.setFieldValue(field.name, value);
                                  }}
                                  toegestaneBestandstypes={[
                                    {
                                      weergaveNaam: 'Afbeelding',
                                      mediaType: 'image/*',
                                    },
                                  ]}
                                />
                              </div>
                              <BijlagenContainer
                                bestanden={field.value}
                                weergaveFocus={EWeergaveFocus.Visualisatie}
                                uploadProgresses={uploadProgresses}
                                bestandenMuterenToegestaan={!isBezigMetUploaden && !isSubmitting}
                                onBestandenChange={async (bestanden) => {
                                  const value = await muteerBestanden((_) => bestanden);
                                  form.setFieldValue(field.name, value);
                                }}
                                herordenenToestaan
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <KenmerkenField />
                    </div>
                  </div>

                  <div className="d-flex flex-fill flex-column">
                    <div className="d-flex flex-column flex-fill">
                      <div className="pl-3 pr-3 pb-3">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={submitForm}
                          disabled={isBezigMetUploaden || isSubmitting}
                        >
                          Opslaan
                        </button>
                        <button
                          className="btn btn-secondary btn-sm ml-2"
                          onClick={() => resetForm()}
                          disabled={isBezigMetUploaden || !dirty || isSubmitting}
                        >
                          Herstellen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DetailRegelContext.Provider>
          );
        }}
      />
    </div>
  );
};

export default DetailRegel;
