import React, { useContext, useMemo } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { ERemoteDataState } from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import * as _ from 'lodash';
import {
  ILeveringen,
  IOphalenOpdrachtenResultElement,
} from '../../../../../shared/src/api/v2/inkoop/opdracht';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { addDays, format } from 'date-fns';
import nameof from '../../../core/nameOf';
import { dagDatum } from '../../../helpers/datum';

interface IProps {}

export interface IRegel extends IOphalenOpdrachtenResultElement {}

const VerlopenInkoopopdrachtenTegel: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const inkoopopdrachten: IRegel[] = _.flatten(context.inkoopopdrachtenVerlopen.data!);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__magazijn' as any,
        title: 'Magazijn',
      },
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
      },
      {
        name: '__datumVerwacht' as any,
        title: 'Verwacht' as any,
      },
      {
        name: '__aantal' as any,
        title: 'Aantal',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__magazijn' as any,
        width: 90,
      },
      {
        columnName: '__merknaam' as any,
        width: 110,
      },
      {
        columnName: '__typenaam' as any,
        width: 190,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 70,
      },
      {
        columnName: '__datumVerwacht' as any,
        width: 135,
      },
      {
        columnName: '__aantal' as any,
        width: 90,
      },
      {
        columnName: 'Notities' as any,
        width: 350,
      },
    ],
    [],
  );

  return (
    true && (
      <TegelBasis titel="Verlopen inkoopopdrachten">
        {context.inkoopopdrachtenVerlopen.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <GridStyleWrapper rowAmount={inkoopopdrachten.length} maxHeight={150}>
              <Grid rows={inkoopopdrachten!} columns={kolommen}>
                <DataTypeProvider
                  for={['__magazijn']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.magazijn.NaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__datumVerwacht']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    if (rij.DatumVerwacht !== null) {
                      return <span>{format(new Date(rij.DatumVerwacht), 'dd-MM-yyyy')}</span>;
                    }
                    if (rij.DatumGewenst !== null) {
                      return <span>({format(new Date(rij.DatumGewenst), 'dd-MM-yyyy')})</span>;
                    }

                    return <span></span>;
                  }}
                />

                <DataTypeProvider
                  for={['__aantal']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.Aantal - rij.AantalGeleverdeProducten}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__merknaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.producttype.Merknaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__typenaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.producttype.Typenaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__productsoortnaamKort']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.producttype.ProductsoortnaamKort}</span>;
                  }}
                />

                <VirtualTable messages={{ noData: 'Er zijn geen verwachte leveringen vandaag' }} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        )}
      </TegelBasis>
    )
  );
};

export default VerlopenInkoopopdrachtenTegel;
