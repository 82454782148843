import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { IRapportageDetailProps } from '../../index';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import {
  IOpenstaandeCrediteurenResult,
  IOpenstaandeCrediteurenResultElement,
} from '../../../../../../../../shared/src/api/v2/boekhouding/rapportage';
import api from '../../../../../../api';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../../../components/tabel/ASPTabel/types';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import UitgeklapteRij from './UitgeklapteRij';
import { IOphalenRelatiesResult } from '../../../../../../../../shared/src/api/v2/relatie';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';

export enum EOpenstaandeCrediteurenKolom {
  Relatienummer,
  Relatienaam,
  TotaalOpenstaand,
}

// enum EKolom {
//   Relatienummer,
// }

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Opsommingsgegevens = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const OpenstaandeCrediteuren = (props: IRapportageDetailProps) => {
  const [openstaandeCrediteuren, setOpenstaandeCrediteuren] = useState<
    IRemoteData<IOpenstaandeCrediteurenResult>
  >(createPendingRemoteData());

  const [relaties, setRelaties] = useState<IRemoteData<IOphalenRelatiesResult>>(
    createPendingRemoteData(),
  );

  const ophalenOpenstaandeCrediteuren = useCallback(async () => {
    const result = await api.v2.boekhouding.rapportage.openstaandeCrediteuren({
      filterSchema: {
        filters: [
          {
            naam: 'PEILDATUM',
            data: props.peildatum,
          },
        ],
      },
    });

    setOpenstaandeCrediteuren(createReadyRemoteData(result));
  }, [props.peildatum]);

  useEffect(() => {
    ophalenOpenstaandeCrediteuren();
  }, [ophalenOpenstaandeCrediteuren]);

  const ophalenRelaties = useCallback(async () => {
    if (openstaandeCrediteuren.state !== ERemoteDataState.Ready) {
      return;
    }

    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: openstaandeCrediteuren.data!.crediteuren.map((x) => x.RelID),
          },
        ],
      },
    });
    setRelaties(createReadyRemoteData(result));
  }, [openstaandeCrediteuren]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);

  const [sortering, setSortering] = useState<
    IAspKolomSorteringItem<EOpenstaandeCrediteurenKolom>[]
  >([
    {
      key: EOpenstaandeCrediteurenKolom.Relatienummer,
      sortering: ESortering.Descending,
    },
  ]);

  const keyExtractor = useCallback(
    (regel: IOpenstaandeCrediteurenResultElement) => regel.RelID,
    [],
  );
  const kolommen = useMemo<
    ASPKolom<EOpenstaandeCrediteurenKolom, IOpenstaandeCrediteurenResultElement>[]
  >(
    () => [
      {
        key: EOpenstaandeCrediteurenKolom.Relatienummer,
        label: 'Relatienummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (rij) => rij.Relatienummer,
      },
      {
        key: EOpenstaandeCrediteurenKolom.Relatienaam,
        label: 'Relatienaam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (rij) => <RelatieVisualisatie relID={rij.RelID} />,
        vergelijkingswaarde: (rij) => {
          if (relaties.state !== ERemoteDataState.Ready) {
            return;
          }
          return relaties.data!.relaties.find((x) => x.RelID === rij.RelID)!.weergavenaam;
        },
      },
      {
        key: EOpenstaandeCrediteurenKolom.TotaalOpenstaand,
        label: 'Totaal openstaand',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => (
          <FormatteerBedrag bedrag={rij.facturen.reduce((acc, curr) => acc + curr.Openstaand, 0)} />
        ),
        vergelijkingswaarde: (rij) => rij.facturen.reduce((acc, curr) => acc + curr.Openstaand, 0),
      },
    ],
    [relaties],
  );

  return (
    <Root>
      {openstaandeCrediteuren.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Layout>
          <Opsommingsgegevens>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Totaal openstaand',
                  waarde: (
                    <FormatteerBedrag bedrag={openstaandeCrediteuren.data!.totaalOpenstaand} />
                  ),
                },
              ]}
            />
          </Opsommingsgegevens>
          <ASPTabel
            rijen={openstaandeCrediteuren.data!.crediteuren}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            uitgeklapteRijComponent={UitgeklapteRij}
            uitgeklapt={uitgeklapt}
            onUitgeklaptChange={setUitgeklapt}
            uitgeklapteRijHoogte={250}
            sortering={sortering}
            onSorteringChange={setSortering}
            lokaalSorteren
          />
        </Layout>
      )}
    </Root>
  );
};

export default OpenstaandeCrediteuren;
