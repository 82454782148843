import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Container, Root } from './style';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import IRemoteData from '../../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';

export interface IEntiteitProps {
  relID: number;
  relatie: IRemoteData<IOphalenRelatiesResultElementV2>;
  verversRelatie: () => Promise<void>;
}

export interface IUrlEntiteitMapping {
  criteria: (pad: string) => boolean;
  comp: React.ComponentType<IEntiteitProps>;
}

interface IProps extends RouteComponentProps {
  urlEntiteitMapping: IUrlEntiteitMapping[];
  onEntiteitNietGevonden: () => void;
  nietGevondenIsLaden?: boolean;
  relID: number;
  relatie: IRemoteData<IOphalenRelatiesResultElementV2>;
  verversRelatie: () => Promise<void>;
}

const EntiteitContainer: React.FC<IProps> = (props) => {
  const Entiteit = useMemo(() => {
    const pad = props.location.pathname.replace(props.match.url, '');
    const map = props.urlEntiteitMapping.find((map) => map.criteria(pad)) || null;
    if (map !== null) {
      return map.comp;
    }

    return null;
  }, [props.urlEntiteitMapping, props]);

  useEffect(() => {
    if (props.nietGevondenIsLaden || Entiteit !== null) {
      return;
    }
    props.onEntiteitNietGevonden();
  }, [Entiteit, props.nietGevondenIsLaden]);

  return (
    <Root>
      <Container>
        {Entiteit !== null ? (
          <Entiteit
            relID={props.relID}
            relatie={props.relatie}
            verversRelatie={props.verversRelatie}
          />
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center flex-fill"
            style={{ width: '100%' }}
          >
            <LoadingSpinner />
          </div>
        )}
      </Container>
    </Root>
  );
};

export default withRouter(EntiteitContainer);
