import voorkeur from './voorkeur';
import {
  IAanbiedenVoorVerzendenParams,
  IAanbiedenVoorVerzendenResult,
  IOphalenBulkberichtenParams,
  IOphalenBulkberichtenResult,
  IToevoegenBulkberichtParams,
  IToevoegenBulkberichtResult,
  IVerwijderenBulkberichtenParams,
  IVerwijderenBulkberichtenResult,
} from '../../../../../shared/src/api/v2/bulkbericht';
import api from '../../index';
import ontvangerssoort from './ontvangerssoort';
import ontvangersselectie from './ontvangersselectie';
import contextProvider from './contextProvider';
import maatwerkselectie from './maatwerkselectie';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const bulkbericht = {
  voorkeur,
  ontvangerssoort,
  ontvangersselectie,
  contextProvider,
  maatwerkselectie,
  ophalenBulkberichten: async (
    params: IOphalenBulkberichtenParams,
  ): Promise<IOphalenBulkberichtenResult> => {
    return await api.post('/v2/bulkbericht/ophalen-bulkberichten', params);
  },
  checkToevoegenBulkbericht: async (params: IToevoegenBulkberichtParams): Promise<ICheckData> => {
    return await api.post('/v2/bulkbericht/check-toevoegen-bulkbericht', params);
  },
  toevoegenBulkbericht: async (
    params: IToevoegenBulkberichtParams,
  ): Promise<IToevoegenBulkberichtResult> => {
    return await api.post('/v2/bulkbericht/toevoegen-bulkbericht', params);
  },
  checkAanbiedenVoorVerzenden: async (
    params: IAanbiedenVoorVerzendenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bulkbericht/check-aanbieden-voor-verzenden', params);
  },
  aanbiedenVoorVerzendenAchtergrondproces: async (
    params: IAanbiedenVoorVerzendenParams,
  ): Promise<IAchtergrondProces<IAanbiedenVoorVerzendenResult>> => {
    return await api.post('/v2/bulkbericht/aanbieden-voor-verzenden-achtergrondproces', params);
  },
  verwijderenBulkberichten: async (
    params: IVerwijderenBulkberichtenParams,
  ): Promise<IVerwijderenBulkberichtenResult> => {
    return await api.post('/v2/bulkbericht/verwijderen-bulkberichten', params);
  },
};

export default bulkbericht;
