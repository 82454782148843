import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { ISponsorCommissie } from '../../../../../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import SponsorCommissieSelectie from '../../../../../../../components/formulier/SponsorCommissieSelectie';
import SponsorDienstSelectie from '../../../../../../../components/formulier/SponsorDienstSelectie';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../../core/nameOf';
import ContactpersoonSelectie from '../../../../../../../components/formulier/ContactpersoonSelectie';
import PersoonSelectie from '../../../../../../../components/formulier/PersoonSelectie';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
  relID: number | null;
}

interface IFormikValues {
  Naam?: string;
  Jaar?: number;
  Contactpersoon_PersID?: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  Naam: 'Naam',
  Jaar: 'Jaar',
  Contactpersoon_PersID: 'Contactpersoon',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [item, setItem] = useState<ISponsorCommissie | undefined>(undefined);

  const ophalenItem = useCallback(async () => {
    const results = await api.v2.sponsoringv2.commissie.ophalenCommissies({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });
    setItem(results[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenItem();
  }, [ophalenItem]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: ISponsorCommissie = {
        ID: item?.ID,
        SponDienstID: props.id!,
        Naam: values.Naam,
        Jaar: values.Jaar,

        Contactpersoon_PersID: item?.Contactpersoon_PersID,
      };

      console.log('commissie:');
      console.log(params);

      await api.v2.sponsoringv2.commissie.toevoegenOfWijzigenCommissie(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (item == null) {
      return {
        Naam: '',
        Jaar: 0,
        Contactpersoon_PersID: undefined,
      };
    }

    return {
      Naam: item.Naam,
      Jaar: item.Jaar,

      Contactpersoon_PersID: item.Contactpersoon_PersID,
    };
  }, [item]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        Naam: Yup.string().required(),
        Jaar: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{props.id === null ? 'Toevoegen' : 'Wijzigen'} Sponsorcommissie</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.Naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('Naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.Jaar}</label>
                        <Field
                          name={nameOf<IFormikValues>('Jaar')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.Contactpersoon_PersID}</label>
                        <Field
                          name={nameOf<IFormikValues>('Contactpersoon_PersID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <PersoonSelectie
                                  PersID={field.value}
                                  RelID={props.relID}
                                  onChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
