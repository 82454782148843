import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import MenuLayout from '../../../components/MenuLayout';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import nameOf from '../../../core/nameOf';
import { format } from 'date-fns';
import { IOphalenSEPALandenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  // selectie: number[];
}

const defaultUrlState: IUrlState = {
  // selectie: [],
};

export interface IRegel extends IOphalenSEPALandenResultElement {}

const SEPALanden: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [sepalanden, setSepalanden] = useState<IRegel[] | null>(null);

  const ophalenSEPALanden = useCallback(async () => {
    const result = (
      await api.v2.bank.opdracht.ophalenSEPALanden({
        orderSchema: {
          orders: [{ naam: 'LANDNAAM', richting: 'ASC' }],
        },
      })
    ).sepaLanden;

    setSepalanden(result);
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Landnaam',
        title: 'Landnaam',
      },
      {
        name: 'Landcode',
        title: 'Code',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
      {
        name: 'Regex' as any,
        title: 'RegEx',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Landcode',
        width: 110,
      },
      {
        columnName: 'Landnaam',
        width: 200,
      },
      {
        columnName: 'Actief',
        width: 115,
      },
      {
        columnName: 'Regex' as any,
        width: 250,
      },
    ],
    [],
  );

  useEffect(() => {
    ophalenSEPALanden();
  }, [ophalenSEPALanden]);

  return (
    <>
      {sepalanden === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          // menu={
          //   <div className="d-flex">
          //     <button
          //       className="btn btn-sm btn-light d-flex align-items-center"
          //       style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
          //       disabled={false}
          //       onClick={() => null}
          //     >
          //       <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
          //       <span className="ml-2">Nieuw</span>
          //     </button>
          //     <button
          //       className="btn btn-sm btn-light d-flex align-items-center ml-3"
          //       style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
          //       disabled={urlState.selectie.length === 0}
          //       onClick={() => null}
          //     >
          //       <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
          //       <span className="ml-2">Verwijderen</span>
          //     </button>
          //   </div>
          // }
          body={
            <GridStyleWrapper height="calc(100vh - 30px)">
              <Grid rows={sepalanden} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[nameOf<IRegel>('Actief')]}
                  formatterComponent={(formatterProps) => (
                    <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>
                  )}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('RecordGewijzigd')]}
                  formatterComponent={(fmtProps) => {
                    const row: IRegel = fmtProps.row;
                    return (
                      <span>
                        {row.RecordGewijzigd !== null
                          ? format(new Date(row.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                          : null}
                      </span>
                    );
                  }}
                />

                <EditingState
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                  }}
                />

                <VirtualTable
                  messages={{
                    noData: 'Geen gebruikers',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableEditColumn
                  width={35}
                  showEditCommand
                  // showDeleteCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />
                <TableHeaderRow />
                {/* <RowDetailState defaultExpandedRowIds={[]} />
                <TableRowDetail
                  toggleCellComponent={DXTableToggleCellComponent}
                  contentComponent={DetailComp}
                /> */}
                {/* <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                /> */}
                {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
              </Grid>
            </GridStyleWrapper>
          }
        />
      )}
    </>
  );
});

export default SEPALanden;
