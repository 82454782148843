import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialoog from '../Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Combobox from '../../formulier/Combobox';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenInterneLocatiesResultElement } from '../../../../../shared/src/api/v2/locatie/locatie';

interface IDialoogResult {
  locIntID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  initieelLocIntIDProvider?: () => Promise<number | null>;
  interneLocatiesProvider: () => Promise<IOphalenInterneLocatiesResultElement[]>;
}

const InterneLocatieSelectieDialoog: React.FC<IProps> = (props) => {
  const [locIntID, setLocIntID] = useState<IRemoteData<number | null>>(createPendingRemoteData());
  const [interneLocaties, setInterneLocaties] = useState<
    IRemoteData<IOphalenInterneLocatiesResultElement[]>
  >(createPendingRemoteData());

  useEffect(() => {
    if (props.initieelLocIntIDProvider === undefined) {
      setLocIntID(createReadyRemoteData(null));
      return;
    }

    props
      .initieelLocIntIDProvider()
      .then((locIntID) => setLocIntID(createReadyRemoteData(locIntID)));
  }, []);

  useEffect(() => {
    props
      .interneLocatiesProvider()
      .then((interneLocaties) => setInterneLocaties(createReadyRemoteData(interneLocaties)));
  }, [props.interneLocatiesProvider]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Selecteer een interne locatie</ModalTitle>
      </ModalHeader>

      <ModalBody>
        {locIntID.state === ERemoteDataState.Pending ||
        interneLocaties.state === ERemoteDataState.Pending ? (
          <div>
            <span>Bezig met laden...</span>
          </div>
        ) : interneLocaties.data!.length === 0 ? (
          <p>Geen interne locaties gevonden, voer deze eerst bij de locatie op.</p>
        ) : (
          <div>
            <label>Interne locatie</label>
            <Combobox
              geselecteerd={locIntID.data}
              opties={interneLocaties.data!.map((interneLocatie) => {
                return {
                  label: interneLocatie.Naam,
                  id: interneLocatie.ID,
                };
              })}
              onSelectieChange={(locIntID) => {
                setLocIntID(createReadyRemoteData(locIntID));
              }}
              legeOptieTonen
              options={{ legeOptieTekst: 'Geen interne locatie' }}
            />
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() =>
            props.onSuccess({
              locIntID: locIntID.data!,
            })
          }
          style={{ width: 100 }}
          disabled={locIntID.state === ERemoteDataState.Pending}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default InterneLocatieSelectieDialoog;
