import React, { useCallback, useContext, useEffect, useState } from 'react';
import MenuLayout from '../../../../../../../components/MenuLayout';
import IncassozakenTabel, {
  IWijzigenIncassozaakDialoogState,
} from '../../../../../../../components/debiteuren/IncassozakenTabel';
import { IOphalenIncassozakenResultElement } from '../../../../../../../../../shared/src/api/v2/debiteur/incassozaak';
import useUrlState from '../../../../../../../core/useUrlState';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { EIncassozaakStatus } from '../../../../../../../bedrijfslogica/enums';

export interface IUrlState {
  selectie: number[];
  wijzigenIncassozaakDialoogState: IWijzigenIncassozaakDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenIncassozaakDialoogState: null,
};

interface IProps extends RouteComponentProps {}

const Incassozaken: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const { klantkaartStore } = useContext(RootStoreContext);

  const [incassozaken, setIncassozaken] = useState<IOphalenIncassozakenResultElement[] | null>(
    null,
  );

  const ophalenIncassozaken = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setIncassozaken(null);
      return;
    }

    const incassozakenResult = await api.v2.debiteur.incassozaak.ophalenIncassozaken({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [klantkaartStore.relatie.RelID],
          },
        ],
      },
    });
    const incassozaken = incassozakenResult.incassozaken;

    setIncassozaken(incassozaken);
  }, [klantkaartStore.relatie === null ? null : klantkaartStore.relatie.RelID]);

  useEffect(() => {
    ophalenIncassozaken();
  }, [ophalenIncassozaken]);

  return (
    <MenuLayout
      menu={
        <div className="d-flex">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            disabled={urlState.selectie.length !== 1}
            style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
            onClick={async () => {
              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Opdracht versturen naar de incassodienst?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              const params = { ids: [urlState.selectie[0]] };

              await api.v2.debiteur.incassozaak.versturenIncassoOpdrachten(params);

              await api.v2.debiteur.incassozaak.wijzigenDossiersstatus({
                ids: [urlState.selectie[0]],
                status: EIncassozaakStatus.Uithanden,
              });

              ophalenIncassozaken();
            }}
          >
            {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
            <span className="ml-2">Versturen opdracht</span>
          </button>

          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            disabled={urlState.selectie.length !== 1}
            style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
            onClick={async () => {
              const params = { ids: [urlState.selectie[0]] };

              const checkData = await api.v2.debiteur.incassozaak.checkToevoegenHerincassozaakVoorIncassozaak(
                params,
              );
              if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Wil je voor deze incassozaak een herincassozaak maken?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              await api.v2.debiteur.incassozaak.toevoegenHerincassozaakVoorIncassozaak(params);

              ophalenIncassozaken();
            }}
          >
            {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
            <span className="ml-2">Herincassozaak maken</span>
          </button>

          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            disabled={urlState.selectie.length !== 1}
            style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
            onClick={async () => {
              const params = { ids: [urlState.selectie[0]] };

              const checkData = await api.v2.debiteur.incassozaak.checkToevoegenRechtzaakVoorIncassozaak(
                params,
              );
              if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Wil je voor deze incassozaak een rechtzaak maken?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              await api.v2.debiteur.incassozaak.toevoegenRechtzaakVoorIncassozaak(params);

              ophalenIncassozaken();
            }}
          >
            {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
            <span className="ml-2">Rechtzaak maken</span>
          </button>
        </div>
      }
      body={
        <div>
          <IncassozakenTabel
            incassozaken={incassozaken}
            selectie={urlState.selectie}
            onSelectieChange={(x) => setUrlStateSync('selectie', x)}
            wijzigenIncassozaakDialoogState={urlState.wijzigenIncassozaakDialoogState}
            onWijzigenIncassozaakDialoogStateChange={(x) =>
              setUrlStateSync('wijzigenIncassozaakDialoogState', x)
            }
            onVerversenAangevraagd={async () => await ophalenIncassozaken()}
            relID={klantkaartStore.relatie !== null ? klantkaartStore.relatie.RelID : undefined}
          />
        </div>
      }
    />
  );
});

export default withRouter(Incassozaken);
