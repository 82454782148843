import React, { useMemo } from 'react';
import { IPotentieelOntvangenInkoopfactuurItem } from '../../../../../../shared/src/api/v2/externeData';
import { IRegelBase } from '../..';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { useHeeftAutorisatie } from '../../../../helpers/heeftAutorisatie';

interface IProps {
  regel: IRegelBase;
  item: IPotentieelOntvangenInkoopfactuurItem;
}

const InkoopfactuurWeergave = (props: IProps) => {
  const heeftAutorisatie = useHeeftAutorisatie(useMemo(() => ['EXTERNE_DATA_INKOOPFACTUREN'], []));

  if (!heeftAutorisatie) {
    return (
      <div className="d-flex align-items-center">
        <span className="font-italic">Je bent niet geautoriseerd om deze gegevens in te zien.</span>
      </div>
    );
  }

  return (
    <div
      className="d-flex align-items-center"
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      }}
    >
      {props.item.RelID === null ? (
        <span className="font-italic">Geen relatie herkend</span>
      ) : (
        <RelatieVisualisatie relID={props.item.RelID} />
      )}
      &nbsp;&nbsp;&ndash;&nbsp;&nbsp;
      <span className="font-italic">
        {props.item.Factuurnummer === null
          ? 'Geen factuurnummer herkend'
          : `Factnr: ${props.item.Factuurnummer}`}
      </span>
    </div>
  );
};

export default InkoopfactuurWeergave;
