import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { ISelectieGeneriekParams } from '../../../../../../shared/src/api/v2/generiek';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../components/dialogen/Dialoog';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import IDialoogProps from '../../../../core/IDialoogProps';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  ids: number[];
}

interface IFormikValues {
  emailVersturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  emailVersturen: 'Email versturen naar aanvrager',
};

const AfwijzenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      await api.v2.sponsoringv2.verzoek.afwijzenVerzoeken({
        ids: props.ids,
        emailVersturen: values.emailVersturen,
      });
      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      emailVersturen: true,
    };
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>
          Afwijzen {props.ids.length} Sponsorverzoek{props.ids.length > 1 ? 'en' : ''}
        </ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <span>Geselecteerde verzoeken afwijzen?</span>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('emailVersturen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">{veldnamen.emailVersturen}</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default AfwijzenDialoog;
