import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Field, FieldProps, Formik, FormikActions, FormikProps, FormikErrors } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IOphalenRelatiesResultElementV2,
  IWijzigenRelatieParams,
} from '../../../../../../../../../shared/src/api/v2/relatie';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';
import UitklapFormulier from '../../../../../../../components/formulier/UitklapFormulier';
import Combobox from '../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import HorizontaleScheidingslijn from '../../../../../../../components/layout/HorizontaleScheidingslijn';
import AdresFormulier, { IValues } from '../../../../../../../components/formulier/AdresFormulier';
import { observer } from 'mobx-react-lite';
import DatumKiezer from '../../../../../../../components/formulier/DatumKiezer';
import api from '../../../../../../../api';
import { IOphalenFactuurniveausResultElement } from '../../../../../../../../../shared/src/api/v2/debiteur';
import { IOphalenBetaalwijzenResultElement } from '../../../../../../../../../shared/src/api/v2/betaalwijze';
import nameOf from '../../../../../../../core/nameOf';
import teksten from '../../../../../../../bedrijfslogica/teksten';
import useUrlState from '../../../../../../../core/useUrlState';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { formatteerRelatieNaam } from '../../../../../../../helpers';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { KlantContext } from '../index';
import { IOphalenVerzendwijzenResultElement } from '../../../../../../../../../shared/src/api/v2/factuur';
import { IOphalenServicedienstenResultElement } from '../../../../../../../../../shared/src/api/v2/service/opdracht';
import MultiCombobox, { IKolom } from '../../../../../../../components/formulier/MultiCombobox';
import relatie from '../../../../../../../api/v2/relatie';
import NumeriekVeld from '../../../../../../../components/formulier/NumeriekVeld';
import FormikVeldFout from '../../../../../../../components/formulier/FormikVeldFout';
import TelefoonnummerInput from '../../../../../../../components/formulier/TelefoonnummerInput';
import UitlegTooltip from '../../../../../../../components/formulier/UitlegTooltip';
import { IToevoegenRelatieHoedanigheidParams } from '../../../../../../../../../shared/src/api/v2/relatie/hoedanigheid';
import BedragInput from '../../../../../../../components/formulier/BedragInput';
import { IOphalenVertegenwoordigersResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/vertegenwoordiger';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IFormikValues {
  primaireContactpersoon: number | null;
  financieleContactpersoon: number | null;
  vertegenwoordigerID: number | null;
  geblokkeerd: boolean;

  // Adres
  adres: IValues | null;

  organisatienaam: string;
  organisatienaamKort: string;
  organisatieEmail: string | null;
  organisatieTelefoonAlgemeen: string | null;
  domein: string | null;
  websiteURL: string | null;
  logoBestandID: number | null;
  rechtsvormID: number | null;
  btwnummer: string;
  kvknummer: string;
  bankmutatieReferentie: string | null;
  externeRelatiemapLink: string | null;

  incassomachtiging: boolean;
  betaaldag: number;
  betWijzeID: number;
  betalingstermijn: number | null;
  factuurniveau: number;
  verzendwijzeFacturen: number;
  emailFactuur: string | null;
  emailAanmaning: string | null;
  factuurKenmerk: string | null;
  factuurregeldataToevoegenBijVersturen: boolean;
  toevoegingNaamOpFactuur: string | null;

  maximaalAantalAanmaningen: number | null;
  nietAanmanen: boolean;
  datumNietAanmanen: Date | null;
  prolongatieTermijn: number;
  resttermijnenDirect: boolean;
  prolongatieOphouden: boolean;
  optieFactuurVersturen: number;
  betalingsregeling: boolean;
  incassoOpdrachtWeb: boolean;
  wervingscode: string | null;
  wervingscodeOngeldig: boolean;
  overnamecode: string | null;
  leveringBijVervallenSaldo: boolean;
  nietIndexeren: boolean;

  vasteInfo: string;
  nietGebruiken: boolean;
  geenReviewUitnodiging: boolean;
  geenEigenGebruik: boolean;
  servDienstID: number | null;
  servDienstOngeachtGarantie: boolean;
  contractwisselToestaanWebsite: number;
  documentatieVersturenNaLevering: boolean;

  geenWOPVordering: boolean;
  eenFactuurPerEmail: boolean;
  grensbedragIncasso: number | null;
  csvSpecificatieMeesturen: boolean;
}

interface IUrlState {
  algemeenTonen: boolean;
  adresTonen: boolean;
  debiteurTonen: boolean;
  serviceTonen: boolean;
  websiteTonen: boolean;
}

const defaultUrlState: IUrlState = {
  algemeenTonen: true,
  adresTonen: false,
  debiteurTonen: false,
  serviceTonen: false,
  websiteTonen: false,
};

const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  primaireContactpersoon: 'Primaire contactpersoon',
  financieleContactpersoon: 'Financiele contactpersoon',
  vertegenwoordigerID: 'Vertegenwoordiger',
  geblokkeerd: 'Relatie is geblokkeerd',
  adres: 'Adres',
  datumNietAanmanen: 'Datum niet aanmanen',
  nietAanmanen: 'Niet aanmanen',
  maximaalAantalAanmaningen: 'Max. aantal aanmaningen voor factuur',
  btwnummer: 'BTW nummer',
  kvknummer: 'KvK-nummer',
  bankmutatieReferentie: 'Bankmutatie referentie',
  betalingstermijn: 'Betalingstermijn',
  betWijzeID: 'Betaalwijze',
  betaaldag: 'Betaaldag',
  incassomachtiging: 'Incassomachtiging',
  factuurniveau: 'Factuurniveau',
  verzendwijzeFacturen: 'Verzendwijze',
  emailFactuur: 'Emailadres facturen',
  emailAanmaning: 'Emailadres aanmaningen',
  factuurKenmerk: 'Factuurkenmerk (Uw ref.)',
  toevoegingNaamOpFactuur: 'Toevoeging aan naam op factuur (2e regel)',
  rechtsvormID: 'Rechtsvorm',
  organisatienaamKort: 'Organisatienaam kort',
  organisatienaam: 'Organisatienaam',
  organisatieEmail: 'Email algemeen',
  organisatieTelefoonAlgemeen: 'Telefoon algemeen',
  domein: 'Domein',
  websiteURL: 'Website',
  prolongatieTermijn: 'Prolongatietermijn',
  resttermijnenDirect: 'Resttermijnen in 1 keer',
  prolongatieOphouden: 'Prolongatie ophouden',
  optieFactuurVersturen: 'Optie factuur versturen',
  betalingsregeling: 'Betalingsregeling toestaan',
  vasteInfo: 'Vaste info',
  incassoOpdrachtWeb: 'Incasso-opdracht op website toestaan',
  wervingscode: 'Wervingscode',
  wervingscodeOngeldig: 'Wervingscode ongeldig',
  overnamecode: 'Overnamecode',
  nietGebruiken: 'Relatie niet gebruiken',
  leveringBijVervallenSaldo: 'Levering toestaan bij vervallen saldo',
  geenReviewUitnodiging: 'Geen review uitnodiging en/of herinnering sturen',
  geenEigenGebruik: 'Niet voor eigen gebruik',
  servDienstID: 'Vaste servicedienst',
  servDienstOngeachtGarantie: 'Ook igv garantie',
  contractwisselToestaanWebsite: 'Contractwissel toestaan op website',
  documentatieVersturenNaLevering: 'Documentatie versturen na levering',
  externeRelatiemapLink: 'Link naar Externe relatiemap',
  nietIndexeren: 'Niet (jaarlijks) indexeren',
  factuurregeldataToevoegenBijVersturen: 'Factuurregeldata toevoegen bij versturen',
  geenWOPVordering: 'WOP-vorderingen niet toegestaan',
  eenFactuurPerEmail: 'Eén factuur per email versturen',
  csvSpecificatieMeesturen: 'CSV-specificatie meesturen met de factuur',
};

interface IVertegenwoordiger extends IOphalenVertegenwoordigersResultElement {
  relatie: IOphalenRelatiesResultElementV2;
}

const KlantgegevensComp: React.FC<IProps> = observer((props) => {
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const klantContext = useContext(KlantContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);
  const [factuurniveaus, setFactuurniveaus] = useState<
    IOphalenFactuurniveausResultElement[] | null
  >(null);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const [servicediensten, setServicediensten] = useState<
    IOphalenServicedienstenResultElement[] | []
  >([]);

  const [vertegenwoordigers, setVertegenwoordigers] = useState<IVertegenwoordiger[] | null>(null);
  // const [vertegenwoordigers, setVertegenwoordigers] = useState<
  //   IOphalenVertegenwoordigersResultElement[] | null
  // >(null);

  useEffect(() => {
    (async () => {
      const vertegenwoordigersResult = (
        await api.v2.relatie.vertegenwoordiging.ophalenVertegenwoordigers({
          filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
        })
      ).vertegenwoordigers;

      const relatiesResult = await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: vertegenwoordigersResult.map((x: any) => x.Vertegenwoordiger_RelID),
            },
          ],
        },
      });

      const result = vertegenwoordigersResult.map((x) => {
        const relatie = relatiesResult.relaties.find((y) => y.RelID === x.Vertegenwoordiger_RelID)!;
        return { ...x, relatie };
      });

      setVertegenwoordigers(result);
    })();
  }, [props.relID]);

  // Rijbron met Servicediensten tbv vaste servicedienst
  useEffect(() => {
    (async () => {
      const diensten = (
        await api.v2.dienst.service.ophalenDiensten({
          filterSchema: { filters: [{ naam: 'IS_HOOFDAANNEMER', data: true }] },
        })
      ).diensten;

      setServicediensten(diensten);
    })();
  }, []);

  const servicedienstenKolommen = useMemo<IKolom<IOphalenServicedienstenResultElement>[]>(
    () => [
      {
        key: 'NaamEnum',
        label: 'Enum',
        formatFabriek: (row) => {
          return <span>{row.relatie!.weergavenaam}</span>;
        },
        breedte: 250,
      },
      // {
      //   key: 'Criterium',
      //   label: 'Leverancier',
      //   breedte: 150,
      // },
    ],
    [],
  );

  const optiesFactuurVersturen = [
    { id: 0, label: 'Standaard' },
    { id: 1, label: 'Altijd' },
    { id: 2, label: 'Nooit' },
  ];

  const optiesContractwisselToestaan = [
    { id: 0, label: 'Standaard' },
    { id: 1, label: 'Ja' },
    { id: 2, label: 'Nee' },
  ];

  const [factuurVerzendwijzenResult, setFactuurVerzendwijzenResult] = useState<
    IOphalenVerzendwijzenResultElement[] | null
  >(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.factuur.ophalenVerzendwijzen({});
      setFactuurVerzendwijzenResult(result.verzendwijzen);
    })();
  }, []);

  const ophalenFactuurniveaus = useCallback(async () => {
    const niveausResult = await api.v2.debiteur.ophalenFactuurniveaus();
    setFactuurniveaus(niveausResult.niveaus);
  }, []);

  useEffect(() => {
    ophalenFactuurniveaus();
  }, [ophalenFactuurniveaus]);

  useEffect(() => {
    klantkaartStore.ophalenContactpersonenVoorlijst({
      relID: klantContext.relID,
      sortering: 1,
    });
  }, [klantContext.relID]);

  const ophalenRelatie = useCallback(async () => {
    setRelatie(null);
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [klantContext.relID],
          },
        ],
      },
    });

    setRelatie(result.relaties[0]);
  }, [klantContext.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  useEffect(() => {
    if (relatie != null && relatie.debiteur == null) {
      aanmakenHoedanigheidKlant();
    }
  }, [relatie]);

  const aanmakenHoedanigheidKlant = useCallback(async () => {
    const params: IToevoegenRelatieHoedanigheidParams = {
      relID: props.relID,
      NaamEnum: 'KLANT',
    };

    const checkResult = await api.v2.relatie.hoedanigheid.checkToevoegenRelatieHoedanigheid(params);
    if ((await checkStore.controleren({ checkData: checkResult })).type === EResultType.Annuleren) {
      console.log('Check gefaald. Het kan zijn dat er toch wel al een klant hoedanigheid is?');
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Leverancier heeft geen hoedanigheid als Klant, wil je die eerst toevoegen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const result = await api.v2.relatie.hoedanigheid.toevoegenRelatieHoedanigheid(params);
    ophalenRelatie();
  }, [relatie]);

  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalwijzenResultElement[] | null>(
    null,
  );
  const [betaaldagen, setBetaaldagen] = useState<number[] | null>(null);
  const [prolongatieTermijnen, setProlongatieTermijnen] = useState<number[]>([]);

  const betalingstermijnen = [1, 8, 15, 30];

  const ophalenBetaalwijzen = useCallback(async () => {
    const result = await api.v2.betaalwijze.ophalenBetaalwijzen({
      filterSchema: {
        filters: [],
      },
    });
    setBetaalwijzen(result.betaalwijzen);
  }, []);

  useEffect(() => {
    ophalenBetaalwijzen();
  }, [ophalenBetaalwijzen]);

  const ophalenBetaaldagen = useCallback(async () => {
    const result = await api.v2.debiteur.ophalenBetaaldagen({});
    setBetaaldagen(result);
  }, []);

  useEffect(() => {
    ophalenBetaaldagen();
  }, []);

  const ophalenProlongatieTermijnen = useCallback(async () => {
    const result = await api.v2.debiteur.ophalenProlongatieTermijnen();
    setProlongatieTermijnen(result);
  }, []);

  useEffect(() => {
    ophalenProlongatieTermijnen();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (relatie === null || relatie.debiteur == null || vertegenwoordigers === null) {
      return null;
    }

    let adres: IValues | null = null;
    // Is er een adres
    if (relatie.adres !== null) {
      adres = {
        straatnaam: relatie.adres.Straatnaam,
        bisnummer: relatie.adres.Bisnummer || '',
        huisnummer: relatie.adres.Huisnummer || 1,
        landID: relatie.adres.LandID,
        plaatsnaam: relatie.adres.Plaatsnaam || '',
        postcode: relatie.adres.Postcode || '',
      };
    }

    return {
      primaireContactpersoon: relatie.PersoonPrimair_PersID,
      financieleContactpersoon:
        relatie.financieel.persoon !== null ? relatie.financieel.persoon.PersID : null,
      vertegenwoordigerID: relatie.Vertegenwoordiger_RelID,
      geblokkeerd: relatie.Geblokkeerd,
      adres,
      organisatienaam: relatie.organisatie !== null ? relatie.organisatie.Naam : '',
      organisatienaamKort: relatie.organisatie !== null ? relatie.organisatie.NaamKort : '',
      organisatieEmail: relatie.organisatie !== null ? relatie.organisatie.Email : '',
      organisatieTelefoonAlgemeen:
        relatie.organisatie !== null ? relatie.organisatie.Telefoonnummer : null,
      domein: relatie.organisatie !== null ? relatie.organisatie.Domein : '',
      websiteURL: relatie.organisatie !== null ? relatie.organisatie.WebsiteURL : '',
      logoBestandID: relatie.organisatie !== null ? relatie.organisatie.Logo_BestandID : null,
      externeRelatiemapLink:
        relatie.ExterneRelatiemapLink !== null ? relatie.ExterneRelatiemapLink : '',
      rechtsvormID: relatie.organisatie !== null ? relatie.organisatie.rechtsvorm.ID : null,
      kvknummer: relatie.organisatie !== null ? relatie.organisatie.KvKnummer || '' : '',
      btwnummer: relatie.organisatie !== null ? relatie.organisatie.Btwnummer || '' : '',
      bankmutatieReferentie:
        relatie.BankmutatieReferentie !== null ? relatie.BankmutatieReferentie : '',
      incassomachtiging: relatie.debiteur!.Incassomachtiging,
      betaaldag: relatie.debiteur!.Betaaldag,
      betWijzeID: relatie.debiteur!.betaalwijze.BetWijzeID,
      betalingstermijn:
        relatie.debiteur!.Betalingstermijn !== null ? relatie.debiteur!.Betalingstermijn : 0,
      factuurniveau: relatie.debiteur!.FactuurNiveau,
      verzendwijzeFacturen: relatie.debiteur!.VerzendwijzeFacturen,
      emailFactuur: relatie.debiteur!.EmailFactuur,
      emailAanmaning: relatie.debiteur!.EmailAanmaning,
      factuurKenmerk: relatie.debiteur!.FactuurKenmerk,
      toevoegingNaamOpFactuur: relatie.debiteur.ToevoegingNaamOpFactuur,
      nietAanmanen: relatie.debiteur!.NietAanmanen,
      datumNietAanmanen:
        relatie.debiteur!.DatumNietAanmanen === null
          ? null
          : new Date(relatie.debiteur!.DatumNietAanmanen),
      betalingsregeling: relatie.debiteur!.BetalingsregelingToestaan,
      prolongatieTermijn: relatie.debiteur!.ProlongatieTermijn,
      resttermijnenDirect: relatie.debiteur!.ResttermijnenDirect,
      prolongatieOphouden: relatie.debiteur!.ProlongatieOphouden,
      optieFactuurVersturen: relatie.debiteur!.OptieFactuurVersturen,
      nietIndexeren: relatie.debiteur!.NietIndexeren,
      factuurregeldataToevoegenBijVersturen: relatie.debiteur!
        .FactuurregeldataToevoegenBijVersturen,
      geenWOPVordering: relatie.debiteur!.GeenWOPVordering,
      eenFactuurPerEmail: relatie.debiteur!.EenFactuurPerEmail,
      vasteInfo: relatie.VasteInfo || '',
      incassoOpdrachtWeb: relatie.debiteur!.IncassoOpdrachtWeb,
      wervingscode: relatie.Wervingscode || '',
      wervingscodeOngeldig: relatie.WervingscodeOngeldig,
      overnamecode: relatie.Overnamecode || '',
      nietGebruiken: relatie.NietGebruiken,
      leveringBijVervallenSaldo: relatie.debiteur!.LeveringBijVervallenSaldo,
      geenReviewUitnodiging: relatie.GeenReviewUitnodiging,
      geenEigenGebruik: relatie.GeenEigenGebruik,
      servDienstID: relatie.ServDienstID,
      servDienstOngeachtGarantie: relatie.ServDienstOngeachtGarantie,
      contractwisselToestaanWebsite: relatie.ContractwisselToestaanWebsite,
      documentatieVersturenNaLevering: relatie.DocumentatieVersturenNaLevering,
      grensbedragIncasso: relatie.debiteur.GrensbedragIncasso ?? 0,
      csvSpecificatieMeesturen: relatie.debiteur.CSVSpecificatieMeesturen,
      maximaalAantalAanmaningen:
        relatie.debiteur.MaximaalAantalAanmaningen !== null
          ? relatie.debiteur.MaximaalAantalAanmaningen
          : null,
    };
  }, [relatie, vertegenwoordigers]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const adres = values.adres!;

      const params: IWijzigenRelatieParams = {
        relID: props.relID,
        relatiesoort: klantkaartStore.relatie!.Relatiesoort,
        geblokkeerd: values.geblokkeerd,
        straatnaam: adres.straatnaam,
        huisnummer: adres.huisnummer,
        bisnummer: adres.bisnummer,
        postcode: adres.postcode,
        plaatsnaam: adres.plaatsnaam,
        landID: adres.landID,
        btwnummer: values.btwnummer,
        kvknummer: values.kvknummer,
        bankmutatieReferentie: values.bankmutatieReferentie,
        organisatienaam: values.organisatienaam,
        organisatienaamKort: values.organisatienaamKort,
        organisatieEmail: values.organisatieEmail,
        organisatieTelefoonAlgemeen: values.organisatieTelefoonAlgemeen,
        websiteURL: values.websiteURL !== '' ? values.websiteURL : null,
        logoBestandID: values.logoBestandID,
        nietGebruiken: values.nietGebruiken,
        vasteInfo: values.vasteInfo.trim().length === 0 ? null : values.vasteInfo.trim(),
        wervingscode: values.wervingscode !== '' ? values.wervingscode : null,
        wervingscodeOngeldig: values.wervingscodeOngeldig,
        overnamecode: values.overnamecode,
        geenReviewUitnodiging: values.geenReviewUitnodiging,
        geenEigenGebruik: values.geenEigenGebruik,
        servDienstID: values.servDienstID,
        servDienstOngeachtGarantie: values.servDienstOngeachtGarantie,
        contractwisselToestaanWebsite: values.contractwisselToestaanWebsite,
        documentatieVersturenNaLevering: values.documentatieVersturenNaLevering,
        domein: values.domein,
        externeRelatiemapLink:
          values.externeRelatiemapLink !== '' ? values.externeRelatiemapLink : null,
        vertegenwoordiger_RelID: values.vertegenwoordigerID,

        debiteur: {
          betaaldag: values.betaaldag,
          betalingstermijn: values.betalingstermijn,
          nietAanmanen: values.nietAanmanen,
          datumNietAanmanen: values.datumNietAanmanen,
          betWijzeID: values.betWijzeID,
          incassomachtiging: values.incassomachtiging,
          prolongatieTermijn: values.prolongatieTermijn,
          optieFactuurVersturen: values.optieFactuurVersturen,
          resttermijnenDirect: values.resttermijnenDirect,
          prolongatieOphouden: values.prolongatieOphouden,
          factuurniveau: values.factuurniveau,
          verzendwijzeFacturen: values.verzendwijzeFacturen,
          emailFactuur: values.emailFactuur,
          emailAanmaning: values.emailAanmaning,
          betalingsregeling: values.betalingsregeling,
          incassoOpdrachtWeb: values.incassoOpdrachtWeb,
          factuurKenmerk: values.factuurKenmerk,
          leveringBijVervallenSaldo: values.leveringBijVervallenSaldo,
          nietIndexeren: values.nietIndexeren,
          factuurregeldataToevoegenBijVersturen: values.factuurregeldataToevoegenBijVersturen,
          toevoegingNaamOpFactuur: values.toevoegingNaamOpFactuur,
          geenWOPVordering: values.geenWOPVordering,
          eenFactuurPerEmail: values.eenFactuurPerEmail,
          maximaalAantalAanmaningen: values.maximaalAantalAanmaningen,
          grensbedragIncasso: values.grensbedragIncasso !== 0 ? values.grensbedragIncasso : null,
          CSVSpecificatieMeesturen: values.csvSpecificatieMeesturen,
        },

        crediteur: null,

        persoonPrimair_PersID: values.primaireContactpersoon,
        persoonFinancieel_PersID: values.financieleContactpersoon,
      };

      const result = await api.v2.relatie.wijzigenRelatie(params);

      await klantkaartStore.verversRelatie();
      await ophalenRelatie();

      klantkaartStore.ophalenContactpersonenVoorlijst({
        relID: props.relID,
        sortering: 1,
      });

      actions.setSubmitting(false);
    },
    [],
  );

  const validateForm = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.adres === null) {
      errors.adres = teksten.formulier.E_VERPLICHT_VELD({
        veldnaam: veldnamen.adres!,
      });
    }
    return errors;
  }, []);

  // const validationSchema = useMemo(
  //   () =>
  //     Yup.object().shape({
  //       organisatieTelefoonAlgemeen: Yup.number()
  //         .nullable(true)
  //         .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Context' })),
  //     }),
  //   [],
  // );

  return (
    <>
      <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
        {initialValues === null ? (
          <LoadingSpinner />
        ) : (
          <div
            className="flex-fill d-flex flex-column"
            style={{
              width: '100%',
            }}
          >
            <Formik<IFormikValues>
              ref={formikRef}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validate={validateForm}
              // validationSchema={validationSchema}
              render={(formikProps: FormikProps<IFormikValues>) => {
                const {
                  submitForm,
                  isSubmitting,
                  isValid,
                  dirty,
                  resetForm,
                  values,
                  errors,
                } = formikProps;
                return (
                  <div className="d-flex flex-column flex-fill">
                    <div className="row p-4">
                      <div className="col-12">
                        <UitklapFormulier
                          uitgeklapt={urlState.algemeenTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('algemeenTonen', x)}
                          label="Algemeen"
                          formulier={
                            <div className="row">
                              <div className="col-7">
                                <div className="row">
                                  <div className="col-9">
                                    <Field
                                      name={nameOf<IFormikValues>('primaireContactpersoon')}
                                      render={(fieldProps: FieldProps<IFormikValues>) => {
                                        const { field, form } = fieldProps;
                                        return (
                                          <>
                                            <div>{veldnamen.primaireContactpersoon}</div>

                                            {/*TODO Fix*/}
                                            {klantkaartStore.contactpersonen.length > 0 ? (
                                              <Combobox
                                                geselecteerd={field.value}
                                                onSelectieChange={(x) => {
                                                  form.setFieldValue(field.name, x);
                                                }}
                                                opties={klantkaartStore.contactpersonen.map(
                                                  (contactPersoon) => {
                                                    return {
                                                      id: contactPersoon.PersID,
                                                      label: formatteerRelatieNaam({
                                                        aanhefKort:
                                                          contactPersoon.geslacht.AanhefKort,
                                                        achternaam: contactPersoon.Achternaam,
                                                        voorletters: contactPersoon.Voorletters,
                                                        voornaam: contactPersoon.Voornaam,
                                                        voorvoegsel: contactPersoon.Voorvoegsel,
                                                      }),
                                                    };
                                                  },
                                                )}
                                              />
                                            ) : (
                                              <>
                                                <LoadingSpinner />
                                              </>
                                            )}
                                          </>
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="col-9 mt-3">
                                    <Field
                                      name={nameOf<IFormikValues>('financieleContactpersoon')}
                                      render={(fieldProps: FieldProps<IFormikValues>) => {
                                        const { field, form } = fieldProps;
                                        return (
                                          <>
                                            <div>{veldnamen.financieleContactpersoon}</div>

                                            {klantkaartStore.contactpersonen.length > 0 ? (
                                              <Combobox
                                                geselecteerd={field.value}
                                                onSelectieChange={(x) => {
                                                  form.setFieldValue(field.name, x);
                                                }}
                                                opties={klantkaartStore.contactpersonen.map(
                                                  (contactPersoon) => {
                                                    return {
                                                      id: contactPersoon.PersID,
                                                      label: formatteerRelatieNaam({
                                                        aanhefKort:
                                                          contactPersoon.geslacht.AanhefKort,
                                                        achternaam: contactPersoon.Achternaam,
                                                        voorletters: contactPersoon.Voorletters,
                                                        voornaam: contactPersoon.Voornaam,
                                                        voorvoegsel: contactPersoon.Voorvoegsel,
                                                      }),
                                                    };
                                                  },
                                                )}
                                                isWisbaar
                                              />
                                            ) : (
                                              <>
                                                <LoadingSpinner />
                                              </>
                                            )}
                                          </>
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="col-9 mt-3">
                                    <Field
                                      name={nameOf<IFormikValues>('vertegenwoordigerID')}
                                      render={(fieldProps: FieldProps<IFormikValues>) => {
                                        const { field, form } = fieldProps;

                                        if (vertegenwoordigers === null) {
                                          return <LoadingSpinner />;
                                        }

                                        return (
                                          <>
                                            <div>{veldnamen.vertegenwoordigerID}</div>

                                            <Combobox
                                              geselecteerd={field.value}
                                              onSelectieChange={(x) => {
                                                form.setFieldValue(field.name, x);
                                              }}
                                              opties={vertegenwoordigers.map(
                                                (vertegenwoordiger) => {
                                                  return {
                                                    id: vertegenwoordiger.relatie.RelID,
                                                    label:
                                                      vertegenwoordiger.relatie.weergavenaam +
                                                      ' (' +
                                                      vertegenwoordiger.hoedanigheid.Naam +
                                                      ')',
                                                  };
                                                },
                                              )}
                                              isWisbaar
                                              // options={{
                                              //   legeOptieTekst:
                                              //     'Maak een keuze (leeg is geen vertegenwoordiger)',
                                              // }}
                                            />
                                          </>
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="col-9 mt-3">
                                    <div className="row">
                                      <div className="col-12">
                                        <label>{veldnamen.vasteInfo}</label>
                                        <Field
                                          name={nameOf<IFormikValues>('vasteInfo')}
                                          render={(fieldProps: FieldProps<IFormikValues>) => {
                                            const { field, form } = fieldProps;

                                            return (
                                              <textarea
                                                rows={3}
                                                {...field}
                                                className="form-control w-100"
                                              />
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row col-12 mt-3">
                                    <div className="col-12">
                                      <label>{veldnamen.externeRelatiemapLink}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('externeRelatiemapLink')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                              placeholder={
                                                'B.v. een link naar een Sharepoint-locatie'
                                              }
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row col-12 mt-3">
                                    <div className="col-6">
                                      <label>{veldnamen.overnamecode} (min. 6 tekens)</label>
                                      <Field
                                        name={nameOf<IFormikValues>('overnamecode')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                              placeholder={'B.v. BIERKRAT of 3581KW'}
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row col-12 mt-3 d-flex">
                                    <div className="col-6">
                                      <label>{veldnamen.wervingscode}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('wervingscode')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>

                                    <div className="col-4 mt-4">
                                      <div className="d-flex align-items-center">
                                        <Field
                                          name={nameOf<IFormikValues>('wervingscodeOngeldig')}
                                          render={(fieldProps: FieldProps<IFormikValues>) => {
                                            const { field, form } = fieldProps;
                                            return (
                                              <VinkVeld
                                                aangevinkt={field.value}
                                                onGewijzigd={(x) =>
                                                  form.setFieldValue(field.name, x)
                                                }
                                              />
                                            );
                                          }}
                                        />
                                        <span className="ml-2">
                                          {veldnamen.wervingscodeOngeldig}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex col-12 mt-4">
                                    <Field
                                      name={nameOf<IFormikValues>('nietGebruiken')}
                                      render={(fieldProps: FieldProps<IFormikValues>) => {
                                        const { field, form } = fieldProps;
                                        return (
                                          <VinkVeld
                                            aangevinkt={field.value}
                                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                          />
                                        );
                                      }}
                                    />
                                    <span className="ml-2">{veldnamen.nietGebruiken}</span>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="d-flex align-items-center">
                                      <Field
                                        name={nameOf<IFormikValues>('geblokkeerd')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;
                                          return (
                                            <VinkVeld
                                              aangevinkt={field.value}
                                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                            />
                                          );
                                        }}
                                      />
                                      <span className="ml-2">{veldnamen.geblokkeerd}</span>
                                    </div>
                                  </div>

                                  {/* <div className="col-12 mt-3">
                                    <div className="d-flex align-items-center">
                                      <Field
                                        name={nameof<IFormikValues>('wervingscodeOngeldig')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;
                                          return (
                                            <VinkVeld
                                              aangevinkt={field.value}
                                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                            />
                                          );
                                        }}
                                      />
                                      <span className="ml-2">{veldnamen.wervingscodeOngeldig}</span>
                                    </div>
                                  </div> */}

                                  <div className="col-12 mt-3">
                                    <div className="d-flex align-items-center">
                                      <Field
                                        name={nameOf<IFormikValues>('geenReviewUitnodiging')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;
                                          return (
                                            <VinkVeld
                                              aangevinkt={field.value}
                                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                            />
                                          );
                                        }}
                                      />
                                      <span className="ml-2">
                                        {veldnamen.geenReviewUitnodiging}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-12 mt-3">
                                    <div className="d-flex align-items-center">
                                      <Field
                                        name={nameOf<IFormikValues>('geenEigenGebruik')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;
                                          return (
                                            <VinkVeld
                                              aangevinkt={field.value}
                                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                            />
                                          );
                                        }}
                                      />
                                      <span className="ml-2">{veldnamen.geenEigenGebruik}</span>
                                    </div>
                                  </div>

                                  <div className="col-12 mt-3">
                                    <div className="d-flex align-items-center">
                                      <Field
                                        name={nameOf<IFormikValues>(
                                          'documentatieVersturenNaLevering',
                                        )}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;
                                          return (
                                            <VinkVeld
                                              aangevinkt={field.value}
                                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                            />
                                          );
                                        }}
                                      />
                                      <span className="ml-2">
                                        {veldnamen.documentatieVersturenNaLevering}
                                      </span>
                                    </div>
                                  </div>

                                  {/* <div className="col-6 mt-3">
                                    <label>{veldnamen.bankmutatieReferentie}</label>
                                    <Field
                                      name={nameof<IFormikValues>('bankmutatieReferentie')}
                                      render={(fieldProps: FieldProps<IFormikValues>) => {
                                        const { field, form } = fieldProps;

                                        return (
                                          <input {...field} className="form-control" type="text" />
                                        );
                                      }}
                                    />
                                  </div> */}

                                  {relatie !== null && relatie.Relatiesoort === 'O' && (
                                    <>
                                      <div className="col-12 mt-3">
                                        <div className="row">
                                          <div className="col-7">
                                            <label>{veldnamen.organisatienaam}</label>
                                            <Field
                                              name={nameOf<IFormikValues>('organisatienaam')}
                                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                                const { field, form } = fieldProps;

                                                return (
                                                  <input
                                                    {...field}
                                                    className="form-control"
                                                    type="text"
                                                  />
                                                );
                                              }}
                                            />
                                          </div>

                                          <div className="col-5">
                                            <label>{veldnamen.organisatienaamKort}</label>
                                            <Field
                                              name={nameOf<IFormikValues>('organisatienaamKort')}
                                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                                const { field, form } = fieldProps;

                                                return (
                                                  <input
                                                    {...field}
                                                    className="form-control"
                                                    type="text"
                                                  />
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 mt-3">
                                        <div className="row">
                                          <div className="col-7">
                                            <label>{veldnamen.organisatieEmail}</label>
                                            <Field
                                              name={nameOf<IFormikValues>('organisatieEmail')}
                                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                                const { field, form } = fieldProps;

                                                return (
                                                  <input
                                                    {...field}
                                                    className="form-control"
                                                    type="text"
                                                  />
                                                );
                                              }}
                                            />
                                          </div>

                                          <div className="col-5">
                                            <label>{veldnamen.organisatieTelefoonAlgemeen}</label>
                                            <Field
                                              name={nameOf<IFormikValues>(
                                                'organisatieTelefoonAlgemeen',
                                              )}
                                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                                const { field, form } = fieldProps;

                                                // return (
                                                //   <input
                                                //     {...field}
                                                //     className="form-control"
                                                //     type="text"
                                                //   />
                                                // );

                                                return (
                                                  <TelefoonnummerInput
                                                    telefoonnummer={field.value}
                                                    onChange={(x) =>
                                                      form.setFieldValue(field.name, x)
                                                    }
                                                    // suggestiesProvider={
                                                    //   telefoonnummerInputSuggestiesProvider
                                                    // }
                                                  />
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-9 mt-3">
                                        <label>{veldnamen.websiteURL}</label>
                                        <Field
                                          name={nameOf<IFormikValues>('websiteURL')}
                                          render={(fieldProps: FieldProps<IFormikValues>) => {
                                            const { field, form } = fieldProps;

                                            return (
                                              <input
                                                {...field}
                                                className="form-control"
                                                type="text"
                                              />
                                            );
                                          }}
                                        />
                                      </div>

                                      <div className="col-12 mt-3">
                                        <div className="row">
                                          <div className="col-5">
                                            <label>{veldnamen.btwnummer}</label>
                                            <Field
                                              name={nameOf<IFormikValues>('btwnummer')}
                                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                                const { field, form } = fieldProps;

                                                return (
                                                  <input
                                                    {...field}
                                                    className="form-control"
                                                    type="text"
                                                  />
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 mt-3">
                                        <div className="row">
                                          <div className="col-5">
                                            <label>{veldnamen.kvknummer}</label>
                                            <Field
                                              name={nameOf<IFormikValues>('kvknummer')}
                                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                                const { field, form } = fieldProps;

                                                return (
                                                  <input
                                                    {...field}
                                                    className="form-control"
                                                    type="text"
                                                  />
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <UitklapFormulier
                          uitgeklapt={urlState.adresTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('adresTonen', x)}
                          label={veldnamen.adres!}
                          formulier={
                            <>
                              <Field
                                name={nameOf<IFormikValues>('adres')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <AdresFormulier
                                      values={field.value}
                                      onChange={(x) => form.setFieldValue(field.name, x)}
                                    />
                                  );
                                }}
                              />
                            </>
                          }
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <UitklapFormulier
                          uitgeklapt={urlState.debiteurTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('debiteurTonen', x)}
                          label="Debiteur"
                          formulier={
                            <div className="row">
                              <div
                                className="col-12 d-flex align-items-center"
                                style={{
                                  height: 35,
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <Field
                                    name={nameOf<IFormikValues>('nietAanmanen')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">{veldnamen.nietAanmanen}</span>
                                </div>

                                {values.nietAanmanen && (
                                  <>
                                    <span>&nbsp;tot</span>

                                    <span className="ml-2">
                                      <Field
                                        name={nameOf<IFormikValues>('datumNietAanmanen')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;
                                          return (
                                            <DatumKiezer
                                              waarde={field.value}
                                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                              placeholder="tot nader order"
                                              isClearable
                                              determineValidDate={() => {
                                                return true;
                                              }}
                                              determinePreviousValidDate="ONBEGRENST"
                                              determineNextValidDate="ONBEGRENST"
                                            />
                                          );
                                        }}
                                      />
                                    </span>
                                  </>
                                )}
                              </div>

                              <div className="col-3 mt-3">
                                <label>{veldnamen.maximaalAantalAanmaningen}</label>

                                <Field
                                  name={nameOf<IFormikValues>('maximaalAantalAanmaningen')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;

                                    const opties = [
                                      { id: 1, label: '1' },
                                      { id: 2, label: '2' },
                                      { id: 3, label: '3' },
                                    ];

                                    return (
                                      <Combobox
                                        geselecteerd={field.value}
                                        onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                        legeOptieTonen
                                        options={{
                                          legeOptieTekst: 'Standaard',
                                        }}
                                        opties={opties}
                                      />
                                    );
                                  }}
                                />
                              </div>

                              <div className="row d-flex col-12 mt-3">
                                <div className="col-3">
                                  <label>{veldnamen.betWijzeID}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('betWijzeID')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (betaalwijzen === null) {
                                        return <LoadingSpinner />;
                                      }

                                      return (
                                        <Combobox
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={betaalwijzen.map((x) => {
                                            return {
                                              id: x.BetWijzeID,
                                              label: x.Naam,
                                            };
                                          })}
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-3">
                                  <label>{veldnamen.betaaldag}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('betaaldag')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (betaaldagen === null) {
                                        return <LoadingSpinner />;
                                      }

                                      return (
                                        <Combobox
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={betaaldagen.map((x) => {
                                            return {
                                              id: x,
                                              label: x !== -1 ? String(x) : 'Stufidag',
                                            };
                                          })}
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-3">
                                  <label>{veldnamen.betalingstermijn} in dagen</label>
                                  <Field
                                    name={nameOf<IFormikValues>('betalingstermijn')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <div>
                                          <NumeriekVeld
                                            waarde={field.value}
                                            onChange={(x) => form.setFieldValue(field.name, x)}
                                            min={0}
                                            max={65}
                                            inactiefRepresentatie={(x) => {
                                              if (field.value === 0) {
                                                return 'Standaard';
                                              }
                                              return `${field.value}`;
                                            }}
                                          />
                                          <FormikVeldFout fieldProps={fieldProps} />
                                        </div>
                                      );
                                    }}
                                  />
                                </div>

                                {/* <div className="col-3">
                                  <label>{veldnamen.betalingstermijn}</label>

                                  <Field
                                    name={nameof<IFormikValues>('betalingstermijn')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (betalingstermijnen === null) {
                                        return <LoadingSpinner />;
                                      }

                                      return (
                                        <Combobox
                                          isWisbaar
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={betalingstermijnen.map((x) => {
                                            return {
                                              id: x,
                                              label: String(x),
                                            };
                                          })}
                                          options={{
                                            legeOptieTekst: 'Standaard',
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                </div> */}
                              </div>

                              <div className="row d-flex col-12 mt-3">
                                <div className="col-3">
                                  <label>{veldnamen.factuurniveau}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('factuurniveau')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <Combobox
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={factuurniveaus!.map((x: any) => {
                                            return {
                                              id: x.Niveau,
                                              label: x.Naam,
                                            };
                                          })}
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-3">
                                  <label>{veldnamen.optieFactuurVersturen}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('optieFactuurVersturen')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <Combobox
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          // opties={factuurniveaus!.map((x: any) => {
                                          //   return {
                                          //     id: x.Niveau,
                                          //     label: x.Naam,
                                          //   };
                                          // })}
                                          opties={optiesFactuurVersturen}
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-3">
                                  <label>{veldnamen.verzendwijzeFacturen}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('verzendwijzeFacturen')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (factuurVerzendwijzenResult === null) {
                                        return;
                                      }

                                      return (
                                        <Combobox
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          legeOptieTonen
                                          options={{
                                            legeOptieTekst: 'Standaard',
                                          }}
                                          opties={factuurVerzendwijzenResult.map((x: any) => {
                                            return {
                                              id: x.ID,
                                              label: x.Naam,
                                            };
                                          })}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row col-12 mt-3">
                                <div className="col-3">
                                  <label>{veldnamen.factuurKenmerk}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('factuurKenmerk')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <input {...field} className="form-control" type="text" />
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-6">
                                  <label>{veldnamen.toevoegingNaamOpFactuur}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('toevoegingNaamOpFactuur')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <input
                                          {...field}
                                          className="form-control"
                                          type="text"
                                          placeholder={
                                            'B.v. Afdeling OnsGeld of T.a.v. Dagobert Duck'
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row col-12 mt-3">
                                <div className="col-6 d-flex align-items-center">
                                  <Field
                                    name={nameOf<IFormikValues>('eenFactuurPerEmail')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2 mr-2">{veldnamen.eenFactuurPerEmail}</span>
                                  <UitlegTooltip inhoud="Indien dit aangevinkt is wordt er bij het versturen van facturen één email per factuur verstuurd, anders één email met alle facturen." />
                                </div>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('csvSpecificatieMeesturen')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2">{veldnamen.csvSpecificatieMeesturen}</span>
                              </div>

                              <div className="row col-12 mt-3 d-flex align-items-center">
                                <div className="col-3">
                                  <label>{veldnamen.prolongatieTermijn}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('prolongatieTermijn')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <Combobox
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={prolongatieTermijnen!.map((x) => {
                                            return {
                                              id: x,
                                              label: String(x),
                                            };
                                          })}
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-3 d-flex align-items-center mt-4">
                                  <Field
                                    name={nameOf<IFormikValues>('prolongatieOphouden')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">{veldnamen.prolongatieOphouden}</span>
                                </div>
                              </div>

                              <div className="col-6 mt-3 align-items-center">
                                <label>{veldnamen.emailFactuur}</label>
                                <Field
                                  name={nameOf<IFormikValues>('emailFactuur')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;

                                    return (
                                      <input
                                        {...field}
                                        className="form-control"
                                        type="text"
                                        placeholder={
                                          'Afwijkend emailadres voor versturen van de facturen'
                                        }
                                      />
                                    );
                                  }}
                                />
                              </div>

                              <div className="col-6 mt-3 align-items-center">
                                <label>{veldnamen.emailAanmaning}</label>
                                <Field
                                  name={nameOf<IFormikValues>('emailAanmaning')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;

                                    return (
                                      <input
                                        {...field}
                                        className="form-control"
                                        type="text"
                                        placeholder={
                                          'Afwijkend emailadres voor versturen van aanmaningen'
                                        }
                                      />
                                    );
                                  }}
                                />
                              </div>

                              <div className="row col-12 mt-3 d-flex align-items-center">
                                <div className="col-3">
                                  <label>Grensbedrag incasso (0 = Geen)</label>
                                  <Field
                                    name={nameOf<IFormikValues>('grensbedragIncasso')}
                                    render={(x: FieldProps<IFormikValues>) => {
                                      return (
                                        <div>
                                          <BedragInput
                                            value={x.field.value}
                                            onChange={(bedrag) => {
                                              x.form.setFieldValue(x.field.name, bedrag);
                                            }}
                                          />
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('resttermijnenDirect')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2">{veldnamen.resttermijnenDirect}</span>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('incassomachtiging')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2">{veldnamen.incassomachtiging}</span>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('incassoOpdrachtWeb')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2">{veldnamen.incassoOpdrachtWeb}</span>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('betalingsregeling')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2">{veldnamen.betalingsregeling}</span>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('leveringBijVervallenSaldo')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2">{veldnamen.leveringBijVervallenSaldo}</span>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('nietIndexeren')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2">{veldnamen.nietIndexeren}</span>
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>(
                                    'factuurregeldataToevoegenBijVersturen',
                                  )}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2 mr-2">
                                  {veldnamen.factuurregeldataToevoegenBijVersturen}
                                </span>
                                <UitlegTooltip inhoud="Voegt een CSV bestand toe met de factuurregels naast de factuur PDF" />
                              </div>

                              <div className="col-12 mt-3 d-flex align-items-center">
                                <Field
                                  name={nameOf<IFormikValues>('geenWOPVordering')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                    );
                                  }}
                                />
                                <span className="ml-2 mr-2">{veldnamen.geenWOPVordering}</span>
                                <UitlegTooltip inhoud="Een WOP-vorderding maken is niet toegestaan. Gebruik hier alijd een directe vordering." />
                              </div>
                            </div>
                          }
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <UitklapFormulier
                          uitgeklapt={urlState.serviceTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('serviceTonen', x)}
                          label={'Service'}
                          formulier={
                            <>
                              <div className="row">
                                <div className="col-6 mt-2">
                                  <label>{veldnamen.servDienstID}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('servDienstID')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (servicediensten === null) {
                                        return <LoadingSpinner />;
                                      }
                                      return (
                                        <>
                                          <MultiCombobox<
                                            number,
                                            IOphalenServicedienstenResultElement
                                          >
                                            sleutelExtractor={(x) => x.ID}
                                            representatieFabriek={(x) =>
                                              `${x.relatie!.weergavenaam}`
                                            }
                                            waarde={field.value}
                                            onWaardeChange={(x) => {
                                              return form.setFieldValue(field.name, x);
                                            }}
                                            opties={servicediensten}
                                            kolommen={servicedienstenKolommen}
                                            isWisbaar
                                          />

                                          {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                                        </>
                                      );
                                    }}
                                  />
                                </div>

                                <div className="d-flex col-12 mt-4">
                                  <Field
                                    name={nameOf<IFormikValues>('servDienstOngeachtGarantie')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">
                                    {veldnamen.servDienstOngeachtGarantie}
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <UitklapFormulier
                          uitgeklapt={urlState.websiteTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('websiteTonen', x)}
                          label={'Website'}
                          formulier={
                            <>
                              <div className="row">
                                <div className="col-3">
                                  <label>{veldnamen.contractwisselToestaanWebsite}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('contractwisselToestaanWebsite')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <Combobox
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          // opties={factuurniveaus!.map((x: any) => {
                                          //   return {
                                          //     id: x.Niveau,
                                          //     label: x.Naam,
                                          //   };
                                          // })}
                                          opties={optiesContractwisselToestaan}
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                {/* <div className="d-flex col-12 mt-4">
                                  <Field
                                    name={nameof<IFormikValues>('servDienstOngeachtGarantie')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">
                                    {veldnamen.servDienstOngeachtGarantie}
                                  </span>
                                </div> */}
                              </div>
                            </>
                          }
                        />
                      </div>
                    </div>
                    <div className="flex-fill" />

                    <code className="m-4"></code>
                    <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />

                    <div className="d-flex flex-row justify-content-start p-3">
                      <button
                        className="btn btn-primary"
                        style={{
                          width: 100,
                        }}
                        disabled={isSubmitting || !isValid}
                        onClick={submitForm}
                      >
                        Ok
                      </button>
                      <button
                        className="btn btn-secondary ml-2"
                        style={{
                          width: 100,
                        }}
                        onClick={async () => {
                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud:
                                  'Bij het annuleren van deze actie zullen alle waarden worden hersteld',
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          resetForm();
                        }}
                        disabled={!dirty}
                      >
                        Annuleren
                      </button>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
});

const Klantgegevens = withRouter(KlantgegevensComp);
export default Klantgegevens;
