import { IOphalenGrootboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import api from '../../../../../api';

export interface IGrootboekenProvider {
  provide: () => Promise<IOphalenGrootboekenResultElement[]>;
}

export const standaardGrootboekenProvider: IGrootboekenProvider = {
  provide: async () => {
    const result = await api.v2.boeking.grootboek.ophalenGrootboeken({
      filterSchema: {
        filters: [],
      },
    });

    return result.grootboeken;
  },
};
