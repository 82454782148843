import * as React from 'react';
import { SVGProps, useCallback, useMemo, useRef, useState } from 'react';
import TekstSuggestieVeld, { IInputComponentProps } from '../TekstSuggestieVeld';
import api from '../../../api';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconTarget,
  VlagBE,
  VlagBR,
  VlagCN,
  VlagDE,
  VlagEN,
  VlagES,
  VlagFR,
  VlagIT,
  VlagMX,
  VlagNL,
  VlagRU,
  VlagUS,
} from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import SelectieDialoog from './SelectieDialoog';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';
import { ETelefoonLand } from '../../../bedrijfslogica/enums';
import { observer } from 'mobx-react-lite';
import useActieveOproep from '../../../helpers/communicatie/telefoon/useActieveOproep';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';

export interface ISuggestiesProvider {
  provide: (invoer: string) => Promise<string[] | null>;
}

const standaardSuggestiesProvider: ISuggestiesProvider = {
  provide: async (_) => null,
};

export const genereerRecenteOproepenSuggestiesProvider = async (
  aspGebrID: number,
): Promise<ISuggestiesProvider> => {
  const result = await api.v2.telefonie.ophalenTelefoonOproepen({
    filterSchema: {
      filters: [
        {
          naam: 'ASP_GEBR_IDS_IN',
          data: [aspGebrID],
        },
      ],
    },
    paginatie: {
      index: 0,
      aantal: 5,
    },
    orderSchema: {
      orders: [
        {
          naam: 'DATUM',
          richting: 'DESC',
        },
      ],
    },
  });

  return {
    provide: async (invoer) => {
      return result.oproepen
        .filter((x) => x.Nummer?.includes(invoer) ?? false)
        .map((x) => x.Nummer!);
    },
  };
};

interface IProps {
  telefoonnummer: string | null;
  onChange: (value: string | null) => void;
  suggestiesProvider?: ISuggestiesProvider;
}

const TelefoonnummerInput = observer((props: IProps) => {
  const [selectieDialoogOpen, setSelectieDialoogOpen] = useState(false);
  const [isFocussed, setIsFocussed] = useState(false);
  const [text, setText] = useState<string>(props.telefoonnummer ?? '');

  useBijGewijzigdEffect(() => {
    if (isFocussed || props.telefoonnummer === text) {
      return;
    }
    setText(props.telefoonnummer ?? '');
  }, [props.telefoonnummer, isFocussed]);

  const suggestiesProvider = useMemo(
    () => props.suggestiesProvider ?? standaardSuggestiesProvider,
    [props.suggestiesProvider],
  );

  const suggestieResolver = useCallback(
    async (invoer) => {
      return await suggestiesProvider.provide(invoer);
    },
    [suggestiesProvider],
  );

  const inputComponent = useMemo<React.ForwardRefRenderFunction<any, IInputComponentProps>>(
    () => (inputProps) => (
      <input
        {...inputProps}
        className="form-control"
        onBlur={(ev) => {
          inputProps.onBlur?.(ev);
          setIsFocussed(false);
        }}
        autoFocus
      />
    ),
    [setIsFocussed],
  );

  const telefoonLand = useMemo(() => {
    if (props.telefoonnummer === null) {
      return null;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    let nummer;
    try {
      nummer = phoneUtil.parseAndKeepRawInput(props.telefoonnummer);
    } catch (err) {
      alert(
        `Het telefoonnummer wat is meegegeven aan invoerveld is niet geldig (${props.telefoonnummer}). Het moet voldoen aan een E.164 formaat (e.g. +31612345678). Pas deze aan.`,
      );
      return null;
    }
    const code = nummer.getCountryCode();

    switch (code) {
      case 31:
        return ETelefoonLand.Nederland;
      case 49:
        return ETelefoonLand.Duitsland;
      case 33:
        return ETelefoonLand.Frankrijk;
      case 44:
        return ETelefoonLand.VerenigdKoninkrijk;
      case 39:
        return ETelefoonLand.Italie;
      case 32:
        return ETelefoonLand.Belgie;
      case 34:
        return ETelefoonLand.Spanje;
      case 86:
        return ETelefoonLand.China;
      case 7:
        return ETelefoonLand.Rusland;
      case 1:
        return ETelefoonLand.VerenigdeStaten;
      case 55:
        return ETelefoonLand.Brazilie;
      case 52:
        return ETelefoonLand.Mexico;
    }

    return null;
    // throw new Error(`Onbekende landcode +${code}`);
  }, [props.telefoonnummer]);

  const actieveOproepOutput = useActieveOproep();

  const actieveOproepKnop = useMemo(() => {
    if (actieveOproepOutput === null || actieveOproepOutput.actieveOproep.Nummer === null) {
      return null;
    }

    const Icon = functioneleIconMap[EFunctioneleIcon.Context];

    return (
      <button
        title={`Telefoonnummer '${actieveOproepOutput.actieveOproep.Nummer}' van actieve oproep invullen`}
        style={{
          position: 'absolute',
          right: 25,
          top: 0,
          outline: 0,
          border: 0,
          background: 0,
          padding: '5px 5px',
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          props.onChange(actieveOproepOutput!.actieveOproep.Nummer!);
        }}
      >
        <Icon
          style={{
            width: 18,
            height: 18,
            fill: Kleur.Grijs,
          }}
        />
      </button>
    );
  }, [actieveOproepOutput?.actieveOproep, props.onChange]);

  const selectieKnop = useMemo(() => {
    return (
      <button
        style={{
          position: 'absolute',
          right: 3,
          top: 1,
          outline: 0,
          border: 0,
          background: 0,
          padding: '5px 5px',
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          setSelectieDialoogOpen(true);
        }}
        title="Telefoonnummer selecteren"
      >
        <IconTarget
          style={{
            width: 18,
            height: 18,
            fill: Kleur.Grijs,
          }}
        />
      </button>
    );
  }, [setSelectieDialoogOpen]);

  const Vlag = useMemo<React.ComponentType<SVGProps<any>> | null>(() => {
    if (telefoonLand === null) {
      return null;
    }

    switch (telefoonLand) {
      case ETelefoonLand.Italie:
        return VlagIT;
      case ETelefoonLand.Belgie:
        return VlagBE;
      case ETelefoonLand.Spanje:
        return VlagES;
      case ETelefoonLand.China:
        return VlagCN;
      case ETelefoonLand.Rusland:
        return VlagRU;
      case ETelefoonLand.VerenigdeStaten:
        return VlagUS;
      case ETelefoonLand.Nederland:
        return VlagNL;
      case ETelefoonLand.Duitsland:
        return VlagDE;
      case ETelefoonLand.Frankrijk:
        return VlagFR;
      case ETelefoonLand.VerenigdKoninkrijk:
        return VlagEN;
      case ETelefoonLand.Brazilie:
        return VlagBR;
      case ETelefoonLand.Mexico:
        return VlagMX;
    }
  }, [telefoonLand]);

  const handleChange = useCallback(
    (newText: string) => {
      setText(newText);

      const phoneUtil = PhoneNumberUtil.getInstance();
      let nummer: PhoneNumber | null = null;
      try {
        nummer = phoneUtil.parseAndKeepRawInput(newText);
      } catch (err) {
        try {
          nummer = phoneUtil.parseAndKeepRawInput(newText, 'NL');
        } catch (err) {}
      }

      if (nummer === null) {
        if (props.telefoonnummer !== null) {
          props.onChange(null);
        }
        return;
      }

      const nummerString = phoneUtil.format(nummer, PhoneNumberFormat.E164);
      if (props.telefoonnummer !== nummerString) {
        props.onChange(nummerString);
      }
    },
    [setText, props.telefoonnummer, props.onChange],
  );

  console.log(props, text, isFocussed);

  return (
    <>
      {isFocussed ? (
        <div
          style={{
            position: 'relative',
          }}
          className="d-flex"
        >
          <TekstSuggestieVeld
            waarde={text}
            onChange={handleChange}
            suggestiesResolver={suggestieResolver}
            inputComponent={inputComponent}
          />
          {selectieKnop}
        </div>
      ) : props.telefoonnummer === null ? (
        <div
          style={{
            position: 'relative',
          }}
          className="d-flex"
        >
          <input
            value=""
            placeholder="+31612345678"
            onFocus={() => setIsFocussed(true)}
            className="form-control"
          />
          {actieveOproepKnop}
          {selectieKnop}
        </div>
      ) : (
        <div
          style={{
            position: 'relative',
          }}
          className="d-flex"
        >
          <input
            value={`${Vlag === null ? '' : '        '}${props.telefoonnummer}`}
            // onChange={(ev) => {}}
            onFocus={() => setIsFocussed(true)}
            className="form-control"
          />
          {Vlag !== null && (
            <div
              style={{
                position: 'absolute',
                left: 5,
                top: 1,
                outline: 0,
                border: 0,
                background: 0,
                padding: '6px 6px',
                fontSize: 12,
              }}
            >
              <Vlag
                style={{
                  width: 16,
                  height: 11,
                }}
              />
            </div>
          )}
          {selectieKnop}
        </div>
      )}
      {selectieDialoogOpen && (
        <SelectieDialoog
          open
          dialoogIndex={3}
          onSuccess={(result) => {
            setSelectieDialoogOpen(false);

            handleChange(result.telefoonnummer);
          }}
          onAnnuleren={() => setSelectieDialoogOpen(false)}
        />
      )}
    </>
  );
});

export default TelefoonnummerInput;
