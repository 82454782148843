import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../api';
import nameof from '../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import Combobox from '../../../components/formulier/Combobox';
import { EResultType } from '../../../stores/CheckStore';
import MultiCombobox, { IKolom } from '../../../components/formulier/MultiCombobox';
import MultiSelect, { IOptie } from '../../formulier/MultiSelect';

interface IProps extends IDialoogProps<{}> {
  ids: number[];
}

interface IFormikValues {
  status: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  status: 'Status',
};

const WijzigenStatusDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  // const [materiaal, setMateriaal] = useState<IOphalenMaterialenResultElement | null>(null);

  // const ophalenMateriaal = useCallback(async () => {
  //   const result = (
  //     await api.v2.sponsoring.materiaal.ophalenMaterialen({
  //       filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
  //     })
  //   ).materialen[0];
  //   setMateriaal(result);
  // }, [props.id]);

  // useEffect(() => {
  //   ophalenMateriaal();
  // }, [ophalenMateriaal]);

  const opties = [
    { key: 0, weergave: 'Onbekend' },
    { key: 1, weergave: 'Geaccepteerd' },
    { key: 2, weergave: 'Afgewezen' },
  ];

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      status: 0,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        ids: props.ids,
        status: values.status,
      };
      await api.v2.product.verkoop.wijzigenStatusVerkoopvoorstellen(params);

      actions.setSubmitting(false);
      onSuccess({});
    },
    [props.ids],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      status: Yup.number().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen status</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isInitialValid
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.status}</label>
                        <Field
                          name={nameof<IFormikValues>('status')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={opties.map((x) => {
                                  return {
                                    id: x.key,
                                    label: x.weergave,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenStatusDialoog;
