import {
  IOphalenProductenParams,
  IOphalenProductenResult,
} from '../../../../../../shared/src/api/v2/retourverwerking/uitBedrijf';
import api from '../../../index';

const uitBedrijf = {
  ophalenProducten: async (params: IOphalenProductenParams): Promise<IOphalenProductenResult> => {
    return await api.post('/v2/retourverwerking/uitbedrijf/ophalen-producten', params);
  },
};

export default uitBedrijf;
