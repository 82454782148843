import styled from 'styled-components';
import { Kleur } from '../../bedrijfslogica/constanten';

export const Root = styled.div`
  width: 100%;
  min-height: 21px;
  font-size: 13px;

  .ongekoppeld-container {
    a {
      color: ${Kleur.Oranje};
    }
  }

  .gekoppeld-container {
    display: flex;
    align-items: center;

    .dropdown {
      .dropdown-toggle {
        padding: 0;
        background-color: transparent;
        border: 0;
        margin-left: 5px;
        padding-right: 5px;
        &:after {
          display: none;
        }
      }
    }
  }
`;

export const OverlayLink = styled.span`
  cursor: pointer;
  color: #4c8fff;
  &:hover {
    text-decoration: underline;
  }
`;
