import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { Root } from './style';
import Advies from './Advies';
import Staat from './Staat';

export enum ETabblad {
  Staat = 1,
  Advies = 2,
}

interface IProps extends RouteComponentProps {}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Staat]: '/staat',
  [ETabblad.Advies]: '/advies',
};

const Productadvies: React.FC<IProps> = observer((props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Staat]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Staat,
        label: 'Productstaat',
        content: Staat,
      },
      // {
      //   id: ETabblad.Advies,
      //   label: 'Adviezen',
      //   content: Advies,
      // },
    ],
    [],
  );

  return (
    <Root>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </Root>
  );
});

export default Productadvies;
