import {
  IOphalenEmailsParams,
  IOphalenEmailsResult,
  IOphalenMailMappenParams,
  IOphalenMailMappenResult,
  IMuterenEmailRelatieContextParams,
  IMuterenEmailRelatieContextResult,
  IOphalenEmailGebrXAspGebrsParams,
  IOphalenEmailGebrXAspGebrsResult,
} from '../../../../../shared/src/api/v2/email-v2';
import api from '../../index';

const emailV2 = {
  ophalenEmails: async (params: IOphalenEmailsParams): Promise<IOphalenEmailsResult> => {
    return await api.post('/v2/email-v2/ophalen-emails', params);
  },
  ophalenMailMappen: async (
    params: IOphalenMailMappenParams,
  ): Promise<IOphalenMailMappenResult> => {
    return await api.post('/v2/email-v2/ophalen-mail-mappen', params);
  },
  muterenEmailRelatieContext: async (
    params: IMuterenEmailRelatieContextParams,
  ): Promise<IMuterenEmailRelatieContextResult> => {
    return await api.post('/v2/email-v2/muteren-email-relatie-context', params);
  },
  ophalenEmailGebrXAspGebrs: async (
    params: IOphalenEmailGebrXAspGebrsParams,
  ): Promise<IOphalenEmailGebrXAspGebrsResult> => {
    return await api.post('/v2/email-v2/ophalen-email-gebr-x-asp-gebrs', params);
  },
};

export default emailV2;
