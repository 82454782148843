import api from '../../../..';
import {
  IOphalenKostenplaatsenParams,
  IOphalenKostenplaatsenResult,
} from '../../../../../../../shared/src/api/v2/rubriek/kostenplaats';

const kostenplaats = {
  ophalenKostenplaatsen: async (
    params: IOphalenKostenplaatsenParams,
  ): Promise<IOphalenKostenplaatsenResult> => {
    return await api.post('/v2/factuur/rubriek/kostenplaats/ophalen-kostenplaatsen', params);
  },
};

export default kostenplaats;
