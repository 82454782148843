import React, { useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import {
  IUrlState as IBetalingsregelingenOverzichtUrlState,
  defaultUrlState as betalingsregelingenOverzichtDefaultUrlState,
} from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Debiteur/Betalingsregeling';

interface IProps extends RouteComponentProps {
  betRglID: number;
}

const BetalingsregelingVisualisatie: React.FC<IProps> = observer((props) => {
  const { betalingsregelingStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (
      betalingsregelingStore.betalingsregelingen.state === ERemoteDataState.Pending ||
      betalingsregelingStore.betalingsregelingen.data![props.betRglID] === undefined
    ) {
      betalingsregelingStore.enqueueOphalenBetalingsregelingen([props.betRglID]);
    }
  }, [props.betRglID]);

  const betalingsregeling = useMemo(() => {
    if (
      betalingsregelingStore.betalingsregelingen.state === ERemoteDataState.Pending ||
      betalingsregelingStore.betalingsregelingen.data![props.betRglID] === undefined
    ) {
      return null;
    }
    return betalingsregelingStore.betalingsregelingen.data![props.betRglID]!;
  }, [props.betRglID, betalingsregelingStore.betalingsregelingen]);

  if (betalingsregeling === null) {
    return <span>Laden...</span>;
  }

  return (
    <a
      href="#"
      className="d-flex align-items-center"
      onClick={(ev) => {
        ev.preventDefault();

        const urlState: IBetalingsregelingenOverzichtUrlState = {
          ...betalingsregelingenOverzichtDefaultUrlState,
          selectie: [betalingsregeling.ID],
        };
        const query = genereerUrlStateQueryParam(urlState);
        // props.history.push(`/klant/${factuur.RelID}/facturen/overzicht?state=${query}`);

        props.history.push(
          `/klant/${betalingsregeling.RelID}/debiteur/betalingsregelingen?state=${query}`,
        );
      }}
    >
      <span>{betalingsregeling.Referentie}</span>
    </a>
  );
});

export default withRouter(BetalingsregelingVisualisatie);
