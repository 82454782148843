import { observable, computed, makeObservable } from 'mobx';
import api from '../api';
import { IGeslacht } from '../models/Geslacht';
import { ITaal } from '../models/Taal';
import { RootStore } from './RootStore';

/* Deze Store niet meer gebruiken
  ophalen data voor rijbronnen graag in een eigen component
  zie FormulierVelden map
*/

// tslint:disable no-console
export default class FormulierDataStore {
  public rootStore: RootStore;
  public geslachten: IGeslacht[] | null = null;
  public talen: ITaal[] | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      geslachten: observable,
      talen: observable,
      getTalen: computed,
    });
  }

  public ophalenGeslachten = async () => {
    console.log('DEFAULT STATE: OPHALEN GESLACHTEN');
    try {
      const response = await api.v2.geslacht.ophalen();
      this.geslachten = response;
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  public ophalenTalen = async () => {
    console.log('DEFAULT STATE: OPHALEN TALEN');
    try {
      const response = await api.v2.taal.ophalen({ filterSchema: { filters: [] } });
      this.talen = response;
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  get getTalen() {
    return this.talen;
  }
}
