import React, { useContext, useEffect, useMemo } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenAfdelingenResultElement } from '../../../../../shared/src/api/v2/relatie/afdeling';
import Skeleton from 'react-loading-skeleton';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';

interface IProps {
  orgAfdID: number;
}

const AfdelingVisualisatie = observer((props: IProps) => {
  const { afdelingStore } = useContext(RootStoreContext);

  const afdeling = useMemo<IRemoteData<IOphalenAfdelingenResultElement>>(() => {
    const value = afdelingStore.afdelingen[props.orgAfdID];
    if (value === undefined) {
      return createPendingRemoteData();
    }
    return value;
  }, [props.orgAfdID, afdelingStore.afdelingen]);

  useEffect(() => {
    if (afdeling.state === ERemoteDataState.Ready) {
      return;
    }
    afdelingStore.enqueueBepalenAfdelingen([props.orgAfdID]);
  }, [props.orgAfdID]);

  if (afdeling.state === ERemoteDataState.Pending) {
    return <Skeleton />;
  }

  const data = afdeling.data!;

  return <span>{data.Naam}</span>;
});

export default AfdelingVisualisatie;
