import {
  IOphalenApiLogsParams,
  IOphalenApiLogsResult,
  IOphalenSysteemInformatieParams,
  IOphalenSysteemInformatieResult,
} from '../../../../../shared/src/api/v2/technisch';
import api from '../../index';
import webReroute from './webReroute';

const technisch = {
  webReroute,
  ophalenSysteemInformatie: async (
    params: IOphalenSysteemInformatieParams,
  ): Promise<IOphalenSysteemInformatieResult> => {
    return await api.post('/v2/technisch/ophalen-systeem-informatie', params);
  },
  ophalenApiLogs: async (params: IOphalenApiLogsParams): Promise<IOphalenApiLogsResult> => {
    return await api.post('/v2/technisch/ophalen-api-logs', params);
  },
};

export default technisch;
