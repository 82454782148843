import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IOphalenWebReroutesResultElement } from '../../../../../shared/src/api/v2/technisch/webReroutes';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IconVerwijderen } from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import ASPTabel from '../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  ESorteringModus,
  IASPTabelSorteringOpties,
  IAspKolomSorteringItem,
} from '../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../core/useUrlState';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  Actief = 'IS_ACTIEF',
  AutomatischToegevoegd = 'IS_AUTOMATISCH_TOEGEVOEGD',
}

export interface IToevoegenMutatieDialoogState {
  mdwID: number | null;
}

enum EKolom {
  BronRoute,
  DoelRoute,
  Statuscode,
  AutomatischToegevoegd,
  Actief,
  RecordToegevoegd,
  RecordGewijzigd,
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

const WebReroutes: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      selectie: [],
      filterData: [
        {
          naam: EFilter.Actief,
          data: true,
          isActief: false,
        },
        {
          naam: EFilter.AutomatischToegevoegd,
          data: true,
          isActief: false,
        },
      ],
      sortering: [
        {
          key: EKolom.RecordGewijzigd,
          sortering: ESortering.Descending,
        },
      ],
    };
  }, []);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [webReroutes, setWebReroutes] = useState<IOphalenWebReroutesResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (webReroutes === null) {
            return null;
          }

          return <div className="d-flex align-items-center">Actief</div>;
        },
      },
      {
        naam: EFilter.AutomatischToegevoegd,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (webReroutes === null) {
            return null;
          }

          return <div className="d-flex align-items-center">Automatisch toegevoegd</div>;
        },
      },
    ],
    [webReroutes],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenWebReroutes = useCallback(async () => {
    const webReroutesResult = (
      await api.v2.technisch.webReroute.ophalenWebReroutes({
        filterSchema: {
          filters: [...filterSchema.filters!],
        },
        orderSchema: { orders: [{ naam: 'RECORD_GEWIJZIGD', richting: 'DESC' }] },
        // paginatie: { index: 0, aantal: 500 },
      })
    ).webReroutes;

    setWebReroutes(webReroutesResult);
  }, [filterSchema.filters]);

  useEffect(() => {
    ophalenWebReroutes();
  }, [ophalenWebReroutes]);

  const keyExtractor = useCallback((row: IOphalenWebReroutesResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenWebReroutesResultElement>[]>(
    () => [
      {
        key: EKolom.RecordGewijzigd,
        label: 'Toegevoegd op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => format(new Date(rij.RecordGewijzigd!), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: EKolom.BronRoute,
        label: 'Bronroute',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 600,
        renderer: (rij) => {
          return (
            <span
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              title={rij.BronRoute}
            >
              {rij.BronRoute}
            </span>
          );
        },
      },
      {
        key: EKolom.DoelRoute,
        label: 'Doelroute',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 600,
        renderer: (rij) => {
          return (
            <span
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              title={rij.DoelRoute ?? ''}
            >
              {rij.DoelRoute}
            </span>
          );
        },
      },
      {
        key: EKolom.Actief,
        label: 'Actief',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => (rij.Actief ? 'Ja' : 'Nee'),
      },
      {
        key: EKolom.AutomatischToegevoegd,
        label: 'Aut. toegevoegd',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => (rij.AutomatischToegevoegd ? 'Ja' : 'Nee'),
      },
    ],
    [],
  );

  const sorteringOpties = useMemo<IASPTabelSorteringOpties<EKolom>>(
    () => ({
      kolomOpties: {
        [EKolom.RecordGewijzigd]: {
          magSorteren: true,
        },
      },
      modus: ESorteringModus.Whitelist,
    }),
    [],
  );

  return (
    <>
      {webReroutes === null ? (
        <div className="justify-content-center align-items-center d-flex flex-fill">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const params = {
                    ids: urlState.selectie,
                  };

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: `Wil je de geselecteerde reroutes verwijderen?`,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.technisch.webReroute.verwijderenWebReroutes(params);
                  ophalenWebReroutes();

                  return;
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(value) => setUrlStateSync('filterData', value)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          }
          body={
            <ASPTabel
              rijen={webReroutes}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              sortering={urlState.sortering}
              onSorteringChange={(x) => setUrlStateSync('sortering', x)}
              sorteringOpties={sorteringOpties}
            />
          }
        />
      )}
    </>
  );
});

export default WebReroutes;
