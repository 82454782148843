import React, { useCallback } from 'react';
import {
  NaamContainer,
  PersoonContainer,
  PreviewContainer,
  Root,
  TelefoonnummerContainer,
} from './style';
import { IContact, INewContact } from '../../index';
import PersoonKoppelComponent from '../../../../personalia/PersoonKoppelComponent';

interface IProps {
  contact: IContact | INewContact;
  selected: boolean;
  onClick: () => void;
  onContactChange: (contact: IContact | INewContact) => void;
}

const ContactItem: React.FC<IProps> = (props) => {
  const handleClick = useCallback(
    (ev: React.MouseEvent<any>) => {
      ev.stopPropagation();
      props.onClick();
    },
    [props.onClick],
  );

  const lastMessage =
    (props.contact as IContact).id !== undefined ? (props.contact as IContact).lastMessage : null;

  const handlePersIDChange = useCallback(
    (persID: number | null) => {
      const newContact: IContact | INewContact = {
        ...props.contact,
        persID,
      };
      props.onContactChange(newContact);
    },
    [props.contact, props.onContactChange],
  );

  return (
    <Root
      className="list-group-item list-group-item-action"
      selected={props.selected}
      onClick={handleClick}
    >
      <PersoonContainer>
        <PersoonKoppelComponent persID={props.contact.persID} onPersIDChange={handlePersIDChange} />
        <TelefoonnummerContainer>{props.contact.phoneNumber}</TelefoonnummerContainer>
        <PreviewContainer>
          {lastMessage === null ? '(Geen preview beschikbaar)' : lastMessage.content}
        </PreviewContainer>
      </PersoonContainer>
    </Root>
  );
};

export default ContactItem;
