import React, { useCallback, useContext, useRef, useState } from 'react';
import { IconKruis, IconZoeken } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import InactiefOverlay from '../../../components/InactiefOverlay';
import styled from 'styled-components';
import { RootStoreContext } from '../../../stores/RootStore';

const Container = styled.div`
  position: relative;
  background-color: rgba(150, 150, 150, 0.3);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Input = styled.input`
  background: transparent;
  border: 0;
  width: 100%;
  height: 30px;
  margin-left: 3px;
  color: ${Kleur.Wit};
  outline: 0;
  flex: 1;
`;

interface IProps {
  input: string;
  onInputChange: (input: string) => void;
}

const GlobaalZoekveld: React.FC<IProps> = (props) => {
  const { zoekStore } = useContext(RootStoreContext);
  const inputRef = useRef<HTMLInputElement>(null);
  // const [isFocused, setIsFocused] = useState(false);
  // const [isSearching, setIsSearching] = useState(false);
  //
  // const performSearch = useCallback(async () => {
  //   inputRef.current!.blur();
  //   setIsSearching(true);
  //
  //   setIsSearching(false);
  // }, [props.input, isSearching, setIsSearching]);

  return (
    <>
      <InactiefOverlay
        // isInactief={isSearching}
        isInactief={false}
        element={
          <Container onClick={() => zoekStore.open()}>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                height: '100%',
                marginLeft: 7,
              }}
            >
              <IconZoeken
                style={{
                  fill: Kleur.Wit,
                  width: 18,
                  height: 18,
                  position: 'relative',
                  top: 1,
                }}
              />
            </div>
            <Input
              ref={inputRef}
              value={props.input}
              onChange={(ev) => props.onInputChange(ev.target.value)}
              // onKeyUp={async (ev) => {
              //   if (ev.key === 'Enter') {
              //     await performSearch();
              //   }
              // }}
              placeholder="Zoeken..."
              // onFocus={() => setIsFocused(true)}
              // onBlur={() => setIsFocused(false)}
              onFocus={() => zoekStore.open()}
            />
            {props.input.length !== 0 && (
              <button
                className="d-flex justify-content-center align-items-center"
                style={{
                  position: 'absolute',
                  right: 0,
                  outline: 0,
                  border: 0,
                  background: 0,
                  height: '100%',
                }}
                onClick={() => props.onInputChange('')}
              >
                <IconKruis
                  style={{
                    fill: Kleur.Wit,
                    height: 18,
                    width: 18,
                    position: 'relative',
                    top: 1,
                  }}
                />
              </button>
            )}
          </Container>
        }
      />
    </>
  );
};

export default GlobaalZoekveld;
