import React, { useCallback, useContext, useMemo } from 'react';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import {
  IconAttachment,
  IconEmail,
  IconEvent,
  IconInformatie,
  IconKlok,
  IconKruis,
  IconLock,
  IconSend,
  IconVernieuwen,
  IconVerwijderen,
  IconVink,
  IconVlag,
} from '../../Icons';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../core/nameOf';
import nameOf from '../../../core/nameOf';
import _ from 'lodash';
import RelatiesVisualisaties from '../../personalia/RelatiesVisualisaties';
import { format } from 'date-fns';
import WijzigenDialoog from '../WijzigenOpdrachtDialoog';
import VersturenDialoog from './VersturenDialoog';
import Regels from './Regels';
import BezoektijdenDialoog from './BezoektijdenDialoog';
import AfmeldenDialoog from './AfmeldenDialoog';
import OpdrachtEventsDialoog from './OpdrachtEventsDialoog';
import SelectieVak from '../../SelectieVak';
import ActieMenuKnop, { IActie } from '../../../components/ActieMenuKnop';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { ERegelstatusTransport } from '../../../bedrijfslogica/enums';
import SoortenIndicatie from '../../kolomveld/transportopdrachtregel/SoortenIndicatieComp';
import TransportopdrachtInfoDialoog from '../OpdrachtInfoDialoog';
import MenuLayout from '../../MenuLayout';
import { AutoSizer } from 'react-virtualized';
import { EHoedanigheid } from '../../personalia/RelatieSelectieDialoog';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import useTPBVersturen from '../../../hooks/transport/opdracht/useTPBVersturen';
import KnopLoadingSpinner from '../../Gedeeld/LoadingSpinner/KnopLoadingSpinner';
import { toast } from 'react-hot-toast';
import { IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenResult } from '../../../../../shared/src/api/v2/transport/bezoek';

export interface IUitstaandTabelContext {
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  onRequestRefresh: () => void;
}

export const UitstaandTabelContext = React.createContext<IUitstaandTabelContext>(null as any);

export interface IVersturenOpdrachtenDialoogState {
  trsOpdIDs: number[];
}

export interface IWijzigenOpdrachtDialoogState {
  trsOpdID: number;
}

export interface IBezoektijdenDialoogState {}

export interface IAfmeldenOpdrachtDialoogState {
  trsOpdID: number;
  trsRegIDs: number[];
}

export interface IOpdrachtEventsDialoogState {
  trsOpdID: number;
}

export interface IVersturenHerinneringenDialoogState {
  trsOpdIDs: number[];
}

export interface ITransportopdrachtInfoDialoogState {
  trsOpdID: number;
}

interface IProps {
  opdrachten: IOpdracht[] | null;
  opdrachtenMetGewijzigdeBezoektijden: IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenResult | null;
  opdrachtenSelectie: number[];
  onOpdrachtenSelectieChange: (value: number[]) => void;
  uitgeklapteOpdrachten: number[];
  onUitgeklapteOpdrachtenChange: (value: number[]) => void;
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  opdrachtEventsDialoogState: IOpdrachtEventsDialoogState | null;
  onOpdrachtEventsDialoogStateChange: (value: IOpdrachtEventsDialoogState | null) => void;
  afmeldenDialoogState: IAfmeldenOpdrachtDialoogState | null;
  onAfmeldenDialoogStateChange: (value: IAfmeldenOpdrachtDialoogState | null) => void;
  bezoektijdenDialoogState: IBezoektijdenDialoogState | null;
  onBezoektijdenDialoogStateChange: (value: IBezoektijdenDialoogState | null) => void;
  versturenOpdrachtenDialoogState: IVersturenOpdrachtenDialoogState | null;
  onVersturenOpdrachtenDialoogStateChange: (value: IVersturenOpdrachtenDialoogState | null) => void;
  wijzigenOpdrachtDialoogState: IWijzigenOpdrachtDialoogState | null;
  onWijzigenOpdrachtDialoogStateChange: (value: IWijzigenOpdrachtDialoogState | null) => void;
  versturenHerinneringenDialoogState: IVersturenHerinneringenDialoogState | null;
  onVersturenHerinneringenDialoogStateChange: (
    value: IVersturenHerinneringenDialoogState | null,
  ) => void;
  onRequestRefresh: () => void;
  transportopdrachtInfoDialoogState: ITransportopdrachtInfoDialoogState | null;
  onTransportopdrachtInfoDialoogStateChange: (
    value: ITransportopdrachtInfoDialoogState | null,
  ) => void;
  filterbalk?: JSX.Element;
}

export interface IRow extends IOpdracht {}

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  relatie: IOphalenRelatiesResultElementV2 | null;
}

export interface IOpdracht extends IOphalenOpdrachtenResultElementV2 {
  regels: IRegel[];
}

const UitstaandTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback((row: IRow) => row.TrsOpdID, []);

  const kolomBreedteAantalRegels = 30;
  const kolomBreedteRelatie = 200;

  const rows = useMemo<IRow[] | null>(() => {
    if (props.opdrachten === null) {
      return null;
    }

    const opdrachten = props.opdrachten.map((opdracht) => ({
      ...opdracht,
    }));
    const opdrachtenGesorteerd = _.orderBy(
      opdrachten,
      [
        'Bezoekdatum',
        (x: IOphalenOpdrachtenResultElementV2) =>
          x.BezoekdatumVan !== null ? format(new Date(x.BezoekdatumVan), 'HH:mm') : '',
      ],
      ['asc', 'asc'],
    );

    return opdrachtenGesorteerd;
  }, [props.opdrachten, props.regelsSelectie]);

  const handleVerwijderenBezoektijden = useCallback(
    async (trsOpdIDs: number[]) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Bezoektijden wissen voor de geselecteerde opdrachten?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }
      await api.v2.transport.opdracht.verwijderenBezoektijden({ trsOpdIDs });

      props.onRequestRefresh();
    },
    [props.opdrachtenSelectie],
  );

  const handleBezoektijdenVoorSelectie = useCallback(async () => {
    const params = { trsOpdIDs: props.opdrachtenSelectie };

    const checkData = await api.v2.transport.checkBijwerkenBezoektijden(params);

    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Bezoektijden voor de geselecteerde opdrachten ophalen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const result = await api.v2.transport.bijwerkenBezoektijden(params);

    // await checkStore.melden({
    //   titel: `Er zijn voor ${result.aantalOpdrachten} opdrachten tijden opgehaald`,
    // });

    props.onRequestRefresh();
  }, [props.opdrachtenSelectie, props.onRequestRefresh]);

  const handleAfmeldenOpdracht = useCallback(
    async (trsOpdID: number) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud:
              'Het heeft de voorkeur om opdrachten af te melden op regelniveau. Toch doorgaan?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      // Zet de TrsOpdID om in een array van TrsRegIDs
      // const opdracht = (
      //   await api.v2.transport.opdracht.ophalenOpdrachten({
      //     filterSchema: { filters: [{ naam: 'IDS', data: [trsOpdID] }] },
      //   })
      // ).opdrachten[0];

      const trsRegIDs = (
        await api.v2.transport.opdracht.ophalenOpdrachtregels({
          filterSchema: { filters: [{ naam: 'TRSOPD_IDS', data: [trsOpdID] }] },
        })
      ).regels.map((x) => x.TrsRegID);

      const params = { trsOpdID, trsRegIDs };

      // const params = {
      //   trsOpdID,
      //   trsRegIDs,
      //   status: 1,
      //   referentiecode: null,
      //   afmelddatum: new Date(),
      // };

      // // Bepaal of alle regels afgemeld kunnen worden
      // const checkData = await api.v2.transport.checkAfmeldenOpdrachten(params);
      // const checkResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (checkResult.type === EResultType.Annuleren) {
      //   return;
      // }

      props.onAfmeldenDialoogStateChange(params);
    },
    [props.opdrachtenSelectie],
  );

  const handleMarkerenAfgehandeld = useCallback(
    async (trsOpdIDs: number[]) => {
      const checkData = await api.v2.transport.opdracht.uitstaand.checkMarkerenOpdrachtenAfgehandeld(
        {
          trsOpdIDs,
        },
      );
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Geselecteerde transportopdrachten als Afgehandeld markeren?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.transport.opdracht.uitstaand.markerenOpdrachtenAfgehandeld({
        trsOpdIDs,
      });

      props.onRequestRefresh();
    },
    [props.opdrachtenSelectie],
  );

  const handleMarkerenPlanning = useCallback(
    async (trsOpdIDs: number[]) => {
      const checkData = await api.v2.transport.opdracht.uitstaand.checkMarkerenOpdrachtenPlanning({
        trsOpdIDs,
      });
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Hiermee krijgen de geselecteerde opdrachten de status `Planning`.
                <br />
                Informeer de transportdienst dat de opdrachten hier niet meer uitstaan.
                <br />
                Doorgaan?
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.transport.opdracht.uitstaand.markerenOpdrachtenPlanning({
        trsOpdIDs,
      });

      props.onRequestRefresh();
    },
    [props.opdrachtenSelectie],
  );

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Opdrachtnummer',
        title: 'Opd.nr',
      },
      {
        name: '__opdrachtInfo' as any,
        title: ' ',
      },
      {
        name: '__relaties' as any,
        title: 'Relatie(s)',
        getCellValue: (x) => {
          const relatieResult = x.regels.filter((x) => x.relatie !== null);
          const relatie = relatieResult.length !== 0 ? relatieResult[0].relatie : null;
          if (relatie === null) return '';
          return relatie.weergavenaam;
        },
      },
      {
        name: '__contactpersoon' as any,
        title: 'Commissie',
        getCellValue: (x) => {
          if (x.persoon === null) {
            return <span></span>;
          }
          return x.persoon.Achternaam;
        },
      },
      {
        name: 'locatie',
        title: 'Locatie',
        getCellValue: (row) => {
          const locatie =
            row.locatie.Plaatsnaam +
            ', ' +
            row.locatie.Straatnaam +
            ' ' +
            row.locatie.Huisnummer +
            (row.locatie.Bisnummer !== null ? ' ' + row.locatie.Bisnummer : '');

          return locatie;
        },
      },
      {
        name: '__soortenIndicatie' as any,
        title: '#/Srt.',
      },
      {
        name: '__indicaties' as any,
        title: 'Ind.',
      },
      {
        name: 'Bezoekdatum',
        title: 'Bez.datum',
      },
      {
        name: '__bezoektijdenVoorkeur' as any,
        title: 'Voorkeurtijd',
      },
      {
        name: '__bezoektijden' as any,
        title: (
          <span title="Oranje betekent dat de opgehaalde tijden ondertussen gewijzigd zijn. Een slotje betekent dat de tijden niet automatisch bijgewerkt worden.">
            Tijden
          </span>
        ),
      },
      {
        name: '__tijdenVerstuurd' as any,
        title: (
          <span title={'Geeft aan dat de bezoektijden verstuurd zijn voor de opdracht'}>
            <IconEmail style={{ width: 15, height: 15, fill: EKleur.Grijs, marginRight: 1 }} />
          </span>
        ) as any,
      },
      {
        name: '__afmeldstatus' as any,
        title: 'Status',
      },
      {
        name: '__heeftBestanden' as any,
        title: 'Bonnen',
      },
      // {
      //   name: '__events' as any,
      //   title: (
      //     <span title={'Geeft aan dat er 1 of meer events zijn vastgelegd voor de opdracht'}>
      //       Evt.
      //     </span>
      //   ) as any,
      // },
      {
        name: '__magazijn' as any,
        title: 'Mag.',
      },
      {
        name: '__dienst' as any,
        title: 'Dienst',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Opdrachtnummer',
        width: 90,
      },
      {
        columnName: '__opdrachtInfo',
        width: 40,
      },
      {
        columnName: '__dienst' as any,
        width: 95,
      },
      {
        columnName: '__contactpersoon' as any,
        width: 125,
      },
      {
        columnName: '__soortenIndicatie' as any,
        width: 75,
      },
      {
        columnName: '__indicaties' as any,
        width: 75,
      },
      {
        columnName: 'locatie',
        width: 275,
      },
      {
        columnName: '__relaties' as any,
        width: kolomBreedteRelatie,
      },
      {
        columnName: 'Bezoekdatum',
        width: 115,
      },
      {
        columnName: '__bezoektijden' as any,
        width: 135,
        getCellValue: (x: IRow) => {
          return x.BezoekdatumVan !== null ? format(new Date(x.BezoekdatumVan), 'HH:mm') : '';
        },
      },
      {
        columnName: '__bezoektijdenVoorkeur' as any,
        width: 130,
      },
      {
        columnName: 'BezoekdatumVan',
        width: 75,
      },
      {
        columnName: 'BezoekdatumTot',
        width: 75,
      },
      {
        columnName: '__tijdenActueel' as any,
        width: 75,
      },
      {
        columnName: '__tijdenVast' as any,
        width: 30,
      },
      {
        columnName: '__tijdenVerstuurd' as any,
        width: 60,
      },
      {
        columnName: '__events' as any,
        width: 70,
      },
      {
        columnName: '__afmeldstatus',
        width: 125,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 90,
      },
      {
        columnName: '__datumVerstuurd',
        width: 115,
      },
      {
        columnName: '__actieMenu',
        width: 100,
      },
      {
        columnName: '__magazijn',
        width: 100,
      },
      // {
      //   columnName: 'Notities',
      //   width: 70,
      // },
    ],
    [props.opdrachtenMetGewijzigdeBezoektijden],
  );

  const acties = useMemo<IActie[]>(
    () => [
      // {
      //   icon: <IconSend style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />,
      //   text: 'Opnieuw versturen',
      //   onClick: async () => {
      //     const checkData = await api.v2.transport.checkSelectieVersturenOpdrachten({
      //       trsOpdIDs: props.opdrachtenSelectie,
      //     });
      //     if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      //       return;
      //     }

      //     props.onVersturenOpdrachtenDialoogStateChange({
      //       trsOpdIDs: props.opdrachtenSelectie,
      //     });
      //   },
      //   disabled: props.opdrachtenSelectie.length === 0,
      // },
      {
        icon: <IconVernieuwen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />,
        text: 'Terugzetten naar Planning',
        onClick: async () => handleMarkerenPlanning(props.opdrachtenSelectie),
        disabled: props.opdrachtenSelectie.length === 0,
      },
      {
        icon: <IconEvent style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />,
        text: 'Events toevoegen',
        onClick: () =>
          props.onOpdrachtEventsDialoogStateChange({
            trsOpdID: props.opdrachtenSelectie[0],
          }),
      },
    ],
    [props.opdrachtenSelectie],
  );

  const { versturenTPB, isBezig: TPBVersturenIsBezig } = useTPBVersturen(
    useMemo(() => {
      const opdrachten =
        props.opdrachten?.filter(
          (opdracht) => props.opdrachtenSelectie.indexOf(opdracht.TrsOpdID) !== -1,
        ) ?? null;
      return {
        opdrachten,
        contextRelIDs:
          opdrachten === null
            ? []
            : _.uniq(
                opdrachten
                  .map(
                    (opdracht) =>
                      opdracht.regels
                        .map((regel) => regel.RelID)
                        .filter((x) => x !== null) as number[],
                  )
                  .flatMap((x) => x),
              ),
      };
    }, [props.opdrachten, props.opdrachtenSelectie]),
  );

  const context = useMemo<IUitstaandTabelContext>(() => {
    return {
      regelsSelectie: props.regelsSelectie,
      onRegelsSelectieChange: props.onRegelsSelectieChange,
      onRequestRefresh: props.onRequestRefresh,
    };
  }, [props.onRequestRefresh, props.regelsSelectie, props.onRegelsSelectieChange]);

  return (
    <UitstaandTabelContext.Provider value={context}>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                // disabled={props.opdrachtenSelectie.length !== 0}
                onClick={() => props.onBezoektijdenDialoogStateChange(props.opdrachtenSelectie)}
              >
                <IconKlok style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Bezoektijden voor datum</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={() => handleBezoektijdenVoorSelectie()}
              >
                <IconKlok style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Bezoektijden voor selectie</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={() => handleVerwijderenBezoektijden(props.opdrachtenSelectie)}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Wissen bezoektijden</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.transport.bezoek.checkVersturenBezoekHerinneringen(
                    {
                      trsOpdIDs: props.opdrachtenSelectie,
                    },
                  );
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Bezoekherinneringen versturen voor de geselecteerde opdrachten?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.transport.bezoek.versturenBezoekHerinneringen({
                    trsOpdIDs: props.opdrachtenSelectie,
                  });

                  toast.success('Bezoektijden verstuurd');
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Versturen bezoektijden</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length !== 1}
                onClick={() => handleAfmeldenOpdracht(props.opdrachtenSelectie[0])}
              >
                <IconVlag style={{ width: 16, height: 16, fill: EKleur.Rood }} />
                <span className="ml-2">Afmelden opdracht</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={
                  props.opdrachten === null || props.opdrachtenSelectie.length === 0
                  // props.opdrachtenSelectie.length === props.opdrachten.length
                }
                onClick={() => handleMarkerenAfgehandeld(props.opdrachtenSelectie)}
              >
                <IconVink style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Naar afgehandeld</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Hiermee ga je de geselecteerde opdrachten opnieuw versturen.
                            <br />
                            Wil je doorgaan?
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  const checkData = await api.v2.transport.checkSelectieVersturenOpdrachten({
                    trsOpdIDs: props.opdrachtenSelectie,
                  });
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  props.onVersturenOpdrachtenDialoogStateChange({
                    trsOpdIDs: props.opdrachtenSelectie,
                  });
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Opnieuw versturen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0 || TPBVersturenIsBezig}
                onClick={async (ev) => {
                  await versturenTPB?.();
                }}
              >
                {TPBVersturenIsBezig ? (
                  <KnopLoadingSpinner />
                ) : (
                  <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                )}
                <span className="ml-2">TPB versturen</span>
              </button>

              <div className="flex-fill" />
              <ActieMenuKnop acties={acties} />
            </div>

            {rows !== null && (
              <div className="mt-3 d-flex align-items-center">
                <SelectieVak
                  aantal={props.opdrachtenSelectie.length}
                  totaalAantal={rows.length}
                  onChange={(alles) => {
                    if (alles) {
                      props.onOpdrachtenSelectieChange(props.opdrachten!.map((x) => x.TrsOpdID));
                    } else {
                      props.onOpdrachtenSelectieChange([]);
                    }
                  }}
                  entiteitEnkelvoud="opdracht"
                  entiteitMeervoud="opdrachten"
                />
                <div className="d-flex flex-fill ml-2">{props.filterbalk}</div>
              </div>
            )}
          </>
        }
        body={
          <>
            {rows === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center p-5 pl-4 pr-4">
                <LoadingSpinner />
              </div>
            ) : (
              <AutoSizer
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {(size) => (
                  <GridStyleWrapper height={size.height}>
                    <Grid rows={rows} columns={kolommen} getRowId={keyExtractor}>
                      <DataTypeProvider
                        for={['__opdrachtInfo']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IOpdracht;

                          return (
                            <div className="mb-1 align-items-center">
                              <a
                                href="#"
                                // style={{ color: EKleur.DonkerGrijs }}
                                onClick={() => {
                                  props.onTransportopdrachtInfoDialoogStateChange({
                                    trsOpdID: rij.TrsOpdID,
                                  });
                                }}
                              >
                                <IconInformatie
                                  style={{ width: 17, height: 17, fill: EKleur.Blauw }}
                                />
                              </a>
                              {rij.Notities !== null ? (
                                <span className="m-1" style={{ color: EKleur.Blauw }}>
                                  <b>!</b>
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          );
                        }}
                      />
                      <DataTypeProvider
                        for={['__magazijn']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          return <span>{rij.magazijn.NaamKort}</span>;
                        }}
                      />
                      <DataTypeProvider
                        for={['__dienst']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          return (
                            <RelatieVisualisatie
                              relID={rij.dienst!.RelID}
                              options={{ geenLinkToepassen: false }}
                            />
                          );
                        }}
                      />
                      {/* <DataTypeProvider
                        for={['__dienst']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;
                          return (
                            <>
                              <span>{rij.dienst.Naam}</span>
                            </>
                          );
                        }}
                      /> */}
                      <DataTypeProvider
                        for={['__contactpersoon']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          if (rij.persoon === null) {
                            return <span></span>;
                          }

                          return (
                            <>
                              <span>{rij.persoon.Achternaam}</span>
                            </>
                          );
                        }}
                      />
                      <DataTypeProvider
                        for={['__soortenIndicatie']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IOpdracht;
                          return <SoortenIndicatie opdracht={rij} />;
                        }}
                      />
                      <DataTypeProvider
                        for={['Notities']}
                        formatterComponent={(formatterProps) => {
                          return <span>{formatterProps.value !== null ? 'Ja' : ''}</span>;
                        }}
                      />
                      <DataTypeProvider
                        for={[nameOf<IOpdracht>('BezoekdatumVan')]}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IOpdracht;
                          const van =
                            rij.BezoekdatumVan !== null
                              ? format(new Date(rij.BezoekdatumVan), 'HH:mm')
                              : rij.VoorkeurBezoektijdVan !== null
                              ? rij.VoorkeurBezoektijdVan
                              : '';
                          return <span>{van}</span>;
                        }}
                      />
                      <DataTypeProvider
                        for={[nameOf<IOpdracht>('BezoekdatumTot')]}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IOpdracht;
                          const tot =
                            rij.BezoekdatumTot !== null
                              ? format(new Date(rij.BezoekdatumTot), 'HH:mm')
                              : rij.VoorkeurBezoektijdTot !== null
                              ? rij.VoorkeurBezoektijdTot
                              : '';
                          return <span>{tot}</span>;
                        }}
                      />
                      <DataTypeProvider
                        for={['__relaties']}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IRow;
                          const relIDs: number[] = _.uniq(
                            row.regels
                              .filter((x) => x.relatie !== null)
                              .map((x) => x.relatie!.RelID),
                          );
                          if (relIDs.length === 0) {
                            return <span />;
                          }
                          return (
                            <RelatiesVisualisaties
                              relIDs={relIDs}
                              relatieLinkBuilder={(hoedanigheid, relID) =>
                                `/${
                                  hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                }/${relID}/transport/opdracht`
                              }
                            />
                          );
                        }}
                      />
                      <DataTypeProvider
                        for={[nameof<IRow>('Bezoekdatum')]}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IRow;
                          return (
                            <span>
                              {row.Bezoekdatum === null
                                ? ''
                                : format(new Date(row.Bezoekdatum), 'dd-MM-yyyy')}
                              {row.IndicatieAAA ? ' (A)' : ''}
                            </span>
                          );
                        }}
                      />
                      <DataTypeProvider
                        for={['__tijdenActueel']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          return (
                            <>
                              {rij.BezoektijdenActueel && (
                                <IconKlok style={{ width: 16, height: 16, fill: EKleur.Groen }} />
                              )}
                              {rij.BezoektijdenNietAutomatischBijwerken && (
                                <IconLock style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                              )}
                            </>
                          );
                        }}
                      />
                      <DataTypeProvider
                        for={['__bezoektijden']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          const gewijzigd =
                            props.opdrachtenMetGewijzigdeBezoektijden !== null
                              ? props.opdrachtenMetGewijzigdeBezoektijden.opdrachten
                                  .filter((x) => x.TrsOpdID === rij.TrsOpdID)
                                  .filter(
                                    (x) =>
                                      !(
                                        x.DatumTijdOpgehaaldVan === null &&
                                        x.DatumTijdOpgehaaldTot === null
                                      ),
                                  ).length !== 0
                              : false;

                          const Actueel = () => (
                            <span>
                              {rij.BezoektijdenActueel && (
                                <IconKlok style={{ width: 16, height: 16, fill: EKleur.Groen }} />
                              )}
                              {rij.BezoektijdenNietAutomatischBijwerken && (
                                <IconLock style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                              )}
                            </span>
                          );

                          if (rij.BezoekdatumVan !== null && rij.BezoekdatumTot !== null) {
                            return (
                              <>
                                <span style={{ color: gewijzigd ? EKleur.Oranje : undefined }}>
                                  {format(new Date(rij.BezoekdatumVan), 'HH:mm')} -{' '}
                                  {format(new Date(rij.BezoekdatumTot), 'HH:mm')}
                                </span>

                                <span className="ml-1">
                                  {rij.BezoekdatumVan >= rij.BezoekdatumTot ? (
                                    <span style={{ color: EKleur.Rood }}>
                                      <b>?</b>
                                    </span>
                                  ) : (
                                    <span>&nbsp;</span>
                                  )}
                                </span>

                                <span className="ml-1">
                                  <Actueel />
                                </span>
                              </>
                            );
                          }
                          if (rij.BezoekdatumVan !== null && rij.BezoekdatumTot === null) {
                            return (
                              <>
                                <span>Na {format(new Date(rij.BezoekdatumVan), 'HH:mm')}</span>
                                <span className="ml-1">
                                  <Actueel />
                                </span>
                              </>
                            );
                          }
                          if (rij.BezoekdatumVan === null && rij.BezoekdatumTot !== null) {
                            return (
                              <>
                                <span>Voor {format(new Date(rij.BezoekdatumTot), 'HH:mm')}</span>{' '}
                                <span className="ml-1">
                                  <Actueel />
                                </span>
                              </>
                            );
                          }

                          return <span>Niet bekend</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__bezoektijdenVoorkeur']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          const voorbericht = rij.TelefonischVoorberichtBezoek ? '½ vtv' : '';

                          if (rij.BezoekdatumVan === null && rij.BezoekdatumTot === null) {
                            return <span>{voorbericht}</span>;
                          }

                          if (
                            rij.VoorkeurBezoektijdVan !== null &&
                            rij.VoorkeurBezoektijdTot !== null
                          ) {
                            return (
                              <span>
                                {rij.VoorkeurBezoektijdVan} - {rij.VoorkeurBezoektijdTot}{' '}
                                {voorbericht}
                              </span>
                            );
                          }
                          if (
                            rij.VoorkeurBezoektijdVan !== null &&
                            rij.VoorkeurBezoektijdTot === null
                          ) {
                            return <span>Na {rij.VoorkeurBezoektijdVan}</span>;
                          }
                          if (
                            rij.VoorkeurBezoektijdVan === null &&
                            rij.VoorkeurBezoektijdTot !== null
                          ) {
                            return (
                              <span>
                                Voor {rij.VoorkeurBezoektijdTot} {voorbericht}
                              </span>
                            );
                          }

                          return <span>{voorbericht}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__tijdenVerstuurd']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          return (
                            <div className="d-flex">
                              {rij.BezoektijdenVerstuurd && (
                                <IconEmail style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
                              )}

                              {rij.BezoektijdenNietVersturen && (
                                <IconKruis style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
                              )}
                            </div>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__indicaties']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IOpdracht;
                          const inkoopreserveringAanwezig = rij.regels.some(
                            (x) => x.type !== null && !x.type.Bulkproduct && !x.type.Gereserveerd,
                          );
                          return <span>{inkoopreserveringAanwezig ? 'IR' : ''}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__afmeldstatus']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IOpdracht;
                          const reedsAfgemeld = rij.regels.some(
                            (x) => x.status.Status !== ERegelstatusTransport.NIET_AFGEMELD,
                          );
                          const nogAfmelden = rij.regels.some(
                            (x) => x.status.Status === ERegelstatusTransport.NIET_AFGEMELD,
                          );

                          const afgemeld =
                            reedsAfgemeld && nogAfmelden ? (
                              <span style={{ color: EKleur.LichtGroen }}>Deels afgemeld</span>
                            ) : reedsAfgemeld ? (
                              <span style={{ color: EKleur.Groen }}>Afgemeld</span>
                            ) : (
                              <span style={{ color: EKleur.Grijs }}>Niet afgemeld</span>
                            );

                          return <span>{afgemeld}</span>;
                        }}
                      />
                      <DataTypeProvider
                        for={['__heeftBestanden']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRow = formatterProps.row;
                          const heeftBestanden = rij.bestanden.length > 0 ? true : false;
                          return (
                            <span>
                              {heeftBestanden ? (
                                <IconAttachment
                                  style={{ width: 20, height: 20, fill: EKleur.Grijs }}
                                />
                              ) : null}
                            </span>
                          );
                        }}
                      />
                      <DataTypeProvider
                        for={['__datumVerstuurd']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRow = formatterProps.row;
                          return <span>{format(new Date(rij.DatumVerstuurd!), 'dd-MM-yyyy')}</span>;
                        }}
                      />
                      <DataTypeProvider
                        for={['__events']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRow = formatterProps.row;
                          return <span>{rij.events.length !== 0 ? 'Ja' : ''}</span>;
                        }}
                      />
                      <SelectionState
                        selection={props.opdrachtenSelectie}
                        onSelectionChange={(value) =>
                          props.onOpdrachtenSelectieChange(value as number[])
                        }
                      />
                      <RowDetailState
                        expandedRowIds={props.uitgeklapteOpdrachten}
                        onExpandedRowIdsChange={(value) =>
                          props.onUitgeklapteOpdrachtenChange(value as number[])
                        }
                      />
                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting
                        columnExtensions={[
                          {
                            columnName: nameof<IRow>('Bezoekdatum'),
                            compare: (links, rechts) => {
                              const linksDatum = links === null ? null : new Date(links);
                              const rechtsDatum = links === null ? null : new Date(rechts);

                              if (linksDatum === null) {
                                return -1;
                              }
                              if (rechtsDatum === null) {
                                return 1;
                              }

                              return linksDatum.getTime() - rechtsDatum.getTime();
                            },
                          },
                        ]}
                      />
                      <VirtualTable
                        messages={{
                          noData: 'Geen transportopdrachten voor het filter',
                        }}
                      />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow showSortingControls />
                      <EditingState
                        onCommitChanges={(changes) => {
                          if (changes.deleted === undefined) {
                            return;
                          }
                          const deleted = changes.deleted;
                          const id = deleted[deleted.length - 1];
                        }}
                        onEditingRowIdsChange={(rowIds) => {
                          const id = rowIds[rowIds.length - 1] as number;
                          props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                        }}
                      />
                      <TableEditColumn
                        width={35}
                        showEditCommand
                        cellComponent={DXTableEditColumnCellComponent}
                        commandComponent={DXTableEditColumnCommandComponent}
                      />
                      <TableRowDetail
                        toggleCellComponent={DXTableToggleCellComponent}
                        contentComponent={Regels}
                      />
                      <TableSelection cellComponent={DXTableCheckboxComponent} />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </AutoSizer>
            )}
          </>
        }
      />

      {props.afmeldenDialoogState !== null && (
        <AfmeldenDialoog
          open
          onSuccess={() => {
            props.onAfmeldenDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onAfmeldenDialoogStateChange(null)}
          trsRegIDs={props.afmeldenDialoogState.trsRegIDs}
          trsOpdID={props.afmeldenDialoogState.trsOpdID}
        />
      )}

      {props.bezoektijdenDialoogState !== null && (
        <BezoektijdenDialoog
          open
          onSuccess={() => {
            props.onBezoektijdenDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onBezoektijdenDialoogStateChange(null)}
        />
      )}

      {props.wijzigenOpdrachtDialoogState !== null && (
        <WijzigenDialoog
          open
          onSuccess={() => {
            props.onWijzigenOpdrachtDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onWijzigenOpdrachtDialoogStateChange(null)}
          trsOpdID={props.wijzigenOpdrachtDialoogState.trsOpdID}
        />
      )}

      {props.versturenOpdrachtenDialoogState !== null && (
        <VersturenDialoog
          open
          onSuccess={async () => {
            props.onVersturenOpdrachtenDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onVersturenOpdrachtenDialoogStateChange(null)}
          trsOpdIDs={props.versturenOpdrachtenDialoogState.trsOpdIDs}
        />
      )}

      {props.opdrachtEventsDialoogState !== null && (
        <OpdrachtEventsDialoog
          open
          onSuccess={async () => {
            props.onOpdrachtEventsDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onOpdrachtEventsDialoogStateChange(null)}
          trsOpdID={props.opdrachtEventsDialoogState.trsOpdID}
        />
      )}

      {props.transportopdrachtInfoDialoogState !== null && (
        <TransportopdrachtInfoDialoog
          open
          trsOpdID={props.transportopdrachtInfoDialoogState.trsOpdID}
          onSuccess={() => {
            props.onTransportopdrachtInfoDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onTransportopdrachtInfoDialoogStateChange(null)}
        />
      )}
    </UitstaandTabelContext.Provider>
  );
});

export default UitstaandTabel;
