import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import Item from './Item';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { EIdentiteitType, Identiteit, IEmailsData, IRelatieIdentiteit } from '../types';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  InfiniteLoader,
  List,
  ListRowProps,
} from 'react-virtualized';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  geadresseerdeNaarIdentiteiten,
  genereerIdentiteitenVanEmail,
  identiteitTypeSignificantie,
} from '../helpers';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import {
  IOphalenRelatiesResultElementV2,
  IOphalenRelPersResultElement,
} from '../../../../../../shared/src/api/v2/relatie';
import { IOphalenOrganisatiesResultElement } from '../../../../../../shared/src/api/v2/organisatie/organisatie';
import IEmailGeadresseerde from '../../../../../../shared/src/models/email/IEmailGeadresseerde';
import * as _ from 'lodash';
import { IOphalenEmailAccountsResultElement } from '../../../../../../shared/src/api/v2/dienst/email/email';

export interface IEmailDataIndexRangeGewijzigdParams {
  range: IndexRange;
}

export interface IExposeData {
  onEmailDataIndexRangeGewijzigd: (params: IEmailDataIndexRangeGewijzigdParams) => void;
}

const Root = styled.div<{
  mappenSectieWeergeven: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-width: 450px;
  max-width: 450px;
  margin-left: ${(props) => (props.mappenSectieWeergeven ? 400 : 0)}px;
`;

const pendingRemoteData = createPendingRemoteData<any>();
const PendingItem = () => (
  <Item
    isActief={false}
    identiteiten={pendingRemoteData}
    onderwerp={pendingRemoteData}
    preview={pendingRemoteData}
    datum={pendingRemoteData}
    avatarImageSrc={pendingRemoteData}
    organisatiesCache={{}}
    personenCache={{}}
    relatiesCache={{}}
    bijlagen={[]}
    onHerberekenenGrootte={() => {}}
  />
);

interface IProps {
  batchSize: number;
  emailsData: IRemoteData<IEmailsData | null>;
  onPaginatieDataAangevraagd: (index: number, aantal: number) => Promise<void>;
  emailBerID: number | null;
  onEmailBerIDChange: (emailID: number | null) => void;
  onExposeData: (exposeData: IExposeData) => void;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  relPersCache: Record<number, IRemoteData<IOphalenRelPersResultElement[]>>;
  mappenSectieWeergeven: boolean;
  emailAccount: IRemoteData<IOphalenEmailAccountsResultElement | null>;
}

const LijstSectie = (props: IProps) => {
  const listRef = useRef<List | null>(null);

  const cache = useRef<CellMeasurerCache>(
    useMemo(
      () =>
        new CellMeasurerCache({
          fixedWidth: true,
          // defaultHeight: 88,
        }),
      [],
    ),
  );

  const handleEmaildataIndexRangeGewijzigd = useCallback(
    (params: IEmailDataIndexRangeGewijzigdParams) => {
      for (let i = params.range.startIndex; i <= params.range.stopIndex; i++) {
        cache.current.clear(i, 0);
      }
      if (listRef.current === null) {
        return;
      }
      for (let i = params.range.startIndex; i <= params.range.stopIndex; i++) {
        listRef.current.recomputeRowHeights(i);
      }
    },
    [],
  );

  const exposeData = useMemo<IExposeData>(
    () => ({
      onEmailDataIndexRangeGewijzigd: handleEmaildataIndexRangeGewijzigd,
    }),
    [handleEmaildataIndexRangeGewijzigd],
  );

  useEffect(() => {
    props.onExposeData(exposeData);
  }, [props.onExposeData, exposeData]);

  const isRijGeladen = useCallback(
    (index: Index) =>
      props.emailsData.state === ERemoteDataState.Ready &&
      props.emailsData.data!.emails[index.index] !== undefined,
    [props.emailsData],
  );
  const laadMeerRijen = useCallback(
    async (range: IndexRange) => {
      await props.onPaginatieDataAangevraagd(
        range.startIndex,
        range.stopIndex - range.startIndex + 1,
      );
    },
    [props.onPaginatieDataAangevraagd],
  );

  const rowRenderer = useCallback(
    (rowProps: ListRowProps) => {
      const email =
        props.emailsData.state === ERemoteDataState.Pending
          ? null
          : props.emailsData.data!.emails[rowProps.index]?.data ?? null;

      const isActief = email !== null && email.ID === props.emailBerID;

      if (email === null) {
        return (
          <CellMeasurer
            cache={cache.current}
            columnIndex={0}
            key={rowProps.key}
            rowIndex={rowProps.index}
            parent={rowProps.parent}
          >
            {({ measure }) => (
              <div style={rowProps.style} className="d-flex flex-column">
                <PendingItem />
              </div>
            )}
          </CellMeasurer>
        );
      }

      const identiteiten = genereerIdentiteitenVanEmail(
        email,
        props.emailAccount.data!.Email,
        props.relPersCache,
      );

      return (
        <CellMeasurer
          cache={cache.current}
          columnIndex={0}
          key={rowProps.key}
          rowIndex={rowProps.index}
          parent={rowProps.parent}
        >
          {({ measure }) => (
            <div style={rowProps.style} className="d-flex flex-column">
              <Item
                isActief={isActief}
                onClick={() => props.onEmailBerIDChange(email.ID)}
                identiteiten={createReadyRemoteData(identiteiten)}
                onderwerp={createReadyRemoteData(email.Onderwerp)}
                preview={createReadyRemoteData(email.InhoudPreview)}
                datum={createReadyRemoteData(new Date(email.Datum!))}
                avatarImageSrc={createReadyRemoteData(null)}
                relatiesCache={props.relatiesCache}
                personenCache={props.personenCache}
                organisatiesCache={props.organisatiesCache}
                bijlagen={email!.emailBerBests}
                onHerberekenenGrootte={measure}
              />
            </div>
          )}
        </CellMeasurer>
      );
    },
    [
      props.emailsData,
      props.emailBerID,
      props.onEmailBerIDChange,
      props.relatiesCache,
      props.personenCache,
      props.organisatiesCache,
      props.emailAccount,
    ],
  );

  return (
    <Root mappenSectieWeergeven={props.mappenSectieWeergeven}>
      <AutoSizer>
        {({ width, height }) =>
          props.emailsData.state === ERemoteDataState.Pending ? (
            <div
              style={{ width, height }}
              className="d-flex align-items-center justify-content-center"
            >
              <LoadingSpinner />
            </div>
          ) : props.emailsData.data === null ? (
            <div
              style={{ width, height }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>Selecteer een map...</span>
            </div>
          ) : props.emailsData.data!.totaalAantal === 0 ? (
            <div
              style={{ width, height }}
              className="d-flex align-items-center justify-content-center"
            >
              <span>Deze map is leeg.</span>
            </div>
          ) : (
            <InfiniteLoader
              loadMoreRows={laadMeerRijen}
              isRowLoaded={isRijGeladen}
              rowCount={props.emailsData.data!.totaalAantal}
              minimumBatchSize={props.batchSize}
            >
              {(infiniteLoaderProps) => (
                <List
                  ref={(ref) => {
                    listRef.current = ref;
                    infiniteLoaderProps.registerChild(ref);
                  }}
                  deferredMeasurementCache={cache.current!}
                  rowRenderer={rowRenderer}
                  columnCount={1}
                  columnWidth={width}
                  height={height}
                  rowCount={props.emailsData.data!.totaalAantal}
                  rowHeight={cache.current.rowHeight}
                  width={width}
                  onRowsRendered={infiniteLoaderProps.onRowsRendered}
                  containerStyle={{ overflow: 'visible' }}
                  overscanRowCount={10}
                />
              )}
            </InfiniteLoader>
          )
        }
      </AutoSizer>
    </Root>
  );
};

export default LijstSectie;
