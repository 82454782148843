import React from 'react';

import styled from 'styled-components';
import { IEmailGeaddresseerdeIdentiteit } from '../../../../types';
import { IconEmail } from '../../../../../../Icons';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  padding: 1px 5px;
  border-radius: 5px;
  height: 20px;
`;

interface IProps {
  identiteit: IEmailGeaddresseerdeIdentiteit;
}

const EmailGeadresseerdeIdentiteit = (props: IProps) => {
  let content;
  if (
    props.identiteit.emailAdres === null ||
    props.identiteit.emailAdres === undefined ||
    props.identiteit.emailAdres === ''
  ) {
    content = '(ongeldig email-adres)';
  }
  content = props.identiteit.emailAdres;

  return (
    <Root>
      <IconEmail style={{ fill: Kleur.DonkerGrijs, width: 12, height: 12, marginRight: 5 }} />
      <span
        title={content}
        style={{
          color: Kleur.DonkerGrijs,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 12,
        }}
      >
        {content}
      </span>
    </Root>
  );
};

export default EmailGeadresseerdeIdentiteit;
