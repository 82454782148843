import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ITabelProps } from '../index';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import InactiefOverlay from '../../../../../../components/InactiefOverlay';
import { IEmailadresItem } from '../../../../../../../../shared/src/api/v2/bulkbericht';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';
import {
  IOphalenTalenResult,
  IOphalenTalenResultElement,
} from '../../../../../../../../shared/src/api/v2/taal';
import Skeleton from 'react-loading-skeleton';

interface IData {
  items: Record<number, IEmailadresItem>;
  totaalAantal: number;
}

type Kolom = 'email' | 'taal' | 'genereer_voorbeeld';

const RelatiesTabel = (props: ITabelProps): JSX.Element => {
  // tslint:disable-next-line:variable-name
  const [data, _setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef<IRemoteData<IData>>(data);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    _setData(data);
  }, []);
  const [isBezigMetInitieelOphalen, setIsBezigMetInitieelOphalen] = useState(false);

  const [talenResult, setTalenResult] = useState<IRemoteData<IOphalenTalenResult>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    const ophalenTalen = async () => {
      const result = await api.v2.taal.ophalen({});
      setTalenResult(createReadyRemoteData(result));
    };

    ophalenTalen();
  }, []);

  const talenBijID = useMemo<IRemoteData<{ [taalID: number]: IOphalenTalenResultElement }>>(() => {
    return mapRemoteData(talenResult, (result) => {
      return result.reduce<{ [taalID: number]: IOphalenTalenResultElement }>((acc, taal) => {
        acc[taal.TaalID] = taal;
        return acc;
      }, {});
    });
  }, [talenResult]);

  const ophalenData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden = false) => {
      if (!uitbreiden) {
        setIsBezigMetInitieelOphalen(true);
      }

      const voorkeurFilter = props.filterData.find((x) => x.naam === 'voorkeur')!;

      const result = await api.v2.bulkbericht.ontvangerssoort.ophalenEmailadressenViaSelectieQuery({
        selectieQuery: props.selectieQuery,
        paginatie,
        filterSchema: {
          filters: [
            voorkeurFilter.isActief
              ? ({
                  naam: 'VOLDOET_AAN_VOORKEUR_IDS',
                  data: [props.voorkeurID!],
                  inverteren: voorkeurFilter.data === 'voldoet_niet_aan_voorkeur',
                } as IFilterSchemaFilter)
              : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      });

      const items = result.items.reduce<Record<number, IEmailadresItem>>((acc, relatie, i) => {
        acc[paginatie.index + i] = relatie;
        return acc;
      }, (uitbreiden ? { ...dataRef.current?.data?.items } : {}) ?? {});

      setData(createReadyRemoteData({ items, totaalAantal: result.totaalAantal }));

      if (!uitbreiden) {
        setIsBezigMetInitieelOphalen(false);
      }
    },
    [props.selectieQuery, props.filterData, props.voorkeurID],
  );

  useEffect(() => {
    ophalenData({ index: 0, aantal: 50 });
  }, [ophalenData]);

  const keyExtractor = useCallback((item: IEmailadresItem) => item.Email, []);
  const kolommen = useMemo<ASPKolom<Kolom, IEmailadresItem>[]>(
    () => [
      {
        key: 'email',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Email',
        renderer: (item) => item.Email,
      },
      {
        key: 'taal',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        label: 'Taal',
        renderer: (item) => {
          if (item.TaalID === null) {
            return <span className="font-italic text-muted">Valt terug op standaardtaal</span>;
          }
          if (talenBijID.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          const taal = talenBijID.data![item.TaalID];
          return <span>{taal.Naam}</span>;
        },
      },
      {
        key: 'genereer_voorbeeld',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item) => {
          return (
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              Genereer voorbeeld
            </a>
          );
        },
      },
    ],
    [talenBijID],
  );

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      await ophalenData(positie, true);
    },
    [ophalenData],
  );

  return (
    <InactiefOverlay
      isInactief={isBezigMetInitieelOphalen}
      element={
        <ASPTabel
          rijen={data.data?.items ?? {}}
          totaalAantalRijen={data.data?.totaalAantal}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
          statusbalkWeergeven
        />
      }
      rootStyle={{ flex: 1 }}
      rootClassName="d-flex"
    />
  );
};

export default RelatiesTabel;
