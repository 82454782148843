import * as React from 'react';
import { Kleur } from '../../bedrijfslogica/constanten';
import RadioKnop from '../formulier/RadioKnop';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';
import Combobox, { IOptie } from '../formulier/Combobox';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../models/IRemoteData';
import { IOphalenRelatieSelectieRecentResultElement } from '../../../../shared/src/api/v2/relatie/Selectie';
import api from '../../api';

interface IProps {
  selectieAspGebrID: number | null;
  onSelectieAspGebrIDChange: (selectieAspGebrID: number | null) => void;
}

// noinspection JSIgnoredPromiseFromCall
const VanMijOfIemandAndersSelectie = observer((props: IProps) => {
  const { gebruikerStore } = useContext(RootStoreContext);

  const [meestRecenteReferentieRelatie, setMeestRecenteReferentieRelatie] = useState<
    IRemoteData<IOphalenRelatieSelectieRecentResultElement | null>
  >(createPendingRemoteData());

  const ophalenMeestRecenteReferentieRelatie = useCallback(async () => {
    const result = await api.v2.selectie.ophalenRelatieselectieRecent({
      aspGebrID: null,
      paginatie: {
        index: 0,
        aantal: 1,
      },
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS',
            data: [gebruikerStore.gebruiker!.AspGebrID],
            inverteren: true,
          },
        ],
      },
    });
    setMeestRecenteReferentieRelatie(
      result.relaties.length === 0
        ? createReadyRemoteData(null)
        : createReadyRemoteData(result.relaties[0]),
    );
  }, [gebruikerStore.gebruiker!.AspGebrID]);
  useEffect(() => {
    ophalenMeestRecenteReferentieRelatie();
  }, [ophalenMeestRecenteReferentieRelatie]);

  const gebruikersRijbron = useMemo(() => {
    if (gebruikerStore.gebruikers === null) {
      return null;
    }

    return gebruikerStore.gebruikers.filter(
      (gebr) => gebr.AspGebrID !== gebruikerStore.gebruiker!.AspGebrID,
    );
  }, [gebruikerStore.gebruikers, gebruikerStore.gebruiker]);

  const iemandAndersVoorselectieAspGebrID =
    meestRecenteReferentieRelatie.data !== null &&
    meestRecenteReferentieRelatie.data!.AspGebrID !== gebruikerStore.gebruiker!.AspGebrID
      ? meestRecenteReferentieRelatie.data!.AspGebrID
      : gebruikersRijbron !== null
      ? gebruikersRijbron[0].AspGebrID
      : null;

  const handleVanMijClick = useCallback(
    () => props.onSelectieAspGebrIDChange(gebruikerStore.gebruiker!.AspGebrID),
    [gebruikerStore.gebruiker, props.onSelectieAspGebrIDChange],
  );
  const handleIemandAndersClick = useCallback(() => {
    const gebrID = iemandAndersVoorselectieAspGebrID;
    if (gebrID === null) {
      return;
    }
    props.onSelectieAspGebrIDChange(gebrID);
  }, [iemandAndersVoorselectieAspGebrID, props.onSelectieAspGebrIDChange]);

  const comboboxOpties = useMemo<IOptie<number>[] | null>(() => {
    if (gebruikersRijbron === null) {
      return null;
    }

    const opties = gebruikersRijbron.map((gebr) => ({
      id: gebr.AspGebrID,
      label: gebr.NaamKort,
    }));

    return opties;
  }, [gebruikersRijbron]);

  if (
    gebruikersRijbron === null ||
    meestRecenteReferentieRelatie.state === ERemoteDataState.Pending
  ) {
    // return <LoadingSpinner />;
    return <span></span>;
  }

  if (gebruikersRijbron.length === 0) {
    return (
      <span>Er moeten mininaal twee gebruikers zijn voor het functioneren van dit component</span>
    );
  }

  return (
    <div className="d-flex align-items-center">
      {/* Van mij */}
      <div
        className="d-flex align-items-center p-2 pl-4 pr-4"
        style={{
          borderRight: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex align-items-center" onClick={handleVanMijClick}>
          <span>
            <RadioKnop
              aangevinkt={gebruikerStore.gebruiker!.AspGebrID === props.selectieAspGebrID}
              onAangevinkt={handleVanMijClick}
            />
          </span>
          <span className="ml-1" style={{ cursor: 'pointer' }}>
            Van mij
          </span>
        </div>
      </div>

      {/* Iemand anders */}
      <div className="d-flex align-items-center p-2 pl-4 pr-4">
        <div className="d-flex align-items-center">
          <span>
            <RadioKnop
              aangevinkt={
                gebruikerStore.gebruiker!.AspGebrID !== props.selectieAspGebrID &&
                props.selectieAspGebrID !== null
              }
              onAangevinkt={handleIemandAndersClick}
            />
          </span>
          <span className="ml-1" style={{ cursor: 'pointer' }} onClick={handleIemandAndersClick}>
            Iemand anders
          </span>
          <span className="ml-3" style={{ width: 100 }}>
            {comboboxOpties === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <Combobox<number>
                geselecteerd={
                  /* Als de gekozen gebruiker niet de ingelogde gebruiker is,
                    dan simpel de gebruiker kiezen uit de lijst.
                    Anders pak de meest recente gerefereerde gebruiker, als deze bestaat en ook
                    niet de ingelogde gebruiker is, dan deze voorselecteren, anders de eerste die in
                    de lijst bestaat
                     */
                  props.selectieAspGebrID === gebruikerStore.gebruiker!.AspGebrID ||
                  props.selectieAspGebrID === null
                    ? iemandAndersVoorselectieAspGebrID
                    : props.selectieAspGebrID
                }
                opties={comboboxOpties}
                onSelectieChange={(id) => props.onSelectieAspGebrIDChange(id!)}
                legeOptieTonen={false}
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
});

export default VanMijOfIemandAndersSelectie;
