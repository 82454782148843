import { EDataType, IMergeDialoogVeldFormatterProps, IMergeDialoogVeldInputProps } from './types';
import Combobox from '../formulier/Combobox';
import React from 'react';
import { format } from 'date-fns';
import DatumKiezer from '../formulier/DatumKiezer';
import { Kleur } from '../../bedrijfslogica/constanten';

export const StandaardMergeDialoogTextInput = (
  inputProps: IMergeDialoogVeldInputProps<string | null>,
) => {
  const value = inputProps.data.type === EDataType.Bepaald ? inputProps.data.data ?? '' : '';

  return (
    <input
      type="text"
      className="form-control"
      value={value}
      onChange={(ev) =>
        inputProps.onDataChange({
          type: EDataType.Bepaald,
          data: ev.target.value,
        })
      }
    />
  );
};

export const StandaardBooleanInput = (inputProps: IMergeDialoogVeldInputProps<boolean | null>) => {
  const value = inputProps.data.type === EDataType.Bepaald ? inputProps.data.data ?? null : false;

  return (
    <Combobox
      geselecteerd={value ? 1 : 2}
      onSelectieChange={(id) =>
        inputProps.onDataChange({ type: EDataType.Bepaald, data: id === 1 })
      }
      opties={[
        {
          id: 1,
          label: 'Ja',
        },
        {
          id: 2,
          label: 'Nee',
        },
      ]}
    />
  );
};

export const StandaardMergeDialoogDateInput = (
  inputProps: IMergeDialoogVeldInputProps<Date | null>,
) => {
  const value =
    inputProps.data.type === EDataType.Bepaald
      ? inputProps.data.data === null
        ? null
        : new Date(inputProps.data.data)
      : null;

  return (
    <DatumKiezer
      waarde={value}
      onGewijzigd={(nieuweWaarde) =>
        inputProps.onDataChange({
          type: EDataType.Bepaald,
          data: nieuweWaarde!,
        })
      }
      determineNextValidDate="ONBEGRENST"
      determinePreviousValidDate="ONBEGRENST"
    />
  );
};

export const StandaardMergeDialoogTextFormatter = (
  formatterProps: IMergeDialoogVeldFormatterProps<string | null>,
) => {
  const alleDatas = [formatterProps.data, ...formatterProps.andereDatas].filter(
    (x) => x !== null,
  ) as string[];
  const langsteDataIndex = alleDatas.reduce(
    (acc, curr, index) => (curr.length > alleDatas[acc].length ? index : acc),
    0,
  );
  const kortsteDataIndex = alleDatas.reduce(
    (acc, curr, index) => (curr.length < alleDatas[acc].length ? index : acc),
    0,
  );
  const langsteData = alleDatas[langsteDataIndex];
  if (kortsteDataIndex === undefined || langsteData === undefined) {
    return <span />;
  }

  const gelijkmask = new Array(langsteData.length).fill(true);
  for (const data of alleDatas) {
    for (let i = 0; i < data.length; i++) {
      // Als i buiten de lengte van de korste data valt, dan is het niet gelijk
      if (i >= alleDatas[kortsteDataIndex].length) {
        gelijkmask[i] = false;
        continue;
      }
      if (data[i] !== langsteData[i]) {
        gelijkmask[i] = false;
      }
    }
  }

  if (formatterProps.data === null) {
    return <span />;
  }

  const weergave = gelijkmask.map((isGelijk, index) => {
    const char = formatterProps.data![index];
    if (char === undefined) {
      return;
    }
    if (isGelijk) {
      return char;
    } else {
      return <span style={{ color: Kleur.Rood, fontWeight: 'bold' }}>{char}</span>;
    }
  });

  return <div>{weergave}</div>;
};

export const StandaardMergeDialoogBooleanFormatter = (
  formatterProps: IMergeDialoogVeldFormatterProps<boolean | null>,
) => {
  return <div>{formatterProps.data === null ? null : formatterProps.data ? 'Ja' : 'Nee'}</div>;
};

export const StandaardMergeDialoogDateFormatter = (
  formatterProps: IMergeDialoogVeldFormatterProps<Date | null>,
) => {
  return (
    <div>
      {formatterProps.data === null ? null : format(new Date(formatterProps.data), 'dd-MM-yyyy')}
    </div>
  );
};
