import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../dialogen/Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { IOphalenMeldingenResultElement } from '../../../../../shared/src/api/v2/service/melding';
import api from '../../../api';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import DetailTab from './DetailTab';
import { Formik } from 'formik';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenWerkbonnenResultElement } from '../../../../../shared/src/api/v2/service/werkbon';
import _ from 'lodash';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';

interface IProps extends IDialoogProps {
  id: number;
}

export interface IContext {
  werkbon: IOphalenWerkbonnenResultElement | null;
  opdracht: IOphalenOpdrachtenResultElement | null;
}

export const MeldingContext = React.createContext<IContext>({
  werkbon: null,
  opdracht: null,
});

enum ETablad {
  Detail,
}

export interface IFormikValues {
  // notities: string | null;
}

const WerkbonInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Detail);

  const [werkbon, setWerkbon] = useState<IOphalenWerkbonnenResultElement | null>(null);
  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  const ophalenWerkbon = useCallback(async () => {
    const result = await api.v2.service.ophalenWerkbonnen({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });

    setWerkbon(result.werkbonnen[0]);
  }, []);

  useEffect(() => {
    ophalenWerkbon();
  }, [ophalenWerkbon]);

  const ophalenServiceopdracht = useCallback(async () => {
    if (werkbon === null) {
      return;
    }
    const opdrachtenResult = (
      await api.v2.service.ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [werkbon.ServOpdID] }],
        },
      })
    ).opdrachten;

    setOpdracht(opdrachtenResult[0]);
  }, [werkbon]);

  useEffect(() => {
    ophalenServiceopdracht();
  }, [ophalenServiceopdracht]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Detail,
        label: 'Detail',
        content: DetailTab,
      },
    ],
    [],
  );

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (werkbon === null) {
      return null;
    }

    return {
      // notities: melding.,
    };
  }, [werkbon]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `lg` }}>
      <ModalHeader>
        <ModalTitle>Werkboninformatie</ModalTitle>
      </ModalHeader>
      <MeldingContext.Provider
        value={{
          werkbon,
          opdracht,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `600px` }}>
          {initialFormikValues === null || werkbon === null || opdracht === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        {/* <button
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        style={{ width: 100 }}
                      >
                        Ok
                      </button> */}
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                        <div className="flex-fill" />
                        <TechnischeInformatieKnop
                          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                          content={() => (
                            <div>
                              ID: {werkbon.ID}
                              {/* <br /> */}
                            </div>
                          )}
                        />
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </div>
      </MeldingContext.Provider>
    </Dialoog>
  );
};

export default WerkbonInfoDialoog;
