import { useContext, useMemo } from 'react';
import { RootStoreContext } from '../stores/RootStore';
import { RouteComponentProps } from 'react-router';

interface IHeeftAutorisatieParams {
  isAdmin: boolean;
  gebruikersPermissies: string[];
  vereistePermissies: string[];
}

interface IHeeftAutorisatieOutput {
  isToegestaan: boolean;
}

const heeftAutorisatie = (params: IHeeftAutorisatieParams): IHeeftAutorisatieOutput => {
  if (params.isAdmin) {
    return {
      isToegestaan: true,
    };
  }

  return {
    isToegestaan: params.vereistePermissies.every(
      (permissie) => params.gebruikersPermissies.indexOf(permissie) !== -1,
    ),
  };
};

export const useHeeftAutorisatie = (permissies: string[]): boolean => {
  const { autorisatieStore, gebruikerStore } = useContext(RootStoreContext);

  return useMemo(() => {
    return heeftAutorisatie({
      isAdmin: gebruikerStore.gebruiker!.IsAdministrator,
      gebruikersPermissies: autorisatieStore.permissies!,
      vereistePermissies: permissies,
    }).isToegestaan;
  }, [permissies]);
};

export const useAutorisatieBewaker = (props: RouteComponentProps, permissies: string[]) => {
  const isGeautoriseerd = useHeeftAutorisatie(permissies);

  if (!isGeautoriseerd) {
    props.history.replace('/');
  }
};

export default heeftAutorisatie;
