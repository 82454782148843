import {
  IInstellenVertegenwoordigerParams,
  IInstellenVertegenwoordigerResult,
  IOphalenVertegenwoordigersParams,
  IOphalenVertegenwoordigersResult,
  IOphalenVertegenwoordigingHoedanighedenParams,
  IOphalenVertegenwoordigingHoedanighedenResult,
  IToevoegenVertegenwoordigerParams,
  IToevoegenVertegenwoordigerResult,
  IVerwijderenVertegenwoordigersParams,
  IVerwijderenVertegenwoordigersResult,
  IWijzigenVertegenwoordigerParams,
  IWijzigenVertegenwoordigerResult,
} from '../../../../../../shared/src/api/v2/relatie/vertegenwoordiger';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const vertegenwoordiging = {
  ophalenVertegenwoordigers: async (
    params: IOphalenVertegenwoordigersParams,
  ): Promise<IOphalenVertegenwoordigersResult> => {
    return await api.post('/v2/relatie/vertegenwoordiger/ophalen-vertegenwoordigers', params);
  },
  checkToevoegenVertegenwoordiger: async (
    params: IToevoegenVertegenwoordigerParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/relatie/vertegenwoordiger/check-toevoegen-vertegenwoordiger',
      params,
    );
  },
  toevoegenVertegenwoordiger: async (
    params: IToevoegenVertegenwoordigerParams,
  ): Promise<IToevoegenVertegenwoordigerResult> => {
    return await api.post('/v2/relatie/vertegenwoordiger/toevoegen-vertegenwoordiger', params);
  },
  instellenVertegenwoordiger: async (
    params: IInstellenVertegenwoordigerParams,
  ): Promise<IInstellenVertegenwoordigerResult> => {
    return await api.post('/v2/relatie/vertegenwoordiger/instellen-vertegenwoordiger', params);
  },
  checkVerwijderenVertegenwoordigers: async (
    params: IVerwijderenVertegenwoordigersParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/relatie/vertegenwoordiger/check-verwijderen-vertegenwoordigers',
      params,
    );
  },
  verwijderenVertegenwoordigers: async (
    params: IVerwijderenVertegenwoordigersParams,
  ): Promise<IVerwijderenVertegenwoordigersResult> => {
    return await api.post('/v2/relatie/vertegenwoordiger/verwijderen-vertegenwoordigers', params);
  },
  ophalenHoedanigheden: async (
    params: IOphalenVertegenwoordigingHoedanighedenParams,
  ): Promise<IOphalenVertegenwoordigingHoedanighedenResult> => {
    return await api.post('/v2/relatie/vertegenwoordiger/ophalen-hoedanigheden', params);
  },
  checkWijzigenVertegenwoordiger: async (
    params: IWijzigenVertegenwoordigerParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/relatie/vertegenwoordiger/check-wijzigen-vertegenwoordiger', params);
  },
  wijzigenVertegenwoordiger: async (
    params: IWijzigenVertegenwoordigerParams,
  ): Promise<IWijzigenVertegenwoordigerResult> => {
    return await api.post('/v2/relatie/vertegenwoordiger/wijzigen-vertegenwoordiger', params);
  },
};

export default vertegenwoordiging;
