import { Field, FieldProps } from 'formik';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import { IFormikValues, veldnamen, WijzigenDialoogContext } from '..';
import Combobox from '../../../formulier/Combobox';
import VinkVeld from '../../../formulier/VinkVeld';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import VeldWeergave from '../../../formulier/VeldWeergave';
import { IconVerwijderen, IconWijzigen } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import ReserverenVoorraadDialoog from '../../../../paginas/Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import ReserverenProductDialoog from '../../../../paginas/Transport/Opdrachten/reservering/KoppelLocatieProductDialoog';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import api from '../../../../api';
import { ETransportopdrachtRegelsoort } from '../../../../bedrijfslogica/enums';
import nameOf from '../../../../core/nameOf';

export interface IReserverenVoorraadDialoogState {
  cntID: number | null;
}

export interface IReserverenProductDialoogState {
  cntID: number | null;
  locID: number;
}

const RegelTab: React.FC = (props) => {
  const { verdiepingen, regel, formikProps } = useContext(WijzigenDialoogContext);

  const { values } = formikProps;

  const [
    koppelenVoorraadProductDialoogState,
    setKoppelenVoorraadProductDialoogState,
  ] = useState<IReserverenVoorraadDialoogState | null>(null);
  const [
    koppelenLocatieProductDialoogState,
    setKoppelenLocatieProductDialoogState,
  ] = useState<IReserverenProductDialoogState | null>(null);

  const [product, setProduct] = useState<IOphalenProductenResultElementV2 | null>(null);
  const [producttype, setProducttype] = useState<IOphalenProducttypenResultElement | null>(null);

  useEffect(() => {
    if (values.product === null || values.product.prodID === null) {
      setProduct(null);
      return;
    }
    (async () => {
      const result = await api.v2.product.ophalenProductenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [values.product!.prodID],
            },
          ],
        },
      });
      setProduct(result.producten[0]);
    })();
  }, [values.product === null ? null : values.product.prodID]);

  useEffect(() => {
    if (values.product === null || values.product.typeID === null) {
      setProduct(null);
      return;
    }
    (async () => {
      const result = await api.v2.product.type.ophalenProducttypen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [values.product!.typeID],
            },
          ],
        },
      });
      setProducttype(result.producttypen[0]);
    })();
  }, [values.product === null ? null : values.product.typeID]);

  return (
    <>
      <ModalBody>
        {regel === null ? null : (
          <div style={{ minHeight: `400px` }}>
            <div className="form-group">
              <div className="row">
                <div className="col-12 mt-3">
                  {/* <div className="col-6"> */}
                  <div className="d-flex align-items-center">
                    <Field
                      name={nameOf<IFormikValues>('aansluitenTrekschakelaar')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <VinkVeld
                            aangevinkt={field.value}
                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          />
                        );
                      }}
                    />
                    <span className="ml-2">{veldnamen.aansluitenTrekschakelaar}</span>
                  </div>
                  {/* </div> */}
                </div>

                {regel.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering && (
                  <>
                    <div className="col-12 mt-3">
                      {/* <div className="col-6"> */}
                      <div className="d-flex align-items-center">
                        <Field
                          name={nameOf<IFormikValues>('aansluitenPerilex')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.aansluitenPerilex}</span>
                      </div>
                      {/* </div> */}
                    </div>
                  </>
                )}

                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-5">
                      <label>{veldnamen.verdieping}</label>
                      <Field
                        name={nameOf<IFormikValues>('verdieping')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (verdiepingen === null) {
                            return <LoadingSpinner />;
                          }

                          return (
                            <>
                              <Combobox
                                legeOptieTonen
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={verdiepingen!.map((x: any) => {
                                  return {
                                    id: x.Verdieping,
                                    label: x.Naam,
                                  };
                                })}
                              />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-12">
                      <label>{veldnamen.product}</label>
                      <Field
                        name={nameOf<IFormikValues>('product')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <VeldWeergave>
                              <div className="d-flex flex-fill align-items-center">
                                <div className="flex-fill">
                                  {field.value === null ? (
                                    'Geen'
                                  ) : product !== null ? (
                                    <span>
                                      {product.producttype.Merknaam} {product.producttype.Typenaam},{' '}
                                      {product.Referentiecode === null
                                        ? 'Geen referentie'
                                        : product.Referentiecode}
                                    </span>
                                  ) : producttype !== null ? (
                                    <span>
                                      {producttype.Merknaam} {producttype.Typenaam}, Nieuw
                                    </span>
                                  ) : null}
                                </div>
                                <button
                                  style={{ outline: 0, border: 0, background: 0 }}
                                  onClick={() =>
                                    regel!.regelsoort.Code === ETransportopdrachtRegelsoort.Levering
                                      ? setKoppelenVoorraadProductDialoogState({
                                          cntID:
                                            regel!.contract === null ? null : regel!.contract.CntID,
                                        })
                                      : setKoppelenLocatieProductDialoogState({
                                          cntID:
                                            regel!.contract === null ? null : regel!.contract.CntID,
                                          locID: regel!.opdracht.locatie.LocID,
                                        })
                                  }
                                >
                                  <IconWijzigen
                                    style={{ width: 18, height: 18, fill: Kleur.Grijs }}
                                  />
                                </button>
                                <button
                                  style={{ outline: 0, border: 0, background: 0 }}
                                  className="ml-1"
                                  onClick={() => form.setFieldValue(field.name, null)}
                                >
                                  <IconVerwijderen
                                    style={{ width: 18, height: 18, fill: Kleur.Grijs }}
                                  />
                                </button>
                              </div>
                            </VeldWeergave>
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="row pt-3">
                    <div className="col-12">
                      <label>{veldnamen.instructies}</label>
                      <Field
                        name={nameOf<IFormikValues>('instructies')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <input
                              type="text"
                              className="form-control"
                              {...field}
                              // onChange={(ev) =>
                              //   form.setFieldValue(field.name, ev.target.value.toUpperCase().trim())
                              // }
                              placeholder="Optioneel, b.v. aanwezig apparaat verplaatsen"
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      {koppelenVoorraadProductDialoogState !== null && (
        <ReserverenVoorraadDialoog
          open
          onSuccess={(data) => {
            formikProps.setFieldValue(nameOf<IFormikValues>('product'), {
              typeID: data.typeID,
              prodID: data.prodID,
            });
            setKoppelenVoorraadProductDialoogState(null);
          }}
          onAnnuleren={() => setKoppelenVoorraadProductDialoogState(null)}
          cntID={
            koppelenVoorraadProductDialoogState.cntID !== null
              ? koppelenVoorraadProductDialoogState.cntID
              : undefined
          }
        />
      )}
      {koppelenLocatieProductDialoogState !== null && (
        <ReserverenProductDialoog
          open
          locID={koppelenLocatieProductDialoogState.locID}
          cntID={
            koppelenLocatieProductDialoogState.cntID !== null
              ? koppelenLocatieProductDialoogState.cntID
              : undefined
          }
          onSuccess={(data) => {
            formikProps.setFieldValue(nameOf<IFormikValues>('product'), {
              typeID: null,
              prodID: data,
            });
            setKoppelenLocatieProductDialoogState(null);
          }}
          onAnnuleren={() => setKoppelenLocatieProductDialoogState(null)}
        />
      )}
    </>
  );
};

export default RegelTab;
