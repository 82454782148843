import {
  IOphalenAfbeeldingenParams,
  IOphalenAfbeeldingenResult,
  IVerwijderenAfbeeldingenParams,
  IVerwijderenAfbeeldingenResult,
  IWijzigenAfbeeldingParams,
  IWijzigenAfbeeldingResult,
  IToevoegenAfbeeldingParams,
  IToevoegenAfbeeldingResult,
} from '../../../../../../shared/src/api/v2/website/afbeelding';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const afbeelding = {
  ophalenAfbeeldingen: async (
    params: IOphalenAfbeeldingenParams,
  ): Promise<IOphalenAfbeeldingenResult> => {
    return api.post('/v2/extern/afbeelding/ophalen-afbeeldingen', params);
  },
  checkToevoegenAfbeelding: async (params: IToevoegenAfbeeldingParams): Promise<ICheckData> => {
    return api.post('/v2/extern/afbeelding/check-toevoegen-afbeelding', params);
  },
  toevoegenAfbeelding: async (
    params: IToevoegenAfbeeldingParams,
  ): Promise<IToevoegenAfbeeldingResult> => {
    return api.post('/v2/extern/afbeelding/toevoegen-afbeelding', params);
  },
  verwijderenAfbeeldingen: async (
    params: IVerwijderenAfbeeldingenParams,
  ): Promise<IVerwijderenAfbeeldingenResult> => {
    return api.post('/v2/extern/afbeelding/verwijderen-afbeeldingen', params);
  },
  checkWijzigenAfbeelding: async (params: IWijzigenAfbeeldingParams): Promise<ICheckData> => {
    return api.post('/v2/extern/afbeelding/check-wijzigen-afbeelding', params);
  },
  wijzigenAfbeelding: async (
    params: IWijzigenAfbeeldingParams,
  ): Promise<IWijzigenAfbeeldingResult> => {
    return api.post('/v2/extern/afbeelding/wijzigen-afbeelding', params);
  },
};

export default afbeelding;
