import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo } from 'react';
import { AutoSizer } from 'react-virtualized';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { ERegelstatusTransport, ETransportopdrachtRegelsoort } from '../../../bedrijfslogica/enums';
import nameOf from '../../../core/nameOf';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { IconAttachment, IconInformatie, IconKlok, IconLock } from '../../Icons';
import MenuLayout from '../../MenuLayout';
import SoortenIndicatie from '../../kolomveld/transportopdrachtregel/SoortenIndicatieComp';
import { EHoedanigheid } from '../../personalia/RelatieSelectieDialoog';
import RelatiesVisualisaties from '../../personalia/RelatiesVisualisaties';
import Regels from './Regels';
import TransportopdrachtInfoDialoog from '../OpdrachtInfoDialoog';

export interface IAfgehandeldTabelContext {
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  onRequestRefresh: () => void;
}
export const AfgehandeldTabelContext = React.createContext<IAfgehandeldTabelContext>(null as any);

export interface IWijzigenOpdrachtDialoogState {
  trsOpdID: number;
}

export interface ITransportopdrachtInfoDialoogState {
  trsOpdID: number;
}

interface IProps {
  opdrachten: IOpdracht[] | null;
  opdrachtenSelectie: number[];
  ophalenBezig: boolean;
  onOpdrachtenSelectieChange: (value: number[]) => void;
  uitgeklapteOpdrachten: number[];
  onUitgeklapteOpdrachtenChange: (value: number[]) => void;
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  wijzigenOpdrachtDialoogState: IWijzigenOpdrachtDialoogState | null;
  onWijzigenOpdrachtDialoogStateChange: (value: IWijzigenOpdrachtDialoogState | null) => void;
  onRequestRefresh: () => void;
  transportopdrachtInfoDialoogState: ITransportopdrachtInfoDialoogState | null;
  onTransportopdrachtInfoDialoogStateChange: (
    value: ITransportopdrachtInfoDialoogState | null,
  ) => void;
  filterbalk?: JSX.Element;
}

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  relatie: IOphalenRelatiesResultElementV2 | null;
}

export interface IOpdracht extends IOphalenOpdrachtenResultElementV2 {
  regels: IRegel[];
}

const AfgehandeldTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback((row: IOpdracht) => row.TrsOpdID, []);

  const kolomBreedteAantalRegels = 30;
  const kolomBreedteRelatie = 200;

  const rows = useMemo<IOpdracht[] | null>(() => {
    if (props.opdrachten === null) {
      return null;
    }

    const opdrachten = props.opdrachten.map((opdracht) => ({
      ...opdracht,
    }));

    // const opdrachtenGesorteerd = _.orderBy(opdrachten, ['Opdrachtnummer'], ['desc']);

    return opdrachten;
  }, [props.opdrachten, props.regelsSelectie]);

  const handleMarkerenUitstaand = useCallback(
    async (trsOpdIDs: number[]) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Geselecteerde transportopdracht(en) markeren als Uitstaand?
                <br />
                Hiermee komt de opdracht weer onder tabblad Uitstaand te staan.
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.transport.opdracht.afgehandeld.markerenOpdrachtenUitstaand({
        trsOpdIDs,
      });

      props.onRequestRefresh();
    },
    [props.opdrachtenSelectie],
  );

  const kolommen = useMemo<TypedColumn<IOpdracht>[]>(
    () => [
      {
        name: 'Opdrachtnummer',
        title: 'Opd.nr',
      },
      {
        name: '__opdrachtInfo' as any,
        title: ' ',
      },
      {
        name: '__dienst' as any,
        title: 'Dienst',
      },
      {
        name: '__relaties' as any,
        title: 'Relatie(s)',
      },
      {
        name: 'locatie',
        title: 'Locatie',
        getCellValue: (x) =>
          x.locatie.Straatnaam + ' ' + x.locatie.Huisnummer + ' ' + x.locatie.Bisnummer,
      },
      {
        name: '__soortenIndicatie' as any,
        title: '#/Srt.',
      },
      {
        name: 'Bezoekdatum',
        title: 'Bezoekdatum',
      },
      {
        name: '__tijdenActueel' as any,
        title: ' ',
      },
      {
        name: '__tijdenVast' as any,
        title: ' ',
      },

      {
        name: '__afmeldstatus' as any,
        title: 'Status',
      },
      {
        name: '__resultaat' as any,
        title: 'Resultaat',
      },
      // {
      //   name: '__heeftBestanden' as any,
      //   title: 'Bijl.',
      // },
      {
        name: '__actieMenu' as any,
        title: ' ',
      },
      {
        name: 'DatumAfgehandeld',
        title: 'Afgehandeld',
      },
      {
        name: 'DocumentatieVerstuurd',
        title: 'Doc. verstuurd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOpdracht>[]>(
    () => [
      {
        columnName: 'Opdrachtnummer',
        width: 90,
      },
      {
        columnName: '__opdrachtInfo',
        width: 40,
      },
      {
        columnName: '__dienst' as any,
        width: 100,
      },
      {
        columnName: '__soortenIndicatie' as any,
        width: 75,
      },
      {
        columnName: 'locatie',
        width: 315,
      },
      {
        columnName: '__relaties' as any,
        width: kolomBreedteRelatie,
      },
      {
        columnName: 'Bezoekdatum',
        width: 125,
      },
      {
        columnName: 'DatumAfgehandeld',
        width: 135,
      },
      {
        columnName: '__tijdenActueel' as any,
        width: 30,
      },
      {
        columnName: '__tijdenVast' as any,
        width: 30,
      },

      {
        columnName: '__afmeldstatus' as any,
        width: 125,
      },
      {
        columnName: '__resultaat' as any,
        width: 135,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 80,
      },
      {
        columnName: '__actieMenu' as any,
        width: 100,
      },
      {
        columnName: 'DocumentatieVerstuurd',
        width: 150,
      },
    ],
    [],
  );

  const context = useMemo<IAfgehandeldTabelContext>(() => {
    return {
      regelsSelectie: props.regelsSelectie,
      onRegelsSelectieChange: props.onRegelsSelectieChange,
      onRequestRefresh: props.onRequestRefresh,
    };
  }, [props.onRequestRefresh, props.regelsSelectie, props.onRegelsSelectieChange]);

  return (
    <AfgehandeldTabelContext.Provider value={context}>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={() => handleMarkerenUitstaand(props.opdrachtenSelectie)}
              >
                {/* <IconVink style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Naar status Uitstaand</span>
              </button>
            </div>
            <div className="d-flex flex-fill ml-2">{props.filterbalk}</div>
          </>
        }
        body={
          <>
            {rows === null || props.ophalenBezig ? (
              <div className="d-flex flex-fill align-items-center justify-content-center p-5 pl-4 pr-4">
                <LoadingSpinner />
              </div>
            ) : (
              <AutoSizer
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {(size) => (
                  <GridStyleWrapper height={size.height}>
                    <Grid rows={rows} columns={kolommen} getRowId={keyExtractor}>
                      <DataTypeProvider
                        for={['__opdrachtInfo']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IOpdracht;

                          return (
                            <div className="mb-1 align-items-center">
                              <a
                                href="#"
                                // style={{ color: Kleur.DonkerGrijs }}
                                onClick={() => {
                                  props.onTransportopdrachtInfoDialoogStateChange({
                                    trsOpdID: rij.TrsOpdID,
                                  });
                                }}
                              >
                                <IconInformatie
                                  style={{ width: 17, height: 17, fill: Kleur.Blauw }}
                                />
                              </a>
                              {rij.Notities !== null ? (
                                <span className="m-1" style={{ color: Kleur.Blauw }}>
                                  <b>!</b>
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__dienst']}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;
                          return (
                            <>
                              <span>
                                {row.dienst.Naam !== null
                                  ? row.dienst.Naam
                                  : row.dienst.relatie!.weergavenaam}
                              </span>
                            </>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IOpdracht>('DocumentatieVerstuurd')]}
                        formatterComponent={(formatterProps) => {
                          return <span>{formatterProps.value !== null ? 'Ja' : 'Nee'}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__soortenIndicatie']}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;
                          return <SoortenIndicatie opdracht={row} />;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IOpdracht>('locatie')]}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;
                          const locatie =
                            row.locatie.Straatnaam +
                            ' ' +
                            row.locatie.Huisnummer +
                            (row.locatie.Bisnummer !== null ? ' ' + row.locatie.Bisnummer : '') +
                            ', ' +
                            row.locatie.Plaatsnaam;
                          return <span>{locatie}</span>;
                        }}
                      />
                      <DataTypeProvider
                        for={['__relaties']}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;
                          const relIDs: number[] = _.uniq(
                            row.regels
                              .filter((x) => x.relatie !== null)
                              .map((x) => x.relatie!.RelID),
                          );
                          if (relIDs.length === 0) {
                            return <span />;
                          }
                          return (
                            <RelatiesVisualisaties
                              relIDs={relIDs}
                              relatieLinkBuilder={(hoedanigheid, relID) =>
                                `/${
                                  hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                }/${relID}/transport/opdracht`
                              }
                            />
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IOpdracht>('Bezoekdatum')]}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;
                          return (
                            <span>
                              {row.Bezoekdatum === null
                                ? ''
                                : format(new Date(row.Bezoekdatum), 'dd-MM-yyyy')}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IOpdracht>('DatumAfgehandeld')]}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;
                          return (
                            <span>
                              {row.DatumAfgehandeld === null
                                ? ''
                                : format(new Date(row.DatumAfgehandeld), 'dd-MM-yyyy HH:mm')}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__tijdenActueel']}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;

                          return (
                            <span>
                              {row.BezoektijdenActueel ? (
                                <IconKlok style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                              ) : (
                                ''
                              )}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__tijdenVast']}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;

                          return (
                            <span>
                              {row.BezoektijdenNietAutomatischBijwerken ? (
                                <IconLock style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                              ) : (
                                ''
                              )}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__afmeldstatus']}
                        formatterComponent={(formatterProps) => {
                          const row = formatterProps.row as IOpdracht;
                          const reedsAfgemeld = row.regels.some((x) => x.status.Status !== 0);
                          const nogAfmelden = row.regels.some((x) => x.status.Status === 0);

                          const afgemeld =
                            reedsAfgemeld && nogAfmelden ? (
                              <span style={{ color: Kleur.LichtGroen }}>Deels afgemeld</span>
                            ) : reedsAfgemeld ? (
                              <span style={{ color: Kleur.Groen }}>Afgemeld</span>
                            ) : (
                              // <a
                              //   href="#"
                              //   onClick={async () => {
                              //     // setRegelIDs(row.regels.map((x) => x.TrsRegID));
                              //     // setAfmeldenDialoogTonen(true);
                              //     // handleRequestRefresh();
                              //   }}
                              //   style={{ color: Kleur.Blauw }}
                              // >
                              //   Afmelden
                              // </a>
                              <span style={{ color: Kleur.Grijs }}>Niet afgemeld</span>
                            );

                          return <span>{afgemeld}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__resultaat']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOpdracht = formatterProps.row;

                          // Aantallen per status

                          const aantalUitgevoerd = rij.regels.filter(
                            (x) => x.status.Status === ERegelstatusTransport.UITGEVOERD,
                          ).length;
                          const aantalMislukt = rij.regels.filter(
                            (x) => x.status.Status === ERegelstatusTransport.MISLUKT,
                          ).length;
                          const aantalGeannuleerd = rij.regels.filter(
                            (x) => x.status.Status === ERegelstatusTransport.GEANNULEERD,
                          ).length;

                          let status: string = '';
                          status += aantalGeannuleerd !== 0 ? `${aantalGeannuleerd} Geannul.` : '';
                          status += aantalMislukt !== 0 ? `${aantalMislukt} Mislukt` : '';

                          const nietToegwezenRegels = rij.regels.some(
                            (x) =>
                              x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering &&
                              x.type === null &&
                              // !x.type.Bulkproduct &&
                              x.product === null &&
                              x.status.Status === ERegelstatusTransport.UITGEVOERD &&
                              rij.DatumAfgehandeld !== null,
                          );

                          return (
                            <span style={{ color: Kleur.Rood }}>
                              {nietToegwezenRegels ? 'Geen type/product ' : ''}
                              {status}
                            </span>
                          );
                        }}
                      ></DataTypeProvider>

                      <DataTypeProvider
                        for={['__heeftBestanden']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOpdracht = formatterProps.row;
                          const heeftBestanden = rij.bestanden.length > 0 ? true : false;
                          return (
                            <span>
                              {heeftBestanden ? (
                                <IconAttachment
                                  style={{ width: 20, height: 20, fill: Kleur.Grijs }}
                                />
                              ) : null}
                            </span>
                          );
                        }}
                      />

                      <SelectionState
                        selection={props.opdrachtenSelectie}
                        onSelectionChange={(value) =>
                          props.onOpdrachtenSelectieChange(value as number[])
                        }
                      />
                      <RowDetailState
                        expandedRowIds={props.uitgeklapteOpdrachten}
                        onExpandedRowIdsChange={(value) =>
                          props.onUitgeklapteOpdrachtenChange(value as number[])
                        }
                      />

                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />
                      <VirtualTable />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow showSortingControls />

                      <EditingState
                        onCommitChanges={(changes) => {
                          if (changes.deleted === undefined) {
                            return;
                          }
                          const deleted = changes.deleted;
                          const id = deleted[deleted.length - 1];
                        }}
                        onEditingRowIdsChange={(rowIds) => {
                          const id = rowIds[rowIds.length - 1] as number;
                          props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                        }}
                      />

                      {/* <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}
                      <TableRowDetail
                        toggleCellComponent={DXTableToggleCellComponent}
                        contentComponent={Regels}
                      />
                      <TableSelection cellComponent={DXTableCheckboxComponent} />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </AutoSizer>
            )}
          </>
        }
      />
      {props.transportopdrachtInfoDialoogState !== null && (
        <TransportopdrachtInfoDialoog
          open
          trsOpdID={props.transportopdrachtInfoDialoogState.trsOpdID}
          onSuccess={() => {
            props.onTransportopdrachtInfoDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onTransportopdrachtInfoDialoogStateChange(null)}
        />
      )}
    </AfgehandeldTabelContext.Provider>
  );
});

export default AfgehandeldTabel;
