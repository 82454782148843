import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenNieuweKlantenResultElement } from '../../../../../../shared/src/api/v2/klantactie/winweek';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { addWeeks, differenceInCalendarWeeks, format, getWeek } from 'date-fns';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../../../components/formulier/Combobox';

type Kolom = 'RelID' | 'datumEersteBestelling';

export enum EFilter {
  JaarWeek = 'JAARWEEK',
}

interface IProps extends RouteComponentProps {}

interface IMuterenDialoogState {
  id: number | null;
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

const Bestellingen: React.FC<IProps> = (props) => {
  const defaultUrlState: IUrlState = useMemo(() => {
    const nu = new Date();
    const jaar = nu.getFullYear();
    const week = getWeek(nu);
    const id = Number(`${jaar}${week}`);

    return {
      selectie: [],
      filterData: [
        {
          naam: EFilter.JaarWeek,
          isActief: true,
          data: [id],
        },
      ],
    };
  }, []);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<IOphalenNieuweKlantenResultElement[] | null>(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenRijen = async () => {
    let results = await api.v2.klantactie.winweek.ophalenNieuweKlanten({
      filterSchema,
      orderSchema: {
        orders: [
          {
            naam: 'INGANGSDATUM',
            richting: 'DESC',
          },
        ],
      },
    });
    setRijen(results.nieuweKlanten);
  };

  useEffect(() => {
    ophalenRijen();
  }, [filterSchema]);

  const keyExtractor = useCallback((row: IOphalenNieuweKlantenResultElement) => row.RelID, [rijen]);

  const kolommen = useMemo<ASPKolom<Kolom, IOphalenNieuweKlantenResultElement>[]>(
    () => [
      {
        key: 'RelID',
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => (
          <RelatieVisualisatie relID={x.RelID!} options={{ geenLinkToepassen: false }} />
        ),
      },
      {
        key: 'datumEersteBestelling',
        label: 'Eerste bestelling',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (x) => format(new Date(x.datumEersteBestelling), 'dd-MM-yyyy'),
      },
      {
        key: '__weeknummer' as any,
        label: 'Week',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => format(new Date(x.datumEersteBestelling), 'II'),
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.JaarWeek,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const ids = weergaveProps.data as number[];
          const geselecteerd = ids.length === 0 ? null : ids[0];

          const nu = new Date();

          const drieWekenGeleden = addWeeks(nu, -3);
          const drieWekenVerder = addWeeks(nu, 3);

          const aantalWekenTussen = differenceInCalendarWeeks(drieWekenVerder, drieWekenGeleden);

          const weekjaarOpties = new Array(aantalWekenTussen).fill(null).map((_, i) => {
            const datum = addWeeks(drieWekenGeleden, i);

            const jaar = datum.getFullYear();
            const week = getWeek(datum);

            const id = Number(`${jaar}${week}`);
            const label = `${jaar}-${week}`;

            return { id, label };
          });

          // const weekjaarOpties = [
          //   { id: 202436, label: '2024-36' },
          //   { id: 202437, label: '2024-37' },
          //   { id: 202438, label: '2024-38' },
          // ];

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Week</span>
              <Combobox
                geselecteerd={geselecteerd}
                onSelectieChange={(x) => {
                  const newValue = x === null ? [] : [x];
                  weergaveProps.onDataChange(newValue);
                  weergaveProps.toepassen();
                }}
                opties={weekjaarOpties}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Nieuwe klanten</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{
                border: `1px solid ${Kleur.LichtGrijs}`,
              }}
              disabled={urlState.selectie.length !== 1}
              onClick={async () => {
                const filterData = urlState.filterData.find((x) => x.naam === EFilter.JaarWeek)!;

                const params = { relID: urlState.selectie[0], jaarweek: filterData.data[0] };

                const checkData = await api.v2.klantactie.winweek.checkInstellenWinnaar(params);
                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Wil je de geselecteerde klant als winnaar kiezen?
                          <br />
                          De klant verschijnt dan bij tab Winnaars.
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.klantactie.winweek.instellenWinnaar(params);

                // ophalenRijen();
              }}
            >
              {/* <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Winnaar selecteren</span>
            </button>
            <div className="flex-fill">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          rijen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              // onWijzigenRij={async (rij) => {
              //   setUrlStateSync('muterenDialoogState', { id: rij.ID ?? null });
              // }}
            />
          )
        }
      />
    </>
  );
};

// const TdComponent = (props: ITableDataProps<Kolom, IWinweekWinnaar>) => {
//   return (
//     <TableData
//       {...props}
//       style={{
//         whiteSpace: 'nowrap',
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//         maxWidth: '100%',
//         display: 'block',
//         paddingTop: '8px',
//       }}
//     />
//   );
// };

export default withRouter(Bestellingen);
