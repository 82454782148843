import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, MeldingContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import _ from 'lodash';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import ContractVisualisatie from '../../../entiteitVisualisaties/ContractVisualisatie';

const DetailTab: React.FC<IProps> = (props) => {
  const { werkbon, opdracht } = useContext<IContext>(MeldingContext);

  return (
    <>
      {werkbon === null || opdracht === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="d-flex flex-column flex-fill p-3">
            <div className="row">
              <div className="col-12">
                <VeldWeergave>
                  <GegevensLayout
                    labelBreedte="150px"
                    gegevens={[
                      {
                        label: 'Datum',
                        waarde:
                          werkbon.Bezoekdatum !== null
                            ? format(new Date(werkbon.Bezoekdatum), 'dd-MM-yyyy')
                            : null,
                      },
                      {
                        label: 'Werkzaamheden',
                        waarde: werkbon.Werkzaamheden,
                      },
                      {
                        label: 'Totale kosten (excl. btw)',
                        waarde: <FormatteerBedrag bedrag={werkbon.KostenTotaal} />,
                      },
                      {
                        label: 'Kosten doorberekenen',
                        waarde: werkbon.KostenDoorberekenen ? 'Ja' : 'Nee',
                      },
                      {
                        label: 'Kosten doorberekend',
                        waarde: werkbon.KostenDoorberekend ? 'Ja' : 'Nee',
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>

              {opdracht !== null ? (
                <div className="col-12 mt-3">
                  <VeldWeergave>
                    <GegevensLayout
                      labelBreedte="150px"
                      gegevens={[
                        {
                          label: 'Relatie',
                          waarde: <RelatieVisualisatie relID={opdracht.melding.RelID!} />,
                        },
                        {
                          label: 'Contract',
                          waarde:
                            opdracht.melding.contract !== null ? (
                              <ContractVisualisatie cntID={opdracht.melding.contract?.CntID} />
                            ) : (
                              <span>Geen contract</span>
                            ),
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>
              ) : (
                <span>Geen gekoppelde serviceopdracht</span>
              )}

              {opdracht !== null ? (
                <div className="col-12 mt-3">
                  <VeldWeergave>
                    <GegevensLayout
                      labelBreedte="150px"
                      gegevens={[
                        {
                          label: 'Servicedienst',
                          waarde: <RelatieVisualisatie relID={opdracht.dienst.RelID} />,
                        },
                        {
                          label: 'Uitgevoerd door',
                          waarde:
                            werkbon.uitvoerendeDienst !== null ? (
                              <RelatieVisualisatie relID={werkbon.uitvoerendeDienst.RelID} />
                            ) : (
                              <span></span>
                            ),
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>
              ) : (
                <span></span>
              )}

              <div className="col-12">
                {' '}
                <VeldWeergave>
                  <GegevensLayout
                    labelBreedte="150px"
                    gegevens={[
                      {
                        label: 'Rep.opdracht',
                        waarde: opdracht.melding.Meldnummer + '-' + opdracht.Volgnummer,
                      },
                      {
                        label: 'Datum verstuurd',
                        waarde: format(new Date(opdracht.DatumVerstuurd!), 'dd-MM-yyyy'),
                      },
                      {
                        label: 'Omschrijving',
                        waarde: opdracht.Omschrijving,
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailTab;
