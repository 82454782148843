import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { IOphalenGebruikersResultElement } from '../../../../../../../shared/src/api/v2/gebruiker';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../api';
import Dialoog from '../../../../dialogen/Dialoog';
import {
  IActie,
  IOphalenActiesResult,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import nameOf from '../../../../../core/nameOf';

interface IProps extends IDialoogProps<{ tarActieID: number }> {}

const SelectieDialoog: React.FC<IProps> = observer((props) => {
  const [actiesResult, setActiesResult] = useState<IOphalenActiesResult | null>(null);

  const ophalenActies = useCallback(async () => {
    const result = await api.v2.aanbod.tarieven.acties.ophalenActies({
      filterSchema: {
        filters: [
          {
            naam: 'ACTIEF',
            data: true,
          },
        ],
      },
      orderSchema: { orders: [{ naam: 'NUMMER', richting: 'DESC' }] },
    });
    setActiesResult(result);
  }, []);

  useEffect(() => {
    ophalenActies();
  }, [ophalenActies]);

  const keyExtractor = useCallback((row: IActie) => row.TarActieID, []);

  const kolommen = useMemo<TypedColumn<IActie>[]>(
    () => [
      {
        name: 'Nummer',
        title: 'Nr.',
      },
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: '__openbaar' as any,
        title: 'Openb.',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
      { name: '__kies_actie' as any, title: ' ' },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IActie>[]>(
    () => [
      {
        columnName: 'Nummer' as any,
        width: 65,
      },
      {
        columnName: 'Naam' as any,
        width: 400,
      },
      {
        columnName: '__openbaar' as any,
        width: 85,
      },
      {
        columnName: 'Actief' as any,
        width: 110,
      },
      {
        columnName: '__kies_actie' as any,
        width: 110,
      },
    ],
    [],
  );

  const onGekozen = useCallback((tarActieID: number) => props.onSuccess({ tarActieID }), [
    props.onSuccess,
  ]);

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Selecteer een actie</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0">
        {actiesResult === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper height={500}>
            <Grid getRowId={keyExtractor} columns={kolommen} rows={actiesResult.acties}>
              <DataTypeProvider
                for={['__kies_actie']}
                formatterComponent={(formatterProps) => {
                  // const entiteit = formatterProps.row as TEntiteit;
                  const rij: IActie = formatterProps.row;

                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        props.onSuccess({
                          tarActieID: rij.TarActieID,
                        });
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              />

              <DataTypeProvider
                for={[nameOf<IActie>('Actief')]}
                formatterComponent={(formatterProps) => {
                  return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <Table
                rowComponent={(rowProps) => (
                  <tr
                    {...rowProps}
                    // className=""
                    style={{
                      cursor: 'pointer',
                    }}
                    onDoubleClick={() => {
                      const row: IActie = rowProps.row;
                      onGekozen(row.TarActieID);
                    }}
                  >
                    {rowProps.children}
                  </tr>
                )}
              />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
            </Grid>
          </GridStyleWrapper>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start mt-2">
        <button className="btn btn-secondary" style={{ width: 100 }} onClick={props.onAnnuleren}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default SelectieDialoog;
