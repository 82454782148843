import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { IconContactTelefoon } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {}

const ToestelSectie = observer((props: IProps) => {
  const { voipStore, gebruikerStore } = useContext(RootStoreContext);

  const voipAccount = useMemo(() => {
    if (
      voipStore.voipAccountsVigerend === null ||
      gebruikerStore.gebruiker === null ||
      voipStore.voipAccounts === null
    ) {
      return null;
    }
    const voipAccVig =
      voipStore.voipAccountsVigerend.find(
        (x) => x.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
      ) ?? null;
    if (voipAccVig === null) {
      return null;
    }
    return voipStore.voipAccounts.find((x) => x.VoipAccID === voipAccVig.VoipAccID) ?? null;
  }, [voipStore.voipAccountsVigerend, gebruikerStore.gebruiker, voipStore.voipAccounts]);

  if (voipAccount === null) {
    return null;
  }

  return (
    <div
      style={{
        padding: '8px 18px',
        backgroundColor: 'rgba(150,150,150,.25)',
      }}
      className="d-flex align-items-center w-100"
    >
      <IconContactTelefoon style={{ fill: Kleur.LichtGrijs, width: 17, height: 17 }} />
      <span className="flex-fill d-flex justify-content-center" style={{ color: Kleur.Wit }}>
        {voipAccount.Naam}
      </span>
      <span>{voipAccount.TelefoonIntern}</span>
    </div>
  );
});

export default ToestelSectie;
