import { create } from 'zustand';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../models/IRemoteData';
import api from '../../api';
import { IBezigheid } from '../../../../shared/src/api/v2/gebruiker';

type BezigheidNaamEnum =
  | 'WHATSAPP'
  | 'RELATIE'
  | 'CONTROL_TAAK'
  | 'APP_EVENT'
  | 'BELTAAK'
  | 'SIGNALERING'
  | 'ESCALATIE';

export interface IBezigheidsleutel {
  naamEnum: BezigheidNaamEnum;
  id: number;
}

export const bezigheidsleutelNaarString = (sleutel: IBezigheidsleutel) => {
  return `${sleutel.naamEnum}-${sleutel.id}`;
};

const stringNaarBezigheidsleutel = (sleutel: string): IBezigheidsleutel => {
  // Split last part of the key
  const parts = sleutel.split('-');
  if (parts.length < 2) {
    throw new Error('Ongeldig sleutel formaat');
  }
  const naamEnum = parts.slice(0, parts.length - 1).join('-') as BezigheidNaamEnum;
  const idStr = parts[parts.length - 1];

  const id = parseInt(idStr, 10);
  if (isNaN(id)) {
    throw new Error('ID is geen nummer');
  }

  return { naamEnum, id };
};

export const bezigheidNaarSleutel = (bezigheid: IBezigheid): IBezigheidsleutel => {
  const naamEnum = bezigheid.NaamEnum as BezigheidNaamEnum;
  switch (naamEnum) {
    case 'WHATSAPP':
      return { naamEnum, id: bezigheid.WhatsAppSes_ID! };
    case 'RELATIE':
      return { naamEnum, id: bezigheid.RelID! };
    case 'CONTROL_TAAK':
      return { naamEnum, id: bezigheid.ControlTaakID! };
    case 'APP_EVENT':
      return { naamEnum, id: bezigheid.AppEventID! };
    case 'BELTAAK':
      return { naamEnum, id: bezigheid.BeltaakID! };
    case 'SIGNALERING':
      return { naamEnum, id: bezigheid.SignaleringID! };
    case 'ESCALATIE':
      return { naamEnum, id: bezigheid.EscalatieID! };
  }
  throw new Error('Onbekende bezigheid');
};

export interface IBezigheidKnopStore {
  bezigheden: { [sleutelString: string]: IRemoteData<IBezigheid> };
  enqueueOphalenBezigheden: (sleutels: IBezigheidsleutel[]) => void;
}

interface IEnqueuedState {
  timeout: NodeJS.Timeout;
  sleutels: IBezigheidsleutel[];
}

let enqueuedState: IEnqueuedState | null = null;

export const useBezigheidKnopStore = create<IBezigheidKnopStore>((set, get) => ({
  bezigheden: {},
  enqueueOphalenBezigheden: (sleutels) => {
    if (sleutels.length === 0) {
      return;
    }

    const bezigheden = { ...get().bezigheden };

    // Maak sleutel strings van de sleutels die nog niet voorkomen
    const items = sleutels
      .map((sleutel, i) => ({ sleutelString: bezigheidsleutelNaarString(sleutel), index: i }))
      .filter((item) => bezigheden[item.sleutelString] === undefined);

    if (items.length === 0) {
      return;
    }

    // Voeg sleutels toe aan de store voor pending, zodat we ze niet nogmaals ophalen
    for (const item of items) {
      bezigheden[item.sleutelString] = createPendingRemoteData();
    }

    set({ bezigheden });

    const toegepasteSleutels = items.map((item) => sleutels[item.index]);

    const handler = async () => {
      const state = enqueuedState!;
      enqueuedState = null;

      const sleutelStrings = state.sleutels.map(bezigheidsleutelNaarString);

      const result = await api.v2.gebruiker.ophalenBezighedenV2({
        filterSchema: {
          filters: [
            {
              naam: 'SLEUTELS',
              data: sleutelStrings,
            },
          ],
        },
      });

      const bezigheden = { ...get().bezigheden };

      for (const bezigheid of result.bezigheden) {
        const sleutel = bezigheidNaarSleutel(bezigheid);
        const sleutelString = bezigheidsleutelNaarString(sleutel);
        bezigheden[sleutelString] = createReadyRemoteData(bezigheid);
      }

      set({ bezigheden });
    };
    const timeout = setTimeout(handler, 100);

    if (enqueuedState !== null) {
      clearTimeout(enqueuedState.timeout);
      enqueuedState.timeout = timeout;
      enqueuedState.sleutels = enqueuedState.sleutels.concat(toegepasteSleutels);
      return;
    }

    enqueuedState = {
      sleutels: toegepasteSleutels,
      timeout,
    };
  },
}));
