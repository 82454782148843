import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { IOphalenActieclaimsResultElement } from '../../../../../shared/src/api/v2/actie/klantWerftKlant';
import _ from 'lodash';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconKruis, IconToevoegen, IconVerwijderen, IconVlag } from '../../Icons';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import FormatteerBedrag from '../../MutatieBedrag';
import { format } from 'date-fns';
import DetailComp from './DetailComp';
import {
  IOphalenContractenResultElement,
  IOphalenContractenResultElementV2,
} from '../../../../../shared/src/api/v2/contract';
import RelatieSelectieDialoog, {
  ETabblad,
} from '../../../components/personalia/RelatieSelectieDialoog';

export enum EClaimstatus {
  Beoordelen = 0,
  Goedgekeurd = 1,
  Afgekeurd = 2,
}

export interface IActieclaimTabelContext {
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  onRequestRefresh: () => void;
}
export const ActieclaimTabelContext = React.createContext<IActieclaimTabelContext>(null as any);

export interface IRelatieSelecterenDialoogState {
  tabblad: ETabblad;
}

interface IProps {
  relID?: number;
  actieclaims: IOphalenActieclaimsResultElement[] | null;
  actieclaimsSelectie: number[];
  onActieclaimsSelectieChange: (value: number[]) => void;
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  onRequestRefresh: () => void;
}

export interface IRow extends IOphalenActieclaimsResultElement {
  contracten: IOphalenContractenResultElementV2[];
}

const ActieclaimTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [
    relatieSelecterenDialoogState,
    setRelatieSelecterenDialoogState,
  ] = useState<IRelatieSelecterenDialoogState | null>(null);

  const rows = useMemo<IRow[] | null>(() => {
    if (props.actieclaims === null) {
      return null;
    }

    const actieclaimsResult = props.actieclaims.map((actieclaim) => ({
      ...actieclaim,
    }));
    const actieclaimsGesorteerd = _.orderBy(actieclaimsResult, ['Claimdatum'], ['desc']);

    return actieclaimsGesorteerd;
  }, [props.actieclaims]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () =>
      [
        {
          name: 'Claimdatum',
          title: 'Claimdatum',
        },
        {
          name: 'Wervingscode',
          title: 'Wervingscode',
        },
        {
          name: '__aanbrengendeRelatie' as any,
          title: 'Aanbrenger',
        },
        {
          name: 'VergoedingAanbrengendeRelatie',
          title: 'Vergoed.',
        },
        // props.relID === undefined
        //   ? {
        //       name: '__aangebrachteRelatie' as any,
        //       title: 'Aangebrachte',
        //     }
        //   : null,
        {
          name: '__aangebrachteRelatie' as any,
          title: 'Aangebrachte',
        },
        {
          name: 'VergoedingAangebrachteRelatie',
          title: 'Tegenverg.',
        },
        {
          name: 'Status',
          title: 'Status',
        },
        {
          name: 'StatusAdvies',
          title: 'Advies',
        },
        {
          name: 'VergoedingVerwerkt',
          title: 'Toegew.',
        },
      ].filter((x) => x !== null) as TypedColumn<IRow>[],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Claimdatum',
        width: 100,
      },
      {
        columnName: 'Wervingscode',
        width: 125,
      },
      {
        columnName: '__aanbrengendeRelatie' as any,
        width: 200,
      },
      {
        columnName: 'VergoedingAanbrengendeRelatie',
        width: 75,
      },
      {
        columnName: '__aangebrachteRelatie' as any,
        width: 200,
      },
      {
        columnName: 'VergoedingAangebrachteRelatie',
        width: 90,
      },
      {
        columnName: 'Status',
        width: 125,
      },
      {
        columnName: 'StatusAdvies',
        width: 125,
      },
      {
        columnName: 'VergoedingVerwerkt',
        width: 90,
      },
    ],
    [],
  );

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.klantactie.klantWerftKlant.checkVerwijderenActieclaims({
      IDs: props.actieclaimsSelectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Actieclaims verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.klantactie.klantWerftKlant.VerwijderenActieclaims({
      IDs: props.actieclaimsSelectie,
    });

    props.onRequestRefresh();
  }, [props.actieclaimsSelectie, props.onRequestRefresh]);

  const handleAfwijzen = useCallback(async () => {
    const checkData = await api.v2.klantactie.klantWerftKlant.checkAfwijzenActieclaims({
      ids: props.actieclaimsSelectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Actieclaims afwijzen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.klantactie.klantWerftKlant.afwijzenActieclaims({
      ids: props.actieclaimsSelectie,
    });

    props.onRequestRefresh();
  }, [props.actieclaimsSelectie, props.onRequestRefresh]);

  const handleToewijzen = useCallback(async () => {
    const checkData = await api.v2.klantactie.klantWerftKlant.checkToewijzenActieclaims({
      ids: props.actieclaimsSelectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Actieclaims toewijzen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.klantactie.klantWerftKlant.toewijzenActieclaims({
      ids: props.actieclaimsSelectie,
    });

    props.onRequestRefresh();
  }, [props.actieclaimsSelectie, props.onRequestRefresh]);

  const context = useMemo<IActieclaimTabelContext>(() => {
    return {
      regelsSelectie: props.regelsSelectie,
      onRegelsSelectieChange: props.onRegelsSelectieChange,
      onRequestRefresh: props.onRequestRefresh,
    };
  }, [props.onRequestRefresh, props.regelsSelectie, props.onRegelsSelectieChange]);

  return (
    <>
      <ActieclaimTabelContext.Provider value={context}>
        <div>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              {props.relID !== undefined && (
                <button
                  className="btn btn-sm btn-light d-flex align-items-center mr-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: (
                            <span>
                              De te selecteren relatie wordt de aanbrenger voor deze claim.
                              Doorgaan?
                            </span>
                          ),
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    setRelatieSelecterenDialoogState({ tabblad: ETabblad.Recent });
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Toevoegen claim</span>
                </button>
              )}

              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.actieclaimsSelectie.length === 0}
                onClick={() => handleVerwijderen()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.actieclaimsSelectie.length === 0}
                onClick={() => handleAfwijzen()}
              >
                <IconKruis style={{ width: 16, height: 16, fill: Kleur.Rood }} />
                <span className="ml-2">Afwijzen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.actieclaimsSelectie.length === 0}
                onClick={() => handleToewijzen()}
              >
                <IconVlag style={{ width: 16, height: 16, fill: Kleur.Groen }} />
                <span className="ml-2">Toewijzen</span>
              </button>
            </div>
          </div>

          {rows === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height={'calc(100vh - 150px)'}>
              <Grid rows={rows} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['Claimdatum']}
                  formatterComponent={(props) => (
                    <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                  )}
                />

                <DataTypeProvider
                  for={['__aanbrengendeRelatie']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRow;
                    if (row.AanbrengendeRelatie_RelID === null) {
                      return <span>Niet gevonden</span>;
                    }
                    return <RelatieVisualisatie relID={row.AanbrengendeRelatie_RelID!} />;
                  }}
                />

                <DataTypeProvider
                  for={['__aangebrachteRelatie']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRow;
                    return <RelatieVisualisatie relID={row.AangebrachteRelatie_RelID} />;
                  }}
                />

                <DataTypeProvider
                  for={['VergoedingAanbrengendeRelatie']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRow;
                    return <FormatteerBedrag bedrag={row.VergoedingAanbrengendeRelatie} />;
                  }}
                />
                <DataTypeProvider
                  for={['VergoedingAangebrachteRelatie']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRow;
                    return <FormatteerBedrag bedrag={row.VergoedingAangebrachteRelatie} />;
                  }}
                />

                <DataTypeProvider
                  for={['VergoedingVerwerkt']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRow;
                    const datum =
                      row.VergoedingVerwerkt !== null
                        ? format(new Date(row.VergoedingVerwerkt), 'dd-MM-yyyy')
                        : '';
                    return <span>{datum}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['Status']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRow;

                    if (row.Status === EClaimstatus.Goedgekeurd) {
                      return <span style={{ color: Kleur.Groen }}>Goedgekeurd</span>;
                    }
                    if (row.Status === EClaimstatus.Afgekeurd) {
                      return <span style={{ color: Kleur.Rood }}>Afgekeurd</span>;
                    }
                    return <span style={{ color: Kleur.Blauw }}>Beoordelen</span>;
                  }}
                />

                <DataTypeProvider
                  for={['StatusAdvies']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRow;

                    if (row.Status !== 0) {
                      return <span></span>;
                    }

                    if (row.StatusAdvies === EClaimstatus.Goedgekeurd) {
                      return <span style={{ color: Kleur.Groen }}>Goedkeuren</span>;
                    }
                    if (row.StatusAdvies === EClaimstatus.Afgekeurd) {
                      return <span style={{ color: Kleur.Rood }}>Afkeuren</span>;
                    }
                    return <span style={{ color: Kleur.Blauw }}>Beoordelen</span>;
                  }}
                />

                <SelectionState
                  selection={props.actieclaimsSelectie}
                  onSelectionChange={(value) =>
                    props.onActieclaimsSelectieChange(value as number[])
                  }
                />

                <RowDetailState defaultExpandedRowIds={[]} />

                <VirtualTable />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />

                <EditingState
                  onCommitChanges={(changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1];
                    alert(id);
                  }}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    // props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                    // alert(id);
                  }}
                />

                <TableEditColumn
                  width={35}
                  showEditCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />
                <TableRowDetail
                  toggleCellComponent={DXTableToggleCellComponent}
                  contentComponent={DetailComp}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )}
        </div>
      </ActieclaimTabelContext.Provider>
      {relatieSelecterenDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={async (result) => {
            setRelatieSelecterenDialoogState(null);

            const params = {
              AanbrengendeRelatie_RelID: result.relID,
              AangebrachteRelatie_RelID: props.relID!,
            };

            await api.v2.klantactie.klantWerftKlant.toevoegenActieclaim(params);

            props.onRequestRefresh();
          }}
          onAnnuleren={() => setRelatieSelecterenDialoogState(null)}
          defaultTabblad={relatieSelecterenDialoogState.tabblad}
        />
      )}
    </>
  );
});

export default ActieclaimTabel;
