import React, { useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { ERemoteDataState } from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { EntiteitType } from '../../../../../shared/src/api/v2/zoek';
import Entiteit from './Entiteit';
import { EEntiteit, entiteitenBijKey } from '../shared';
import Masonry from 'react-responsive-masonry';
import { IconKruis } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { AutoSizer } from 'react-virtualized';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Balk = styled.div`
  //height: 50px;
  display: flex;
  //background: red;
  justify-content: flex-end;
  padding: 15px 15px 0;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px;
`;

interface IProps {}

const Rechts = observer((props: IProps) => {
  const { zoekStore } = useContext(RootStoreContext);

  return (
    <Root>
      <Balk>
        <button
          style={{ padding: 0, outline: 'none', margin: 0, background: 0, border: 0 }}
          onClick={() => zoekStore.sluiten()}
        >
          <IconKruis style={{ width: 48, height: 48, fill: Kleur.Grijs }} />
        </button>
      </Balk>
      <ContentContainer>
        {zoekStore.isAanHetZoeken ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : zoekStore.zoekResult.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            Begin met zoeken om resultaten te zien
          </div>
        ) : (
          <AutoSizer className="d-flex flex-fill flex-column w-100 h-100">
            {({ width, height }) => (
              <div style={{ width, height, overflowY: 'auto' }}>
                <Masonry columnsCount={2} gutter="15px">
                  {Object.keys(zoekStore.zoekResult.data!.resultaten).map((key, i, arr) => {
                    const resultaat = zoekStore.zoekResult.data!.resultaten[key as EntiteitType];
                    const entiteit = entiteitenBijKey[key as EEntiteit];

                    const handleAllesWeergeven = resultaat.totaalAantal <= 3 ? undefined : () => {};

                    const EntiteitContent = entiteit.content;

                    return (
                      <div key={key} style={{ width: '100%' }}>
                        <Entiteit
                          naam={entiteit.naam}
                          aantal={resultaat.totaalAantal}
                          onAllesWeergeven={handleAllesWeergeven}
                        >
                          {EntiteitContent !== undefined && <EntiteitContent ids={resultaat.ids} />}
                        </Entiteit>
                      </div>
                    );
                  })}
                </Masonry>
              </div>
            )}
          </AutoSizer>
        )}
      </ContentContainer>
    </Root>
  );
});

export default Rechts;
