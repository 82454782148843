import * as React from 'react';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { ESortering } from '../../types';
import TableHeading from '../TableHeading';

interface IProps {
  naam: string;
  sortering?: ESortering;
  sorteringsnummer?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const NaamTableHeading = (props: IProps) => {
  return (
    <TableHeading
      onClick={props.onClick}
      sortering={props.sortering}
      sorteringsnummer={props.sorteringsnummer}
    >
      <span
        style={{
          color: Kleur.Grijs,
          fontSize: 14,
        }}
      >
        {props.naam}
      </span>
    </TableHeading>
  );
};

export default NaamTableHeading;
