import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { ModalBody, ModalFooter, ModalTitle, Tooltip } from 'react-bootstrap';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import VoorkeurSelectie from './VoorkeurSelectie';
import nameOf from '../../../../../core/nameOf';
import OntvangerssoortSelectie from './OntvangerssoortSelectie';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import InactiefOverlay from '../../../../../components/InactiefOverlay';
import OntvangersselectieSelectie from './OntvangersselectieSelectie';
import Skeleton from 'react-loading-skeleton';
import CodeBewerker from '../../../../../components/formulier/CodeBewerker';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import SjabloonSelectie from './SjabloonSelectie';
import {
  ISjabloon,
  ISjabloonContext,
} from '../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import SjabloonContextVeld from './SjabloonContextVeld';
import api from '../../../../../api';
import { IOntvangerssoort } from '../../../../../../../shared/src/api/v2/bulkbericht/ontvangerssoort';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import {
  ISjabloonContexten,
  ISjabloonContextWaarde,
  IToevoegenBulkberichtParams,
} from '../../../../../../../shared/src/api/v2/bulkbericht';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import CommunicatiekanaalSelectie from './CommunicatiekanaalSelectie';

export interface IDialoogOutput {}

type Ontvangersmodus = 'voor_gedefinieerd' | 'maatwerk';

interface IMaatwerkQuery {
  query: string;
  bewerkt: boolean;
}

interface IFormikValues {
  comKanID: number | null;
  voorkeurID: number | null;
  voorkeurGebruiken: boolean;
  ontvangerssoortID: number | null;
  ontvangersmodus: Ontvangersmodus;
  ontvangersselectieID: number | null;
  maatwerkQuery: IMaatwerkQuery;
  sjabID: number | null;
  sjabloonContexten: ISjabloonContexten;
}

interface IProps extends IDialoogProps<IDialoogOutput> {}

const ToevoegenDialoog = (props: IProps): JSX.Element => {
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IToevoegenBulkberichtParams = {
        comKanID: values.comKanID!,
        voorkeurID: values.voorkeurGebruiken ? values.voorkeurID! : null,
        ontvangerssoortID: values.ontvangerssoortID!,
        ontvangers:
          values.ontvangersmodus === 'voor_gedefinieerd'
            ? {
                modus: 'voor_gedefinieerd',
                ontvangersselectieID: values.ontvangersselectieID!,
              }
            : {
                modus: 'maatwerk',
                query: values.maatwerkQuery.query,
              },
        sjabID: values.sjabID!,
        sjabloonContexten: Object.entries(values.sjabloonContexten).reduce(
          (
            acc,
            [sjabCtxXSjabIDStr, sjabCtxWaarde]: [string, ISjabloonContextWaarde | undefined],
          ) => {
            if (sjabCtxWaarde === undefined) {
              return acc;
            }
            const sjabCtxXSjabID = parseInt(sjabCtxXSjabIDStr, 10);

            acc[sjabCtxXSjabID] = {
              modus: sjabCtxWaarde.modus ?? 'vaste_waarde',
              vasteWaarde: sjabCtxWaarde.vasteWaarde,
              providerID: sjabCtxWaarde.providerID,
            };
            return acc;
          },
          {} as ISjabloonContexten,
        ),
      };

      const checkData = await api.v2.bulkbericht.checkToevoegenBulkbericht(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.bulkbericht.toevoegenBulkbericht(params);

      props.onSuccess({});

      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );
  const initialValues = useMemo<IFormikValues>(
    () => ({
      comKanID: null,
      voorkeurID: null,
      voorkeurGebruiken: true,
      ontvangerssoortID: null,
      ontvangersmodus: 'voor_gedefinieerd',
      ontvangersselectieID: null,
      maatwerkQuery: {
        query: '',
        bewerkt: false,
      },
      sjabID: null,
      sjabloonContexten: {},
    }),
    [],
  );

  const [modalGrootte, setModalGrootte] = useState<'klein' | 'groot'>('klein');

  return (
    <Dialoog
      index={props.dialoogIndex ?? 0}
      modalProps={{ size: modalGrootte === 'klein' ? undefined : 'xl' }}
    >
      <ModalHeader>
        <ModalTitle>Bulkbericht toevoegen</ModalTitle>
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(renderProps) => (
          <Formulier
            props={props}
            formikProps={renderProps}
            modalGrootte={modalGrootte}
            onModalGrootteChange={setModalGrootte}
          />
        )}
      />
    </Dialoog>
  );
};

type Tabblad = 'voor_gedefinieerd' | 'maatwerk';

interface ITabbladProps {
  formikProps: FormikProps<IFormikValues>;
  resetMaatwerkQuery: () => void;
}

interface IFormulierProps {
  props: IProps;
  formikProps: FormikProps<IFormikValues>;
  modalGrootte: 'klein' | 'groot';
  onModalGrootteChange: (modalGrootte: 'klein' | 'groot') => void;
}

const Formulier = (p: IFormulierProps): JSX.Element => {
  const { checkStore } = useContext(RootStoreContext);
  const { props, formikProps } = p;
  const { values, isSubmitting, submitForm } = formikProps;

  const valuesRef = useRef<IFormikValues>(values);
  const setFieldValue = useCallback(
    <TKey extends keyof IFormikValues>(name: TKey, value: IFormikValues[TKey]) => {
      valuesRef.current = {
        ...valuesRef.current,
        [name]: value,
      };
      formikProps.setFieldValue(name, value);
    },
    [formikProps.setFieldValue],
  );

  const tabbladen = useMemo<ITabblad<Tabblad>[]>(
    () => [
      {
        id: 'voor_gedefinieerd',
        label: 'Voor gedefinieerd',
        content: VoorGedefinieerdTabblad,
      },
      {
        id: 'maatwerk',
        label: 'Maatwerk',
        content: MaatwerkTabblad,
      },
    ],
    [],
  );

  useEffect(() => {
    if (values.ontvangersmodus === 'voor_gedefinieerd' && p.modalGrootte === 'groot') {
      p.onModalGrootteChange('klein');
    } else if (values.ontvangersmodus === 'maatwerk' && p.modalGrootte === 'klein') {
      p.onModalGrootteChange('groot');
    }
  }, [values.ontvangersmodus, p.modalGrootte]);

  const ontvangerssoortCacheRef = useRef<{ [ontvangerssoortID: number]: IOntvangerssoort }>({});
  const huidigeOntvangerssoort = useMemo(
    () =>
      values.ontvangerssoortID === null
        ? null
        : ontvangerssoortCacheRef.current[values.ontvangerssoortID] ?? null,
    [values.ontvangerssoortID],
  );

  const tabbladProps = useMemo<ITabbladProps>(() => {
    return {
      formikProps,
      resetMaatwerkQuery: () =>
        setFieldValue(nameOf<IFormikValues>('maatwerkQuery'), {
          query: huidigeOntvangerssoort?.MaatwerkSjabloon ?? '',
          bewerkt: false,
        } as IMaatwerkQuery),
    };
  }, [formikProps, setFieldValue, huidigeOntvangerssoort]);

  useEffect(() => {
    (async () => {
      if (huidigeOntvangerssoort === null) {
        return;
      }
      if (values.maatwerkQuery.bewerkt) {
        if (
          (
            await checkStore.bevestigen({
              titel: 'Maatwerk query overschrijven',
              inhoud:
                'Je hebt de maatwerk query aangepast, de gekozen ontvangers heeft een ander sjabloon gedefinieerd. Wil je deze overnemen?',
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }
      }

      setFieldValue(nameOf<IFormikValues>('maatwerkQuery'), {
        query: huidigeOntvangerssoort.MaatwerkSjabloon ?? '',
        bewerkt: false,
      } as IMaatwerkQuery);
    })();
  }, [huidigeOntvangerssoort?.ID]);

  const renderSelecteerOntvangerssoortTooltip = useCallback((p: any) => {
    return (
      <Tooltip id="selecteer-ontvangerssoort-tooltip" {...p}>
        Je dient eerst de ontvangers te selecteren.
      </Tooltip>
    );
  }, []);

  const sjabloonCacheRef = useRef<{ [sjabID: number]: ISjabloon }>({});
  const huidigSjabloon = useMemo(
    () => (values.sjabID === null ? null : sjabloonCacheRef.current[values.sjabID] ?? null),
    [values.sjabID],
  );
  const huidigeSjabloonSjabCtxIDs = useMemo(
    () => huidigSjabloon?.sjabCtxs.map((x) => x.sjabCtxID) ?? null,
    [huidigSjabloon],
  );
  const [sjabloonContexten, setSjabloonContexten] = useState<
    IRemoteData<{
      [sjabCtxID: number]: ISjabloonContext;
    }>
  >(createPendingRemoteData());

  const bepalenSjabloonContexten = useCallback(async () => {
    if (huidigeSjabloonSjabCtxIDs === null || huidigeSjabloonSjabCtxIDs.length === 0) {
      return;
    }
    setSjabloonContexten(createPendingRemoteData());

    const result = await api.v2.sjabloon.ophalenSjabloonContexten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: huidigeSjabloonSjabCtxIDs,
          },
        ],
      },
    });

    const ctxs = result.contexten.reduce<{
      [sjabCtxID: number]: ISjabloonContext;
    }>(
      (acc, sjabCtx) => {
        acc[sjabCtx.SjabCtxID] = sjabCtx;
        return acc;
      },
      { ...sjabloonContexten },
    );

    setSjabloonContexten(createReadyRemoteData(ctxs));
  }, [JSON.stringify(huidigeSjabloonSjabCtxIDs)]);

  useEffect(() => {
    bepalenSjabloonContexten();
  }, [bepalenSjabloonContexten]);

  useEffect(() => {
    // Controleer of huidige sjabloon contexten in de values
    // contexten bevatten die niet behoren tot het huidige sjabloon.
    // Als deze voorkomen willen we deze verwijderen.
    if (huidigSjabloon === null) {
      setFieldValue(nameOf<IFormikValues>('sjabloonContexten'), {});
      return;
    }

    const teVerwijderenSjabCtxXSjabIDs = Object.keys(valuesRef.current!.sjabloonContexten)
      .map((x) => parseInt(x, 10))
      .filter((sjabCtxXSjabID) => {
        return !huidigSjabloon.sjabCtxs.some((x) => x.ID === sjabCtxXSjabID);
      });
    if (teVerwijderenSjabCtxXSjabIDs.length === 0) {
      return;
    }
    const nieuweSjabloonContexten = { ...valuesRef.current!.sjabloonContexten };
    for (const sjabCtxXSjabID of teVerwijderenSjabCtxXSjabIDs) {
      delete nieuweSjabloonContexten[sjabCtxXSjabID];
    }
    setFieldValue(nameOf<IFormikValues>('sjabloonContexten'), nieuweSjabloonContexten);
  }, [huidigSjabloon]);

  const formulierWaardenGeldig = useMemo<boolean>(() => {
    if (values.comKanID === null) {
      return false;
    }
    if (values.voorkeurID === null && values.voorkeurGebruiken) {
      return false;
    }
    if (values.ontvangersmodus === 'voor_gedefinieerd' && values.ontvangersselectieID === null) {
      return false;
    }
    if (values.ontvangersmodus === 'maatwerk' && values.maatwerkQuery.query.trim().length === 0) {
      return false;
    }
    if (huidigSjabloon === null || values.ontvangerssoortID === null) {
      return false;
    }

    // console.log('sjabCtxs', huidigSjabloon.sjabCtxs);
    // console.log(
    //   'filtered',
    //   huidigSjabloon.sjabCtxs.filter((x) => !x.isOptioneel),
    // );
    // console.log('values.sjabloonContexten', values.sjabloonContexten);

    return huidigSjabloon.sjabCtxs
      .filter((x) => !x.isOptioneel)
      .every((x) => {
        const sjabloonContext = values.sjabloonContexten[x.ID];
        if (sjabloonContext === undefined) {
          return false;
        }
        if (
          sjabloonContext.modus === 'vaste_waarde' &&
          (sjabloonContext.vasteWaarde === null || sjabloonContext.vasteWaarde === undefined)
        ) {
          return false;
        }
        if (
          sjabloonContext.modus === 'provider' &&
          (sjabloonContext.providerID === null || sjabloonContext.providerID === undefined)
        ) {
          return false;
        }
        return true;
      });
  }, [
    values.ontvangersmodus,
    values.ontvangerssoortID,
    values.voorkeurID,
    values.ontvangersselectieID,
    values.maatwerkQuery,
    huidigSjabloon?.ID,
    values.sjabloonContexten,
    values.sjabID,
  ]);

  return (
    <>
      <ModalBody className="p-0" style={{ maxHeight: 850, overflowY: 'auto' }}>
        <div className="p-3">
          <label>Communicatiekanaal</label>
          <Field
            name={nameOf<IFormikValues>('comKanID')}
            render={({ field, form }: FieldProps<IFormikValues>) => (
              <CommunicatiekanaalSelectie
                comKanID={field.value}
                onComKanIDChange={(comKanID) => {
                  setFieldValue(field.name as keyof IFormikValues, comKanID);
                  setFieldValue(nameOf<IFormikValues>('ontvangerssoortID'), null);
                  setFieldValue(nameOf<IFormikValues>('sjabID'), null);
                }}
              />
            )}
          />
        </div>
        <div className="pl-3 pr-3 pb-3 pt-1">
          <label>Voorkeur</label>
          <div className="d-flex align-items-center">
            <Field
              name={nameOf<IFormikValues>('voorkeurGebruiken')}
              render={({ field }: FieldProps<IFormikValues>) => (
                <VinkVeld
                  aangevinkt={field.value}
                  onGewijzigd={(x) => setFieldValue(field.name as keyof IFormikValues, x)}
                  className="mr-2"
                />
              )}
            />
            <Field
              name={nameOf<IFormikValues>('voorkeurID')}
              render={({ field, form }: FieldProps<IFormikValues>) => (
                <VoorkeurSelectie
                  voorkeurID={field.value}
                  onVoorkeurIDChange={(voorkeurID) =>
                    setFieldValue(field.name as keyof IFormikValues, voorkeurID)
                  }
                  disabled={!form.values.voorkeurGebruiken}
                />
              )}
            />
          </div>
          <div className="mt-2 text-muted">
            Door het uitschakelen van de voorkeur zal er geen gebruik worden gemaakt van de
            voorkeursinstellingen die ingesteld zijn voor de desbetreffende ontvanger.
          </div>
          <label className="mt-3">Ontvangers</label>
          <Field
            name={nameOf<IFormikValues>('ontvangerssoortID')}
            render={({ field }: FieldProps<IFormikValues>) => {
              if (values.comKanID === null) {
                return <Skeleton height={30} />;
              }

              return (
                <OntvangerssoortSelectie
                  ontvangerssoortID={field.value}
                  onOntvangerssoortIDChange={(ontvangerssoortID) =>
                    setFieldValue(field.name as keyof IFormikValues, ontvangerssoortID)
                  }
                  cache={ontvangerssoortCacheRef.current}
                  comKanID={values.comKanID}
                />
              );
            }}
          />
        </div>
        <Field
          name={nameOf<IFormikValues>('ontvangersmodus')}
          render={({ field }: FieldProps<IFormikValues>) => (
            <InactiefOverlay
              isInactief={values.ontvangerssoortID === null}
              inactiefTooltip={renderSelecteerOntvangerssoortTooltip}
              element={
                <Tabblad
                  geselecteerd={field.value}
                  onSelectieChange={(x) => setFieldValue(field.name as keyof IFormikValues, x)}
                  tabbladen={tabbladen}
                  options={{
                    tabbladComponentProps: tabbladProps,
                  }}
                />
              }
            />
          )}
        />
        <div className="p-3">
          <label>Sjabloon</label>
          <Field
            name={nameOf<IFormikValues>('sjabID')}
            render={({ field, form }: FieldProps<IFormikValues>) => {
              if (form.values.comKanID === null) {
                return <Skeleton height={30} />;
              }

              return (
                <SjabloonSelectie
                  sjabID={field.value}
                  onSjabIDChange={(x) => setFieldValue(field.name as keyof IFormikValues, x)}
                  cache={sjabloonCacheRef.current}
                  comKanID={form.values.comKanID}
                />
              );
            }}
          />

          {huidigSjabloon !== null &&
            values.ontvangerssoortID !== null &&
            huidigSjabloon.sjabCtxs.length > 0 && (
              <div className="mt-3">
                <label>Contexten</label>
                <div className="d-flex flex-column" style={{ rowGap: 10 }}>
                  {huidigSjabloon.sjabCtxs.map((sjabCtx) => {
                    const sjabloonContextWaarde = values.sjabloonContexten[sjabCtx.ID] ?? null;
                    const sjabloonContext =
                      sjabloonContexten.state === ERemoteDataState.Pending
                        ? null
                        : sjabloonContexten.data![sjabCtx.sjabCtxID] ?? null;

                    return (
                      <SjabloonContextVeld
                        ontvangerssoortID={values.ontvangerssoortID!}
                        sjabCtx={sjabCtx}
                        vasteWaarde={sjabloonContextWaarde?.vasteWaarde ?? null}
                        onVasteWaardeChange={(x) => {
                          setFieldValue(nameOf<IFormikValues>('sjabloonContexten'), {
                            ...valuesRef.current!.sjabloonContexten,
                            [sjabCtx.ID]: {
                              ...sjabloonContextWaarde,
                              vasteWaarde: x,
                            } as ISjabloonContextWaarde,
                          });
                        }}
                        modus={sjabloonContextWaarde?.modus ?? 'vaste_waarde'}
                        onModusChange={(modus) => {
                          setFieldValue(nameOf<IFormikValues>('sjabloonContexten'), {
                            ...valuesRef.current!.sjabloonContexten,
                            [sjabCtx.ID]: {
                              ...sjabloonContextWaarde,
                              providerID: sjabloonContextWaarde?.providerID ?? null,
                              vasteWaarde: sjabloonContextWaarde?.vasteWaarde ?? null,
                              modus,
                            } as ISjabloonContextWaarde,
                          });
                        }}
                        providerID={sjabloonContextWaarde?.providerID ?? null}
                        onProviderIDChange={(x) => {
                          setFieldValue(nameOf<IFormikValues>('sjabloonContexten'), {
                            ...valuesRef.current!.sjabloonContexten,
                            [sjabCtx.ID]: {
                              ...sjabloonContextWaarde,
                              providerID: x,
                            } as ISjabloonContextWaarde,
                          });
                        }}
                        sjabloonContext={sjabloonContext}
                      />
                    );
                  })}
                </div>
              </div>
            )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !formulierWaardenGeldig}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

const VoorGedefinieerdTabblad = (props: ITabbladProps) => {
  const { values, setFieldValue } = props.formikProps;

  if (values.ontvangerssoortID === null) {
    return <Skeleton height={50} />;
  }

  return (
    <div className="p-3">
      <OntvangersselectieSelectie
        ontvangerssoortID={values.ontvangerssoortID!}
        ontvangersselectieID={values.ontvangersselectieID}
        onOntvangersselectieIDChange={(x) =>
          setFieldValue(nameOf<IFormikValues>('ontvangersselectieID'), x)
        }
      />

      <div className="mt-3 text-muted">
        Je kunt kiezen uit een lijst met voor gedefinieerde mogelijkheden om de juiste ontvangers te
        bepalen.
      </div>
    </div>
  );
};

const MaatwerkTabblad = (props: ITabbladProps) => {
  const { values, setFieldValue } = props.formikProps;

  return (
    <div className="p-3 d-flex flex-column" style={{ height: 320 }}>
      <CodeBewerker
        code={values.maatwerkQuery.query}
        onCodeChange={(x) =>
          setFieldValue(nameOf<IFormikValues>('maatwerkQuery'), {
            query: x,
            bewerkt: true,
          } as IMaatwerkQuery)
        }
        language="sql"
      />
      {/*<div className="mt-3 text-muted">*/}
      {/*  Stel zelf een SQL-query samen om binnen volledig op maatwerk ontvangers te selecteren.*/}
      {/*</div>*/}
      {/*<div className="mt-1 text-muted">*/}
      {/*  Het is hierbij belangrijk dat de query de juiste kolom selecteert voor het soort ontvanger.*/}
      {/*</div>*/}
      {/*<div className="mt-1" style={{ color: Kleur.Rood }}>*/}
      {/*  <div>Let op! Ga zorgvuldig te werk met deze functionaliteit.</div>*/}
      {/*  <div>*/}
      {/*    Plak niet zomaar een query in dit veld als niet zeker bent van de werking. Dit kan grote*/}
      {/*    gevolgen met zich meebrengen.*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="mt-2 d-flex align-items-center">
        <button
          className="btn btn-sm btn-light d-flex align-items-center"
          style={{ border: `1px solid ${Kleur.Grijs}` }}
          onClick={() => props.resetMaatwerkQuery()}
        >
          <span className="ml-2">SQL-query resetten</span>
        </button>
      </div>
    </div>
  );
};

export default ToevoegenDialoog;
