import {
  IOphalenSelectieNieuwParams,
  IKoppelenProductParams,
  IKoppelenProductResult,
  OphalenSelectieNieuwResult,
  IVerwijderenProductKoppelingParams,
  IVerwijderenProductKoppelingResult,
  CheckVerwijderenProductReserveringParams,
  IOphalenSelectieGebruiktParams,
  OphalenSelectieGebruiktResult,
  IReserverenProductenParams,
  IReserverenProductenResult,
} from '../../../../../../shared/src/api/v2/transport/reservering/voorraad';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const levering = {
  ophalenSelectieNieuw: async (
    params: IOphalenSelectieNieuwParams,
  ): Promise<OphalenSelectieNieuwResult> => {
    return await api.post('v2/transport/reservering/levering/ophalen-selectie-nieuw', params);
  },

  koppelenProduct: async (params: IKoppelenProductParams): Promise<IKoppelenProductResult> => {
    return await api.post('v2/transport/reservering/levering/koppelen-product', params);
  },
  verwijderenProductKoppeling: async (
    params: IVerwijderenProductKoppelingParams,
  ): Promise<IVerwijderenProductKoppelingResult> => {
    return await api.post(
      'v2/transport/reservering/levering/verwijderen-product-koppeling',
      params,
    );
  },
  checkVerwijderenProductReservering: async (
    params: CheckVerwijderenProductReserveringParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/reservering/levering/check-verwijderen-productreservering',
      params,
    );
  },
  verwijderenProductReservering: async (
    params: IVerwijderenProductKoppelingParams,
  ): Promise<IVerwijderenProductKoppelingResult> => {
    return await api.post(
      'v2/transport/reservering/levering/verwijderen-productreservering',
      params,
    );
  },
  ophalenSelectieGebruikt: async (
    params: IOphalenSelectieGebruiktParams,
  ): Promise<OphalenSelectieGebruiktResult> => {
    return await api.post('v2/transport/reservering/levering/ophalen-selectie-gebruikt', params);
  },
  checkReserverenProducten: async (params: IReserverenProductenParams): Promise<ICheckData> => {
    return await api.post('v2/transport/reservering/levering/check-reserveren-producten', params);
  },
  reserverenProducten: async (
    params: IReserverenProductenParams,
  ): Promise<IReserverenProductenResult> => {
    return await api.post('v2/transport/reservering/levering/reserveren-producten', params);
  },
};

export default levering;
