import React from 'react';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import UitlegTooltip from '../../formulier/UitlegTooltip';
import { IconService } from '../../Icons';

interface IProps {
  status: EMeldingstatus;
  // naam: string;
}

interface IPropsIndicatie {
  status: EMeldingstatus;
}

export enum EMeldingstatus {
  Groen = 'GROEN',
  Blauw = 'BLAUW',
}

const kleurMap: { [status: string]: string } = {
  [EMeldingstatus.Groen]: EKleur.Groen,
  [EMeldingstatus.Blauw]: EKleur.Blauw,
};

const uitlegMap: Record<EMeldingstatus, string> = {
  [EMeldingstatus.Groen]: 'Afgehandeld',
  [EMeldingstatus.Blauw]: 'Lopend',
};

const MeldingstatusIndicatie: React.FC<IProps> = (props) => {
  return (
    <UitlegTooltip inhoud={uitlegMap[props.status]}>
      <div className="d-flex align-items-center">
        {/* <div
          style={{
            width: 14,
            height: 14,
            borderRadius: '50%',
            backgroundColor: kleurMap[props.status],
          }}
        /> */}
        {/* <span className="ml-2">{props.naam}</span> */}
        <IconService style={{ width: 12, height: 12, fill: kleurMap[props.status] }} />
      </div>
    </UitlegTooltip>
  );
};

export default MeldingstatusIndicatie;
