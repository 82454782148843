import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IContentProps } from '../../shared';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import NavigerenPijl from '../../Rechts/NavigerenPijl';
import api from '../../../../api';
import {
  IOphalenBestandenResult,
  IOphalenBestandsmappenResult,
  IOphalenBestandsmappenResultElement,
} from '../../../../../../shared/src/api/v2/bestand/bestand';
import { getIconFile } from '../../../../components/Icons';
import {
  IOphalenMediaTypesV2Result,
  IOphalenMediaTypesV2ResultElement,
} from '../../../../../../shared/src/api/v2/mediatype';

const BestandContent = (props: IContentProps) => {
  const [mediatypenResult, setMediatypenResult] = useState<IRemoteData<IOphalenMediaTypesV2Result>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    api.v2.mediaType
      .ophalenMediaTypes({})
      .then((result) => setMediatypenResult(createReadyRemoteData(result)));
  }, []);

  const mediatypenMap = useMemo<Record<number, IOphalenMediaTypesV2ResultElement> | null>(() => {
    if (mediatypenResult.state === ERemoteDataState.Pending) {
      return null;
    }
    return mediatypenResult.data!.mediaTypes.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ID]: curr,
      };
    }, {});
  }, [mediatypenResult]);

  const [bestandenResult, setBestandenResult] = useState<IRemoteData<IOphalenBestandenResult>>(
    createPendingRemoteData(),
  );
  const ophalenBestanden = useCallback(async () => {
    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: props.ids,
          },
        ],
      },
    });
    setBestandenResult(createReadyRemoteData(result));
  }, [props.ids]);
  useEffect(() => {
    ophalenBestanden();
  }, [ophalenBestanden]);

  const [bestandsmappenResult, setBestandsmappenResult] = useState<
    IRemoteData<IOphalenBestandsmappenResult>
  >(createPendingRemoteData());
  const ophalenBestandsmappen = useCallback(async () => {
    if (bestandenResult.state === ERemoteDataState.Pending) {
      return;
    }
    const bovenliggendeBestandsmappenResult = await api.v2.bestand.ophalenBovenliggendeBestandsmappenVoorBestanden(
      {
        bestandIDs: bestandenResult.data!.bestanden.map((bestand) => bestand.ID),
      },
    );
    const bestandsmappenResult = await api.v2.bestand.ophalenBestandsmappen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bovenliggendeBestandsmappenResult.bestandsmapIDs,
          },
        ],
      },
    });
    setBestandsmappenResult(createReadyRemoteData(bestandsmappenResult));
  }, [bestandenResult]);
  useEffect(() => {
    ophalenBestandsmappen();
  }, [ophalenBestandsmappen]);

  const bestandsmappenMap = useMemo<
    IRemoteData<{ [bestandsmapID: number]: IOphalenBestandsmappenResultElement }>
  >(
    () =>
      bestandsmappenResult.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            bestandsmappenResult.data!.mappen.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.ID]: curr,
              }),
              {},
            ),
          ),
    [bestandsmappenResult],
  );

  const handleNavigeren = useCallback(() => {}, []);

  return (
    <div style={{ paddingLeft: 35, paddingRight: 20 }} className="d-flex flex-column">
      {bestandenResult.state === ERemoteDataState.Pending ||
      mediatypenResult.state === ERemoteDataState.Pending ||
      bestandsmappenResult.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        bestandenResult.data!.bestanden.map((bestand, i) => {
          const mediaType = mediatypenMap![bestand.MediaTypeID];
          const Svg = getIconFile(mediaType.MediaType);

          const recursiefPadBepalen = (
            bestandsmapID: number | null,
            curr?: number[],
          ): number[] | null => {
            if (bestandsmapID === null) {
              return curr ?? [];
            }
            const bestandsmap = bestandsmappenMap.data![bestandsmapID] ?? null;
            if (bestandsmap === null) {
              return null;
            }
            const boom = recursiefPadBepalen(bestandsmap.Bovenliggende_BestandsmapID);
            if (boom === null) {
              return null;
            }
            return [...boom, bestandsmapID];
          };
          const padArr: number[] | null = recursiefPadBepalen(bestand.BestandsmapID);
          const pad =
            padArr === null
              ? null
              : padArr.map((id) => bestandsmappenMap.data![id]!.Naam).join('/');

          return (
            <div key={bestand.ID} className="p-2 d-flex align-items-center">
              <Svg style={{ width: 24, height: 24 }} />
              <div className="flex-fill ml-3 d-flex flex-column">
                <span>{bestand.Naam}</span>
                <span className="text-muted">/{pad}</span>
              </div>
              <NavigerenPijl onClick={handleNavigeren} />
            </div>
          );
        })
      )}
    </div>
  );
};

export default BestandContent;
