import styled from 'styled-components';
import { Kleur } from '../../bedrijfslogica/constanten';

export const Root = styled.div`
  width: 100%;
  background-color: ${Kleur.LichterGrijs};
  border: 1px solid ${Kleur.LichtGrijs};
  display: flex;
`;

export const ActionArea = styled.div`
  display: flex;
  border-right: 1px solid ${Kleur.LichtGrijs};
`;

export const FiltersArea = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
