import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { IconBellen, IconEmail, IconTaal } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IProps {}

const Placeholder = (props: IProps) => {
  return (
    <Root>
      <div className="d-flex align-items-center">
        <div style={{ width: 30 }}>
          <Skeleton height={30} />
        </div>
        <div className="flex-fill" style={{ marginLeft: 10 }}>
          <Skeleton width="90%" height={25} />
        </div>
        <div style={{ width: 50, marginLeft: 10 }}>
          <Skeleton />
        </div>
      </div>

      <div style={{ marginTop: 5 }}>
        <Skeleton width="70%" />
      </div>

      <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
        <IconEmail style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
        <div className="flex-fill" style={{ marginLeft: 10 }}>
          <Skeleton width="50%" />
        </div>
      </div>

      <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
        <IconBellen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
        <div className="flex-fill" style={{ marginLeft: 10 }}>
          <Skeleton width="35%" />
        </div>
      </div>

      <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
        <IconTaal style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
        <div className="flex-fill" style={{ marginLeft: 10 }}>
          <Skeleton width="5%" />
        </div>
      </div>

      <div style={{ borderTop: `1px solid ${Kleur.LichtGrijs}`, marginTop: 8, marginBottom: 8 }} />

      <div style={{ marginBottom: 5 }}>
        <Skeleton height={250} />
      </div>
    </Root>
  );
};

export default Placeholder;
