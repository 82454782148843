import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import GegevensLayout from '../../../../layout/GegevensLayout';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../MutatieBedrag';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IProps extends RouteComponentProps {
  opdracht: IOphalenOpdrachtenResultElement;
}

const Data = (props: IProps) => {
  return (
    <Root>
      <h4 className="mb-2">{props.opdracht.Betalingskenmerk}</h4>
      <GegevensLayout
        labelBreedte={110}
        gegevens={[
          {
            label: 'Uitvoerdatum',
            waarde:
              props.opdracht.Uitvoerdatum === null
                ? '- -'
                : format(new Date(props.opdracht.Uitvoerdatum), 'dd-MM-yyyy'),
          },
          {
            label: 'Bedrag',
            waarde: <FormatteerBedrag bedrag={props.opdracht.Bedrag} />,
          },
        ]}
      />
    </Root>
  );
};

export default withRouter(Data);
