import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import api from '../../../../../../api';
import nameof from '../../../../../../core/nameOf';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import VeldWeergave from '../../../../../../components/formulier/VeldWeergave';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';
import Combobox from '../../../../../../components/formulier/Combobox';

interface IProps extends IDialoogProps<null> {
  bankOpdID: number;
}

interface IFormikValues {
  omschrijving: string;
  wijzigingIBAN: number | null;
}

const veldnamen = {
  omschrijving: 'Omschrijving',
  wijzigingIBAN: 'Is de wijziging IBAN bij zelfde of andere bank?',
};

const WijzigOpdrachtDialoog = observer((props: IProps) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;

  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  const ophalenOpdracht = useCallback(async () => {
    const opdrachtenResult = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.bankOpdID] }] },
    });
    const opdracht = opdrachtenResult.opdrachten[0];
    setOpdracht(opdracht);
  }, [props.bankOpdID]);

  useEffect(() => {
    ophalenOpdracht();
  }, [ophalenOpdracht]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (opdracht === null) {
      return null;
    }

    return {
      omschrijving: opdracht.Omschrijving !== null ? opdracht.Omschrijving : '',
      wijzigingIBAN: opdracht.WijzigingIBAN,
    };
  }, [opdracht]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        bankOpdID: props.bankOpdID,
        bedrag: opdracht!.Bedrag,
        uitvoerdatum: opdracht!.Uitvoerdatum,
        bankRekID: opdracht!.BankRekID,
        omschrijving: values.omschrijving !== '' ? values.omschrijving : null,
        betalingskenmerk: opdracht!.Betalingskenmerk,
        vrijgeven: opdracht!.Vrijgeven,
        stornoberichtVersturen: opdracht!.StornoberichtVersturen,
        wijzigingIBAN: values.wijzigingIBAN,
        mandaatdatum: opdracht!.MandaatDatum,
      };

      // const checkData = await api.v2.bank.opdracht.checkWijzigenOpdracht(params);
      // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      // const resultaat = await checkStore.bevestigen({
      //   titel: `Wijzigingen vastleggen?`,
      // });
      // if (resultaat.type === EResultType.Annuleren) {
      //   return;
      // }

      const result = await api.v2.bank.opdracht.wijzigenOpdracht(params);

      onSuccess(null);

      actions.setSubmitting(false);
    },
    [onSuccess, opdracht, props.bankOpdID],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>
          Wijzigen{' '}
          {opdracht === null ? '' : opdracht?.Bedrag > 0 ? 'Incasso-opdracht' : 'Betaalopdracht'}
        </ModalTitle>
      </ModalHeader>

      {initieleWaarden === null || opdracht === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <Formik<IFormikValues>
            isInitialValid
            initialValues={initieleWaarden}
            //   validate={handleValidate}
            onSubmit={handleSubmit}
            render={(FormikProps) => {
              const { submitForm, isSubmitting, values, errors } = FormikProps;
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      {opdracht.OudeIBAN !== null && (
                        <>
                          <div className="col-12">
                            {/* <label></label> */}
                            <VeldWeergave>
                              <GegevensLayout
                                gegevens={[
                                  {
                                    label: 'IBAN in opdracht',
                                    waarde: `${opdracht.IBAN}`,
                                  },
                                  {
                                    label: 'Voorgaande IBAN',
                                    waarde: `${opdracht.OudeIBAN || ''}`,
                                  },
                                ]}
                              />
                            </VeldWeergave>
                          </div>

                          <div className="col-12 mt-3">
                            <label>{veldnamen.wijzigingIBAN}</label>
                            <Field
                              name={nameof<IFormikValues>('wijzigingIBAN')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                return (
                                  <>
                                    <Combobox
                                      geselecteerd={field.value}
                                      opties={[
                                        { id: 0, label: 'Onbekend' },
                                        { id: 1, label: 'Zelfde bank' },
                                        { id: 2, label: 'Andere bank' },
                                      ]}
                                      onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                      disabled={opdracht.OudeIBAN === null}
                                      options={{ legeOptieTekst: 'Niet van toepassing' }}
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>
                        </>
                      )}

                      <div className="col-12 mt-3 mb-2">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameof<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={3} />;
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default WijzigOpdrachtDialoog;
