import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IContext, IPendelregels, ITabbladProps, ProductContext } from '../index';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import VeldWeergave from '../../../formulier/VeldWeergave';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState } from '@devexpress/dx-react-grid';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import api from '../../../../api';
import { IOphalenDocumentenResultElement } from '../../../../../../shared/src/api/v2/product/documentatie';
import nameOf from '../../../../core/nameOf';
import MediaWeergaveDialoog from '../../../dialogen/MediaWeergaveDialoog';
import { mediaTypeNaarMediaWeergaveType } from '../../../../components/MediaWeergave';

interface IProps extends ITabbladProps {}

interface IMediaweergaveDialoogState {
  id: number;
}

const DocumentatieTab: React.FC<IProps> = (props) => {
  const { product } = useContext<IContext>(ProductContext);

  const [documenten, setDocumenten] = useState<IOphalenDocumentenResultElement[] | null>(null);

  const [
    mediaweergaveDialoogState,
    setMediaweergaveDialoogState,
  ] = useState<IMediaweergaveDialoogState | null>(null);

  // const mediaWeergaven = documenten!.map((x) => ({
  //   id: x.ID,
  //   title: x.Naam,
  //   src: x.bestand!.url,
  //   type: mediaTypeNaarMediaWeergaveType(x.bestand!.MediaType),
  // }));

  useEffect(() => {
    (async () => {
      if (product === null) {
        return;
      }

      // Haal de beschikbare documenten op voor het producttype
      const documenten = (
        await api.v2.product.documentatie.ophalenDocumenten({
          // filterSchema: { filters: [{ naam: 'TYPE_IDS', data: [product.producttype.TypeID] }] },
          filterSchema: {
            filters: [{ naam: 'HEEFT_GEKOPPELD_DOCUMENT', data: true }],
            uitgebreideFilter: {
              or: [
                {
                  filter: {
                    naam: 'TYPE_IDS',
                    data: [product.producttype.TypeID],
                  },
                },
                // {
                //   filter: {
                //     naam: 'ALGEMENE_DOCUMENTATIE',
                //     data: true,
                //   },
                // },
              ],
            },
          },
        })
      ).documenten;

      setDocumenten(documenten);
    })();
  }, [product]);

  const keyExtractor = useCallback((row: IOphalenDocumentenResultElement) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenDocumentenResultElement>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Document',
      },
      {
        name: '__talen' as any,
        title: 'Talen',
      },
      {
        name: '__documentsoort' as any,
        title: 'Soort',
      },
      {
        name: '__mediaweergave' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenDocumentenResultElement>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 275,
      },
      {
        columnName: '__talen' as any,
        width: 190,
      },
      {
        columnName: '__documentsoort' as any,
        width: 175,
      },
      {
        columnName: '__mediaweergave' as any,
        width: 75,
      },
    ],
    [],
  );

  if (documenten === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-column flex-fill">
        <div className="mt-2  pt-2 mb-4">
          <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
            <VeldWeergave>
              <div className="p-1 font-weight-bold">Beschikbare documentatie</div>
              <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
                <GridStyleWrapper rowAmount={documenten.length} maxHeight={400}>
                  <Grid rows={documenten} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={[nameOf('__documentsoort')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenDocumentenResultElement = formatterProps.row;

                        return <span>{rij.documentsoort.Naam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf('__talen')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenDocumentenResultElement = formatterProps.row;
                        const talen = rij.talen.map((x) => x.Naam).join(',');
                        return <span>{talen}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__mediaweergave']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenDocumentenResultElement = formatterProps.row;
                        return (
                          <span className="d-flex align-items-center">
                            <a
                              href="#"
                              onClick={async () => {
                                setMediaweergaveDialoogState({ id: rij.ID });
                              }}
                            >
                              Inzien
                            </a>
                          </span>
                        );
                      }}
                    />

                    <VirtualTable
                      messages={{
                        noData: 'Geen documenten gevonden',
                      }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </VeldWeergave>
          </div>
        </div>
      </div>
      {mediaweergaveDialoogState !== null && (
        <MediaWeergaveDialoog
          current={mediaweergaveDialoogState.id}
          onCurrentChange={(id) => setMediaweergaveDialoogState({ id: id as number })}
          mediaWeergaven={documenten!.map((x) => {
            return {
              id: x.ID,
              title: x.Naam,
              src: x.bestand!.url,
              type: mediaTypeNaarMediaWeergaveType(x.bestand!.MediaType)!,
              mediaType: x.bestand!.MediaType,
            };
          })}
          open
          onSuccess={() => setMediaweergaveDialoogState(null)}
          onAnnuleren={() => setMediaweergaveDialoogState(null)}
        />
      )}
    </>
  );
};

export default DocumentatieTab;
