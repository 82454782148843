import api from '../../index';
import retouren from './retouren';
import voorraad from './voorraad';
import reviseren from './reviseren';
import afvoer from './afvoer';
import retourLeverancier from './retourLeverancier';
import uitBedrijf from './uitBedrijf';
import {
  IWijzigenProductstatusParams,
  IWijzigenProductstatusResult,
  IParkerenProductenParams,
  IParkerenProductenResult,
  IHerstellenParkerenProductenParams,
  IHerstellenParkerenProductenResult,
  IMarkerenUitBedrijfParams,
  IMarkerenUitBedrijfResult,
  IZoekMeldenProductenParams,
  IZoekMeldenProductenResult,
  IGevondenMeldenProductenParams,
  IGevondenMeldenProductenResult,
  IMarkerenInBedrijfParams,
  IMarkerenInBedrijfResult,
} from '../../../../../shared/src/api/v2/retourverwerking';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const retourverwerking = {
  retouren,
  voorraad,
  reviseren,
  afvoer,
  retourLeverancier,
  uitbedrijf: uitBedrijf,

  wijzigenProductstatus: async (
    params: IWijzigenProductstatusParams,
  ): Promise<IWijzigenProductstatusResult> => {
    return await api.post('v2/retourverwerking/wijzigen-productstatus', params);
  },
  parkerenProducten: async (
    params: IParkerenProductenParams,
  ): Promise<IParkerenProductenResult> => {
    return await api.post('v2/retourverwerking/parkeren-producten', params);
  },
  checkMarkerenUitBedrijf: async (params: IMarkerenUitBedrijfParams): Promise<ICheckData> => {
    return await api.post('v2/retourverwerking/uit-bedrijf/check-markeren-uit-bedrijf', params);
  },
  markerenUitBedrijf: async (
    params: IMarkerenUitBedrijfParams,
  ): Promise<IMarkerenUitBedrijfResult> => {
    return await api.post('v2/retourverwerking/uit-bedrijf/markeren-uit-bedrijf', params);
  },
  checkMarkerenInBedrijf: async (params: IMarkerenInBedrijfParams): Promise<ICheckData> => {
    return await api.post('v2/retourverwerking/uit-bedrijf/check-markeren-in-bedrijf', params);
  },
  markerenInBedrijf: async (
    params: IMarkerenInBedrijfParams,
  ): Promise<IMarkerenInBedrijfResult> => {
    return await api.post('v2/retourverwerking/uit-bedrijf/markeren-in-bedrijf', params);
  },
  checkZoekMeldenProducten: async (params: IZoekMeldenProductenParams): Promise<ICheckData> => {
    return await api.post('v2/retourverwerking/check-zoekmelden-producten', params);
  },
  zoekMeldenProducten: async (
    params: IZoekMeldenProductenParams,
  ): Promise<IZoekMeldenProductenResult> => {
    return await api.post('v2/retourverwerking/zoekmelden-producten', params);
  },
  gevondenMeldenProducten: async (
    params: IGevondenMeldenProductenParams,
  ): Promise<IGevondenMeldenProductenResult> => {
    return await api.post('v2/retourverwerking/gevondenmelden-producten', params);
  },
};

export default retourverwerking;
