import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ITabelProps } from '../index';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../shared/src/api/v2/relatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import { formatteerRelatieNaam } from '../../../../../../helpers';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';
import InactiefOverlay from '../../../../../../components/InactiefOverlay';

interface IData {
  items: Record<number, IOphalenRelatiesResultElementV2>;
  totaalAantal: number;
}

type Kolom = 'naam' | 'geblokkeerd' | 'niet_gebruiken' | 'overleden' | 'genereer_voorbeeld';

const RelatiesTabel = (props: ITabelProps): JSX.Element => {
  // tslint:disable-next-line:variable-name
  const [data, _setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef<IRemoteData<IData>>(data);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    _setData(data);
  }, []);
  const [isBezigMetInitieelOphalen, setIsBezigMetInitieelOphalen] = useState(false);

  const ophalenData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden = false) => {
      if (!uitbreiden) {
        setIsBezigMetInitieelOphalen(true);
      }

      const voorkeurFilter = props.filterData.find((x) => x.naam === 'voorkeur')!;

      const result = await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'SELECTIE_QUERY',
              data: props.selectieQuery,
            },
            voorkeurFilter.isActief
              ? ({
                  naam: 'VOLDOET_AAN_VOORKEUR_IDS',
                  data: [props.voorkeurID!],
                  inverteren: voorkeurFilter.data === 'voldoet_niet_aan_voorkeur',
                } as IFilterSchemaFilter)
              : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        paginatie,
      });

      const items = result.relaties.reduce<Record<number, IOphalenRelatiesResultElementV2>>(
        (acc, relatie, i) => {
          acc[paginatie.index + i] = relatie;
          return acc;
        },
        (uitbreiden ? { ...dataRef.current?.data?.items } : {}) ?? {},
      );

      setData(createReadyRemoteData({ items, totaalAantal: result.totaalAantal }));

      if (!uitbreiden) {
        setIsBezigMetInitieelOphalen(false);
      }
    },
    [props.selectieQuery, props.filterData, props.voorkeurID],
  );

  useEffect(() => {
    ophalenData({ index: 0, aantal: 50 });
  }, [ophalenData]);

  const keyExtractor = useCallback((relatie: IOphalenRelatiesResultElementV2) => relatie.RelID, []);
  const kolommen = useMemo<ASPKolom<Kolom, IOphalenRelatiesResultElementV2>[]>(
    () => [
      {
        key: 'naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Naam',
        renderer: (relatie) => (
          <RelatieVisualisatie relID={relatie.RelID} weergaveNaam={relatie.weergavenaam} />
        ),
      },
      {
        key: 'geblokkeerd',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Geblokkeerd',
        renderer: (relatie) => (relatie.Geblokkeerd ? 'Ja' : 'Nee'),
      },
      {
        key: 'niet_gebruiken',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 140,
        label: 'Niet gebruiken',
        renderer: (relatie) => (relatie.NietGebruiken ? 'Ja' : 'Nee'),
      },
      {
        key: 'overleden',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        label: 'Overleden',
        renderer: (relatie) =>
          relatie.persoon === null ? '- -' : relatie.persoon?.Overleden ? 'Ja' : 'Nee',
      },
      {
        key: 'genereer_voorbeeld',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (relatie) => {
          return (
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              Genereer voorbeeld
            </a>
          );
        },
      },
    ],
    [],
  );

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      await ophalenData(positie, true);
    },
    [ophalenData],
  );

  return (
    <InactiefOverlay
      isInactief={isBezigMetInitieelOphalen}
      element={
        <ASPTabel
          rijen={data.data?.items ?? {}}
          totaalAantalRijen={data.data?.totaalAantal}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
          statusbalkWeergeven
        />
      }
      rootStyle={{ flex: 1 }}
      rootClassName="d-flex"
    />
  );
};

export default RelatiesTabel;
