import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IUseInkomendeOfRinkelendeOproepenOutput } from '../../../../helpers/communicatie/telefoon/useInkomendeOfRinkelendeOproepen';
import PersoonVisualisatie from '../../../../components/personalia/PersoonVisualisatie';
import OrganisatieVisualisatie from '../../../../components/personalia/OrganisatieVisualisatie';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import styled from 'styled-components';
import EnkeleOproep from './EnkeleOproep';
import { IconBellen, IconContactTelefoon } from '../../../../components/Icons';
import { ETelefooneventStatus } from '../../../../bedrijfslogica/enums';

const Root = styled.div`
  position: relative;
  overflow: hidden;

  .content {
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .anim {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1px;
    height: 1px;
    margin-bottom: -1px;
    background: rgba(255, 255, 255, 0.3);
    animation: anim 3s infinite ease-in-out;
    border-radius: 50%;
  }

  @keyframes anim {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(225);
    }
    100% {
      transform: scale(1);
    }
  }

  .pulse i {
    color: #fff;
  }

  .pulse {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1px;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pulse::before {
    content: '';
    position: absolute;
    border: 5px solid rgba(155, 155, 155, 0.7);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    animation: pulse 1s linear infinite;
  }

  .pulse::after {
    content: '';
    position: absolute;
    border: 5px solid rgba(155, 155, 155, 0.7);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    animation: pulse 1s linear infinite;
    animation-delay: 0.3s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
`;

interface IProps {
  output: IUseInkomendeOfRinkelendeOproepenOutput;
}

const TelefoongesprekRinkelend: React.FC<IProps> = observer((props) => {
  let content;

  if (props.output.oproepen.length === 1) {
    const oproep = props.output.oproepen[0];

    content = <EnkeleOproep oproep={oproep} />;
  } else {
    content = <span>{props.output.oproepen.length} inkomende gesprekken</span>;
  }

  const toonPulseAnimatie = useMemo(
    () =>
      props.output.oproepen.some((x) =>
        x.events.some((e) => e.Status === ETelefooneventStatus.Rinkelt),
      ),
    [props.output.oproepen],
  );

  return (
    <Root
      className="d-flex flex-fill align-items-center justify-content-center p-3 w-100"
      style={{
        maxWidth: 205,
        overflow: 'hidden',
      }}
    >
      {toonPulseAnimatie && <div className="pulse" />}
      <div className="content">{content}</div>
    </Root>
  );
});

export default TelefoongesprekRinkelend;
