import React, { useContext, useMemo } from 'react';
import { IContext, ITabbladProps, ProductContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { differenceInMonths, format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import nameOf from '../../../../core/nameOf';
import VerkoopvoorstellenComp, { IRegel } from '../../../verkoopvoorstel/VerkoopvoorstellenComp';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import FormatteerBedrag from '../../../MutatieBedrag';
import _ from 'lodash';
import DownloadKnop from '../../../DownloadKnop';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { IOphalenRegisterItemsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/activaregister/register';

interface IProps extends ITabbladProps {}

export const berekenBoekwaarde = (
  product: IOphalenProductenResultElementV2,
  registeritem: IOphalenRegisterItemsResultElement,
) => {
  const leeftijdInMaanden = differenceInMonths(new Date(), new Date(registeritem.DatumGeactiveerd));

  const boekwaardeResult =
    product.Inkoopprijs !== null
      ? ((60 - leeftijdInMaanden) / 60) * registeritem.Aanschafwaarde
      : null;

  const boekwaarde =
    boekwaardeResult !== null ? (boekwaardeResult < 0 ? 0 : boekwaardeResult) : null;
  return boekwaarde;
};

const DetailTab: React.FC<IProps> = (props) => {
  const {
    product,
    verkoopprijs,
    verkoopvoorstellen,
    inkoopfactuur,
    mutatiesReferentiecode,
    registeritem,
    servicemeldingen,
    serviceopdrachten,
  } = useContext<IContext>(ProductContext);

  // Boekwaarde berekenen
  const boekwaarde =
    product !== null && registeritem !== null && registeritem !== undefined
      ? berekenBoekwaarde(product!, registeritem)
      : null;

  if (
    product === null ||
    verkoopvoorstellen === null ||
    mutatiesReferentiecode === null ||
    registeritem === undefined ||
    servicemeldingen === null ||
    serviceopdrachten === null
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="row">
          <div className="col-12 d-flex mt-3">
            <div className="col-6">
              {/* <label></label> */}
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte="160px"
                  gegevens={[
                    {
                      label: 'Merk/type',
                      waarde: (
                        <span>
                          <div>
                            {product.producttype.Merknaam} {product.producttype.Typenaam}
                          </div>
                        </span>
                      ),
                    },
                    {
                      label: 'Leverancier',
                      waarde:
                        product.inkoopopdracht !== null
                          ? product.inkoopopdracht.dienst.relatie!.weergavenaam
                          : null,
                    },
                    {
                      label: 'Fact.datum',
                      waarde:
                        inkoopfactuur !== null
                          ? format(new Date(inkoopfactuur.Factuurdatum), 'dd-MM-yyyy')
                          : null,
                    },
                    {
                      label: 'Fact.nr.',
                      waarde: inkoopfactuur !== null ? inkoopfactuur.Factuurnummer : null,
                    },
                    // {
                    //   label: 'Ink.prijs hierin (excl. btw)',
                    //   waarde:
                    //     product.InkoopprijsFactuur !== null ? (
                    //       <FormatteerBedrag bedrag={product.InkoopprijsFactuur} />
                    //     ) : null,
                    // },
                    {
                      label: 'Ink.prijs (excl. btw)',
                      waarde:
                        inkoopfactuur !== null ? (
                          <FormatteerBedrag bedrag={inkoopfactuur.BedragInFactuur} />
                        ) : product.inkoopopdracht !== null &&
                          product.inkoopopdracht.InkoopPrijs !== null ? (
                          <FormatteerBedrag bedrag={product.inkoopopdracht.InkoopPrijs} />
                        ) : (
                          <span></span>
                        ),
                    },
                    {
                      label: 'Download factuur',
                      waarde: (
                        <DownloadKnop
                          onDownloadAangevraagd={async () => {
                            const bestand =
                              inkoopfactuur!.bestanden.length === 1
                                ? inkoopfactuur!.bestanden[0]
                                : null;

                            if (bestand === null) {
                              return;
                            }

                            const downloadLink = bestand.url;
                            window.open(downloadLink, '_blank');
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="col-6">
              {/* <label>Totaal te betalen</label> */}
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte="170px"
                  gegevens={[
                    {
                      label: 'In bedrijf',
                      waarde: `${format(new Date(product.DatumInBedrijf), 'dd-MM-yyyy')}`,
                    },
                    {
                      label: 'Uit bedrijf',
                      waarde: `${
                        product.DatumUitBedrijf !== null
                          ? format(new Date(product.DatumUitBedrijf), 'dd-MM-yyyy')
                          : ''
                      }`,
                    },
                    product.DatumUitBedrijf !== null
                      ? {
                          label: 'Reden',
                          waarde: `${product.productstatus.NaamEnum === 'UITBEDRIJF' ? '' : ''}`,
                        }
                      : null,
                    {
                      label: 'Actuele boekw. (excl. btw)',
                      waarde:
                        boekwaarde !== null ? (
                          <span>
                            {' '}
                            <FormatteerBedrag bedrag={boekwaarde} />
                          </span>
                        ) : (
                          <span>?</span>
                        ),
                    },
                    {
                      label: 'Geactiveerd',
                      waarde: (
                        <span>
                          {registeritem !== null ? (
                            <span className="d-flex flex-row ">
                              {format(new Date(registeritem.DatumGeactiveerd), 'dd-MM-yyyy')}
                              {' ('}
                              <FormatteerBedrag bedrag={registeritem.Aanschafwaarde} />
                              {')'}
                            </span>
                          ) : (
                            'Niet geactiveerd'
                          )}
                        </span>
                      ),
                    },
                    {
                      label: 'Gedeactiveerd',
                      waarde: (
                        <span>
                          {registeritem !== null && registeritem.DatumGedeactiveerd !== null
                            ? format(new Date(registeritem.DatumGedeactiveerd), 'dd-MM-yyyy')
                            : ''}
                        </span>
                      ),
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="col-6">
            <div className="mt-2 pl-3 pt-2 mb-3">
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte="160px"
                  gegevens={[
                    {
                      label: 'Verkoopprijs (incl. btw)',
                      waarde:
                        verkoopprijs !== null ? (
                          <FormatteerBedrag bedrag={verkoopprijs} />
                        ) : (
                          'Kan niet bepaald worden'
                        ),
                    },
                    {
                      label: 'Leeftijd',
                      waarde: `${
                        product.Nieuw
                          ? 'Nieuw'
                          : product.leeftijd !== null
                          ? maandenNaarJaren(product.leeftijd)
                          : '?'
                      }`,
                    },
                    {
                      label: 'Aantal serv.meldingen',
                      waarde: servicemeldingen.length.toString(),
                    },
                    {
                      label: 'Gemaakte kosten',
                      waarde: (
                        <FormatteerBedrag
                          bedrag={_.sum(
                            _.flatten(
                              serviceopdrachten.map((x) => x.werkbonnen.map((x) => x.KostenTotaal)),
                            ),
                          )}
                        />
                      ),
                    },
                  ]}
                />
                <span className="d-flex align-items-center">
                  <a
                    href={`https://www.google.com/search?q=${product.producttype.Merknaam}+${product.producttype.Typenaam}`}
                    target="_blank"
                  >
                    Zoek product op het internet
                  </a>
                </span>
              </VeldWeergave>
            </div>
          </div>

          <div className="col-6">
            <div className="mt-2 pr-3 pt-2 mb-3">
              <VeldWeergave>
                <GegevensLayout
                  labelBreedte="160px"
                  gegevens={[
                    {
                      label: 'Inkoopopdracht',
                      waarde:
                        product.inkoopopdracht !== null ? product.inkoopopdracht.Referentie : null,
                    },
                    {
                      label: 'Opdrachtdatum',
                      waarde:
                        product.inkoopopdracht !== null &&
                        product.inkoopopdracht.DatumOpdracht !== null
                          ? format(new Date(product.inkoopopdracht.DatumOpdracht), 'dd-MM-yyyy')
                          : null,
                    },
                    {
                      label: 'Levermagazijn',
                      waarde:
                        product.inkoopopdracht !== null
                          ? product.inkoopopdracht.magazijn.NaamKort
                          : null,
                    },
                    {
                      label: 'Ink.prijs hierin (excl. btw)',
                      waarde:
                        product.inkoopopdracht !== null &&
                        product.inkoopopdracht.InkoopPrijs !== null ? (
                          <FormatteerBedrag bedrag={product.inkoopopdracht.InkoopPrijs} />
                        ) : (
                          <span>Onbekend</span>
                        ),
                    },
                    null,
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="col-12">
            {' '}
            <div className="pl-3 pr-3 pt-2 mb-4">
              <VerkoopvoorstellenComp verkoopvoorstellen={verkoopvoorstellen} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailTab;
