import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import useUrlTabblad from '../../../core/useUrlTabblad';
import BanksaldiV2 from './BanksaldiV2';

interface IProps extends RouteComponentProps {}

export enum ETabblad {
  Banksaldi = 1,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Banksaldi]: '/banksaldi',
};

const Controle: React.FC<IProps> = observer((props) => {
  const { tabblad, onChange: onChangeTabblad } = useUrlTabblad<ETabblad>(
    props,
    pathMap,
    ETabblad.Banksaldi,
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Banksaldi,
        label: 'Banksaldi',
        content: BanksaldiV2,
      },
    ],
    [],
  );

  return (
    <Tabblad
      geselecteerd={tabblad}
      onSelectieChange={(tabblad) => onChangeTabblad(tabblad)}
      tabbladen={tabbladen}
    />
  );
});

export default withRouter(Controle);
