import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../../api';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Combobox from '../../../../../components/formulier/Combobox';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import * as Yup from 'yup';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import {
  IOphalenBtwSoortenResultElement,
  IOphalenBtwTarievenResultElement,
} from '../../../../../../../shared/src/api/v2/btw';
import { dagDatum } from '../../../../../helpers/datum';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import { EBtwsoort } from '../../../../../bedrijfslogica/enums';
import { IOphalenGrootboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import nameof from '../../../../../core/nameOf';
import { IOphalenWerkkostenRegelingenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';

interface IProps extends IDialoogProps<null> {
  id: number;
}

export interface IFormikValues {
  nummer: number;
  naam: string;
  omschrijving: string | null;
  btwSrtID: number | null;
  wkrRglID: number | null;
  soort: 'A' | 'B' | 'P' | 'L';
  isActief: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  nummer: 'Nummer',
  naam: 'Naam',
  omschrijving: 'Omschrijving',
  btwSrtID: 'BtwSoort',
  wkrRglID: 'Werkkostenregeling',
  soort: 'Soort',
  isActief: 'Is actief',
};

const WijzigenDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [
    grootboekrekening,
    setGrootboekrekening,
  ] = useState<IOphalenGrootboekenResultElement | null>(null);

  const [btwsoorten, setBtwsoorten] = useState<IOphalenBtwSoortenResultElement[] | null>(null);

  useEffect(() => {
    (async () => {
      const result = (
        await api.v2.btw.ophalenBtwSoorten({
          // filterSchema: {
          //   filters: [{ naam: 'NAAM_ENUMS', data: [EBtwsoort.Geen], inverteren: true }],
          // },
        })
      ).btwSoorten;

      setBtwsoorten(result);
    })();
  }, []);

  const [werkkostenregelingen, setWerkkostenregelingen] = useState<
    IOphalenWerkkostenRegelingenResultElement[] | null
  >(null);
  const ophalenWerkkostenRegelingen = useCallback(async () => {
    const result = await api.v2.boeking.ophalenWerkkostenRegelingen({});
    setWerkkostenregelingen(result.regelingen);
  }, []);
  useEffect(() => {
    ophalenWerkkostenRegelingen();
  }, [ophalenWerkkostenRegelingen]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.boekhouding.boeking.grootboek.ophalenGrootboeken({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.id] }],
        },
      });

      setGrootboekrekening(result.grootboeken[0]);
    })();
  }, [props.id]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (grootboekrekening === null) {
        return;
      }

      actions.setSubmitting(true);
      const params = {
        id: props.id,
        nummer: values.nummer,
        naam: values.naam,
        omschrijving: values.omschrijving,
        soort: values.soort,
        btwSrtID: values.btwSrtID,
        wkrRglID: values.wkrRglID,
        isActief: values.isActief,
      };

      const checkData = await api.v2.boekhouding.boeking.grootboek.checkWijzigenGrootboek(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Grootboek wijzigen?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.boekhouding.boeking.grootboek.wijzigenGrootboek(params);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [grootboekrekening],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (grootboekrekening === null) {
      return null;
    }

    return {
      nummer: grootboekrekening.Nummer,
      naam: grootboekrekening.Naam,
      omschrijving: grootboekrekening.Omschrijving,
      btwSrtID: grootboekrekening.BtwSrtID,
      wkrRglID:
        grootboekrekening.werkkostenRegeling == null
          ? null
          : grootboekrekening.werkkostenRegeling.ID,
      soort: grootboekrekening.Soort,
      isActief: grootboekrekening.IsActief,
    };
  }, [grootboekrekening]);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      nummer: Yup.number().required(),
      naam: Yup.string().required,
    };

    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen Grootboekrekening</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(formikProps: FormikProps<IFormikValues | null>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps) => {
                            return (
                              <>
                                <input {...fieldProps.field} type="text" className="form-control" />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.nummer}</label>
                        <Field
                          name={nameof<IFormikValues>('nummer')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <input {...field} className="form-control" type="number" min={0} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameof<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={3} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.soort}</label>
                        <Field
                          name={nameof<IFormikValues>('soort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                // opties={btwsoorten.map((btwsoort) => {
                                //   return {
                                //     id: btwsoort.ID,
                                //     label: btwsoort.Naam,
                                //   };
                                // })}
                                opties={[
                                  { id: 'A', label: 'Activa' },
                                  { id: 'B', label: 'Baten' },
                                  { id: 'P', label: 'Passiva' },
                                  { id: 'L', label: 'Lasten' },
                                ]}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.btwSrtID}</label>
                        <Field
                          name={nameof<IFormikValues>('btwSrtID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (btwsoorten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={btwsoorten.map((btwsoort) => {
                                  return {
                                    id: btwsoort.ID,
                                    label: btwsoort.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                legeOptieTonen
                                options={{ legeOptieTekst: 'Geen btw-soort' }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.wkrRglID}</label>
                        <Field
                          name={nameof<IFormikValues>('wkrRglID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return werkkostenregelingen === null ? (
                              <LoadingSpinner />
                            ) : (
                              <Combobox
                                geselecteerd={field.value}
                                opties={werkkostenregelingen.map((regeling) => {
                                  return {
                                    id: regeling.ID,
                                    label: regeling.Naam,
                                  };
                                })}
                                legeOptieTonen={true}
                                isWisbaar={true}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('isActief')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.isActief}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default WijzigenDialoog;
