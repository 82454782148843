import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IconBellen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import api from '../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import classNames from 'classnames';
import { setCommunicatieOverlayState } from '../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../one-off-components/CommunicatieOverlay/MenuHandle';
import KopieerNaarKlembord from '../../KopieerNaarKlembord';

interface IOptions {
  icoonWeergeven?: boolean;
  copyToClipboard?: boolean;
}

const defaultOptions: IOptions = {
  icoonWeergeven: true,
  copyToClipboard: true,
};

interface IProps {
  telefoonNummer: string;
  orgID?: number;
  persID?: number;
  options?: IOptions;
  contentComponent?: React.ComponentType<React.HTMLProps<HTMLSpanElement>>;
}

const TelefoonComponent: React.FC<IProps> = observer((props) => {
  const options = useMemo<IOptions>(() => {
    return { ...defaultOptions, ...props.options };
  }, [props.options]);
  const { checkStore, gebruikerStore } = useContext(RootStoreContext);
  const { children, telefoonNummer, orgID, persID } = props;
  const [hovered, setHoverd] = useState(false);

  const handleClick = useCallback(async () => {
    setCommunicatieOverlayState!((prevState) => ({
      ...prevState,
      telefoonContext: {
        ...prevState.telefoonContext,
        telefoonnummer: telefoonNummer,
      },
      geselecteerdTabblad: ECommunicatieTabblad.Telefoon,
    }));
    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: `Bevestigen bellen naar ${telefoonNummer}`,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }
    // const result = await api.v2.telefoon.verzoekOproep({
    //   orgID,
    //   persID,
    //   telefoonNummer,
    //   voipAccID: gebruikerStore.aspGebruikersprofiel!.VoipAccID!,
    //   weergevenNummer: null,
    // });
    // result.oproep.
  }, [telefoonNummer, orgID, persID, gebruikerStore.aspGebruikersprofiel]);

  const ContentComponent = props.contentComponent ?? 'span';

  const content = (
    <>
      {options.icoonWeergeven && (
        <IconBellen
          style={{ width: 15, height: 15, fill: Kleur.LichtBlauw, position: 'relative', top: 2 }}
        />
      )}
      <ContentComponent
        className={classNames([options.icoonWeergeven ? 'ml-2' : null])}
        // style={{ position: 'relative', top: 1 }}
      >
        {telefoonNummer}
      </ContentComponent>
    </>
  );

  // if (
  //   gebruikerStore.aspGebruikersprofiel === null ||
  //   gebruikerStore.aspGebruikersprofiel.VoipAccID === null
  // ) {
  //   return <span>{content}</span>;
  // }

  return (
    <span
      className="d-flex align-items-center"
      onMouseEnter={() => {
        setHoverd(true);
      }}
      onMouseLeave={() => {
        setHoverd(false);
      }}
    >
      <a href="#" className="d-flex align-items-center" onClick={handleClick}>
        {content}
      </a>
      {options.copyToClipboard && hovered && (
        <span className="ml-2">
          <KopieerNaarKlembord waarde={telefoonNummer} />
        </span>
      )}
    </span>
  );
});

export default TelefoonComponent;
