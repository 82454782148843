import api from '../../index';
import {
  IMakenVoorstelBerichtenParams,
  IMakenVoorstelBerichtenResult,
  IOngedaanMakenVerwerkenIndexeringParams,
  IOphalenBerichtenParams,
  IOphalenBerichtenResult,
  IOphalenIndexeringenParams,
  IOphalenIndexeringenResult,
  IToevoegenIndexeringParams,
  IToevoegenIndexeringResult,
  IVersturenIndexeringsberichtenParams,
  IVersturenIndexeringsberichtenResult,
  IVerwerkenIndexeringParams,
  IVerwerkenIndexeringResult,
  IVerwijderenIndexeringenParams,
  IVerwijderenIndexeringenResult,
  IVerwijderenBerichtenParams,
  IVerwijderenBerichtenResult,
  IMakenIndexeringsberichtenParams,
  IMakenIndexeringsberichtenResult,
  ISelecterenContractenVoorIndexeringParams,
  ISelecterenContractenVoorIndexeringResult,
  IOngedaanMakenVerwerkenIndexeringResult,
} from '../../../../../shared/src/api/v2/indexering';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const indexering = {
  ophalenIndexeringen: async (
    params: IOphalenIndexeringenParams,
  ): Promise<IOphalenIndexeringenResult> => {
    return await api.post('/v2/indexering/ophalen-indexeringen', params);
  },
  selecterenContractenVoorIndexering: async (
    params: ISelecterenContractenVoorIndexeringParams,
  ): Promise<ISelecterenContractenVoorIndexeringResult> => {
    return await api.post('/v2/indexering/selecteren-contracten-voor-indexering', params);
  },
  checkToevoegenIndexering: async (params: IToevoegenIndexeringParams): Promise<ICheckData> => {
    return await api.post('/v2/indexering/check-toevoegen-indexering', params);
  },
  toevoegenIndexering: async (
    params: IToevoegenIndexeringParams,
  ): Promise<IToevoegenIndexeringResult> => {
    return await api.post('/v2/indexering/toevoegen-indexering', params);
  },
  checkVerwerkenIndexering: async (params: IVerwerkenIndexeringParams): Promise<ICheckData> => {
    return await api.post('/v2/indexering/check-verwerken-indexering', params);
  },
  verwerkenIndexering: async (
    params: IVerwerkenIndexeringParams,
  ): Promise<IVerwerkenIndexeringResult> => {
    return await api.post('/v2/indexering/verwerken-indexering', params);
  },
  checkOngedaanMakenVerwerkenIndexering: async (
    params: IOngedaanMakenVerwerkenIndexeringParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/indexering/check-ongedaan-maken-verwerken-indexering', params);
  },
  ongedaanMakenVerwerkenIndexering: async (
    params: IOngedaanMakenVerwerkenIndexeringParams,
  ): Promise<IOngedaanMakenVerwerkenIndexeringResult> => {
    return await api.post('/v2/indexering/ongedaan-maken-verwerken-indexering', params);
  },
  checkVerwijderenIndexeringen: async (
    params: IVerwijderenIndexeringenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/indexering/check-verwijderen-indexeringen', params);
  },
  verwijderenIndexeringen: async (
    params: IVerwijderenIndexeringenParams,
  ): Promise<IVerwijderenIndexeringenResult> => {
    return await api.post('/v2/indexering/verwijderen-indexeringen', params);
  },
  checkMakenIndexeringsberichten: async (
    params: IMakenIndexeringsberichtenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/indexering/check-maken-indexeringsberichten', params);
  },
  makenIndexeringsberichten: async (
    params: IMakenIndexeringsberichtenParams,
  ): Promise<IMakenIndexeringsberichtenResult> => {
    return await api.post('/v2/indexering/maken-indexeringsberichten', params);
  },
};

export default indexering;
