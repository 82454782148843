import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import ContactSelectie, {
  EType,
} from '../../../../../../../../components/formulier/ContactSelectie';
import { IOphalenRedenenAfkeuringResultElement } from '../../../../../../../../../../shared/src/api/v2/contract/afkeuring';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import _ from 'lodash';
import { EKanaal } from '../../../../../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<{}> {
  relID: number;
  prodIDs: number[];
}

interface IFormikValues {
  reden: number;
  bevestigingSturen: boolean;
  persID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  reden: 'Reden',
  bevestigingSturen: 'Bevestiging sturen',
  persID: 'Persoon',
};

const NieuwVerkoopvoorstelDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, relID, prodIDs } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Verkoopvoorstellen maken?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.product.verkoop.makenVerkoopVoorstellen({
        relID,
        persID: values.bevestigingSturen ? values.persID : null,
        prodIDs,
        voorsteldatum: null,
      });

      if (values.bevestigingSturen) {
        const params = {
          relID,
          persID: values.persID,
          kanalen: [EKanaal.Email],
        };

        await api.v2.product.verkoop.versturenVerkoopVoorstellen(params);
      }

      actions.setSubmitting(false);
      onSuccess({});
    },
    [onSuccess, relID, prodIDs, setHasSubmitted, checkStore],
  );

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      reden: 1,
      persID: null,
      bevestigingSturen: false,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        bevestigingSturen: Yup.boolean(),
        persID: Yup.number()
          .nullable(true)
          .when('bevestigingSturen', {
            is: true,
            then: Yup.number().required(),
          }),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Verkoopvoorstellen maken</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        isInitialValid
        ref={formikRef}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    {/* <div className="col-12 mt-3 d-flex align-items-center">
                      <Field
                        name="bevestigingSturen"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <VinkVeld
                              aangevinkt={fieldProps.field.value}
                              onGewijzigd={(x) =>
                                fieldProps.form.setFieldValue(fieldProps.field.name, x)
                              }
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                      <label className="ml-2 mt-1">{veldnamen.bevestigingSturen}</label>
                    </div> */}

                    {values.bevestigingSturen && (
                      <div className="col-12 mt-2">
                        <label>{veldnamen.persID}</label>
                        <Field
                          name="persID"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <ContactSelectie
                                  alleenVoorRelIDs={[relID]}
                                  value={
                                    field.value === null
                                      ? null
                                      : {
                                          persID: field.value,
                                          orgID: null,
                                        }
                                  }
                                  onChange={(x) =>
                                    form.setFieldValue(field.name, x?.persoon?.PersID ?? null)
                                  }
                                  options={{
                                    types: [EType.Persoon],
                                  }}
                                />
                                <FormikVeldFout
                                  fieldProps={fieldProps}
                                  directTonen={hasSubmitted}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  // disabled={isSubmitting}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default NieuwVerkoopvoorstelDialoog;
