import React from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconInklappen, IconUitklappen } from '../../Icons';

interface IProps<TId> {
  secties: ISectie<TId>[];
  uitgeklaptId: TId | null;
  onUitgeklaptIdChange: (id: TId | null) => void;
}

export interface ISectie<TId> {
  id: TId;
  label: string;
}

const TegelUitklapMenu = <TId extends any>(props: IProps<TId>) => {
  const { secties } = props;

  return (
    <div>
      {secties.map((sectie, i) => {
        const isUitgeklapt = props.uitgeklaptId === sectie.id;
        const Icon = isUitgeklapt ? IconInklappen : IconUitklappen;

        return (
          <a
            key={i}
            href="#"
            style={{ color: Kleur.LichtBlauw, textDecoration: 'none' }}
            className={i === 0 ? '' : 'ml-3'}
            onClick={(ev) => {
              ev.stopPropagation();
              props.onUitgeklaptIdChange(isUitgeklapt ? null : sectie.id);
            }}
          >
            {sectie.label}
            <Icon style={{ width: 17, height: 17, fill: Kleur.LichtBlauw }} />
          </a>
        );
      })}
    </div>
  );
};

export default TegelUitklapMenu;
