import * as React from 'react';
import { IOphalenCommunicatieItemsResultElement } from '../../../../../../../../../shared/src/api/v2/communicatie';
import { ICommunicatieItemEmailData } from '../../EmailV2';
import { useMemo, useRef } from 'react';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';

interface IProps {
  item: IOphalenCommunicatieItemsResultElement;
  data: ICommunicatieItemEmailData;
}

const EmailRowDetail = (props: IProps) => {
  const {
    Onderwerp,
    IsHTML,
    Inhoud,
    InhoudPreview,
    Bron_BestandID,
    EmailMapNaamEnum,
    WebURL,
  } = props.data;
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const inhoud = useMemo(() => {
    const tekst = Inhoud === null ? null : IsHTML ? Inhoud : Inhoud.replace(/\n/g, '<br/>');
    if (tekst === null) {
      return null;
    }
    return `
        <head>
            <base target="_blank" />
        </head>
        <style>
        body {
            font-family: "Lato", sans-serif;
            font-size: 14px;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            color: ${Kleur.DonkerGrijs};
            padding: 5px 20px;
            margin: 0;
        }
        </style>
        <link href="https://fonts.googleapis.com/css?family=Lato:400,700&amp;display=swap" rel="stylesheet">
        ${tekst}
    `;
  }, [Inhoud, IsHTML]);

  return (
    <div className="d-flex flex-fill flex-column">
      {inhoud === null ? (
        <div className="p-2 pl-3 pr-3">
          <span>De inhoud van deze email is niet beschikbaar.</span>
        </div>
      ) : (
        <iframe
          ref={iframeRef}
          onLoad={() => {
            iframeRef.current!.style.minHeight =
              Math.min(iframeRef.current!.contentWindow!.document.body.scrollHeight, 450) + 'px';
          }}
          srcDoc={inhoud}
          style={{
            outline: 0,
            border: 0,
            width: '100%',
            margin: 0,
            flex: 1,
            background: '#fff',
          }}
          frameBorder={0}
          // scrolling="no"
        />
      )}
    </div>
  );
};

export default EmailRowDetail;
