import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { ISectieProps } from '../index';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedColumnExtension,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState, TableRowDetail } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { IOphalenAanmaningenResultElement } from '../../../../../../shared/src/api/v2/aanmaning';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import * as _ from 'lodash';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import { IconInformatie } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import FactuurinfoDialoog from '../../../../components/factuur/FactuurinfoDialoog';

interface IRow {
  factID: number;
  factuurnummer: string;
  factuurdatum: Date;
  vervaldatum: Date;
  bedrag: number;
  kostenAanmaning: number;
  aantalKeerAangemaand: number;
}

interface IProps extends TableRowDetail.ContentProps {}
export interface IFactuurinfoDialoogState {
  factID: number;
}

const FacturenDetailComp: React.FC<IProps> = (props) => {
  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  // Aanmaning
  const aanmaning = useMemo(() => {
    return props.row as IOphalenAanmaningenResultElement;
  }, [props.row]);

  // Facturen in aanmaning
  const rows = useMemo<IRow[]>(() => {
    const facturen = aanmaning.facturen.map(
      (x): IRow => ({
        factID: x.FactID,
        factuurnummer: x.Factuurnummer,
        factuurdatum: new Date(x.Factuurdatum),
        vervaldatum: new Date(x.Vervaldatum),
        bedrag: x.BedragInAanmaning,
        kostenAanmaning: x.KostenAanmaning,
        aantalKeerAangemaand: x.aantalKeerAangemaand,
      }),
    );

    const facturenGesorteerd: IRow[] = _.orderBy(
      facturen,
      [(x: any) => format(x.factuurdatum, 'yyyy-MM-dd')],
      ['asc'],
    );

    return facturenGesorteerd;
  }, [aanmaning.facturen]);

  const keyExtractor = useCallback((row: IRow) => row.factID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(() => {
    return [
      {
        name: 'factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'bedrag',
        title: 'Bedrag',
      },
      {
        name: 'kostenAanmaning',
        title: 'Kosten',
      },
      {
        name: 'aantalKeerAangemaand',
        title: 'Keer aangemaand',
      },
      {
        name: '__factuurInfo' as any,
        title: 'Info',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(() => {
    return [
      {
        columnName: 'factuurnummer',
        width: 100,
      },
      {
        columnName: 'factuurdatum',
        width: 100,
      },
      {
        columnName: 'vervaldatum',
        width: 100,
      },
      {
        columnName: 'bedrag',
        width: 100,
      },
      {
        columnName: 'kostenAanmaning',
        width: 100,
      },
      {
        columnName: 'bedrag',
        width: 100,
      },
      {
        columnName: 'aantalKeerAangemaand',
        width: 135,
      },
      {
        columnName: '__factuurInfo' as any,
        width: 80,
      },
    ];
  }, []);

  const kolomExtensies: TypedColumnExtension<IRow>[] = useMemo(() => {
    return [
      {
        columnName: 'bedrag',
        align: 'right',
      },
      {
        columnName: 'kostenAanmaning',
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      {rows === null ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex">
          <TabelInspringBlok />
          <GridStyleWrapper maxHeight={500} rowAmount={rows.length}>
            <Grid getRowId={keyExtractor} rows={rows} columns={kolommen}>
              <DataTypeProvider
                for={['factuurdatum']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;

                  return <span>{format(row.factuurdatum, 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['vervaldatum']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;

                  return <span>{format(row.vervaldatum, 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['bedrag']}
                formatterComponent={(props) => {
                  return (
                    <span>
                      <FormatteerBedrag bedrag={props.value} rechtsUitlijnen />
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['kostenAanmaning']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;
                  return (
                    <span>
                      <FormatteerBedrag bedrag={row.kostenAanmaning} rechtsUitlijnen />
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__factuurInfo']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;
                  return (
                    <a
                      href="#"
                      className="ml-1"
                      style={{ color: Kleur.LichtBlauw, position: 'relative', bottom: 2 }}
                      onClick={() => {
                        setFactuurinfoDialoogState({ factID: row.factID });
                      }}
                    >
                      <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                    </a>
                  );
                }}
              />

              <VirtualTable columnExtensions={kolomExtensies} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </div>
      )}

      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        ></FactuurinfoDialoog>
      )}
    </>
  );
};

export default FacturenDetailComp;
