import { RouteComponentProps } from 'react-router';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useUrlState, { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { matchPath, Redirect, Route } from 'react-router-dom';
import { Root } from './style';
import Retouren, { retourenMagazijnKolommen } from './Retouren';
import Voorraad, { voorraadMagazijnKolommen } from './Voorraad';
import Revisie, { revisieMagazijnKolommen } from './Revisie';
import Afvoeren, { afvoerMagazijnKolommen } from './Afvoer';
import RetourLeverancier, { retourLeverancierMagazijnKolommen } from './RetourLeverancier';
import Geparkeerd, { geparkeerdMagazijnKolommen } from './Geparkeerd';
import Zoek, { zoekgemeldMagazijnKolommen } from './Zoekgemeld';
import UitBedrijf, { uitBedrijfMagazijnKolommen } from './UitBedrijf';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MultiCombobox, { IKolom } from '../../../components/formulier/MultiCombobox';
import HorizontaleScheidingslijn from '../../../components/layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import ProductSelectieDialoog, {
  IProductSelectieDialoogResult,
} from '../../../components/product/ProductSelectieDialoog';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import ProductinfoDialoog from '../../../components/product/ProductinfoDialoog';
import WijzigenProductDialoog from './WijzigenProductDialoog';
import { IconImportExport, IconPijlOmhoog } from '../../../components/Icons';
import downloadUrl from '../../../core/downloadUrl';
import { MagazijnKolom } from '../../../../../shared/src/api/v2/magazijn/export';
import { basisMagazijnKolommen } from '../../../components/magazijn/ProductenTabel';
import ToevoegenProductDialoog from './ToevoegenProductDialoog';

export enum ETabblad {
  Retouren = 1,
  Voorraad = 2,
  Revisie = 3,
  Afvoer = 4,
  RetourLeverancier = 5,
  Geparkeerd = 6,
  Zoek = 7,
  UitBedrijf = 8,
}

export interface IProductSelecterenDialoogState {
  prodID: number;
}

interface IUrlState {
  magID: number | null;
  productSelecterenDialoogState: IProductSelecterenDialoogState | null;
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Retouren]: 'retouren',
  [ETabblad.Voorraad]: 'voorraad',
  [ETabblad.Revisie]: 'revisie',
  [ETabblad.Afvoer]: 'afvoer',
  [ETabblad.RetourLeverancier]: 'retour-leverancier',
  [ETabblad.Geparkeerd]: 'geparkeerd',
  [ETabblad.Zoek]: 'zoek',
  [ETabblad.UitBedrijf]: 'uit-bedrijf',
};

interface IProps extends RouteComponentProps {}

export interface ITabbladProps {
  magID: number | null;
  filterProdID?: number;
  onSelectieChange: (prodIDs: number[]) => void;
}

const urlStateKey = 'retourverwerking';

export const reverseKeyExtractor = (
  key: string,
): {
  typeID?: number;
  prodID?: number;
} => {
  const [type, idStr] = key.split(':');
  const id = Number(idStr);
  if (type === 'T') {
    return {
      typeID: id,
    };
  }
  return {
    prodID: id,
  };
};

export const titelStatuswijzigingNietMogelijk: string =
  'Deze functie werkt alleen op een selectie van producten of één type. Pas de selectie aan of kies eerst een andere weergavemodus.';
export const titelFunctieNietMogelijk: string =
  'Deze functie werkt alleen op een selectie van producten. Pas de selectie aan of kies eerst een andere weergavemodus.';

const Depot: React.FC<IProps> = observer((props) => {
  const { instellingStore, checkStore, sessieStore } = useContext(RootStoreContext);

  const globaleRenderer = useContext(GlobaleRendererContext);

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      magID: sessieStore.beheerInitieelMagazijnID ?? instellingStore.MagID,
      productSelecterenDialoogState: null,
    }),
    [sessieStore.beheerInitieelMagazijnID, instellingStore.MagID],
  );

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);
  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);

  const ophalenMagazijnen = useCallback(async () => {
    const result = await api.v2.magazijn.ophalenMagazijnen({
      filterSchema: {
        filters: [{ naam: 'IS_ACTIEF', data: true }],
      },
    });
    setMagazijnen(result.magazijnen);
  }, []);

  useEffect(() => {
    if (urlState.magID === null && instellingStore.MagID !== null) {
      setUrlStateSync('magID', instellingStore.MagID);
    }
  }, [instellingStore.MagID]);

  // Bijwerken sessie store na wijziging magazijn selectie
  useEffect(() => {
    if (sessieStore.beheerInitieelMagazijnID !== urlState.magID) {
      sessieStore.bijwerkenBeheerInitieelMagazijnID(urlState.magID);
    }
  }, [urlState.magID]);

  useEffect(() => {
    ophalenMagazijnen();
  }, []);

  const magazijnKolommen = useMemo<IKolom<IOphalenMagazijnenResultElement>[]>(
    () => [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 150,
      },
      // {
      //   key: 'PlaatsNaam',
      //   label: 'Plaats',
      //   breedte: 150,
      // },
    ],
    [],
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Retouren,
        label: 'Retouren',
        content: Retouren,
      },
      {
        id: ETabblad.Voorraad,
        label: 'Voorraad',
        content: Voorraad,
      },
      {
        id: ETabblad.Geparkeerd,
        label: 'Geparkeerd',
        content: Geparkeerd,
      },
      {
        id: ETabblad.Revisie,
        label: 'Revisie',
        content: Revisie,
      },
      {
        id: ETabblad.Afvoer,
        label: 'Afvoer',
        content: Afvoeren,
      },
      {
        id: ETabblad.RetourLeverancier,
        label: 'Retour Leverancier',
        content: RetourLeverancier,
      },
      {
        id: ETabblad.Zoek,
        label: 'Zoekgemeld',
        content: Zoek,
      },
      {
        id: ETabblad.UitBedrijf,
        label: 'Uit Bedrijf',
        content: UitBedrijf,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  const [
    productSelectieDialoogResult,
    setProductSelectieDialoogResult,
  ] = useState<IProductSelectieDialoogResult | null>(null);

  const selectieRef = useRef<number[] | null>(null);
  const tabbladComponentProps = useMemo<ITabbladProps>(() => {
    return {
      magID: urlState.magID,
      onSelectieChange: (selectie) => (selectieRef.current = selectie),
      // TODO Dennis
      // Ik heb dit tijdelijk uitgecomment ivm onjuiste opzet

      // filterProdID:
      //   productSelectieDialoogResult === null ||
      //   productSelectieDialoogResult.tabblad !== tabblad
      //     ? undefined
      //     : productSelectieDialoogResult.prodID,
    };
  }, [urlState.magID]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => (
          <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Retouren]}`} />
        )}
      />
      {magazijnen === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="p-3">
            <div className="d-flex">
              <div style={{ width: 300 }}>
                <div className="mt-1">
                  <MultiCombobox<number, IOphalenMagazijnenResultElement>
                    sleutelExtractor={(row) => row.MagID}
                    representatieFabriek={(row) => row.NaamKort}
                    waarde={urlState.magID}
                    onWaardeChange={(x) => setUrlStateSync('magID', x)}
                    opties={magazijnen}
                    kolommen={magazijnKolommen}
                  />
                </div>
              </div>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={false}
                onClick={async () => {
                  globaleRenderer.render((renderProps) => {
                    return (
                      <ProductSelectieDialoog
                        open
                        onSuccess={(result) => {
                          renderProps.destroy();
                          globaleRenderer.render((productinfoRenderProps) => (
                            <ProductinfoDialoog
                              open
                              id={result.prodID}
                              onSuccess={() => productinfoRenderProps.destroy()}
                              onAnnuleren={() => productinfoRenderProps.destroy()}
                            />
                          ));
                        }}
                        onAnnuleren={() => renderProps.destroy()}
                      />
                    );
                  });
                }}
              >
                {/* <IconHerstel style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Productinformatie</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={false}
                onClick={async () => {
                  globaleRenderer.render((renderProps) => {
                    return (
                      <ProductSelectieDialoog
                        open
                        onSuccess={(result) => {
                          renderProps.destroy();
                          globaleRenderer.render((productinfoRenderProps) => (
                            <WijzigenProductDialoog
                              open
                              prodID={result.prodID}
                              onSuccess={() => productinfoRenderProps.destroy()}
                              onAnnuleren={() => productinfoRenderProps.destroy()}
                            />
                          ));
                        }}
                        onAnnuleren={() => renderProps.destroy()}
                      />
                    );
                  });
                }}
              >
                {/* <IconHerstel style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Product wijzigen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={false}
                onClick={async () => {
                  globaleRenderer.render((renderProps) => {
                    // renderProps.destroy();
                    return (
                      <ToevoegenProductDialoog
                        open
                        onSuccess={() => renderProps.destroy()}
                        onAnnuleren={() => renderProps.destroy()}
                      />
                    );
                  });
                }}
              >
                {/* <IconHerstel style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Product toevoegen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  if (tabblad === null) {
                    return;
                  }
                  if (selectieRef.current === null || selectieRef.current.length === 0) {
                    await checkStore.melden({
                      titel: 'Geen producten geselecteerd',
                    });
                    return;
                  }
                  const prodIDs = selectieRef.current;
                  let extraKolommen: MagazijnKolom[] = [];
                  switch (tabblad) {
                    case ETabblad.Retouren:
                      extraKolommen = retourenMagazijnKolommen;
                      break;
                    case ETabblad.Voorraad:
                      extraKolommen = voorraadMagazijnKolommen;
                      break;
                    case ETabblad.Revisie:
                      extraKolommen = revisieMagazijnKolommen;
                      break;
                    case ETabblad.Afvoer:
                      extraKolommen = afvoerMagazijnKolommen;
                      break;
                    case ETabblad.RetourLeverancier:
                      extraKolommen = retourLeverancierMagazijnKolommen;
                      break;
                    case ETabblad.Geparkeerd:
                      extraKolommen = geparkeerdMagazijnKolommen;
                      break;
                    case ETabblad.Zoek:
                      extraKolommen = zoekgemeldMagazijnKolommen;
                      break;
                    case ETabblad.UitBedrijf:
                      extraKolommen = uitBedrijfMagazijnKolommen;
                      break;
                  }

                  const result = await api.v2.magazijn.export.exporteren({
                    formaat: 'csv',
                    prodIDs,
                    kolommen: [...basisMagazijnKolommen, ...extraKolommen],
                  });
                  await downloadUrl(result.bestand.url, result.bestand.Naam);
                }}
              >
                <IconPijlOmhoog style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Exporteren</span>
              </button>
            </div>
          </div>
          <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
          {tabblad === null ? (
            <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Retouren]}`} />
          ) : (
            <Root>
              <Tabblad<ETabblad, ITabbladProps>
                geselecteerd={tabblad}
                onSelectieChange={(identifier) => {
                  const beheerState = new URLSearchParams(props.location.search).get(urlStateKey);
                  props.history.push({
                    pathname: `${props.match.path}/${urlTabbladMap[identifier]}`,
                    search: beheerState === null ? undefined : `${urlStateKey}=${beheerState}`,
                  });
                }}
                tabbladen={tabbladen}
                options={{
                  tabbladComponentProps,
                }}
              />
            </Root>
          )}
        </>
      )}
      {/* {urlState.productSelecterenDialoogState !== null && (
        <ProductSelectieDialoog
          open
          onSuccess={async (result) => {
            // if (urlState.magID !== result.magID) {
            //   const bevestigenResult = await checkStore.bevestigen({
            //     titel:
            //       'Magazijn van het geselecteerde product wijkt af van het vigerende magazijn. Doorgaan?',
            //   });
            //   if (bevestigenResult.type === EResultType.Annuleren) {
            //     setUrlStateSync('productSelecterenDialoogState', null);
            //     return;
            //   }
            // }
            // setProductSelectieDialoogResult(result);
            // const state: IUrlState = {
            //   ...urlState,
            //   magID: result.magID,
            //   productSelecterenDialoogState: null,
            // };
            // // setUrlState(state);
            // // const beheerState = new URLSearchParams(props.location.search).get(urlStateKey);
            // props.history.push({
            //   pathname: `${props.match.path}/${urlTabbladMap[result.tabblad]}`,
            //   search: `${urlStateKey}=${genereerUrlStateQueryParam(state)}`,
            // });
          }}
          onAnnuleren={() => setUrlStateSync('productSelecterenDialoogState', null)}
        />
      )} */}
    </>
  );
});

export default Depot;
