import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import { observer } from 'mobx-react-lite';
import Kostenplaatsen from './Kostenplaatsen';
import Budgetcodes from './Budgetcodes';
import Factuurkenmerken from './Factuurkenmerken';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Factuurkenmerken,
  Kostenplaatsen,
  Budgetcodes,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Factuurkenmerken]: '/factuurkenmerken',
  [ETabblad.Kostenplaatsen]: '/kostenplaatsen',
  [ETabblad.Budgetcodes]: '/budgetcodes',
};

const Rubrieken: React.FC<IProps> = observer((props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Factuurkenmerken]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Factuurkenmerken,
        label: 'Factuurkenmerken',
        content: Factuurkenmerken,
      },
      {
        id: ETabblad.Kostenplaatsen,
        label: 'Kostenplaatsen',
        content: Kostenplaatsen,
      },
      {
        id: ETabblad.Budgetcodes,
        label: 'Budgetcodes',
        content: Budgetcodes,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              relID: props.relID,
            },
          }}
        />
      )}
    </div>
  );
});

export default withRouter(Rubrieken);
