import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import Tabel from '../Tabel';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import NavigatiePijl from '../NavigatiePijl';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenInformatieVoorOverzichtIncasssozaak } from '../../../../../../../../../shared/src/api/v2/relatie';
import { format } from 'date-fns';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import {
  IUrlState as IIncassozaakUrlState,
  defaultUrlState as defaultIncassozaakUrlState,
} from '../../Debiteur/Incassozaken';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtIncasssozaak;
}

const Incassozaaktegel = (props: IProps) => {
  return (
    <TegelBasis titel="Incassozaak">
      <div>
        <table>
          <tbody>
            {props.data.zaken.map((zaak) => {
              return (
                <tr key={zaak.id}>
                  <td style={{ width: 100, verticalAlign: 'top' }}>
                    {zaak.zaakdatum !== null ? format(new Date(zaak.zaakdatum), 'dd-MM-yyyy') : ''}
                  </td>
                  <td style={{ width: 90, verticalAlign: 'top' }}>
                    <FormatteerBedrag bedrag={zaak.hoofdsom} />
                  </td>
                  <td style={{ width: 125, verticalAlign: 'top' }}>{zaak.dienstnaamKort}</td>

                  <td style={{ verticalAlign: 'top' }}>
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam<IIncassozaakUrlState>({
                          ...defaultIncassozaakUrlState,
                          selectie: [zaak.id],
                        });
                        return `debiteur/incassozaken?state=${state}`;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {props.data.totaalAantalZaken > props.data.zaken.length && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/debiteur/incassozaken`);
              }}
            >
              Er zijn nog {props.data.totaalAantalZaken - props.data.zaken.length}
              &nbsp;andere meldingen...
            </a>
          </div>
        )}
      </div>
    </TegelBasis>
  );
};

export default withRouter(Incassozaaktegel);
