import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../api';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../stores/CheckStore';
import { Formik, FormikProps, Field, FieldProps } from 'formik';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { format, addDays } from 'date-fns';
import nameOf from '../../../../core/nameOf';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import Combobox from '../../../../components/formulier/Combobox';
import { IOphalenBetaalwijzenResultElement } from '../../../../../../shared/src/api/v2/betaalwijze';
import teksten from '../../../../bedrijfslogica/teksten';
import * as Yup from 'yup';
import { IOphalenRekeningenResultElement } from '../../../../../../shared/src/api/v2/relatie';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { EBetaalwijze } from '../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps {
  factID: number | null;
}

export interface IFormikValues {
  factuurdatum: Date;
  vervaldatum: Date;
  nietVerrekenen: boolean;
  betWijzeID: number;
  versturen: boolean;
  kenmerk: string | null;
  relRekID: number | null;
  nietAanmanen: boolean;
  onderwerp: string | null;
  resetBestand: boolean;
}

const veldnamen = {
  factuurdatum: 'Factuurdatum',
  vervaldatum: 'Vervaldatum',
  nietVerrekenen: 'Niet verrekenen',
  betWijzeID: 'Betaalwijze',
  versturen: 'Aanbieden voor versturen',
  kenmerk: 'Factuurkenmerk',
  relRekID: 'IBAN tbv bankopdracht',
  nietAanmanen: 'Niet aanmanen',
  onderwerp: 'Onderwerp',
  resetBestand: 'Reset bestand',
};

const WijzigenFactuurDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [factuur, setFactuur] = useState<IOphalenFacturenBasisResultElement | null>(null);
  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalwijzenResultElement[] | null>(
    null,
  );
  const [rekeningen, setRekeningen] = useState<IOphalenRekeningenResultElement[] | null>(null);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const ophalenRekeningen = useCallback(async () => {
    if (factuur === null) {
      return;
    }

    const result = await api.v2.relatie.rekening.ophalenRekeningen({
      filterSchema: {
        filters: [
          { naam: 'REL_IDS', data: [factuur.RelID] },
          { naam: 'NIET_GEBRUIKEN', data: false },
        ],
      },
    });
    setRekeningen(result.rekeningen);
  }, [factuur]);

  useEffect(() => {
    ophalenRekeningen();
  }, [ophalenRekeningen]);

  const ophalenBetaalwijzen = useCallback(async () => {
    const result = await api.v2.betaalwijze.ophalenBetaalwijzen({
      filterSchema: { filters: [] },
    });
    setBetaalwijzen(result.betaalwijzen);
  }, []);

  useEffect(() => {
    ophalenBetaalwijzen();
  }, [ophalenBetaalwijzen]);

  useEffect(() => {
    if (props.factID === null) {
      return;
    }

    const fn = async () => {
      const { facturen } = await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.factID!] }] },
      });

      setFactuur(facturen[0]);
    };

    fn();
  }, [props.factID]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (factuur === null) {
      return null;
    }

    return {
      factuurdatum: new Date(factuur.Factuurdatum),
      vervaldatum: new Date(factuur.Vervaldatum),
      nietVerrekenen: factuur.NietVerrekenen,
      betWijzeID: factuur.betaalwijze.BetWijzeID,
      kenmerk: factuur.Kenmerk,
      versturen: factuur.Versturen,
      relRekID: factuur.rekening !== null ? factuur.rekening.RelRekID : null,
      nietAanmanen: factuur.NietAanmanen !== null,
      onderwerp: factuur.Onderwerp,
      resetBestand: false,
    };
  }, [factuur]);

  const handleWijzigen = useCallback(
    async (values: IFormikValues) => {
      if (factuur === null) {
        return null;
      }

      // if (values.resetBestand) {
      //   if (
      //     (
      //       await checkStore.bevestigen({
      //         inhoud: (
      //           <span>
      //             Je hebt aangegeven dat het bestand gereset moet worden. Hiermee verwijder je de
      //             koppeling naar het bestand. Zodra je de factuur download of weergeeft wordt het
      //             bestand opnieuw gemaakt en gekoppeld.
      //             <br />
      //             <br />
      //             Wil je doorgaan?
      //           </span>
      //         ),
      //       })
      //     ).type === EResultType.Annuleren
      //   ) {
      //     setIsSubmitting(false);
      //     return null;
      //   }
      // }

      setIsSubmitting(true);

      await api.v2.factuur.wijzigenFactuur({
        factID: props.factID!,
        factuurdatum: values.factuurdatum!.toISOString(),
        vervaldatum: values.vervaldatum!.toISOString(),
        nietVerrekenen: values.nietVerrekenen,
        betWijzeID: values.betWijzeID,
        versturen: values.versturen,
        kenmerk: values.kenmerk,
        onderwerp: values.onderwerp,
        relRekID: values.relRekID,
        nietAanmanen: values.nietAanmanen
          ? factuur.NietAanmanen !== null
            ? factuur.NietAanmanen
            : new Date()
          : null,
        resetBestand: values.resetBestand,
      });

      setIsSubmitting(false);

      props.onSuccess({});
      setFactuur(null);
    },
    [setIsSubmitting, props.onSuccess, factuur],
  );

  const handleAnnuleren = useCallback(() => {
    props.onAnnuleren();
    setFactuur(null);
  }, [props.onAnnuleren, setFactuur]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        factuurdatum: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.factuurdatum,
          }),
        ),
        vervaldatum: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.vervaldatum,
          }),
        ),
      }),
    [],
  );

  const rekeningKolommen = useMemo<IKolom<IOphalenRekeningenResultElement>[]>(() => {
    return [
      {
        key: 'IBAN',
        label: 'IBAN',
        breedte: 200,
      },
      {
        key: 'Rekeningnaam',
        label: 'Rekeningnaam',
        breedte: 250,
      },
    ];
  }, []);

  return (
    <Modal show={props.open}>
      <ModalHeader>
        <ModalTitle>Wijzigen factuur {factuur !== null ? factuur.Factuurnummer : ''}</ModalTitle>
      </ModalHeader>
      {factuur === null || betaalwijzen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          // ref={formikRef}
          isInitialValid
          enableReinitialize
          onSubmit={(values) => handleWijzigen(values)}
          initialValues={initieleWaarden!}
          // validationSchema={validatieschema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      {!factuur.Definitief && (
                        <div className="d-flex col-12 mt-3 pl-0">
                          <div className="col-5 align-items-left">
                            <label>{veldnamen.factuurdatum}</label>
                            <Field
                              name={nameOf('factuurdatum')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                return (
                                  <div className="d-flex">
                                    <DatumKiezer
                                      onGewijzigd={(x) => {
                                        form.setFieldValue(field.name, x);
                                      }}
                                      waarde={field.value}
                                      determineValidDate={(date) => {
                                        return (
                                          date >= addDays(new Date(), -60) &&
                                          date < addDays(new Date(), 65)
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              }}
                            />
                          </div>

                          <div className="col-5">
                            <label>{veldnamen.vervaldatum}</label>
                            <Field
                              name={nameOf('vervaldatum')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                return (
                                  <div className="d-flex align-items-center">
                                    <DatumKiezer
                                      onGewijzigd={(x) => {
                                        form.setFieldValue(field.name, x);
                                      }}
                                      waarde={field.value}
                                      determineValidDate={(date) => {
                                        return (
                                          date >= addDays(new Date(), -60) &&
                                          date < addDays(new Date(), 65)
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <div className="row">
                          <div className="col-12">
                            <label>{veldnamen.betWijzeID}</label>

                            <Field
                              name={nameOf<IFormikValues>('betWijzeID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                if (betaalwijzen === null) {
                                  return <LoadingSpinner />;
                                }

                                return (
                                  <Combobox
                                    legeOptieTonen
                                    geselecteerd={field.value}
                                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                    opties={betaalwijzen.map((x) => {
                                      return {
                                        id: x.BetWijzeID,
                                        label: x.Naam,
                                      };
                                    })}
                                  />
                                );
                              }}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <label>{veldnamen.relRekID}</label>
                            <Field
                              name={nameOf<IFormikValues>('relRekID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                const betaalwijzeNaamEnum = betaalwijzen.find(
                                  (x) => x.BetWijzeID === values.betWijzeID,
                                )!.NaamEnum;

                                return (
                                  <>
                                    <MultiCombobox<number, IOphalenRekeningenResultElement>
                                      sleutelExtractor={(row) => row.RelRekID}
                                      onWaardeChange={(waarde: number | null) => {
                                        form.setFieldValue(field.name, waarde);
                                      }}
                                      representatieFabriek={(row) => `${row.IBAN}`}
                                      waarde={fieldProps.field.value}
                                      opties={rekeningen}
                                      kolommen={rekeningKolommen}
                                      // options={{
                                      //   geenWaardeBericht: 'Alle productsoorten',
                                      // }}
                                      isWisbaar
                                      disabled={
                                        betaalwijzeNaamEnum !== EBetaalwijze.Incasso &&
                                        betaalwijzeNaamEnum !== EBetaalwijze.Verzamelbetaling
                                      }
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>

                          <div className="col-12 mt-3">
                            <label>{veldnamen.kenmerk}</label>
                            <Field
                              name={nameOf<IFormikValues>('kenmerk')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return <input {...field} className="form-control" type="text" />;
                              }}
                            />
                          </div>

                          <div className="col-12 mt-3">
                            <label>{veldnamen.onderwerp}</label>
                            <Field
                              name={nameOf<IFormikValues>('onderwerp')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return <input {...field} className="form-control" type="text" />;
                              }}
                            />
                          </div>

                          <div className="col-12 d-flex align-items-center mt-3">
                            <Field
                              name="nietVerrekenen"
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.nietVerrekenen}</span>
                          </div>

                          <div className="col-12 d-flex align-items-center mt-3">
                            <Field
                              name="nietAanmanen"
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.nietAanmanen}</span>
                          </div>

                          <div className="col-7 d-flex align-items-center mt-3">
                            <Field
                              name="versturen"
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.versturen}</span>
                          </div>

                          <div className="col-5 d-flex align-items-center mt-3">
                            <Field
                              name="resetBestand"
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.resetBestand}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleAnnuleren}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Modal>
  );
});

export default WijzigenFactuurDialoog;
