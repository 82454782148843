import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import MenuLayout from '../../../components/MenuLayout';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  ISjabloon,
  ISjabloonBasis,
  ISjabloonBestand,
  ISjabloonCategorie,
  ISjabloonContext,
  ISjabloonInhoud,
  ISjabloonKanaal,
  IVerwijderenSjablonenParams,
} from '../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from '../../../api';
import SjabloonRowDetail from './SjabloonRowDetail';
import { ICommunicatiekanaal } from '../../../../../shared/src/api/v2/bericht/Bericht';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconVink, IconAttachment, IconToevoegen, IconCopy } from '../../../components/Icons';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import { IOphalenTalenResultElement } from '../../../../../shared/src/api/v2/taal';
import * as _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';
import MultiSelect from '../../../components/formulier/MultiSelect';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import { ITekstPrecisieData } from '../../../../../shared/src/api/sql';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';

export const defaultSysteemDataContext = '{\n\n}';

export interface IRow {
  sjabloon: ISjabloon;
  categorie: ISjabloonCategorie | null;
  kanalen: {
    kanaal: ISjabloonKanaal;
    communicatiekanaal: ICommunicatiekanaal;
    inhouden: {
      taal: IOphalenTalenResultElement;
      inhoud: ISjabloonInhoud;
      bestanden: IOphalenBestandenResultElement[];
    }[];
  }[];
}

enum EFilter {
  SjabloonCategorieIds = 'SJAB_CAT_IDS',
  HeeftSjabloonKanaalIds = 'HEEFT_COM_KAN_IDS',
  HeeftTaalIds = 'HEEFT_TAAL_IDS',
  HeeftSjabloonContextIds = 'HEEFT_SJAB_CTX_IDS',
  TekstPrecisieHeeftInhoud = 'TEKST_PRECISIE_HEEFT_INHOUD',
  IsConcept = 'IS_CONCEPT',
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  sjabloonToevoegenTonen: boolean;
  sjabloonWijzigenSjabID: number | null;
  filterDatas: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  sjabloonToevoegenTonen: false,
  sjabloonWijzigenSjabID: null,
  filterDatas: [
    {
      naam: EFilter.SjabloonCategorieIds,
      data: [],
      isActief: false,
    },
    {
      naam: EFilter.HeeftSjabloonKanaalIds,
      data: [],
      isActief: false,
    },
    {
      naam: EFilter.HeeftTaalIds,
      data: [],
      isActief: false,
    },
    {
      naam: EFilter.HeeftSjabloonContextIds,
      data: [],
      isActief: false,
    },
    {
      naam: EFilter.TekstPrecisieHeeftInhoud,
      data: {
        precisie: 'ONGEVEER',
        waarde: '',
      } as ITekstPrecisieData,
      isActief: false,
    },
    {
      naam: EFilter.IsConcept,
      data: true,
      isActief: false,
    },
  ],
};

export interface ISjablonenContext {
  talen: IOphalenTalenResultElement[];
  communicatiekanalen: ICommunicatiekanaal[];
  categorieen: ISjabloonCategorie[];
  contexten: ISjabloonContext[];
  sjabloonBasissen: ISjabloonBasis[];

  onVerversenAangevraagd: () => void;
}

export const SjablonenContext = React.createContext<ISjablonenContext | null>(null);

interface IProps extends RouteComponentProps {}

const Sjablonen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const keyExtractor = useCallback((row: IRow) => row.sjabloon.ID, []);
  const [sjablonen, setSjablonen] = useState<ISjabloon[] | null>(null);
  const [sjabloonKanalen, setSjabloonKanalen] = useState<ISjabloonKanaal[] | null>(null);
  const [sjabloonCategorieen, setSjabloonCategorieen] = useState<ISjabloonCategorie[] | null>(null);
  const [sjabloonInhouden, setSjabloonInhouden] = useState<ISjabloonInhoud[] | null>(null);
  const [sjabloonBestanden, setSjabloonBestanden] = useState<ISjabloonBestand[] | null>(null);
  const [sjabloonBasissen, setSjabloonBassisen] = useState<ISjabloonBasis[] | null>(null);
  const [bestanden, setBestanden] = useState<IOphalenBestandenResultElement[] | null>(null);
  const [communicatiekanalen, setCommunicatiekanalen] = useState<ICommunicatiekanaal[] | null>(
    null,
  );
  const [talen, setTalen] = useState<IOphalenTalenResultElement[] | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.taal.ophalen({ filterSchema: { filters: [] } });
      setTalen(result);
    })();
  }, []);

  const [contexten, setContexten] = useState<ISjabloonContext[] | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonContexten({
        filterSchema: {
          filters: [],
        },
      });
      setContexten(result.contexten);
    })();
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.SjabloonCategorieIds,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Categorie
              <span className="ml-2">
                {sjabloonCategorieen === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={sjabloonCategorieen.map((x) => ({
                      key: x.SjabCatID,
                      weergave: x.Naam,
                    }))}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.HeeftSjabloonKanaalIds,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Kanaal
              <span className="ml-2">
                {communicatiekanalen === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={communicatiekanalen.map((x) => ({
                      key: x.ComKanID,
                      weergave: x.Naam,
                    }))}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.HeeftTaalIds,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Taal
              <span className="ml-2">
                {talen === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={talen.map((x) => ({
                      key: x.TaalID,
                      weergave: x.Naam,
                    }))}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.HeeftSjabloonContextIds,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Contexten
              <span className="ml-2">
                {contexten === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={contexten.map((x) => ({
                      key: x.SjabCtxID,
                      weergave: x.Naam,
                    }))}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.TekstPrecisieHeeftInhoud,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const d = weergaveProps.data as ITekstPrecisieData;
          return (
            <span className="d-flex align-items-center">
              Inhoud bevat
              <span className="ml-2">
                <input
                  type="text"
                  className="form-control"
                  value={d.waarde}
                  onChange={(ev) => {
                    weergaveProps.onDataChange({
                      ...d,
                      waarde: ev.target.value,
                    });
                  }}
                  onKeyUp={(ev) => {
                    if (ev.key === 'Enter') {
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }
                  }}
                />
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.IsConcept,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Concept</span>
            </div>
          );
        },
      },
    ],
    [sjabloonCategorieen, contexten, talen, communicatiekanalen],
  );
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterDatas));

  const ophalenSjablonen = useCallback(async () => {
    const result = await api.v2.sjabloon.ophalenSjablonen({
      filterSchema,
      orderSchema: {
        orders: [
          {
            naam: 'NAAM',
            richting: 'ASC',
          },
        ],
      },
    });

    setSjablonen(result.sjablonen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenSjablonen();
  }, [ophalenSjablonen]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.bericht.ophalenCommunicatiekanalen({
        filterSchema: {
          filters: [],
        },
      });
      setCommunicatiekanalen(result.kanalen);
    })();
  }, []);

  useEffect(() => {
    if (sjablonen === null) {
      return;
    }
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonKanalen({
        filterSchema: {
          filters: [
            // {
            //   naam: 'SJAB_IDS',
            //   data: sjablonen.map((x) => x.ID),
            // },
          ],
        },
      });
      setSjabloonKanalen(result.kanalen);
    })();
  }, [sjablonen]);

  const heeftGeinitialiseerd = useRef(false);
  useEffect(() => {
    if (sjablonen === null) {
      return;
    }

    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonCategorieen({
        filterSchema: {
          filters: [],
        },
        orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
      });
      setSjabloonCategorieen(result.categorieen);

      if (heeftGeinitialiseerd.current) {
        return;
      }
      heeftGeinitialiseerd.current = true;

      // const sjabloonCategorieFilterdata = urlState.filterDatas.find(
      //   (x) => x.naam === EFilter.SjabloonCategorieIds,
      // )!;
      // if (sjabloonCategorieFilterdata.data.length === 0) {
      //   const nieuweFilterdatas = urlState.filterDatas.map((filterdata) => {
      //     if (filterdata.naam === EFilter.SjabloonCategorieIds) {
      //       const algemeenCategorie = result.categorieen.find((x) => x.NaamEnum === 'ALGEMEEN');
      //       const contractenCategorie = result.categorieen.find((x) => x.NaamEnum === 'CONTRACTEN');
      //
      //       return {
      //         ...filterdata,
      //         data: [algemeenCategorie?.SjabCatID].filter((x) => x !== undefined) as number[],
      //         isActief: true,
      //       };
      //     }
      //     return filterdata;
      //   });
      //   setUrlStateSync('filterDatas', nieuweFilterdatas);
      //   // Handmatig zetten omdat isActief op true plaatsen opzichzelf niet genoeg is om een
      //   // nieuwe filterschema te genereren
      //   setFilterSchema(maakFilterSchema(nieuweFilterdatas));
      // }
    })();
  }, [sjablonen]);

  useEffect(() => {
    if (sjabloonKanalen === null) {
      return;
    }
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonInhouden({
        filterSchema: {
          filters: [
            {
              naam: 'SJAB_KAN_IDS',
              data: sjabloonKanalen.map((x) => x.ID),
            },
          ],
        },
      });
      setSjabloonInhouden(result.inhouden);
    })();
  }, [sjabloonKanalen]);

  useEffect(() => {
    if (sjabloonInhouden === null) {
      return;
    }
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonBestanden({
        filterSchema: {
          filters: [
            {
              naam: 'SJAB_INH_IDS',
              data: sjabloonInhouden.map((x) => x.ID),
            },
          ],
        },
      });
      setSjabloonBestanden(result.bestanden);
    })();
  }, [sjabloonInhouden]);

  useEffect(() => {
    if (sjabloonBestanden === null) {
      return;
    }
    (async () => {
      const result = await api.v2.bestand.ophalenBestanden({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: sjabloonBestanden.map((x) => x.BestandID),
            },
          ],
        },
      });
      setBestanden(result.bestanden);
    })();
  }, [sjabloonBestanden]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonBasissen({
        filterSchema: {
          filters: [],
        },
      });
      setSjabloonBassisen(result.basissen);
    })();
  }, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      // {
      //   name: 'Code' as any,
      //   title: 'Code',
      //   getCellValue: (x) => {
      //     return x.sjabloon.Code === null ? '' : x.sjabloon.Code;
      //   },
      // },
      {
        name: 'Naam' as any,
        title: 'Naam',
        getCellValue: (x) => {
          return x.sjabloon.Naam;
        },
      },
      {
        name: '__isConcept' as any,
        title: 'Concept',
      },
      {
        name: '__categorie' as any,
        title: 'Categorie',
        getCellValue: (x) => {
          return x.categorie !== null ? x.categorie.Naam : '';
        },
      },
      {
        name: '__kanalen' as any,
        title: 'Kanalen',
      },
      {
        name: '__talen' as any,
        title: 'Talen',
      },
      {
        name: '__heeftBestanden' as any,
        title: 'Bestanden',
      },
      {
        name: '__contexten' as any,
        title: 'Contexten',
      },
      {
        name: '__systeemSjabloon' as any,
        title: 'Systeemsjabloon',
      },
      {
        name: '__recordGewijzigd' as any,
        title: 'Laatst gewijzigd',
        getCellValue: (x) => x.sjabloon.RecordGewijzigd,
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Code',
        width: 75,
      },
      {
        columnName: 'Naam',
        width: 275,
      },
      {
        columnName: '__isConcept' as any,
        width: 95,
      },
      {
        columnName: '__categorie' as any,
        width: 110,
      },
      {
        columnName: '__kanalen' as any,
        width: 150,
      },
      {
        columnName: '__talen' as any,
        width: 80,
      },
      {
        columnName: '__contexten' as any,
        width: 450,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 105,
      },
      {
        columnName: '__systeemSjabloon' as any,
        width: 150,
      },
      {
        columnName: '__recordGewijzigd' as any,
        width: 150,
      },
    ],
    [],
  );

  const rijen = useMemo<IRow[] | null>(() => {
    if (
      sjablonen === null ||
      sjabloonKanalen === null ||
      sjabloonInhouden === null ||
      sjabloonBestanden === null ||
      sjabloonCategorieen === null ||
      communicatiekanalen === null ||
      talen === null ||
      bestanden === null
    ) {
      return null;
    }
    return sjablonen.map((sjabloon) => {
      const kanalen = sjabloonKanalen.filter((x) => x.SjabID === sjabloon.ID);
      const categorie =
        sjabloon.SjabCatID === null
          ? null
          : sjabloonCategorieen.find((x) => x.SjabCatID === sjabloon.SjabCatID)!;
      return {
        sjabloon,
        categorie,
        kanalen: kanalen.map((kanaal) => {
          const inhouden = sjabloonInhouden.filter((x) => x.SjabKanID === kanaal.ID);
          const comKanaal = communicatiekanalen.find((x) => x.ComKanID === kanaal.ComKanID)!;
          return {
            kanaal,
            communicatiekanaal: comKanaal,
            inhouden: inhouden.map((inhoud) => {
              const sjabBestanden = sjabloonBestanden.filter((x) => x.SjabInhID === inhoud.ID);
              const bests = sjabBestanden.map(
                (sjabBest) => bestanden.find((x) => x.ID === sjabBest.BestandID)!,
              );
              const taal = talen.find((x) => x.TaalID === inhoud.TaalID)!;
              return {
                taal,
                inhoud,
                bestanden: bests,
              };
            }),
          };
        }),
      };
    });
  }, [
    sjablonen,
    sjabloonKanalen,
    sjabloonInhouden,
    sjabloonBestanden,
    sjabloonCategorieen,
    communicatiekanalen,
    talen,
    bestanden,
  ]);

  const isAanHetLaden = useMemo(() => rijen === null || sjabloonBasissen === null, [
    rijen,
    sjabloonBasissen,
  ]);

  return (
    <>
      <Helmet>
        <title>Sjablonen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => setUrlStateSync('sjabloonToevoegenTonen', true)}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                const params = { ids: urlState.selectie };

                const checkData = await api.v2.sjabloon.checkKopierenSjablonen(params);
                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: <span>Geselecteerde sjablonen kopieren?</span>,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.sjabloon.kopierenSjablonen(params);

                ophalenSjablonen();
              }}
            >
              <IconCopy style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Kopieren</span>
            </button>

            <div className="ml-3 d-flex flex-fill">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterDatas}
                onFilterDataChange={(x) => setUrlStateSync('filterDatas', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          <div className="d-flex align-items-center justify-content-center">
            {isAanHetLaden ? (
              <LoadingSpinner />
            ) : (
              <SjablonenContext.Provider
                value={{
                  talen: talen!,
                  categorieen: sjabloonCategorieen!,
                  communicatiekanalen: communicatiekanalen!,
                  contexten: contexten!,
                  sjabloonBasissen: sjabloonBasissen!,
                  onVerversenAangevraagd: async () => {
                    setSjablonen(null);
                    setSjabloonKanalen(null);
                    setSjabloonCategorieen(null);
                    setSjabloonInhouden(null);
                    setSjabloonBestanden(null);
                    setBestanden(null);
                    await ophalenSjablonen();
                  },
                }}
              >
                <GridStyleWrapper height="calc(100vh - 155px)">
                  <Grid getRowId={keyExtractor} columns={kolommen} rows={rijen!}>
                    <DataTypeProvider
                      for={['Code']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        return <span>{row.sjabloon.Code === null ? '' : row.sjabloon.Code}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__naam']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;

                        if (row.sjabloon.Omschrijving === null) {
                          return <span>{row.sjabloon.Naam}</span>;
                        }

                        return (
                          <UitlegTooltip inhoud={row.sjabloon.Omschrijving}>
                            <span>{row.sjabloon.Naam}</span>
                          </UitlegTooltip>
                        );
                      }}
                    />
                    <DataTypeProvider
                      for={['__isConcept']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        return <span>{row.sjabloon.IsConcept ? 'Ja' : null}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__categorie']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        if (row.categorie === null) {
                          return <span />;
                        }
                        return <span>{row.categorie.Naam}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__kanalen']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        const kanalenWeergave = row.kanalen
                          .map((x) => x.communicatiekanaal.Naam)
                          .join(', ');
                        return <span>{kanalenWeergave}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__systeemSjabloon']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;

                        return <span>{row.sjabloon.IsSysteemSjabloon ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__talen']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        const talenWeergave = _.uniq(
                          row.kanalen.map((x) => x.inhouden.map((y) => y.taal.TaalID)).flat(),
                        )
                          .map(
                            (taalID: number) => talen!.find((x) => x.TaalID === taalID)!.NaamKort,
                          )
                          .join(', ');
                        return <span>{talenWeergave}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__contexten']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        if (contexten === null) {
                          return <span />;
                        }
                        const contextenWeergave = _.uniq(
                          row.sjabloon.sjabCtxs.map((sjabCtx) => {
                            const context = contexten!.find(
                              (context) => context.SjabCtxID === sjabCtx.sjabCtxID,
                            )!;
                            if (sjabCtx.isOptioneel) {
                              return `[${context.Naam}]`;
                            }
                            return context.Naam;
                          }),
                        ).join(', ');
                        return <span>{contextenWeergave}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__heeftBestanden']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        const heeftBestanden = row.kanalen.some((kanaal) =>
                          kanaal.inhouden.some((inhoud) => inhoud.bestanden.length > 0),
                        );
                        return (
                          <span>
                            {heeftBestanden ? (
                              <IconAttachment
                                style={{ width: 20, height: 20, fill: Kleur.Grijs }}
                              />
                            ) : null}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__recordGewijzigd']}
                      formatterComponent={(formatterProps) => {
                        const row: IRow = formatterProps.row;
                        return (
                          <span>
                            {row.sjabloon.RecordGewijzigd !== null
                              ? format(new Date(row.sjabloon.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                              : ''}
                          </span>
                        );
                      }}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <RowDetailState
                      expandedRowIds={urlState.uitgeklapt}
                      onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                    />
                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted !== undefined && changes.deleted.length > 0) {
                          const verwijderenParams: IVerwijderenSjablonenParams = {
                            sjabIDs: changes.deleted as number[],
                          };
                          const checkData = await api.v2.sjabloon.checkVerwijderenSjablonen(
                            verwijderenParams,
                          );
                          if (
                            (await checkStore.controleren({ checkData })).type ===
                            EResultType.Annuleren
                          ) {
                            return;
                          }

                          const bevestigResult = await checkStore.bevestigen({
                            inhoud: 'Bevestig verwijderen',
                          });
                          if (bevestigResult.type === EResultType.Annuleren) {
                            return;
                          }

                          await api.v2.sjabloon.verwijderenSjablonen(verwijderenParams);
                          await ophalenSjablonen();
                        }
                      }}
                      onEditingRowIdsChange={(ids) => {
                        const id = ids[ids.length - 1] as number;
                        // const row = ;
                        setUrlStateSync('sjabloonWijzigenSjabID', id);
                      }}
                    />
                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />
                    <VirtualTable />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow showSortingControls />
                    <TableEditColumn
                      width={65}
                      commandComponent={DXTableEditColumnCommandComponent}
                      cellComponent={DXTableEditColumnCellComponent}
                      showEditCommand
                      showDeleteCommand
                    />
                    <TableRowDetail
                      toggleCellComponent={DXTableToggleCellComponent}
                      contentComponent={SjabloonRowDetail}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </SjablonenContext.Provider>
            )}
          </div>
        }
      />
      {urlState.sjabloonToevoegenTonen && (
        <ToevoegenDialoog
          open
          onSuccess={async () => {
            await ophalenSjablonen();
            setUrlStateSync('sjabloonToevoegenTonen', false);
          }}
          onAnnuleren={() => setUrlStateSync('sjabloonToevoegenTonen', false)}
        />
      )}
      {urlState.sjabloonWijzigenSjabID !== null && rijen !== null && (
        <WijzigenDialoog
          open
          onSuccess={async () => {
            await ophalenSjablonen();
            setUrlStateSync('sjabloonWijzigenSjabID', null);
          }}
          onAnnuleren={() => setUrlStateSync('sjabloonWijzigenSjabID', null)}
          row={rijen!.find((x) => x.sjabloon.ID === urlState.sjabloonWijzigenSjabID)!}
        />
      )}
    </>
  );
});

export default withRouter(Sjablonen);
