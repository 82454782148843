import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import nameof from '../../../../core/nameOf';
import { addDays, format } from 'date-fns';
import { dagDatum } from '../../../../helpers/datum';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IRegel as IOphalenFactuurregelsResultElement } from '../../../../../../shared/src/api/v2/factuur';
import _ from 'lodash';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { IMakenFactuurVoorstelResult } from '../../../../../../shared/src/api/v2/vordering';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import { EBetaalwijze } from '../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<null> {
  // relID?: number;
  factRegIDs: number[];
}
interface IFormikValues {
  factuurDatum: Date | null;
  directDefinitief: boolean;
  bankopdracht: boolean;
  betaaldatum: Date | null;
  alleenSelectie: boolean;
}

const veldnamen = {
  factuurDatum: 'Factuurdatum',
  directDefinitief: 'Direct definitief maken',
  bankopdracht: 'Bankopdracht',
  betaaldatum: 'Betaaldatum',
  alleenSelectie: 'Alleen voor de geselecteerde vorderingen',
};

const FactuurVoorstelDialoog = observer((props: IProps) => {
  const realtimeContext = useContext(RealtimeContext);

  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  // Gegegevens van de relatie ophalen
  // const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  // useEffect(() => {
  //   (async () => {
  //     if (props.relID === undefined) {
  //       return;
  //     }
  //     const result = await api.v2.relatie.ophalenRelaties({
  //       filterSchema: { filters: [{ naam: 'IDS', data: [props.relID] }] },
  //     });
  //     setRelatie(result.relaties[0]);
  //   })();
  // }, [props.relID]);

  const [factuurregels, setFactuurregels] = useState<IOphalenFactuurregelsResultElement[] | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      // if (props.relID === undefined) {
      //   return;
      // }
      const result = (
        await api.v2.factuur.ophalenFactuurregels({
          filterSchema: { filters: [{ naam: 'IDS', data: props.factRegIDs }] },
        })
      ).regels;

      setFactuurregels(result);
    })();
  }, [props.factRegIDs]);

  const isCredit = useMemo<boolean | null>(() => {
    if (factuurregels === null) {
      return null;
    }
    const totaalbedrag = _.sum(factuurregels.map((x) => x.Bedrag));
    return totaalbedrag < 0;
  }, [factuurregels]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud:
              'Factu(u)r(en) maken?' +
              (values.bankopdracht && !isCredit
                ? '\nJe hebt opgegeven dat er direct een incasso-opdracht gemaakt moet worden.'
                : ''),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const params = {
        factRegIDs: props.factRegIDs, //values.alleenSelectie ? props.factRegIDs : undefined,
        factuurdatum: values.factuurDatum,
        definitief: values.directDefinitief,
        betWijzeID: values.bankopdracht
          ? undefined
          : (
              await api.v2.betaalwijze.ophalenBetaalwijzen({
                filterSchema: { filters: [{ naam: 'NAAM_ENUMS', data: ['OVERSCHRIJVING'] }] },
              })
            ).betaalwijzen[0].BetWijzeID,
      };

      // const facturenResult = await api.v2.vordering.makenFactuurVoorstel(params);

      const facturenAchtergrondProces = await api.v2.vordering.makenFactuurVoorstelAchtergrondProces(
        params,
      );
      const facturenAchtergrondProcesResult = await achtergrondProcesAfwachten<
        IMakenFactuurVoorstelResult
      >(facturenAchtergrondProces.id, realtimeContext);

      if (facturenAchtergrondProcesResult.type !== 'DATA') {
        // Timeout of error
        alert('ERROR');
        return;
      }
      const facturenResult = facturenAchtergrondProcesResult.data!;

      // Bankopdracht (Incasso of Betaal) maken
      if (values.bankopdracht && values.directDefinitief) {
        const { facturen } = await api.v2.factuur.ophalenFacturenBasis({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: facturenResult.factIDs,
              },
              {
                naam: 'BETAALWIJZE_NAANENUMS',
                data: [EBetaalwijze.Incasso, EBetaalwijze.Verzamelbetaling],
              },
              // { naam: 'HEEFT_GEEN_BANKOPDRACHT', data: true },
            ],
          },
        });

        if (facturen.length > 0) {
          await api.v2.factuur.makenBankopdrachten({
            factIDs: facturen.map((x) => x.FactID),
            uitvoerdatum: values.betaaldatum !== null ? dagDatum(values.betaaldatum) : null,
          });
        }
      }
      await checkStore.melden({
        titel: `Er zijn ${facturenResult.factIDs.length} facturen gemaakt`,
      });

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess, isCredit],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    // if (props.relID !== undefined && relatie === null) {
    //   return null;
    // }

    // Bepaal de incassodatum
    const betaaldatum = dagDatum(new Date());

    return {
      factuurDatum: dagDatum(new Date()),
      directDefinitief: true,
      bankopdracht: true,
      betaaldatum,
      alleenSelectie: true,
    };
  }, []);

  const validatieschema = Yup.object().shape({
    factuurDatum: Yup.date().required(),
  });

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Facturen maken</ModalTitle>
      </ModalHeader>

      {initieleWaarden === null || factuurregels === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          isInitialValid
          onSubmit={handleSubmit}
          initialValues={initieleWaarden}
          validationSchema={validatieschema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.factuurDatum}</label>
                        <Field
                          name={nameof('factuurDatum')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex align-items-center">
                                <DatumKiezer
                                  onGewijzigd={(x) => {
                                    form.setFieldValue(field.name, x);
                                    // setfactuurDatum(x);
                                  }}
                                  waarde={field.value}
                                  determineValidDate={(date) => {
                                    const nu = dagDatum(new Date());
                                    return date > addDays(nu, -45);
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    const minDate = addDays(new Date(), -45);
                                    const newDate = addDays(date, -1);
                                    if (newDate >= minDate) {
                                      return newDate;
                                    }
                                    return null;
                                  }}
                                  determineNextValidDate={(date) => {
                                    const maxDate = addDays(new Date(), +7);
                                    const newDate = addDays(date, +1);
                                    if (newDate < maxDate) {
                                      return newDate;
                                    }
                                    return null;
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-6 mt-3">
                        <Field
                          name={nameof<IFormikValues>('directDefinitief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  aangevinkt={field.value}
                                />
                                <span className="ml-2">{veldnamen.directDefinitief}</span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      {values.directDefinitief && (
                        <div className="row col-12" style={{ minHeight: 45 }}>
                          <div className="d-flex col-5 mt-3">
                            <Field
                              name={nameof<IFormikValues>('bankopdracht')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    aangevinkt={field.value}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">
                              {isCredit ? 'Betaalopdracht' : 'Incasso-opdracht'}
                            </span>
                          </div>
                          {values.bankopdracht && (
                            <div className="col-7 mt-2 d-flex">
                              <div className="d-flex flex-fill align-items-center justify-content-end">
                                <span>{isCredit ? 'Betaaldatum' : 'Incassodatum'}</span>

                                <Field
                                  name={nameof<IFormikValues>('betaaldatum')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <Field
                                        name={nameof('betaaldatum')}
                                        render={({ field, form }: FieldProps<IFormikValues>) => {
                                          return (
                                            <div className="d-flex flex-fill align-items-center justify-content-end ml-2">
                                              <DatumKiezer
                                                onGewijzigd={(x) => {
                                                  form.setFieldValue(field.name, x);
                                                }}
                                                waarde={field.value}
                                                isClearable
                                                placeholder={'Opdrachtdatum'}
                                                determineValidDate={(date) => {
                                                  const nu = dagDatum(new Date());
                                                  return date >= nu && date < addDays(nu, 32);
                                                }}
                                                determineNextValidDate={(date) => {
                                                  const maxDate = addDays(new Date(), +32);
                                                  const newDate = addDays(date, +1);
                                                  if (newDate < maxDate) {
                                                    return newDate;
                                                  }
                                                  return null;
                                                }}
                                                determinePreviousValidDate={(date) => {
                                                  const minDate = addDays(new Date(), -1);
                                                  const newDate = addDays(date, -1);
                                                  if (newDate >= minDate) {
                                                    return newDate;
                                                  }
                                                  return null;
                                                }}
                                              />
                                            </div>
                                          );
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {/* <div className="col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('alleenSelectie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  aangevinkt={field.value}
                                />
                                <span className="ml-2">{veldnamen.alleenSelectie}</span>
                              </div>
                            );
                          }}
                        />
                      </div> */}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default FactuurVoorstelDialoog;
