import api from '../index';
import {
  IOphalenRekeningenParams,
  IOphalenRekeningenResult,
  IOphalenStornoredenenParams,
  IOphalenStornoredenenResult,
  IWijzigenStornoredenParams,
  IWijzigenStornoredenResult,
} from '../../../../shared/src/api/v2/bank/bank';
import mutatie from './bank/mutatie';
import opdracht from './bank/opdracht';
import rekening from './bank/rekening';
import ICheckData from '../../../../shared/src/models/ICheckData';

const bank = {
  mutatie,
  opdracht,
  rekening,
  async ophalenRekeningen(params: IOphalenRekeningenParams): Promise<IOphalenRekeningenResult> {
    return await api.post('v2/bank/ophalen-rekeningen', params);
  },
  async ophalenStornoredenen(
    params: IOphalenStornoredenenParams,
  ): Promise<IOphalenStornoredenenResult> {
    return await api.post('v2/bank/ophalen-stornoredenen', params);
  },
  async checkWijzigenStornoredenen(params: IWijzigenStornoredenParams): Promise<ICheckData> {
    return await api.post('v2/bank/check-wijzigen-stornoreden', params);
  },
  async wijzigenStornoreden(
    params: IWijzigenStornoredenParams,
  ): Promise<IWijzigenStornoredenResult> {
    return await api.post('v2/bank/wijzigen-stornoreden', params);
  },
};

export default bank;
