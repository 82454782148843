import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedDataTypeProvider,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';

import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenAbonnementResult } from '../../../../../../shared/src/api/v2/aanbod/Abonnement';
import { Root } from './style';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import WijzigenDialoog from './WijzigenDialoog';
import BooleanWeergave from '../../../../components/tabel/BooleanWeergave';
import NieuwDialoog from './NieuwDialoog';
import FilterBalk, { genereerFilterFunctie } from '../../../../components/FilterBalk';
import {
  EDataType,
  ICustomFilter,
  IFilter,
  Omschrijving,
} from '../../../../components/FilterBalk/types';
import { Helmet } from 'react-helmet';

enum ECustomFilter {
  AlleenActieve,
  MetEenmaligBedrag,
}

interface IProps extends RouteComponentProps<{}> {}

const Abonnementen: React.FC<IProps> = observer((props) => {
  const { abonnementenStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback((row: IOphalenAbonnementResult) => row.AbonID, []);

  const [nieuwDialoogOpen, setNieuwDialoogOpen] = useState<boolean>(false);
  const [wijzigAbonID, setWijzigAbonID] = useState<number | null>(null);
  const [filters, setFilters] = useState<IFilter<number, IOphalenAbonnementResult>[]>([]);

  const mogelijkeFilters = useMemo<
    ICustomFilter<ECustomFilter, number, IOphalenAbonnementResult>[]
  >(
    () => [
      {
        id: ECustomFilter.AlleenActieve,
        weergaveNaam: 'Alleen actieve weergeven',
        filter: async (entities) => entities.filter((ent) => ent.Actief).map(keyExtractor),
      },
      {
        id: ECustomFilter.MetEenmaligBedrag,
        weergaveNaam: 'Met eenmalig bedrag',
        filter: async (entities) =>
          entities.filter((ent) => ent.EenmaligBedrag !== 0).map(keyExtractor),
      },
    ],
    [],
  );
  const [customFilters, setCustomFilters] = useState<ECustomFilter[]>([]);

  const filterBalkOmschrijving = useMemo<Omschrijving<IOphalenAbonnementResult>>(() => {
    return {
      AbonID: {
        weergaveNaam: 'ID',
        dataType: EDataType.Number,
      },
      Actief: {
        weergaveNaam: 'Actief',
        dataType: EDataType.Boolean,
      },
      EenmaligBedrag: {
        weergaveNaam: 'Eenmalig Bedrag',
        dataType: EDataType.Number,
      },
      MinimaleTermijn: {
        weergaveNaam: 'Minimale Termijn',
        dataType: EDataType.Number,
      },
      Naam: {
        weergaveNaam: 'Naam',
        dataType: EDataType.String,
      },
      NaamKort: {
        weergaveNaam: 'Naam Kort',
        dataType: EDataType.String,
      },
      SortNr: {
        weergaveNaam: 'Sorteer Nummer',
        dataType: EDataType.Number,
      },
    };
  }, []);

  const handleNieuwSuccess = useCallback(() => {
    setNieuwDialoogOpen(false);
    abonnementenStore.ophalenAbonnementen();
  }, [setNieuwDialoogOpen]);

  const handleWijzigenSuccess = useCallback(() => {
    setWijzigAbonID(null);
    abonnementenStore.ophalenAbonnementen();
  }, [setWijzigAbonID]);

  const kolommen = useMemo<TypedColumn<IOphalenAbonnementResult>[]>(() => {
    return [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'NaamKort',
        title: 'Naamkort',
      },
      {
        name: 'MinimaleTermijn',
        title: 'Minimale Termijn',
      },
      {
        name: 'EenmaligBedrag',
        title: 'EenmaligBedrag',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenAbonnementResult>[]>(() => {
    return [
      {
        columnName: 'Naam',
        width: 170,
      },
      {
        columnName: 'NaamKort',
        width: 150,
      },
      {
        columnName: 'MinimaleTermijn',
        width: 150,
      },
      {
        columnName: 'EenmaligBedrag',
        width: 150,
      },
      {
        columnName: 'Actief',
        width: 150,
      },
    ];
  }, []);

  useEffect(() => {
    if (abonnementenStore.abonnementen !== null) {
      return;
    }

    abonnementenStore.ophalenAbonnementen();
  }, []);

  const [abonnementen, setAbonnementen] = useState<IOphalenAbonnementResult[] | null>(
    abonnementenStore.abonnementen,
  );
  useEffect(() => {
    if (abonnementenStore.abonnementen === null) {
      setAbonnementen(null);
      return;
    }

    (async () => {
      setAbonnementen(null);
      const filterFn = genereerFilterFunctie(
        keyExtractor,
        filters,
        mogelijkeFilters.filter((x) => customFilters.includes(x.id)),
      );
      const ids = await filterFn(abonnementenStore.abonnementen!);
      setAbonnementen(abonnementenStore.abonnementen!.filter((abon) => ids.includes(abon.AbonID)));
    })();
  }, [filters, customFilters, mogelijkeFilters, abonnementenStore.abonnementen]);

  return (
    <>
      <Helmet>
        <title>Abonnementen</title>
      </Helmet>
      <Root>
        <FilterBalk
          omschrijving={filterBalkOmschrijving}
          keyExtractor={keyExtractor}
          filters={filters}
          onFiltersChange={(data) => setFilters(data)}
          mogelijkeCustomFilters={mogelijkeFilters}
          customFilters={customFilters}
          onCustomFiltersChange={(data) => setCustomFilters(data)}
        />

        {abonnementen === null ? (
          <LoadingSpinner />
        ) : (
          <GridStyleWrapper>
            <Grid getRowId={keyExtractor} rows={abonnementen} columns={kolommen}>
              <TypedDataTypeProvider<IOphalenAbonnementResult>
                for={['EenmaligBedrag']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />

              <DataTypeProvider
                for={['Actief']}
                formatterComponent={(props) => <BooleanWeergave waarde={props.value} />}
              />

              <EditingState
                onAddedRowsChange={() => {
                  setNieuwDialoogOpen(true);
                }}
                onEditingRowIdsChange={(x) => {
                  const id = x[x.length - 1] as number;
                  setWijzigAbonID(id);
                }}
                onCommitChanges={() => null}
              />

              <VirtualTable estimatedRowHeight={43} />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <TableHeaderRow />

              <TableEditColumn
                width={100}
                showAddCommand={true}
                showEditCommand
                showDeleteCommand
                commandComponent={DXCommandComponent}
              />
            </Grid>
          </GridStyleWrapper>
        )}
      </Root>

      <NieuwDialoog
        onSuccess={handleNieuwSuccess}
        onAnnuleren={() => setNieuwDialoogOpen(false)}
        open={nieuwDialoogOpen !== false}
      />

      <WijzigenDialoog
        abonID={wijzigAbonID}
        open={wijzigAbonID !== null}
        onSuccess={handleWijzigenSuccess}
        onAnnuleren={() => setWijzigAbonID(null)}
      />
    </>
  );
});

export default Abonnementen;
