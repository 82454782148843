import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import React, { useRef } from 'react';
import { EFilter } from '..';

const BedragFilter = (p: IWeergaveProps<any>) => {
  return (
    <span className="d-flex align-items-center" style={{ width: 150 }}>
      <span className="mr-3">Bedrag</span>
      <input
        placeholder="B.v. 20.50 of -9,70"
        className="form-control"
        value={p.state || ''}
        onChange={(ev) => p.onStateChange(ev.target.value)}
        onKeyUp={(ev) => {
          if (ev.key === 'Enter') {
            p.onDataChange(p.state);
            p.setFilterIsActief(EFilter.Bedrag, true);
            p.toepassen();
          }
        }}
      />
    </span>
  );
};

export default BedragFilter;
