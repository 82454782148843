import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Column,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RowDetailComp from './RowDetailComp';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import MenuLayout from '../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../stores/RootStore';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import SelectieVak from '../../../../components/SelectieVak';
import FactuurinfoDialoog from '../../../../components/inkoopfactuur/FactuurinfoDialoog';
import styled from 'styled-components';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import { IOphalenBoekingRegelsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

export interface IFactuurinfoDialoogState {
  inkFactID: number;
}
export interface IUrlState {
  selectie: number[];
  factuurinfoDialoogState: IFactuurinfoDialoogState | null;
}
const defaultUrlState: IUrlState = {
  selectie: [],
  factuurinfoDialoogState: null,
};

export interface IFactuur extends IOphalenFacturenResultElement {}

export interface IRelatie extends IOphalenRelatiesResultElementV2 {
  facturen: IFactuur[];
  boekingsregels: IOphalenBoekingRegelsResultElement[];
}

const geenData = {
  noData: 'Geen crediteuren gevonden',
};

interface IOphoudenTrProps {
  nietKleuren?: boolean;
}

const OphoudenTr = styled.tr<IOphoudenTrProps>`
  td {
    ${(props) =>
      props.nietKleuren
        ? ''
        : `
      color: ${EKleur.Rood} !important;
    `}
  }
`;

const Betalen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const keyExtractor = useCallback((row: IRelatie) => row.RelID, []);

  const [relaties, setRelaties] = useState<IRelatie[] | null>(null);
  const [facturen, setFacturen] = useState<IFactuur[] | null>(null);

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          { naam: 'IS_GEFIATTEERD', data: true },
          { naam: 'IS_OPENSTAAND', data: true },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });

    setFacturen(facturenResult.facturen);
  }, []);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const ophalenRelaties = useCallback(async () => {
    if (facturen === null) {
      return;
    }

    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: _.uniq(facturen.map((x) => x.RelID)) }].filter(
          (x) => x !== null,
        ) as IFilterSchemaFilter[],
      },
    });

    const relIDs = relatiesResult.relaties.map((x) => x.RelID);

    const boekingsregels = await api.v2.boekhouding.boeking.ophalenBoekingregels({
      filterSchema: {
        filters: [
          { naam: 'REL_IDS', data: relIDs },
          { naam: 'INKFACTID_IS_LEEG', data: true },
          { naam: 'FACTID_IS_LEEG', data: true },
        ],
      },
    });

    const relaties = relatiesResult.relaties.map((relatie) => {
      const facturenVoorRelatie = facturen.filter((x) => x.RelID === relatie.RelID);

      const facturenVoorRelatieGesorteerd = _.orderBy(
        facturenVoorRelatie,
        ['Factuurdatum'],
        ['asc'],
      );

      const boekingsregelsVoorRelatie = boekingsregels.regels.filter(
        (x) => x.RelID === relatie.RelID,
      );
      return {
        ...relatie,
        facturen: facturenVoorRelatieGesorteerd,
        boekingsregels: boekingsregelsVoorRelatie,
      };
    });

    setRelaties(relaties);
  }, [facturen]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const relatieKolommen = useMemo<TypedColumn<IRelatie>[]>(
    () => [
      {
        name: '__relatienaam' as any,
        title: 'Naam',
        getCellValue: (x) => x.weergavenaam,
      },
      {
        name: '__totaalOpenstaand' as any,
        title: 'Tot. openstaand',
        getCellValue: (x) => _.sum(x.facturen.map((x) => x.Openstaand)),
      },
      {
        name: '__aantalFacturen' as any,
        title: 'Atl. fact.',
        getCellValue: (x) => x.facturen.length,
      },
      {
        name: '__oudsteFactuurdatum' as any,
        title: 'Oudste factuurdatum',
        getCellValue: (x) => new Date(_.min(x.facturen.map((x) => x.Factuurdatum))),
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );
  const relatieKolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRelatie>[]>(
    () => [
      {
        columnName: '__relatienaam' as any,
        width: 200,
      },
      {
        columnName: '__totaalOpenstaand' as any,
        width: 150,
      },
      {
        columnName: '__openstaandRelatie' as any,
        width: 150,
      },
      {
        columnName: '__aantalFacturen' as any,
        width: 90,
      },
      {
        columnName: '__oudsteFactuurdatum' as any,
        width: 175,
      },
      {
        columnName: 'Notities',
        width: 400,
      },
    ],
    [],
  );

  const relatieKolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Overzicht inkoopfacturen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="mt-2 d-flex align-items-center">
            <>
              {relaties !== null && (
                <>
                  <SelectieVak
                    totaalAantal={relaties.length}
                    aantal={urlState.selectie.length}
                    onChange={(allesGeselecteerd) => {
                      if (allesGeselecteerd) {
                        setUrlStateSync('selectie', relaties.map(keyExtractor));
                      } else {
                        setUrlStateSync('selectie', []);
                      }
                    }}
                  />
                </>
              )}
            </>
          </div>
        }
        body={
          <div>
            {relaties === null ? (
              <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={relatieKolommen} rows={relaties} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={['__relatienaam']}
                    formatterComponent={(props) => (
                      <RelatieVisualisatie
                        relID={props.row.RelID}
                        relatieLinkBuilder={(hoedanigheid, relID) =>
                          `/${
                            hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                          }/${relID}/facturen/overzicht`
                        }
                      />
                    )}
                  />

                  <DataTypeProvider
                    for={['__totaalOpenstaand']}
                    formatterComponent={(props) => {
                      const rij: IRelatie = props.row;
                      const openstaand = _.sum(rij.facturen.map((x) => x.Openstaand));
                      return <FormatteerBedrag bedrag={openstaand} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__openstaandRelatie']}
                    formatterComponent={(props) => {
                      const rij: IRelatie = props.row;

                      const openstaand = _.sum(
                        rij.boekingsregels
                          .filter((x) => x.RelID === rij.RelID)
                          .map((x) => x.Bedrag),
                      );

                      // const openstaand = _.sum(rij.facturen.map((x) => x.Openstaand));
                      return <FormatteerBedrag bedrag={openstaand} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__aantalFacturen']}
                    formatterComponent={(props) => {
                      const rij: IRelatie = props.row;
                      return <span>{rij.facturen.length}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__oudsteFactuurdatum']}
                    formatterComponent={(props) => {
                      const rij: IRelatie = props.row;
                      const datum = new Date(_.min(rij.facturen.map((x) => x.Factuurdatum)));
                      return <span>{format(datum, 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <RowDetailState defaultExpandedRowIds={[]} />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable
                    estimatedRowHeight={43}
                    messages={geenData}
                    columnExtensions={relatieKolomExtensies}
                    rowComponent={(rowProps) => {
                      const rij: IRelatie = rowProps.row;
                      // if (rij.Ophouden) {
                      //   return <OphoudenTr nietKleuren={false}>{rowProps.children}</OphoudenTr>;
                      // }

                      return <tr>{rowProps.children}</tr>;
                    }}
                  />

                  <TableColumnResizing defaultColumnWidths={relatieKolomBreedtes} />
                  <TableHeaderRow showSortingControls />

                  <TableRowDetail
                    contentComponent={RowDetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}

            {urlState.factuurinfoDialoogState !== null && (
              <FactuurinfoDialoog
                open
                inkFactID={urlState.factuurinfoDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('factuurinfoDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('factuurinfoDialoogState', null)}
              />
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Betalen);
