import React from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router';
import useUrlState from '../../../core/useUrlState';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { Helmet } from 'react-helmet';
import WhatsAppWerkbladV2 from '../../../components/communicatie/WhatsAppWerkbladV2';
import { ZoekenFocus } from '../../../components/communicatie/WhatsAppWerkbladV2/helpers';
import { EWhatsappTabblad } from '../../../components/communicatie/WhatsAppWerkbladV2/types';

// tslint:disable-next-line:no-empty-interface
interface IProps extends RouteComponentProps<{}> {}

interface IUrlState {
  tabblad: EWhatsappTabblad;
  chatSessieID: number | null;
  zoekterm: string;
  zoekenFocus: ZoekenFocus | null;
}

const defaultUrlState: IUrlState = {
  tabblad: EWhatsappTabblad.Nieuw,
  chatSessieID: null,
  zoekterm: '',
  zoekenFocus: null,
};

const WhatsApp: React.FC<IProps> = observer((props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  return (
    <>
      <Helmet>
        <title>WhatsApp</title>
      </Helmet>
      <div
        className="d-flex flex-column flex-fill w-100 h-100"
        style={{ backgroundColor: Kleur.Wit }}
      >
        <WhatsAppWerkbladV2
          tabblad={urlState.tabblad}
          onTabbladChange={(tabblad) => setUrlStateSync('tabblad', tabblad)}
          chatsessieID={urlState.chatSessieID}
          onChatsessieIDChange={(x) => setUrlStateSync('chatSessieID', x)}
          zoekterm={urlState.zoekterm}
          onZoektermChange={(x) => setUrlStateSync('zoekterm', x)}
          zoekenFocus={urlState.zoekenFocus}
          onZoekenFocusChange={(x) => setUrlStateSync('zoekenFocus', x)}
        />
      </div>
    </>
  );
});

export default WhatsApp;
