import {
  IMuterenProductsoortLandingspaginaParams,
  IMuterenProductsoortLandingspaginaResult,
  IOphalenProductsoortLandingspaginasParams,
  IOphalenProductsoortLandingspaginasResult,
  IVerwijderenProductsoortLandingspaginaParams,
  IVerwijderenProductsoortLandingspaginaResult,
} from '../../../../../../shared/src/api/v2/product/soort/landingspagina';
import api from '../../../index';

const landingspagina = {
  ophalenLandingspaginas: async (
    params: IOphalenProductsoortLandingspaginasParams,
  ): Promise<IOphalenProductsoortLandingspaginasResult> => {
    return await api.post('/v2/product/soort/landingspagina/ophalen-landingspaginas', params);
  },
  muterenLandingspagina: async (
    params: IMuterenProductsoortLandingspaginaParams,
  ): Promise<IMuterenProductsoortLandingspaginaResult> => {
    return await api.post('/v2/product/soort/landingspagina/muteren-landingspagina', params);
  },
  verwijderenLandingspagina: async (
    params: IVerwijderenProductsoortLandingspaginaParams,
  ): Promise<IVerwijderenProductsoortLandingspaginaResult> => {
    return await api.post('/v2/product/soort/landingspagina/verwijderen-landingspagina', params);
  },
};

export default landingspagina;
