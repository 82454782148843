import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import ContactSelectie, {
  EType,
} from '../../../../../../../../components/formulier/ContactSelectie';
import nameOf from '../../../../../../../../core/nameOf';
import { EMutatiebron } from '../../../../../../../../bedrijfslogica/enums';
interface IFormikValues {
  cntRdOvdID: number | null;
  persID: number | null;
  bevestigingSturen: boolean;
}

interface IProps extends IDialoogProps<null> {
  relID: number;
  cntIDs: number[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  cntRdOvdID: 'Reden',
  persID: 'Aanvrager',
  bevestigingSturen: 'Bevestiging sturen naar aanvrager',
};

const AanbiedenOvernameDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, relID, cntIDs } = props;
  const { overdrachtStore, klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (overdrachtStore.redenenAanbieding !== null) {
      return;
    }

    overdrachtStore.ophalenRedenenAanbieding();
  }, [overdrachtStore.redenenAanbieding]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      setHasSubmitted(true);
      // Controle stap validatie (voor foutmeldingen na OK)
      const errors = await actions.validateForm(values);
      const hasError = Object.keys(errors).some((key) => (errors as any)[key] !== null);
      if (hasError) {
        return;
      }
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <>
                <span>Geselecteerde contracten aanbieden voor overname?</span>
                {values.bevestigingSturen && (
                  <span>
                    <br /> <br />
                    Er wordt direct een bevestiging verstuurd
                  </span>
                )}
              </>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        setHasSubmitted(false);
        actions.setSubmitting(false);
        return;
      }

      const params = {
        relID,
        cntIDs,
        persID: values.persID,
        cntRdOvdID: values.cntRdOvdID,
        mutatiebron: EMutatiebron.Intern,
      };
      const checkData = await api.v2.contract.aanbieding.checkAanbiedenContracten(params);

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const aanbiedenResult = await api.v2.contract.aanbieding.aanbiedenContracten(params);

      if (values.bevestigingSturen) {
        await api.v2.contract.aanbieding.versturenOverzichtAangebodenContracten({
          relID: props.relID,
          persID: values.persID!,
        });
      }

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess, relID, cntIDs, setHasSubmitted],
  );

  const redenenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (overdrachtStore.redenenAanbieding === null) {
      return null;
    }

    return overdrachtStore.redenenAanbieding.map(
      (reden): IOptie<number> => ({
        id: reden.CntRdOvdID,
        label: reden.Naam,
      }),
    );
  }, [overdrachtStore.redenenAanbieding]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (klantkaartStore.relatie === null) {
      return null;
    }
    return {
      cntRdOvdID: null,
      persID: klantkaartStore.relatie.persoon!.PersID,
      bevestigingSturen: true,
    };
  }, []);

  // const validatie = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
  //   const errors: FormikErrors<IFormikValues> = {};

  //   return errors;
  // }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        persID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.persID,
          }),
        ),
        cntRdOvdID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.cntRdOvdID,
          }),
        ),
      }),
    [],
  );

  return (
    <Modal show={open}>
      <ModalHeader>
        <ModalTitle>Aanbieden overname</ModalTitle>
      </ModalHeader>
      {initieleWaarden !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initieleWaarden}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label className="ml-2 mt-1">{veldnamen.persID}</label>
                        <Field
                          name="persID"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <ContactSelectie
                                  alleenVoorRelIDs={[relID]}
                                  value={
                                    field.value === null
                                      ? null
                                      : {
                                          orgID: null,
                                          persID: field.value,
                                        }
                                  }
                                  onChange={(x) =>
                                    fieldProps.form.setFieldValue(
                                      field.name,
                                      x?.persoon?.PersID ?? null,
                                    )
                                  }
                                  options={{
                                    types: [EType.Persoon],
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.cntRdOvdID}</label>
                        <Field
                          name="cntRdOvdID"
                          render={(fieldProps: FieldProps<IFormikValues>) => (
                            <>
                              <Combobox
                                geselecteerd={
                                  fieldProps.field.value === null ? null : fieldProps.field.value
                                }
                                opties={redenenOpties === null ? [] : redenenOpties}
                                onSelectieChange={(id) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, id)
                                }
                                legeOptieTonen
                                options={{
                                  legeOptieTekst: 'Geen reden',
                                }}
                              />
                              <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                            </>
                          )}
                        />
                      </div>

                      <div className="col-12 mt-3 d-flex">
                        <Field
                          name={nameOf<IFormikValues>('bevestigingSturen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                            );
                          }}
                        />
                        <label className="ml-2">{veldnamen.bevestigingSturen}</label>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Modal>
  );
});

export default AanbiedenOvernameDialoog;
