import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import IDialoogProps from '../../../../core/IDialoogProps';
import { RootStoreContext } from '../../../../stores/RootStore';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import nameof from '../../../../core/nameOf';
import api from '../../../../api';
import {
  IOphalenOpdrachtenResultElement,
  IWijzigenInkoopfactuurInOpdrachtParams,
} from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import BedragInput from '../../../formulier/BedragInput';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';

interface IProps extends IDialoogProps<null> {
  bankOpdID: number;
  inkFactID: number;
}

interface IFormikValues {
  bedrag: number;
  bedragKorting: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  bedrag: 'Bedrag waarvoor de factuur in opdracht zit',
  bedragKorting: 'Toegepaste korting',
};

const WijzigenAccountDialoog: React.FC<IProps> = (props) => {
  const { open, onSuccess, onAnnuleren, dialoogIndex } = props;
  const {} = useContext(RootStoreContext);

  const [bankopdracht, setBankopdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  const ophalenBankopdracht = useCallback(async () => {
    if (props.bankOpdID === null) {
      return;
    }

    const bankopdracht = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.bankOpdID],
            },
          ],
        },
      })
    ).opdrachten[0];

    setBankopdracht(bankopdracht);
  }, []);

  useEffect(() => {
    ophalenBankopdracht();
  }, [ophalenBankopdracht]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (bankopdracht === null) {
      return null;
    }

    const factuur = bankopdracht.crediteurFacturen.find((x) => x.InkFactID === props.inkFactID)!;

    return {
      bedrag: factuur.Bedrag,
      bedragKorting: factuur.BedragKorting,
    };
  }, [bankopdracht]);

  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      const params: IWijzigenInkoopfactuurInOpdrachtParams = {
        bankOpdID: props.bankOpdID,
        inkFactID: props.inkFactID,
        bedrag: values.bedrag,
        bedragKorting: values.bedragKorting,
      };

      const result = await api.v2.bank.opdracht.wijzigenInkoopfactuurInOpdracht(params);

      onSuccess(null);
    },
    [props.bankOpdID, props.inkFactID, onSuccess],
  );

  const validationSchema = useCallback(() => {}, []);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'sm' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen inkoopfactuur</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.bedrag}</label>
                        <Field
                          name={nameof<IFormikValues>('bedrag')}
                          render={(x: FieldProps<IFormikValues>) => {
                            return (
                              <>
                                <div>
                                  <BedragInput
                                    value={x.field.value}
                                    onChange={(bedrag) => {
                                      x.form.setFieldValue(x.field.name, bedrag);
                                    }}
                                  />
                                </div>
                                {/* <FormikVeldFout fieldProps={x} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.bedragKorting}</label>
                        <Field
                          name={nameof<IFormikValues>('bedragKorting')}
                          render={(x: FieldProps<IFormikValues>) => {
                            return (
                              <>
                                <div>
                                  <BedragInput
                                    value={x.field.value}
                                    onChange={(bedrag) => {
                                      x.form.setFieldValue(x.field.name, bedrag);
                                    }}
                                  />
                                </div>
                                {/* <FormikVeldFout fieldProps={x} /> */}
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default WijzigenAccountDialoog;
