import styled from 'styled-components';
import style from 'dom-helpers/style';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 10px;
`;

export const Lijn = styled.div`
  background-color: #cbcbcb;
  height: 1px;
  flex: 1;
`;

export const TijdContainer = styled.div`
  margin: 0 15px;
  color: #a2a2a2;
`;
