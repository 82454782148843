import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import {
  IOphalenRelatieBetrekkingenResultElement,
  IToevoegenRelatieBetrekkingParams,
  IWijzigenRelatieBetrekkingParams,
} from '../../../../../shared/src/api/v2/relatie/contactpersoon';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import api from '../../../api';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import nameof from '../../../core/nameOf';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../stores/CheckStore';

export interface IRelatieBetrekkingMuterenDialoogOutput {
  betrekking: IOphalenRelatieBetrekkingenResultElement;
}

interface IProps extends IDialoogProps<IRelatieBetrekkingMuterenDialoogOutput> {
  id: number | null;
  initialValues?: IFormikValues;
}

interface IFormikValues {
  naam: string;
}

const RelatieBetrekkingMuterenDialoog = observer(
  (props: IProps): JSX.Element => {
    const { checkStore } = useContext(RootStoreContext);

    const [betrekking, setBetrekking] = useState<
      IRemoteData<IOphalenRelatieBetrekkingenResultElement | null>
    >(createPendingRemoteData());
    const bepalenBetrekking = useCallback(async () => {
      if (props.id === null) {
        setBetrekking(createReadyRemoteData(null));
        return;
      }
      const result = await api.v2.relatie.ophalenRelatieBetrekkingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.id],
            },
          ],
        },
      });
      setBetrekking(createReadyRemoteData(result.betrekkingen[0]));
    }, [props.id]);
    useEffect(() => {
      bepalenBetrekking();
    }, [bepalenBetrekking]);

    const handleSubmit = useCallback(
      async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
        actions.setSubmitting(true);

        if (props.id === null) {
          const params: IToevoegenRelatieBetrekkingParams = {
            naam: values.naam.trim(),
          };
          const checkData = await api.v2.relatie.checkToevoegenRelatieBetrekking(params);
          if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
            actions.setSubmitting(false);
            return;
          }

          const result = await api.v2.relatie.toevoegenRelatieBetrekking(params);
          props.onSuccess({
            betrekking: result.relatieBetrekking,
          });
          actions.setSubmitting(false);
          return;
        }

        const params: IWijzigenRelatieBetrekkingParams = {
          id: props.id!,
          naam: values.naam.trim(),
        };
        const checkData = await api.v2.relatie.checkWijzigenRelatieBetrekking(params);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        const result = await api.v2.relatie.wijzigenRelatieBetrekking(params);
        props.onSuccess({
          betrekking: result.relatieBetrekking,
        });

        actions.setSubmitting(false);
      },
      [props.id, props.onSuccess],
    );
    const initialValues = useMemo<IRemoteData<IFormikValues>>(() => {
      if (props.initialValues !== undefined) {
        return createReadyRemoteData(props.initialValues);
      }

      if (betrekking.state === ERemoteDataState.Pending) {
        return createPendingRemoteData();
      }
      if (betrekking.data !== null) {
        return createReadyRemoteData({
          naam: betrekking.data!.Naam,
        });
      }

      return createReadyRemoteData({
        naam: '',
      });
    }, [betrekking, props.initialValues]);

    return (
      <Dialoog index={props.dialoogIndex ?? 0}>
        <ModalHeader>
          <ModalTitle>Relatie betrekking {props.id === null ? 'toevoegen' : 'wijzigen'}</ModalTitle>
        </ModalHeader>
        {initialValues.state === ERemoteDataState.Pending ? (
          <ModalBody className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </ModalBody>
        ) : (
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues.data!}
            isInitialValid={props.initialValues !== undefined}
            render={(formikProps) => <Formulier {...props} formikProps={formikProps} />}
          />
        )}
      </Dialoog>
    );
  },
);

const Formulier = (props: IProps & { formikProps: FormikProps<IFormikValues> }): JSX.Element => {
  const { formikProps } = props;
  const { isSubmitting, isValid, submitForm } = formikProps;

  return (
    <>
      <ModalBody className="p-3">
        <label>Naam</label>
        <Field
          name={nameof<IFormikValues>('naam')}
          render={({ field, form }: FieldProps<IFormikValues>) => (
            <input {...field} className="form-control" autoFocus />
          )}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default RelatieBetrekkingMuterenDialoog;
