import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { EFilter, ExportMedium, RapportageType } from '../types';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { actieveExportMediaPerRapportageType, actieveFiltersPerRapportageType } from '../helpers';
import MaandSelectie, { EMaand, EWeergave } from '../../../../components/formulier/MaandSelectie';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  background-color: ${Kleur.HeelLichtGrijs};
  border-right: 1px solid ${Kleur.LichtGrijs};
  padding: 20px;
`;

const Kop = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const FilterKop = styled.span`
  margin-bottom: 5px;
  font-weight: bolder;
`;

interface IProps {
  rapportageType: RapportageType;
  onRapportageTypeChange: (rapportageType: RapportageType) => void;
  peildatum: Date;
  onPeildatumChange: (peildatum: Date) => void;
  jaar: number;
  onJaarChange: (jaar: number) => void;
  maandVem: EMaand;
  onMaandVemChange: (maandVem: EMaand) => void;
  maandTem: EMaand;
  onMaandTemChange: (maandTem: EMaand) => void;
  exportMedium: ExportMedium;
  onExportMediumChange: (exportMedium: ExportMedium) => void;
  isBezig: boolean;
  onExporteren: () => void;
}

const Links = (props: IProps) => {
  const jaarOpties = useMemo<IOptie<number>[]>(() => {
    const now = new Date();
    const jaar = now.getFullYear();
    return new Array(10).fill(null).map((_, i) => ({
      id: jaar + 1 - i,
      label: (jaar + 1 - i).toString(),
    }));
  }, []);

  const actieveFilters = actieveFiltersPerRapportageType[props.rapportageType];

  const exportMediumOpties = useMemo<IOptie<ExportMedium>[]>(() => {
    const alleExportOpties: IOptie<ExportMedium>[] = [
      {
        id: 'PDF',
        label: 'PDF',
      },
      {
        id: 'CSV',
        label: 'CSV',
      },
    ];
    return alleExportOpties.filter((x) =>
      actieveExportMediaPerRapportageType[props.rapportageType].includes(x.id),
    );
  }, [props.rapportageType]);

  return (
    <Root>
      <Kop>Type</Kop>
      <Combobox
        geselecteerd={props.rapportageType}
        opties={[
          {
            id: 'OPENSTAANDE_DEBITEUREN',
            label: 'Openstaande debiteuren',
          },
          {
            id: 'OPENSTAANDE_CREDITEUREN',
            label: 'Openstaande crediteuren',
          },
          {
            id: 'GEGEVENS_TEN_BEHOEVE_VAN_JAARWERK',
            label: 'Gegevens ten behoeve van jaarwerk',
          },
          {
            id: 'LIJST_PRODUCTEN_UIT_BEDRIJF',
            label: 'Lijst Producten Uit bedrijf',
          },
          {
            id: 'LIJST_ACTIVAREGISTER',
            label: 'Lijst activaregister',
          },
          {
            id: 'WINST_EN_VERLIES_REKENING',
            label: 'Winst- en verliesrekening',
          },
        ]}
        onSelectieChange={(x) => {
          const beschikbareExportMediaVoorNieuwRapportageType =
            actieveExportMediaPerRapportageType[x!];
          if (!beschikbareExportMediaVoorNieuwRapportageType.includes(props.exportMedium)) {
            props.onExportMediumChange(beschikbareExportMediaVoorNieuwRapportageType[0]);
          }
          props.onRapportageTypeChange(x!);
        }}
        className="mt-2"
        disabled={props.isBezig}
      />

      <Kop className="mt-3">Filters</Kop>

      {actieveFilters[EFilter.Peildatum] && (
        <>
          <FilterKop className="mt-2">Peildatum tot</FilterKop>
          <DatumKiezer
            waarde={props.peildatum}
            onGewijzigd={(x) => props.onPeildatumChange(x!)}
            disabled={props.isBezig}
          />
        </>
      )}

      {actieveFilters[EFilter.Jaar] && (
        <>
          <FilterKop className="mt-2">Jaar</FilterKop>
          <Combobox
            geselecteerd={props.jaar}
            opties={jaarOpties}
            onSelectieChange={(x) => props.onJaarChange(x!)}
            disabled={props.isBezig}
          />
        </>
      )}

      {actieveFilters[EFilter.Periode] && (
        <>
          <FilterKop className="mt-2">Periode</FilterKop>
          <MaandSelectie
            maand={props.maandVem}
            onMaandChange={(x) => {
              const maand = x!;
              if (maand > props.maandTem) {
                props.onMaandTemChange(maand);
              }
              props.onMaandVemChange(maand);
            }}
            weergave={EWeergave.Tekst}
            // opties={maandVemOpties}
            disabled={props.isBezig}
          />
          <span className="ml-2 mt-1 mb-1">t/m</span>
          <MaandSelectie
            maand={props.maandTem}
            onMaandChange={(x) => {
              const maand = x!;
              if (maand < props.maandVem) {
                props.onMaandVemChange(maand);
              }
              props.onMaandTemChange(maand);
            }}
            weergave={EWeergave.Tekst}
            // opties={maandTemOpties}
            disabled={props.isBezig}
          />
        </>
      )}

      <div className="flex-fill" />

      <Kop>Export</Kop>
      <Combobox
        geselecteerd={props.exportMedium}
        opties={exportMediumOpties}
        onSelectieChange={(x) => props.onExportMediumChange(x!)}
        className="mt-2"
      />
      <button
        className="btn btn-primary mt-3"
        onClick={props.onExporteren}
        disabled={props.isBezig}
      >
        Exporteren
      </button>
    </Root>
  );
};

export default Links;
