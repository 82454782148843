import api from '../../../index';
import {
  IToevoegenGeneriekResult,
  ISelectieGeneriekParams,
  ILeegGeneriekResult,
  IOphalenGeneriekParams,
} from '../../../../../../shared/src/api/v2/generiek';

import { ISponsorCommissie } from '../../../../../../shared/src/api/v2/sponsoringv2/event';

const commissie = {
  ophalenCommissies: async (params: IOphalenGeneriekParams): Promise<ISponsorCommissie[]> => {
    return await api.post('/v2/sponsoringv2/commissie/ophalen-commissies', params);
  },
  toevoegenOfWijzigenCommissie: async (
    params: ISponsorCommissie,
  ): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/commissie/toevoegen-of-wijzigen-commissie', params);
  },
  verwijderenCommissies: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/commissie/verwijderen-commissies', params);
  },
};

export default commissie;
