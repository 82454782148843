import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ISjabloonContext } from '../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableEditColumnCommandComponent,
  DXTableEditColumnCellComponent,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import MuteerSjabCtxFormValueDialoog, { IFormikValues } from './MuteerSjabCtxFormValueDialoog';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import * as _ from 'lodash';

export interface ISjabCtxFormValue {
  sjabCtxID: number;
  alias?: string;
  isOptioneel: boolean;
}

interface IProps {
  contexten: ISjabloonContext[];
  value: ISjabCtxFormValue[];
  onChange: (value: ISjabCtxFormValue[]) => void;
}

const SjabCtxsVeld = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback(
    (row: ISjabCtxFormValue) => `${row.sjabCtxID}-${row.alias === undefined ? '' : row.alias}`,
    [],
  );
  const kolommen = useMemo<TypedColumn<ISjabCtxFormValue>[]>(
    () => [
      {
        name: '__naam' as any,
        title: 'Naam',
      },
      {
        name: '__optioneel' as any,
        title: 'Opt.',
      },
      {
        name: '__sleutel',
        title: 'Sleutel',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<ISjabCtxFormValue>[]>(
    () => [
      {
        columnName: '__naam' as any,
        width: 150,
      },
      {
        columnName: '__optioneel' as any,
        width: 100,
      },
      {
        columnName: '__sleutel',
        width: 100,
      },
    ],
    [],
  );
  const [toevoegenTonen, setToevoegenTonen] = useState(false);
  const [wijzigenKey, setWijzigenKey] = useState<string | null>(null);

  const wijzigenInitialValues = useMemo<IFormikValues | null>(() => {
    if (wijzigenKey === null) {
      return null;
    }
    const item = props.value.find((x) => keyExtractor(x) === wijzigenKey)!;
    return {
      sjabCtxID: item.sjabCtxID,
      alias: item.alias || '',
      isOptioneel: item.isOptioneel,
    };
  }, [wijzigenKey]);

  return (
    <>
      <GridStyleWrapper rowAmount={props.value.length} maxHeight={300}>
        <Grid getRowId={keyExtractor} rows={props.value} columns={kolommen}>
          <DataTypeProvider
            for={['__naam']}
            formatterComponent={(formatterProps) => {
              const row: ISjabCtxFormValue = formatterProps.row;
              const context = props.contexten.find((x) => x.SjabCtxID === row.sjabCtxID)!;
              return <span>{context.Naam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__optioneel']}
            formatterComponent={(formatterProps) => {
              const row: ISjabCtxFormValue = formatterProps.row;
              return (
                <span style={{ fontWeight: row.isOptioneel ? 'bold' : undefined }}>
                  {row.isOptioneel ? 'Ja' : 'Nee'}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={['__sleutel']}
            formatterComponent={(formatterProps) => {
              const row: ISjabCtxFormValue = formatterProps.row;
              if (row.alias === undefined || row.alias === null) {
                const context = props.contexten.find((x) => x.SjabCtxID === row.sjabCtxID)!;
                return <span>{context.Sleutel}</span>;
              }
              return <b>{row.alias}</b>;
            }}
          />

          <EditingState
            onCommitChanges={(changeset) => {
              if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                const newValue = props.value.filter(
                  (sjabCtx) => !changeset.deleted!.includes(keyExtractor(sjabCtx)),
                );
                props.onChange(newValue);
              }
            }}
            onAddedRowsChange={() => setToevoegenTonen(true)}
            onEditingRowIdsChange={(ids) => {
              const key = ids[ids.length - 1] as string;
              setWijzigenKey(key);
            }}
          />

          <VirtualTable messages={{ noData: 'Geen contexten opgegeven' }} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <TableHeaderRow />
          <TableEditColumn
            width={65}
            commandComponent={DXTableEditColumnCommandComponent}
            cellComponent={DXTableEditColumnCellComponent}
            showAddCommand
            showDeleteCommand
            showEditCommand
          />
        </Grid>
      </GridStyleWrapper>
      {toevoegenTonen && (
        <MuteerSjabCtxFormValueDialoog
          open
          onSuccess={async (data) => {
            if (data.alias !== undefined && props.value.some((x) => x.alias === data.alias)) {
              await checkStore.controleren({
                checkData: {
                  errors: ['Alias mag niet dubbel voorkomen'],
                  warnings: [],
                },
              });
              return;
            }

            const newKey = keyExtractor(data);
            const existingKeys = props.value.map((x) => keyExtractor(x));
            if (existingKeys.some((key) => key === newKey)) {
              await checkStore.controleren({
                checkData: {
                  errors: [
                    data.alias === undefined
                      ? 'Context type komt al voor (zonder alias)'
                      : 'Context type in combinatie met alias komt al voor',
                  ],
                  warnings: [],
                },
              });
              return;
            }

            const newValue = [...props.value, data];
            props.onChange(newValue);
            setToevoegenTonen(false);
          }}
          onAnnuleren={() => setToevoegenTonen(false)}
          contexten={props.contexten}
        />
      )}
      {wijzigenKey !== null && (
        <MuteerSjabCtxFormValueDialoog
          open
          onSuccess={async (data) => {
            if (
              data.alias !== undefined &&
              props.value.some((x) => x.alias === data.alias && keyExtractor(x) !== wijzigenKey)
            ) {
              await checkStore.controleren({
                checkData: {
                  errors: ['Alias mag niet dubbel voorkomen'],
                  warnings: [],
                },
              });
              return;
            }

            const newValue = props.value.map((x) => {
              const key = keyExtractor(x);
              if (key === wijzigenKey) {
                return data;
              }
              return x;
            });
            const aantalVoorkomenZelfdeKey = _(
              newValue.map((x) => ({
                key: keyExtractor(x),
                data: x,
              })),
            )
              .groupBy('key')
              .values()
              .map((group: any) => ({ ...group[0], aantal: group.length }));

            if (aantalVoorkomenZelfdeKey.some((x: any) => x.aantal > 1)) {
              await checkStore.controleren({
                checkData: {
                  errors: [
                    data.alias === undefined
                      ? 'Context type komt al voor (zonder alias)'
                      : 'Context type in combinatie met alias komt al voor',
                  ],
                  warnings: [],
                },
              });
              return;
            }

            props.onChange(newValue);
            setWijzigenKey(null);
          }}
          onAnnuleren={() => setWijzigenKey(null)}
          initialValues={wijzigenInitialValues!}
          contexten={props.contexten}
        />
      )}
    </>
  );
});

export default SjabCtxsVeld;
