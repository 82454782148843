import api from '../../../..';
import {
  IOphalenGrootboekenEerderGebruiktVoorRelatieParams,
  IOphalenGrootboekenEerderGebruiktVoorRelatieResult,
  IOphalenGrootboekenParams,
  IOphalenGrootboekenResult,
  IToevoegenGrootboekParams,
  IToevoegenGrootboekResult,
  IWijzigenGrootboekParams,
  IWijzigenGrootboekResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const grootboek = {
  // ophalen
  ophalenGrootboeken: async (
    params: IOphalenGrootboekenParams,
  ): Promise<IOphalenGrootboekenResult> => {
    return await api.post('/v2/boeking/grootboek/ophalen-grootboeken', params);
  },
  ophalenGrootboekenEerderGebruiktVoorRelatie: async (
    params: IOphalenGrootboekenEerderGebruiktVoorRelatieParams,
  ): Promise<IOphalenGrootboekenEerderGebruiktVoorRelatieResult> => {
    return await api.post(
      '/v2/boeking/grootboek/ophalen-grootboeken-eerder-gebruikt-voor-relatie',
      params,
    );
  },

  // toevoegen
  checkToevoegenGrootboek: async (params: IToevoegenGrootboekParams): Promise<ICheckData> => {
    return await api.post('/v2/boeking/grootboek/check-toevoegen-grootboek', params);
  },
  toevoegenGrootboek: async (
    params: IToevoegenGrootboekParams,
  ): Promise<IToevoegenGrootboekResult> => {
    return await api.post('/v2/boeking/grootboek/toevoegen-grootboek', params);
  },

  // wijzigen
  checkWijzigenGrootboek: async (params: IWijzigenGrootboekParams): Promise<ICheckData> => {
    return await api.post('/v2/boeking/grootboek/check-wijzigen-grootboek', params);
  },
  wijzigenGrootboek: async (
    params: IWijzigenGrootboekParams,
  ): Promise<IWijzigenGrootboekResult> => {
    return await api.post('/v2/boeking/grootboek/wijzigen-grootboek', params);
  },
};

export default grootboek;
