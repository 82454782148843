import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { IOphalenNogTeKoppelenProductenResultElement } from '../../../../../../../../../shared/src/api/v2/inkoopfactuur';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';

interface IProps {
  producten: IOphalenNogTeKoppelenProductenResultElement[];
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
}

export interface IRegel extends IOphalenNogTeKoppelenProductenResultElement {}

const GegroepeerdWeergave: React.FC<IProps> = (props) => {
  const producten = props.producten;

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'DatumInBedrijf',
        title: 'In bedrijf',
      },
      {
        name: '__relatie' as any,
        title: 'Leverancier',
      },
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: 'Inkoopreferentie',
        title: 'Ink.ref.',
      },
      {
        name: 'Aantal',
        title: 'Aantal',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Merknaam',
        width: 85,
      },
      {
        columnName: 'Typenaam',
        width: 175,
      },
      {
        columnName: 'DatumInBedrijf',
        width: 100,
      },
      {
        columnName: 'Inkoopreferentie',
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 125,
      },
      {
        columnName: 'Aantal',
        width: 90,
      },
    ],
    [],
  );

  return (
    <>
      <GridStyleWrapper rowAmount={producten.length}>
        <Grid columns={kolommen} rows={producten}>
          <DataTypeProvider
            for={['DatumInBedrijf']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              return <span>{format(new Date(rij.DatumInBedrijf), 'dd-MM-yyyy')}</span>;
            }}
          />

          <DataTypeProvider
            for={['__relatie']}
            formatterComponent={(props) => {
              return (
                <RelatieVisualisatie
                  relID={props.row.RelID}
                  options={{ geenLinkToepassen: true }}
                />
              );
            }}
          />
          <SortingState defaultSorting={[]} />
          <IntegratedSorting />

          <VirtualTable messages={{ noData: 'Geen producten' }} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

          <TableHeaderRow showSortingControls />

          <SelectionState
            selection={props.selectie}
            onSelectionChange={(x) => props.onSelectieChange(x as number[])}
          />
          <TableSelection cellComponent={DXTableCheckboxComponent} />
        </Grid>
      </GridStyleWrapper>
    </>
  );
};

export default GegroepeerdWeergave;
