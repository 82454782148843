import React, { useContext, useMemo, useState } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, InfoContext } from '../index';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import VeldWeergave from '../../../../../components/formulier/VeldWeergave';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import GegevensLayout from '../../../../../components/layout/GegevensLayout';
import { IInventarisatieInfoExternRegel } from '../../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import ASPTabel from '../../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  ESorteringModus,
  IAspKolomSorteringItem,
} from '../../../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';

enum ETabelKolom {
  Typenaam,
  Inslagdatum,
  Nieuw,
  Referentiecode,
  Partij,
  Partijnummer,
  Saldo,
}

const ExternTab: React.FC<IProps> = (props) => {
  const { inventarisatieInfo } = useContext<IContext>(InfoContext);

  const tabelKolommen = useMemo<Array<ASPKolom<ETabelKolom, IInventarisatieInfoExternRegel>>>(
    () => [
      {
        key: ETabelKolom.Typenaam,
        label: 'Typenaam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => rij.Typenaam,
      },
      {
        key: ETabelKolom.Nieuw,
        label: 'N/G',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 60,
        renderer: (rij) => (rij.Nieuw ? 'N' : 'G'),
      },
      {
        key: ETabelKolom.Referentiecode,
        label: 'Ref.code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (rij) => rij.Referentiecode,
      },
      {
        key: ETabelKolom.Saldo,
        label: 'Saldo',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (rij) => rij.Saldo,
      },
      {
        key: ETabelKolom.Inslagdatum,
        label: 'Inslagdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (rij) =>
          rij.Inslagdatum !== null ? format(new Date(rij.Inslagdatum), 'dd-MM-yyyy') : '',
      },
      {
        key: ETabelKolom.Partij,
        label: 'Partij',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 185,
        renderer: (rij) => rij.Partij,
      },
      {
        key: ETabelKolom.Partijnummer,
        label: 'Partij No',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => rij.Partijnummer,
      },
    ],
    [],
  );

  const [sortering, setSortering] = useState<IAspKolomSorteringItem<ETabelKolom>[]>([
    {
      key: ETabelKolom.Typenaam,
      sortering: ESortering.Ascending,
    },
    {
      key: ETabelKolom.Nieuw,
      sortering: ESortering.Ascending,
    },
    {
      key: ETabelKolom.Referentiecode,
      sortering: ESortering.Ascending,
    },
  ]);

  return (
    <div className="d-flex flex-fill flex-column">
      <div style={{ height: 500 }} className="d-flex flex-column">
        {inventarisatieInfo === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ASPTabel
            keyExtractor={(row) => row.ID}
            rijen={inventarisatieInfo.extern.regels}
            kolommen={tabelKolommen}
            sortering={sortering}
            onSorteringChange={setSortering}
            sorteringOpties={{
              modus: ESorteringModus.Whitelist,
              kolomOpties: {
                [ETabelKolom.Typenaam]: {
                  magSorteren: true,
                },
                [ETabelKolom.Nieuw]: {
                  magSorteren: true,
                },
              },
            }}
            lokaalSorteren
          />
        )}
      </div>
    </div>
  );
};

export default ExternTab;
