import {
  IVersturenProductdocumentatieParams,
  IVersturenProductdocumentatieResult,
  IOphalenDocumentenParams,
  IOphalenDocumentenResult,
  IToevoegenDocumentParams,
  IToevoegenDocumentResult,
  IWijzigenDocumentParams,
  IWijzigenDocumentResult,
  IVerwijderenDocumentenParams,
  IVerwijderenDocumentenResult,
  IOphalenDocumentsoortenParams,
  IOphalenDocumentsoortenResult,
  IToevoegenProducttypenParams,
  IToevoegenProducttypenResult,
  IVerwijderenProducttypenParams,
  IVerwijderenProducttypenResult,
} from '../../../../../shared/src/api/v2/product/documentatie';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const productdocumentatie = {
  versturenProductdocumentatie: async (
    params: IVersturenProductdocumentatieParams,
  ): Promise<IVersturenProductdocumentatieResult> => {
    return await api.post('v2/product/documentatie/versturen-productdocumentatie', params);
  },
  ophalenDocumenten: async (
    params: IOphalenDocumentenParams,
  ): Promise<IOphalenDocumentenResult> => {
    return await api.post('v2/product/documentatie/ophalen-documenten', params);
  },
  checkToevoegenDocument: async (params: IToevoegenDocumentParams): Promise<ICheckData> => {
    return await api.post('v2/product/documentatie/check-toevoegen-document', params);
  },
  toevoegenDocument: async (
    params: IToevoegenDocumentParams,
  ): Promise<IToevoegenDocumentResult> => {
    return await api.post('v2/product/documentatie/toevoegen-document', params);
  },
  checkWijzigenDocument: async (params: IWijzigenDocumentParams): Promise<ICheckData> => {
    return await api.post('v2/product/documentatie/check-wijzigen-document', params);
  },
  wijzigenDocument: async (params: IWijzigenDocumentParams): Promise<IWijzigenDocumentResult> => {
    return await api.post('v2/product/documentatie/wijzigen-document', params);
  },
  checkVerwijderenDocumenten: async (params: IVerwijderenDocumentenParams): Promise<ICheckData> => {
    return await api.post('v2/product/documentatie/check-verwijderen-documenten', params);
  },
  verwijderenDocumenten: async (
    params: IVerwijderenDocumentenParams,
  ): Promise<IVerwijderenDocumentenResult> => {
    return await api.post('v2/product/documentatie/verwijderen-documenten', params);
  },
  ophalenDocumentsoorten: async (
    params: IOphalenDocumentsoortenParams,
  ): Promise<IOphalenDocumentsoortenResult> => {
    return await api.post('v2/product/documentatie/ophalen-documentsoorten', params);
  },
  checkToevoegenProducttypen: async (params: IToevoegenProducttypenParams): Promise<ICheckData> => {
    return await api.post('v2/product/documentatie/check-toevoegen-producttypen', params);
  },
  toevoegenProducttypen: async (
    params: IToevoegenProducttypenParams,
  ): Promise<IToevoegenProducttypenResult> => {
    return await api.post('v2/product/documentatie/toevoegen-producttypen', params);
  },
  verwijderenProducttypen: async (
    params: IVerwijderenProducttypenParams,
  ): Promise<IVerwijderenProducttypenResult> => {
    return await api.post('v2/product/documentatie/verwijderen-producttypen', params);
  },
};

export default productdocumentatie;
