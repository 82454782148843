import React from 'react';
import LijstItemBasis from '../LijstItemBasis';
import { IAfbeeldingPreviewItem } from '../..';
import { AutoSizer } from 'react-virtualized';

interface IProps {
  previewItem: IAfbeeldingPreviewItem;
  isGeselecteerd: boolean;
  onGeselecteerd: () => void;
}

const AfbeeldingLijstItem = (props: IProps) => {
  return (
    <LijstItemBasis isGeselecteerd={props.isGeselecteerd} onGeselecteerd={props.onGeselecteerd}>
      <AutoSizer className="flex-fill d-flex flex-column w-100 h-100 align-items-center justify-content-center">
        {({ width, height }) => {
          const imgWidth = width * 0.8;
          const imgHeight = height * 0.8;

          return (
            <img
              src={props.previewItem.bestand.url}
              style={{ width: imgWidth, height: imgHeight, objectFit: 'cover' }}
            />
          );
        }}
      </AutoSizer>
    </LijstItemBasis>
  );
};

export default AfbeeldingLijstItem;
