import { makeObservable, observable } from 'mobx';
import api from '../api';

class StandaardWaardeStore {
  public taalID: number | null = null;
  public landID: number | null = null;

  constructor() {
    makeObservable(this, {
      taalID: observable,
    });
  }

  public ophalenTaalID = async () => {
    const result = (
      await api.v2.taal.ophalen({
        filterSchema: { filters: [{ naam: 'NAAM_ENUMS', data: ['TAAL_N'] }] },
      })
    )[0];
    this.taalID = result.TaalID;
  };

  public ophalenLandID = async () => {
    const result = await api.v2.land.ophalenStandaardland({});
    this.landID = result.LandID;
  };
}

export default StandaardWaardeStore;
