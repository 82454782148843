import api from '../../../index';
import {
  IMuterenRolXAspGebrParams,
  IMuterenRolXAspGebrResult,
  IOphalenRolXAspGebrsParams,
  IOphalenRolXAspGebrsResult,
} from '../../../../../../shared/src/api/v2/autorisatie/rolXAspGebr';

const gebruikers = {
  ophalenRolXGebruikers: async (
    params: IOphalenRolXAspGebrsParams,
  ): Promise<IOphalenRolXAspGebrsResult> => {
    return await api.post('/v2/autorisatie/rol-x-gebruikers/ophalen-rol-x-gebruikers', params);
  },
  muterenRolXAspGebr: async (
    params: IMuterenRolXAspGebrParams,
  ): Promise<IMuterenRolXAspGebrResult> => {
    return await api.post('/v2/autorisatie/rol-x-gebruikers/muteren-rol-x-gebruikers', params);
  },
};

export default gebruikers;
