import React, { useEffect, useMemo, useState } from 'react';
import { ERegelboekstukType, Regelboekstuk } from '../types';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../shared/src/api/v2/factuur';
import { IOphalenFacturenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import {
  IOphalenMutatieBestandenResult,
  IOphalenMutatiesResultElement,
} from '../../../../../../../shared/src/api/v2/bank/mutaties';
import GeenPreviewBeschikbaar from './GeenPreviewBeschikbaar';
import MeerdereLayout from './MeerdereLayout';
import PDFPreview from './previews/PDFPreview';
import { IOphalenBestandenResultElement } from '../../../../../../../shared/src/api/v2/bestand/bestand';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import GeenPreviewGekozen from './GeenPreviewGekozen';
import PDFLijstItem from './lijst/PDFLijstItem';
import api from '../../../../../api';
import AfbeeldingLijstItem from './lijst/AfbeeldingLijstItem';
import AfbeeldingPreview from './previews/AfbeeldingPreview';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../../shared/src/api/v2/debiteur/betalingsregeling';

export enum EPreviewType {
  PDF,
  Afbeelding,
}

export interface IPreviewItemBase {
  id: string;
}

export interface IPDFPreviewItem {
  type: EPreviewType.PDF;
  bestand: IOphalenBestandenResultElement;
}

export interface IAfbeeldingPreviewItem {
  type: EPreviewType.Afbeelding;
  bestand: IOphalenBestandenResultElement;
}

export type PreviewItem = IPreviewItemBase & (IPDFPreviewItem | IAfbeeldingPreviewItem);

interface IProps {
  geselecteerdID: string | null;
  onGeselecteerdIDChange: (id: string | null) => void;
  boekstuk: Regelboekstuk | null;
  facturenCache: Record<number, IOphalenFacturenBasisResultElement>;
  inkoopfacturenCache: Record<number, IOphalenFacturenResultElement>;
  bankmutatiesCache: Record<number, IOphalenMutatiesResultElement>;
  betalingsregelingenCache: Record<number, IOphalenBetalingsregelingenResultElement>;
}

const Preview = (props: IProps) => {
  const [mutatieBestandenResult, setMutatieBestandenResult] = useState<
    IRemoteData<IOphalenMutatieBestandenResult | null>
  >(createPendingRemoteData());
  const [bestanden, setBestanden] = useState<IRemoteData<IOphalenBestandenResultElement[] | null>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    if (props.boekstuk === null) {
      setMutatieBestandenResult(createReadyRemoteData(null));
      setBestanden(createReadyRemoteData(null));
      return;
    }
    setMutatieBestandenResult(createPendingRemoteData());
    setBestanden(createPendingRemoteData());

    (async () => {
      const boekstuk = props.boekstuk!;

      switch (boekstuk.type) {
        case ERegelboekstukType.Factuur: {
          break;
        }
        case ERegelboekstukType.Inkoopfactuur: {
          break;
        }
        case ERegelboekstukType.Bankmutatie: {
          const mutatieBestandenResult = await api.v2.bank.mutatie.ophalenMutatieBestanden({
            filterSchema: {
              filters: [
                {
                  naam: 'BANK_MUT_IDS',
                  data: [boekstuk.bankMutID],
                },
              ],
            },
          });
          setMutatieBestandenResult(createReadyRemoteData(mutatieBestandenResult));

          if (mutatieBestandenResult.mutatieBestanden.length === 0) {
            setBestanden(createReadyRemoteData([]));
            return;
          }

          const bestandenResult = await api.v2.bestand.ophalenBestanden({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: mutatieBestandenResult.mutatieBestanden.map((x) => x.BestandID),
                },
              ],
            },
          });
          setBestanden(createReadyRemoteData(bestandenResult.bestanden));
          break;
        }
      }
    })();
  }, [props.boekstuk]);

  const previewItems = useMemo<IRemoteData<PreviewItem[] | null>>(() => {
    if (props.boekstuk === null) {
      return createReadyRemoteData(null);
    }

    switch (props.boekstuk.type) {
      case ERegelboekstukType.Factuur: {
        const factuur = props.facturenCache[props.boekstuk.factID];
        if (factuur === undefined) {
          return createPendingRemoteData();
        }
        return createReadyRemoteData(null);
      }
      case ERegelboekstukType.Inkoopfactuur: {
        const inkoopfactuur = props.inkoopfacturenCache[props.boekstuk.inkFactID];
        if (inkoopfactuur === undefined) {
          return createPendingRemoteData();
        }
        const pdfBestanden = inkoopfactuur.bestanden.filter(
          (x) => x.MediaType === 'application/pdf',
        );
        return createReadyRemoteData(
          pdfBestanden.map((bestand) => ({
            type: EPreviewType.PDF,
            bestand,
            id: `pdf-${bestand.ID}`,
          })),
        );
      }
      case ERegelboekstukType.Bankmutatie: {
        const bankmutatie = props.bankmutatiesCache[props.boekstuk.bankMutID];
        if (
          bankmutatie === undefined ||
          mutatieBestandenResult.state === ERemoteDataState.Pending ||
          bestanden.state === ERemoteDataState.Pending
        ) {
          return createPendingRemoteData();
        }

        if (
          mutatieBestandenResult.data === null ||
          mutatieBestandenResult.data!.mutatieBestanden.length === 0 ||
          bestanden.data === null ||
          bestanden.data!.length === 0
        ) {
          return createReadyRemoteData(null);
        }
        const pdfBestanden = bestanden.data.filter(
          (x) => x.MediaType === 'application/pdf' || x.MediaType.startsWith('image/'),
        );

        return createReadyRemoteData(
          pdfBestanden.map((bestand) => {
            if (bestand.MediaType === 'application/pdf') {
              return {
                type: EPreviewType.PDF,
                bestand,
                id: `pdf-${bestand.ID}`,
              };
            }
            if (bestand.MediaType.startsWith('image/')) {
              return {
                type: EPreviewType.Afbeelding,
                bestand,
                id: `img-${bestand.ID}`,
              };
            }
            throw new Error('Onbekend bestand type');
          }),
        );
      }
      case ERegelboekstukType.Betalingsregeling: {
        const betalingsregeling = props.betalingsregelingenCache[props.boekstuk.betRglID];
        if (betalingsregeling === undefined) {
          return createPendingRemoteData();
        }
        return createReadyRemoteData(null);
      }
    }
  }, [
    props.boekstuk,
    props.facturenCache,
    props.inkoopfacturenCache,
    props.bankmutatiesCache,
    mutatieBestandenResult,
    bestanden,
  ]);

  useEffect(() => {
    if (previewItems.state === ERemoteDataState.Pending || previewItems.data === null) {
      return;
    }
    if (props.geselecteerdID !== null || previewItems.data.length === 0) {
      return;
    }
    const eerstePreviewItem = previewItems.data[0];
    props.onGeselecteerdIDChange(eerstePreviewItem.id);
  }, [
    useMemo(() => JSON.stringify(previewItems.data?.map((x) => x.id).join(',') ?? null), [
      previewItems,
    ]),
  ]);

  const gekozenPreviewItem = useMemo<IRemoteData<PreviewItem | null>>(() => {
    if (previewItems.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const items = previewItems.data;
    if (items === null || props.geselecteerdID === null) {
      return createReadyRemoteData(null);
    }
    const item = items.find((x) => x.id === props.geselecteerdID)!;
    return createReadyRemoteData(item);
  }, [previewItems, props.geselecteerdID]);

  const preview = useMemo(() => {
    if (gekozenPreviewItem.state === ERemoteDataState.Pending) {
      return (
        <div className="d-flex flex-column flex-fill">
          <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        </div>
      );
    }

    if (gekozenPreviewItem.data === null) {
      return <GeenPreviewGekozen />;
    }

    switch (gekozenPreviewItem.data!.type) {
      case EPreviewType.PDF: {
        return <PDFPreview previewItem={gekozenPreviewItem.data!} />;
      }
      case EPreviewType.Afbeelding: {
        return <AfbeeldingPreview previewItem={gekozenPreviewItem.data!} />;
      }
    }
  }, [gekozenPreviewItem]);

  const lijst = useMemo(() => {
    if (
      previewItems.state === ERemoteDataState.Pending ||
      previewItems.data === null ||
      previewItems.data!.length < 2
    ) {
      return null;
    }

    return (
      <div
        className="d-flex flex-column flex-fill p-3"
        style={{
          rowGap: 10,
        }}
      >
        {previewItems.data!.map((previewItem) => {
          const isGeselecteerd =
            gekozenPreviewItem.state === ERemoteDataState.Ready &&
            gekozenPreviewItem.data !== null &&
            gekozenPreviewItem.data!.id === previewItem.id;

          const handleGeselecteerd = () => {
            props.onGeselecteerdIDChange(previewItem.id);
          };

          const lijstItemProps = {
            isGeselecteerd,
            onGeselecteerd: handleGeselecteerd,
          };

          switch (previewItem.type) {
            case EPreviewType.PDF: {
              return <PDFLijstItem {...lijstItemProps} previewItem={previewItem} />;
            }
            case EPreviewType.Afbeelding: {
              return <AfbeeldingLijstItem {...lijstItemProps} previewItem={previewItem} />;
            }
          }
        })}
      </div>
    );
  }, [previewItems, gekozenPreviewItem, props.onGeselecteerdIDChange]);

  if (previewItems.state === ERemoteDataState.Pending) {
    return (
      <div className="d-flex flex-column flex-fill">
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (
    previewItems.state === ERemoteDataState.Ready &&
    (previewItems.data === null || previewItems.data!.length === 0)
  ) {
    return <GeenPreviewBeschikbaar />;
  }

  if (previewItems.data!.length === 1) {
    return preview;
  }

  return <MeerdereLayout links={lijst!} rechts={preview} />;
};

export default Preview;
