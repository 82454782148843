import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import api from '../../../../../../../../api';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ContactSelectie from '../../../../../../../../components/formulier/ContactSelectie';
import {
  IOphalenContractenResultElement,
  IOphalenContractenResultElementV2,
} from '../../../../../../../../../../shared/src/api/v2/contract';
import _ from 'lodash';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  cntIDs: number[];
}

interface IFormikValues {
  versturenOudeHuurder: boolean;
  versturenNieuweHuurder: boolean;
  persID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  versturenOudeHuurder: 'Bevestiging naar oude huurder',
  versturenNieuweHuurder: 'Bevestiging naar nieuwe huurder',
  persID: 'Naar persoon:',
};

const FiatterenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, cntIDs, relID } = props;
  const { checkStore } = useContext(RootStoreContext);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      versturenOudeHuurder: true,
      versturenNieuweHuurder: true,
      persID: null,
    };
  }, []);

  const handleOk = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        relID,
        cntIDs,
      };
      const checkData = await api.v2.contract.overname.checkFiatterenContracten(params);
      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Overname-contracten fiatteren?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // Bepaal de contracten die overgenomen zijn (van de vorige huurder)
      const contracten: IOphalenContractenResultElementV2[] = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: cntIDs }] },
        })
      ).contracten;

      const vorigeContracten_CntIDs = contracten
        .filter((x) => x.vorigContract !== null)
        .map((x) => x.vorigContract!.CntID!);

      const vorigeContracten = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: vorigeContracten_CntIDs }] },
        })
      ).contracten;
      const relIDs_Vorig = _.uniq(vorigeContracten.map((x) => x.RelID));

      // Fiatteren contracten
      await api.v2.contract.overname.fiatterenContracten(params);

      // Bevestigingen versturen
      if (values.versturenNieuweHuurder) {
        await api.v2.contract.overname.versturenBevestigingNieuweHuurder({
          relID,
          persID: null,
          cntIDs,
          kanaal: 'EMAIL',
        });
      }
      if (values.versturenOudeHuurder) {
        // Voor elke vorige huurder een bericht sturen
        for (const relID of relIDs_Vorig) {
          const cntIDs = vorigeContracten.filter((x) => x.RelID === relID).map((x) => x.CntID);

          await api.v2.contract.overname.versturenBevestigingOudeHuurder({
            relID,
            persID: null,
            cntIDs, //: vorigeContracten_CntIDs,
            kanaal: 'EMAIL',
          });
        }
      }

      onSuccess({});
      actions.setSubmitting(false);
    },
    [relID, cntIDs],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Fiatteren overname-contracten</ModalTitle>
      </ModalHeader>

      <Formik<IFormikValues>
        isInitialValid
        onSubmit={handleOk}
        initialValues={initialValues}
        render={(FormikProps) => {
          const { isSubmitting, submitForm } = FormikProps;
          return (
            <>
              <ModalBody>
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex align-items-center">
                    <Field
                      name="versturenNieuweHuurder"
                      render={(x: FieldProps<IFormikValues>) => (
                        <VinkVeld
                          aangevinkt={x.field.value}
                          onGewijzigd={(aangevinkt: boolean) =>
                            x.form.setFieldValue(x.field.name, aangevinkt)
                          }
                        />
                      )}
                    />
                    <label className="ml-3">{veldnamen.versturenNieuweHuurder}</label>
                  </div>
                  {/* {FormikProps.values.versturenNieuweHuurder && (
                    <div className="col-12 mt-3">
                      <label>{veldnamen.persID}</label>
                      <Field
                        name="persID"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <PersoonSelectie
                            relID={relID}
                            persID={fieldProps.field.value}
                            onChange={(x) =>
                              fieldProps.form.setFieldValue(fieldProps.field.name, x)
                            }
                            options={{
                              bijEnkeleDirectVoorselecteren: true,
                            }}
                          />
                        )}
                      />
                    </div>
                  )} */}
                  <div className="d-flex align-items-center mt-3">
                    <Field
                      name="versturenOudeHuurder"
                      render={(x: FieldProps<IFormikValues>) => (
                        <VinkVeld
                          aangevinkt={x.field.value}
                          onGewijzigd={(aangevinkt: boolean) =>
                            x.form.setFieldValue(x.field.name, aangevinkt)
                          }
                        />
                      )}
                    />
                    <label className="ml-3">{veldnamen.versturenOudeHuurder}</label>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default FiatterenDialoog;
