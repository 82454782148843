import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { IOphalenProductenResultElementV2 } from '../../../../../shared/src/api/v2/product';
import { IRow } from '../../../paginas/Magazijn/Depot/Context';
import { maandenNaarJaren } from '../../../bedrijfslogica/teksten';
import { format } from 'date-fns';
import { IconInformatie } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import ProductinfoDialoog from '../../product/ProductinfoDialoog';
import WijzigenProductDialoog from '../../../paginas/Magazijn/Depot/WijzigenProductDialoog';
import { IOphalenPendelregelsResultElement as IOphalenOpdrachtregelsResultElement } from '../../../../../shared/src/api/v2/pendel';
import nameof from '../../../core/nameOf';
import { RootStoreContext } from '../../../stores/RootStore';
import {
  IOphalenVoorraadinfoResultElement,
  IOphalenVoorraadUitgepaktResultElement,
} from '../../../../../shared/src/api/v2/voorraad';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import styled from 'styled-components';
import { IOphalenAanmeldingenResultElement } from '../../../../../shared/src/api/v2/inkoop/retour';
import { nummerRetouraanmelding } from '../../../bedrijfslogica/opdrachtnummers';
import { MagazijnKolom } from '../../../../../shared/src/api/v2/magazijn/export';
import UitlegTooltip from '../../formulier/UitlegTooltip';

interface IZoekgemeldTrProps {
  nietKleuren?: boolean;
}

const ZoekgemeldTr = styled.tr<IZoekgemeldTrProps>`
  td {
    ${(props) =>
      props.nietKleuren
        ? ''
        : `
      color: ${Kleur.Rood} !important;
    `}
  }
`;

interface IProps {
  producten: IRow[];
  voorraadInfo?: IOphalenVoorraadinfoResultElement[];
  voorraadUitgepakt?: IOphalenVoorraadUitgepaktResultElement[];
  selectie: string[];
  onSelectieChange: (value: string[]) => void;
  onRequestRefresh: () => void;
  pendelregels?: IOphalenOpdrachtregelsResultElement[];
  retouraanmeldingen?: IOphalenAanmeldingenResultElement[];
  extraWeerTeGevenkolommen?: string[];
  keyExtractorMetTypeGebruiken?: boolean;
  zoekgemeldNietKleuren?: boolean;
}

export interface IProductinfoDialoogState {
  id: number;
}

export enum EKolomnaam {
  Statusnaam = '__statusnaam',
  Retourstatusnaam = '__retourstatusnaam',
  Pendelopdrachten = '__pendelopdrachten',
  DatumUitBedrijf = 'DatumUitBedrijf',
  RedenUB = 'RedenUB',
  ZoekGemeld = 'ZoekGemeld',
  Defect = 'Defect',
  VoorraadAantalVrij = '__aantalVrij',
  VoorraadAantalRes = '__aantalRes',
  InkoopReferentie = '__inkoopReferentie',
  Retouraanmeldingen = '__retouraanmeldingen',
  Reserveringen = '__reserveringen',
  RecordGewijzigd = 'RecordGewijzigd',
}

export const basisMagazijnKolommen: MagazijnKolom[] = [
  'merk',
  'type',
  'productsoort',
  'referentiecode',
  'nieuw',
  'leeftijd',
  'datumInBedrijf',
];

const geenData = {
  noData: 'Geen gegevens aanwezig',
};

export const keyExtractor = (row: IRow): string => {
  const prodID =
    row.uitgepakt !== undefined
      ? row.uitgepakt.ProdID
      : row.ingepakt!.product !== undefined
      ? row.ingepakt!.product!.ProdID
      : null;
  return prodID !== null ? `P:${prodID}` : `T:${row.ingepakt!.type.TypeID}`;
  //
  // const keyExtractorMetTypeGebruiken =
  //   props.keyExtractorMetTypeGebruiken !== undefined ? props.keyExtractorMetTypeGebruiken : true;
  //
  // const product = !row.zonderReferentie
  //   ? (row.uitgepakt as IOphalenProductResult)
  //   : (row.uitgepakt as IOphalenProducttypenResultElement);
  // return keyExtractorMetTypeGebruiken && row.zonderReferentie
  //   ? `T:${product.TypeID}`
  //   : `P:${(row.uitgepakt as IOphalenProductResult).ProdID}`;
};

const ProductenTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);
  const [wijzigenProdID, setWijzigenProdID] = useState<number | null>(null);

  const reverseKeyExtractor = useCallback((key: string): { typeID?: number; prodID?: number } => {
    const [type, idStr] = key.split(':');
    const id = Number(idStr);
    if (type === 'T') {
      return {
        typeID: id,
      };
    }
    return {
      prodID: id,
    };
  }, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () =>
      [
        {
          name: '__merknaam' as any,
          title: 'Merk',
          getCellValue: (x: IRow) => {
            const product = x.uitgepakt || x.ingepakt!.product;
            if (product !== undefined) {
              return product.producttype.Merknaam;
            }
            return x.ingepakt!.type.Merknaam;
          },
        },
        {
          name: '__typenaam' as any,
          title: 'Type',
          getCellValue: (x: IRow) => {
            const product = x.uitgepakt || x.ingepakt!.product;
            if (product !== undefined) {
              return product.producttype.Typenaam;
            }
            return x.ingepakt!.type.Typenaam;
          },
        },
        {
          name: '__productsoortnaamKort' as any,
          title: 'Cat.',
          getCellValue: (x: IRow) => {
            const product = x.uitgepakt || x.ingepakt!.product;
            if (product !== undefined) {
              return product.producttype.ProductsoortnaamKort;
            }
            return x.ingepakt!.type.ProductsoortnaamKort;
          },
        },
        {
          name: '__referentiecode',
          title: 'Ref.code',
          getCellValue: (x: IRow) => {
            const product = x.uitgepakt || x.ingepakt!.product;
            if (product !== undefined) {
              return product.Referentiecode;
            }
            return '';
          },
        },
        {
          name: '__product' as any,
          title: ' ',
        },
        props.keyExtractorMetTypeGebruiken
          ? {
              name: '__aantal',
              title: 'Atl.',
              getCellValue: (row: IRow) => {
                if (row.ingepakt !== undefined && row.ingepakt.aantalGegroepeerd !== undefined) {
                  return row.ingepakt.aantalGegroepeerd;
                }
                return null;
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.VoorraadAantalRes) !== -1
          ? {
              name: EKolomnaam.VoorraadAantalRes as any,
              title: 'Geres.',
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.VoorraadAantalVrij) !== -1
          ? {
              name: EKolomnaam.VoorraadAantalVrij as any,
              title: 'Vrij',
            }
          : null,
        {
          name: 'nieuw',
          title: 'N/G',
          getCellValue: (x: IRow) => {
            const product = x.uitgepakt || x.ingepakt!.product;
            if (product !== undefined) {
              return product.Nieuw;
            }
            return '';
          },
        },
        {
          name: 'leeftijd',
          title: 'Lft.',
          getCellValue: (x: IRow) => {
            const product = x.uitgepakt || x.ingepakt!.product;
            if (product !== undefined) {
              return product.leeftijd;
            }
            return '';
          },
        },
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.Statusnaam) !== -1
          ? {
              name: EKolomnaam.Statusnaam as any,
              title: 'Status',
              getCellValue: (x: any) => {
                const product = x.uitgepakt || x.ingepakt!.product;
                if (product !== undefined) {
                  return product.Statusnaam || '';
                }
                return '';
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.Defect) !== -1
          ? {
              name: EKolomnaam.Defect,
              title: 'Defect',
              getCellValue: (x: IRow) => {
                const product = x.uitgepakt || x.ingepakt!.product;
                if (product !== undefined) {
                  return product.Defect ? 'Ja' : 'Nee';
                }
                return '';
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.Retourstatusnaam) !== -1
          ? {
              name: EKolomnaam.Retourstatusnaam as any,
              title: 'Doelstatus',
              getCellValue: (x: IRow) => {
                const product = x.uitgepakt || x.ingepakt!.product;
                if (product !== undefined) {
                  return product.retourstatus !== null ? product.retourstatus.Naam : '';
                }
                return '';
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.InkoopReferentie) !== -1
          ? {
              name: EKolomnaam.InkoopReferentie as any,
              title: (
                <span title={'Geeft aan op welke inkoopopdracht dit product is ingeslagen'}>
                  Ink.ref.
                </span>
              ) as any,
              getCellValue: (x: IRow) => {
                const product = x.uitgepakt || x.ingepakt!.product;
                if (product !== undefined) {
                  return product.inkoopopdracht?.Referentie ?? '';
                }
                return '';
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.Pendelopdrachten) !== -1
          ? {
              name: EKolomnaam.Pendelopdrachten as any,
              title: (
                <span title={'Geeft aan in welke lopende pendel dit product zit'}>Pendel</span>
              ) as any,
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.Retouraanmeldingen) !== -1
          ? {
              name: EKolomnaam.Retouraanmeldingen as any,
              title: (
                <span title={'Geeft aan in welke lopende retouraanmeldingen dit product zit'}>
                  Ret.meld.
                </span>
              ) as any,
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.DatumUitBedrijf) !== -1
          ? {
              name: EKolomnaam.DatumUitBedrijf,
              title: 'Datum UB',
              getCellValue: (x: IRow) => {
                const product = x.uitgepakt || x.ingepakt!.product;
                if (product !== undefined) {
                  return product.DatumUitBedrijf;
                }
                return '';
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.RedenUB) !== -1
          ? {
              name: EKolomnaam.RedenUB,
              title: 'Reden',
              getCellValue: (x: IRow) => {
                const product = x.uitgepakt || x.ingepakt!.product;
                if (product !== undefined) {
                  return product.redenUB !== null ? product.redenUB.Naam : null;
                }
                return '';
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.ZoekGemeld) !== -1
          ? {
              name: EKolomnaam.ZoekGemeld,
              title: 'Zoekgemeld',
              getCellValue: (x: IRow) => {
                const product = x.uitgepakt || x.ingepakt!.product;
                if (product !== undefined) {
                  return product.ZoekGemeld !== null ? new Date(product.ZoekGemeld) : null;
                }
                return null;
              },
            }
          : null,
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.Reserveringen) !== -1
          ? {
              name: EKolomnaam.Reserveringen,
              title: 'Reserveringen',
            }
          : null,
        {
          name: '__notities' as any,
          title: 'Notities',
          getCellValue: (x: IRow) => {
            const product = x.uitgepakt || x.ingepakt!.product;
            if (product !== undefined) {
              return product.Notities || '';
            }
            return '';
          },
        },
        props.extraWeerTeGevenkolommen !== undefined &&
        props.extraWeerTeGevenkolommen.indexOf(EKolomnaam.RecordGewijzigd) !== -1
          ? {
              name: 'RecordGewijzigd',
              title: 'Laatst gewijzigd',
              getCellValue: (x: IRow) => {
                let product = null;

                if (x.uitgepakt !== undefined) {
                  product = x.uitgepakt;
                } else {
                  if (x.ingepakt !== undefined && x.ingepakt.product !== undefined) {
                    product = x.ingepakt.product;
                  }
                }

                if (product !== null) {
                  return product.RecordGewijzigd;
                }
                return null;
              },
            }
          : null,
      ].filter((x) => x !== null) as TypedColumn<IRow>[],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 100,
      },
      {
        columnName: '__typenaam' as any,
        width: 160,
      },
      {
        columnName: 'nieuw',
        width: 80,
      },
      {
        columnName: '__aantal',
        width: 65,
      },
      {
        columnName: '__referentiecode',
        width: 100,
      },
      {
        columnName: '__product',
        width: 50,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 75,
      },
      {
        columnName: 'leeftijd',
        width: 75,
      },
      {
        columnName: 'DatumUitBedrijf',
        width: 110,
      },
      {
        columnName: 'RedenUB',
        width: 150,
      },
      {
        columnName: '__statusnaam' as any,
        width: 110,
      },
      {
        columnName: 'Defect',
        width: 85,
      },
      {
        columnName: '__pendelopdrachten' as any,
        width: 110,
      },
      {
        columnName: '__retouraanmeldingen' as any,
        width: 110,
      },
      {
        columnName: '__retourstatusnaam' as any,
        width: 110,
      },
      {
        columnName: 'ZoekGemeld',
        width: 140,
      },
      {
        columnName: '__notities' as any,
        width: 600,
      },
      {
        columnName: '__aantalVrij' as any,
        width: 75,
      },
      {
        columnName: '__aantalRes' as any,
        width: 80,
      },
      {
        columnName: '__reserveringen' as any,
        width: 250,
      },
      {
        columnName: '__inkoopReferentie' as any,
        width: 85,
      },
      {
        columnName: 'RecordGewijzigd',
        width: 150,
      },
    ],
    [],
  );

  // const RijOpmaakComp = ({ row, ...restProps }) => (
  //   <VirtualTable.Row
  //     {...restProps}
  //     {...row}
  //     // eslint-disable-next-line no-alert
  //     onClick={() => alert(JSON.stringify(row))}
  //     // style={{
  //     //   cursor: 'pointer',
  //     //   ...styles[row.sector.toLowerCase()],
  //     // }}
  //   />
  // );

  return (
    <>
      <GridStyleWrapper height={'calc(100vh - 240px)'}>
        <Grid rows={props.producten} columns={kolommen} getRowId={keyExtractor}>
          <DataTypeProvider
            for={['__aantal']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              if (rij.ingepakt !== undefined && rij.ingepakt.aantalGegroepeerd !== undefined) {
                return <span>{rij.ingepakt.aantalGegroepeerd}</span>;
              }

              return <span />;
            }}
          />
          <DataTypeProvider
            for={['__reserveringen']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              if (rij.uitgepakt !== undefined) {
                const leveropdrachtResult = props.voorraadUitgepakt!.find(
                  (x) => x.product.ProdID === rij.uitgepakt!.ProdID,
                );
                if (
                  leveropdrachtResult === undefined ||
                  leveropdrachtResult.leveropdracht === null
                ) {
                  return <span></span>;
                }
                const relID = leveropdrachtResult.leveropdracht.RelID;
                if (relID === null) {
                  return <span>Geen relatie opgegeven</span>;
                }
                return <RelatieVisualisatie relID={relID} />;
              }

              return <span></span>;
            }}
          />
          <DataTypeProvider
            for={['__aantalVrij']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              if (props.voorraadInfo === undefined) {
                return <span></span>;
              }

              if (rij.uitgepakt !== undefined) {
                return <span></span>;
              }

              // if (!rij.zonderReferentie || !props.keyExtractorMetTypeGebruiken) {
              //   const leveropdrachtResult = props.voorraadUitgepakt!.find(
              //     (x) => x.product.ProdID === (rij.product as IOphalenProductResult).ProdID,
              //   );
              //   return (
              //     <span>
              //       {leveropdrachtResult !== undefined
              //         ? leveropdrachtResult.leveropdracht !== null
              //           ? leveropdrachtResult.leveropdracht.RelID
              //           : '?'
              //         : '1'}
              //     </span>
              //   );
              // }

              const typeID = rij.ingepakt!.type.TypeID;
              const voorraadInfo = props.voorraadInfo.find((x) => x.TypeID === typeID)!;
              return (
                <>
                  <span
                    style={{
                      color: voorraadInfo.aantalVrij < 0 ? Kleur.Rood : Kleur.Grijs,
                    }}
                  >
                    {voorraadInfo.aantalVrij}
                  </span>
                </>
              );
            }}
          />
          <DataTypeProvider
            for={['__aantalRes']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              if (props.voorraadInfo === undefined) {
                return <span></span>;
              }

              if (rij.uitgepakt !== undefined) {
                return <span></span>;
              }

              const typeID = rij.ingepakt!.type.TypeID;
              const voorraadInfo = props.voorraadInfo.find((x) => x.TypeID === typeID)!;
              const aantalRes =
                voorraadInfo.aantalPlanningGereserveerd + voorraadInfo.aantalUitstaandGereserveerd;
              return (
                <>
                  <span>{aantalRes !== 0 ? aantalRes : ''}</span>
                </>
              );
            }}
          />
          <DataTypeProvider
            for={['__merknaam']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return <span>{product.producttype.Merknaam}</span>;
              }
              return <span>{rij.ingepakt!.type.Merknaam}</span>;
            }}
          />
          <DataTypeProvider
            for={['__typenaam']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return <span>{product.producttype.Typenaam}</span>;
              }
              return <span>{rij.ingepakt!.type.Typenaam}</span>;
            }}
          />
          <DataTypeProvider
            for={['__productsoortnaamKort']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return <span>{product.producttype.ProductsoortnaamKort}</span>;
              }
              return <span>{rij.ingepakt!.type.ProductsoortnaamKort}</span>;
            }}
          />
          <DataTypeProvider
            for={['__referentiecode']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return (
                  <span>
                    {product.Referentiecode !== null
                      ? product.Referentiecode
                      : !product.Nieuw
                      ? '?'
                      : ''}
                  </span>
                );
              }
              return <span></span>;
            }}
          />
          <DataTypeProvider
            for={['leeftijd']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                if (product.leeftijd !== null) {
                  return <span>{maandenNaarJaren(product.leeftijd)}</span>;
                }
                return <span>?</span>;
              }
              return <span></span>;
            }}
          />
          <DataTypeProvider
            for={['Defect']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return <span>{product.Defect ? 'Ja' : ''}</span>;
              }
              return <span></span>;
            }}
          />
          <DataTypeProvider
            for={['nieuw']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return <span>{product.Nieuw ? 'N' : 'G'}</span>;
              }
              return <span>N</span>;
            }}
          />
          <DataTypeProvider
            for={['__notities']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = (rij.uitgepakt || rij.ingepakt!.product) ?? null;

              if (product === null) {
                return <span></span>;
              }

              const notities =
                product === null || product.Notities === null ? (
                  ''
                ) : (
                  <div
                    style={{
                      maxHeight: 400,
                      overflowY: 'hidden',
                    }}
                  >
                    {product.Notities.split('\n').map((part) => (
                      <>
                        <span>{part}</span>
                        <br />
                      </>
                    ))}
                  </div>
                );

              return (
                <UitlegTooltip inhoud={notities}>
                  <span>{product.Notities !== null ? product.Notities : ''}</span>
                </UitlegTooltip>
              );
            }}
          />
          <DataTypeProvider
            for={[nameof('RedenUB')]}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined && product.redenUB !== null) {
                return <span>{product.redenUB.Naam || ''}</span>;
              }
              return <span></span>;

              // const rij: IRow = formatterProps.row;
              // return (
              //   <span>
              //     {rij.uitgepakt !== undefined && rij.uitgepakt.redenUB !== null
              //       ? rij.uitgepakt.redenUB.Naam
              //       : null}
              //   </span>
              // );
            }}
          />

          <DataTypeProvider
            for={['__product']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              let prodID: number | null = null;

              if (rij.uitgepakt !== undefined) {
                prodID = rij.uitgepakt.ProdID;
              } else {
                if (rij.ingepakt !== undefined && rij.ingepakt.product !== undefined) {
                  prodID = rij.ingepakt.product.ProdID;
                }
              }

              if (prodID !== null) {
                return (
                  <div className="mb-1">
                    <a
                      href="#"
                      // style={{ color: Kleur.DonkerGrijs }}
                      onClick={() => {
                        setProductinfoDialoogState({
                          id: prodID!,
                        });
                      }}
                    >
                      <IconInformatie style={{ width: 17, height: 17, fill: Kleur.Blauw }} />
                    </a>
                  </div>
                );
              }
              return <span></span>;
            }}
          />

          <DataTypeProvider
            for={['__retourstatusnaam']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;

              if (rij.uitgepakt !== undefined) {
                const product = rij.uitgepakt as IOphalenProductenResultElementV2;
                return (
                  <span>{product.retourstatus !== null ? product.retourstatus.Naam : ''}</span>
                );
              }
              return <span></span>;
            }}
          />
          <DataTypeProvider
            for={['__statusnaam']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return (
                  <span>
                    {product.productstatus.Naam !== null ? product.productstatus.Naam : ''}
                  </span>
                );
              }
              return <span></span>;
            }}
          />

          <DataTypeProvider
            for={['__inkoopReferentie']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined && product.inkoopopdracht !== null) {
                return <span>{product.inkoopopdracht.Referentie}</span>;
              }
              return <span></span>;
            }}
          />

          <DataTypeProvider
            for={['__pendelopdrachten']}
            formatterComponent={(formatterProps) => {
              if (props.pendelregels === undefined) {
                return <span></span>;
              }
              const rij: IRow = formatterProps.row;

              if (rij.uitgepakt !== undefined) {
                const product = rij.uitgepakt as IOphalenProductenResultElementV2;
                const regels = props.pendelregels.filter((x) => x.ProdID === product.ProdID);
                if (regels.length === 0) {
                  return <span></span>;
                }

                const opdracht = regels[0].opdracht;

                const kleur =
                  opdracht.DatumVerstuurd === null
                    ? Kleur.Blauw
                    : opdracht.Afgehandeld
                    ? Kleur.Rood
                    : Kleur.Groen;
                return <span style={{ color: kleur }}>{regels[0].opdracht.Opdrachtnummer}</span>;
              }
              return <span></span>;
            }}
          />

          <DataTypeProvider
            for={['__retouraanmeldingen']}
            formatterComponent={(formatterProps) => {
              if (props.retouraanmeldingen === undefined) {
                return <span></span>;
              }
              const rij: IRow = formatterProps.row;

              if (rij.uitgepakt !== undefined) {
                const product = rij.uitgepakt as IOphalenProductenResultElementV2;
                const aanmeldingen = props.retouraanmeldingen
                  .filter((x) => x.product !== null)
                  .filter((x) => x.product!.ProdID === product.ProdID);
                if (aanmeldingen.length === 0) {
                  return <span></span>;
                }

                const aanmelding = aanmeldingen[0];

                const kleur = aanmelding.AfgehandeldOp === null ? Kleur.Groen : Kleur.Rood;

                return (
                  <span style={{ color: kleur }}>{nummerRetouraanmelding(aanmelding.Nummer)}</span>
                );
              }
              return <span></span>;
            }}
          />

          <DataTypeProvider
            for={['DatumUitBedrijf']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return (
                  <span>
                    {product.DatumUitBedrijf !== null
                      ? format(new Date(product.DatumUitBedrijf), 'dd-MM-yyyy')
                      : null}
                  </span>
                );
              }
              return <span></span>;
            }}
          />
          <DataTypeProvider
            for={['ZoekGemeld']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              const product = rij.uitgepakt || rij.ingepakt!.product;
              if (product !== undefined) {
                return product.ZoekGemeld !== null ? (
                  <span>{format(new Date(product.ZoekGemeld), 'dd-MM-yyyy HH:mm')}</span>
                ) : (
                  <span></span>
                );
              }
              return <span></span>;
            }}
          />
          <DataTypeProvider
            for={['RecordGewijzigd']}
            formatterComponent={(formatterProps) => {
              const rij: IRow = formatterProps.row;
              if (rij.uitgepakt !== undefined) {
                const product = rij.uitgepakt || rij.ingepakt!.product;
                if (product !== undefined) {
                  return (
                    <span>
                      {product.RecordGewijzigd !== null
                        ? format(new Date(product.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                        : ''}
                    </span>
                  );
                }
                return null;
              }
              return <span></span>;
            }}
          />
          <SelectionState
            selection={props.selectie}
            onSelectionChange={(x) => props.onSelectieChange(x as string[])}
          />
          {/* <SortingState defaultSorting={[]} /> */}
          <SortingState
            defaultSorting={[
              { columnName: '__merknaam', direction: 'asc' },
              { columnName: '__typenaam', direction: 'asc' },
            ]}
          />

          <IntegratedSorting />

          <VirtualTable
            messages={geenData}
            rowComponent={(rowProps) => {
              const row: IRow = rowProps.row;
              const product = row.uitgepakt || row.ingepakt!.product;
              const isZoekgemeld = product !== undefined && product.ZoekGemeld !== null;

              if (isZoekgemeld) {
                return (
                  <ZoekgemeldTr nietKleuren={props.zoekgemeldNietKleuren}>
                    {rowProps.children}
                  </ZoekgemeldTr>
                );
              }

              return (
                <tr
                // onClick={(x) => {}}
                // style={{ color: isZoekgemeld ? `Kleur.Rood` : undefined }}
                >
                  {rowProps.children}
                </tr>
              );
            }}
          />
          {/* <VirtualTable messages={geenData} rowComponent={RijOpmaakComp} /> */}
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <TableHeaderRow showSortingControls />
          <EditingState
            onAddedRowsChange={() => {}}
            onEditingRowIdsChange={async (x) => {
              const key = x[x.length - 1] as string;
              const { prodID, typeID } = reverseKeyExtractor(key);
              if (prodID !== undefined) {
                setWijzigenProdID(prodID);
                return;
              }
              await checkStore.melden({
                titel:
                  'Deze functie werkt niet op een ingepakt product in weergave Gegroepeerd. Pas de weergave aan en probeer opnieuw.',
              });
              return;
            }}
            onCommitChanges={() => null}
          />
          <TableEditColumn width={40} showEditCommand commandComponent={DXCommandComponent} />
          {/* <RowDetailState />
          <TableRowDetail
            toggleCellComponent={DXTableToggleCellComponent}
            contentComponent={() => <span>details</span>}
          /> */}
          <TableSelection cellComponent={DXTableCheckboxComponent} />
        </Grid>
      </GridStyleWrapper>
      {productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={productinfoDialoogState.id}
          onSuccess={() => {
            setProductinfoDialoogState(null);
          }}
          onAnnuleren={() => setProductinfoDialoogState(null)}
        />
      )}
      {wijzigenProdID !== null && (
        <WijzigenProductDialoog
          open
          prodID={wijzigenProdID}
          onSuccess={async () => {
            setWijzigenProdID(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => {
            setWijzigenProdID(null);
          }}
        />
      )}
    </>
  );
});

export default ProductenTabel;
