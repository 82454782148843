import React from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import Beheer from './Beheer';
import Verrekeningen from './Verrekeningen';
import Aanmaningen from './Aanmaningen';
import Betalingsregelingen from './Betalingsregelingen';
import Incassozaken from './Incassozaken/index';
import Herincassozaken from './Herincassozaken';
import Rechtzaken from './Rechtzaken';
import Overzicht from './Overzicht';
import Diensten from '../Diensten/Beheer/Incasso';

interface IProps extends RouteComponentProps {}

const Debiteuren = (props: IProps) => {
  const { match } = props;
  return (
    <div className="d-flex flex-column flex-fill">
      <PaginaHeader>
        <ul>
          <li>
            <NavLink
              to={`${match.url}/beheer`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Beheer
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/verrekeningen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Verrekeningen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/aanmaningen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Aanmaningen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/betalingsregelingen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Betalingsregelingen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/incassozaken`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Incassozaken
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/herincassozaken`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Herincassozaken
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/rechtzaken`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Rechtzaken
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/overzicht`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Overzicht
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/diensten`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Incassodiensten
            </NavLink>
          </li>
        </ul>
      </PaginaHeader>

      <Route path={`${match.path}/beheer`} component={Beheer} />
      <Route path={`${match.path}/verrekeningen`} component={Verrekeningen} />
      <Route path={`${match.path}/aanmaningen`} component={Aanmaningen} />
      <Route path={`${match.path}/betalingsregelingen`} component={Betalingsregelingen} />
      <Route path={`${match.path}/incassozaken`} component={Incassozaken} />
      <Route path={`${match.path}/herincassozaken`} component={Herincassozaken} />
      <Route path={`${match.path}/rechtzaken`} component={Rechtzaken} />
      <Route path={`${match.path}/overzicht`} component={Overzicht} />
      <Route path={`${match.path}/diensten`} component={Diensten} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/beheer`} />}
      />
    </div>
  );
};

export default Debiteuren;
