import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { ISjabloonContext } from '../../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import Skeleton from 'react-loading-skeleton';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import IndexeringSelectie from './IndexeringSelectie';
import ContextProviderSelectie from './ContextProviderSelectie';
import {
  ISjabloonContextDataVasteWaarde,
  SjabloonModus,
} from '../../../../../../../../shared/src/api/v2/bulkbericht';

const Root = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Naam = styled.span``;
const Optioneel = styled.span`
  color: ${Kleur.Grijs};
  font-size: 12px;
`;

const Body = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const ModusToggleButton = styled.button`
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${Kleur.LichtGrijs};
  font-size: 12px;
  border: none;
  cursor: pointer;
  min-width: 110px;
`;

const BodyVeld = styled.div`
  flex: 1;
  margin-left: 12px;
`;

interface IVeldProps {
  vasteWaarde: ISjabloonContextDataVasteWaarde | null;
  onVasteWaardeChange: (value: ISjabloonContextDataVasteWaarde) => void;
}

const map: { [key: string]: React.ComponentType<IVeldProps> } = {
  INDEXERING: (props) => (
    <IndexeringSelectie
      indexeringID={props.vasteWaarde?.id ?? null}
      onIndexeringIDChange={(id) => props.onVasteWaardeChange({ id })}
    />
  ),
};

interface IProps {
  modus: SjabloonModus;
  onModusChange: (modus: SjabloonModus) => void;
  sjabCtx: {
    sjabCtxID: number;
    alias?: string | null;
    isOptioneel: boolean;
  };
  vasteWaarde: ISjabloonContextDataVasteWaarde | null;
  onVasteWaardeChange: (value: ISjabloonContextDataVasteWaarde) => void;
  providerID: number | null;
  onProviderIDChange: (id: number | null) => void;
  sjabloonContext: ISjabloonContext | null;
  ontvangerssoortID: number;
}

const SjabloonContextVeld = (props: IProps): JSX.Element => {
  const { sjabCtx, sjabloonContext } = props;

  const veld = useMemo<IRemoteData<React.ComponentType<IVeldProps> | null>>(() => {
    if (sjabloonContext === null) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(map[sjabloonContext.NaamEnum] ?? null);
  }, [sjabloonContext?.NaamEnum]);
  const Veld = veld.data!;

  useEffect(() => {
    if (props.sjabloonContext === null) {
      return;
    }

    // Bestaat er een enkele waarde selectie, dan willen we initieel de vaste waarde sjabloonmodus gebruiken.
    // Zo niet, dan willen we de provider sjabloonmodus gebruiken.

    const veld = map[sjabloonContext!.NaamEnum];
    if (veld === undefined && props.modus === 'vaste_waarde') {
      props.onModusChange('provider');
    } else if (veld !== undefined && props.modus === 'provider') {
      props.onModusChange('vaste_waarde');
    }
  }, [sjabloonContext?.NaamEnum]);

  return (
    <Root>
      {sjabloonContext === null || veld.state === ERemoteDataState.Pending ? (
        <div>
          <Skeleton />
          <Skeleton height={30} />
        </div>
      ) : (
        <>
          <Header>
            <Naam>{sjabloonContext.Naam}</Naam>
            {sjabCtx.isOptioneel && <Optioneel>Optioneel</Optioneel>}
          </Header>
          <Body>
            <ModusToggleButton
              onClick={() =>
                props.onModusChange(props.modus === 'vaste_waarde' ? 'provider' : 'vaste_waarde')
              }
            >
              {props.modus === 'vaste_waarde' ? 'Vaste waarde' : 'Provider'}
            </ModusToggleButton>
            <BodyVeld>
              {props.modus === 'provider' ? (
                <ContextProviderSelectie
                  providerID={props.providerID}
                  onProviderIDChange={props.onProviderIDChange}
                  sjabCtxID={sjabloonContext.SjabCtxID}
                  ontvangerssoortID={props.ontvangerssoortID}
                />
              ) : Veld === null ? (
                <span className="text-muted" style={{ fontSize: 12 }}>
                  De vaste waarde selectie wordt niet ondersteund door deze context.
                </span>
              ) : (
                <Veld
                  vasteWaarde={props.vasteWaarde}
                  onVasteWaardeChange={props.onVasteWaardeChange}
                />
              )}
            </BodyVeld>
          </Body>
        </>
      )}
    </Root>
  );
};

export default SjabloonContextVeld;
