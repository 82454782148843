import { create } from 'zustand';
import api from '../api';
import {
  IBulkVerzendBerichtVerzondenMessage,
  IBulkVerzenderAantallenGewijzigdMessage,
  IBulkVerzenderErrorGewijzigdMessage,
} from '../../../shared/src/realtime-manager/messages/bulkVerzender';
import { IBulkVerzenderError } from '../../../shared/src/api/v2/bulkVerzending';

interface IData {
  teVerzendenBerichtenAantal: number;
  teReviewenBerichtenAantal: number;
  error: IBulkVerzenderError | null;
}

export interface IBulkverzenderStore {
  data: IData | null | 'laden';

  initialiseer: () => Promise<void>;
  handleBulkVerzendBerichtVerzonden: (data: IBulkVerzendBerichtVerzondenMessage) => void;
  handleBulkVerzenderErrorGewijzigd: (data: IBulkVerzenderErrorGewijzigdMessage) => void;
  handleBulkVerzenderAantallenGewijzijgd: (data: IBulkVerzenderAantallenGewijzigdMessage) => void;
}

export const useBulkverzenderStore = create<IBulkverzenderStore>((set, get) => ({
  data: null,

  initialiseer: async () => {
    if (get().data !== null) {
      return;
    }
    set({ data: 'laden' });

    const [overzichtDataResult, errorResult] = await Promise.all([
      api.v2.bulkVerzending.bepalenBulkverzenderOverzichtData({}),
      api.v2.bulkVerzending.ophalenBulkVerzenderError({}),
    ]);

    const data: IData = {
      teVerzendenBerichtenAantal: overzichtDataResult.teVerzendenBerichtenAantal,
      teReviewenBerichtenAantal: overzichtDataResult.teReviewenBerichtenAantal,
      error: errorResult.error,
    };

    set({ data });
  },
  handleBulkVerzendBerichtVerzonden: (data) => {
    set((self: IBulkverzenderStore) => {
      if (self.data === null || self.data === 'laden') {
        return {};
      }
      const newData = {
        ...self.data!,
        teVerzendenBerichtenAantal: self.data.teVerzendenBerichtenAantal - 1,
      };
      return { data: newData };
    });
  },
  handleBulkVerzenderErrorGewijzigd: (data) => {
    set((self: IBulkverzenderStore) => {
      if (self.data === null || self.data === 'laden') {
        return {};
      }

      const newData = {
        ...self.data!,
        error: data.error,
      };

      return { data: newData };
    });
  },
  handleBulkVerzenderAantallenGewijzijgd: (data) => {
    set((self: IBulkverzenderStore) => {
      if (self.data === null || self.data === 'laden') {
        return {};
      }

      const newData = {
        ...self.data!,
        teVerzendenBerichtenAantal: data.aantalTeVerzenden,
        teReviewenBerichtenAantal: data.aantalTeReviewen,
      };

      return { data: newData };
    });
  },
}));
