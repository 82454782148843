import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AutoSizer } from 'react-virtualized';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { Boekstuksaldo, ERegelboekstukType, Regel } from '../types';
import { IOphalenMutatiesResultElement } from '../../../../../../../shared/src/api/v2/bank/mutaties';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../shared/src/api/v2/factuur';
import { IOphalenFacturenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import FactuurRegel from './FactuurRegel';
import InkoopfactuurRegel from './InkoopfactuurRegel';
import BankmutatieRegel from './BankmutatieRegel';
import { IOphalenDagboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { EDagboeksoort } from '../../../../../bedrijfslogica/enums';
import BetalingsregelingRegel from './BetalingsregelingRegel';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../../shared/src/api/v2/debiteur/betalingsregeling';

export interface IRegelCompProps {
  regel: Regel;
  isGeselecteerd: boolean;
  onGeselecteerd: () => void;
  boekstuksaldi: IRemoteData<Boekstuksaldo[]>;
  boekdatum: IRemoteData<Date | null>;
  controleerBoekdatum: boolean;
  facturenCache: Record<number, IOphalenFacturenBasisResultElement>;
  inkoopfacturenCache: Record<number, IOphalenFacturenResultElement>;
  bankmutatiesCache: Record<number, IOphalenMutatiesResultElement>;
  betalingsregelingenCache: Record<number, IOphalenBetalingsregelingenResultElement>;
}

interface IProps {
  regels: IRemoteData<Regel[]>;
  geselecteerdRegelID: number | string | null;
  onGeselecteerdRegelIDChange: (id: number | string | null) => void;
  boekstuksaldi: IRemoteData<Boekstuksaldo[]>;
  boekdatum: IRemoteData<Date | null>;
  dagboek: IRemoteData<IOphalenDagboekenResultElement | null>;
  facturenCache: Record<number, IOphalenFacturenBasisResultElement>;
  inkoopfacturenCache: Record<number, IOphalenFacturenResultElement>;
  bankmutatiesCache: Record<number, IOphalenMutatiesResultElement>;
  betalingsregelingenCache: Record<number, IOphalenBetalingsregelingenResultElement>;
}

const Boekstukgegevens = (props: IProps) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <div
          className="d-flex flex-column"
          style={{
            width,
            height,
          }}
        >
          <BoekstukgegevensInner {...props} />
        </div>
      )}
    </AutoSizer>
  );
};

const BoekstukgegevensInner = (props: IProps) => {
  const regelMap = useMemo<Record<ERegelboekstukType, React.ComponentType<IRegelCompProps>>>(
    () => ({
      [ERegelboekstukType.Factuur]: FactuurRegel,
      [ERegelboekstukType.Inkoopfactuur]: InkoopfactuurRegel,
      [ERegelboekstukType.Bankmutatie]: BankmutatieRegel,
      [ERegelboekstukType.Betalingsregeling]: BetalingsregelingRegel,
    }),
    [],
  );

  const regelsMetBoekstuk = useMemo<IRemoteData<Regel[]>>(() => {
    if (props.regels.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(props.regels.data!.filter((x) => x.regelboekstuk !== null));
  }, [props.regels]);

  const controleerBoekdatumIdx = useMemo<IRemoteData<number | null>>(() => {
    if (
      regelsMetBoekstuk.state === ERemoteDataState.Pending ||
      props.dagboek.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }

    const idx = regelsMetBoekstuk.data!.findIndex((regel) => {
      if (props.dagboek.state !== ERemoteDataState.Pending && props.dagboek.data !== null) {
        if (
          props.dagboek.data!.dagboekSoort.NaamEnum === EDagboeksoort.INKOOP &&
          regel.regelboekstuk!.type === ERegelboekstukType.Inkoopfactuur
        ) {
          return true;
        } else if (
          props.dagboek.data!.dagboekSoort.NaamEnum === EDagboeksoort.VERKOOP &&
          regel.regelboekstuk!.type === ERegelboekstukType.Factuur
        ) {
          return true;
        } else if (
          props.dagboek.data!.dagboekSoort.NaamEnum === EDagboeksoort.BANK &&
          regel.regelboekstuk!.type === ERegelboekstukType.Bankmutatie
        ) {
          return true;
        }
      }
      return false;
    });
    return createReadyRemoteData(idx);
  }, [regelsMetBoekstuk, props.dagboek]);

  if (regelsMetBoekstuk.state === ERemoteDataState.Pending) {
    return (
      <div
        className="flex-fill d-flex flex-column p-3 pl-4 pr-4"
        style={{
          overflowY: 'auto',
          rowGap: 10,
        }}
      >
        {new Array(3).fill(null).map((_, i) => (
          <div key={i}>
            <Skeleton height={100} style={{}} />
          </div>
        ))}
      </div>
    );
  }

  if (regelsMetBoekstuk.data!.length === 0) {
    return (
      <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
        Geen boekstukken beschikbaar
      </div>
    );
  }

  return (
    <div
      className="flex-fill d-flex flex-column p-3 pl-4 pr-4"
      style={{
        overflowY: 'auto',
        rowGap: 10,
      }}
    >
      {regelsMetBoekstuk.data!.map((regel, i) => {
        const Comp = regelMap[regel.regelboekstuk!.type];

        const isGeselecteerd = regel.id.waarde === props.geselecteerdRegelID;

        const controleerBoekdatum =
          controleerBoekdatumIdx.state === ERemoteDataState.Ready &&
          controleerBoekdatumIdx.data === i;

        return (
          <div key={regel.id.waarde}>
            <Comp
              regel={regel}
              isGeselecteerd={isGeselecteerd}
              onGeselecteerd={() => props.onGeselecteerdRegelIDChange(regel.id.waarde)}
              boekstuksaldi={props.boekstuksaldi}
              boekdatum={props.boekdatum}
              controleerBoekdatum={controleerBoekdatum}
              facturenCache={props.facturenCache}
              inkoopfacturenCache={props.inkoopfacturenCache}
              bankmutatiesCache={props.bankmutatiesCache}
              betalingsregelingenCache={props.betalingsregelingenCache}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Boekstukgegevens;
