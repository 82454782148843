import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import nameof from '../../../core/nameOf';
import ContactSelectie, { EType } from '../../formulier/ContactSelectie';
import VeldWeergave from '../../formulier/VeldWeergave';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState } from '@devexpress/dx-react-grid';
import RadioKnop from '../../formulier/RadioKnop';
import ModalFooter from 'react-bootstrap/ModalFooter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import api from '../../../api';
import {
  IOphalenDocumentenResultElement,
  IVersturenProductdocumentatieParams,
} from '../../../../../shared/src/api/v2/product/documentatie';
import { IOphalenProductenResultElementV2 } from '../../../../../shared/src/api/v2/product';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import MediaWeergaveDialoog from '../../dialogen/MediaWeergaveDialoog';
import { mediaTypeNaarMediaWeergaveType } from '../../MediaWeergave';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import downloadUrl from '../../../core/downloadUrl';

export interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

const urlStateKey = 'documentenSelectie';

interface IFormikValues {
  persID: number | null;
  verzendwijze: string;
  versturenOnderhoudtips: boolean;
  versturenGebruiksaanwijzing: boolean;
  versturenProgrammatabel: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  persID: 'Persoon',
  verzendwijze: 'Verzendwijze',
  versturenOnderhoudtips: 'Onderhoudtips',
  versturenGebruiksaanwijzing: 'Gebruiksaanwijzingen',
  versturenProgrammatabel: 'Programmatabellen',
};

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  prodID: number;
  relID?: number;
  persID?: number;
}

interface IMediaweergaveDialoogState {
  id: number;
}

const VersturenDocumentatieDialoog = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);

  const [primairPersoonPersID, setPrimairPersoonPersID] = useState<IRemoteData<number | null>>(
    createPendingRemoteData(),
  );
  const [product, setProduct] = useState<IRemoteData<IOphalenProductenResultElementV2>>(
    createPendingRemoteData(),
  );
  const [documenten, setDocumenten] = useState<IRemoteData<IOphalenDocumentenResultElement[]>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    if (props.relID === undefined) {
      setPrimairPersoonPersID(createReadyRemoteData(null));
      return;
    }

    api.v2.relatie
      .ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.relID],
            },
          ],
        },
      })
      .then((result) => {
        const persID = result.relaties[0].PersoonPrimair_PersID;
        setPrimairPersoonPersID(createReadyRemoteData(persID));
      });
  }, [props.relID]);

  useEffect(() => {
    api.v2.product
      .ophalenProductenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.prodID],
            },
          ],
        },
      })
      .then((result) => {
        setProduct(createReadyRemoteData(result.producten[0]));
      });
  }, [props.prodID]);

  useEffect(() => {
    if (product.state === ERemoteDataState.Pending) {
      return;
    }

    api.v2.product.documentatie
      .ophalenDocumenten({
        filterSchema: {
          filters: [
            { naam: 'HEEFT_GEKOPPELD_DOCUMENT', data: true },
            {
              naam: 'TYPE_IDS',
              data: [product.data!.producttype.TypeID],
            },
          ],
        },
      })
      .then((result) => {
        setDocumenten(createReadyRemoteData(result.documenten));
      });
  }, [product]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (primairPersoonPersID.state === ERemoteDataState.Pending) {
      return null;
    }
    return {
      persID: props.persID ?? primairPersoonPersID.data,
      verzendwijze: 'EMAIL',
      versturenOnderhoudtips: true,
      versturenGebruiksaanwijzing: false,
      versturenProgrammatabel: false,
    };
  }, [primairPersoonPersID.data, props.persID]);

  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      if (urlState.selectie.length === 0) {
        await checkStore.melden({ titel: 'Selecteer 1 of meer documenten' });
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Documenten versturen?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const params: IVersturenProductdocumentatieParams = {
        relID: props.relID,
        persID: values.persID!,
        prodDocIDs: urlState.selectie,
        kanalen: [values.verzendwijze],
      };

      await api.v2.product.documentatie.versturenProductdocumentatie(params);

      if (props.relID !== undefined) {
        await api.v2.relatie.koppelenContactpersoon({
          persID: values.persID!,
          relID: props.relID,
        });
      }

      setUrlStateSync('selectie', []);

      props.onSuccess({});
    },
    [props.relID, urlState.selectie],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        persID: Yup.number().required('Geen persoon geselecteerd'),
      }),
    [],
  );

  const ladenVisualisatie = useMemo(() => {
    return (
      <ModalBody>
        <LoadingSpinner />
      </ModalBody>
    );
  }, []);

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Versturen documentatie</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        ladenVisualisatie
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps) => {
            return documenten.state === ERemoteDataState.Pending ? (
              ladenVisualisatie
            ) : (
              <Formulier
                relID={props.relID}
                formikProps={formikProps}
                documenten={documenten.data!}
                documentSelectie={urlState.selectie}
                onDocumentSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                onAnnuleren={props.onAnnuleren}
              />
            );
          }}
        />
      )}
    </Dialoog>
  );
});

interface IFormulierProps {
  relID?: number;
  formikProps: FormikProps<IFormikValues>;
  documenten: IOphalenDocumentenResultElement[];
  documentSelectie: number[];
  onDocumentSelectieChange: (documentSelectie: number[]) => void;
  onAnnuleren: () => void;
}

const Formulier = (props: IFormulierProps) => {
  const [persoon, setPersoon] = useState<IRemoteData<IOphalenPersonenResultElementV2>>(
    createPendingRemoteData(),
  );

  const [
    mediaweergaveDialoogState,
    setMediaweergaveDialoogState,
  ] = useState<IMediaweergaveDialoogState | null>(null);

  useEffect(() => {
    if (props.formikProps.values.persID === null) {
      if (persoon.state === ERemoteDataState.Ready) {
        setPersoon(createPendingRemoteData());
      }
      return;
    }

    api.v2.persoon
      .ophalenPersonen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.formikProps.values.persID],
            },
          ],
        },
      })
      .then((result) => {
        const persoon = result.personen[0];
        setPersoon(createReadyRemoteData(persoon));
      });
  }, [props.formikProps.values.persID]);

  const documentKeyExtractor = useCallback((row: IOphalenDocumentenResultElement) => row.ID, []);
  const documentKolommen = useMemo<TypedColumn<IOphalenDocumentenResultElement>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Document',
      },
      {
        name: '__talen' as any,
        title: 'Talen',
      },
      {
        name: '__documentsoort' as any,
        title: 'Soort',
      },
      {
        name: '__mediaweergave' as any,
        title: ' ',
      },
      {
        name: '__downloaden',
        title: ' ',
      },
    ],
    [],
  );
  const documentKolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenDocumentenResultElement>[]
  >(
    () => [
      {
        columnName: 'Naam',
        width: 260,
      },
      {
        columnName: '__talen' as any,
        width: 150,
      },
      {
        columnName: '__documentsoort' as any,
        width: 150,
      },
      {
        columnName: '__mediaweergave' as any,
        width: 65,
      },
      {
        columnName: '__downloaden',
        width: 90,
      },
    ],
    [],
  );

  return (
    <>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <label>{veldnamen.persID}</label>
            <Field
              name={nameof<IFormikValues>('persID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                return (
                  <>
                    <ContactSelectie
                      value={field.value === null ? null : { persID: field.value, orgID: null }}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value?.persoon?.PersID ?? null);
                      }}
                      alleenVoorRelIDs={props.relID !== undefined ? [props.relID] : undefined}
                      options={{
                        bijEnkeleDirectVoorselecteren: true,
                        types: [EType.Persoon],
                      }}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                );
              }}
            />
          </div>

          {persoon.state === ERemoteDataState.Ready && (
            <div className="col-12 mt-3">
              <VeldWeergave>Taal {persoon.data!.taal.Taal}</VeldWeergave>
            </div>
          )}

          <div className="col-12 mt-4">
            <h6>Selecteer 1 of meer documenten:</h6>
          </div>

          <div className="col-12 mt-3">
            <GridStyleWrapper rowAmount={props.documenten.length} maxHeight={400}>
              <Grid
                rows={props.documenten}
                columns={documentKolommen}
                getRowId={documentKeyExtractor}
              >
                <DataTypeProvider
                  for={[nameof('__documentsoort')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenDocumentenResultElement = formatterProps.row;

                    return <span>{rij.documentsoort.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameof('__talen')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenDocumentenResultElement = formatterProps.row;
                    const talen = rij.talen.map((x) => x.Naam).join(',');
                    return <span>{talen}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__mediaweergave']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenDocumentenResultElement = formatterProps.row;
                    return (
                      <span className="d-flex align-items-center">
                        <a
                          href="#"
                          onClick={async (ev) => {
                            ev.preventDefault();

                            setMediaweergaveDialoogState({ id: rij.ID });
                          }}
                        >
                          Inzien
                        </a>
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__downloaden']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenDocumentenResultElement = formatterProps.row;

                    if (rij.bestand === null) {
                      return <span>- -</span>;
                    }

                    return (
                      <span className="d-flex align-items-center">
                        <a
                          href="#"
                          onClick={async (ev) => {
                            ev.preventDefault();

                            await downloadUrl(rij.bestand!.url, rij.bestand!.Naam);
                          }}
                        >
                          Downloaden
                        </a>
                      </span>
                    );
                  }}
                />

                <VirtualTable
                  messages={{
                    noData: 'Geen documenten',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={documentKolomBreedtes} />
                <TableHeaderRow />

                <SelectionState
                  selection={props.documentSelectie}
                  onSelectionChange={(x) => props.onDocumentSelectieChange(x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          </div>

          <div className="col-12 mt-4 mb-2 d-flex">
            <label>{veldnamen.verzendwijze}</label>
            <Field
              name="verzendwijze"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <div className="d-flex" style={{ width: 250 }}>
                      <span className="d-flex align-items-center ml-3">
                        <RadioKnop
                          aangevinkt={field.value === 'EMAIL'}
                          onAangevinkt={() => form.setFieldValue(field.name, 'EMAIL')}
                        />
                        Email
                      </span>
                      <span className="d-flex align-items-center ml-3">
                        <RadioKnop
                          aangevinkt={field.value === 'SMS'}
                          onAangevinkt={() => form.setFieldValue(field.name, 'SMS')}
                        />
                        SMS
                      </span>
                    </div>

                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                );
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={props.formikProps.submitForm}
          style={{ width: 100 }}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>

      {mediaweergaveDialoogState !== null && (
        <MediaWeergaveDialoog
          current={mediaweergaveDialoogState.id}
          onCurrentChange={(id) => setMediaweergaveDialoogState({ id: id as number })}
          mediaWeergaven={props.documenten.map((x) => {
            return {
              id: x.ID,
              title: x.Naam,
              src: x.bestand!.url,
              type: mediaTypeNaarMediaWeergaveType(x.bestand!.MediaType)!,
              mediaType: x.bestand!.MediaType,
            };
          })}
          open
          onSuccess={() => setMediaweergaveDialoogState(null)}
          onAnnuleren={() => setMediaweergaveDialoogState(null)}
        />
      )}
    </>
  );
};

export default withRouter(VersturenDocumentatieDialoog);
