import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenFacturenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';
import FormatteerBedrag from '../../../../MutatieBedrag';
import { ERemoteDataState } from '../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import { IconPriorityHigh, IconVink } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { dagDatum } from '../../../../../helpers/datum';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IProps extends RouteComponentProps {
  inkoopfactuur: IOphalenFacturenResultElement;
}

const Data = (props: IProps) => {
  const { inkoopfactuur } = props;

  return (
    <Root>
      <div className="d-flex align-items-center">
        <h5 style={{ marginBottom: 0 }}>Inkoopfactuur</h5>
        <span
          className="ml-3"
          style={{
            position: 'relative',
            top: 1,
          }}
          title="Factuurnummer"
        >
          {inkoopfactuur.Factuurnummer}
        </span>
        <span
          className="ml-4"
          style={{
            position: 'relative',
            top: 2,
            color: '#a8a8a8',
            fontSize: 13,
          }}
          title="Intern nummer"
        >
          ({format(new Date(inkoopfactuur.Factuurdatum), 'yyyy')}
          {inkoopfactuur.Boekstuknummer})
        </span>
        <div className="flex-fill" />
        <RelatieVisualisatie relID={inkoopfactuur.RelID} rootStyle={{ fontSize: 15 }} />
      </div>
      <div className="flex-fill mt-1">
        <span>
          <FormatteerBedrag bedrag={inkoopfactuur.Bedrag} fontSize={21} />
        </span>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ height: 30, color: '#b4b4b4', fontSize: 15 }}
      >
        {inkoopfactuur.Onderwerp}
      </div>
      <table style={{ width: '80%' }}>
        <tbody>
          <tr>
            <td style={{ minWidth: 100 }}>
              <span>Openstaand:</span>
            </td>

            <td style={{ minWidth: 85 }}>
              <FormatteerBedrag bedrag={inkoopfactuur.Openstaand} />
            </td>
            <td style={{ minWidth: 100 }}>
              <span>Bedrag BTW:</span>
            </td>
            <td style={{ minWidth: 85 }}>
              <FormatteerBedrag bedrag={inkoopfactuur.BedragBtw} />
            </td>
            <td style={{ minWidth: 100 }}>
              <span>Factuurdatum:</span>
            </td>
            <td style={{ minWidth: 100 }}>
              <span>{format(new Date(inkoopfactuur.Factuurdatum), 'dd-MM-yyyy')}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Bedrag korting:</span>
            </td>
            <td>
              <FormatteerBedrag bedrag={inkoopfactuur.BedragKorting} />
            </td>
            <td />
            <td />
            <td>
              <span>Vervaldatum:</span>
            </td>
            <td>
              <span>{format(new Date(inkoopfactuur.Vervaldatum), 'dd-MM-yyyy')}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </Root>
  );
};

export default withRouter(Data);
