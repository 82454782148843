import React from 'react';
import styled from 'styled-components';
import { IContactData } from '../../index';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import { IconBellen, IconChevronRight, IconContact } from '../../../../../../../../Icons';
import TelefoonComponent from '../../../../../../../TelefoonComponent';
import EmailComponent from '../../../../../../../EmailComponent';
import { formatteerAdresV2 } from '../../../../../../../../../helpers';

interface IProps {
  data: IContactData;
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
  width: 500px;
`;

const InfoCard = (props: IProps) => {
  const { data } = props;
  const { name, phones, emails, urls, addresses } = data;

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      <div className="d-flex align-items-center flex-fill">
        <IconContact style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
        <span className="name ml-3 mr-3">{name.formatted_name}</span>
      </div>
      {phones !== null && (
        <div className="d-flex flex-column flex-fill mt-2">
          {phones
            .filter((phone) => phone.phone !== null)
            .map((phone, i) => (
              <TelefoonComponent key={i} telefoonNummer={phone.phone!.replace(/ /g, '')} />
            ))}
        </div>
      )}
      {emails !== null && (
        <div className="d-flex flex-column flex-fill mt-2">
          {emails.map((email, i) => (
            <EmailComponent key={i} emailAdres={email.email} />
          ))}
        </div>
      )}
      {addresses !== null && (
        <div className="d-flex flex-column flex-fill mt-2">
          {addresses.map((address, i) => (
            <span>
              {formatteerAdresV2({
                straatnaam: address.street,
                postcode: address.zip,
                plaatsnaam: address.city,
                landnaamKort: address.country_code ?? address.country,
              })}
            </span>
          ))}
        </div>
      )}
      {urls !== null && (
        <div className="d-flex flex-column flex-fill mt-2">
          {urls
            .filter((url) => url.url !== null)
            .map((url, i) => (
              <a key={i} href={url.url!}>
                {url.url!}
              </a>
            ))}
        </div>
      )}
    </Root>
  );
};

export default InfoCard;
