import React, { useMemo, useCallback, useState, useEffect, useRef, useContext } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import { FormikActions, Formik, FormikProps, Field, FieldProps } from 'formik';
import { ModalBody, ModalTitle } from 'react-bootstrap';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import nameof from '../../../../../core/nameOf';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../../api';
import BedragInput from '../../../../../components/formulier/BedragInput';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import { IOphalenAbonnementenVoorLijstResult } from '../../../../../../../shared/src/api/v2/aanbod/Abonnement';
import {
  IOphalenTariefsetsResultElement,
  IWijzigenTariefsetParams,
  IWijzigenTariefsetTarief,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven';
import AbonnementTariefFormulier from './AbonnementTariefFormulier';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import classNames from 'classnames';
import InactiefOverlay from '../../../../../components/InactiefOverlay';
import HorizontaleScheidingslijn from '../../../../../components/layout/HorizontaleScheidingslijn';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../stores/CheckStore';
import { addDays, format } from 'date-fns';
import { dagDatum } from '../../../../../helpers/datum';

interface IAbonnementTarief {
  actief: boolean;
  abonID: number;
  maandHuur: number;
  eenmaligBedrag: number;
}

interface IFormikValues {
  borgsom: number;
  ingangsdatum: Date;
  einddatum: Date | null;
  tarieven: IAbonnementTarief[] | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ingangsdatum: 'Ingangsdatum',
  einddatum: 'Einddatum',
  tarieven: 'Tarieven',
  borgsom: 'Borgsom',
};

const genereerTarieven = (
  ingangsdatum: Date,
  einddatum: Date | null,
  tariefsetVanProductmodel: IOphalenTariefsetsResultElement,
  abonnementen: IOphalenAbonnementenVoorLijstResult,
): {
  tarieven: IAbonnementTarief[] | null;
  ingangsdatum: Date;
  einddatum: Date | null;
} => {
  const meestRecenteTariefset = tariefsetVanProductmodel;

  if (
    meestRecenteTariefset.Einddatum !== null &&
    new Date(meestRecenteTariefset.Einddatum).getTime() > new Date().getTime()
  ) {
    ingangsdatum = dagDatum(new Date(meestRecenteTariefset.Einddatum));
  }
  return {
    tarieven: abonnementen
      .filter((abonnement) => abonnement.Actief)
      .map((abonnement) => {
        const tarief = meestRecenteTariefset.tarieven.find(
          (tarief) => tarief.AbonID === abonnement.AbonID,
        );
        return {
          actief: tarief !== undefined,
          abonID: abonnement.AbonID,
          maandHuur: tarief !== undefined ? tarief.Maandhuur : 0,
          eenmaligBedrag: tarief !== undefined ? tarief.EenmaligBedrag : abonnement.EenmaligBedrag,
        };
      }),
    ingangsdatum,
    einddatum,
  };
};

interface IProps extends IDialoogProps<null> {
  tarNomID: number;
}

const TariefWijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [abonnementen, setAbonnementen] = useState<IOphalenAbonnementenVoorLijstResult | null>(
    null,
  );
  const [
    tariefsetVanProductmodel,
    setTariefsetVanProductmodel,
  ] = useState<IOphalenTariefsetsResultElement | null>(null);

  const ophalenAbonnementen = useCallback(async () => {
    const result = await api.v2.abonnement.ophalenAbonnementenVoorLijst({ actief: null });
    setAbonnementen(result);
  }, []);

  useEffect(() => {
    ophalenAbonnementen();
  }, [ophalenAbonnementen]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const data: IWijzigenTariefsetParams = {
        tarNomID: props.tarNomID,
        borgsom: values.borgsom,
        ingangsdatum: format(values.ingangsdatum, 'yyyy-MM-dd'),
        einddatum: values.einddatum !== null ? format(values.einddatum, 'yyyy-MM-dd') : null,
        tarieven: values
          .tarieven!.filter((x) => x.actief)
          .map((tarief) => {
            const x: IWijzigenTariefsetTarief = {
              ...tarief,
            };
            // @ts-ignore
            delete x.actief;
            return x;
          }),
      };
      const checkData = await api.v2.aanbod.tarieven.checkWijzigenTariefset(data);
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }
      await api.v2.aanbod.tarieven.wijzigenTariefset(data);

      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const tarievenVoorReset = useMemo<IAbonnementTarief[] | null>(() => {
    if (abonnementen === null) {
      return null;
    }

    return abonnementen
      .filter((abonnement) => abonnement.Actief)
      .map((abonnement) => {
        return {
          actief: false,
          abonID: abonnement.AbonID,
          maandHuur: 0,
          eenmaligBedrag: 0,
        };
      });
  }, [abonnementen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (abonnementen === null || tariefsetVanProductmodel === null) {
      return null;
    }
    const ingangsdatumInit = dagDatum(new Date(tariefsetVanProductmodel.Ingangsdatum));
    const einddatumInit =
      tariefsetVanProductmodel.Einddatum !== null
        ? dagDatum(new Date(tariefsetVanProductmodel.Einddatum))
        : null;

    if (tariefsetVanProductmodel === null) {
      return {
        ingangsdatum: ingangsdatumInit,
        einddatum: einddatumInit,
        borgsom: 0,
        tarieven: null,
      };
    }

    const { tarieven, ingangsdatum, einddatum } = genereerTarieven(
      ingangsdatumInit,
      einddatumInit,
      tariefsetVanProductmodel,
      abonnementen,
    );

    return {
      ingangsdatum,
      einddatum,
      borgsom: 0,
      tarieven,
    };
  }, [props.tarNomID, abonnementen, tariefsetVanProductmodel]);

  const ophalenTariefsetsVoorProductmodel = useCallback(
    async (tarNomID: number) => {
      const result = await api.v2.aanbod.tarieven.ophalenTariefsets({
        filterSchema: {
          filters: [
            {
              naam: 'TARNOM_IDS',
              data: [tarNomID],
            },
          ],
        },
      });
      setTariefsetVanProductmodel(result.tariefsets[0]);

      if (abonnementen === null) {
        return;
      }

      const bag = formikRef.current!.getFormikBag();
      const { tarieven, ingangsdatum } = genereerTarieven(
        bag.values.ingangsdatum,
        bag.values.einddatum,
        result.tariefsets[0],
        abonnementen,
      );
      formikRef.current!.setFieldValue('tarieven', tarieven);
      formikRef.current!.setFieldValue('ingangsdatum', ingangsdatum);
    },
    [abonnementen],
  );

  useEffect(() => {
    ophalenTariefsetsVoorProductmodel(props.tarNomID);
  }, [props.tarNomID]);

  return (
    <Dialoog index={dialoogIndex || 0} onEscape={onAnnuleren}>
      <ModalHeader>
        <ModalTitle>Tarief wijzigen</ModalTitle>
      </ModalHeader>
      {abonnementen === null || initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isInitialValid={initialValues.tarieven !== null}
          render={(formik: FormikProps<IFormikValues>) => {
            return (
              <>
                <ModalBody>
                  <div className="row">
                    <div className="col-12">
                      {formik.values.tarieven !== null && (
                        <>
                          <div className="form-group">
                            <div className="row d-flex">
                              <div className="col-4">
                                <label>{veldnamen.ingangsdatum}</label>
                                <Field
                                  name={nameof<IFormikValues>('ingangsdatum')}
                                  render={({ field, form }: FieldProps<IFormikValues>) => {
                                    const minDate = addDays(new Date(), -7);
                                    const maxDate = addDays(new Date(), +1);
                                    return (
                                      <DatumKiezer
                                        waarde={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        // determineValidDate={(date) => {
                                        //   return date >= minDate;
                                        // }}
                                        determinePreviousValidDate={(date) => {
                                          const newDate = addDays(date, -1);
                                          return newDate;

                                          // if (newDate >= minDate) {
                                          //   return newDate;
                                          // }
                                          // return null;
                                        }}
                                        determineNextValidDate={(date) => {
                                          const newDate = addDays(date, +1);
                                          return newDate;

                                          // if (newDate < maxDate) {
                                          //   return newDate;
                                          // }
                                          // return null;
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-4">
                                <label>{veldnamen.einddatum}</label>
                                <Field
                                  name={nameof<IFormikValues>('einddatum')}
                                  render={({ field, form }: FieldProps<IFormikValues>) => {
                                    const minDate = addDays(new Date(), -7);
                                    const maxDate = addDays(new Date(), +1);
                                    return (
                                      <DatumKiezer
                                        waarde={field.value}
                                        isClearable={true}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        // determineValidDate={(date) => {
                                        //   return date >= minDate;
                                        // }}
                                        determinePreviousValidDate={(date) => {
                                          const newDate = addDays(date, -1);
                                          return newDate;

                                          // if (newDate >= minDate) {
                                          //   return newDate;
                                          // }
                                          // return null;
                                        }}
                                        determineNextValidDate={(date) => {
                                          const newDate = addDays(date, +1);
                                          return newDate;

                                          // if (newDate < maxDate) {
                                          //   return newDate;
                                          // }
                                          // return null;
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="d-flex align-items-center mb-1">
                              <span>{veldnamen.tarieven}</span>
                              <div className="flex-fill" />
                              <a
                                href="#"
                                onClick={() => {
                                  formikRef.current!.setFieldValue(
                                    nameof<IFormikValues>('tarieven'),
                                    tarievenVoorReset!,
                                  );
                                }}
                              >
                                Tarieven resetten
                              </a>
                            </div>
                            <Field
                              name={nameof<IFormikValues>('tarieven')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                const tarieven: IAbonnementTarief[] = field.value!;
                                return tarieven.map((tarief, i) => {
                                  const wijzigTarief = (nieuwTarief: IAbonnementTarief) => {
                                    form.setFieldValue(
                                      field.name,
                                      tarieven.map((x) => {
                                        if (x.abonID === tarief.abonID) {
                                          return nieuwTarief;
                                        }

                                        return x;
                                      }),
                                    );
                                  };

                                  const abonnement = abonnementen!.find(
                                    (abonnement) => abonnement.AbonID === tarief.abonID,
                                  )!;
                                  return (
                                    <div
                                      key={abonnement.AbonID}
                                      className={classNames([i === 0 ? null : 'mt-2'])}
                                    >
                                      <div className={classNames(['d-flex align-items-center'])}>
                                        <VinkVeld
                                          aangevinkt={tarief.actief}
                                          onGewijzigd={(aangevinkt) => {
                                            wijzigTarief({ ...tarief, actief: aangevinkt });
                                          }}
                                        />
                                        <span className="ml-2 flex-fill">
                                          <InactiefOverlay
                                            isInactief={!tarief.actief}
                                            element={
                                              <AbonnementTariefFormulier
                                                abonnement={abonnement}
                                                maandHuur={tarief.maandHuur}
                                                eenmaligBedrag={tarief.eenmaligBedrag}
                                                onMaandHuurChange={(value) =>
                                                  wijzigTarief({ ...tarief, maandHuur: value })
                                                }
                                                onEenmaligBedragChange={(value) =>
                                                  wijzigTarief({ ...tarief, eenmaligBedrag: value })
                                                }
                                              />
                                            }
                                            onClick={() => {
                                              wijzigTarief({ ...tarief, actief: true });
                                            }}
                                          />
                                        </span>
                                      </div>
                                      {i + 1 !== tarieven.length && (
                                        <div className="mt-2">
                                          <HorizontaleScheidingslijn />
                                        </div>
                                      )}
                                    </div>
                                  );
                                });
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label>{veldnamen.borgsom}</label>
                            <Field
                              name={nameof<IFormikValues>('borgsom')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                return (
                                  <BedragInput
                                    value={field.value}
                                    onChange={(value) => form.setFieldValue(field.name, value)}
                                  />
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={formik.submitForm}
                    style={{ width: 100 }}
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default TariefWijzigenDialoog;
