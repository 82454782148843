import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { IOphalenRolXAspGebrsResultElement } from '../../../../../../../shared/src/api/v2/autorisatie/rolXAspGebr';
import { TypedColumn } from '../../../../../helpers/dxTableGrid';
import { IOphalenGebruikersResultElement } from '../../../../../../../shared/src/api/v2/gebruiker';
import ASPGebruikerSelectieDialoog from '../../../../../components/dialogen/ASPGebruikerSelectieDialoog';
import { EResultType } from '../../../../../stores/CheckStore';
import ASPTabel from '../../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';

interface ISelectieDialoogState {
  uitgeslotenAspGebrIDs: number[];
}

enum EKolom {
  Naam,
}

interface IProps {
  rolXAspGebrs: IOphalenRolXAspGebrsResultElement[];
  onGemuteerd: (aspGebruikerIDs: number[]) => void;
}

const GebruikerWeergave = observer((props: IProps) => {
  const { gebruikerStore, checkStore } = useContext(RootStoreContext);

  const [selectieDialoogState, setSelectieDialoogState] = useState<ISelectieDialoogState | null>(
    null,
  );

  const gekozenGebruikers = useMemo(() => {
    if (gebruikerStore.gebruikers === null) {
      return null;
    }
    return props.rolXAspGebrs.map(
      (x) => gebruikerStore.gebruikers!.find((gebr) => gebr.AspGebrID === x.AspGebrID)!,
    );
  }, [gebruikerStore.gebruikers, props.rolXAspGebrs]);

  const keyExtractor = useCallback(
    (aspGebruiker: IOphalenGebruikersResultElement) => aspGebruiker.AspGebrID,
    [],
  );
  const kolommen = useMemo<ASPKolom<EKolom, IOphalenGebruikersResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (aspGebruiker) => aspGebruiker.GebruikersNaam,
      },
    ],
    [],
  );

  const handleToevoegenRij = useCallback(() => {
    setSelectieDialoogState({
      uitgeslotenAspGebrIDs: gekozenGebruikers!.map((x) => x.AspGebrID),
    });
  }, [setSelectieDialoogState, gekozenGebruikers]);

  const handleVerwijderenRij = useCallback(
    async (aspGebruiker: IOphalenGebruikersResultElement) => {
      props.onGemuteerd(
        props.rolXAspGebrs.map((x) => x.AspGebrID).filter((x) => x !== keyExtractor(aspGebruiker)),
      );
    },
    [props.onGemuteerd, props.rolXAspGebrs, keyExtractor],
  );

  return (
    <div className="d-flex flex-column flex-fill p-3">
      <div className="d-flex justify-content-between mb-2">
        <h4>Gebruikers</h4>
        <a
          href="#"
          onClick={async (ev) => {
            ev.preventDefault();

            if (
              (await checkStore.bevestigen({ inhoud: 'Uitpoetsen bevestigen?' })).type ===
              EResultType.Annuleren
            ) {
              return;
            }

            props.onGemuteerd([]);
          }}
        >
          Alles uitpoetsen
        </a>
      </div>
      {gekozenGebruikers && (
        <ASPTabel
          rijen={gekozenGebruikers}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          onToevoegenRij={handleToevoegenRij}
          onVerwijderenRij={handleVerwijderenRij}
        />
      )}
      {selectieDialoogState !== null && (
        <ASPGebruikerSelectieDialoog
          open
          onSuccess={(result) => {
            props.onGemuteerd([...props.rolXAspGebrs.map((x) => x.AspGebrID), result.aspGebrID]);
            setSelectieDialoogState(null);
          }}
          onAnnuleren={() => setSelectieDialoogState(null)}
          uitgeslotenAspGebrIDs={selectieDialoogState.uitgeslotenAspGebrIDs}
        />
      )}
    </div>
  );
});

export default GebruikerWeergave;
