import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import React, { useEffect, useMemo, useState } from 'react';
import {
  IOphalenMutatieBestandenResult,
  IOphalenMutatiesResultElement,
} from '../../../../../../../../shared/src/api/v2/bank/mutaties';
import TabelInspringBlok from '../../../../../../components/layout/TabelInspringBlok';
import { TableRowDetailWrapper } from '../../../../../../helpers/dxTableGrid';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../../../components/layout/VerticaleScheidingslijn';
import useUpload from '../../../../../../core/useUpload';
import BijlagenContainer, {
  BestandType,
  IExternBestand,
} from '../../../../../../components/BijlagenContainer';
import BijlageKnop, { EOrientatie } from '../../../../../../components/BijlageKnop';
import api from '../../../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import {
  IOphalenBestandenResult,
  IOphalenBestandenResultElement,
} from '../../../../../../../../shared/src/api/v2/bestand/bestand';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IOphalenMutatiesResultElement = props.row;
  const [mutatieBestandenResult, setMutatieBestandenResult] = useState<
    IRemoteData<IOphalenMutatieBestandenResult>
  >(createPendingRemoteData());
  const [bestandenResult, setBestandenResult] = useState<IRemoteData<IOphalenBestandenResult>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    (async () => {
      const result = await api.v2.bank.mutatie.ophalenMutatieBestanden({
        filterSchema: {
          filters: [
            {
              naam: 'BANK_MUT_IDS',
              data: [row.BankMutID],
            },
          ],
        },
      });

      setMutatieBestandenResult(createReadyRemoteData(result));
    })();
  }, [row.BankMutID]);

  useEffect(() => {
    if (mutatieBestandenResult.state === ERemoteDataState.Pending) {
      return;
    }
    (async () => {
      const result = await api.v2.bestand.ophalenBestanden({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: mutatieBestandenResult.data!.mutatieBestanden.map((x) => x.BestandID),
            },
          ],
        },
      });

      setBestandenResult(createReadyRemoteData(result));
    })();
  }, [mutatieBestandenResult]);

  if (bestandenResult.state === ERemoteDataState.Pending) {
    return <LoadingSpinner />;
  }
  return <DetailComponentInner row={row} bestanden={bestandenResult.data!.bestanden} />;
};

interface IInnerProps {
  row: IOphalenMutatiesResultElement;
  bestanden: IOphalenBestandenResultElement[];
}

const DetailComponentInner = (props: IInnerProps) => {
  const row = props.row;

  const {
    moetNogUploaden,
    isBezigMetUploaden,
    uploadProgresses,
    bestanden,
    muteerBestanden,
  } = useUpload(
    useMemo(
      () => ({
        initieleBestanden: props.bestanden.map(
          (bestand): IExternBestand => ({
            type: BestandType.ASPDrive,
            bestand,
          }),
        ),
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (ids: number[]) => {
          await api.v2.bank.mutatie.muterenMutatieBestanden({
            bankMutID: row.BankMutID,
            bestandIDs: ids,
          });
          // await inboekenContext.onRequestRefresh();
        },
      }),
      [row.BankMutID, props.bestanden],
    ),
  );

  return (
    <TableRowDetailWrapper>
      <div className="d-flex">
        <TabelInspringBlok />
        <div className="d-flex p-3">
          <div style={{ width: 500 }}>
            <GegevensLayout
              gegevens={[
                {
                  label: 'IBAN',
                  waarde: row.IBAN,
                },
                {
                  label: 'Rekeningnaam',
                  waarde: row.Rekeningnaam,
                },
                {
                  label: 'Omschrijving',
                  waarde: row.Omschrijving,
                },
                {
                  label: 'Betalingskenmerk',
                  waarde: row.Betalingskenmerk,
                },
              ]}
            />
          </div>

          <VerticaleScheidingslijn className="ml-3 mr-3" height={80} />

          <div style={{ width: 300 }}>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Transactiecode',
                  waarde: row.Transactiecode !== null ? row.Transactiecode : 'Niet opgegeven',
                },
                {
                  label: 'Batchreferentie',
                  waarde: row.BatchReferentie,
                },
                {
                  label: 'AantalTransactiesInBatch',
                  waarde:
                    row.AantalTransactiesInBatch !== null
                      ? row.AantalTransactiesInBatch.toString()
                      : '',
                },
                {
                  label: 'Stornocode',
                  waarde: row.Returncode,
                },
              ]}
            />
          </div>
        </div>
        <div className="d-flex flex-column flex-fill p-2">
          <h5>Bestanden</h5>
          <div className="d-flex flex-fill mt-1">
            <div style={{ height: '100%' }} className="mr-2">
              <BijlageKnop
                disabled={isBezigMetUploaden}
                onBijgevoegd={(bijlagen) =>
                  muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
                }
                orientatie={EOrientatie.Verticaal}
              />
            </div>
            <div>
              <BijlagenContainer
                bestanden={bestanden}
                uploadProgresses={uploadProgresses}
                onBestandenChange={(bijlagen) => muteerBestanden((_) => bijlagen)}
                bestandenMuterenToegestaan={!isBezigMetUploaden}
              />
            </div>
          </div>
        </div>
      </div>
    </TableRowDetailWrapper>
  );
};

export default DetailComp;
