import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import api from '../../../../../../../api';
import useUrlState from '../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { KlantContext } from '../../Klant/index';
import { IOphalenUitnodigingenResultElement } from '../../../../../../../../../shared/src/api/v2/review/klant';
import { IconToevoegen, IconVerwijderen } from '../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../../shared/src/api/v2/relatie';
import nameof from '../../../../../../../core/nameOf';
import { format } from 'date-fns';
import PersoonVisualisatie from '../../../../../../../components/personalia/PersoonVisualisatie';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

export interface IRow extends IOphalenUitnodigingenResultElement {}

const Reviews: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'selectie-review-uitnodigingen',
  );
  // const { klantkaartStore } = useContext(RootStoreContext);
  // const klantContext = useContext(KlantContext);
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const [reviews, setReviews] = useState<IOphalenUitnodigingenResultElement[] | null>(null);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const ophalenUitnodigingen = useCallback(async () => {
    const reviews = (
      await api.v2.review.ophalenUitnodigingen({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [props.relID] }],
        },
      })
    ).uitnodigingen;

    setReviews(reviews);
  }, [props.relID]);

  useEffect(() => {
    ophalenUitnodigingen();
  }, [ophalenUitnodigingen]);

  const ophalenRelatie = useCallback(async () => {
    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.relID] }],
        },
      })
    ).relaties;

    setRelatie(relaties[0]);
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.review.checkVerwijderenUitnodigingen({
      ids: urlState.selectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Uitnodigingen verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.review.verwijderenUitnodigingen({
      ids: urlState.selectie,
    });

    await ophalenUitnodigingen();
    await klantkaartStore.verversRelatie();
    // props.onRequestRefresh();
  }, [urlState.selectie, ophalenUitnodigingen]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'DatumUitgenodigd',
        title: 'Datum uitgenodigd',
      },
      {
        name: '__persoon' as any,
        title: 'Persoon',
      },
      {
        name: 'Email',
        title: 'Email',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'DatumUitgenodigd',
        width: 150,
      },
      {
        columnName: '__persoon' as any,
        width: 250,
      },
      {
        columnName: 'Email',
        width: 300,
      },
    ],
    [],
  );

  return reviews === null || relatie === null ? (
    <div className="d-flex flex-fill justify-content-center align-items-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            disabled={relatie.GeenReviewUitnodiging}
            onClick={async () => {
              const persID = relatie.PersoonPrimair_PersID;
              if (persID === null) {
                await checkStore.melden({
                  titel: `Er is geen primaire persoon bekend bij deze relatie`,
                });
              }

              const checkData = await api.v2.review.checkToevoegenUitnodigingen({
                uitnodigingen: [{ persID: persID!, relID: props.relID }],
              });
              if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: (
                      <span>Wil je een reviewuitnodiging versturen voor de primaire persoon?</span>
                    ),
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              const result = await api.v2.review.toevoegenUitnodigingen({
                uitnodigingen: [{ persID: persID!, relID: props.relID }],
              });

              await api.v2.review.versturenUitnodigingen({ ids: result.ids });

              await ophalenUitnodigingen();
              await klantkaartStore.verversRelatie();
            }}
          >
            <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Uitnodiging versturen</span>
          </button>
          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-2"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            disabled={urlState.selectie.length === 0}
            onClick={() => handleVerwijderen()}
          >
            <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Verwijderen</span>
          </button>
        </div>
      </div>

      {reviews === null || relatie === null ? (
        <LoadingSpinner />
      ) : (
        <>
          {relatie.GeenReviewUitnodiging && (
            <div
              style={{
                backgroundColor: Kleur.Geel,
                color: Kleur.BeetjeDonkerGrijs,
              }}
              className="p-2 pl-4 pr-4"
            >
              Geen uitnodiging sturen voor deze relatie
            </div>
          )}
          <GridStyleWrapper height={'calc(100vh - 150px)'}>
            <Grid rows={reviews} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={[nameof<IRow>('DatumUitgenodigd')]}
                formatterComponent={(formatterProps) => {
                  if (formatterProps.value === null) {
                    return <span>Nog versturen</span>;
                  }
                  return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                }}
              />
              <DataTypeProvider
                for={['__persoon']}
                formatterComponent={(formatterProps) => {
                  const rij: IRow = formatterProps.row;
                  if (rij.PersID === null) {
                    return <span></span>;
                  }
                  return (
                    <PersoonVisualisatie
                      persID={rij.PersID}
                      options={{ geenLinkToepassen: true }}
                    />
                  );
                }}
              />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />

              <RowDetailState defaultExpandedRowIds={[]} />

              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />

              <EditingState
                onCommitChanges={(changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1];
                  alert(id);
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  // props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                  // alert(id);
                }}
              />

              <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={() => <span></span>}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}
    </div>
  );
});

export default withRouter(Reviews);
