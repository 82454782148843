import api from '../../../..';
import {
  IOphalenAfschrijvingenParams,
  IOphalenAfschrijvingenResult,
  IToevoegenAfschrijvingParams,
  IToevoegenAfschrijvingResult,
  IMakenBoekingParams,
  IMakenBoekingResult,
  IVerwijderenAfschrijvingenParams,
  IVerwijderenAfschrijvingenResult,
  IBerekenenAfschrijvingenParams,
  IBerekenenAfschrijvingenResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/activaregister/afschrijvingen';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const afschrijvingen = {
  ophalenAfschrijvingen: async (
    params: IOphalenAfschrijvingenParams,
  ): Promise<IOphalenAfschrijvingenResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/afschrijvingen/ophalen-afschrijvingen',
      params,
    );
  },
  checkToevoegenAfschrijving: async (params: IToevoegenAfschrijvingParams): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/activaregister/afschrijvingen/check-toevoegen-afschrijving',
      params,
    );
  },
  toevoegenAfschrijving: async (
    params: IToevoegenAfschrijvingParams,
  ): Promise<IToevoegenAfschrijvingResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/afschrijvingen/toevoegen-afschrijving',
      params,
    );
  },
  makenBoeking: async (params: IMakenBoekingParams): Promise<IMakenBoekingResult> => {
    return await api.post('/v2/boekhouding/activaregister/afschrijvingen/maken-boeking', params);
  },
  checkVerwijderenAfschrijvingen: async (
    params: IVerwijderenAfschrijvingenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/activaregister/afschrijvingen/check-verwijderen-afschrijvingen',
      params,
    );
  },
  verwijderenAfschrijvingen: async (
    params: IVerwijderenAfschrijvingenParams,
  ): Promise<IVerwijderenAfschrijvingenResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/afschrijvingen/verwijderen-afschrijvingen',
      params,
    );
  },
  berekenenAfschrijvingen: async (
    params: IBerekenenAfschrijvingenParams,
  ): Promise<IBerekenenAfschrijvingenResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/afschrijvingen/berekenen-afschrijvingen',
      params,
    );
  },
};

export default afschrijvingen;
