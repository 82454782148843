import api from '../../../index';
import levering from './levering';
import retour from './retour';

const reservering = {
  levering,
  retour,
};

export default reservering;
