import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  formatteerAdresV2,
  formatteerOrganisatieNaam,
  formatteerPersoonNaam,
} from '../../../../helpers';
import { Adres, Header, HeaderIcon, Relatienummer, Wrapper } from './style';
import {
  IconInklappen,
  IconKlantkaart,
  IconLeeftijd,
  IconLeverancier,
  IconLink,
  IconMap,
  IconOrganisatie,
  IconPersoon,
  IconReview,
  IconTaal,
  IconToevoegen,
  IconUitklappen,
  IconWijzigen,
} from '../../../Icons';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import { Kleur as EKleur, Kleur } from '../../../../bedrijfslogica/constanten';
import TelefoonComponent from '../../../communicatie/TelefoonComponent';
import EmailComponent from '../../../communicatie/EmailComponent';
import {
  IOphalenInformatieVoorOverzichtResult,
  IOphalenRelatiesResultElementV2,
} from '../../../../../../shared/src/api/v2/relatie';
import { EKaartType } from '../../root';
import AdresVisualisatie from '../../../locatie/AdresVisualisatie';
import {
  IOphalenDebiteurenBetaalgedragResultElement,
  IOphalenFinancieleInformatieResult,
} from '../../../../../../shared/src/api/v2/debiteur';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import PersoonWijzigenDialoog from '../../../dialogen/PersoonWijzigenDialoog';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import _ from 'lodash';
import ActieMenuKnop from '../../../ActieMenuKnop';
import HandmatigControltaakToevoegenDialoog from '../../../control/HandmatigControltaakToevoegenDialoog';
import { RouterContext } from '../../../../routers/RouterContextProvider';
import { EResultType } from '../../../../stores/CheckStore';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import { BetaalgedragIndicatie } from '../../../../paginas/Debiteuren/Beheer/BetaalgedragIndicatie';
import FormatteerBedrag, { StandaardMutatieBedragOpmaker } from '../../../MutatieBedrag';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import PinKnop from './PinKnop';
import SjabloonOplosserOpenenKnop from './SjabloonOplosserOpenenKnop';
import GegevensLayout from '../../../layout/GegevensLayout';
import KopieerNaarKlembord from '../../../KopieerNaarKlembord';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { EBetaalwijze } from '../../../../bedrijfslogica/enums';
import KredietwaardigheidIndicatie, {
  Kredietscore,
} from '../../../../paginas/Debiteuren/Beheer/KredietwaardigheidIndicatie';
import {
  IKredietwaardigheidstoetsResultaatMessageData,
  IOphalenKrediettoetsenResultElement,
} from '../../../../../../shared/src/api/v2/kredietwaardigheid';
import { useRealtimeListener } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import ToevoegenEscalatieDialoog from '../../../../paginas/Escalaties/ToevoegenEscalatieDialoog';

interface IRelatieIndentiteitWijzigenDialoogState {
  persID?: number;
  orgID?: number;
}

interface IPrimairPersoonOrganisatieWijzigenDialoogState {
  persID: number;
}

interface ITaakToevoegenDialoogState {}
interface IEscalatieToevoegenDialoogState {}

interface IProps {
  relID: number;
  relatie: IOphalenRelatiesResultElementV2;
  debiteurInformatie?: IOphalenFinancieleInformatieResult;
  type: EKaartType;
  onVerversenAangevraagd: () => void;
}

const Identiteit: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const { navigeerLeverancierNaarRelID, navigeerKlantkaartNaarRelID } = useContext(RouterContext);
  const { relatie, relID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [uitgeklapt, setUitgeklapt] = useState(false);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [oudsteContractdatum, setOudsteContractdatum] = useState<Date | null | undefined>(
    undefined,
  );
  const [
    betaalgedrag,
    setBetaalgedrag,
  ] = useState<IOphalenDebiteurenBetaalgedragResultElement | null>(null);
  const [krediettoets, setKrediettoets] = useState<
    IRemoteData<IOphalenKrediettoetsenResultElement | null>
  >(createPendingRemoteData());

  const [
    taakToevoegenDialoogState,
    setTaakToevoegenDialoogState,
  ] = useState<ITaakToevoegenDialoogState | null>(null);

  const [
    escalatieToevoegenDialoogState,
    setEscalatieToevoegenDialoogState,
  ] = useState<IEscalatieToevoegenDialoogState | null>(null);

  const ophalenContracten = useCallback(async () => {
    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [relID],
            },
          ],
        },
      })
    ).contracten;

    const contractenBasis = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'CNTBASIS_IDS',
              data: contracten.map((x) => x.basis.CntBasisID),
            },
            {
              naam: 'VOLGNUMMERS',
              data: [0],
            },
          ],
        },
      })
    ).contracten;

    setOudsteContractdatum(
      contracten.length !== 0
        ? _.min(contractenBasis.filter((x) => x.Volgnummer === 0).map((x) => x.Ingangsdatum))
        : null,
    );

    setContracten(contracten);
  }, [relID]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  useEffect(() => {
    (async () => {
      const result = (
        await api.v2.debiteur.ophalenDebiteurenBetaalgedrag({
          relIDs: [relatie.RelID],
        })
      ).debiteuren[0];

      setBetaalgedrag(result);
    })();
  }, [relatie.RelID]);

  const ophalenKrediettoets = useCallback(async () => {
    const result = await api.v2.kredietwaardigheid.ophalenKrediettoetsen({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relatie.RelID],
          },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'DATUM',
            richting: 'DESC',
          },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 1,
      },
    });
    const toets = result.toetsen[0] ?? null;
    setKrediettoets(createReadyRemoteData(toets));
  }, [relatie.RelID]);

  useRealtimeListener(
    async (key, data) => {
      if (key === 'KREDIETSWAARDIGHEIDTOETS_RESULTAAT') {
        const resultaat: IKredietwaardigheidstoetsResultaatMessageData = data;
        if (resultaat.persID !== relatie.PersoonPrimair_PersID) {
          return;
        }

        await ophalenKrediettoets();
      }
    },
    [relatie.PersoonPrimair_PersID, ophalenKrediettoets],
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenKrediettoets();
  }, [ophalenKrediettoets]);

  const [informatie, setInformatie] = useState<IRemoteData<IOphalenInformatieVoorOverzichtResult>>(
    createPendingRemoteData(),
  );
  const ophalenInformatie = useCallback(async () => {
    const result = await api.v2.relatie.ophalenInformatieVoorOverzicht({
      relID: props.relID,
    });

    setInformatie(createReadyRemoteData(result));
  }, [props.relID]);
  useEffect(() => {
    ophalenInformatie();
  }, [ophalenInformatie]);

  const [
    relatieIdentiteitWijzigenDialoogState,
    setRelatieIdentiteitWijzigenDialoogState,
  ] = useState<IRelatieIndentiteitWijzigenDialoogState | null>(null);
  const [naamHovered, setNaamHovered] = useState(false);

  const [
    primairPersoonOrganisatieWijzigenDialoogState,
    setPrimairPersoonOrganisatieWijzigenDialoogState,
  ] = useState<IPrimairPersoonOrganisatieWijzigenDialoogState | null>(null);
  const [primairPersoonOrganisatieHovered, setPrimairPersoonOrganisatieHovered] = useState(false);

  const Icon = relatie.Relatiesoort === 'P' ? <IconPersoon /> : <IconOrganisatie />;

  const geboortedatum: Date | null = useMemo(() => {
    if (relatie.persoon === null) {
      return null;
    }
    return relatie.persoon!.Geboortedatum !== null
      ? new Date(relatie.persoon!.Geboortedatum)
      : null;
  }, [relatie.persoon]);

  const standaardRekening = useMemo(() => {
    // tslint:disable-next-line:variable-name
    const standaardrekening_RelRekID = relatie.financieel.StandaardRekening_RelRekID;
    return standaardrekening_RelRekID !== null
      ? relatie.financieel.rekeningen.find((x) => x.RelRekID === standaardrekening_RelRekID)!
      : null;
  }, [relatie.financieel.rekeningen, relatie.financieel.StandaardRekening_RelRekID]);

  return (
    <>
      <Header className="pl-3 pr-3 pt-3 pb-3">
        <div className="d-flex">
          <div className="flex-fill">
            <Wrapper>
              <div className="d-flex flex-fill align-items-center">
                <HeaderIcon>{Icon}</HeaderIcon>

                <div
                  className="ml-2"
                  // style={{ marginLeft: 5 }}
                  onMouseEnter={() => setNaamHovered(true)}
                  onMouseLeave={() => setNaamHovered(false)}
                >
                  <h5 style={{ display: 'inline', margin: 0 }}>
                    {relatie.Relatiesoort === 'P'
                      ? formatteerPersoonNaam({
                          voornaam: relatie.persoon!.Voornaam,
                          achternaam: relatie.persoon!.Achternaam,
                          voorletters: relatie.persoon!.Voorletters,
                          aanhefKort: relatie.persoon!.geslacht.AanhefKort,
                          voorvoegsel: relatie.persoon!.Voorvoegsel,
                        })
                      : formatteerOrganisatieNaam({
                          naam: relatie.organisatie!.Naam,
                        })}
                  </h5>

                  {naamHovered ? (
                    <button
                      className="ml-2"
                      style={{
                        outline: 0,
                        border: 0,
                        padding: 0,
                        background: 0,
                        display: 'inline',
                        position: 'relative',
                        top: -2,
                      }}
                      onClick={() => {
                        setRelatieIdentiteitWijzigenDialoogState({
                          persID:
                            relatie.Relatiesoort === 'P' ? relatie.persoon!.PersID : undefined,
                          orgID:
                            relatie.Relatiesoort === 'O' ? relatie.organisatie!.OrgID : undefined,
                        });
                      }}
                    >
                      <IconWijzigen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                    </button>
                  ) : (
                    <span style={{ width: 0, height: 16 }} />
                  )}
                </div>

                <div className="flex-fill" />
                <span>
                  <Relatienummer>{relatie.Relatienummer}</Relatienummer>
                </span>
              </div>

              {/*<DropdownWrapper>*/}
              {/*<MenuDropdown*/}
              {/*  opties={[*/}
              {/*    { tekst: 'Locatie opgeven', onclick: () => setModalLocatieSelecteren(true) },*/}
              {/*  ]}*/}
              {/*/>*/}
              {/*</DropdownWrapper>*/}
            </Wrapper>
            <span className="d-flex align-items-end mt-2">
              <Adres>
                {/*<IcoonLocatie style={{ width: 15, height: 15, fill: Kleur.Grijs }} />*/}
                {/*<span className="ml-2">*/}
                {relatie.adres === null ? (
                  'Adres niet opgegeven'
                ) : (
                  <>
                    <AdresVisualisatie
                      weergaveTekst={formatteerAdresV2({
                        bisnummer: relatie.adres.Bisnummer,
                        straatnaam: relatie.adres.Straatnaam,
                        huisnummer: relatie.adres.Huisnummer,
                        postcode: relatie.adres.Postcode,
                        plaatsnaam: relatie.adres.Plaatsnaam,
                        landnaamKort: relatie.adres.LandnaamKort,
                        landnaamEnum: relatie.adres.LandnaamEnum,
                      })}
                    />
                    <span className="ml-2">
                      {relatie.persoon !== null && relatie.persoon.InterneLocatie !== null
                        ? ` (${relatie.persoon.InterneLocatie})`
                        : ''}
                    </span>
                  </>
                )}
                {/*</span>*/}
              </Adres>
              <div className="flex-fill" />
            </span>
            <span className="d-flex mt-2">
              {relatie.Relatiesoort === 'O' ? (
                <div style={{ fontSize: `14px`, fontWeight: `bold` }}>
                  <div
                    className="d-flex align-items-center"
                    onMouseEnter={() => setPrimairPersoonOrganisatieHovered(true)}
                    onMouseLeave={() => setPrimairPersoonOrganisatieHovered(false)}
                  >
                    {relatie.persoon === null
                      ? 'Geen primair contactpersoon opgegeven'
                      : formatteerPersoonNaam({
                          voornaam: relatie.persoon!.Voornaam,
                          achternaam: relatie.persoon!.Achternaam,
                          voorletters: relatie.persoon!.Voorletters,
                          aanhefKort: relatie.persoon!.geslacht.AanhefKort,
                          voorvoegsel: relatie.persoon!.Voorvoegsel,
                        })}

                    {primairPersoonOrganisatieHovered && relatie.persoon !== null ? (
                      <button
                        className="ml-2"
                        style={{
                          outline: 0,
                          border: 0,
                          padding: 0,
                          background: 0,
                          display: 'inline',
                          position: 'relative',
                          top: -2,
                        }}
                        onClick={() => {
                          setPrimairPersoonOrganisatieWijzigenDialoogState({
                            persID: relatie.persoon!.PersID,
                          });
                        }}
                      >
                        <IconWijzigen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                      </button>
                    ) : (
                      <span style={{ width: 0, height: 16 }} />
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </span>
            {/* {relatie.Relatiesoort === 'P' && ( */}
            <>
              <div className="d-flex flex-column">
                <div className="d-flex mt-2">
                  {relatie.persoon !== null && contracten !== null && betaalgedrag !== null && (
                    <div className="d-flex flex-fill align-items-start">
                      <div className="d-flex flex-fill flex-column">
                        {relatie.persoon!.Email !== null && (
                          <span
                            style={{
                              overflowWrap: 'break-word',
                              maxWidth: 300,
                            }}
                          >
                            <EmailComponent
                              emailAdres={relatie.persoon!.Email}
                              persID={relatie.persoon!.PersID}
                            />
                          </span>
                        )}
                        {relatie.persoon!.TelefoonMobiel !== null ? (
                          <div className="d-flex">
                            <TelefoonComponent telefoonNummer={relatie.persoon!.TelefoonMobiel} />
                          </div>
                        ) : (
                          <span>Telefoonnummer niet opgegeven</span>
                        )}
                        {relatie.organisatie !== null && relatie.organisatie.WebsiteURL !== null ? (
                          <span className="d-flex align-items-center">
                            <IconLink
                              style={{ width: 15, height: 15, fill: EKleur.Blauw, marginTop: 2 }}
                            />
                            <a
                              href={relatie.organisatie.WebsiteURL}
                              target="_blank"
                              className="ml-2"
                            >
                              {relatie.organisatie.WebsiteURL}
                            </a>
                          </span>
                        ) : (
                          <span></span>
                        )}
                        <div className="d-flex">
                          {relatie.persoon!.taal.TaalKort !== null && (
                            <>
                              <div className="d-flex align-items-center">
                                <IconTaal
                                  style={{
                                    width: 15,
                                    height: 15,
                                    fill: EKleur.Grijs,
                                    marginTop: 2,
                                  }}
                                />
                                <span className="ml-2 mt-1">{relatie.persoon!.taal.TaalKort}</span>
                              </div>
                            </>
                          )}

                          {relatie.Relatiesoort === 'P' &&
                            geboortedatum !== null &&
                            (differenceInYears(new Date(), geboortedatum) > 27 ||
                              differenceInYears(new Date(), geboortedatum) < 18) && (
                              <>
                                <div className="d-flex align-items-center ml-3">
                                  <IconLeeftijd
                                    style={{
                                      width: 15,
                                      height: 15,
                                      fill: EKleur.Grijs,
                                      marginTop: 2,
                                    }}
                                  />
                                  <span className="ml-2 mt-1">
                                    {maandenNaarJaren(
                                      differenceInMonths(new Date(), geboortedatum),
                                    )}
                                    {differenceInYears(new Date(), geboortedatum) < 18 && (
                                      <span className="ml-2" style={{ color: EKleur.Rood }}>
                                        Minderjarig
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </>
                            )}
                        </div>

                        {relatie.debiteur !== null && (
                          <>
                            {/* <div className="mt-1">
                              {relatie.debiteur.Betaaldag === -1 ? (
                                <span
                                // style={{
                                //   color: relatie.debiteur.Incassomachtiging
                                //     ? EKleur.Grijs
                                //     : EKleur.LichtGrijs,
                                // }}
                                >
                                  {relatie.debiteur.Incassomachtiging
                                    ? 'Incasso op stufidatum'
                                    : 'Betaaldag stufidatum'}
                                </span>
                              ) : (
                                <span
                                // style={{
                                //   color: relatie.debiteur.Incassomachtiging
                                //     ? EKleur.Grijs
                                //     : EKleur.LichtGrijs,
                                // }}
                                >
                                  {relatie.debiteur.Incassomachtiging
                                    ? `Incasso ${relatie.debiteur.Betaaldag}e van de maand`
                                    : `Betaaldag ${relatie.debiteur.Betaaldag}e van de maand`}
                                </span>
                              )}
                            </div> */}
                            {/* <div
                              className="d-flex justify-content-between"
                              // style={{
                              //   color: relatie.debiteur.Incassomachtiging
                              //     ? undefined
                              //     : EKleur.Grijs,
                              // }}
                            >
                              {standaardRekening !== null ? (
                                <span>
                                  {standaardRekening.IBAN} &ndash; {standaardRekening.Rekeningnaam}
                                </span>
                              ) : (
                                <span>Geen standaard rekening aangegeven</span>
                              )}
                            </div> */}

                            {/* <div className="d-flex justify-content-between align-items-center">
                              <span>
                                <UitlegTooltip inhoud="aantal lopende contracten / totaal aantal">
                                  Contracten&nbsp;
                                  {
                                    contracten.filter(
                                      (x) =>
                                        x.status.NaamEnum === EContractStatus.Concept ||
                                        x.status.NaamEnum === EContractStatus.Lopend,
                                    ).length
                                  }
                                  {'/'}
                                  {contracten.length}
                                </UitlegTooltip>
                              </span>
                            </div> */}

                            {props.debiteurInformatie !== undefined && (
                              <div className="d-flex justify-content-between align-items-center mt-1">
                                <span className="d-flex align-items-center">
                                  Openstaand&nbsp;
                                  <FormatteerBedrag
                                    bedrag={props.debiteurInformatie!.facturen.bedragOpenstaand}
                                  />
                                  {props.debiteurInformatie!.facturen.bedragVervallen !== 0 && (
                                    <span className="d-flex align-items-center ml-1">
                                      {'('}
                                      <UitlegTooltip inhoud="Het totale bedrag van de facturen die reeds vervallen zijn">
                                        <FormatteerBedrag
                                          bedrag={
                                            props.debiteurInformatie!.facturen.bedragVervallen
                                          }
                                          opmaakComponent={(opmaakProps) => {
                                            return (
                                              <StandaardMutatieBedragOpmaker
                                                {...opmaakProps}
                                                color={Kleur.Rood}
                                              />
                                            );
                                          }}
                                        />
                                      </UitlegTooltip>
                                      {')'}
                                    </span>
                                  )}
                                  {props.debiteurInformatie!.saldoDubieuzeDebiteuren !== 0 && (
                                    <span className="d-flex align-items-center ml-2">
                                      {'Dub.'}
                                      <UitlegTooltip inhoud="Het totaal aan dubieuze bedragen. Dit zijn de openstaande facturen waarvan het waarschijnlijk is dat deze niet meer voldaan zullen worden.">
                                        <FormatteerBedrag
                                          bedrag={props.debiteurInformatie!.saldoDubieuzeDebiteuren}
                                        />
                                      </UitlegTooltip>
                                    </span>
                                  )}
                                  {props.debiteurInformatie!.saldoVooruitbetaald !== 0 && (
                                    <span className="d-flex align-items-center ml-2">
                                      {'VU'}
                                      <UitlegTooltip inhoud="Het totaal aan vooruitbetaalde bedragen">
                                        <FormatteerBedrag
                                          bedrag={props.debiteurInformatie!.saldoVooruitbetaald}
                                        />
                                      </UitlegTooltip>
                                    </span>
                                  )}
                                  {props.debiteurInformatie!.saldoDebiteurenBetalingsregeling !==
                                    0 && (
                                    <span className="d-flex align-items-center ml-2">
                                      {'Saldo BR'}
                                      <UitlegTooltip inhoud="Het saldo betaald op een betalingsregeling. Dit is in feite een vooruitbetaald bedrag wat nog gebruikt kan worden om de facturen in de regeling af te boeken.">
                                        <FormatteerBedrag
                                          bedrag={
                                            props.debiteurInformatie!
                                              .saldoDebiteurenBetalingsregeling
                                          }
                                          // opmaakComponent={(opmaakProps) => {
                                          //   return (
                                          //     <StandaardMutatieBedragOpmaker
                                          //       {...opmaakProps}
                                          //       color={Kleur.Rood}
                                          //     />
                                          //   );
                                          // }}
                                        />
                                      </UitlegTooltip>
                                    </span>
                                  )}
                                </span>

                                <span className="d-flex align-items-center">
                                  {krediettoets.state === ERemoteDataState.Ready &&
                                    krediettoets.data !== null && (
                                      <KredietwaardigheidIndicatie
                                        score={krediettoets.data!.Score! as Kredietscore}
                                        className="mr-2"
                                      />
                                    )}
                                  <BetaalgedragIndicatie status={betaalgedrag.indicatie} />
                                </span>
                              </div>
                            )}

                            {props.debiteurInformatie !== undefined &&
                              props.debiteurInformatie!.saldoLopendeBankopdrachten !== 0 && (
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="d-flex align-items-center">
                                    {'Saldo in lopende bankopdrachten:'}
                                    <UitlegTooltip inhoud="">
                                      <FormatteerBedrag
                                        bedrag={
                                          props.debiteurInformatie!.saldoLopendeBankopdrachten
                                        }
                                        // opmaakComponent={(opmaakProps) => {
                                        //   return (
                                        //     <StandaardMutatieBedragOpmaker
                                        //       {...opmaakProps}
                                        //       color={Kleur.Rood}
                                        //     />
                                        //   );
                                        // }}
                                      />
                                    </UitlegTooltip>
                                  </span>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* {contracten !== null && (
              <div className="mt-2" style={{ justifyContent: 'space-between' }}>
                <div>
                  Aantal contracten:&nbsp;
                  {contracten.filter((x) => x.status.NaamEnum === EContractStatus.Lopend).length +
                    '/' +
                    contracten.length}
                </div>
                <div>
                  Huren sinds:&nbsp;
                  {oudsteContractdatum !== undefined
                    ? oudsteContractdatum !== null
                      ? format(new Date(oudsteContractdatum), 'dd-MM-yyyy')
                      : ''
                    : ''}
                </div>
              </div>
            )} */}

                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                  {/* {relatie.debiteur !== null && (
                <div className="d-flex">
                  <span style={{ textAlign: 'right' }}>
                    {relatie.debiteur!.betaalwijze.Naam}&nbsp;
                    {relatie.debiteur!.betaalwijze.NaamEnum === EBetaalwijze.Incasso &&
                    relatie.debiteur!.Betaaldag === -1
                      ? 'Stufidatum'
                      : relatie.debiteur!.Betaaldag.toString() + 'e v/d mnd'}
                  </span>
                </div>
              )} */}

                  {/* {props.debiteurInformatie &&
                props.debiteurInformatie.facturen.bedragOpenstaand !== 0 && (
                  <div className="d-flex align-items-center">
                    <span className="mr-2">Openstaand:</span>
                    <div className="d-flex align-items-center">
                      <MutatieBedrag bedrag={props.debiteurInformatie.facturen.bedragOpenstaand} />
                      {props.debiteurInformatie.facturen.bedragVervallen !== 0 && (
                        <span className="d-flex ml-2">
                          (
                          <MutatieBedrag
                            bedrag={props.debiteurInformatie.facturen.bedragVervallen}
                            opmaakComponent={(opmaakProps) => {
                              return (
                                <StandaardMutatieBedragOpmaker
                                  {...opmaakProps}
                                  color={Kleur.Rood}
                                />
                              );
                            }}
                          />
                          )
                        </span>
                      )}
                    </div>
                  </div>
                )} */}
                </div>

                {/* <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <div className="d-flex">
                {props.debiteurInformatie && props.debiteurInformatie.saldoVooruitbetaald !== 0 ? (
                  <>
                    <span className="d-flex mr-2">Saldo vooruitbetaald:</span>
                    <span>
                      <span>
                        <MutatieBedrag bedrag={props.debiteurInformatie.saldoVooruitbetaald} />
                      </span>
                    </span>
                  </>
                ) : (
                  <span className="mr-2"></span>
                )}
              </div>

              <div className="d-flex">
                <span></span>
              </div>
            </div> */}

                {/* <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <div className="d-flex">
                Aantal contracten:{' '}
                {contracten !== null
                  ? _.min(contracten!.map((x) => new Date(x.IngangsdatumBasis)))
                  : ''}
              </div>
            </div> */}
              </div>
            </>
          </div>
          <div
            className="ml-2 d-flex flex-column align-items-end"
            style={{
              position: 'relative',
              right: -10,
            }}
          >
            <ActieMenuKnop
              acties={[
                {
                  text: 'Taak toevoegen',
                  icon: <IconToevoegen style={{ width: 16, height: 16 }} />,
                  onClick: () => {
                    setTaakToevoegenDialoogState({});
                  },
                },
                'scheiding',
                {
                  text: 'Escalatie toevoegen',
                  icon: <IconToevoegen style={{ width: 16, height: 16 }} />,
                  onClick: async () => {
                    const checkData = await api.v2.escalatie.checkOpenDialoogToevoegenEscalatie({
                      relID: relatie.RelID,
                    });
                    if (
                      (
                        await checkStore.controleren({
                          checkData,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }
                    setEscalatieToevoegenDialoogState({});
                  },
                },
                'scheiding',
                {
                  text: 'Review-uitnodiging voorstellen',
                  icon: <IconReview style={{ width: 16, height: 16 }} />,
                  onClick: async () => {
                    const params = {
                      uitnodigingen: [
                        { persID: relatie.PersoonPrimair_PersID!, relID: props.relID },
                      ],
                    };

                    const checkData = await api.v2.review.checkToevoegenUitnodigingen(params);
                    const checkResult = await checkStore.controleren({ checkData });
                    if (checkResult.type === EResultType.Annuleren) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: <span>Wil je een review-uitnodiging voorstellen?</span>,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.review.toevoegenVoorstelUitnodigingen(params);
                  },
                },
                {
                  text: `Ga naar Externe relatiemap`,
                  disabled: relatie.ExterneRelatiemapLink === null,
                  icon: <IconMap style={{ width: 16, height: 16 }} />,
                  onClick: async () => {
                    const url = `${relatie.ExterneRelatiemapLink}`;
                    window.open(url, '_blank');
                  },
                },
                'scheiding',
                {
                  text: `Ga naar ${props.type === EKaartType.Klant ? 'Leverancier' : 'Klant'}`,
                  icon:
                    props.type === EKaartType.Klant ? (
                      <IconLeverancier style={{ width: 16, height: 16 }} />
                    ) : (
                      <IconKlantkaart style={{ width: 16, height: 16 }} />
                    ),
                  onClick: async () => {
                    if (props.type === EKaartType.Klant) {
                      await navigeerLeverancierNaarRelID(props.relatie.RelID);
                    } else {
                      await navigeerKlantkaartNaarRelID(props.relatie.RelID);
                    }
                  },
                },
              ]}
              style={{ marginRight: 3 }}
            />

            <div className="mt-1" style={{ marginRight: 3 }}>
              <PinKnop relID={relID} />
            </div>

            <SjabloonOplosserOpenenKnop relID={relID} relatie={relatie} />

            <div className="flex-fill" />

            <button
              style={{
                background: 0,
                outline: 0,
                border: 0,
                padding: 0,
                marginRight: 3,
                width: 28,
                height: 28,
                position: 'relative',
                top: 3,
              }}
              onClick={() => setUitgeklapt((x) => !x)}
            >
              {uitgeklapt ? (
                <IconInklappen
                  style={{
                    fill: Kleur.Grijs,
                    transform: 'scale(1.1)',
                  }}
                />
              ) : (
                <IconUitklappen
                  style={{
                    fill: Kleur.Grijs,
                    transform: 'scale(1.1)',
                  }}
                />
              )}
            </button>
          </div>
        </div>

        {uitgeklapt && (
          <div>
            <div className="mt-2">
              <GegevensLayout
                labelBreedte={140}
                gegevens={[
                  {
                    label: 'IBAN',
                    waarde:
                      standaardRekening !== null ? (
                        <span>{standaardRekening.IBAN}</span>
                      ) : (
                        <span>Geen standaard rekening aangegeven</span>
                      ),
                  },
                  {
                    label: 'Rekeningnaam',
                    waarde:
                      standaardRekening !== null ? (
                        <span>{standaardRekening.Rekeningnaam}</span>
                      ) : (
                        <span>Geen standaard rekening aangegeven</span>
                      ),
                  },
                  {
                    label: 'Betaalwijze',
                    waarde:
                      relatie.debiteur !== null ? (
                        <>
                          <span style={{ textAlign: 'right' }}>
                            {relatie.debiteur.betaalwijze.Naam}&nbsp;
                            {relatie.debiteur.betaalwijze.NaamEnum === EBetaalwijze.Incasso
                            // &&
                            // relatie.debiteur.Betaaldag === -1
                            //   ? 'Stufidatum'
                            //   : relatie.debiteur!.Betaaldag.toString() + 'e v/d mnd'
                            }
                          </span>
                        </>
                      ) : (
                        <span></span>
                      ),
                  },
                  {
                    label: 'Betaaldag',
                    waarde:
                      relatie.debiteur !== null ? (
                        relatie.debiteur.Betaaldag === -1 ? (
                          <span
                          // style={{
                          //   color: relatie.debiteur.Incassomachtiging
                          //     ? EKleur.Grijs
                          //     : EKleur.LichtGrijs,
                          // }}
                          >
                            {relatie.debiteur.Incassomachtiging
                              ? 'Incasso op stufidatum'
                              : 'Betaaldag stufidatum'}
                          </span>
                        ) : (
                          <span
                          // style={{
                          //   color: relatie.debiteur.Incassomachtiging
                          //     ? EKleur.Grijs
                          //     : EKleur.LichtGrijs,
                          // }}
                          >
                            {/* {relatie.debiteur.Incassomachtiging
                              ? `Incasso ${relatie.debiteur.Betaaldag}e van de maand`
                              : `Betaaldag ${relatie.debiteur.Betaaldag}e van de maand`} */}
                            {relatie.debiteur.Betaaldag}e van de maand
                          </span>
                        )
                      ) : (
                        ''
                      ),
                  },

                  // relatie.debiteur!.Betalingstermijn === 0
                  // ? 'Standaard'
                  // : relatie.debiteur!.Betalingstermijn + ' dagen'

                  {
                    label: 'Betaaltermijn',
                    waarde:
                      relatie.debiteur !== null
                        ? relatie.debiteur!.Betalingstermijn === 0
                          ? 'Standaard'
                          : relatie.debiteur!.Betalingstermijn + ' dagen'
                        : '',
                  },
                ]}
              />
            </div>
            <div className="mt-2">
              <GegevensLayout
                labelBreedte={140}
                gegevens={[
                  {
                    label: 'Minimaal huren tot',
                    waarde:
                      informatie.state === ERemoteDataState.Ready && informatie.data !== null
                        ? informatie.data.bijzonderheden.minimaalHurenTot !== null &&
                          new Date(informatie.data.bijzonderheden.minimaalHurenTot) > new Date()
                          ? format(
                              new Date(informatie.data.bijzonderheden.minimaalHurenTot),
                              'dd-MM-yyyy',
                            )
                          : '-'
                        : '',
                    // waarde: 'TODO',
                  },

                  {
                    label: 'Wervingscode',
                    waarde: (
                      <div className="d-flex">
                        <span
                          className="d-flex align-items-center"
                          style={{
                            color:
                              relatie.Wervingscode !== null && relatie.WervingscodeOngeldig
                                ? EKleur.Rood
                                : undefined,
                          }}
                        >
                          {relatie.Wervingscode !== null ? relatie.Wervingscode : 'Onbekend'}
                        </span>
                        {relatie.Wervingscode !== null && (
                          <span className="ml-2">
                            <KopieerNaarKlembord waarde={relatie.Wervingscode!} />
                          </span>
                        )}
                      </div>
                    ),
                  },
                  {
                    label: 'Overnamecode',
                    waarde: (
                      <div className="d-flex">
                        <span className="d-flex align-items-center">
                          {relatie.Overnamecode !== null ? relatie.Overnamecode : 'Onbekend'}
                        </span>
                        {relatie.Overnamecode !== null && (
                          <span className="ml-2">
                            <KopieerNaarKlembord waarde={relatie.Overnamecode!} />
                          </span>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <div className="mt-2">
              <GegevensLayout
                labelBreedte={140}
                gegevens={[
                  {
                    label: 'Aantal gebruikers',
                    waarde: `${
                      informatie.state === ERemoteDataState.Ready && informatie.data !== null
                        ? informatie.data.aantalGebruikers !== null
                          ? informatie.data.aantalGebruikers
                          : 'Zie contracten'
                        : ''
                    }`,
                  },
                  {
                    label: 'Actieve contracten',
                    waarde: `${
                      informatie.state === ERemoteDataState.Ready && informatie.data !== null
                        ? informatie.data.actiefAantalContracten
                        : ''
                    }`,
                  },
                  {
                    label: 'Totaal contracten',
                    waarde: `${
                      informatie.state === ERemoteDataState.Ready && informatie.data !== null
                        ? informatie.data.totaalAantalContracten
                        : ''
                    }`,
                  },
                ]}
              />
            </div>

            <div className="mt-2">
              <GegevensLayout
                labelBreedte={140}
                gegevens={[
                  {
                    label: 'Huren sinds',
                    waarde: `${
                      informatie.state === ERemoteDataState.Ready && informatie.data !== null
                        ? informatie.data.datumOudsteContract !== null
                          ? maandenNaarJaren(
                              differenceInMonths(
                                new Date(),
                                new Date(informatie.data.datumOudsteContract!),
                              ),
                            )
                          : '-'
                        : ''
                    }`,
                    // waarde: 'TODO',
                  },
                ]}
              />
            </div>
          </div>
        )}
      </Header>
      {relatieIdentiteitWijzigenDialoogState !== null &&
      relatieIdentiteitWijzigenDialoogState.persID !== undefined ? (
        <PersoonWijzigenDialoog
          open
          onSuccess={async () => {
            await api.v2.persoon.bijwerkenLijstRecent({
              persID: relatieIdentiteitWijzigenDialoogState!.persID!,
            });
            await props.onVerversenAangevraagd();
            setRelatieIdentiteitWijzigenDialoogState(null);
          }}
          onAnnuleren={() => setRelatieIdentiteitWijzigenDialoogState(null)}
          persID={relatieIdentiteitWijzigenDialoogState!.persID!}
        />
      ) : null}
      {primairPersoonOrganisatieWijzigenDialoogState !== null && (
        <PersoonWijzigenDialoog
          open
          onSuccess={async () => {
            await api.v2.persoon.bijwerkenLijstRecent({
              persID: primairPersoonOrganisatieWijzigenDialoogState!.persID!,
            });
            await props.onVerversenAangevraagd();
            setPrimairPersoonOrganisatieWijzigenDialoogState(null);
          }}
          onAnnuleren={() => setPrimairPersoonOrganisatieWijzigenDialoogState(null)}
          persID={primairPersoonOrganisatieWijzigenDialoogState!.persID!}
        />
      )}

      {taakToevoegenDialoogState !== null && props.relID !== null && (
        <HandmatigControltaakToevoegenDialoog
          open
          aspGebrID={gebruikerStore.gebruiker!.AspGebrID}
          onSuccess={(result) => {
            setTaakToevoegenDialoogState(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => setTaakToevoegenDialoogState(null)}
          initieelRelatie={{
            relID: props.relID!,
            muterenViaFormulierToestaan: false,
          }}
        />
      )}
      {escalatieToevoegenDialoogState !== null && props.relID !== null && (
        <ToevoegenEscalatieDialoog
          open
          aspGebrID={gebruikerStore.gebruiker!.AspGebrID}
          onSuccess={(result) => {
            setEscalatieToevoegenDialoogState(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => setEscalatieToevoegenDialoogState(null)}
          initieelRelatie={{
            relID: props.relID!,
            muterenViaFormulierToestaan: false,
          }}
        />
      )}
    </>
  );
});

export default Identiteit;
