import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Root = styled.button`
  padding: 12px 16px;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

interface IProps {
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
  title?: string;
  disabled?: boolean;
}

const Knop = React.forwardRef<any, PropsWithChildren<IProps>>((props, ref) => {
  return (
    <Root
      ref={ref}
      onClick={props.onClick}
      style={props.style}
      title={props.title}
      disabled={props.disabled}
    >
      {props.children}
    </Root>
  );
});

export default Knop;
