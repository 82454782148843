import React from 'react';
import PaginaHeader from '../PaginaHeader';
import { CommunicatieNavigatie } from '../Communicatie/styles';
import { NavLink } from 'react-router-dom';
import { EyeIcon, IconLijst } from '../../components/Icons';
import { Redirect, Route } from 'react-router';
import { RouteComponentProps } from 'react-router';
import Dashboard from './Dashboard';
import Monitoring from './Dashboard';
import Brongegevens from './Dashboard';

interface IProps extends RouteComponentProps {}

const Bedrijfsinfo: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <CommunicatieNavigatie>
          <li>
            <NavLink
              to={`${match.url}/dashboard`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconLijst />
              &nbsp; Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/monitoring`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <EyeIcon />
              &nbsp; Monitoring
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/brongegevens`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <EyeIcon />
              &nbsp; Brongegevens
            </NavLink>
          </li>
        </CommunicatieNavigatie>
      </PaginaHeader>

      <Route path={`${match.path}/dashboard`} component={Dashboard} />
      <Route path={`${match.path}/monitoring`} component={Monitoring} />
      <Route path={`${match.path}/brongegevens`} component={Brongegevens} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/dashboard`} />}
      />
    </div>
  );
};

export default Bedrijfsinfo;
