import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IContentProps } from '../../shared';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { formatteerAdresV2 } from '../../../../helpers';
import NavigerenPijl from '../../Rechts/NavigerenPijl';

const WhatsAppBerichtContent = (props: IContentProps) => {
  // const [berichtenResult, setBerichtenResult] = useState<IRemoteData<IOphalenBerichtenResult>>(
  //   createPendingRemoteData(),
  // );
  // const ophalenBerichten = useCallback(async () => {
  //   const result = await api.v2.dienst.whatsapp.ophalenChatBerichten()({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'IDS',
  //           data: props.ids,
  //         },
  //       ],
  //     },
  //   });
  //   setBerichtenResult(createReadyRemoteData(result));
  // }, [props.ids]);
  // useEffect(() => {
  //   ophalenBerichten();
  // }, [ophalenBerichten]);

  const handleNavigeren = useCallback(() => {}, []);

  return (
    <div style={{ paddingLeft: 35, paddingRight: 20 }} className="d-flex flex-column">
      {/*{berichtenResult.state === ERemoteDataState.Pending ? (*/}
      {/*  <div className="d-flex flex-fill align-items-center justify-content-center">*/}
      {/*    <LoadingSpinner />*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  berichtenResult.data!.locaties.map((locatie, i) => (*/}
      {/*    <div key={locatie.LocID} className="p-2 d-flex align-items-center">*/}
      {/*      <span className="flex-fill">*/}
      {/*        {formatteerAdresV2({*/}
      {/*          straatnaam: locatie.Straatnaam,*/}
      {/*          postcode: locatie.Postcode,*/}
      {/*          plaatsnaam: locatie.Plaatsnaam,*/}
      {/*          landnaamKort: locatie.LandnaamKort,*/}
      {/*          landnaamEnum: locatie.LandnaamEnum,*/}
      {/*          huisnummer: locatie.Huisnummer,*/}
      {/*          bisnummer: locatie.Bisnummer,*/}
      {/*        })}*/}
      {/*      </span>*/}
      {/*      <NavigerenPijl onClick={handleNavigeren} />*/}
      {/*    </div>*/}
      {/*  ))*/}
      {/*)}*/}
      TODO
    </div>
  );
};

export default WhatsAppBerichtContent;
