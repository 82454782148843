import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';

import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Column,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  IconAttachment,
  IconVerwijderen,
  IconToevoegen,
  IconVink,
  IconKruis,
} from '../../../../components/Icons';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RowDetailComp from './RowDetailComp';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import MenuLayout from '../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../stores/RootStore';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';
import {
  IInitieleRelatiesProvider,
  relatieSelectieRelatieToRow,
} from '../../../../components/formulier/RelatieSelectie';
import RelatieSelectieUitgebreid from '../../../../components/formulier/RelatieSelectieUitgebreid';
import MediaWeergaveDialoog from '../../../../components/dialogen/MediaWeergaveDialoog';
import { mediaTypeNaarMediaWeergaveType } from '../../../../components/MediaWeergave';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import WijzigenFactuurDialoog from '../../../../components/inkoopfactuur/WijzigenFactuurDialoog';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  RelIDs = 'REL_IDS',
  Zoekterm = 'ZOEKTERM',
  IsOpenstaand = 'IS_OPENSTAAND',
}

export interface INieuweFactuurDialoogState {
  open: boolean;
}

interface IMediaweergaveDialoogState {
  id: number;
  bestanden: IOphalenBestandenResultElement[];
}

export interface IWijzigenFactuurDialoogState {
  inkFactID: number;
}
export interface IUrlState {
  selectie: number[];
  nieuweFactuurDialoogState: INieuweFactuurDialoogState | null;
  filterData: IFilterData<EFilter>[];
  wijzigenFactuurDialoogState: IWijzigenFactuurDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  nieuweFactuurDialoogState: null,
  filterData: [
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
    {
      naam: EFilter.RelIDs,
      isActief: false,
      data: [],
    },
    {
      naam: EFilter.IsOpenstaand,
      data: true,
      isActief: false,
    },
  ],
  wijzigenFactuurDialoogState: null,
};

const geenData = {
  noData: 'Geen inkoopfacturen gevonden voor het ingestelde filter',
};

export interface IDocument extends IOphalenBestandenResultElement {}

const Afgehandeld: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [facturen, setFacturen] = useState<IOphalenFacturenResultElement[] | null>(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const [
    mediaweergaveDialoogState,
    setMediaweergaveDialoogState,
  ] = useState<IMediaweergaveDialoogState | null>(null);

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema,
      // filterSchema: {
      //   filters: [
      //     {
      //       naam: 'IS_GEBOEKT',
      //       data: true,
      //     },
      //     {
      //       naam: 'IS_GEFIATTEERD',
      //       data: true,
      //     },
      //     {
      //       naam: 'IS_OPENSTAAND',
      //       data: false,
      //     },
      //   ],
      // },
      orderSchema: { orders: [{ naam: 'FACTUURDATUM', richting: 'DESC' }] },
      paginatie: {
        index: 0,
        aantal: 500,
      },
    });

    const relIDs = _.uniq(facturenResult.facturen.map((x) => x.RelID));

    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
      })
    ).relaties;

    setRelaties(relaties);

    const facturen = facturenResult.facturen.map((x: any) => {
      return { ...x, boeking: null };
    });

    setFacturen(facturen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const keyExtractor = useCallback((row: IOphalenFacturenResultElement) => row.InkFactID, []);
  const kolommen = useMemo<TypedColumn<IOphalenFacturenResultElement>[]>(
    () => [
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      {
        name: '__relatie' as any,
        title: 'Naam',
        getCellValue: (x) => {
          if (relaties === null) {
            return null;
          }
          const relatie = relaties.find((r) => r.RelID === x.RelID);
          return relatie !== undefined ? relatie.weergavenaam : null;
        },
      },
      // {
      //   name: '__betaalwijze' as any,
      //   title: 'Betaalwijze',
      // },
      {
        name: 'Gefiatteerd',
        title: 'Gefiat.',
      },
      {
        name: 'Geboekt',
        title: 'Geboekt',
      },
      {
        name: '__heeftBestanden' as any,
        title: 'Bijl.',
      },
      {
        name: '__mediaweergave' as any,
        title: ' ',
      },
    ],
    [relaties],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenResultElement>[]>(
    () => [
      {
        columnName: 'Factuurdatum',
        width: 125,
      },
      {
        columnName: 'Factuurnummer',
        width: 200,
      },
      {
        columnName: '__relatie' as any,
        width: 175,
      },
      {
        columnName: 'Bedrag',
        width: 120,
      },
      {
        columnName: 'Openstaand',
        width: 150,
      },
      {
        columnName: 'Onderwerp',
        width: 400,
      },
      // {
      //   columnName: '__betaalwijze' as any,
      //   width: 150,
      // },
      {
        columnName: 'Gefiatteerd',
        width: 90,
      },
      {
        columnName: 'Geboekt',
        width: 95,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 80,
      },
      {
        columnName: '__mediaweergave' as any,
        width: 75,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  const initieleRelatiesProvider = useMemo<IInitieleRelatiesProvider>(
    () => ({
      provide: async () => {
        const relatiesResult = await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IS_CREDITEUR',
                data: true,
              },
              {
                naam: 'MET_OPENSTAANDE_FACTUREN',
                data: true,
              },
            ],
          },
        });
        return relatiesResult.relaties.map(relatieSelectieRelatieToRow);
      },
    }),
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.RelIDs,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const data: number[] = weergaveProps.data;

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Inkoopleverancier</span>
              <RelatieSelectieUitgebreid
                filters={[{ naam: 'IS_CREDITEUR', data: true }]}
                onChange={(relID) => {
                  const newData = relID === null ? [] : [relID];
                  weergaveProps.onDataChange(newData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                relID={data.length === 0 ? null : data[0]}
                initieleRelatiesProvider={initieleRelatiesProvider}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
      {
        naam: EFilter.IsOpenstaand,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen openstaand</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Overzicht inkoopfacturen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            {/* <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => null!}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Knop todo</span>
            </button> */}
            <div className="flex-fill">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={setFilterSchema}
              />
            </div>
          </div>
        }
        body={
          <>
            {facturen === null || relaties === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height="calc(100vh - 125px)">
                <Grid columns={kolommen} rows={facturen || []} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={['__heeftBestanden']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenFacturenResultElement = formatterProps.row;
                      const heeftBestanden = rij.bestanden.length > 0 ? true : false;
                      return (
                        <span>
                          {heeftBestanden ? (
                            <IconAttachment style={{ width: 20, height: 20, fill: EKleur.Grijs }} />
                          ) : null}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Factuurdatum']}
                    formatterComponent={(props) => {
                      const rij: IOphalenFacturenResultElement = props.row;
                      const datum = format(new Date(rij.Factuurdatum), 'dd-MM-yyyy');
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IOphalenFacturenResultElement;
                      return (
                        <RelatieVisualisatie
                          relID={rij.RelID}
                          relatieLinkBuilder={(hoedanigheid, relID) =>
                            `/${
                              hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                            }/${relID}/facturen/overzicht`
                          }
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__betaalwijze']}
                    formatterComponent={(props) => {
                      const rij: IOphalenFacturenResultElement = props.row;
                      if (rij.Verrekenen) {
                        return <span>Verrekenen</span>;
                      }
                      const naam = rij.betaalwijze !== null ? rij.betaalwijze.Naam : '';
                      return <span>{naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />
                  <DataTypeProvider
                    for={['Openstaand']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />

                  <DataTypeProvider
                    for={['Gefiatteerd']}
                    formatterComponent={(props) => {
                      return (
                        <span>
                          {props.value ? (
                            <IconVink style={{ width: 20, height: 20, fill: EKleur.Groen }} />
                          ) : (
                            <IconKruis style={{ width: 20, height: 20, fill: EKleur.Rood }} />
                          )}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Geboekt']}
                    formatterComponent={(formatterProps) => {
                      return formatterProps.value ? (
                        <IconVink style={{ width: 20, height: 20, fill: EKleur.Groen }} />
                      ) : (
                        <IconKruis style={{ width: 20, height: 20, fill: EKleur.Rood }} />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__mediaweergave']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenFacturenResultElement = formatterProps.row;
                      if (rij.bestanden.length === 0) {
                        return <span></span>;
                      }

                      return (
                        <span className="d-flex align-items-center">
                          <a
                            href="#"
                            onClick={async () => {
                              setMediaweergaveDialoogState({
                                id: rij.bestanden[0].ID,
                                bestanden: rij.bestanden,
                              });
                              // setMediaweergaveDialoogState({ id: 1 });
                            }}
                          >
                            Inzien
                          </a>
                        </span>
                      );
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <RowDetailState defaultExpandedRowIds={[]} />
                  <VirtualTable
                    estimatedRowHeight={43}
                    columnExtensions={kolomExtensies}
                    messages={geenData}
                  />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={(x) => {
                      const id = x[x.length - 1] as number;
                      setUrlStateSync('wijzigenFactuurDialoogState', { inkFactID: id });
                    }}
                    onCommitChanges={() => null}
                  />
                  <TableEditColumn
                    width={35}
                    // showAddCommand={true}
                    showEditCommand
                    // showDeleteCommand
                    commandComponent={DXCommandComponent}
                  />

                  <TableRowDetail
                    contentComponent={RowDetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </>
        }
      />
      {mediaweergaveDialoogState !== null && (
        <MediaWeergaveDialoog
          current={mediaweergaveDialoogState.id}
          onCurrentChange={(id) =>
            setMediaweergaveDialoogState({
              id: id as number,
              bestanden: mediaweergaveDialoogState.bestanden,
            })
          }
          mediaWeergaven={mediaweergaveDialoogState.bestanden!.map((x) => {
            return {
              id: x.ID,
              title: x.Naam,
              src: x.url,
              type: mediaTypeNaarMediaWeergaveType(x.MediaType)!,
              mediaType: x.MediaType,
            };
          })}
          open
          onSuccess={() => setMediaweergaveDialoogState(null)}
          onAnnuleren={() => setMediaweergaveDialoogState(null)}
        />
      )}
      {urlState.wijzigenFactuurDialoogState !== null && (
        <WijzigenFactuurDialoog
          open
          inkFactID={urlState.wijzigenFactuurDialoogState.inkFactID}
          onSuccess={() => {
            setUrlStateSync('wijzigenFactuurDialoogState', null);
            ophalenFacturen();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenFactuurDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Afgehandeld);
