import React, { useMemo } from 'react';

import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import IRemoteData from '../../../../../models/IRemoteData';
import {
  IOphalenRelatiesResultElementV2,
  IOphalenRelPersResultElement,
} from '../../../../../../../shared/src/api/v2/relatie';
import RelatieContexten from './RelatieContexten';
import GeadresseerdenSectie from './GeadresseerdenSectie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../../../shared/src/api/v2/organisatie/organisatie';
import { IEmail } from '../../../../../../../shared/src/api/v2/email-v2';
import { IOphalenEmailAccountsResultElement } from '../../../../../../../shared/src/api/v2/dienst/email/email';

const Root = styled.div`
  margin: 15px 15px 20px;
  background-color: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
`;

const OnderwerpTekst = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 15px;
`;

const GeenOnderwerpOpgevenTekst = styled.div`
  font-size: 18px;
  font-style: italic;
  padding: 10px 15px;
`;

const Container = styled.div`
  border-top: 1px solid ${Kleur.LichtGrijs};
  padding: 10px 15px;
`;

interface IProps {
  email: IEmail;
  onContextRelIDsChange: (relIDs: number[]) => Promise<void>;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  relPersCache: Record<number, IRemoteData<IOphalenRelPersResultElement[]>>;
  avatarImageSrc: IRemoteData<string | null>;
  emailAccount: IOphalenEmailAccountsResultElement;
}

const Heading = (props: IProps) => {
  const relIDs = useMemo(() => props.email.relCtxXEmailBers.map((x) => x.RelID), [
    props.email.relCtxXEmailBers,
  ]);

  return (
    <Root>
      {props.email.Onderwerp === null ? (
        <GeenOnderwerpOpgevenTekst>Geen onderwerp opgegeven</GeenOnderwerpOpgevenTekst>
      ) : (
        <OnderwerpTekst>{props.email.Onderwerp}</OnderwerpTekst>
      )}
      <Container>
        <GeadresseerdenSectie
          email={props.email}
          relatiesCache={props.relatiesCache}
          personenCache={props.personenCache}
          organisatiesCache={props.organisatiesCache}
          relPersCache={props.relPersCache}
          avatarImageSrc={props.avatarImageSrc}
          emailAccount={props.emailAccount}
        />
        <RelatieContexten
          relIDs={relIDs}
          onRelIDsChange={props.onContextRelIDsChange}
          relatiesCache={props.relatiesCache}
        />
      </Container>
    </Root>
  );
};

export default Heading;
