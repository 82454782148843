import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IOphalenOpdrachtregelsResultElement } from '../../../../../../shared/src/api/v2/transport/opdracht';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';

interface IRegelRow extends IOphalenOpdrachtregelsResultElement {}

interface IProps extends RouteComponentProps {
  rij: IRegelRow;
  magID: number;
  onVernieuwen: () => void;
}

const ReserveringProductComp: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const rij: IRegelRow = props.rij;

  const [aantalVrij, setAantalVrij] = useState<number | null>(0);

  // const ophalenVoorraadinfo = useCallback(async () => {
  //   if (rij.type === null) {
  //     return;
  //   }
  //   const voorraad = (
  //     await api.v2.voorraad.ophalenVoorraadinfo({
  //       magID: props.magID,
  //       typeIDs: [rij.type!.TypeID],
  //     })
  //   ).voorraad[0];

  //   setAantalVrij(voorraad.aantalVrij);
  // }, [rij.type, rij.type!.TypeID]);

  // useEffect(() => {
  //   ophalenVoorraadinfo();
  // }, [ophalenVoorraadinfo]);

  if (!(rij.type !== null && !rij.type.Bulkproduct)) {
    return <span></span>;
  }

  if (rij.type.Gereserveerd !== null && rij.type.Gereserveerd) {
    // Is gereserveerd
    return (
      <div className="d-flex">
        <a
          href="#"
          onClick={async () => {
            if (
              (
                await checkStore.bevestigen({
                  inhoud: `Omzetten naar een inkoopreservering?`,
                })
              ).type === EResultType.Annuleren
            ) {
              return;
            }

            await api.v2.transport.opdracht.wijzigenReserveringVoorTransportregel({
              trsRegID: rij.TrsRegID,
              gereserveerd: false,
            });

            props.onVernieuwen();
          }}
        >
          {/* <span style={{ color: EKleur.Groen }}>Res</span> */}
          <span>Geres.</span>
        </a>
      </div>
    );
  }

  // Is niet gereserveerd (inkoopreservering)

  return aantalVrij === null ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className="d-flex">
        <a
          href="#"
          onClick={async () => {
            // Is er vrije voorraad?
            const voorraad = (
              await api.v2.voorraad.ophalenVoorraadinfo({
                magID: props.magID,
                typeIDs: [rij.type!.TypeID],
              })
            ).voorraad[0];

            if (voorraad.aantalVrij <= 0) {
              await checkStore.melden({
                titel:
                  'Deze inkoopreservering kan niet omgezet worden naar een reservering omdat er geen vrije voorraad is',
              });
              return;
            }

            if (
              (
                await checkStore.bevestigen({
                  inhoud: `Omzetten naar een reservering?`,
                })
              ).type === EResultType.Annuleren
            ) {
              return;
            }

            await api.v2.transport.opdracht.wijzigenReserveringVoorTransportregel({
              trsRegID: rij.TrsRegID,
              gereserveerd: true,
            });

            props.onVernieuwen();
          }}
        >
          {/* <span style={{ color: aantalVrij > 0 ? EKleur.Groen : EKleur.Rood }}>IR</span> */}
          <span>IR</span>
          {aantalVrij > 0 ? <span style={{ color: EKleur.Groen }}> *</span> : <span></span>}
        </a>
      </div>
    </>
  );
});

export default withRouter(ReserveringProductComp);
