import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import Dialoog from '../../../../components/dialogen/Dialoog';
import api from '../../../../api';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps, FormikErrors } from 'formik';
import {
  IOphalenOpdrachtenResultElement,
  IOphalenOpdrachtstatussenResultElement,
  IOphalenServicedienstenResultElement,
  IWijzigenServiceOpdrachtParams,
} from '../../../../../../shared/src/api/v2/service/opdracht';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import BedragInput from '../../../../components/formulier/BedragInput';
import Tabblad, { ITabblad } from '../../../../components/layout/Tabblad';
import OpdrachtContext from './Context/OpdrachtContext';
import OpdrachtTab from './OpdrachtTab';
import WerkzaamhedenTab from './WerkzaamhedenTab';
import { format } from 'date-fns';
import styled from 'styled-components';

interface IDialoogResult {}

enum ETablad {
  Opdracht,
  Werkzaamheden,
}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

export interface IFormikValues {
  omschrijving: string | null;
  telefoon: string | null;
  email: string | null;
  garantieclaim: boolean;
  afgehandeld: boolean;
  status: number;
  persID: number;
  uitvoerendeServicedienstID: number | null;
  spoed: boolean;
  // meerDiensten: boolean;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  omschrijving: 'Klachtomschrijving',
  telefoon: 'Telefoon',
  email: 'Email',
  garantieclaim: 'Garantieclaim',
  afgehandeld: 'Afgehandeld',
  status: 'Status',
  persID: 'Contactpersoon',
  uitvoerendeServicedienstID: 'Uitvoerende servicedienst',
  spoed: 'Spoed',
  // meerDiensten: 'Meer diensten weergeven',
};

const DialoogWrapper = styled.div`
  .modal-dialog {
    max-width: 550px !important;
    border: 4px red;
  }
`;

const WijzigenOpdrachtDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, id } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);
  const [opdrachtstatussen, setOpdrachtstatussen] = useState<
    IOphalenOpdrachtstatussenResultElement[] | null
  >(null);
  const [servicediensten, setServicediensten] = useState<
    IOphalenServicedienstenResultElement[] | []
  >([]);
  const [meerDiensten, setMeerDiensten] = useState<boolean>(false);

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Opdracht);

  useEffect(() => {
    (async () => {
      const result = await api.v2.dienst.service.ophalenDiensten({
        filterSchema: {
          filters: [
            { naam: 'IS_ACTIEF', data: true },
            { naam: 'IS_UITVOEREND', data: true },
          ],
        },
        orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
      });
      const diensten = result.diensten;

      setServicediensten(diensten);
    })();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdracht === null) {
      return null;
    }

    return {
      omschrijving: opdracht.Omschrijving,
      telefoon: opdracht.Telefoon !== null ? opdracht.Telefoon : '',
      email: opdracht.Email !== null ? opdracht.Email : '',
      garantieclaim: opdracht.Garantieclaim,
      afgehandeld: opdracht.Afgehandeld,
      status: opdracht.status.Status,
      persID: opdracht.PersID!,
      uitvoerendeServicedienstID: opdracht.UitvoerendeServicedienst_ServDienstID,
      spoed: opdracht.Spoed,
      // meerDiensten: false,
    };
  }, [opdracht]);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    return errors;
  }, []);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Opdracht,
        label: 'Opdracht',
        content: OpdrachtTab,
      },
      // {
      //   id: ETablad.Werkzaamheden,
      //   label: 'Werkzaamheden',
      //   content: WerkzaamhedenTab,
      // },
    ],
    [onSuccess],
  );

  const ophalenStatussen = useCallback(async () => {
    const statussen = (await api.v2.service.ophalenOpdrachtstatusssen({})).statussen;

    setOpdrachtstatussen(statussen);
  }, []);

  useEffect(() => {
    ophalenStatussen();
  }, [ophalenStatussen]);

  const ophalenOpdracht = useCallback(async () => {
    const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });

    setOpdracht(opdrachtenResult.opdrachten[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenOpdracht();
  }, [ophalenOpdracht]);

  // useEffect(() => {
  //   (async () => {
  //     const result = await api.v2.dienst.service.ophalenDiensten({
  //       filterSchema: {
  //         filters: [
  //           { naam: 'IS_ACTIEF', data: true },
  //           { naam: 'IS_HOOFDAANNEMER', data: false },
  //         ],
  //       },
  //     });
  //     const diensten = result.diensten;

  //     setServicediensten(diensten);
  //   })();
  // }, []);

  const handleSubmit = useCallback(async (values: IFormikValues) => {
    const params: IWijzigenServiceOpdrachtParams = {
      id: props.id,
      omschrijving: values.omschrijving!,
      telefoon: values.telefoon != '' ? values.telefoon : null,
      email: values.email !== '' ? values.email : null,
      garantieclaim: values.garantieclaim,
      afgehandeld: values.afgehandeld,
      status: values.status,
      persID: values.persID,
      uitvoerendeServicedienst_ServDienstID: values.uitvoerendeServicedienstID,
      spoed: values.spoed,
    };

    await api.v2.service.wijzigenServiceOpdracht(params);

    onSuccess({});
  }, []);

  return (
    <DialoogWrapper>
      {/* <Dialoog index={dialoogIndex || 0} modalProps={{ dialogClassName: `dialog-width-550` }}> */}
      <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
        <ModalHeader>
          <ModalTitle>Wijzigen opdracht</ModalTitle>
        </ModalHeader>

        <OpdrachtContext.Provider
          value={{ opdracht, id: props.id, opdrachtstatussen, servicediensten }}
        >
          {initialValues === null ? (
            <LoadingSpinner />
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialValues}
                validate={handleValidate}
                onSubmit={handleSubmit}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div style={{ minHeight: `400px` }}>
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                          style={{ width: 100 }}
                        >
                          Ok
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Annuleren
                        </button>
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </OpdrachtContext.Provider>
      </Dialoog>
    </DialoogWrapper>
  );
});

export default WijzigenOpdrachtDialoog;
