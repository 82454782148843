import React, { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import { EGetTekstStatus, IGetTekstOutput } from '../../stores/TekstStore';

interface IProps {
  gewensteTaalID?: number;
  tekstID: number;
  visualisatieFabriek?: (item: IGetTekstOutput) => JSX.Element | null;
}

const defaultVisualisatieFabriek = (item: IGetTekstOutput) => {
  if (item.status !== EGetTekstStatus.Data) {
    return null;
  }

  return <span>{item.data!.tekst}</span>;
};

const TekstVisualisatie: React.FC<IProps> = observer((props) => {
  const { tekstStore, instellingStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (instellingStore.TaalID === null) {
      return;
    }

    tekstStore.tekstenVerzamelen([
      {
        tekstID: props.tekstID,
        gewensteTaalID: props.gewensteTaalID,
      },
    ]);
  }, [props.tekstID, props.gewensteTaalID, instellingStore.TaalID]);

  const item = useMemo(() => {
    return tekstStore.getTekst(props.tekstID, props.gewensteTaalID);
  }, [tekstStore.teksten, tekstStore.tekstenOphaalQueue, props.tekstID, props.gewensteTaalID]);

  const visualisatieFabriek = useMemo(() => {
    if (props.visualisatieFabriek === undefined) {
      return defaultVisualisatieFabriek;
    }
    return props.visualisatieFabriek;
  }, [props.visualisatieFabriek]);

  return visualisatieFabriek(item);
});

export default TekstVisualisatie;
