import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import api from '../../../../../api';
import { IOphalenTekstenResultElement } from '../../../../../../../shared/src/api/v2/tekst';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import teksten from '../../../../../bedrijfslogica/teksten';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import { LoadingSpinnerCenter } from '../../../../../components/Gedeeld/LoadingSpinner';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import WaardeTypeSelectie from '../../WaardeTypeSelectie';
import WaardeVeld from '../../WaardeVeld';
import {
  IToevoegenVoetnootParams,
  IVoetnoot,
  IWijzigenVoetnootParams,
} from '../../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt/voetnoot';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps {
  id: number | null;
}

interface IWijzigenData {
  voetnoot: IVoetnoot;
  teksten: IOphalenTekstenResultElement[];
}

interface IFormValues {
  naam: string;
  teksten: ITaalTekst[];
}

const MuterenDialoog = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const [wijzigenData, setWijzigenData] = useState<IRemoteData<IWijzigenData | null>>(
    props.id === null ? createReadyRemoteData(null) : createPendingRemoteData(),
  );

  const bepalenWijzigenData = useCallback(async () => {
    if (props.id === null) {
      if (wijzigenData.state === ERemoteDataState.Pending || wijzigenData.data !== null) {
        setWijzigenData(createReadyRemoteData(null));
      }
      return;
    }

    const result = await api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVoetnoten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });
    const [voetnoot] = result.voetnoten;

    const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs: [voetnoot.Naam_TekstID],
    });

    const data: IWijzigenData = {
      voetnoot,
      teksten: tekstenResult.teksten,
    };
    setWijzigenData(createReadyRemoteData(data));
  }, [props.id]);

  useEffect(() => {
    bepalenWijzigenData();
  }, [bepalenWijzigenData]);

  const initialValues = useMemo<IRemoteData<IFormValues>>(() => {
    if (wijzigenData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (wijzigenData.data === null) {
      const values: IFormValues = {
        naam: '',
        teksten: [],
      };
      return createReadyRemoteData(values);
    }
    const data = wijzigenData.data;

    const values: IFormValues = {
      naam: data.voetnoot.Naam,
      teksten: data.teksten.map((tekst) => ({
        taalID: tekst.TaalID,
        tekst: tekst.Tekst ?? '',
        toepassen: tekst.Toepassen,
      })),
    };
    return createReadyRemoteData(values);
  }, [wijzigenData]);

  const validate = useCallback((values: IFormValues): FormikErrors<IFormValues> => {
    if (values.naam.trim().length === 0) {
      return {
        naam: teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: 'naam',
        }),
      };
    }
    if (values.teksten.filter((x) => x.toepassen).length === 0) {
      return {
        teksten: teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: 'teksten',
        }) as any,
      };
    }
    const eersteTekst = values.teksten.find((x) => x.toepassen)!;
    if (eersteTekst.tekst.trim().length === 0) {
      return {
        teksten: `Teksten moet minimaal een geldige tekst bevatten.` as any,
      };
    }

    return {};
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormValues, actions: FormikActions<IFormValues>) => {
      actions.setSubmitting(true);
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        actions.setSubmitting(false);
        return;
      }

      if (wijzigenData.data === null) {
        const params: IToevoegenVoetnootParams = {
          naam: values.naam,
          naamTeksten: values.teksten,
        };
        const checkData = await api.v2.concurrentie.vergelijkingspunt.voetnoot.checkToevoegenVoetnoot(
          params,
        );
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        await api.v2.concurrentie.vergelijkingspunt.voetnoot.toevoegenVoetnoot(params);
      } else {
        const params: IWijzigenVoetnootParams = {
          id: wijzigenData.data.voetnoot.ID,
          naam: values.naam,
          naamTekstID: wijzigenData.data.voetnoot.Naam_TekstID,
          naamTeksten: values.teksten,
        };
        const checkData = await api.v2.concurrentie.vergelijkingspunt.voetnoot.checkWijzigenVoetnoot(
          params,
        );
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }
        await api.v2.concurrentie.vergelijkingspunt.voetnoot.wijzigenVoetnoot(params);
      }

      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [wijzigenData, props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>{props.id === null ? 'Toevoegen' : 'Wijzigen'}&nbsp;voetnoot</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinnerCenter />
        </ModalBody>
      ) : (
        <Formik
          initialValues={initialValues.data!}
          onSubmit={handleSubmit}
          validate={validate}
          render={(formikProps) => <MuterenDialoogInner {...props} formikProps={formikProps} />}
        />
      )}
    </Dialoog>
  );
};

interface IInnerProps {
  formikProps: FormikProps<IFormValues>;
}

const MuterenDialoogInner = (props: IProps & IInnerProps) => {
  const { formikProps, onAnnuleren } = props;
  const { isSubmitting, submitForm, values } = formikProps;

  return (
    <>
      <ModalBody>
        <div className="form-group">
          <label>Naam</label>
          <Field
            name={nameOf<IFormValues>('naam')}
            render={(fieldProps: FieldProps<IFormValues>) => {
              const { field } = fieldProps;

              return (
                <div className="d-flex flex-column">
                  <input type="text" className="form-control" {...field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />

          <label className="mt-3">Teksten</label>
          <Field
            name={nameOf<IFormValues>('teksten')}
            render={(fieldProps: FieldProps<IFormValues>) => {
              const { field, form } = fieldProps;

              return (
                <div className="d-flex flex-column">
                  <MeertaligTekstveld
                    waarden={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  />
                  <FormikVeldFout
                    fieldProps={fieldProps}
                    options={{ objectAlsVeldBeschouwen: true }}
                  />
                </div>
              );
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default MuterenDialoog;
