import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  IconSend,
  IconVerwijderen,
  IconToevoegen,
  IconLijst,
  IconGrid,
  IconInkoop,
} from '../../../components/Icons';
import { ITabbladProps } from '../index';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import api from '../../../api';
import MultiCombobox from '../../../components/formulier/MultiCombobox';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import VerticaleScheidingslijn from '../../../components/layout/VerticaleScheidingslijn';
import NieuwOpdrachtDialoog from '../../Inkoop/Opdrachten/NieuwOpdrachtDialoog';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../core/useUrlState';
import WeergaveKeuze, { IWeergave } from '../../../components/WeergaveKeuze';
import TypeTabel from './TypeTabel';
import ModelTabel from './ModelTabel';
import { IOphalenProductsoortenResultElement } from '../../../../../shared/src/api/v2/product/soort';
import { IOphalenProductmodellenResultElement } from '../../../../../shared/src/api/v2/aanbod/productmodel';
import { IOphalenVoorraadNieuwResultElement } from '../../../../../shared/src/api/v2/inkoop/voorraad';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { IOphalenProductmerkenResultElement } from '../../../../../shared/src/api/v2/product/merk';
import {
  IOphalenStatistischeInfoResultElement,
  IOphalenVoorraadinfoResultElement,
} from '../../../../../shared/src/api/v2/voorraad';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import VinkVeld from '../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../stores/RootStore';

export enum EWeergave {
  Type,
  Model,
}

interface INieuwOpdrachtDialoogState {
  open: boolean;
  typeID?: number;
}

interface IUrlState {
  weergave: EWeergave;
  typeSelectie: number[];
  modelSelectie: number[];
}

const defaultUrlState: IUrlState = {
  weergave: EWeergave.Model,
  typeSelectie: [],
  modelSelectie: [],
};

export interface INieuwTabbladContext {
  onVerversenAangevraagd: () => Promise<void>;
}
export const NieuwTabbladContext = React.createContext<INieuwTabbladContext>(null as any);

interface IProps extends ITabbladProps, RouteComponentProps {}

export interface IRowModel extends IOphalenProductmodellenResultElement {
  voorraadinfo: IOphalenVoorraadinfoResultElement[];
  info: IOphalenStatistischeInfoResultElement | null;
}

const Nieuw: React.FC<IProps> = (props) => {
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const {
    magazijnen,
    productsoorten,
    magID,
    onMagIDChange,
    prodSrtID,
    onProdSrtIDChange,
    merkID,
    onMerkIDChange,
    alleenOpenbaar,
    onAlleenOpenbaarChange,
  } = props;
  const [nieuwOpdrachtDialoogState, setNieuwOpdrachtDialoogState] = useState<
    INieuwOpdrachtDialoogState
  >({
    open: false,
  });
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [typeRijbron, setTypeRijbron] = useState<IOphalenVoorraadNieuwResultElement[] | null>(null);
  const [modelRijbron, setModelRijbron] = useState<IRowModel[] | null>(null);
  const [infos, setInfos] = useState<IOphalenStatistischeInfoResultElement[] | null>(null);
  const [productmodellen, setProductmodellen] = useState<
    IOphalenProductmodellenResultElement[] | null
  >(null);

  useEffect(() => {
    // if (urlState.weergave !== EWeergave.Type) {
    //   return;
    // }
    (async () => {
      const result = await api.v2.voorraad.ophalenVoorraadNieuw({
        magID,
        prodSrtID,
        merkID,
      });

      const typeIDs = result.map((x) => x.TypeID);

      setTypeRijbron(result);
    })();
  }, [magID, prodSrtID, merkID, urlState.weergave]);

  // Productmodellen om weer te geven bij de producttypen
  useEffect(() => {
    (async () => {
      if (typeRijbron === null) {
        return;
      }
      const prodModIDs = _.flatten(
        typeRijbron.map((x) => x.gekoppeldeProductmodellen.map((x) => x.ProdModID)),
      );

      const result = (
        await api.v2.product.model.ophalenProductmodellen({
          filterSchema: { filters: [{ naam: 'IDS', data: prodModIDs }] },
        })
      ).modellen;

      setProductmodellen(result);
    })();
  }, [typeRijbron]);

  // Rijbron productmerken
  const [merkenRijbron, setMerkenRijbron] = useState<IOphalenProductmerkenResultElement[] | null>(
    null,
  );
  useEffect(() => {
    (async () => {
      const result = await api.v2.product.merk.ophalenProductmerken({
        filterSchema: { filters: [] },
        orderSchema: { orders: [{ naam: 'MERKNAAM', richting: 'ASC' }] },
      });

      setMerkenRijbron(result.productmerken);
    })();
  }, []);

  const ophalenModellenEnBijhorendeInfo = useCallback(async () => {
    // Actieve productmodellen ophalen
    const modellenResult = await api.v2.product.model.ophalenProductmodellen({
      filterSchema: {
        filters: [
          { naam: 'ACTIEF', data: true },
          // { naam: 'GEBRUIKT_PRODUCT', data: false },
          prodSrtID !== null ? { naam: 'PRODSRT_IDS', data: [prodSrtID] } : null,
          merkID !== null ? { naam: 'MERK_IDS', data: [merkID] } : null,
          alleenOpenbaar ? { naam: 'OPENBAAR', data: true } : null,
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });

    // Haal statistische info erbij
    const prodModIDs = modellenResult.modellen.map((x) => x.ProdModID);
    const infos = await api.v2.voorraad.ophalenStatistischeInfo({ prodModIDs });
    setInfos(infos.infoPerModel);

    // Voorraad voor de producttypen ophalen
    // const producttypenResult = await api.v2.voorraad.ophalenVoorraadNieuw({
    //   magID,
    //   prodSrtID,
    //   merkID,
    // });

    const producttypen = _.flatten(modellenResult.modellen.map((x) => x.producttypen));
    const ids = _.uniq(producttypen.map((x: any) => x.TypeID));

    const producttypenResult = await api.v2.product.type.ophalenProducttypen({
      filterSchema: { filters: [{ naam: 'IDS', data: ids }] },
    });

    const voorraadInfosResult = (
      await api.v2.voorraad.ophalenVoorraadinfo({
        magID,
        typeIDs: ids,
      })
    ).voorraad;

    // Combineer de modellen met de voorraadinfo per type (via gekopelde typen per model)
    const modellen = modellenResult.modellen.map((model) => {
      const typeIDs = model.producttypen.map((x) => x.TypeID);
      const voorraadinfo = voorraadInfosResult.filter((x) => typeIDs.indexOf(x.TypeID) !== -1);

      // statistische info zoals bv het aantal contracten wat voor een model ingezet is de laatste maand
      const info = infos.infoPerModel.find((x) => x.ProdModID === model.ProdModID);

      // De gekoppelde producttypen worden hier apart bijgevoegd (op het model zelf staan slechts beperkte gegevens inzake de gekoppelde typen)
      const gekoppeldeProducttypen = producttypenResult.producttypen.filter(
        (x) => typeIDs.indexOf(x.TypeID) !== -1,
      );

      return {
        ...model,
        voorraadinfo: voorraadinfo,
        info: info !== undefined ? info : null,
        gekoppeldeProducttypen,
      };
    });

    const result = modellen;

    setModelRijbron(result);
  }, [magID, prodSrtID, merkID, alleenOpenbaar, urlState.weergave]);

  useEffect(() => {
    ophalenModellenEnBijhorendeInfo();
  }, [ophalenModellenEnBijhorendeInfo]);

  const weergaven = useMemo<Array<IWeergave<EWeergave>>>(
    () => [
      {
        key: EWeergave.Model,
        naam: 'Model',
        icon: <IconGrid style={{ width: 18, height: 18, fill: Kleur.Grijs }} />,
      },
      {
        key: EWeergave.Type,
        naam: 'Type',
        icon: <IconInkoop style={{ width: 16, height: 16, fill: Kleur.Grijs }} />,
      },
    ],
    [],
  );

  const verversen = useCallback(async () => {
    await ophalenModellenEnBijhorendeInfo();
  }, [ophalenModellenEnBijhorendeInfo]);

  const context = useMemo<INieuwTabbladContext>(
    () => ({
      onVerversenAangevraagd: verversen,
    }),
    [verversen],
  );

  return (
    <NieuwTabbladContext.Provider value={context}>
      <div>
        <Helmet>
          <title>Voorraad Ingepakt</title>
        </Helmet>
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex align-items-start">
            <div style={{ width: 125 }}>
              <MultiCombobox<number, IOphalenMagazijnenResultElement>
                sleutelExtractor={(x) => x.MagID}
                representatieFabriek={(x) => x.NaamKort}
                waarde={magID}
                onWaardeChange={(x) => {
                  return onMagIDChange(x!);
                }}
                opties={magazijnen}
                kolommen={[
                  {
                    key: 'NaamKort',
                    label: 'Naam',
                    breedte: 125,
                  },
                  // {
                  //   key: 'PlaatsNaam',
                  //   label: 'Plaats',
                  //   breedte: 200,
                  // },
                ]}
              />
            </div>

            <div style={{ width: 250 }} className="ml-3">
              <MultiCombobox<number, IOphalenProductsoortenResultElement>
                sleutelExtractor={(x) => x.ProdSrtID}
                representatieFabriek={(x) => x.Naam}
                waarde={prodSrtID}
                onWaardeChange={(x) => onProdSrtIDChange(x!)}
                opties={productsoorten}
                isWisbaar
                kolommen={[
                  {
                    key: 'Naam',
                    label: 'Naam',
                    breedte: 250,
                  },
                ]}
                options={{
                  geenWaardeBericht: 'Alle productsoorten',
                }}
              />
            </div>

            <div style={{ width: 200 }} className="ml-3">
              <MultiCombobox<number, IOphalenProductmerkenResultElement>
                sleutelExtractor={(x) => x.MerkID}
                representatieFabriek={(x) => x.Merknaam}
                waarde={merkID}
                onWaardeChange={(x) => onMerkIDChange(x!)}
                opties={merkenRijbron}
                isWisbaar
                kolommen={[
                  {
                    key: 'Merknaam',
                    label: 'Naam',
                    breedte: 250,
                  },
                ]}
                options={{
                  geenWaardeBericht: 'Alle merken',
                }}
              />
            </div>

            <div style={{ width: 200 }} className="d-flex ml-3 pt-1">
              <VinkVeld
                aangevinkt={alleenOpenbaar}
                onGewijzigd={(x) => {
                  onAlleenOpenbaarChange(x);
                }}
              />
              <span className="ml-2">Alleen openbaar aanbod</span>
            </div>

            <div className="pl-3 pr-3">
              <VerticaleScheidingslijn height={30} />
            </div>

            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={
                urlState.weergave === EWeergave.Type
                  ? urlState.typeSelectie.length !== 1
                  : urlState.modelSelectie.length !== 1
              }
              onClick={async () => {
                if (urlState.weergave === EWeergave.Type) {
                  const producttype = (
                    await api.v2.product.type.ophalenProducttypen({
                      filterSchema: { filters: [{ naam: 'IDS', data: urlState.typeSelectie }] },
                    })
                  ).producttypen[0];

                  if (!producttype.productsoort.Actief) {
                    await checkStore.melden({
                      titel: 'Productsoort is niet actief',
                    });
                    return;
                  }

                  return setNieuwOpdrachtDialoogState({
                    open: true,
                    typeID:
                      urlState.typeSelectie.length === 0 ? undefined : urlState.typeSelectie[0],
                  });
                } else {
                  const productmodel = (
                    await api.v2.product.model.ophalenProductmodellen({
                      filterSchema: { filters: [{ naam: 'IDS', data: [urlState.modelSelectie] }] },
                    })
                  ).modellen[0];

                  if (!productmodel.productsoort.Actief) {
                    await checkStore.melden({
                      titel: 'Productsoort is niet actief',
                    });
                    return;
                  }

                  const producttype = productmodel.producttypen.find((x) => x.PrioNr === 0) ?? null;

                  return setNieuwOpdrachtDialoogState({
                    open: true,
                    typeID: producttype !== null ? producttype.TypeID : undefined,
                  });
                }
              }}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Inkoopopdracht</span>
            </button>

            <div className="flex-fill" />

            <div>
              <WeergaveKeuze
                weergave={urlState.weergave}
                onChange={(x) => setUrlStateSync('weergave', x)}
                weergaven={weergaven}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: Kleur.Wit,
          }}
        >
          {typeRijbron === null ||
          modelRijbron === null ||
          infos === null ||
          productmodellen === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : urlState.weergave === EWeergave.Type ? (
            <TypeTabel
              rows={typeRijbron}
              productmodellen={productmodellen}
              selectie={urlState.typeSelectie}
              onSelectieChange={(x) => setUrlStateSync('typeSelectie', x)}
              onNieuweInkoopopdracht={(typeID) => {
                setNieuwOpdrachtDialoogState({
                  open: true,
                  typeID,
                });
              }}
            />
          ) : (
            <ModelTabel
              rows={modelRijbron}
              infos={infos}
              selectie={urlState.modelSelectie}
              onSelectieChange={(x) => setUrlStateSync('modelSelectie', x)}
              // onNieuweInkoopopdracht={(typeID) => {
              //   setNieuwOpdrachtDialoogState({
              //     open: true,
              //     typeID,
              //   });
              // }}
            />
          )}
        </div>
      </div>
      {nieuwOpdrachtDialoogState.open && (
        <NieuwOpdrachtDialoog
          prodSrtID={null}
          typeID={nieuwOpdrachtDialoogState.typeID}
          open
          onSuccess={() => {
            setNieuwOpdrachtDialoogState({
              open: false,
            });
            // ophalenOpdrachten();
          }}
          onAnnuleren={() => {
            setNieuwOpdrachtDialoogState({
              open: false,
            });
          }}
        />
      )}
    </NieuwTabbladContext.Provider>
  );
};

export default withRouter(Nieuw);
