import React, { useCallback, useMemo } from 'react';
import ASPTabel from '../../../tabel/ASPTabel';
import { IOphalenContactpersonenResultElement } from '../../../../../../shared/src/api/v2/relatie/contactpersoon';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import { formatteerPersoonNaam } from '../../../../helpers';

interface IProps {
  personen: IOphalenContactpersonenResultElement[];
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  onToevoegen: (persoon: IOphalenContactpersonenResultElement) => void;
}

type Kolom = 'naam' | 'voeg_toe';

const OverigePersonenVanRelatieTabel = (props: IProps): JSX.Element => {
  const keyExtractor = useCallback(
    (persoon: IOphalenContactpersonenResultElement) => persoon.ID,
    [],
  );
  const kolommen = useMemo<ASPKolom<Kolom, IOphalenContactpersonenResultElement>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (persoon) =>
          formatteerPersoonNaam({
            aanhefKort: persoon.geslacht.AanhefKort,
            achternaam: persoon.Achternaam,
            voorletters: persoon.Voorletters,
            voornaam: persoon.Voornaam,
            voorvoegsel: persoon.Voorvoegsel,
          }),
      },
      {
        key: 'voeg_toe',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 85,
        renderer: (persoon) => (
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              props.onToevoegen(persoon);
            }}
          >
            Voeg toe
          </a>
        ),
      },
    ],
    [props.onToevoegen],
  );

  return (
    <ASPTabel
      rijen={props.personen}
      kolommen={kolommen}
      keyExtractor={keyExtractor}
      selectie={props.selectie}
      onSelectieChange={props.onSelectieChange}
    />
  );
};

export default OverigePersonenVanRelatieTabel;
