import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import api from '../../../../../api';
import { IOphalenDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenDienstenResultElement as IOphalenSponsorDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/sponsoring';
import { IOphalenDienstenResultElement as IOphalenTransportDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/transport';

import { dagDatum } from '../../../../../helpers/datum';
import {
  IOphalenFacturenResultElement,
  IToevoegenInkoopfactuurParams,
} from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import { addDays } from 'date-fns';
import nameOf from '../../../../../core/nameOf';
import RelatieSelectieUitgebreid from '../../../../../components/formulier/RelatieSelectieUitgebreid';
import TekstSuggestieVeld, {
  IExposeData,
  IInputComponentProps,
} from '../../../../../components/formulier/TekstSuggestieVeld';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import BedragInput from '../../../../../components/formulier/BedragInput';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import PDFWeergever from '../../../../../components/PDFWeergever';
import {
  IInitieleRelatiesProvider,
  relatieSelectieRelatieToRow,
} from '../../../../../components/formulier/RelatieSelectie';
import { AppContext } from '../../../../../App';
import VeldWeergave from '../../../../../components/formulier/VeldWeergave';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { EBtwsoort } from '../../../../../bedrijfslogica/enums';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenBestandenResultElement } from '../../../../../../../shared/src/api/v2/bestand/bestand';
import FactuurinfoDialoog from '../../../../../components/inkoopfactuur/FactuurinfoDialoog';
import CrediteurInfoDialoog from '../../../../../components/crediteur/CrediteurinfoDialoog';
import { IconInformatie } from '../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { IOphalenServicedienstenResultElement } from '../../../../../../../shared/src/api/v2/service/opdracht';
import Transportdienst from '../../../../Leverancier/Entiteiten/entiteiten/Transportdienst';

export interface IDialoogResult {
  inkFactID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID?: number;
  inkoopfactuurBestandID?: number;
  factuurnummer?: string;
  factuurreferentie?: string;
  onderwerp?: string;
  factuurdatum?: Date;
  vervaldatum?: Date;
  btwBedrag?: number;
  btwNietAutomatischHerberekenen?: boolean;
  bedragInclusiefBtw?: number;
  credit?: boolean;
}

interface IFormulierBron {
  relatie?: IOphalenRelatiesResultElementV2;
  inkoopdienst?: IOphalenDienstenResultElement;
  // sponsordienst?: IOphalenSponsorDienstenResultElement;
  facturen?: IOphalenFacturenResultElement[];
}

interface IFormikValues {
  relID: number | null;
  factuurnummer: string;
  factuurreferentie: string | null;
  factuurdatum: Date | string | null;
  vervaldatum: Date | string | null;
  vervaldatumKorting: Date | string | null;
  bedrag: number;
  bedragBtw: number;
  bedragKorting: number;
  credit: boolean;
  onderwerp: string;
  notities: string | null;
  koppelenProducten: boolean;
  koppelenSponsorcontracten: boolean;
  koppelenWerkbonnen: boolean;
  koppelenTransportopdrachten: boolean;
  koppelenRetourProducten: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  relID: 'Crediteur',
  factuurnummer: 'Factuurnr',
  factuurreferentie: 'Referentie op bankmutatie',
  factuurdatum: 'Factuurdatum',
  vervaldatum: 'Vervaldatum',
  vervaldatumKorting: 'Bij korting',
  bedrag: 'Bedrag (incl.)',
  bedragBtw: 'Btw-bedrag',
  bedragKorting: 'Bet.kort',
  credit: 'Betreft een Credit-factuur',
  onderwerp: 'Onderwerp',
  notities: 'Notities',
  koppelenProducten: 'Producten',
  koppelenSponsorcontracten: 'Spon.cnt.',
  koppelenWerkbonnen: 'Werkbonnen',
  koppelenTransportopdrachten: 'Transportopdr.',
  koppelenRetourProducten: 'Ret.prod.',
};

const initieleWaardenBepalen = ({
  props,
  relatie,
  inkoopdienst,
  sponsordienst,
  servicedienst,
  meestRecenteFactuur,
  transportdienst,
}: {
  props: IProps;
  relatie?: IOphalenRelatiesResultElementV2;
  inkoopdienst?: IOphalenDienstenResultElement;
  sponsordienst?: IOphalenSponsorDienstenResultElement;
  servicedienst?: IOphalenServicedienstenResultElement;
  meestRecenteFactuur?: IOphalenFacturenResultElement;
  transportdienst?: IOphalenTransportDienstenResultElement;
}): IFormikValues => {
  const factuurdatum = dagDatum(props.factuurdatum ?? new Date());
  const betalingstermijnkorting = relatie?.crediteur?.BetalingstermijnKorting ?? null;

  return {
    relID: relatie?.RelID ?? props.relID ?? null,
    factuurnummer: props.factuurnummer ?? '',
    factuurreferentie: props.factuurreferentie ?? '',
    factuurdatum,
    vervaldatum: dagDatum(
      props.vervaldatum ?? addDays(factuurdatum, relatie?.crediteur?.Betalingstermijn ?? 0),
    ),
    vervaldatumKorting:
      betalingstermijnkorting === null
        ? null
        : dagDatum(addDays(new Date(factuurdatum), betalingstermijnkorting)),
    bedrag: props.bedragInclusiefBtw ?? 0,
    bedragBtw: props.btwBedrag ?? 0,
    bedragKorting: 0,
    credit: props.credit ?? false,
    onderwerp: props.onderwerp ?? '',
    notities: ``,
    koppelenProducten: inkoopdienst?.KoppelenProductenAanFactuur ?? false,
    koppelenSponsorcontracten: sponsordienst?.KoppelenContractenAanFactuur ?? false,
    koppelenWerkbonnen: servicedienst?.KoppelenWerkbonnenAanFactuur ?? false,
    koppelenTransportopdrachten: transportdienst?.KoppelenTransportopdrachtenAanFactuur ?? false,
    koppelenRetourProducten: false,
  };
};

export interface ICrediteurinfoDialoogState {
  relID: number;
}

const NieuweFactuurDialoogV2: React.FC<IProps> = observer((props) => {
  const { setGlobalCssValue } = useContext(AppContext);
  const { checkStore } = useContext(RootStoreContext);
  const [formulierBron, setFormulierBron] = React.useState<IRemoteData<IFormulierBron>>(
    props.relID === undefined ? createReadyRemoteData({}) : createPendingRemoteData(),
  );
  const [isFormulierMetPDF, setIsFormulierMetPDF] = React.useState(false);

  const bepalenFormulierBron = useCallback(async () => {
    if (props.relID === undefined) {
      setFormulierBron(createReadyRemoteData({}));
      return;
    }

    const [relatiesResult, dienstenResult, facturenResult] = await Promise.all([
      api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.relID] }],
        },
      }),
      api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
      }),

      api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [props.relID] }],
        },
      }),
    ]);
    const relatie = relatiesResult.relaties[0];
    const inkoopdienst = dienstenResult.diensten[0];
    const facturen = facturenResult.facturen;

    setFormulierBron(createReadyRemoteData({ relatie, inkoopdienst, facturen }));
  }, [props.relID]);

  useEffect(() => {
    bepalenFormulierBron();
  }, [bepalenFormulierBron]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IToevoegenInkoopfactuurParams = {
        relID: values.relID!,
        bedrag: values.bedrag,
        bedragBtw: values.bedragBtw,
        bedragKorting: values.bedragKorting,
        credit: values.credit,
        onderwerp: values.onderwerp.trim() === '' ? null : values.onderwerp.trim(),
        factuurnummer: values.factuurnummer,
        factuurreferentie: values.factuurreferentie,
        factuurdatum: values.factuurdatum,
        vervaldatum: values.vervaldatum,
        vervaldatumKorting: values.vervaldatumKorting,
        bronFactuur_BestandID: props.inkoopfactuurBestandID,
        koppelenProducten: values.koppelenProducten,
        koppelenSponsorcontracten: values.koppelenSponsorcontracten,
        koppelenWerkbonnen: values.koppelenWerkbonnen,
        koppelenTransportopdrachten: values.koppelenTransportopdrachten,
        koppelenRetourProducten: values.koppelenRetourProducten,
      };

      const checkData = await api.v2.inkoopfactuur.checkToevoegenInkoopfactuur(params);
      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }
      if (
        (await checkStore.bevestigen({ inhoud: 'Factuur vastleggen?' })).type ===
        EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.inkoopfactuur.toevoegenInkoopfactuur(params);

      props.onSuccess({
        inkFactID: result.inkFactID,
      });

      actions.setSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSuccess],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (formulierBron.state === ERemoteDataState.Pending) {
      return null;
    }
    const { relatie, inkoopdienst, facturen } = formulierBron.data!;
    const meestRecenteFactuur = facturen === undefined ? undefined : facturen[0];

    return initieleWaardenBepalen({ props, relatie, inkoopdienst, meestRecenteFactuur });
  }, [formulierBron, props]);

  const dialogClassName = useMemo(() => 'test-modal', []);
  useEffect(() => {
    setGlobalCssValue(
      dialogClassName,
      `
      .${dialogClassName} {
        ${props.inkoopfactuurBestandID !== undefined ? 'max-width: 1380px !important;' : ''}
      }
    `,
    );
  }, [props.inkoopfactuurBestandID]);

  return (
    <>
      <Dialoog
        index={props.dialoogIndex ?? 0}
        modalProps={isFormulierMetPDF ? { dialogClassName: 'modal-xxl' } : { size: 'lg' }}
        onEscape={props.onAnnuleren}
      >
        <ModalHeader>
          <ModalTitle>Nieuwe inkoopfactuur</ModalTitle>
        </ModalHeader>
        {initialValues === null ? (
          <ModalBody>
            <div className="flex-fill d-flex align-items-center justify-content-center m-5">
              <LoadingSpinner />
            </div>
          </ModalBody>
        ) : (
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            render={(formikProps) => {
              return (
                <Formulier
                  props={props}
                  formikProps={formikProps}
                  onAnnuleren={props.onAnnuleren}
                  inkoopfactuurBestandID={props.inkoopfactuurBestandID}
                  onIsFormulierMetPDFChange={setIsFormulierMetPDF}
                />
              );
            }}
          />
        )}
      </Dialoog>
    </>
  );
});

interface IFormulierProps {
  props: IProps;
  formikProps: FormikProps<IFormikValues>;
  initieelRelatie?: IOphalenRelatiesResultElementV2;
  initieelFacturen?: IOphalenFacturenResultElement[];
  inkoopfactuurBestandID?: number;
  onAnnuleren: () => void;
  onIsFormulierMetPDFChange: (isFormulierMetPDF: boolean) => void;
}

const Formulier = (props: IFormulierProps) => {
  const values = props.formikProps.values;

  const [relatie, setRelatie] = useState<IRemoteData<IOphalenRelatiesResultElementV2 | null>>(
    props.initieelRelatie === undefined
      ? createPendingRemoteData()
      : createReadyRemoteData(props.initieelRelatie),
  );
  const [facturen, setFacturen] = useState<IRemoteData<IOphalenFacturenResultElement[] | null>>(
    props.initieelFacturen === undefined
      ? createPendingRemoteData()
      : createReadyRemoteData(props.initieelFacturen),
  );

  const [
    crediteurinfoDialoogState,
    setCrediteurinfoDialoogState,
  ] = useState<ICrediteurinfoDialoogState | null>(null);

  const bepaalCrediteur = useCallback(async () => {
    if (values.relID === null) {
      setRelatie(createReadyRemoteData(null));
      setFacturen(createReadyRemoteData(null));
      return;
    }

    if (
      relatie.state === ERemoteDataState.Ready &&
      relatie.data !== null &&
      relatie.data!.RelID === values.relID
    ) {
      return;
    }

    setRelatie(createPendingRemoteData());
    setFacturen(createPendingRemoteData());

    const [
      relatiesResult,
      inkoopdienstenResult,
      sponsordienstenResult,
      servicedienstenResult,
      transportdienstenResult,
      facturenResult,
    ] = await Promise.all([
      api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [values.relID] }],
        },
      }),
      api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [values.relID] }] },
      }),
      api.v2.dienst.sponsoring.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [values.relID] }] },
      }),
      api.v2.dienst.service.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [values.relID] }] },
      }),
      api.v2.dienst.transport.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [values.relID] }] },
      }),
      api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [values.relID] }],
        },
      }),
    ]);
    const rel = relatiesResult.relaties[0];
    const inkoopdienst = inkoopdienstenResult.diensten[0];
    const sponsordienst = sponsordienstenResult.diensten[0];
    const servicedienst = servicedienstenResult.diensten[0];
    const transportdienst = transportdienstenResult.diensten[0];
    const meestRecenteFactuur = facturenResult.facturen[0];
    const waarden = initieleWaardenBepalen({
      props: props.props,
      relatie: rel,
      inkoopdienst,
      sponsordienst,
      servicedienst,
      transportdienst,
      meestRecenteFactuur,
    });
    props.formikProps.setValues(waarden);
    setRelatie(createReadyRemoteData(rel));
    setFacturen(createReadyRemoteData(facturenResult.facturen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.relID]);
  useEffect(() => {
    bepaalCrediteur();
  }, [bepaalCrediteur]);

  const [btwPercentage, setBtwPercentage] = useState<IRemoteData<number | null>>(
    createPendingRemoteData(),
  );
  const bepaalBtwPercentage = useCallback(async () => {
    setBtwPercentage(createPendingRemoteData());
    if (relatie.state === ERemoteDataState.Pending) {
      return;
    }
    if (relatie.data === null || values.factuurdatum === null) {
      setBtwPercentage(createReadyRemoteData(null));
      return;
    }

    const btwSrtID =
      relatie.data.crediteur?.BtwSrtInkoop_BtwSrtID ??
      (
        await api.v2.btw.ophalenBtwSoorten({
          filterSchema: { filters: [{ naam: 'NAAM_ENUMS', data: [EBtwsoort.AfdragenHoog] }] },
        })
      ).btwSoorten[0].ID;

    const btwTarievenResult = await api.v2.btw.ophalenBtwTarieven({
      filterSchema: {
        filters: [
          { naam: 'BTWSRT_IDS', data: [btwSrtID] },
          { naam: 'PEILDATUM', data: values.factuurdatum },
        ],
      },
    });
    const btwTarieven = btwTarievenResult.btwTarieven[0];

    setBtwPercentage(createReadyRemoteData(btwTarieven.Percentage));
  }, [relatie]);
  useEffect(() => {
    bepaalBtwPercentage();
  }, [bepaalBtwPercentage]);

  const [inkoopfactuurbestand, setInkoopfactuurbestand] = useState<
    IRemoteData<IOphalenBestandenResultElement | null>
  >(createPendingRemoteData());
  const ophalenInkoopfactuurbestand = useCallback(async () => {
    if (props.inkoopfactuurBestandID === undefined) {
      setInkoopfactuurbestand(createReadyRemoteData(null));
      return;
    }
    const bestandenResult = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.inkoopfactuurBestandID],
          },
        ],
      },
    });
    const bestandinfo = bestandenResult.bestanden[0];
    setInkoopfactuurbestand(createReadyRemoteData(bestandinfo));
  }, [props.inkoopfactuurBestandID]);
  useEffect(() => {
    ophalenInkoopfactuurbestand();
  }, [ophalenInkoopfactuurbestand]);

  const initieleRelatiesProvider = useMemo<IInitieleRelatiesProvider>(
    () => ({
      provide: async () => {
        const relatiesResult = await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IS_CREDITEUR',
                data: true,
              },
              // {
              //   naam: 'MET_OPENSTAANDE_FACTUREN',
              //   data: true,
              // },
            ],
          },
          orderSchema: { orders: [{ naam: 'WEERGAVENAAM', richting: 'ASC' }] },
        });
        return relatiesResult.relaties.map(relatieSelectieRelatieToRow);
      },
    }),
    [],
  );

  const notities = useMemo<IRemoteData<string | null>>(() => {
    if (relatie.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    return createReadyRemoteData(relatie.data?.Notities ?? null);
  }, [relatie]);

  const [factuurnummers, setFactuurnummers] = useState<IRemoteData<string[] | null>>(
    createPendingRemoteData(),
  );

  const [onderwerpen, setOnderwerpen] = useState<IRemoteData<string[] | null>>(
    createPendingRemoteData(),
  );

  const factuurnummerTekstSuggestieVeldExposeRef = useRef<IExposeData>();
  const onderwerpTekstSuggestieVeldExposeRef = useRef<IExposeData>();

  const factuurnummerInputComponent = useMemo<
    React.ForwardRefRenderFunction<any, IInputComponentProps>
  >(
    () => (inputProps, ref) => (
      <textarea {...(inputProps as any)} ref={ref} className="form-control" rows={1} />
    ),
    [],
  );

  const factuurreferentieInputComponent = useMemo<
    React.ForwardRefRenderFunction<any, IInputComponentProps>
  >(
    () => (inputProps, ref) => (
      <textarea
        {...(inputProps as any)}
        ref={ref}
        className="form-control"
        rows={1}
        placeholder={
          'B.v. Rel.nr 23001, Fact.nr F509191-01. Indien leeg wordt het factuurnummer aangehouden'
        }
      />
    ),
    [],
  );

  const onderwerpInputComponent = useMemo<
    React.ForwardRefRenderFunction<any, IInputComponentProps>
  >(
    () => (inputProps, ref) => (
      <textarea {...(inputProps as any)} ref={ref} className="form-control" rows={1} />
    ),
    [],
  );

  // Onderwerpen en Factuurnummers om de suggestievelden daarvan te vullen
  const ophalenSuggesties = useCallback(async () => {
    if (values.relID === null) {
      setFactuurnummers(createReadyRemoteData(null));
      setOnderwerpen(createReadyRemoteData(null));

      if (factuurnummerTekstSuggestieVeldExposeRef.current !== undefined) {
        factuurnummerTekstSuggestieVeldExposeRef.current.forceerResolveSuggesties();
      }
      if (onderwerpTekstSuggestieVeldExposeRef.current !== undefined) {
        onderwerpTekstSuggestieVeldExposeRef.current.forceerResolveSuggesties();
      }
      return;
    }
    setFactuurnummers(createPendingRemoteData());
    setOnderwerpen(createPendingRemoteData());
    const suggestiesResult = await api.v2.inkoopfactuur.ophalenFactuurSuggesties({
      relID: values.relID,
    });
    setFactuurnummers(createReadyRemoteData(suggestiesResult.factuurnummers));
    setOnderwerpen(createReadyRemoteData(suggestiesResult.onderwerpen));

    if (factuurnummerTekstSuggestieVeldExposeRef.current !== undefined) {
      factuurnummerTekstSuggestieVeldExposeRef.current.forceerResolveSuggesties();
    }
    if (onderwerpTekstSuggestieVeldExposeRef.current !== undefined) {
      onderwerpTekstSuggestieVeldExposeRef.current.forceerResolveSuggesties();
    }
  }, [values.relID]);

  useEffect(() => {
    ophalenSuggesties();
  }, [ophalenSuggesties]);

  useEffect(() => {
    if (
      btwPercentage.state === ERemoteDataState.Pending ||
      btwPercentage.data === null ||
      relatie.data === null
    ) {
      return;
    }

    if (values.bedrag <= 0) {
      props.formikProps.setFieldValue(nameOf<IFormikValues>('credit'), false);
    }

    // Bereken het bedrag aan btw
    const btw =
      props.props.btwNietAutomatischHerberekenen && props.formikProps.values.bedragBtw
        ? props.formikProps.values.bedragBtw
        : values.bedrag * (1 - 1 / (1 + btwPercentage.data / 100));

    if (!props.props.btwNietAutomatischHerberekenen) {
      props.formikProps.setFieldValue(nameOf<IFormikValues>('bedragBtw'), btw);
    }

    if (relatie.data.crediteur!.BetalingstermijnKorting !== null) {
      const bedragExBtw = values.bedrag - btw;
      const bedragKorting = bedragExBtw * (relatie.data.crediteur!.KortingsPercentage! / 100);
      props.formikProps.setFieldValue(nameOf<IFormikValues>('bedragKorting'), bedragKorting);
    }
  }, [values.bedrag, btwPercentage, relatie.data]);

  useEffect(() => {
    if (
      values.factuurdatum === null ||
      relatie.state !== ERemoteDataState.Ready ||
      relatie.data === null
    ) {
      return;
    }

    const vervaldatum = dagDatum(
      addDays(new Date(values.factuurdatum), relatie.data.crediteur!.Betalingstermijn),
    );
    props.formikProps.setFieldValue(nameOf<IFormikValues>('vervaldatum'), vervaldatum);

    if (relatie.data.crediteur!.BetalingstermijnKorting !== null) {
      const vervaldatumBijKorting = dagDatum(
        addDays(new Date(values.factuurdatum), relatie.data.crediteur!.BetalingstermijnKorting),
      );
      props.formikProps.setFieldValue(
        nameOf<IFormikValues>('vervaldatumKorting'),
        vervaldatumBijKorting,
      );
    }
  }, [values.factuurdatum, relatie.state, relatie.data]);

  useEffect(() => {
    const heeftPDF = inkoopfactuurbestand.data !== null;
    props.onIsFormulierMetPDFChange(heeftPDF);
  }, [inkoopfactuurbestand.data, props.onIsFormulierMetPDFChange]);

  return (
    <>
      <ModalBody className="p-0 d-flex flex-column">
        <div className="d-flex flex-fill">
          <div className="pl-4 pr-4 d-flex flex-column flex-fill">
            <div className="mt-3">
              <label>{veldnamen.relID}</label>
              <Field
                name={nameOf<IFormikValues>('relID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <a
                        href="#"
                        // style={{ color: Kleur.DonkerGrijs }}
                        onClick={() => {
                          if (field.value === null) {
                            return;
                          }
                          setCrediteurinfoDialoogState({ relID: field.value });
                        }}
                      >
                        <IconInformatie
                          style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                          className="ml-2"
                        />
                      </a>
                      <RelatieSelectieUitgebreid
                        filters={[
                          { naam: 'IS_CREDITEUR', data: true },
                          { naam: 'NIET_GEBRUIKEN', data: false },
                        ]}
                        onChange={(relID) => {
                          fieldProps.form.setFieldValue(fieldProps.field.name, relID);
                        }}
                        relID={fieldProps.field.value}
                        initieleRelatiesProvider={initieleRelatiesProvider}
                        options={{
                          geenWaardeBericht: 'Selecteer een crediteur uit de lijst',
                        }}
                      />
                    </>
                  );
                }}
              />
              {/* <span>
                  {' '}
                  <a
                    href="#"
                    // style={{ color: Kleur.DonkerGrijs }}
                    onClick={() => {
                      setCrediteurinfoDialoogState({ relID: 1 });
                    }}
                  >
                    <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                  </a>
                </span> */}
            </div>
            {notities.state !== ERemoteDataState.Pending && notities.data !== null && (
              <div className="mt-3">
                <VeldWeergave style={{ maxHeight: 40, overflow: 'auto' }}>
                  {notities.data!}
                </VeldWeergave>
              </div>
            )}
            <div className="mt-3">
              <label>{veldnamen.factuurnummer}</label>
              <Field
                name={nameOf<IFormikValues>('factuurnummer')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <div>
                      <TekstSuggestieVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        suggestiesResolver={async (waarde) => {
                          if (factuurnummers.state === ERemoteDataState.Pending) {
                            return [];
                          }
                          return factuurnummers.data! ?? [];
                        }}
                        inputComponent={factuurnummerInputComponent}
                        onExpose={(expose) =>
                          (factuurnummerTekstSuggestieVeldExposeRef.current = expose)
                        }
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </div>
                  );
                }}
              />
            </div>
            <div className="mt-3">
              <label>{veldnamen.factuurreferentie}</label>
              <Field
                name={nameOf<IFormikValues>('factuurreferentie')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <div>
                      <TekstSuggestieVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        suggestiesResolver={async (waarde) => {
                          // Stel rijbron samen met bv Factuurnr of Relatienr/Factuurnr

                          const factuurnummer =
                            form.values.factuurnummer !== '' ? form.values.factuurnummer : null;
                          const relatienummer =
                            relatie.state === ERemoteDataState.Ready && relatie.data !== null
                              ? relatie.data.Relatienummer
                              : null;

                          const optie1 =
                            relatienummer !== null && factuurnummer !== null
                              ? 'Rel.nr ' + relatienummer + ', Fact.nr ' + factuurnummer
                              : null;
                          const optie2 =
                            relatienummer !== null && factuurnummer === null
                              ? 'Rel.nr ' + relatienummer
                              : null;
                          const optie3 =
                            relatienummer === null && factuurnummer !== null
                              ? 'Fact.nr ' + factuurnummer
                              : null;

                          const opties = [];
                          if (optie1 !== null) {
                            opties.push(optie1);
                          }
                          if (optie2 !== null) {
                            opties.push(optie2);
                          }
                          if (optie3 !== null) {
                            opties.push(optie3);
                          }

                          return opties;
                        }}
                        inputComponent={factuurreferentieInputComponent}
                        onExpose={(expose) =>
                          (factuurnummerTekstSuggestieVeldExposeRef.current = expose)
                        }
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </div>
                  );
                }}
              />
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label>{veldnamen.factuurdatum}</label>
                <Field
                  name={nameOf('factuurdatum')}
                  render={({ field, form }: FieldProps<IFormikValues>) => {
                    const minimaleDatum = addDays(new Date(), -365);
                    const maximaleDatum = addDays(new Date(), +30);
                    return (
                      <div className="d-flex align-items-center">
                        <DatumKiezer
                          dateFormat={'dd-MM-yyyy'}
                          onGewijzigd={(x) => {
                            form.setFieldValue(field.name, x);
                          }}
                          waarde={field.value}
                          determineValidDate={(date) => {
                            return date >= minimaleDatum && date <= maximaleDatum;
                          }}
                          determineNextValidDate={(date) => {
                            const nieuweDatum = addDays(date, +1);
                            if (nieuweDatum <= maximaleDatum) {
                              return nieuweDatum;
                            }
                            return null;
                          }}
                          determinePreviousValidDate={(date) => {
                            const nieuweDatum = addDays(date, -1);
                            if (nieuweDatum >= minimaleDatum) {
                              return nieuweDatum;
                            }
                            return null;
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>

              <div className="col-4">
                <label>{veldnamen.vervaldatum}</label>
                <Field
                  name={nameOf('vervaldatum')}
                  render={({ field, form }: FieldProps<IFormikValues>) => {
                    const minimaleDatum = addDays(new Date(), -365);
                    const maximaleDatum = addDays(new Date(), +30);
                    return (
                      <div className="d-flex align-items-center">
                        <DatumKiezer
                          dateFormat={'dd-MM-yyyy'}
                          onGewijzigd={(x) => {
                            form.setFieldValue(field.name, x);
                          }}
                          waarde={field.value}
                          determineValidDate={(date) => {
                            return date >= minimaleDatum && date <= maximaleDatum;
                          }}
                          determineNextValidDate={(date) => {
                            const nieuweDatum = addDays(date, +1);
                            if (nieuweDatum <= maximaleDatum) {
                              return nieuweDatum;
                            }
                            return null;
                          }}
                          determinePreviousValidDate={(date) => {
                            const nieuweDatum = addDays(date, -1);
                            if (nieuweDatum >= minimaleDatum) {
                              return nieuweDatum;
                            }
                            return null;
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>

              {relatie.data !== null && relatie.data.crediteur!.BetalingstermijnKorting !== null && (
                <div className="col-4">
                  <label>{veldnamen.vervaldatumKorting}</label>
                  <Field
                    name={nameOf('vervaldatumKorting')}
                    render={({ field, form }: FieldProps<IFormikValues>) => {
                      const minimaleDatum = addDays(new Date(), -365);
                      const maximaleDatum = addDays(new Date(), +30);
                      return (
                        <div className="d-flex align-items-center">
                          <DatumKiezer
                            dateFormat={'dd-MM-yyyy'}
                            onGewijzigd={(x) => {
                              form.setFieldValue(field.name, x);
                            }}
                            waarde={field.value}
                            isClearable
                            determineValidDate={(date) => {
                              return date >= minimaleDatum && date <= maximaleDatum;
                            }}
                            determineNextValidDate={(date) => {
                              const nieuweDatum = addDays(date, +1);
                              if (nieuweDatum <= maximaleDatum) {
                                return nieuweDatum;
                              }
                              return null;
                            }}
                            determinePreviousValidDate={(date) => {
                              const nieuweDatum = addDays(date, -1);
                              if (nieuweDatum >= minimaleDatum) {
                                return nieuweDatum;
                              }
                              return null;
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label>{veldnamen.bedrag}</label>
                <Field
                  name={nameOf<IFormikValues>('bedrag')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div>
                        <BedragInput
                          value={field.value}
                          onChange={(x) => {
                            form.setFieldValue(field.name, x);
                          }}
                          min={0}
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </div>
                    );
                  }}
                />
              </div>
              <div className="col-4">
                <label>{veldnamen.bedragBtw}</label>
                <Field
                  name={nameOf<IFormikValues>('bedragBtw')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div>
                        <BedragInput
                          value={field.value}
                          onChange={(x) => {
                            form.setFieldValue(field.name, x);
                          }}
                          min={0}
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </div>
                    );
                  }}
                />
              </div>
              {relatie.data !== null && relatie.data.crediteur!.BetalingstermijnKorting !== null && (
                <div className="col-4">
                  <label>{veldnamen.bedragKorting}</label>
                  <Field
                    name={nameOf<IFormikValues>('bedragKorting')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <div>
                          <BedragInput
                            value={field.value}
                            onChange={(x) => {
                              form.setFieldValue(field.name, x);
                            }}
                            min={0}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <Field
                  name={nameOf<IFormikValues>('credit')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div className="d-flex align-items-center">
                        <VinkVeld
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          aangevinkt={field.value}
                          disabled={values.bedrag === 0}
                        />
                        <span className="ml-2">{veldnamen.credit}</span>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-12">
                <label>{veldnamen.onderwerp}</label>
                <Field
                  name={nameOf<IFormikValues>('onderwerp')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <>
                        <TekstSuggestieVeld
                          waarde={field.value}
                          onChange={(x) => form.setFieldValue(field.name, x)}
                          suggestiesResolver={async (waarde) => {
                            if (onderwerpen.state === ERemoteDataState.Pending) {
                              return [];
                            }
                            return onderwerpen.data! ?? [];
                          }}
                          inputComponent={onderwerpInputComponent}
                          onExpose={(expose) =>
                            (onderwerpTekstSuggestieVeldExposeRef.current = expose)
                          }
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </>
                    );
                  }}
                />
              </div>
            </div>
            <div className="mt-2 mb-2">Koppelen aan</div>
            <div
              className="mt-2 mb-2"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                width: '100%',
                rowGap: 10,
                columnGap: 10,
              }}
            >
              <div>
                <Field
                  name={nameOf<IFormikValues>('koppelenProducten')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div className="d-flex align-items-center">
                        <VinkVeld
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          aangevinkt={field.value}
                        />
                        <span className="ml-2">{veldnamen.koppelenProducten}</span>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <Field
                  name={nameOf<IFormikValues>('koppelenTransportopdrachten')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div className="d-flex align-items-center">
                        <VinkVeld
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          aangevinkt={field.value}
                        />
                        <span className="ml-2">{veldnamen.koppelenTransportopdrachten}</span>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <Field
                  name={nameOf<IFormikValues>('koppelenWerkbonnen')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div className="d-flex align-items-center">
                        <VinkVeld
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          aangevinkt={field.value}
                        />
                        <span className="ml-2">{veldnamen.koppelenWerkbonnen}</span>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <Field
                  name={nameOf<IFormikValues>('koppelenRetourProducten')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div className="d-flex align-items-center">
                        <VinkVeld
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          aangevinkt={field.value}
                        />
                        <span className="ml-2">{veldnamen.koppelenRetourProducten}</span>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <Field
                  name={nameOf<IFormikValues>('koppelenSponsorcontracten')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div className="d-flex align-items-center">
                        <VinkVeld
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          aangevinkt={field.value}
                        />
                        <span className="ml-2">{veldnamen.koppelenSponsorcontracten}</span>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {inkoopfactuurbestand.state === ERemoteDataState.Ready &&
            inkoopfactuurbestand.data !== null && (
              <div
                className="d-flex flex-column"
                style={{ maxWidth: 850, minWidth: 850, minHeight: 585, maxHeight: 585 }}
              >
                <PDFWeergever url={inkoopfactuurbestand.data.url} />
              </div>
            )}

          {crediteurinfoDialoogState !== null && (
            <CrediteurInfoDialoog
              open
              relID={crediteurinfoDialoogState.relID}
              onSuccess={() => null}
              onAnnuleren={() => setCrediteurinfoDialoogState(null)}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={props.formikProps.submitForm}
          disabled={props.formikProps.isSubmitting}
          style={{ width: 100 }}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={props.formikProps.isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default NieuweFactuurDialoogV2;
