import React, { useMemo } from 'react';
import { EMutatiebron } from '../../bedrijfslogica/enums';
import { Kleur, mutatiebronIconMap } from '../../bedrijfslogica/constanten';
import UitlegTooltip from '../formulier/UitlegTooltip';

interface IProps {
  mutatiebron: EMutatiebron;
  style?: React.CSSProperties;
}

const MutatiebronVisualisatie = (props: IProps) => {
  const tooltip = useMemo(() => {
    switch (props.mutatiebron) {
      case EMutatiebron.Intern:
        return 'Door een medewerker van splash of het systeem zelf';
      case EMutatiebron.Website:
        return 'Door de klant via de website';
      case EMutatiebron.GebruikersApp:
        return 'Door de klant via de app';
    }
  }, [props.mutatiebron]);
  const Icon = mutatiebronIconMap[props.mutatiebron];

  return (
    <UitlegTooltip inhoud={tooltip}>
      <Icon style={{ fill: Kleur.Grijs, width: 16, height: 16, ...props.style }} />
    </UitlegTooltip>
  );
};

export default MutatiebronVisualisatie;
