import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../api';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { IconLink, IconVerwijderen } from '../../../../../../components/Icons';
import MenuLayout from '../../../../../../components/MenuLayout';
import nameOf from '../../../../../../core/nameOf';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import KoppelenDialoog from './KoppelenDialoog';
import { IOphalenProductmodellenResultElement } from '../../../../../../../../shared/src/api/v2/aanbod/productmodel';
import { IConcurrentProductKoppeling } from '../../../../../../../../shared/src/api/v2/concurrent';

interface IProps {
  koppelBasisID: number;
}

interface IKoppelenDialoogState {
  koppelBasisID: number;
}

const Item: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<IConcurrentProductKoppeling[] | null>(null);
  const [koppelbareData, setKoppelbareData] = useState<
    IOphalenProductmodellenResultElement[] | null
  >(null);
  const [koppelenDialoogState, setKoppelenDialoogState] = useState<IKoppelenDialoogState | null>(
    null,
  );
  const [selectieState, setSelectieState] = useState<number[]>([]);

  const ophalenRijen = async () => {
    const results = await api.v2.concurrentie.ophalenProductModelKoppelingen({
      filterSchema: {
        filters: [{ naam: 'CONCURRENT_PROD_IDS', data: [props.koppelBasisID] }],
      },
    });

    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, []);

  const ophalenKoppelbareData = async () => {
    const results = await api.v2.product.model.ophalenProductmodellen({
      filterSchema: {
        filters: [
          {
            naam: 'ACTIEF',
            data: true,
          },
        ],
      },
    });
    setKoppelbareData(results.modellen);
  };

  useEffect(() => {
    ophalenKoppelbareData();
  }, []);

  const handleKoppelen = useCallback(async () => {
    setKoppelenDialoogState({ koppelBasisID: props.koppelBasisID });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: selectieState };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde items ontkoppelen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.concurrentie.ontkoppelenProductModellen(params);
    setSelectieState([]);

    await ophalenRijen();
  }, [selectieState]);

  const gekoppeldeData = (id: number) => {
    let result = koppelbareData?.find((d) => d.ProdModID == id);
    return result;
  };

  const keyExtractor = useCallback(
    (row: IConcurrentProductKoppeling) => (row.ID == null ? 0 : row.ID),
    [rijen],
  );

  const kolommen = useMemo<TypedColumn<IConcurrentProductKoppeling>[]>(
    () => [
      {
        name: 'ProdModID',
        title: 'Productmodel',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IConcurrentProductKoppeling>[]>(
    () => [
      {
        columnName: 'ProdModID',
        width: 400,
      },
      {
        columnName: 'Notities',
        width: 400,
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex">
        <MenuLayout
          menu={
            <div className="d-flex align-items-center">
              <span style={{ marginRight: 10 }}>Gekoppelde modellen</span>
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={false}
                onClick={() => handleKoppelen()}
              >
                <IconLink style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuw model koppelen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={selectieState.length == 0}
                onClick={() => handleVerwijderen()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Ontkoppelen</span>
              </button>
            </div>
          }
          body={
            <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
              {rijen === null ? (
                <LoadingSpinner />
              ) : (
                <GridStyleWrapper height="200px">
                  <Grid getRowId={keyExtractor} rows={rijen} columns={kolommen}>
                    <DataTypeProvider
                      for={[nameOf<IConcurrentProductKoppeling>('ProdModID')]}
                      formatterComponent={(formatterProps) => (
                        <span>{gekoppeldeData(formatterProps.value)?.Modelnaam}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={[nameOf<IConcurrentProductKoppeling>('Notities')]}
                      formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                    />
                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />

                    <VirtualTable messages={{ noData: 'Geen modellen aanwezig' }} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <TableHeaderRow />
                    <SelectionState
                      selection={selectieState}
                      onSelectionChange={(x) => setSelectieState(x as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              )}
            </div>
          }
        />
      </div>

      {koppelenDialoogState !== null && (
        <KoppelenDialoog
          open
          koppelBasisID={koppelenDialoogState.koppelBasisID}
          onSuccess={async () => {
            setKoppelenDialoogState(null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setKoppelenDialoogState(null)}
        />
      )}
    </>
  );
};

export default Item;
