import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import GebruikerRij from './GebruikerRij';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import api from '../../../api';
import { useRealtimeListener } from '../../../one-off-components/realtime/RealtimeIntegratie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenPersonenResult } from '../../../../../shared/src/api/v2/persoon/persoon';
import TegelBasis from '../../../components/tegel/TegelBasis';
import {
  IOphalenGebruikersprofielenResult,
  IAspGebruikerStatusIngesteldMessageData,
  IAspGebruikerGebruikersprofielIngesteldMessageData,
} from '../../../../../shared/src/api/v2/aspGebruiker';

interface IProps {}

const OnlineGebruikers: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const [onlineAspGebrIDs, setOnlineAspGebrIDs] = useState<number[] | null>(null);

  const ophalenOnlineAspGebrIDs = useCallback(async () => {
    const result = await api.v2.aspGebruiker.ophalenOnlineAspGebrIDs({});
    setOnlineAspGebrIDs(result.ids);
  }, []);
  useEffect(() => {
    ophalenOnlineAspGebrIDs();
  }, [ophalenOnlineAspGebrIDs]);

  const onlineGebruikers = useMemo(() => {
    if (gebruikerStore.gebruikers === null || onlineAspGebrIDs === null) {
      return null;
    }
    return onlineAspGebrIDs.map(
      (id) => gebruikerStore.gebruikers!.find((x) => x.AspGebrID === id)!,
    );
  }, [gebruikerStore.gebruikers, onlineAspGebrIDs]);

  const [personen, setPersonen] = useState<IRemoteData<IOphalenPersonenResult | null>>(
    createPendingRemoteData(),
  );
  const ophalenPersonen = useCallback(async () => {
    if (onlineGebruikers === null) {
      setPersonen(createPendingRemoteData());
      return;
    }
    const persIDs = onlineGebruikers.map((x) => x.PersID).filter((x) => x !== null);
    if (persIDs.length === 0) {
      setPersonen(createReadyRemoteData(null));
      return;
    }

    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: persIDs,
          },
        ],
      },
    });
    setPersonen(createReadyRemoteData(result));
  }, [onlineGebruikers]);
  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const [profielenResult, setProfielenResult] = useState<
    IRemoteData<IOphalenGebruikersprofielenResult | null>
  >(createPendingRemoteData());
  const ophalenProfielen = useCallback(async () => {
    if (onlineGebruikers === null) {
      setProfielenResult(createPendingRemoteData());
      return;
    }

    const result = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS_IN',
            data: onlineGebruikers.map((x) => x.AspGebrID),
          },
        ],
      },
    });
    setProfielenResult(createReadyRemoteData(result));
  }, [onlineGebruikers]);
  useEffect(() => {
    ophalenProfielen();
  }, [ophalenProfielen]);

  useRealtimeListener(async (naamEnum, data) => {
    if (naamEnum === 'ONLINE_GEBRUIKERS_GEWIJZIGD') {
      setOnlineAspGebrIDs(data as number[]);
      return;
    } else if (naamEnum === 'ASP_GEBRUIKER_GEBRUIKERSPROFIEL_INGESTELD') {
      const d = data as IAspGebruikerGebruikersprofielIngesteldMessageData;
      await ophalenProfielen();
      return;
    } else if (naamEnum === 'ASP_GEBRUIKER_GEWIJZIGD') {
      await ophalenProfielen();
      return;
    }
  });

  return (
    <TegelBasis titel="Online gebruikers">
      {onlineGebruikers === null ||
      profielenResult.state === ERemoteDataState.Pending ||
      personen.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-column">
          {onlineGebruikers.map((gebruiker) => {
            const profiel = profielenResult.data!.profielen.find(
              (x) => x.ID === gebruiker.AspGebrProfielID,
            );
            const persoon =
              gebruiker.PersID === null
                ? undefined
                : personen.data!.personen.find((x) => x.PersID === gebruiker.PersID);
            return (
              <GebruikerRij
                key={gebruiker.AspGebrID}
                gebruiker={gebruiker}
                persoon={persoon}
                profiel={profiel}
              />
            );
          })}
        </div>
      )}
    </TegelBasis>
  );
});

export default OnlineGebruikers;
