import {
  IOphalenBezoekdagenParams,
  IOphalenBezoekdagenResult,
  IVerwijderenBezoektijdenParams,
  IVerwijderenBezoektijdenResult,
  IOphalenBezoektijdenParams,
  IOphalenBezoektijdenResult,
  IVersturenBezoekbevestigingenParams,
  IVersturenBezoekbevestigingenResult,
  IOphalenBezoeksetsParams,
  IOphalenBezoeksetsResult,
  IOphalenBezoeksetregelsParams,
  IOphalenBezoeksetregelsResult,
  IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenParams,
  IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenResult,
} from '../../../../../../shared/src/api/v2/transport/bezoek';
import {
  IVersturenBezoekHerinneringenParams,
  IVersturenBezoekHerinneringenResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/uitstaand';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const bezoek = {
  ophalenBezoekdagen: async (
    params: IOphalenBezoekdagenParams,
  ): Promise<IOphalenBezoekdagenResult> => {
    return await api.post('v2/transport/bezoek/ophalen-bezoekdagen', params);
  },
  verwijderenBezoektijden: async (
    params: IVerwijderenBezoektijdenParams,
  ): Promise<IVerwijderenBezoektijdenResult> => {
    return await api.post('v2/transport/bezoek/verwijderen-bezoektijden', params);
  },
  ophalenBezoektijden: async (
    params: IOphalenBezoektijdenParams,
  ): Promise<IOphalenBezoektijdenResult> => {
    return await api.post('v2/transport/bezoek/ophalen-bezoektijden', params);
  },
  checkVersturenBezoekHerinneringen: async (
    params: IVersturenBezoekHerinneringenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/bezoek/check-versturen-bezoekherinneringen', params);
  },
  versturenBezoekHerinneringen: async (
    params: IVersturenBezoekHerinneringenParams,
  ): Promise<IVersturenBezoekHerinneringenResult> => {
    return await api.post('v2/transport/bezoek/versturen-bezoekherinneringen', params);
  },
  versturenBezoekbevestigingen: async (
    params: IVersturenBezoekbevestigingenParams,
  ): Promise<IVersturenBezoekbevestigingenResult> => {
    return await api.post('v2/transport/bezoek/versturen-bezoekbevestigingen', params);
  },
  ophalenBezoeksets: async (
    params: IOphalenBezoeksetsParams,
  ): Promise<IOphalenBezoeksetsResult> => {
    return await api.post('v2/transport/bezoek/ophalen-bezoeksets', params);
  },
  ophalenBezoeksetregels: async (
    params: IOphalenBezoeksetregelsParams,
  ): Promise<IOphalenBezoeksetregelsResult> => {
    return await api.post('v2/transport/bezoek/ophalen-bezoeksetregels', params);
  },
  ophalenTransportopdrachtenMetGewijzigdeBezoektijden: async (
    params: IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenParams,
  ): Promise<IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenResult> => {
    return await api.post(
      'v2/transport/bezoek/ophalen-transportopdrachten-met-gewijzigde-bezoektijden',
      params,
    );
  },
};

export default bezoek;
