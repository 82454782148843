import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { IFormikValues, veldnamen, WijzigenDialoogContext } from '..';
import ModalBody from 'react-bootstrap/ModalBody';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../core/nameOf';
import LocatieSelectie from '../../../formulier/LocatieSelectie';
import VinkVeld from '../../../formulier/VinkVeld';
import Combobox from '../../../formulier/Combobox';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import NumeriekVeld from '../../../formulier/NumeriekVeld';
import _ from 'lodash';
import DatumKiezer from '../../../formulier/DatumKiezer';
import { addDays } from 'date-fns';

const DetailTab: React.FC = (props) => {
  const { verdiepingen, opdracht, indicatiesPremium } = useContext(WijzigenDialoogContext);

  const relIDs = useMemo<number[] | null>(
    () =>
      opdracht === null
        ? null
        : _.uniq(
            opdracht.regels
              .map((x) => (x.RelID === null ? null : x.RelID))
              .filter((x) => x !== null),
          ),
    [opdracht],
  );

  return (
    <ModalBody>
      <div style={{ minHeight: `400px` }}>
        <div className="row">
          <div className="col-12">
            <label>{veldnamen.locID}</label>
            <Field
              name={nameOf<IFormikValues>('locID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <LocatieSelectie
                    locID={field.value}
                    onChange={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                    relIDs={relIDs || undefined}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <label>{veldnamen.verdiepingOpdracht}</label>
            <Field
              name={nameOf<IFormikValues>('verdiepingOpdracht')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                if (verdiepingen === null) {
                  return <LoadingSpinner />;
                }

                return (
                  <>
                    <Combobox
                      legeOptieTonen
                      geselecteerd={field.value}
                      onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                      opties={verdiepingen!.map((x) => {
                        return {
                          id: x.Verdieping,
                          label: x.Naam,
                        };
                      })}
                    />
                  </>
                );
              }}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="row mt-3">
              <div className="col-4">
                <label>{veldnamen.aantalAfvoer}</label>
                <Field
                  name={nameOf<IFormikValues>('aantalAfvoer')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                        max={99}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row mt-3">
              <div className="col-4">
                <label>{veldnamen.indicatiePremium}</label>
                <Field
                  name={nameOf<IFormikValues>('indicatiePremium')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    if (indicatiesPremium === null) {
                      return <LoadingSpinner />;
                    }

                    return (
                      <>
                        <Combobox
                          legeOptieTonen
                          geselecteerd={field.value}
                          onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                          opties={indicatiesPremium!.map((x) => {
                            return {
                              id: x.ID,
                              label: x.Naam,
                            };
                          })}
                        />
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row mt-3">
              <div className="col-6">
                <label>{veldnamen.facturatieBeoordeeldOp}</label>
                <Field
                  name={nameOf<IFormikValues>('facturatieBeoordeeldOp')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    const minimaleDatum = addDays(new Date(), -90);
                    const maximaleDatum = addDays(new Date(), +7);

                    return (
                      <DatumKiezer
                        waarde={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        isClearable
                        determineValidDate={(date) => {
                          return date >= minimaleDatum && date <= maximaleDatum;
                        }}
                        determineNextValidDate={(date) => {
                          const nieuweDatum = addDays(date, +1);
                          if (nieuweDatum <= maximaleDatum) {
                            return nieuweDatum;
                          }
                          return null;
                        }}
                        determinePreviousValidDate={(date) => {
                          const nieuweDatum = addDays(date, -1);
                          if (nieuweDatum >= minimaleDatum) {
                            return nieuweDatum;
                          }
                          return null;
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row pt-2">
          <div className="col-12">
            <label>{veldnamen.bezoekInstructies}</label>
            <Field
              name={nameof<IFormikValues>('bezoekInstructies')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return <textarea {...field} className="form-control" rows={2} />;
              }}
            />
          </div>
        </div> */}

        <div className="row pt-3">
          <div className="col-6 d-flex align-items-center pt-2">
            <Field
              name={nameOf<IFormikValues>('ophouden')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.ophouden}</span>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 d-flex align-items-center pt-2">
            <Field
              name={nameOf<IFormikValues>('documentatieNietVersturen')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.documentatieNietVersturen}</span>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 d-flex align-items-center pt-2">
            <Field
              name={nameOf<IFormikValues>('nietWeergevenBijPortaal')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.nietWeergevenBijPortaal}</span>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 d-flex align-items-center pt-2">
            <Field
              name={nameOf<IFormikValues>('gedwongenRetour')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.gedwongenRetour}</span>
          </div>
        </div>
      </div>
    </ModalBody>
  );
};

export default DetailTab;
