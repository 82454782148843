import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../core/nameOf';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import api from '../../../../api';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/pendel';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/pendel';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { addDays, format } from 'date-fns';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';

interface IProps extends IDialoogProps {
  ID: number;
}

interface IFormikValues {
  dienstID: number | null;
  datumGepland: Date | null;
  datumUitgevoerd: Date | null;
  notities: string | null;
  afgehandeld: boolean;
  maxAantalProducten: number;
  gesloten: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  dienstID: 'Pendeldienst',
  datumGepland: 'Plandatum',
  datumUitgevoerd: 'Uitgevoerd op',
  notities: 'Notities',
  afgehandeld: 'Afgehandeld',
  maxAantalProducten: 'Max. aantal producten',
  gesloten: 'Gesloten voor toevoegen',
};

const WijzigenOpdrachtDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);
  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);

  // Ophalen opdracht
  useEffect(() => {
    (async () => {
      const result = await api.v2.pendel.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.ID] }] },
      });

      setOpdracht(result.opdrachten[0]);
    })();
  }, [props.ID]);

  // Pendeldiensten
  useEffect(() => {
    (async () => {
      const result = await api.v2.dienst.pendel.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });
      setDiensten(result.diensten);
    })();
  }, []);

  const dienstenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (diensten === null) {
      return null;
    }

    return diensten.map(
      (dienst): IOptie<number> => ({
        id: dienst.ID,
        label: dienst.relatie!.weergavenaam,
      }),
    );
  }, [diensten]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (opdracht === null) {
      return null;
    }

    return {
      dienstID: opdracht.dienst !== null ? opdracht.dienst.ID : null,
      datumGepland: opdracht.DatumGepland !== null ? new Date(opdracht.DatumGepland) : null,
      datumUitgevoerd:
        opdracht.DatumUitgevoerd !== null ? new Date(opdracht.DatumUitgevoerd) : null,
      notities: opdracht.Notities,
      afgehandeld: opdracht.Afgehandeld,
      maxAantalProducten: opdracht.MaxAantalProducten,
      gesloten: opdracht.Gesloten,
    };
  }, [opdracht]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        id: props.ID,
        notities: values.notities,
        penDienstID: values.dienstID,
        datumGepland:
          values.datumGepland !== null ? format(values.datumGepland, 'yyyy-MM-dd') : null,
        datumUitgevoerd:
          values.datumUitgevoerd !== null ? format(values.datumUitgevoerd, 'yyyy-MM-dd') : null,
        afgehandeld: values.afgehandeld,
        maxAantalProducten: values.maxAantalProducten,
        gesloten: values.gesloten,
      };
      const checkData = await api.v2.pendel.checkWijzigenOpdracht(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      await api.v2.pendel.wijzigenOpdracht(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {opdracht === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center m-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Wijzigen opdracht</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label>{veldnamen.dienstID}</label>
                          <Field
                            name="dienstID"
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <>
                                  <Combobox
                                    geselecteerd={field.value}
                                    opties={dienstenOpties === null ? [] : dienstenOpties}
                                    onSelectieChange={(id) => {
                                      fieldProps.form.setFieldValue(fieldProps.field.name, id);
                                    }}
                                    legeOptieTonen
                                    options={{
                                      legeOptieTekst: 'Geen dienst',
                                    }}
                                  />
                                  {/* <FormikVeldFout
                                    fieldProps={fieldProps}
                                    directTonen={hasSubmitted}
                                  /> */}
                                </>
                              );
                            }}
                          />
                        </div>

                        <div className="col-6 mt-3">
                          <label>{veldnamen.maxAantalProducten}</label>
                          <Field
                            name={nameof<IFormikValues>('maxAantalProducten')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <NumeriekVeld
                                  waarde={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  min={1}
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <div className="d-flex align-items-center">
                            <Field
                              name={nameof<IFormikValues>('gesloten')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.gesloten}</span>
                          </div>
                        </div>

                        <div className="row col-12 mt-3">
                          <div className="col-5">
                            <label>{veldnamen.datumGepland}</label>
                            <Field
                              name={nameof<IFormikValues>('datumGepland')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                const ondergrensDagen = 30;
                                return (
                                  <DatumKiezer
                                    waarde={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    isClearable
                                    determineValidDate={(date) => {
                                      return date > addDays(new Date(), -ondergrensDagen);
                                    }}
                                    determinePreviousValidDate={(date) => {
                                      const minDate = addDays(new Date(), -ondergrensDagen);
                                      const newDate = addDays(date, -1);
                                      if (newDate >= minDate) {
                                        return newDate;
                                      }
                                      return null;
                                    }}
                                    determineNextValidDate="ONBEGRENST"
                                  />
                                );
                              }}
                            />
                          </div>

                          <div className="col-5">
                            <label>{veldnamen.datumUitgevoerd}</label>
                            <Field
                              name={nameof<IFormikValues>('datumUitgevoerd')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <DatumKiezer
                                    waarde={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    isClearable
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-6 mt-3">
                          <div className="d-flex align-items-center">
                            <Field
                              name={nameof<IFormikValues>('afgehandeld')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.afgehandeld}</span>
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.notities}</label>
                          <Field
                            name={nameof<IFormikValues>('notities')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return <textarea {...field} className="form-control" rows={3} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => props.onAnnuleren()}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
};

export default WijzigenOpdrachtDialoog;
