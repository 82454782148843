import { addDays } from 'date-fns';
import { IDetermineValidDateProps } from './index';

/**
 * Deze datums moeten gesoorteerd zijn in oplopende volgorde en voldoen aan het `dagDatum` formaat
 * @param validDates
 */
export const generateDetermineValidDatePropsByValidDateArray = (
  validDates: Date[],
): IDetermineValidDateProps => {
  return {
    determineValidDate: (date) => {
      // getTime is nodig zodat de waardes worden vergeleken ipv de references
      return validDates.find((d) => date.getTime() === d.getTime()) !== undefined;
    },
    determineNextValidDate: (date) => {
      return validDates.find((d) => d > date) || null;
    },
    determinePreviousValidDate: (date) => {
      return [...validDates].reverse().find((d) => d < date) || null;
    },
  };
};

export const standaardDetermineValidDateProps: IDetermineValidDateProps = {
  determineValidDate: () => true,
  determineNextValidDate: (date) => addDays(date, 1),
  determinePreviousValidDate: (date) => addDays(date, -1),
};
