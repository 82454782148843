import {
  IHerordenenVergelijkingspuntenParams,
  IHerordenenVergelijkingspuntenResult,
  IOphalenVergelijkingspuntenParams,
  IOphalenVergelijkingspuntenResult,
  IOphalenVergelijkingspuntXConcurrentiesParams,
  IOphalenVergelijkingspuntXConcurrentiesResult,
  IToevoegenVergelijkingspuntParams,
  IToevoegenVergelijkingspuntResult,
  IToevoegenVergelijkingspuntXConcurrentieParams,
  IToevoegenVergelijkingspuntXConcurrentieResult,
  IVerwijderenVergelijkingspuntParams,
  IVerwijderenVergelijkingspuntResult,
  IVerwijderenVergelijkingspuntXConcurrentieParams,
  IVerwijderenVergelijkingspuntXConcurrentieResult,
  IWijzigenVergelijkingspuntParams,
  IWijzigenVergelijkingspuntResult,
  IWijzigenVergelijkingspuntXConcurrentieParams,
  IWijzigenVergelijkingspuntXConcurrentieResult,
} from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt';
import api from '../../../index';
import voetnoot from './voetnoot';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const vergelijkingspunt = {
  voetnoot,
  ophalenVergelijkingspunten: async (
    params: IOphalenVergelijkingspuntenParams,
  ): Promise<IOphalenVergelijkingspuntenResult> => {
    return await api.post('/v2/concurrentie/vergelijkingspunt/ophalen-vergelijkingspunten', params);
  },
  toevoegenVergelijkingspunt: async (
    params: IToevoegenVergelijkingspuntParams,
  ): Promise<IToevoegenVergelijkingspuntResult> => {
    return await api.post('/v2/concurrentie/vergelijkingspunt/toevoegen-vergelijkingspunt', params);
  },
  wijzigenVergelijkingspunt: async (
    params: IWijzigenVergelijkingspuntParams,
  ): Promise<IWijzigenVergelijkingspuntResult> => {
    return await api.post('/v2/concurrentie/vergelijkingspunt/wijzigen-vergelijkingspunt', params);
  },
  checkVerwijderenVergelijkingspunt: async (
    params: IVerwijderenVergelijkingspuntParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/check-verwijderen-vergelijkingspunt',
      params,
    );
  },
  verwijderenVergelijkingspunt: async (
    params: IVerwijderenVergelijkingspuntParams,
  ): Promise<IVerwijderenVergelijkingspuntResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/verwijderen-vergelijkingspunt',
      params,
    );
  },
  herordenenVergelijkingspunten: async (
    params: IHerordenenVergelijkingspuntenParams,
  ): Promise<IHerordenenVergelijkingspuntenResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/herordenen-vergelijkingspunten',
      params,
    );
  },
  ophalenVergelijkingspuntXConcurrenties: async (
    params: IOphalenVergelijkingspuntXConcurrentiesParams,
  ): Promise<IOphalenVergelijkingspuntXConcurrentiesResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/ophalen-vergelijkingspunt-x-concurrenties',
      params,
    );
  },
  toevoegenVergelijkingspuntXConcurrentie: async (
    params: IToevoegenVergelijkingspuntXConcurrentieParams,
  ): Promise<IToevoegenVergelijkingspuntXConcurrentieResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/toevoegen-vergelijkingspunt-x-concurrentie',
      params,
    );
  },
  wijzigenVergelijkingspuntXConcurrentie: async (
    params: IWijzigenVergelijkingspuntXConcurrentieParams,
  ): Promise<IWijzigenVergelijkingspuntXConcurrentieResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/wijzigen-vergelijkingspunt-x-concurrentie',
      params,
    );
  },
  verwijderenVergelijkingspuntXConcurrentie: async (
    params: IVerwijderenVergelijkingspuntXConcurrentieParams,
  ): Promise<IVerwijderenVergelijkingspuntXConcurrentieResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/verwijderen-vergelijkingspunt-x-concurrentie',
      params,
    );
  },
};

export default vergelijkingspunt;
