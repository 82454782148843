import React from 'react';
import { IconPijlVolgend } from '../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  relID: number;
  pathWithStateBuilder: () => string;
}

const NavigatiePijl = (props: IProps) => {
  return (
    <button
      style={{
        outline: 0,
        border: 0,
        background: 'transparent',
      }}
      onClick={(ev) => {
        const pathWithState = props.pathWithStateBuilder();
        const link = `/klant/${props.relID}/${pathWithState}`;
        props.history.push(link);
      }}
    >
      <IconPijlVolgend style={{ width: 20, height: 20, fill: Kleur.ZwembadBlauw }} />
    </button>
  );
};

export default withRouter(NavigatiePijl);
