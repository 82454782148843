import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Combobox, { IOptie } from '../../../../../../components/formulier/Combobox';
import * as Yup from 'yup';
import api from '../../../../../../api';
import nameof from '../../../../../../core/nameOf';

interface IProps extends IDialoogProps<null> {
  TypeID: number;
}

interface IFormikValues {
  inkDienstID: number | null;
}
const veldnamen: Record<keyof IFormikValues, string> = {
  inkDienstID: 'Inkoopleverancier',
};

export interface IRow {
  InkDienstID: number;
  LeveranciernaamKort: string;
}

const ToevoegenInkoopgegevensDialoog: React.FC<IProps> = observer((props) => {
  const { onAnnuleren, onSuccess, TypeID } = props;

  const [inkoopdiensten, setInkoopdiensten] = useState<IRow[] | null>(null);

  useEffect(() => {
    (async () => {
      // Haal alle actieve inkoopdiensten op
      const inkoopdienstenResult = await api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });

      // Haal alle inkoopdiensten op die nu reeds aan het type gekoppeld zijn
      const inkoopdienstenVoorTypeResult = await api.v2.inkoop.aanbod.ophalenLeveranciersVoorProduct(
        {
          filterSchema: { filters: [{ naam: 'TYPE_IDS', data: [props.TypeID] }] },
        },
      );
      const inkoopdienstenVoorType = inkoopdienstenVoorTypeResult.inkoopdiensttypen;

      // Bepaal nu de inkoopdiensten die nog niet gekoppeld zijn aan het type
      const inkoopdienstenOngekoppeld = inkoopdienstenResult.diensten.filter(
        (x) => inkoopdienstenVoorType.map((x) => x.InkDienstID).indexOf(x.ID) === -1,
      );

      const inkoopdiensten = inkoopdienstenOngekoppeld.map((x) => {
        return { InkDienstID: x.ID, LeveranciernaamKort: x.relatie!.weergavenaam };
      });

      setInkoopdiensten(inkoopdiensten);
    })();
  }, [props.TypeID]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      inkDienstID: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      inkDienstID: Yup.number().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      await api.v2.inkoop.aanbod.toevoegenInkoopgegevens({
        typeID: props.TypeID,
        inkDienstID: values.inkDienstID!,
      });

      onSuccess(null);
    },
    [props.TypeID],
  );

  return (
    <Dialoog index={0}>
      <ModalHeader>
        <ModalTitle>Toevoegen leverancier voor product</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          isInitialValid
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.inkDienstID}</label>
                        <Field
                          name={nameof<IFormikValues>('inkDienstID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (inkoopdiensten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={inkoopdiensten.map((x) => {
                                  return {
                                    id: x.InkDienstID,
                                    label: x.LeveranciernaamKort,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      onAnnuleren();
                    }}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenInkoopgegevensDialoog;
