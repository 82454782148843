import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { IconAttachment, IconSend, IconSjablonen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import Knop from '../../Knop';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import SjabloonOplosser, { ISjabloonOplosserOutput } from '../../../../SjabloonOplosser';
import {
  ECommunicatiekanaalSelectieType,
  EOpgegevenContextSelectieType,
  ESjabloonOplosserModus,
  ETaalSelectieType,
  IOpgegevenContext,
} from '../../../../SjabloonOplosser/types';
import { IWhatsappChatsessie } from '../../../../../api/services/v1/whatsapp';
import api from '../../../../../api';
import BijlageVak from './BijlageVak';
import BijlageKnop, { IBijlageKnopButtonComponentProps } from '../../../../BijlageKnop';
import { Bestand, BestandType } from '../../../../BijlagenContainer';
import { useWhatsappV2Store } from '../../store';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const HoofdVak = styled.div`
  display: flex;
  align-items: end;
  padding: 8px 12px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
`;

const Tekstvak = styled.textarea`
  flex: 1;
  padding: 10px 14px;
  background-color: rgba(0, 0, 0, 0.05);
  border: 2px solid transparent;
  outline: none;
  font-size: 14px;
  resize: none;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  transition: border 0.2s ease-in-out;

  &:focus {
    border: 2px solid ${Kleur.SelectieBlauw};
  }

  ::placeholder {
    color: ${Kleur.Grijs};
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

interface IProps {
  chatsessie: IWhatsappChatsessie;
  bericht: string;
  onBerichtChange: (bericht: string) => void;
  bijlage: Bestand | null;
  onBijlageChange: (bijlage: Bestand | null) => void;
  disabled?: boolean;
}

const Invoerbalk = (props: IProps) => {
  const store = useWhatsappV2Store();
  const globaleRenderer = useContext(GlobaleRendererContext);
  const tekstvakRef = React.useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    tekstvakRef.current?.focus();
  }, [props.chatsessie.id]);

  // const tekstRef = useRef(props.bericht);
  // const [tekstState, setTekstState] = useState(props.bericht);
  // const tekst = tekstState;
  // const setTekst = useCallback((x: string) => {
  //   tekstRef.current = x;
  //   setTekstState(x);
  // }, []);
  //
  // useEffect(() => {
  //   if (tekstRef.current !== props.bericht) {
  //     setTekst(props.bericht);
  //   }
  // }, [props.bericht]);

  const herberekenenHoogteTekstvak = useCallback(() => {
    const textarea = tekstvakRef.current!;

    const maxHeight = 125;

    textarea.style.height = 'inherit';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(textarea);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      // parseInt(computed.getPropertyValue('padding-top'), 10) +
      textarea.scrollHeight +
      // parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10) +
      1;

    textarea.style.height = `${Math.min(height, maxHeight)}px`;
  }, []);

  useEffect(() => {
    herberekenenHoogteTekstvak();
  }, [props.bericht, herberekenenHoogteTekstvak]);

  const handleSjabloonToepassen = useCallback(async () => {
    const opgegevenContexten: IOpgegevenContext[] = [];

    if (props.chatsessie.persId !== null) {
      opgegevenContexten.push({
        selectie: {
          type: EOpgegevenContextSelectieType.NaamEnum,
          naamEnum: 'PERSOON',
        },
        data: { persID: props.chatsessie.persId },
      });
    }

    const output = await globaleRenderer.render<ISjabloonOplosserOutput>((renderProps) => (
      <SjabloonOplosser
        open
        onSuccess={(result) => renderProps.destroy(result)}
        onAnnuleren={() => renderProps.destroy()}
        taalSelectie={{
          type: ETaalSelectieType.AutomatischBepalenViaContexten,
          isMuteerbaar: true,
        }}
        communicatiekanaalSelectie={{
          type: ECommunicatiekanaalSelectieType.Opgegeven,
          isMuteerbaar: false,
          magVoorvullen: true,
          kanaalID: 6,
        }}
        defaultOpgegevenContexten={opgegevenContexten}
        modus={{
          type: ESjabloonOplosserModus.Genereer,
        }}
      />
    ));

    if (output === undefined) {
      return;
    }
    props.onBerichtChange(output.resolveResult!.inhoud ?? '');
    tekstvakRef.current?.focus();
  }, [props.onBerichtChange, props.chatsessie.persId, globaleRenderer]);

  const handleVersturen = useCallback(async () => {
    const tekst = props.bericht.trim();
    if (tekst.length === 0 && props.bijlage === null) {
      return;
    }
    store.setBerichtVersturenState(props.chatsessie.id, {
      bijlageUploadProgress: null,
    });
    props.onBerichtChange('');

    if (props.bijlage === null) {
      await api.services.v1.whatsapp.verstuurBericht({
        chatsessieId: props.chatsessie.id,
        bericht: {
          type: 'tekst',
          tekst,
        },
      });

      store.setBerichtVersturenState(props.chatsessie.id, null);
      return;
    }

    let bestandID: number;
    switch (props.bijlage.type) {
      case BestandType.ASPDrive: {
        bestandID = props.bijlage.bestand.ID;
        break;
      }
      case BestandType.Lokaal: {
        const uploadResult = await api.v2.bestand.upload(props.bijlage, (percentage: number) => {
          store.setBerichtVersturenState(props.chatsessie.id, {
            bijlageUploadProgress: percentage,
          });
        });
        bestandID = uploadResult.ID;
        break;
      }
    }

    await api.services.v1.whatsapp.verstuurBericht({
      chatsessieId: props.chatsessie.id,
      bericht: {
        type: 'media',
        bestandId: bestandID,
        naam: tekst,
      },
    });

    props.onBijlageChange(null);
    store.setBerichtVersturenState(props.chatsessie.id, null);
  }, [
    props.chatsessie.id,
    props.bericht,
    props.onBerichtChange,
    props.bijlage,
    props.onBijlageChange,
    store.setBerichtVersturenState,
  ]);

  const BijlageKnopButtonComp = useMemo<
    React.ComponentType<IBijlageKnopButtonComponentProps & React.RefAttributes<any>>
  >(
    () =>
      React.forwardRef((props, ref) => {
        return (
          <Knop
            ref={ref}
            style={{ marginRight: 5 }}
            title="Bijlage"
            onClick={(ev) => {
              ev.stopPropagation();

              props.onClick();
            }}
            disabled={props.disabled}
          >
            <IconAttachment
              style={{ fill: Kleur.Grijs, width: 21, height: 21, transform: 'scale(1.1)' }}
            />
          </Knop>
        );
      }),
    [],
  );

  const handleBijlageKnopBijgevoegd = useCallback(
    (bestanden: Bestand[]) => {
      const bestand = bestanden[0];
      props.onBijlageChange(bestand);
      tekstvakRef.current?.focus();
    },
    [props.onBijlageChange],
  );

  const isBezig = useMemo<boolean>(() => {
    return props.disabled || Boolean(store.berichtVersturenState[props.chatsessie.id]);
  }, [props.chatsessie.id, store.berichtVersturenState, props.disabled]);

  return (
    <Root>
      {props.bijlage !== null && (
        <BijlageVak
          chatsessie={props.chatsessie}
          bijlage={props.bijlage}
          onBijlageChange={props.onBijlageChange}
        />
      )}
      <HoofdVak>
        <BijlageKnop
          onBijgevoegd={handleBijlageKnopBijgevoegd}
          buttonComponent={BijlageKnopButtonComp}
          disabled={isBezig}
        />
        <Knop title="Sjabloon toepassen" onClick={handleSjabloonToepassen} disabled={isBezig}>
          <IconSjablonen
            style={{ fill: Kleur.Grijs, width: 21, height: 21, transform: 'scale(.9)' }}
          />
        </Knop>
        <Tekstvak
          ref={tekstvakRef}
          placeholder={
            props.bijlage === null
              ? 'Typ een bericht'
              : 'Voeg een omschrijving toe aan deze bijlage'
          }
          rows={1}
          value={props.bericht}
          onChange={(ev) => props.onBerichtChange(ev.target.value)}
          disabled={isBezig}
        />
        <Knop title="Bericht versturen" onClick={handleVersturen} disabled={isBezig}>
          <IconSend style={{ fill: Kleur.Grijs, width: 20, height: 20, padding: 1 }} />
        </Knop>
      </HoofdVak>
    </Root>
  );
};

export default Invoerbalk;
