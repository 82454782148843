import api from '../../../..';
import {
  IOphalenProductenParams,
  IOphalenProductenResult,
  IActiverenProductenParams,
  IActiverenProductenResult,
  IOphalenInkoopprijsProductenParams,
  IOphalenInkoopprijsProductenResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/activaregister/activeren';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const activeren = {
  ophalenInkoopprijsProducten: async (
    params: IOphalenInkoopprijsProductenParams,
  ): Promise<IOphalenInkoopprijsProductenResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/activeren/ophalen-inkoopprijs-producten',
      params,
    );
  },
  ophalenProducten: async (params: IOphalenProductenParams): Promise<IOphalenProductenResult> => {
    return await api.post('/v2/boekhouding/activaregister/activeren/ophalen-producten', params);
  },
  checkActiverenProducten: async (params: IActiverenProductenParams): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/activaregister/activeren/check-activeren-producten',
      params,
    );
  },
  activerenProducten: async (
    params: IActiverenProductenParams,
  ): Promise<IActiverenProductenResult> => {
    return await api.post('/v2/boekhouding/activaregister/activeren/activeren-producten', params);
  },
};

export default activeren;
