import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import RunTabelV2, {
  ERunTabelV2Kolom,
  IData,
  IDataProviderOutput,
  IDataProviderParams,
} from '../../../../../../../components/prolongatie/RunTabelV2';
import {
  IProlongatieContractenDataProviderOutput,
  IProlongatieContractenDataProviderParams,
} from '../../../../../../../components/prolongatie/RunTabelV2/ProlongatieDetail';
import { useHeeftAutorisatie } from '../../../../../../../helpers/heeftAutorisatie';
import { EPermissie } from '../../../../../../../bedrijfslogica/enums';
import { IAspKolomSorteringItem } from '../../../../../../../components/tabel/ASPTabel/types';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  sortering: IAspKolomSorteringItem<ERunTabelV2Kolom>[];
  regelsSelectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  sortering: [],
  regelsSelectie: [],
};

const Prolongaties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  // const [runs, setRuns] = useState<IProlongatieRun[] | null>(null);
  //
  // const ophalenRuns = useCallback(async () => {
  //   if (klantkaartStore.relatie === null) {
  //     setRuns(null);
  //     return;
  //   }
  //
  //   const vanafDatum = format(addMonths(new Date(), -3), 'yyyy-MM-dd');
  //   const { prolongatieRuns } = await api.v2.prolongatie.ophalenProlongatieRuns({
  //     filterSchema: {
  //       filters: [
  //         { naam: 'RUNDATUM_VANAF', data: vanafDatum },
  //         { naam: 'REL_IDS', data: [klantkaartStore.relatie.RelID] },
  //       ],
  //     },
  //   });
  //   setRuns(prolongatieRuns);
  // }, [klantkaartStore.relatie === null ? null : klantkaartStore.relatie.RelID]);
  //
  // useEffect(() => {
  //   ophalenRuns();
  // }, [ophalenRuns]);

  const dataProvider = useCallback(
    async (params: IDataProviderParams): Promise<IDataProviderOutput> => {
      const runsResult = await api.v2.prolongatie.ophalenProlongatieRunsV2({
        filterSchema: {
          filters: [
            ...(params.filterSchema?.filters ?? []),
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
        paginatie: params.paginatie,
        orderSchema: {
          orders: [
            {
              naam: 'DATUM_RUN',
              richting: 'DESC',
            },
          ],
        },
      });
      const afgeleideDatasResult =
        runsResult.prolongatieRuns.length === 0
          ? null
          : await api.v2.prolongatie.ophalenProlongatieRunAfgeleideDatas({
              prolRunIDs: runsResult.prolongatieRuns.map((run) => run.ID),
              relIDs: [props.relID],
            });

      const afgeleideDatas =
        afgeleideDatasResult === null
          ? params.basis?.afgeleideDatas ?? {}
          : {
              ...params.basis?.afgeleideDatas,
              ...afgeleideDatasResult.afgeleideDatas,
            };

      const runs = runsResult.prolongatieRuns.reduce(
        (acc, run, i) => ({
          ...acc,
          [params.paginatie.index + i]: run,
        }),
        params.basis?.runs ?? {},
      );

      const data: IData = {
        totaalAantalRuns: runsResult.totaalAantal,
        runs,
        afgeleideDatas,
      };

      return {
        data,
      };
    },
    [props.relID],
  );

  const prolongatieContractenDataProvider = useCallback(
    async (
      params: IProlongatieContractenDataProviderParams,
    ): Promise<IProlongatieContractenDataProviderOutput> => {
      const result = await api.v2.prolongatie.ophalenProlongatieContracten({
        filterSchema: {
          filters: [
            {
              naam: 'PROLONGATIE_RUN_IDS',
              data: [params.run.ID],
            },
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
        paginatie: params.paginatie,
      });
      const prolCntIDs = result.contracten.map((contract) => contract.ID);

      const afgeleideDatasResult = await api.v2.prolongatie.ophalenProlongatieContractAfgeleideDatas(
        {
          prolCntIDs,
        },
      );

      return {
        data: {
          totaalAantal: result.totaalAantal,
          contracten: result.contracten.reduce(
            (acc, contract, i) => ({
              ...acc,
              [params.paginatie.index + i]: contract,
            }),
            params.currentData?.contracten ?? {},
          ),
          afgeleideDatas: {
            ...params.currentData?.afgeleideDatas,
            ...afgeleideDatasResult.afgeleideDatas,
          },
        },
      };
    },
    [props.relID],
  );

  const permissieMagRunToevoegen = useHeeftAutorisatie([
    EPermissie.KaartContractProlongatieNieuweRun,
  ]);
  const permissieMagRunVerwerken = useHeeftAutorisatie([
    EPermissie.KaartContractProlongatieVerwerken,
  ]);
  const permissieMagRunVerwijderen = useHeeftAutorisatie([
    EPermissie.KaartContractProlongatieVerwijderen,
  ]);

  return (
    <RunTabelV2
      relID={props.relID}
      dataProvider={dataProvider}
      prolongatieContractenDataProvider={prolongatieContractenDataProvider}
      selectie={urlState.selectie}
      onSelectieChange={(x) => setUrlStateSync('selectie', x)}
      uitgeklapt={urlState.uitgeklapt}
      sortering={urlState.sortering}
      onSorteringChange={(x) => setUrlStateSync('sortering', x)}
      onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
      paginatieAantal={50}
      relatieKolomVerstoppen
      alleenNietVerwerktInitieel={false}
      permissieMagRunToevoegen={permissieMagRunToevoegen}
      permissieMagRunVerwerken={permissieMagRunVerwerken}
      permissieMagRunVerwijderen={permissieMagRunVerwijderen}
    />
  );

  // return (
  //   <RunTabel
  //     prolongatieRuns={runs}
  //     prolongatieRunsSelectie={urlState.selectie}
  //     nieuweRunDialoogState={urlState.nieuweRunDialoogState}
  //     onNieuweRunDialoogStateChange={(value: INieuweRunDialoogState | null) => {
  //       return setUrlStateSync('nieuweRunDialoogState', value);
  //     }}
  //     verwerkenDialoogState={urlState.verwerkenDialoogState}
  //     onVerwerkenDialoogStateChange={(value: IVerwerkenDialoogState | null) => {
  //       return setUrlStateSync('verwerkenDialoogState', value);
  //     }}
  //     onProlongatieRunsSelectieChange={(value: number[]) => setUrlStateSync('selectie', value)}
  //     onRequestRefresh={() => ophalenRuns()}
  //     regelsSelectie={urlState.regelsSelectie}
  //     onRegelsSelectieChange={(x) => setUrlStateSync('regelsSelectie', x)}
  //     relID={klantkaartStore.relatie.RelID}
  //   />
  // );
});

export default withRouter(Prolongaties);
